<template>
  <!--begin::My-office-->
  <div class="my-office">
    <!--begin::Header-->
    <div class="my-office-header py-4">
      <div class="container-fluid d-flex align-items-center justify-content-between">
        <div class="web-block-picks-search d-flex flex-fill align-items-center mr-5">
          <div class="mr-3">
            <div class="select-custom input-icon">
              <date-picker class="form-control" name="datePicker" :disabled="!isOfficeSelected" v-model="date" :config="options"></date-picker>
              <span><i class="fas fa-calendar-alt icon-custom"></i></span>
            </div>
          </div>
          <div class="mr-3">
            <b-dropdown class="time-select-custom">
              <template v-slot:button-content>
                <div class="input-icon">
                  <b-form-input class="form-control" v-model="timeRange" :disabled="!isOfficeSelected"></b-form-input>
                  <span><i class="fas fa-clock"></i></span>
                </div>
              </template>
              <div class="d-flex flex-column px-5 py-3">
                <div>{{ $t("MY_OFFICE.TIME_FROM") }} </div>
                <div>
					<date-picker class="form-control" name="timePicker" :disabled="!isOfficeSelected" v-model="time_start" :config="options2"></date-picker>
				</div>
              </div>
              <div class="d-flex flex-column px-5 py-3">
                <div>{{ $t("MY_OFFICE.TIME_TO") }} </div>
                <div>
                  <date-picker class="form-control" name="timePicker" :disabled="!isOfficeSelected" v-model="time_end" :config="options3"></date-picker>
                </div>
              </div>
            </b-dropdown>
          </div>
          <div class="flex-fill flex-shrink-1 max-w-400px d-none d-lg-block">
            <DropdownSearch
                :options="availableResources"
                v-on:selected="onSearch"
                v-on:filter="getDropdownValues"
                :disabled="!isOfficeSelected"
                name="myOfficeDropdownSearch"
                :placeholder="$t('MY_OFFICE.DROPDOWN_SEARCH_PLACEHOLDER')">
            </DropdownSearch>
          </div>
        </div>
        <div class="web-block-filter d-none d-md-flex align-items-center justify-content-end w-auto max-w-500px">
            <MainSelect
                :current-date="date"
                :start-time="time_start"
                :end-time="time_end"
                @setSelectedOffice="toggleIsOfficeSelected"
            />
        </div>
      </div>
      <div class="mobile-block container-fluid d-flex align-items-center justify-content-between mt-2">
        <div v-if="!isMobileSearch" class="d-flex flex-fill align-items-center">
          <div class="mr-3">
            <div class="select-custom input-icon">
              <date-picker class="form-control" name="datePicker" :disabled="!isOfficeSelected" v-model="date" :config="options"></date-picker>
              <span><i class="fas fa-calendar-alt icon-custom"></i></span>
            </div>
          </div>
          <div class="mr-3">
            <b-dropdown class="time-select-custom">
              <template v-slot:button-content>
                <div class="input-icon">
                  <b-form-input class="form-control" v-model="timeRange" :disabled="!isOfficeSelected"></b-form-input>
                  <span><i class="fas fa-clock"></i></span>
                </div>
              </template>
              <div class="d-flex flex-column px-5 py-3">
                <div>{{ $t("MY_OFFICE.TIME_FROM") }} </div>
                <div>
                  <date-picker class="form-control" name="timePicker" :disabled="!isOfficeSelected" v-model="time_start" :config="options2"></date-picker>
                </div>
              </div>
              <div class="d-flex flex-column px-5 py-3">
                <div>{{ $t("MY_OFFICE.TIME_TO") }} </div>
                <div>
                  <date-picker class="form-control" name="timePicker" :disabled="!isOfficeSelected" v-model="time_end" :config="options3"></date-picker>
                </div>
              </div>
            </b-dropdown>
          </div>
          <button @click="isMobileSearch = !isMobileSearch" class="btn border-secondary"><i class="fas fa-search icon-custom"></i></button>
        </div>
        <div v-else class="d-flex flex-fill align-items-center">
          <DropdownSearch
              class="mr-3"
              :options="availableResources"
              v-on:selected="onSearch"
              v-on:filter="getDropdownValues"
              :disabled="!isOfficeSelected"
              name="myOfficeDropdownSearch"
              :placeholder="$t('MY_OFFICE.DROPDOWN_SEARCH_PLACEHOLDER')">
          </DropdownSearch>
          <button @click="isMobileSearch = !isMobileSearch" class="btn border-secondary"><i class="fas fa-calendar-alt icon-custom"></i></button>
        </div>
        <div class="d-md-flex align-items-center justify-content-end w-auto max-w-900px mt-4">
          <MainSelect
              :current-date="date"
              :start-time="time_start"
              :end-time="time_end"
              @setSelectedOffice="toggleIsOfficeSelected"
          />
        </div>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Dummy-->
    <template v-if="!isOfficeSelected || !myOffice.plan.image">
      <div class="w-100 justify-content-center row mt-20">
        <div class="col-auto d-flex">
          <MyOfficeImage/>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center mt-10 text-center">
        <h5 class="font-weight-bolder text-dark text-center">{{ $t("MY_OFFICE.DUMMY_TITLE") }}</h5>
        <p class="d-block font-weight-lighter font-size-sm">{{ $t("MY_OFFICE.DUMMY_DESC") }}</p>
        <div class="w-100 max-w-600px mt-1">
          <MainSelect
              :current-date="date"
              :start-time="time_start"
              :end-time="time_end"
              @setSelectedOffice="toggleIsOfficeSelected"
          />
        </div>
      </div>
    </template>
    <!--end::Dummy-->
    <!--begin::Body-->
    <div v-if="isOfficeSelected && myOffice.plan.image" class="my-office-content d-flex">
      <div class="flex-grow-1 position-relative">
        <div class="h-100">
          <v-zoomer
              ref="zoomer"
              :max-scale="10"
              :min-scale="1"
              :zooming-elastic="true"
              :limitTranslation="false"
              :pivot="'cursor'"
              :zoomed.sync="zoomed"
              class="zoomer-block">
            <div class="points-block">
              <vue-drag-resize
                  v-if="hasPlanLoaded"
                  v-for="(item, index) in myOffice.points"
                  :key="`item-${item.id}`"
                  :id="`tooltip-item-${index}`"
                  :class="[setPointClass(item), item.hidden ? 'd-none' : ''].join(' ')"
                  :x="getRelativeCoordinates('X', item.coordinates.x)"
                  :y="getRelativeCoordinates('Y', item.coordinates.y)"
                  :sticks="[]"
                  :w="setResizedPoint(item.size)"
                  :h="setResizedPoint(item.size)"
                  :minw="10"
                  :minh="10"
                  @clicked="onActivated(index, item.resource.type)"
                  :parentLimitation="true"
                  :isDraggable="false">
                <b-tooltip ref="tooltip-item" :target="`tooltip-item-${index}`" triggers="click"> <!-- " focus" -->
                  <div class="tooltip-item-block mb-4"
                       :class="item.resource.type !== 'desk' && item.status !== 'unavailable' ? 'w-500px' : ''">
                    <div class="row">
                      <div :class="item.resource.type === 'desk' || item.resource.type !== 'desk' && item.status === 'unavailable' ? 'col-10' : 'col-11'"
                           class="float-left pt-4">
                        <h6 class="text-left font-weight-bolder">
                          <span>{{ item.resource.name }}</span>
                          <span v-if="item.resource.type === 'desk' && item.status === 'booked'"
                                class="label label-light-success label-inline font-weight-bolder ml-2">{{ $t("MY_OFFICE.BOOKED_TITLE") }}</span>
                        </h6>
                      </div>
                      <span @click="onCloseTooltip(index)"
                            :class="item.resource.type === 'desk' || item.resource.type !== 'desk' && item.status === 'unavailable' ? 'col-2' : 'col-1'"
                            class="float-right cursor-pointer">
                        <span class="font-size-lg" aria-hidden="true">&times;</span>
                      </span>
                    </div>
                    <div class="row">
                      <p v-if="item.resource.type === 'desk' && item.status !== 'busy' && item.status !== 'unavailable'"
                         class="text-left font-size-base col-12 ml-0 mb-5 text-dark">
                        {{ $t("MY_OFFICE.DESKS_TITLE") }}
                        <span class="tooltip-item-block-dot mx-3"></span>
                        <span>{{ $t("MY_OFFICE.ALL_DAY_TITLE") }}</span>
                      </p>
                      <p v-if="item.resource.type !== 'desk' && item.status !== 'unavailable'"
                         class="text-left font-size-base col-12 ml-0 mb-5 text-dark">
                        {{ $t("MY_OFFICE.MEETING_ROOMS_TITLE") }}
                        <span class="tooltip-item-block-dot mx-3"></span>
                        <span v-if="item.resource">{{ item.resource.properties.amountOfSeats !== null ?
                            $t("MY_OFFICE.AMOUNT_OF_SEATS_TO") + item.resource.properties.amountOfSeats + $t("MY_OFFICE.AMOUNT_OF_SEATS_PEOPLE") :
                            $t("MY_OFFICE.AMOUNT_OF_SEATS_IS_NOT_FOUND") }}
                        </span>
                        <span class="tooltip-item-block-dot mx-3"></span>
                        <span>{{ $t("MY_OFFICE.BOOKINGS_COUNT_TITLE") + meetingRoomRanges(item).length }}</span>
                      </p>
                    </div>
                    <!-- :roomData="room" :timeStamp="timestamp" -->
                    <!--                <Timeline-->
                    <!--                    class="mb-5"-->
                    <!--                    :timeStamp="timestamp"-->
                    <!--                    :roomData="pointMeetingAllInfo(item)"-->
                    <!--                    v-if="item.resource.type !== 'desk'"-->
                    <!--                    @bookingTime="selectedBookingTime">-->
                    <!--                </Timeline>-->
                    <template v-if="item.resource.type === 'desk' && item.status === 'busy'">
                      <div v-for="(point, index) in busyPointInfo(item)" :key="index" class="row">
                        <h5 class="text-left col-12 ml-0 font-weight-bolder text-dark">
                          {{ point.creator.name.display }}</h5>
                        <h6 class="text-left col-12 ml-0 text-dark">{{ point.creator.email }}</h6>
                        <h6 class="text-left col-12 ml-0 text-dark">{{ point.creator.profile.phone }}</h6>
                      </div>
                    </template>
                    <template v-if="item.resource.type === 'desk' && item.status === 'unavailable'">
                      <p class="text-left col-12 m-0 p-0 text-dark">{{ $t("MY_OFFICE.UNAVAILABLE_BOOKING_DESK_TITLE") }}</p>
                    </template>
                    <div v-if="item.resource.type === 'desk' && item.status !== 'busy'" class="row">
                      <div class="col-12">
                        <p v-if="item.status === 'free'"
                            @click="onReserve(item)"
                            class="btn btn-primary btn-block font-weight-bolder">
                          {{ $t("MY_OFFICE.BOOK_NOW_BUTTON") }}
                        </p>
                        <p v-if="item.status === 'booked'"
                            @click="onCancelReserve(item)"
                            class="btn btn-secondary btn-block font-weight-bolder">
                          {{ $t("MY_OFFICE.CANCEL_BOOKING_BUTTON") }}
                        </p>
                      </div>
                    </div>
                    <template v-if="item.resource.type !== 'desk' && item.status === 'unavailable'">
                      <p class="text-left col-12 m-0 p-0 text-dark">{{ $t("MY_OFFICE.UNAVAILABLE_BOOKING_MEETING_ROOM_TITLE") }}</p>
                    </template>
                    <template v-if="item.resource.type !== 'desk' && item.status !== 'unavailable'">
                      <div v-if="!isCreateReserve && !isEditReserve" class="row">
                        <p class="font-weight-bolder text-left text-muted mx-4 mb-1">{{ $t("MY_OFFICE.ALL_BOOKINGS") }}</p>
                        <h6 v-if="meetingRoomRanges(item).length === 0" class="col-12 text-left ml-0 mt-0">{{ $t("MY_OFFICE.NO_BOOKINGS_YET") }}</h6>
                        <div v-for="(range, index) in meetingRoomRanges(item)" :key="index"
                             class="col-12 mb-2">
                          <h6 class="text-left text-primary under mb-1 p-0 cursor-pointer"
                              @click="onSelectRange(range)">
                            <!--                          <p>{{ $moment(range.dateRange.start, 'HH:mm').local().format('HH:mm') }}</p>-->
                            <!--                          <p>{{ $moment(range.dateRange.start, 'HH:mm').utc(false).format('HH:mm') }}</p>-->
                            {{ $t("MY_OFFICE.BOOKING_TITLE") }} {{ $moment(range.dateRange.start, 'HH:mm').utcOffset(myOffice.office.timezone.slice(1, 7)).format('HH:mm') + "-" +
                          $moment(range.dateRange.end, 'HH:mm').utcOffset(myOffice.office.timezone.slice(1, 7)).format('HH:mm')}}, {{ range.members.length }} {{ $t("MY_OFFICE.MEMBERS_TITLE") }}
                            <!--                          Бронирование: {{ range.dateRange.start + "-" + range.dateRange.end }}, {{ range.members.length }} участника-->
                            <span v-if="range.source !== 'deskki'" class="label label-sm label-inline">Outlook</span>
                          </h6>
                        </div>
                        <span v-if="meetingRoomRanges(item).length > 0" class="col-12 text-left font-size-sm text-muted ml-0 mt-0">{{ $t("MY_OFFICE.LOCAL_TIME_DESC") }}</span>
                      </div>
                      <div v-if="isCreateReserve || isEditReserve" class="d-flex flex-column">
                        <p class="font-weight-bolder text-left text-muted mb-2">{{ $t("MY_OFFICE.TIME_TITLE") }}</p>
                        <div class="flex-grow-1 text-left mb-1">
                          <h6>{{ getCurrentDate() }}</h6>
                        </div>
                        <div class="d-flex align-items-center">
                          <div>
                            <date-picker class="input-group-text" name="timePicker"
                                         v-model="currentRange.dateRange.start" :config="options2"></date-picker>
                          </div>
                          <div class="px-5">
                            <i class="fas fa-arrow-right"></i>
                          </div>
                          <div>
                            <date-picker class="input-group-text" name="timePicker"
                                         v-model="currentRange.dateRange.end" :config="options3"></date-picker>
                          </div>
                        </div>
                        <span class="text-left font-size-sm text-muted ml-0 mt-2">{{ $t("MY_OFFICE.IN_LOCAL_TIME_DESC") }}</span>
                      </div>
                      <div v-if="isCreateReserve || isEditReserve" class="row my-5">
                        <div class="col-12">
                          <p class="font-weight-bolder text-left text-muted m-0 mb-2">{{ $t("MY_OFFICE.MEMBERS_BLOCK_TITLE") }}</p>
                          <DropdownSearch
                              :options="availableEmployees"
                              :resource="item.resource"
                              v-on:selectedEmployee="onSearchEmployees"
                              v-on:filter="getDropdownEmployeesValues"
                              :disabled="false"
                              name="myOfficeDropdownEmployeesSearch"
                              :placeholder="$t('MY_OFFICE.MEMBERS_BLOCK_SEARCH_PLACEHOLDER')">
                          </DropdownSearch>
                        </div>
                        <div class="col-12">
                          <div class="selected-employees mx-4">
                            <div v-if="currentRangeMembers && isEditReserve"
                                 v-for="(range, index) in currentRangeMembers" :key="index">
                              <div class="row selected-employees-item py-4" v-for="(member, i) in range.members" :key="i">
                                <div class="col-1 mt-2">
                                  <span class="btn btn-circle btn-icon btn-light"><User/></span>
                                </div>
                                <div class="col-9">
                                  <h6 class="text-left font-weight-bolder text-dark pt-2 m-0 ml-2">
                                    {{ !member.name ? $t("MY_OFFICE.MEMBERS_BLOCK_GUEST_DESC") : member.name.display ? member.name.display : member.name }}
                                  </h6>
                                  <span class="d-block text-left font-weight-light text-muted m-0 ml-2 p-0">
                                    {{ member.email }}
                                    <!-- Если совпадает с creater -->
                                    <span v-if="member.email === range.creator.email"
                                          class="label label-light-success label-inline ml-2">{{ $t("MY_OFFICE.MEMBERS_BLOCK_ORG_DESC") }}</span>
                                  </span>
                                </div>
                                <div class="col-1 mt-1">
                                  <span class="btn btn-icon btn-light btn-hover-primary btn-sm"
                                        v-if="member.email !== range.creator.email"
                                        @click="onDeleteMember(range, member)"><Trash/></span>
                                </div>
                              </div>
                            </div>
                            <div v-if="isCreateReserve"
                                 class="row selected-employees-item py-4"
                                 v-for="(employee, i) in addedEmployees" :key="i">
                              <div class="col-1">
                                <span class="btn btn-circle btn-icon btn-light"><User/></span>
                              </div>
                              <div class="col-9">
                                <h6 class="text-left font-weight-bolder text-dark pt-1 m-0 ml-2">{{ employee.name.display }}</h6>
                                <span class="d-block text-left font-weight-light text-muted m-0 ml-2 p-0">
                                  {{ employee.email }}
                                  <!-- Если совпадает с creater -->
                                  <span v-if="employee.email === currentUserAccountInfo.email"
                                        class="label label-light-success label-inline ml-2">{{ $t("MY_OFFICE.MEMBERS_BLOCK_ORG_DESC") }}</span>
                                </span>
                              </div>
                              <div class="col-1 mt-1">
                                <span class="btn btn-icon btn-light btn-hover-primary btn-sm"
                                      v-if="employee.email !== currentUserAccountInfo.email"
                                      @click="onDeleteEmployee(employee)"><Trash/></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 text-left mt-5">
                          <p v-if="!isCreateReserve && !isEditReserve"
                              @click="onCreateReserve"
                              class="btn btn-primary font-weight-bolder">
                            {{ $t("MY_OFFICE.NEW_BOOKING_BUTTON") }}
                          </p>
                          <p v-if="isCreateReserve"
                              @click="onReserve(item)"
                              class="btn btn-primary font-weight-bolder">
                            {{ $t("MY_OFFICE.BOOK_NOW_BUTTON") }}
                          </p>
                          <p v-if="isEditReserve"
                              @click="onEditReserve()"
                              class="btn btn-primary font-weight-bolder">
                            {{ $t("MY_OFFICE.BOOK_NOW_BUTTON") }}
                          </p>
                          <p v-if="isEditReserve"
                              @click="onCancelReserve(item)"
                              class="btn btn-outline-danger font-weight-bolder ml-2">
                            {{ $t("MY_OFFICE.CANCEL_BOOKING_BTN") }}
                          </p>
                          <p v-if="isCreateReserve || isEditReserve"
                              @click="isCreateReserve = false; isEditReserve = false"
                              class="btn btn-secondary font-weight-bolder ml-2">
                            {{ $t("MY_OFFICE.BACK_BUTTON") }}
                          </p>
                        </div>
                      </div>
                    </template>
                  </div>
                </b-tooltip>
              </vue-drag-resize>
              <img :src="'data:image/svg+xml;base64,' + myOffice.plan.image" ref="plan" @load="onPlanLoad" alt="My office image"/>
            </div>
          </v-zoomer>
        </div>
      </div>
      <div v-if="myOffice.points" class="resource-controls btn-group btn-group-shadow">
        <button type="button" class="btn btn-white" @click="onOpenAsideBlock('desk')">
          {{ $t("MY_OFFICE.DESKS_BUTTON") }}
          <template v-if="myOffice.availableDesksLimit !== undefined">
            <span :class="myOffice.availableDesksLimit === 0 ? 'label-warning' : 'label-success'"
                  class="label label-sm label-inline">{{ myOffice.availableDesksLimit }} {{ $t("MY_OFFICE.DESKS_AVAILABLE_BUTTON") }}</span>
          </template>
        </button>
        <button type="button" class="btn btn-white" @click="onOpenAsideBlock('meeting_room')">
          {{ $t("MY_OFFICE.MEETING_ROOMS_BUTTON") }}
        </button>
      </div>
      <div class="zoom-controls btn-group btn-group-shadow">
        <button type="button" class="btn btn-white" @click="$refs.zoomer.zoomIn()">
          <i class="fas fa-plus icon-sm"></i>
        </button>
        <button type="button" class="btn btn-white" @click="$refs.zoomer.zoomOut()">
          <i class="fas fa-minus icon-sm"></i>
        </button>
      </div>
      <div v-if="myOffice.points" class="points-control-block">
        <b-tooltip :show.sync="showLegend" target="tooltip-legend" placement="topleft" triggers="click">
          <div class="px-1 py-1">
            <div class="row align-items-center py-2">
              <div class="col-1"><div class="free-workplace-point demo"></div></div>
              <div class="col-10"><span class="float-left pl-5">{{ $t("MY_OFFICE.FREE_DESK_POINT_TYPE") }}</span></div>
            </div>
            <div class="row align-items-center py-2">
              <div class="col-1"><div class="free-meeting-point demo"></div></div>
              <div class="col-10"><span class="float-left pl-5">{{ $t("MY_OFFICE.FREE_MEET_POINT_TYPE") }}</span></div>
            </div>
            <div class="row align-items-center py-2">
              <div class="col-1"><div class="reserved-workplace-point demo"></div></div>
              <div class="col-10"><span class="float-left pl-5">{{ $t("MY_OFFICE.BOOKED_DESK_POINT_TYPE") }}</span></div>
            </div>
            <div class="row align-items-center py-2">
              <div class="col-1"><div class="reserved-meeting-point demo"></div></div>
              <div class="col-10"><span class="float-left pl-5">{{ $t("MY_OFFICE.BOOKED_MEET_POINT_TYPE") }}</span></div>
            </div>
            <div class="row align-items-center py-2">
              <div class="col-1"><div class="busy-workplace-point demo"></div></div>
              <div class="col-10"><span class="float-left pl-5">{{ $t("MY_OFFICE.BUSY_DESK_POINT_TYPE") }}</span></div>
            </div>
            <div class="row align-items-center py-2">
              <div class="col-1"><div class="busy-meeting-point demo"></div></div>
              <div class="col-10"><span class="float-left pl-5">{{ $t("MY_OFFICE.BUSY_MEET_POINT_TYPE") }}</span></div>
            </div>
            <div class="row align-items-center py-2">
              <div class="col-1"><div class="unavailable-workplace-point demo"></div></div>
              <div class="col-10"><span class="float-left pl-5">{{ $t("MY_OFFICE.UNAVAILABLE_DESK_POINT_TYPE") }}</span></div>
            </div>
            <div class="row align-items-center py-2">
              <div class="col-1"><div class="unavailable-meeting-point demo"></div></div>
              <div class="col-10"><span class="float-left pl-5">{{ $t("MY_OFFICE.UNAVAILABLE_MEET_POINT_TYPE") }}</span></div>
            </div>
          </div>
        </b-tooltip>
        <b-icon id="tooltip-legend"
                @click="showLegend = !showLegend; showLegend === true ? setAmplitudeEventType('my-office-legend open') : false "
                icon="question-circle-fill"
                class="btn-shadow rounded-circle bg-white text-primary cursor-pointer p-5"
                font-scale="4"
                variant="primary">
        </b-icon>
      </div>
    </div>
    <!--begin::Aside-->
    <div v-if="isAsideBlock" class="my-office-aside-block">
      <!--begin::Header-->
      <div class="my-office-aside-block-header d-flex align-items-center justify-content-between p-5 pt-10">
        <p class="font-weight-bolder text-muted m-0">
          {{ resourcesListTitle  === 'desk' ? $t("MY_OFFICE.DESKS_TITLE") : $t("MY_OFFICE.MEETING_ROOMS_TITLE") }}
        </p>
        <button class="btn btn-xs btn-icon btn-light btn-hover-primary"
           @click="onCloseAsideBlock">
          <i class="ki ki-close icon-xs text-muted"></i>
        </button>
      </div>
      <!--end::Header-->
      <!--begin::Content-->
      <div v-if="isAmenitiesFilter"
           class="my-office-amenities-block">
        <div class="checkbox-list my-5"
             v-for="(amenity, index) in myOfficeAmenitiesList.items" :key="index">
          <label class="checkbox">
            <input type="checkbox" :value="amenity.id" v-model="selectedAmenities" :name="amenity.name"/>
            <span></span>
            {{ amenity.name }}
          </label>
        </div>
        <div class="w-100">
          <b-button
              variant="light-primary"
              class="float-left font-weight-bolder"
              @click="onSetAmenities(resourcesListTitle === 'desk' ? 'desks' : 'meeting-rooms', 'reset')">
            {{ $t("MY_OFFICE.CLEAR_BUTTON") }}
          </b-button>
          <p class="float-right">
            <b-button
                class="font-weight-bolder"
                variant="primary"
                @click="onSetAmenities(resourcesListTitle === 'desk' ? 'desks' : 'meeting-rooms', 'apply')">
              {{ $t("MY_OFFICE.ACCEPT_BUTTON") }}
            </b-button>
          </p>
        </div>
      </div>
      <div class="my-office-aside-block-content">
        <div v-if="resourcesListTitle === 'desk'">
          <div class="mb-5 px-5">
            <button @click="isAmenitiesFilter = !isAmenitiesFilter" :class="selectedAmenities.length > 0 ? 'btn-primary' : 'btn-light'"
                    class="btn btn-xs font-weight-bolder">{{ selectedAmenities.length === 0 ? $t("MY_OFFICE.AMENITIES_NOT_SELECTED") : $t("MY_OFFICE.AMENITIES") + selectedAmenities.length }}</button>
          </div>
          <p v-if="myOffice.availableDesksLimit" class="px-5">{{ $t("MY_OFFICE.AVAILABLE_FOR_BOOKING") }} {{ myOffice.availableDesksLimit }}</p>
          <h6 v-if="onlyDeskList.length === 0" class="px-5">{{ $t("TABLE.EMPTY_TITLE") }}</h6>
          <div v-for="(item, i) in onlyDeskList" :key="i"
               @click="onSelectPoint(item)"
               class="d-flex flex-column my-office-aside-block-content_item cursor-pointer px-5">
            <div class="d-flex align-items-center py-5">
              <div class="mr-3"><div :class="setPointClass(item)" class="demo"></div></div>
              <div class="font-weight-bolder text-dark">
                <div>{{ item.resource.name }}</div>
                <template v-if="item.status === 'busy'">
                  <span v-for="(point, index) in busyPointInfo(item)" :key="index">
                    <span class="m-0 p-0 font-weight-bolder text-dark">{{ point.creator.name.display + " " }}</span>
                  </span>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div v-if="resourcesListTitle !== 'desk'">
          <div class="mb-5 px-5">
            <button @click="isAmenitiesFilter = !isAmenitiesFilter" :class="selectedAmenities.length > 0 ? 'btn-primary' : 'btn-light'"
                    class="btn btn-xs font-weight-bolder">{{ selectedAmenities.length === 0 ? $t("MY_OFFICE.AMENITIES_NOT_SELECTED") : $t("MY_OFFICE.AMENITIES") + selectedAmenities.length }}</button>
          </div>
          <h6 v-if="onlyMeetingRoomList.length === 0" class="px-5">{{ $t("TABLE.EMPTY_TITLE") }}</h6>
          <div v-for="(item, i) in onlyMeetingRoomList" :key="i"
               @click="onSelectPoint(item)"
               class="d-flex flex-column my-office-aside-block-content_item cursor-pointer px-5">
            <div class="d-flex align-items-center py-4">
              <div class="mr-3"><div :class="setPointClass(item)" class="demo"></div></div>
              <div class="font-weight-bolder text-dark"><div>{{ item.resource.name }}</div></div>
            </div>
          </div>
        </div>
      </div>
      <!--end::Content-->
    </div>
    <!--end::Aside-->
    <!--end::Body-->
  </div>
  <!--end::My-office-->
</template>

<script>
import moment from 'moment';
import {mapGetters} from "vuex";
import i18nService from "@/core/services/i18n.service.js";

import {GET_DESKS_LIST, GET_MEETING_ROOMS_LIST} from "@/core/services/store/management/resources.module";
import {
  ASSIGN_MEMBER_TO_MEETING_ROOM,
  GET_AVAILABLE_EMPLOYEES,
  GET_AVAILABLE_RESOURCES,
  GET_BOOKING_DESK_LIST,
  GET_BOOKING_MEETING_ROOM_LIST,
  GET_MY_OFFICE,
  GET_MY_OFFICE_AMENITIES_LIST,
  GET_MY_OFFICE_AMENITIES_LIST_FILTERED,
  POST_RESERVE_DESK,
  POST_RESERVE_MEETING_ROOM,
  PUT_RESERVE_DESK_CANCEL,
  PUT_RESERVE_MEETING_ROOM_CANCEL,
  PUT_RESERVE_MEETING_ROOM_EDIT,
  REVOKE_MEMBER_FROM_MEETING_ROOM
} from "@/core/services/store/myoffice/index";

import DropdownSearch from "@/view/layout/extras/DropdownSearch";
import Timeline from "@/view/layout/extras/Timeline";
import MainSelect from "@/view/layout/extras/MainSelect";
import MyOfficeImage from "@/view/layout/svg/MyOfficeImage";
import Trash from "@/view/layout/svg/Trash";
import Check from "@/view/layout/svg/Check";
import User from "@/view/layout/svg/User";
import {SET_ERROR} from "@/core/services/store/auth.module";
import {sendAmplitudeEvent} from "@/core/services/amplitude.service";

export default {
  name: "My-office",
  components: {
    User,
    Check,
    Trash,
    DropdownSearch,
    Timeline,
    MainSelect,
    MyOfficeImage
  },
  mounted() {
    // this.timestamp = (+moment().subtract(0, 'days').startOf('day') + Math.abs(moment.parseZone().utcOffset() * 60) * 1000) / 1000;
    // this.getOffice();
    // this.$store.dispatch(GET_DESKS_LIST, { statuses: 'active' });
    // this.$store.dispatch(GET_MEETING_ROOMS_LIST, { statuses: 'active' });
    // this.$refs.zoomer.onWindowResize();
    // this.$refs.zoomer.reset();
    // this.$nextTick(() => {
    //   this.$refs.zoomer.onWindowResize;
    //   this.$refs.zoomer.reset();
    // });
  },
  created() {
    this.setAmplitudeEventType('my-office show');
    if (this.deviceType() === 'mobile') {
      window.addEventListener("orientationchange", this.onWindowResize);
    } else {
      window.addEventListener("resize", this.onWindowResize);
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  data() {
    return {
      isMobileSearch: false,
      isCreateReserve: false,
      isEditReserve: false,
      isAsideBlock: false,
      resourcesListTitle: null,
      isOfficeSelected: false,
      showLegend: false,
      date: new Date(),
      time_start: '09:00',
      time_end: '18:00',
      // time_start: new Date().setHours(0,0),
      // time_end: new Date().setHours(23,59),
      timestamp: 0,
      options: {
        locale: i18nService.getActiveLanguage(),
        format: 'DD.MM.YYYY',
        // format: 'dd, DD MMM',
        useCurrent: true,
        // showTodayButton: true,
        // showClear: true,
        // showClose: true,
        defaultDate: new Date(),
        minDate: new Date(new Date().setDate(new Date().getDate() - 1)),
        // maxDate: new Date(new Date().setDate(new Date().getDate() + 30)),
        // timeZone: '',
        tooltips: {
          today: 'Go to today',
          clear: 'Clear selection',
          close: 'Close the picker',
          selectMonth: 'Select Month',
          prevMonth: 'Previous Month',
          nextMonth: 'Next Month',
          selectYear: 'Select Year',
          prevYear: 'Previous Year',
          nextYear: 'Next Year',
          selectDecade: 'Select Decade',
          prevDecade: 'Previous Decade',
          nextDecade: 'Next Decade',
          prevCentury: 'Previous Century',
          nextCentury: 'Next Century'
        }
      },
      options2: {
        locale: i18nService.getActiveLanguage(),
        format: 'HH:mm',
        useCurrent: true,
        showClear: true,
        showClose: true,
        defaultDate: new Date().setHours(0,0),
        // disabledTimeIntervals: [[moment({ h: 0 }), moment({ h: 8 })], [moment({ h: 22 }), moment({ h: 24 })]]
        // minDate: new Date().setHours(0,0),
        // maxDate: new Date().setHours(23,59)
      },
      options3: {
        locale: i18nService.getActiveLanguage(),
        format: 'HH:mm',
        useCurrent: true,
        showClear: true,
        showClose: true,
        defaultDate: new Date().setHours(23,59),
        // disabledTimeIntervals: [[moment({ h: 0 }), moment({ h: 8 })], [moment({ h: 22 }), moment({ h: 24 })]]
        // minDate: this.options2.minDate,
        // maxDate: new Date().setHours(23,59)
      },
      zoomed: false,
      currentRange: {
        dateRange: {
          start: null,
          end: null
        }
      },
      currentRangeMembers: null,
      // range_time_start: null,
      // range_time_end: null,
      addedEmployees: [],
      planSize: {
        width: null,
        height: null,
      },
      hasPlanLoaded: false,
      isAmenitiesFilter: false,
      selectedAmenities: []
    }
  },
  computed: {
    ...mapGetters([
      "myOffice",
      "currentUserAccountInfo",
      "availableResources",
      "bookingDeskList",
      "bookingMeetingRoomList",
      "onlyDeskList",
      "onlyMeetingRoomList",
      "availableEmployees",
      "deskList",
      "meetingRoomsList",
      "myOfficeAmenitiesList"
    ]),
    timeRange: {
      get() {
        if (this.time_start && this.time_end) return this.time_start + ' - ' + this.time_end;
      },
      set(value) {}
    }
  },
  watch: {
    date(newVal) {
      if (newVal) {
        this.setAmplitudeEventType('my-office-date change');
        this.getOffice();
      }
    },
    time_start(newVal) {
      if (newVal) {
        this.setAmplitudeEventType('my-office-time change');
        this.getOffice()
      }
    },
    time_end(newVal) {
      if (newVal) {
        this.setAmplitudeEventType('my-office-time change');
        this.getOffice()
      }
    },
    currentRange(newVal) { if (newVal) this.meetingRoomMembers(newVal.id) },
  },
  methods: {
    deviceType() {
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(navigator.userAgent)) {
        return "tablet";
      }
      else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(navigator.userAgent)) {
        return "mobile";
      }
      return "desktop";
    },
    setAmplitudeEventType(eventType) {
      sendAmplitudeEvent(eventType);
    },
    onPlanLoad () {
      this.hasPlanLoaded = false;
      this.$refs.zoomer.onWindowResize;
      this.planSize = this.getPlanSize();
      this.$refs.zoomer.reset();
      setTimeout(() => {
        this.hasPlanLoaded = true;
      }, 1000)
    },
    getPlanSize() {
      return {
        width: this.$refs.plan.width,
        height: this.$refs.plan.height,
      }
    },
    getRelativeCoordinates(coordinateType, coordinateValue) {
      switch (coordinateType) {
        case 'X':
          return coordinateValue * this.planSize.width;
        case 'Y':
          return coordinateValue * this.planSize.height;
        default:
          return 0;
      }
    },
    getCurrentDate() {
      let lang = moment(this.date, 'DD.MM.YYYY').locale(i18nService.getActiveLanguage());
      return lang.format('LL');
    },
    getOffice() {
      this.$store.dispatch(GET_BOOKING_DESK_LIST, {
        id: this.myOffice.id,
        params: {
          status: 'booked', // "canceled" // "finished"
          date_start: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_start,
          date_end: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_end
        }
      });
      this.$store.dispatch(GET_BOOKING_MEETING_ROOM_LIST, {
        id: this.myOffice.id,
        params: {
          status: 'booked', // "canceled" // "finished"
          date_start: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_start,
          date_end: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_end
        }
      });
      this.$store.dispatch(GET_MY_OFFICE, {
        id: this.myOffice.id,
        params: {
          date_start: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_start,
          date_end: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_end
        }
      });
    },
    toggleIsOfficeSelected(variable) {
      this.isOfficeSelected = variable;
    },
    setPointClass(item) {
      if (item.resource.type === 'desk') {
        if (item.status === 'free') return 'free-workplace-point';
        else if (item.status === 'booked') return 'reserved-workplace-point';
        else if (item.status === 'unavailable') return 'unavailable-workplace-point';
        else return 'busy-workplace-point';
      } else {
        if (item.status === 'free') return 'free-meeting-point';
        else if (item.status === 'booked') return 'reserved-meeting-point';
        else if (item.status === 'unavailable') return 'unavailable-meeting-point';
        else return 'busy-meeting-point';
      }
    },
    onActivated(index, itemType) {
      sendAmplitudeEvent('my-office-point click', { 'type': itemType });
      this.onCloseAllTooltips();
      this.currentRange = {
        dateRange: {
          start: null,
          end: null
        }
      };
      this.currentRangeMembers = null;
      this.isCreateReserve = null;
      this.isEditReserve = null;
      this.$refs['tooltip-item'][index].$emit('open');
    },
    onCloseTooltip(index) {
      this.$refs['tooltip-item'][index].$emit('close');
      this.currentRange = {
        dateRange: {
          start: null,
          end: null
        }
      };
      this.currentRangeMembers = null;
      this.isCreateReserve = null;
      this.isEditReserve = null;
    },
    onCloseAllTooltips() {
      this.$refs['tooltip-item'].forEach((item, index) => {
        this.$refs['tooltip-item'][index].$emit('close');
      });
    },
    busyPointInfo(item) {
      return this.bookingDeskList.items.filter((bookItem) => {
        if (bookItem.resource.id === item.resource.id) {
          return bookItem;
        }
      });
    },
    // pointMeetingAllInfo(item) {
    //   let point = this.bookingMeetingRoomList.items.filter((bookItem) => {
    //     if (bookItem.resource.id === item.resource.id) {
    //       return bookItem;
    //     }
    //   });
    //   return point[0]
    // },
    meetingRoomRanges(item) {
      if (this.bookingMeetingRoomList) {
        let ranges = this.bookingMeetingRoomList.items.filter((bookItem) => {
          if (bookItem.resource.id === item.resource.id) {
            return bookItem;
          }
        });
        if (ranges) {
          return ranges;
        }
      }
    },
    meetingRoomMembers(item) {
      let members = this.bookingMeetingRoomList.items.filter((bookItem) => {
        if (bookItem.id === item) return bookItem;
      });
      if (members) this.currentRangeMembers = members;
    },
    onSearch(searchQuery) {
      if (this.myOffice.points) {
        this.myOffice.points.forEach((point, index) => {
          if (point.resource.id === (searchQuery.id || searchQuery.resource_id)) {
            sendAmplitudeEvent('my-office-search-result click', { 'type': searchQuery.type });
            this.$refs['tooltip-item'][index].$emit('open');
          }
        })
      }
    },
    getDropdownValues(searchQuery) {
      if (searchQuery.length > 2) sendAmplitudeEvent('my-office-search submit');
      this.$store.dispatch(GET_AVAILABLE_RESOURCES, {
        id: this.myOffice.id,
        params: {
          name: searchQuery,
          date_start: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_start,
          date_end: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_end
        }
      });
    },
    onDeleteMember(range, item) {
      const memberEmail = item.email;
      this.$store.dispatch(REVOKE_MEMBER_FROM_MEETING_ROOM, { id: range.id, body: { memberEmail } })
        .then(() => {
          range.members = range.members.filter(member => member.email !== item.email)
          this.$store.dispatch(GET_BOOKING_MEETING_ROOM_LIST, {
            id: this.myOffice.id,
            params: {
              status: 'booked', // "canceled" // "finished"
              date_start: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_start,
              date_end: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_end
            }
          });
          this.$store.dispatch(GET_MY_OFFICE, {
            id: this.myOffice.id,
            params: {
              date_start: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_start,
              date_end: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_end
            }
          })
        })
    },
    onSearchEmployees(searchQuery) {
      if (this.isCreateReserve) {
        let sameEmail = this.addedEmployees.filter((item) => {
          return item.email === searchQuery.selected.email;
        })
        if (sameEmail.length > 0) {
          this.$store.commit(SET_ERROR, { message: "Участник уже был добавлен." });
        } else {
          if (searchQuery.properties !== null) {
            this.addedEmployees.length < searchQuery.properties
                ? this.addedEmployees.push(searchQuery.selected)
                : this.$store.commit(SET_ERROR, { message: "Превышено ограничение по вместимости переговорной комнаты: приглашено слишком много гостей." });
            //  EN: Meeting room capacity limit exceeded: too many guests invited.
          } else {
            this.addedEmployees.push(searchQuery.selected);
          }
        }
      } else {
        let sameEmail = this.currentRangeMembers[0].members.filter((item) => {
          return item.email === searchQuery.selected.email;
        })
        if (sameEmail.length > 0) {
          this.$store.commit(SET_ERROR, { message: "Участник уже был добавлен." });
        } else {
          if (searchQuery.properties !== null) {
            if (this.currentRangeMembers[0].members.length < searchQuery.properties) {
              const memberEmail = searchQuery.selected.email;
              this.currentRangeMembers[0].members.push(searchQuery.selected);
              this.$store.dispatch(ASSIGN_MEMBER_TO_MEETING_ROOM, { id: this.currentRange.id, body: { memberEmail } });
            } else {
              this.$store.commit(SET_ERROR, { message: "Превышено ограничение по вместимости переговорной комнаты: приглашено слишком много гостей." });
              //  EN: Meeting room capacity limit exceeded: too many guests invited.
            }
          } else {
            const memberEmail = searchQuery.selected.email;
            this.currentRangeMembers[0].members.push(searchQuery.selected);
            this.$store.dispatch(ASSIGN_MEMBER_TO_MEETING_ROOM, { id: this.currentRange.id, body: { memberEmail } });
          }
        }
      }
    },
    onDeleteEmployee(employee) {
      this.addedEmployees = this.addedEmployees.filter(item => item.email !== employee.email)
    },
    getDropdownEmployeesValues(searchQuery) {
      this.$store.dispatch(GET_AVAILABLE_EMPLOYEES, { params: {name: searchQuery} })
    },
    // selectedBookingTime(variable) {
    //   this.range_time_start = variable.substr(0, 5);
    //   this.range_time_end = variable.substr(8, 12);
    // },
    onOpenAsideBlock(name) {
      name === 'desk' ? sendAmplitudeEvent('my-office-desk-list open') : sendAmplitudeEvent('my-office-meeting-room-list open');
      if (this.resourcesListTitle !== name) {
        this.selectedAmenities = [];
        this.$store.dispatch(GET_MY_OFFICE, {
          id: this.myOffice.id,
          params: {
            date_start: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_start,
            date_end: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_end
          }
        }).then(() => {
          this.$store.dispatch(GET_MY_OFFICE_AMENITIES_LIST_FILTERED, null);
          this.resourcesListTitle = name;
          this.$store.dispatch(GET_MY_OFFICE_AMENITIES_LIST, { 'resource-type': name });
          this.myOffice.points.forEach((point, index) => {
            this.myOffice.points[index].hidden = name !== point.resource.type;
          });
          this.isAsideBlock = true;
        });
      } else {
        this.isAsideBlock = true;
      }
    },
    onSelectPoint(item) {
      this.onCloseAsideBlock();
      this.onCloseAllTooltips();
      this.myOffice.points.forEach((point, index) => {
        if (point.resource.id === item.resource.id) {
          this.$refs['tooltip-item'][index].$emit('open');
        }
      })
    },
    onCloseAsideBlock() {
      this.isAsideBlock = false;
      this.myOffice.points.forEach((point, index) => {
        this.myOffice.points[index].hidden = false;
      })
    },
    setResizedPoint(pointSize) {
      if (this.planSize.width < 600) {
        pointSize = Math.round(this.planSize.width * pointSize / 1000);
        pointSize = pointSize > 5 ? pointSize - 2 : pointSize - 1
      } else if (this.planSize.width > 1200) {
        pointSize = Math.round(this.planSize.width * pointSize / 1000);
        pointSize = pointSize > 20 ? pointSize - 3 : pointSize - 2;
      } else {
        pointSize = Math.round(this.planSize.width * pointSize / 1000);
        pointSize = pointSize > 10 ? pointSize - 2 : pointSize - 1;
      }
      return pointSize;
    },
    onWindowResize() {
      this.hasPlanLoaded = false;
      this.onPlanLoad();
    },
    onCreateReserve() {
      this.isCreateReserve = true;
      this.addedEmployees = [];
      this.addedEmployees.push(this.currentUserAccountInfo);
    },
    onSelectRange(range) {
      this.isEditReserve = true;
      this.currentRange = {
        id: range.id,
        dateRange: {
          // start: range.dateRange.start,
          // end: range.dateRange.end
          start: moment(range.dateRange.start, 'HH:mm').utcOffset(this.myOffice.office.timezone.slice(1, 7)).format('HH:mm'),
          end: moment(range.dateRange.end, 'HH:mm').utcOffset(this.myOffice.office.timezone.slice(1, 7)).format('HH:mm')
        }
      };
    },
    onReserve(item) {
      const id = JSON.parse(localStorage.getItem("floorFilter")).filter_floors;
      if (item.resource.type === 'desk') {
        let pointData = {
          recipient: this.currentUserAccountInfo.id,
          date: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY'),
          resourceId: item.resource.id
        }
        this.$store.dispatch(POST_RESERVE_DESK, { body: pointData })
            .then(() => {
              this.$store.dispatch(GET_BOOKING_DESK_LIST, {
                id: this.myOffice.id,
                params: {
                  status: 'booked', // "canceled" // "finished"
                  date_start: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_start,
                  date_end: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_end
                }
              });
              this.$store.dispatch(GET_DESKS_LIST, { id, amenities: this.selectedAmenities })
                .then((res) => {
                  this.$store.dispatch(GET_MY_OFFICE, {
                    id: this.myOffice.id,
                    params: {
                      date_start: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_start,
                      date_end: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_end
                    }
                  }).then(() => this.$store.dispatch(GET_MY_OFFICE_AMENITIES_LIST_FILTERED, res));
                });
            });
      } else {
        let membersEmails = this.addedEmployees.map(item => item.email);
        let pointData = {
          members: membersEmails,
          dateRange: {
            start: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.currentRange.dateRange.start,
            end: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.currentRange.dateRange.end,
          },
          resourceId: item.resource.id
        }
        this.$store.dispatch(POST_RESERVE_MEETING_ROOM, { body: pointData })
            .then(() => {
              this.addedEmployees = [];
              this.currentRange = {
                dateRange: {
                  start: null,
                  end: null
                }
              };
              this.currentRangeMembers = null;
              this.isCreateReserve = false;
              this.isEditReserve = false;
              this.$store.dispatch(GET_BOOKING_MEETING_ROOM_LIST, {
                id: this.myOffice.id,
                params: {
                  status: 'booked', // "canceled" // "finished"
                  date_start: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_start,
                  date_end: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_end
                }
              });
              this.$store.dispatch(GET_MEETING_ROOMS_LIST, { id, amenities: this.selectedAmenities })
                .then((res) => {
                  this.$store.dispatch(GET_MY_OFFICE, {
                    id: this.myOffice.id,
                    params: {
                      date_start: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_start,
                      date_end: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_end
                    }
                  }).then(() => this.$store.dispatch(GET_MY_OFFICE_AMENITIES_LIST_FILTERED, res));
                });
            })
      }
    },
    onEditReserve() {
      const dateRange = {
        start: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.currentRange.dateRange.start,
        end: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.currentRange.dateRange.end
      }
      const id = JSON.parse(localStorage.getItem("floorFilter")).filter_floors;
      this.$store.dispatch(PUT_RESERVE_MEETING_ROOM_EDIT, { id: this.currentRange.id, body: { dateRange } })
          .then(() => {
            this.addedEmployees = [];
            this.currentRange = {
              dateRange: {
                start: null,
                end: null
              }
            };
            this.currentRangeMembers = null;
            this.isCreateReserve = false;
            this.isEditReserve = false;
            this.$store.dispatch(GET_BOOKING_MEETING_ROOM_LIST, {
              id: this.myOffice.id,
              params: {
                status: 'booked', // "canceled" // "finished"
                date_start: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_start,
                date_end: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_end
              }
            });
            this.$store.dispatch(GET_MEETING_ROOMS_LIST, { id, amenities: this.selectedAmenities })
              .then((res) => {
                this.$store.dispatch(GET_MY_OFFICE, {
                  id: this.myOffice.id,
                  params: {
                    date_start: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_start,
                    date_end: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_end
                  }
                }).then(() => this.$store.dispatch(GET_MY_OFFICE_AMENITIES_LIST_FILTERED, res));
              });
          });
    },
    onCancelReserve(item) {
      const id = JSON.parse(localStorage.getItem("floorFilter")).filter_floors;
      if (item.resource.type === 'desk') {
        let pointsList = this.bookingDeskList.items.filter((bookItem) => {
          if (bookItem.status === 'booked' && bookItem.resource.id === item.resource.id) {
            return bookItem;
          }
        });
        this.$store.dispatch(PUT_RESERVE_DESK_CANCEL, { id: pointsList[0].id })
            .then(() => {
              this.$store.dispatch(GET_BOOKING_DESK_LIST, {
                id: this.myOffice.id,
                params: {
                  status: 'booked', // "canceled" // "finished"
                  date_start: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_start,
                  date_end: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_end
                }
              });
              this.$store.dispatch(GET_DESKS_LIST, { id, amenities: this.selectedAmenities })
                .then((res) => {
                  this.$store.dispatch(GET_MY_OFFICE, {
                    id: this.myOffice.id,
                    params: {
                      date_start: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_start,
                      date_end: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_end
                    }
                  }).then(() => this.$store.dispatch(GET_MY_OFFICE_AMENITIES_LIST_FILTERED, res));
                });
            });
      } else {
        this.$store.dispatch(PUT_RESERVE_MEETING_ROOM_CANCEL, { id: this.currentRange.id })
          .then(() => {
            this.currentRange = {
              dateRange: {
                start: null,
                end: null
              }
            };
            this.currentRangeMembers = null;
            this.isCreateReserve = false;
            this.isEditReserve = false;
            this.$store.dispatch(GET_BOOKING_MEETING_ROOM_LIST, {
              id: this.myOffice.id,
              params: {
                status: 'booked', // "canceled" // "finished"
                date_start: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_start,
                date_end: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_end
              }
            });
            this.$store.dispatch(GET_MEETING_ROOMS_LIST, { id, amenities: this.selectedAmenities })
              .then((res) => {
                this.$store.dispatch(GET_MY_OFFICE, {
                  id: this.myOffice.id,
                  params: {
                    date_start: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_start,
                    date_end: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_end
                  }
                }).then(() => this.$store.dispatch(GET_MY_OFFICE_AMENITIES_LIST_FILTERED, res));
              });
          });
      }
    },
    onSetAmenities(name, type) {
      this.selectedAmenities = type === 'reset' ? this.selectedAmenities = [] : this.selectedAmenities;
      const id = JSON.parse(localStorage.getItem("floorFilter")).filter_floors;
      if (name === 'desks') {
        this.$store.dispatch(GET_DESKS_LIST, { id, amenities: this.selectedAmenities })
          .then((res) => {
            this.$store.dispatch(GET_MY_OFFICE, {
              id: this.myOffice.id,
              params: {
                date_start: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_start,
                date_end: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_end
              }
            }).then(() => {
              if (type === 'apply') {this.$store.dispatch(GET_MY_OFFICE_AMENITIES_LIST_FILTERED, res)}
              if (type === 'reset') {this.$store.dispatch(GET_MY_OFFICE_AMENITIES_LIST_FILTERED, null)}
            });
            this.isAmenitiesFilter = false;
          });
      } else if (name === 'meeting-rooms') {
        this.$store.dispatch(GET_MEETING_ROOMS_LIST, { id, amenities: this.selectedAmenities })
          .then((res) => {
            this.$store.dispatch(GET_MY_OFFICE, {
              id: this.myOffice.id,
              params: {
                date_start: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_start,
                date_end: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.time_end
              }
            }).then(() => {
              if (type === 'apply') {this.$store.dispatch(GET_MY_OFFICE_AMENITIES_LIST_FILTERED, res)}
              if (type === 'reset') {this.$store.dispatch(GET_MY_OFFICE_AMENITIES_LIST_FILTERED, null)}
            });
            this.isAmenitiesFilter = false;
          });
      }
    }
  }
};
</script>

<style lang="scss">
  .tooltip {
    z-index: 1!important;
  }
  .tooltip.b-tooltip {
    opacity: 1!important;
  }
  .my-office {
    margin: -25px;
  }
  .my-office-header {
    z-index: 10;
    width: 100%;
    background-color: #FFFFFF;
  }
  .my-office {
    &-aside-block {
      position: fixed;
      z-index: 11;
      top: calc(52px + 61px);
      bottom: 0;
      right: auto;
      left: 0;
      width: 300px;
      overflow-x: hidden;
      overflow-y: auto;
      background-color: white;
      border-top: 1px solid #E4E5EF;
      &-content {
        //touch-action: pan-y !important;
        &_item {
          border-bottom: 1px solid #E4E5EF;
          &:hover {
            background-color: #F2F6F9;
          }
        }
      }
    }
    .resource-controls {
      position: absolute;
      //z-index: 1;
      top: 15px;
      left: 25px;
    }
    .zoom-controls {
      position: absolute;
      //z-index: 1;
      top: 15px;
      right: 25px;
    }
  }
  .my-office-content {
    width: 100%;
    height: calc(100vh - 52px - 62px);
    position: relative;
    .content-container {
      max-width: 20px!important;
      max-height: 20px!important;
    }
  }
  .box {
    margin: auto;
    position: center;
    display: block;
  }

  .free-workplace-point {
    border-radius: 50%;
    background-color: #00C952;
    max-height: 20px;
    max-width: 20px;
  }
  .free-meeting-point {
    border-radius: 50%;
    background-color: #00C952;
    max-height: 20px;
    max-width: 20px;
    //border: 1px solid #fff;
    //box-shadow: 0 0 0 1px #00C952;
    box-shadow: inset 0 0 0 1px #00C952, inset 0 0 0 2px #fff;
  }
  .reserved-workplace-point {
    border-radius: 50%;
    background-color: #F7C247;
    max-height: 20px;
    max-width: 20px;
  }
  .reserved-meeting-point {
    border-radius: 50%;
    background-color: #F7C247;
    max-height: 20px;
    max-width: 20px;
    box-shadow: inset 0 0 0 1px #F7C247, inset 0 0 0 2px #fff;
  }
  .busy-workplace-point {
    border-radius: 50%;
    background-color: #7F809A;
    max-height: 20px;
    max-width: 20px;
    //&:before {
    //  font-family: 'Font Awesome 5 Free';
    //  font-weight: 900;
    //  content: "\f007";
    //  color: #B6B4C3;
    //  padding: 3px 0;
    //  display: inline-block;
    //}
  }
  .busy-meeting-point {
    border-radius: 50%;
    background-color: #7F809A;
    max-height: 20px;
    max-width: 20px;
    box-shadow: inset 0 0 0 1px #7F809A, inset 0 0 0 2px #fff;
    //&:before {
    //  font-family: 'Font Awesome 5 Free';
    //  font-weight: 900;
    //  content: "\f007";
    //  color: #B6B4C3;
    //  padding: 2px 0;
    //  display: inline-block;
    //}
  }
  .unavailable-workplace-point {
    border-radius: 50%;
    background-color: #B5B6C3;
    max-height: 20px;
    max-width: 20px;
  }
  .unavailable-meeting-point {
    border-radius: 50%;
    background-color: #B5B6C3;
    max-height: 20px;
    max-width: 20px;
    box-shadow: inset 0 0 0 1px #B5B6C3, inset 0 0 0 2px #fff;
  }

  .tooltip-item-block {
    min-width: 200px;
    &-dot {
      display: inline-block;
      width: 4px;
      height: 4px;
      margin-bottom: 1px;
      border-radius: 50%;
      background-color: #8687A0;
    }
  }

  .points-control-block {
    position: absolute;
    bottom: 25px;
    right: 25px;
  }
  .tooltip-inner {
    max-width: 600px!important;
  }
  .demo {
    width: 20px!important;
    height: 20px!important;
  }
  .btn-shadow, .btn-group-shadow {
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.1);
  }
  .select-custom, .time-select-custom {
    width: 100%;
    min-width: 120px;
    max-width: 150px;
    cursor: pointer;

    .form-control {
      cursor: pointer;
    }
  }
  .icon-custom {
    color: #B6B4C3!important;
  }
  .time-select-custom {
    .dropdown-toggle {
      padding: 0 0;
      background-color: #FFFFFF;
      border-color: #FFFFFF;
    }
    .btn.btn-secondary:not(.btn-text):not(:disabled):not(.disabled),
    .btn.btn-secondary:hover:not(.btn-text):not(:disabled):not(.disabled),
    .btn.btn-secondary:focus:not(.btn-text),
    .btn.btn-secondary.focus:not(.btn-text) {
      background-color: #FFFFFF;
      border-color: #FFFFFF;
    }
  }
  .btn.btn-secondary i,
  .btn.btn-secondary.dropdown-toggle i {
    color: #B5B5C3!important;
  }
  .dropdown-toggle.btn:after {
    display: none!important;
  }

  .my-office-amenities-block {
    margin: 16% 0 0 4%;
    padding: 10px 20px;
    position: absolute;
    z-index: 100;
    width: 270px;
    min-height: 100px;
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.2);
  }

  .selected-employees {
    max-height: 250px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  @media (max-width: 991.98px) {
    .my-office {
      margin: -15px;
    }
  }

  @media screen and (min-width: 991.98px) {
    .mobile-block {
      display: none !important;
    }
  }

  @media screen and (max-width: 991.97px) {
    .my-office-header {
      padding-bottom: 4rem !important;
    }
    .points-control-block {
      bottom: 90px;
    }
    .web-block-picks-search,
    .web-block-filter {
      display: none !important;
    }
    .mobile-block {
      min-width: 100% !important;
      display: block !important;
      position: relative;
      z-index: 1 !important;
    }
  }

  @media screen and (min-width: 1200px) {
    .my-office-aside-block {
      top: calc(52px + 65px)
    }
  }

  @media screen and (max-width: 600px) {
    .tooltip-item-block {
      width: 200px !important;
      .float-right {
        position: absolute;
        right: 20px;
      }
    }
    .points-control-block {
      bottom: 60px;
    }
    .free-meeting-point {
      box-shadow: inset 0 0 0 0.04rem #00C952, inset 0 0 0 0.08rem #fff;
    }
    .reserved-meeting-point {
      box-shadow: inset 0 0 0 0.04rem #F7C247, inset 0 0 0 0.08rem #fff;
    }
    .busy-meeting-point {
      box-shadow: inset 0 0 0 0.04rem #7F809A, inset 0 0 0 0.08rem #fff;
    }
    .unavailable-meeting-point {
      box-shadow: inset 0 0 0 0.04rem #B5B6C3, inset 0 0 0 0.08rem #fff;
    }
  }
</style>
