<template>
  <div class="filter-list float-left">
    <treeselect :value="selectedCountry"
                @input="countryChange"
                class="filter-list-item"
                :placeholder="$t('SELECT.COUNTRY_PLACEHOLDER')"
                :multiple="false"
                :normalizer="normalizer"
                :clearValueText="$t('SELECT.CLEAR_FIELD')"
                :options="countryCityList">
      <div slot="value-label" slot-scope="{ node }">{{ node.raw.country }}</div>
    </treeselect>
    <treeselect :value="selectedCity"
                @input="cityChange"
                class="filter-list-item"
                :placeholder="$t('SELECT.CITY_PLACEHOLDER')"
                :disabled="!isSelectedCity"
                :multiple="false"
                :normalizer="normalizer2"
                :clearValueText="$t('SELECT.CLEAR_FIELD')"
                :options="citiesList">
      <div slot="value-label" slot-scope="{ node }">{{ node.raw.name }}</div>
    </treeselect>
    <treeselect :value="selectedOffice"
                @input="officeChange"
                class="filter-list-item"
                :placeholder="$t('SELECT.OFFICE_PLACEHOLDER')"
                :disabled="!isSelectedOffice"
                :multiple="false"
                :normalizer="normalizer2"
                :clearValueText="$t('SELECT.CLEAR_FIELD')"
                :options="officesList">
      <div slot="value-label" slot-scope="{ node }">{{ node.raw.name }}</div>
    </treeselect>
    <treeselect :value="selectedNewFloor"
                @input="floorChange"
                class="filter-list-item"
                :placeholder="$t('SELECT.FLOOR_PLACEHOLDER')"
                :disabled="!isSelectedFloor"
                :multiple="false"
                :normalizer="normalizer2"
                :clearValueText="$t('SELECT.CLEAR_FIELD')"
                :options="floorsList">
      <div slot="value-label" slot-scope="{ node }">{{ node.raw.name }}</div>
    </treeselect>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from "vuex";
import {
  GET_COUNTRY_CITY_LIST,
  GET_FLOOR, GET_OCCUPANCY_LIMIT
} from "@/core/services/store/management.module";
import {
  GET_AVAILABLE_RESOURCES,
  GET_BOOKING_DESK_LIST,
  GET_BOOKING_MEETING_ROOM_LIST,
  GET_MY_OFFICE, SELECT_NEW_CITY, SELECT_NEW_COUNTRY, SELECT_NEW_FLOOR, SELECT_NEW_OFFICE, SET_SELECTED_FLOOR
} from "@/core/services/store/myoffice/index";
import {GET_SIGUR_ACCESS_POINTS_LIST} from "@/core/services/store/management/integrations.module";
import {sendAmplitudeEvent} from "@/core/services/amplitude.service";

export default {
  name: "MainSelect",
  mounted() {
    this.$store.dispatch(GET_COUNTRY_CITY_LIST, { params: { is_use:  1 } });
    if (localStorage.getItem("floorFilter") !== null && this.$route.name === 'my-office') {
      this.isSelectedCity = true;
      this.isSelectedOffice = true;
      this.isSelectedFloor = true;
      setTimeout(() => {
        let retrievedObject = localStorage.getItem("floorFilter");
        this.$store.dispatch(SELECT_NEW_COUNTRY, { id: JSON.parse(retrievedObject).filter_country });
        this.$store.dispatch(SELECT_NEW_CITY, { id: JSON.parse(retrievedObject).filter_cities });
        this.$store.dispatch(SELECT_NEW_OFFICE, { id: JSON.parse(retrievedObject).filter_offices });
        this.$store.dispatch(SELECT_NEW_FLOOR, { id: JSON.parse(retrievedObject).filter_floors })
        this.$store.commit(SET_SELECTED_FLOOR);
        this.$store.dispatch(GET_BOOKING_DESK_LIST, {
          id: this.selectedNewFloor,
          params: {
            status: 'booked', // "canceled" // "finished"
            date_start: moment(this.currentDate, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.startTime,
            date_end: moment(this.currentDate, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.endTime
          }
        });
        this.$store.dispatch(GET_BOOKING_MEETING_ROOM_LIST, {
          id: this.selectedNewFloor,
          params: {
            status: 'booked', // "canceled" // "finished"
            date_start: moment(this.currentDate, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.startTime,
            date_end: moment(this.currentDate, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.endTime
          }
        });
        this.$store.dispatch(GET_MY_OFFICE, {
          id: this.selectedNewFloor,
          params: {
            date_start: moment(this.currentDate, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.startTime,
            date_end: moment(this.currentDate, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.endTime
          }
        }).then(() => this.$emit('setSelectedOffice', true))
      }, 1000)
    }
    else if (localStorage.getItem("floorFilter") !== null && this.$route.name === 'booking-calendar') {
      this.isSelectedCity = true;
      this.isSelectedOffice = true;
      this.isSelectedFloor = true;
      setTimeout(() => {
        let retrievedObject = localStorage.getItem("floorFilter");
        this.$store.dispatch(SELECT_NEW_COUNTRY, { id: JSON.parse(retrievedObject).filter_country });
        this.$store.dispatch(SELECT_NEW_CITY, { id: JSON.parse(retrievedObject).filter_cities });
        this.$store.dispatch(SELECT_NEW_OFFICE, { id: JSON.parse(retrievedObject).filter_offices });
        this.$store.dispatch(SELECT_NEW_FLOOR, { id: JSON.parse(retrievedObject).filter_floors });
        this.$store.commit(SET_SELECTED_FLOOR);
        this.$emit('setSelectedOffice', true)
        this.$emit('setFloor', JSON.parse(retrievedObject).filter_floors);
      }, 1000)
    }
    else if (this.$route.name === 'floor-plan-editor' && this.selectedFloor) {
      this.isSelectedCity = true;
      this.isSelectedOffice = true;
      this.isSelectedFloor = true;
      setTimeout(() => {
        this.$store.dispatch(SELECT_NEW_COUNTRY, { id: this.selectedFloor.office.address.country.id });
        this.$store.dispatch(SELECT_NEW_CITY, { id: this.selectedFloor.office.address.city.id });
        this.$store.dispatch(SELECT_NEW_OFFICE, { id: this.selectedFloor.office.id });
        this.$store.dispatch(SELECT_NEW_FLOOR, { id: this.selectedFloor.id })
      }, 1000)
    }
    else if (this.$route.name === 'sigur' && this.selectedFloor && localStorage.getItem("floorFilter") !== null) {
      this.isSelectedCity = true;
      this.isSelectedOffice = true;
      this.isSelectedFloor = true;
      setTimeout(() => {
        let retrievedObject = localStorage.getItem("floorFilter");
        this.$store.dispatch(SELECT_NEW_COUNTRY, { id: JSON.parse(retrievedObject).filter_country });
        this.$store.dispatch(SELECT_NEW_CITY, { id: JSON.parse(retrievedObject).filter_cities });
        this.$store.dispatch(SELECT_NEW_OFFICE, { id: JSON.parse(retrievedObject).filter_offices });
        this.$store.dispatch(SELECT_NEW_FLOOR, { id: JSON.parse(retrievedObject).filter_floors })
        // this.$store.commit(SET_SELECTED_FLOOR);
        this.$emit('changeNotFound', JSON.parse(retrievedObject).filter_floors.length > 0);
        this.$store.dispatch(GET_SIGUR_ACCESS_POINTS_LIST, { floor_id: JSON.parse(retrievedObject).filter_floors });
      }, 1000)
    }
  },
  props: {
    currentDate: null,
    startTime: {
      type: String
    },
    endTime: {
      type: String
    },
    selectedFloor: {
      type: Object
    }
  },
  data() {
    return {
      isSelectedCity: false,
      isSelectedOffice: false,
      isSelectedFloor: false,
      normalizer(node) {
        return {
          id: node.id,
          label: node.country,
        }
      },
      normalizer2(node) {
        return {
          id: node.id,
          label: node.name,
        }
      },
    }
  },
  computed: {
    ...mapGetters([
      "countryCityList",
      "citiesList",
      "floorsList",
      "officesList",
      "selectedCountry",
      "selectedCity",
      "selectedOffice",
      "selectedNewFloor"
    ])
  },
  methods: {
    countryChange(val) {
      this.isSelectedCity = val !== undefined;
      this.$store.dispatch(SELECT_NEW_COUNTRY, { id: val, page: this.$route.name === 'floor-plan-editor' ? 'planEdit' : null });
    },
    cityChange(val) {
      this.isSelectedOffice = val !== undefined;
      this.$store.dispatch(SELECT_NEW_CITY, { id: val, page: this.$route.name === 'floor-plan-editor' ? 'planEdit' : null });
    },
    officeChange(val) {
      this.isSelectedFloor = val !== undefined;
      this.$store.dispatch(SELECT_NEW_OFFICE, { id: val, page: this.$route.name === 'floor-plan-editor' ? 'planEdit' : null })
    },
    floorChange(val) {
      if (val === undefined || val === null) {
        this.selectedNewFloor = null;
        if (this.$route.name === 'sigur') this.$store.dispatch(GET_SIGUR_ACCESS_POINTS_LIST);
        localStorage.removeItem("floorFilter");
      } else {
        let filter = {
          'filter_country': this.selectedCountry,
          'filter_cities': this.selectedCity,
          'filter_offices': this.selectedOffice,
          'filter_floors': val
        }
        this.$store.dispatch(SELECT_NEW_FLOOR, { id: val });
        localStorage.setItem("floorFilter", JSON.stringify(filter));

        if (this.$route.name === 'booking-calendar') {
          sendAmplitudeEvent('booking-calendar change');
          this.$emit('setSelectedOffice', true);
          this.$emit('setFloor', val);
        }

        if (this.$route.name === 'floor-plan-editor') {
          this.$store.dispatch(GET_FLOOR, val)
              .then(() => {
                this.$emit('setSelectedOffice');
                this.$store.dispatch(GET_OCCUPANCY_LIMIT, val);
              })
              .then(() => this.$router.push({ name: 'floor-plan-editor', params: { id: val } }));
        }

        if (this.$route.name === 'my-office') {
          sendAmplitudeEvent('my-office-plan change');
          // this.$store.dispatch(GET_DESKS_LIST, { id: val, statuses: 'active' });
          // this.$store.dispatch(GET_MEETING_ROOMS_LIST, { id, val, statuses: 'active' });
          this.$store.dispatch(GET_BOOKING_DESK_LIST, {
            id: val,
            params: {
              status: 'booked', // "canceled" // "finished"
              date_start: moment(this.currentDate, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.startTime,
              date_end: moment(this.currentDate, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.endTime
            }
          });
          this.$store.dispatch(GET_BOOKING_MEETING_ROOM_LIST, {
            id: val,
            params: {
              status: 'booked', // "canceled" // "finished"
              date_start: moment(this.currentDate, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.startTime,
              date_end: moment(this.currentDate, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.endTime
            }
          });
          this.$store.dispatch(GET_MY_OFFICE, {
            id: val,
            params: {
              date_start: moment(this.currentDate, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.startTime,
              date_end: moment(this.currentDate, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.endTime
            }
          }).then(() => this.$emit('setSelectedOffice', true));
          this.$store.dispatch(GET_AVAILABLE_RESOURCES, {
            id: val,
            params: {
              name: '',
              date_start: moment(this.currentDate, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.startTime,
              date_end: moment(this.currentDate, 'DD.MM.YYYY').format('DD.MM.YYYY') + " " + this.endTime
            }
          });
        }

        if (this.$route.name === 'sigur') {
          this.$emit('changeNotFound', val.length > 0);
          this.$store.dispatch(GET_SIGUR_ACCESS_POINTS_LIST, { floor_id: val });
        }
      }
    }
  }
}
</script>

<style lang="scss">
.filter-list {
  width: auto;
  display: flex;
  cursor: pointer;
  &-item {
    flex: 1 0 25%;
    display: inline-block;
    margin-left: -2px;
    .vue-treeselect__control {
      background-color: #FFFFFF;
      border-radius: 0;
      cursor: pointer;
    }
    &:first-child {
      .vue-treeselect__control {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }
    &:nth-last-child(1) {
      .vue-treeselect__control {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
}
.vue-treeselect--disabled {
  .vue-treeselect__control {
    background-color: #F2F6F9;
  }
}
</style>
