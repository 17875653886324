<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="335.603" height="300" viewBox="0 0 335.603 300">
    <defs>
      <clipPath id="clip-path">
        <path id="Контур_4275" data-name="Контур 4275" d="M160.316-98.411v7.717L.787-183.52v-7.716L160.316-98.411" transform="translate(-0.787 191.236)" fill="#c1c6d5" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2">
        <path id="Контур_4274" data-name="Контур 4274" d="M-92-286.784H330.216V-709H-92Z" transform="translate(92 709)" fill="#c1c6d5"/>
      </clipPath>
      <clipPath id="clip-path-3">
        <path id="Контур_4278" data-name="Контур 4278" d="M479.131-209.514v7.715L303.057-99.326v-7.717L479.131-209.514" transform="translate(-303.057 209.514)" fill="#c1c6d5" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-5">
        <path id="Контур_4281" data-name="Контур 4281" d="M307.024-562.816,265.45-538.758v98.195l41.573-24.824v-97.429" transform="translate(-265.45 562.816)" fill="#d9deea" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-6">
        <path id="Контур_4280" data-name="Контур 4280" d="M-92-286.784H330.216V-709H-92Z" transform="translate(92 709)" fill="#d9deea"/>
      </clipPath>
      <clipPath id="clip-path-7">
        <path id="Контур_4284" data-name="Контур 4284" d="M260.28-520.241,254.5-523.6v98.2l5.779,3.362v-98.195" transform="translate(-254.502 523.604)" fill="#c1c6d5" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-9">
        <path id="Контур_4287" data-name="Контур 4287" d="M301.854-565.814l-5.8-3.35L254.5-545.119l5.778,3.363,41.574-24.058" transform="translate(-254.502 569.164)" fill="#ced3e1" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-10">
        <path id="Контур_4286" data-name="Контур 4286" d="M-92-286.784H330.216V-709H-92Z" transform="translate(92 709)" fill="#ced3e1"/>
      </clipPath>
      <clipPath id="clip-path-11">
        <path id="Контур_4290" data-name="Контур 4290" d="M333.234-556.471,487.6-468.186v98.195L333.234-459.042v-97.429" transform="translate(-333.234 556.471)" fill="#ced3e1" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-13">
        <path id="Контур_4293" data-name="Контур 4293" d="M625.727-392.2l5.778-3.362v98.195L625.727-294V-392.2" transform="translate(-625.727 395.562)" fill="#c1c6d5" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-15">
        <path id="Контур_4296" data-name="Контур 4296" d="M333.234-559.468l5.8-3.35,154.347,88.273-5.778,3.362L333.234-559.468" transform="translate(-333.234 562.818)" fill="#ced3e1" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-17">
        <path id="Контур_4299" data-name="Контур 4299" d="M212.648-406.262v-89.89l21.618-12.477v89.889l-21.618,12.478" transform="translate(-212.648 508.629)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-18">
        <path id="Контур_4298" data-name="Контур 4298" d="M-92-286.784H330.216V-709H-92Z" transform="translate(92 709)" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-19">
        <path id="Контур_4302" data-name="Контур 4302" d="M206.187-308.149v2.762l-2.4-1.4v-2.763l2.4,1.4" transform="translate(-203.783 309.552)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-21">
        <path id="Контур_4305" data-name="Контур 4305" d="M203.783-324.3l2.4,1.4,28.574-16.482-2.416-1.406L203.783-324.3" transform="translate(-203.783 340.788)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-23">
        <path id="Контур_4308" data-name="Контур 4308" d="M208.338-321.641v2.762l28.574-16.492v-2.752l-28.574,16.482" transform="translate(-208.338 338.123)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-25">
        <path id="Контур_4311" data-name="Контур 4311" d="M256.016-512.478V-419.8l-2.407-1.4v-92.679l2.407,1.4" transform="translate(-253.609 513.879)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-27">
        <path id="Контур_4314" data-name="Контур 4314" d="M253.609-515.057l2.407,1.4,2.274-1.313-2.419-1.4-2.263,1.316" transform="translate(-253.609 516.373)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-29">
        <path id="Контур_4317" data-name="Контур 4317" d="M258.17-512.4v92.678l2.274-1.324v-92.667L258.17-512.4" transform="translate(-258.17 513.712)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-31">
        <path id="Контур_4320" data-name="Контур 4320" d="M206.185-483.753v92.677l-2.407-1.4v-92.677l2.407,1.4" transform="translate(-203.778 485.154)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-33">
        <path id="Контур_4323" data-name="Контур 4323" d="M203.778-486.333l2.407,1.4,2.274-1.313-2.419-1.4-2.263,1.317" transform="translate(-203.778 487.65)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-35">
        <path id="Контур_4326" data-name="Контур 4326" d="M208.339-483.675V-391l2.274-1.324v-92.667l-2.274,1.313" transform="translate(-208.339 484.988)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-37">
        <path id="Контур_4329" data-name="Контур 4329" d="M206.187-488.988v2.762l-2.4-1.4v-2.763l2.4,1.4" transform="translate(-203.783 490.391)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-39">
        <path id="Контур_4332" data-name="Контур 4332" d="M203.783-505.142l2.4,1.4,28.574-16.483-2.416-1.406-28.562,16.485" transform="translate(-203.783 521.627)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-41">
        <path id="Контур_4335" data-name="Контур 4335" d="M208.338-502.48v2.762l28.574-16.492v-2.752L208.338-502.48" transform="translate(-208.338 518.963)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-43">
        <path id="Контур_4338" data-name="Контур 4338" d="M212.648-366.869v2.792l24.025-13.858-2.416-1.406-21.61,12.473" transform="translate(-212.648 379.342)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-45">
        <path id="Контур_4341" data-name="Контур 4341" d="M212.648-362.819v2.761l24.025-13.867v-2.752l-24.025,13.858" transform="translate(-212.648 376.677)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-47">
        <path id="Контур_4344" data-name="Контур 4344" d="M212.648-483.18v2.792l24.025-13.858-2.416-1.406-21.61,12.472" transform="translate(-212.648 495.652)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-49">
        <path id="Контур_4347" data-name="Контур 4347" d="M212.648-479.129v2.761l24.025-13.867v-2.752l-24.025,13.858" transform="translate(-212.648 492.987)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-51">
        <path id="Контур_4350" data-name="Контур 4350" d="M162.816-377.488v-89.89l21.618-12.476v89.889l-21.618,12.478" transform="translate(-162.816 479.854)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-53">
        <path id="Контур_4353" data-name="Контур 4353" d="M156.356-279.375v2.762l-2.405-1.4v-2.762l2.405,1.4" transform="translate(-153.951 280.777)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-55">
        <path id="Контур_4356" data-name="Контур 4356" d="M153.951-295.528l2.405,1.4,28.573-16.482-2.416-1.406-28.562,16.486" transform="translate(-153.951 312.014)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-57">
        <path id="Контур_4359" data-name="Контур 4359" d="M158.508-292.868v2.762L187.081-306.6v-2.752l-28.573,16.482" transform="translate(-158.508 309.35)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-59">
        <path id="Контур_4362" data-name="Контур 4362" d="M206.185-483.7v92.677l-2.407-1.4V-485.1l2.407,1.4" transform="translate(-203.778 485.104)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-61">
        <path id="Контур_4365" data-name="Контур 4365" d="M203.778-486.283l2.407,1.4,2.274-1.313-2.419-1.405-2.263,1.317" transform="translate(-203.778 487.6)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-63">
        <path id="Контур_4368" data-name="Контур 4368" d="M208.339-483.624v92.677l2.274-1.323v-92.667l-2.274,1.313" transform="translate(-208.339 484.937)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-65">
        <path id="Контур_4371" data-name="Контур 4371" d="M156.354-454.98V-362.3l-2.407-1.4v-92.678l2.407,1.4" transform="translate(-153.947 456.381)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-67">
        <path id="Контур_4374" data-name="Контур 4374" d="M153.947-457.56l2.407,1.4,2.274-1.313-2.418-1.4-2.263,1.317" transform="translate(-153.947 458.877)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-69">
        <path id="Контур_4377" data-name="Контур 4377" d="M158.508-454.9v92.678l2.274-1.324v-92.667l-2.274,1.313" transform="translate(-158.508 456.215)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-71">
        <path id="Контур_4380" data-name="Контур 4380" d="M156.356-460.214v2.762l-2.405-1.4v-2.763l2.405,1.4" transform="translate(-153.951 461.617)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-73">
        <path id="Контур_4383" data-name="Контур 4383" d="M153.951-476.368l2.405,1.4,28.573-16.482-2.416-1.407-28.562,16.486" transform="translate(-153.951 492.854)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-75">
        <path id="Контур_4386" data-name="Контур 4386" d="M158.508-473.706v2.762l28.573-16.493v-2.751l-28.573,16.482" transform="translate(-158.508 490.188)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-77">
        <path id="Контур_4389" data-name="Контур 4389" d="M162.816-338.1v2.792l24.026-13.858-2.416-1.406L162.816-338.1" transform="translate(-162.816 350.568)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-79">
        <path id="Контур_4392" data-name="Контур 4392" d="M162.816-334.045v2.761l24.026-13.867V-347.9l-24.026,13.858" transform="translate(-162.816 347.903)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-81">
        <path id="Контур_4395" data-name="Контур 4395" d="M162.816-454.4v2.791l24.026-13.858-2.416-1.406L162.816-454.4" transform="translate(-162.816 466.878)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-83">
        <path id="Контур_4398" data-name="Контур 4398" d="M162.816-450.355v2.76l24.026-13.867v-2.752l-24.026,13.858" transform="translate(-162.816 464.213)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-85">
        <path id="Контур_4401" data-name="Контур 4401" d="M112.984-348.73v-89.89L134.6-451.1v89.89l-21.1,12.176-.524.3" transform="translate(-112.984 451.097)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-87">
        <path id="Контур_4404" data-name="Контур 4404" d="M153.947-361.207v0" transform="translate(-153.947 451.097)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-88">
        <path id="Контур_4403" data-name="Контур 4403" d="M-92-286.784H708V-709H-92Z" transform="translate(92 709)" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-89">
        <path id="Контур_4407" data-name="Контур 4407" d="M106.525-250.616v2.762l-2.4-1.4v-2.763l2.4,1.4" transform="translate(-104.12 252.019)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-91">
        <path id="Контур_4410" data-name="Контур 4410" d="M104.12-266.77l2.4,1.4L135.1-281.849l-2.416-1.406L104.12-266.77" transform="translate(-104.12 283.255)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-93">
        <path id="Контур_4413" data-name="Контур 4413" d="M108.676-264.109v2.762l28.573-16.493v-2.752l-28.573,16.482" transform="translate(-108.676 280.591)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-95">
        <path id="Контур_4416" data-name="Контур 4416" d="M156.354-454.945v92.678l-2.407-1.4v-92.678l2.407,1.4" transform="translate(-153.947 456.346)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-97">
        <path id="Контур_4419" data-name="Контур 4419" d="M153.947-457.524l2.407,1.4,2.274-1.314-2.418-1.4-2.263,1.316" transform="translate(-153.947 458.84)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-99">
        <path id="Контур_4422" data-name="Контур 4422" d="M158.508-454.866v92.678l2.274-1.324V-456.18l-2.274,1.314" transform="translate(-158.508 456.18)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-101">
        <path id="Контур_4425" data-name="Контур 4425" d="M106.522-426.221v92.678l-2.407-1.4v-92.678l2.407,1.4" transform="translate(-104.115 427.622)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-103">
        <path id="Контур_4428" data-name="Контур 4428" d="M104.115-428.8l2.407,1.4,2.274-1.313-2.418-1.4-2.263,1.317" transform="translate(-104.115 430.117)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-105">
        <path id="Контур_4431" data-name="Контур 4431" d="M108.676-426.143v92.678l2.274-1.324v-92.667l-2.274,1.313" transform="translate(-108.676 427.456)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-107">
        <path id="Контур_4434" data-name="Контур 4434" d="M106.525-431.455v2.762l-2.4-1.4v-2.762l2.4,1.4" transform="translate(-104.12 432.857)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-109">
        <path id="Контур_4437" data-name="Контур 4437" d="M104.12-447.608l2.4,1.4L135.1-462.688l-2.416-1.406L104.12-447.608" transform="translate(-104.12 464.094)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-111">
        <path id="Контур_4440" data-name="Контур 4440" d="M108.676-444.948v2.762l28.573-16.492v-2.752l-28.573,16.482" transform="translate(-108.676 461.43)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-113">
        <path id="Контур_4443" data-name="Контур 4443" d="M112.984-309.336v2.791L137.01-320.4l-2.416-1.406-21.61,12.473" transform="translate(-112.984 321.809)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-115">
        <path id="Контур_4446" data-name="Контур 4446" d="M112.984-305.287v2.761l24.026-13.868v-2.752l-24.026,13.858" transform="translate(-112.984 319.145)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-117">
        <path id="Контур_4449" data-name="Контур 4449" d="M112.984-425.646v2.792l24.026-13.859-2.416-1.406-21.61,12.473" transform="translate(-112.984 438.119)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-119">
        <path id="Контур_4452" data-name="Контур 4452" d="M112.984-421.6v2.76L137.01-432.7v-2.752L112.984-421.6" transform="translate(-112.984 435.455)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-121">
        <path id="Контур_4455" data-name="Контур 4455" d="M63.153-319.986v-89.89l21.618-12.476v89.889l-21.51,12.415-.109.062" transform="translate(-63.153 422.352)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-123">
        <path id="Контур_4458" data-name="Контур 4458" d="M56.693-221.872v2.762l-2.4-1.4v-2.763l2.4,1.4" transform="translate(-54.288 223.275)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-125">
        <path id="Контур_4461" data-name="Контур 4461" d="M54.288-238.026l2.4,1.4,28.573-16.483-2.416-1.406L54.288-238.026" transform="translate(-54.288 254.512)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-127">
        <path id="Контур_4464" data-name="Контур 4464" d="M58.844-235.365v2.762L87.417-249.1v-2.752L58.844-235.365" transform="translate(-58.844 251.848)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-129">
        <path id="Контур_4467" data-name="Контур 4467" d="M106.522-426.2v92.677l-2.407-1.4V-427.6l2.407,1.4" transform="translate(-104.115 427.602)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-131">
        <path id="Контур_4470" data-name="Контур 4470" d="M104.115-428.78l2.407,1.4,2.274-1.314-2.418-1.4-2.263,1.317" transform="translate(-104.115 430.097)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-133">
        <path id="Контур_4473" data-name="Контур 4473" d="M108.676-426.122v92.677l2.274-1.323v-92.668l-2.274,1.314" transform="translate(-108.676 427.436)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-135">
        <path id="Контур_4476" data-name="Контур 4476" d="M56.691-397.478V-304.8l-2.408-1.4v-92.678l2.408,1.4" transform="translate(-54.283 398.879)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-137">
        <path id="Контур_4479" data-name="Контур 4479" d="M54.283-400.057l2.408,1.4,2.274-1.314-2.418-1.4-2.263,1.316" transform="translate(-54.283 401.373)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-139">
        <path id="Контур_4482" data-name="Контур 4482" d="M58.845-397.4v92.678l2.274-1.324v-92.668L58.845-397.4" transform="translate(-58.845 398.713)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-141">
        <path id="Контур_4485" data-name="Контур 4485" d="M56.693-402.711v2.762l-2.4-1.4v-2.762l2.4,1.4" transform="translate(-54.288 404.113)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-143">
        <path id="Контур_4488" data-name="Контур 4488" d="M54.288-418.865l2.4,1.4,28.573-16.482-2.416-1.406L54.288-418.865" transform="translate(-54.288 435.351)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-145">
        <path id="Контур_4491" data-name="Контур 4491" d="M58.844-416.2v2.762l28.573-16.492v-2.752L58.844-416.2" transform="translate(-58.844 432.686)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-147">
        <path id="Контур_4494" data-name="Контур 4494" d="M63.153-280.592v2.792l24.026-13.858-2.416-1.406-21.61,12.472" transform="translate(-63.153 293.064)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-149">
        <path id="Контур_4497" data-name="Контур 4497" d="M63.153-276.542v2.761l24.026-13.867V-290.4L63.153-276.542" transform="translate(-63.153 290.4)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-151">
        <path id="Контур_4500" data-name="Контур 4500" d="M63.153-396.9v2.792l24.026-13.859-2.416-1.406L63.153-396.9" transform="translate(-63.153 409.375)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-153">
        <path id="Контур_4503" data-name="Контур 4503" d="M63.153-392.852v2.76l24.026-13.866v-2.752L63.153-392.852" transform="translate(-63.153 406.711)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-155">
        <path id="Контур_4506" data-name="Контур 4506" d="M13.322-291.22v-89.89L34.94-393.586v89.908L13.322-291.22" transform="translate(-13.322 393.586)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-157">
        <path id="Контур_4509" data-name="Контур 4509" d="M6.861-193.107v2.763l-2.4-1.4v-2.763l2.4,1.4" transform="translate(-4.457 194.509)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-159">
        <path id="Контур_4512" data-name="Контур 4512" d="M4.457-209.26l2.4,1.4,28.574-16.482-2.416-1.407L4.457-209.26" transform="translate(-4.457 225.746)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-161">
        <path id="Контур_4515" data-name="Контур 4515" d="M9.012-206.6v2.763l28.574-16.493v-2.752L9.012-206.6" transform="translate(-9.012 223.081)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-163">
        <path id="Контур_4518" data-name="Контур 4518" d="M56.691-397.435v92.678l-2.408-1.4v-92.678l2.408,1.4" transform="translate(-54.283 398.836)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-165">
        <path id="Контур_4521" data-name="Контур 4521" d="M54.283-400.014l2.408,1.4,2.274-1.313-2.418-1.4-2.263,1.317" transform="translate(-54.283 401.331)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-167">
        <path id="Контур_4524" data-name="Контур 4524" d="M58.845-397.357v92.678L61.119-306V-398.67l-2.274,1.313" transform="translate(-58.845 398.67)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-169">
        <path id="Контур_4527" data-name="Контур 4527" d="M6.86-368.712v92.678l-2.407-1.4v-92.678l2.407,1.4" transform="translate(-4.453 370.113)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-171">
        <path id="Контур_4530" data-name="Контур 4530" d="M4.453-371.291l2.407,1.4L9.134-371.2l-2.418-1.4-2.263,1.316" transform="translate(-4.453 372.607)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-173">
        <path id="Контур_4533" data-name="Контур 4533" d="M9.013-368.634v92.678l2.274-1.324v-92.668l-2.274,1.313" transform="translate(-9.013 369.947)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-175">
        <path id="Контур_4536" data-name="Контур 4536" d="M6.861-373.945v2.762l-2.4-1.4v-2.763l2.4,1.4" transform="translate(-4.457 375.348)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-177">
        <path id="Контур_4539" data-name="Контур 4539" d="M4.457-390.1l2.4,1.4,28.574-16.482-2.416-1.406L4.457-390.1" transform="translate(-4.457 406.585)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-179">
        <path id="Контур_4542" data-name="Контур 4542" d="M9.012-387.438v2.762l28.574-16.492v-2.752L9.012-387.438" transform="translate(-9.012 403.92)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-181">
        <path id="Контур_4545" data-name="Контур 4545" d="M13.322-251.827v2.792l24.026-13.858L34.932-264.3l-21.61,12.473" transform="translate(-13.322 264.3)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-183">
        <path id="Контур_4548" data-name="Контур 4548" d="M13.322-247.777v2.761l24.026-13.867v-2.752L13.322-247.777" transform="translate(-13.322 261.635)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-185">
        <path id="Контур_4551" data-name="Контур 4551" d="M13.322-368.137v2.792L37.348-379.2l-2.416-1.406-21.61,12.472" transform="translate(-13.322 380.609)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-187">
        <path id="Контур_4554" data-name="Контур 4554" d="M13.322-364.087v2.761l24.026-13.867v-2.752L13.322-364.087" transform="translate(-13.322 377.945)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-189">
        <path id="Контур_4557" data-name="Контур 4557" d="M90.673-218.278,39.879-247.972l117.67-69.29L208.13-287.63,90.673-218.278" transform="translate(-39.879 317.262)" fill="#d1d4de" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-190">
        <path id="Контур_4556" data-name="Контур 4556" d="M-92-286.784H330.216V-709H-92Z" transform="translate(92 709)" fill="#d1d4de"/>
      </clipPath>
      <clipPath id="clip-path-191">
        <path id="Контур_4560" data-name="Контур 4560" d="M248.148-126.544l-50.794-29.694,117.671-69.29L365.6-195.9,248.148-126.544" transform="translate(-197.354 225.528)" fill="#d1d4de" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-193">
        <path id="Контур_4563" data-name="Контур 4563" d="M338.408-352.571l-6.3-3.655,6.44-3.7,6.341,3.642-6.486,3.716" transform="translate(-332.113 359.929)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-195">
        <path id="Контур_4566" data-name="Контур 4566" d="M411.293-310.778l-37.606-21.781,9.4-5.4L420.76-316.2l-9.467,5.423" transform="translate(-373.687 337.963)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-197">
        <path id="Контур_4569" data-name="Контур 4569" d="M563.266-373.288l17.078,9.828v31.5l-17.078-9.829v-31.5" transform="translate(-563.266 373.288)" fill="#afb5ca" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-198">
        <path id="Контур_4568" data-name="Контур 4568" d="M-92-286.784H330.216V-709H-92Z" transform="translate(92 709)" fill="#afb5ca"/>
      </clipPath>
      <clipPath id="clip-path-199">
        <path id="Контур_4572" data-name="Контур 4572" d="M596.955-356.139l-1.33.777v31.5l1.33-.778v-31.5" transform="translate(-595.625 356.139)" fill="#949bb2" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-200">
        <path id="Контур_4571" data-name="Контур 4571" d="M-92-286.784H330.216V-709H-92Z" transform="translate(92 709)" fill="#949bb2"/>
      </clipPath>
      <clipPath id="clip-path-201">
        <path id="Контур_4575" data-name="Контур 4575" d="M581.674-364.933l-1.33.777-17.078-9.828,1.331-.777,17.078,9.828" transform="translate(-563.266 374.761)" fill="#a1a8c3" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-202">
        <path id="Контур_4574" data-name="Контур 4574" d="M-92-286.784H330.216V-709H-92Z" transform="translate(92 709)" fill="#a1a8c3"/>
      </clipPath>
      <clipPath id="clip-path-203">
        <path id="Контур_4578" data-name="Контур 4578" d="M526.512-394.314l17.078,9.828v31.5l-17.078-9.829v-31.5" transform="translate(-526.512 394.314)" fill="#afb5ca" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-205">
        <path id="Контур_4581" data-name="Контур 4581" d="M560.2-377.166l-1.331.777v31.5l1.331-.777v-31.5" transform="translate(-558.871 377.166)" fill="#949bb2" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-207">
        <path id="Контур_4584" data-name="Контур 4584" d="M544.921-385.959l-1.331.777-17.078-9.828,1.331-.777,17.078,9.828" transform="translate(-526.512 395.787)" fill="#a1a8c3" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-209">
        <path id="Контур_4587" data-name="Контур 4587" d="M489.758-415.331l17.077,9.828V-374l-17.077-9.828v-31.5" transform="translate(-489.758 415.331)" fill="#afb5ca" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-211">
        <path id="Контур_4590" data-name="Контур 4590" d="M523.446-398.182l-1.331.777v31.5l1.331-.778v-31.5" transform="translate(-522.115 398.182)" fill="#949bb2" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-213">
        <path id="Контур_4593" data-name="Контур 4593" d="M508.166-406.976l-1.331.777-17.077-9.828,1.33-.777,17.078,9.828" transform="translate(-489.758 416.804)" fill="#a1a8c3" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-215">
        <path id="Контур_4596" data-name="Контур 4596" d="M332.076-378.459v11.5l6.441-3.707.939-12.037-7.381,4.247" transform="translate(-332.076 382.706)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-217">
        <path id="Контур_4599" data-name="Контур 4599" d="M332.819-386.537l-7.381,4.247-7.419-4.282,7.38-4.247,7.419,4.281" transform="translate(-318.019 390.818)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-219">
        <path id="Контур_4602" data-name="Контур 4602" d="M325.438-378.5V-367l-6.162-3.556-1.257-12.222,7.419,4.282" transform="translate(-318.019 382.78)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-221">
        <path id="Контур_4605" data-name="Контур 4605" d="M319.316-386.571l6.083-3.5,6.123,3.533-6.084,3.5Zm6.084-4.247-7.38,4.247,7.419,4.282,7.381-4.247-7.419-4.281Z" transform="translate(-318.019 390.818)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-223">
        <path id="Контур_4608" data-name="Контур 4608" d="M332-389.4l.039,7.034,6.084-3.5L332-389.4" transform="translate(-332.002 389.402)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-225">
        <path id="Контур_4614" data-name="Контур 4614" d="M308.531-343.681l1.4-.812v26.169l-1.4.811,0-26.169" transform="translate(-308.529 344.493)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-227">
        <path id="Контур_4620" data-name="Контур 4620" d="M178.575-263.824l-16.394,9.484,0,19.579,16.391-9.611v-19.452" transform="translate(-162.181 263.824)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-229">
        <path id="Контур_4623" data-name="Контур 4623" d="M160.927-246.581l-1.4-.812v19.58l1.4.811,0-19.579" transform="translate(-159.525 247.393)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-231">
        <path id="Контур_4626" data-name="Контур 4626" d="M177.32-264.516l-1.329-.773-16.466,9.445,1.4.812,16.394-9.484" transform="translate(-159.525 265.289)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-233">
        <path id="Контур_4629" data-name="Контур 4629" d="M162.18-230.818l22.252,12.825,0,1.506L162.18-229.4v-1.421" transform="translate(-162.18 230.818)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-235">
        <path id="Контур_4632" data-name="Контур 4632" d="M204.341-215.019l16.334-9.5v1.506l-16.336,9.5,0-1.506" transform="translate(-204.339 224.519)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-237">
        <path id="Контур_4635" data-name="Контур 4635" d="M162.18-239.3l16.394-9.483,22.193,12.808-16.334,9.5L162.18-239.3" transform="translate(-162.18 248.787)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-239">
        <path id="Контур_4638" data-name="Контур 4638" d="M162.18-245.855l22.252,12.825,0,1.506-22.25-12.91v-1.421" transform="translate(-162.18 245.855)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-241">
        <path id="Контур_4641" data-name="Контур 4641" d="M204.341-230.056l16.334-9.5v1.506l-16.336,9.5,0-1.506" transform="translate(-204.339 239.556)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-243">
        <path id="Контур_4644" data-name="Контур 4644" d="M162.18-254.34l16.394-9.483,22.193,12.807-16.334,9.5L162.18-254.34" transform="translate(-162.18 263.823)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-245">
        <path id="Контур_4647" data-name="Контур 4647" d="M223.26-238.092,207-228.554l0,19.58,16.262-9.665v-19.453" transform="translate(-206.996 238.092)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-247">
        <path id="Контур_4650" data-name="Контур 4650" d="M205.742-220.745l-1.4-.812v19.58l1.4.811,0-19.58" transform="translate(-204.341 221.557)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-249">
        <path id="Контур_4653" data-name="Контур 4653" d="M222.007-238.783l-1.33-.773-16.336,9.5,1.4.812,16.264-9.538" transform="translate(-204.341 239.556)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-251">
        <path id="Контур_4656" data-name="Контур 4656" d="M290.837-328.505l-16.394,9.484,0,19.579,16.392-9.611v-19.453" transform="translate(-274.443 328.505)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-253">
        <path id="Контур_4659" data-name="Контур 4659" d="M273.189-311.261l-1.4-.811v19.579l1.4.811,0-19.579" transform="translate(-271.787 312.072)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-255">
        <path id="Контур_4662" data-name="Контур 4662" d="M289.582-329.2l-1.33-.773-16.465,9.445,1.4.811,16.394-9.484" transform="translate(-271.787 329.969)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-257">
        <path id="Контур_4665" data-name="Контур 4665" d="M274.442-295.5l22.252,12.825,0,1.506-22.25-12.91V-295.5" transform="translate(-274.442 295.499)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-259">
        <path id="Контур_4668" data-name="Контур 4668" d="M316.6-279.7l16.335-9.5v1.506l-16.337,9.5,0-1.506" transform="translate(-316.601 289.199)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-261">
        <path id="Контур_4671" data-name="Контур 4671" d="M274.442-303.984l16.394-9.483,22.193,12.808-16.335,9.5-22.252-12.825" transform="translate(-274.442 313.467)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-263">
        <path id="Контур_4674" data-name="Контур 4674" d="M274.442-310.535l22.252,12.825,0,1.505-22.25-12.909v-1.421" transform="translate(-274.442 310.535)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-265">
        <path id="Контур_4677" data-name="Контур 4677" d="M316.6-294.735l16.335-9.5v1.506l-16.337,9.5,0-1.505" transform="translate(-316.601 304.236)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-267">
        <path id="Контур_4680" data-name="Контур 4680" d="M274.442-319.02l16.394-9.484L313.029-315.7l-16.335,9.5L274.442-319.02" transform="translate(-274.442 328.504)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-269">
        <path id="Контур_4683" data-name="Контур 4683" d="M335.522-302.771l-16.264,9.538,0,19.579,16.262-9.665v-19.452" transform="translate(-319.258 302.771)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-271">
        <path id="Контур_4686" data-name="Контур 4686" d="M318-285.425l-1.4-.811v19.579l1.4.811,0-19.579" transform="translate(-316.604 286.236)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-273">
        <path id="Контур_4689" data-name="Контур 4689" d="M334.268-303.463l-1.329-.773-16.335,9.5,1.4.811,16.264-9.538" transform="translate(-316.604 304.236)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-275">
        <path id="Контур_4692" data-name="Контур 4692" d="M118.509-311.019l-34.938,20.3v24.276l118.728-68.4v-5.159l-83.79,28.988" transform="translate(-83.571 340.007)" fill="#e6e7e3" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-276">
        <path id="Контур_4691" data-name="Контур 4691" d="M-92-286.784H330.216V-709H-92Z" transform="translate(92 709)" fill="#e6e7e3"/>
      </clipPath>
      <clipPath id="clip-path-277">
        <path id="Контур_4695" data-name="Контур 4695" d="M202.3-342.955,83.571-274.278l0,1.775L202.3-341.392v-1.563" transform="translate(-83.571 342.955)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-279">
        <path id="Контур_4698" data-name="Контур 4698" d="M64.117-224.13,42.375-236.761v1.775l21.744,12.631,0-1.775" transform="translate(-42.375 236.761)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-281">
        <path id="Контур_4701" data-name="Контур 4701" d="M182.845-354.221l-21.539-12.592L42.375-298.176l21.742,12.631,118.728-68.677" transform="translate(-42.375 366.813)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-283">
        <path id="Контур_4704" data-name="Контур 4704" d="M39.855-235.3l21.673,12.669,0,26.169-21.671-12.8V-235.3" transform="translate(-39.855 235.296)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-285">
        <path id="Контур_4707" data-name="Контур 4707" d="M80.918-212.017l1.4-.811v26.169l-1.4.811,0-26.169" transform="translate(-80.916 212.828)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-287">
        <path id="Контур_4710" data-name="Контур 4710" d="M39.855-235.988l1.33-.773L62.929-224.13l-1.4.811L39.855-235.988" transform="translate(-39.855 236.761)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-289">
        <path id="Контур_4713" data-name="Контур 4713" d="M162.18-243.162l22.25,12.91,0,6.43L162.18-236.647v-6.515" transform="translate(-162.18 243.162)" fill="#e6e7e3" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-291">
        <path id="Контур_4716" data-name="Контур 4716" d="M274.442-307.842v6.514L296.694-288.5l0-6.43-22.25-12.909" transform="translate(-274.442 307.842)" fill="#e6e7e3" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-293">
        <path id="Контур_4719" data-name="Контур 4719" d="M424.968-275.225l21.673,12.669,0,26.169-21.671-12.8v-26.042" transform="translate(-424.968 275.225)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-295">
        <path id="Контур_4722" data-name="Контур 4722" d="M466.031-251.946l1.4-.811v26.169l-1.4.811,0-26.169" transform="translate(-466.029 252.757)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-297">
        <path id="Контур_4725" data-name="Контур 4725" d="M424.968-275.916l1.33-.773,21.744,12.631-1.4.811-21.673-12.669" transform="translate(-424.968 276.689)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-299">
        <path id="Контур_4728" data-name="Контур 4728" d="M336.075-172.088,319.681-162.6l0,19.58,16.391-9.611v-19.453" transform="translate(-319.681 172.088)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-301">
        <path id="Контур_4731" data-name="Контур 4731" d="M318.427-154.845l-1.4-.811v19.58l1.4.811,0-19.58" transform="translate(-317.025 155.656)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-303">
        <path id="Контур_4734" data-name="Контур 4734" d="M334.82-172.78l-1.33-.773-16.465,9.445,1.4.811,16.394-9.483" transform="translate(-317.025 173.553)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-305">
        <path id="Контур_4737" data-name="Контур 4737" d="M319.68-139.083l22.251,12.826v1.505l-22.25-12.909v-1.422" transform="translate(-319.68 139.083)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-307">
        <path id="Контур_4740" data-name="Контур 4740" d="M361.839-123.282l16.336-9.5v1.506l-16.337,9.5v-1.505" transform="translate(-361.838 132.783)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-309">
        <path id="Контур_4743" data-name="Контур 4743" d="M319.68-147.568l16.394-9.483,22.192,12.808-16.336,9.5L319.68-147.568" transform="translate(-319.68 157.051)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-311">
        <path id="Контур_4746" data-name="Контур 4746" d="M319.68-154.119l22.251,12.825v1.505L319.68-152.7v-1.421" transform="translate(-319.68 154.119)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-313">
        <path id="Контур_4749" data-name="Контур 4749" d="M361.839-138.319l16.336-9.5v1.505l-16.337,9.5v-1.505" transform="translate(-361.838 147.819)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-315">
        <path id="Контур_4752" data-name="Контур 4752" d="M319.68-162.6l16.394-9.483,22.192,12.808-16.336,9.5L319.68-162.6" transform="translate(-319.68 172.088)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-317">
        <path id="Контур_4755" data-name="Контур 4755" d="M380.76-146.354,364.5-136.817l0,19.58L380.76-126.9v-19.452" transform="translate(-364.496 146.354)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-319">
        <path id="Контур_4758" data-name="Контур 4758" d="M363.242-129.009l-1.4-.811v19.58l1.4.811,0-19.58" transform="translate(-361.84 129.82)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-321">
        <path id="Контур_4761" data-name="Контур 4761" d="M379.506-147.046l-1.33-.774-16.336,9.5,1.4.811,16.264-9.537" transform="translate(-361.84 147.82)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-323">
        <path id="Контур_4764" data-name="Контур 4764" d="M448.336-236.769l-16.394,9.483,0,19.58,16.392-9.611v-19.453" transform="translate(-431.942 236.769)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-325">
        <path id="Контур_4767" data-name="Контур 4767" d="M430.689-219.525l-1.4-.811v19.58l1.4.811v-19.58" transform="translate(-429.287 220.336)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-327">
        <path id="Контур_4770" data-name="Контур 4770" d="M447.082-237.46l-1.33-.773-16.465,9.445,1.4.811,16.394-9.484" transform="translate(-429.287 238.233)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-329">
        <path id="Контур_4773" data-name="Контур 4773" d="M431.941-203.763l22.251,12.825v1.506l-22.25-12.91v-1.422" transform="translate(-431.941 203.763)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-331">
        <path id="Контур_4776" data-name="Контур 4776" d="M474.1-187.963l16.336-9.5v1.506l-16.337,9.5v-1.506" transform="translate(-474.1 197.463)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-333">
        <path id="Контур_4779" data-name="Контур 4779" d="M431.941-212.248l16.394-9.482,22.193,12.807-16.336,9.5-22.251-12.825" transform="translate(-431.941 221.73)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-335">
        <path id="Контур_4782" data-name="Контур 4782" d="M431.941-218.8l22.251,12.825v1.506l-22.25-12.909V-218.8" transform="translate(-431.941 218.8)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-337">
        <path id="Контур_4785" data-name="Контур 4785" d="M474.1-203l16.336-9.5v1.506l-16.337,9.5V-203" transform="translate(-474.1 212.5)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-339">
        <path id="Контур_4788" data-name="Контур 4788" d="M431.941-227.285l16.394-9.483,22.193,12.808-16.336,9.5-22.251-12.825" transform="translate(-431.941 236.768)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-341">
        <path id="Контур_4791" data-name="Контур 4791" d="M493.021-211.035,476.758-201.5l0,19.58,16.262-9.666v-19.452" transform="translate(-476.758 211.035)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-343">
        <path id="Контур_4794" data-name="Контур 4794" d="M475.5-193.689l-1.4-.811v19.58l1.4.811,0-19.58" transform="translate(-474.102 194.5)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-345">
        <path id="Контур_4797" data-name="Контур 4797" d="M491.767-211.727l-1.329-.773L474.1-203l1.4.811,16.263-9.538" transform="translate(-474.102 212.5)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-347">
        <path id="Контур_4800" data-name="Контур 4800" d="M276.008-219.283l-34.938,20.3v24.276l118.728-68.4v-5.159l-83.789,28.988" transform="translate(-241.07 248.271)" fill="#e6e7e3" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-349">
        <path id="Контур_4803" data-name="Контур 4803" d="M359.8-251.22,241.07-182.542l0,1.775L359.8-249.657v-1.563" transform="translate(-241.07 251.22)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-351">
        <path id="Контур_4806" data-name="Контур 4806" d="M221.616-132.394l-21.741-12.631v1.775l21.744,12.631,0-1.775" transform="translate(-199.875 145.025)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-353">
        <path id="Контур_4809" data-name="Контур 4809" d="M340.344-262.486l-21.538-12.592L199.875-206.44l21.741,12.631,118.728-68.678" transform="translate(-199.875 275.078)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-355">
        <path id="Контур_4812" data-name="Контур 4812" d="M197.354-143.56l21.673,12.669,0,26.169-21.671-12.8V-143.56" transform="translate(-197.354 143.56)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-357">
        <path id="Контур_4815" data-name="Контур 4815" d="M238.418-120.281l1.4-.811v26.169l-1.4.811,0-26.169" transform="translate(-238.416 121.092)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-359">
        <path id="Контур_4818" data-name="Контур 4818" d="M197.354-144.252l1.331-.773,21.744,12.631-1.4.811-21.673-12.669" transform="translate(-197.354 145.025)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-361">
        <path id="Контур_4821" data-name="Контур 4821" d="M319.68-151.426l22.25,12.909v6.431L319.68-144.912v-6.514" transform="translate(-319.68 151.426)" fill="#e6e7e3" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-363">
        <path id="Контур_4824" data-name="Контур 4824" d="M431.941-216.106v6.514l22.251,12.825v-6.43l-22.25-12.909" transform="translate(-431.941 216.106)" fill="#e6e7e3" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-365">
        <path id="Контур_4827" data-name="Контур 4827" d="M329.3-520.269,396.376-481.5v39.025L329.3-481.245v-39.024" transform="translate(-329.298 520.269)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-367">
        <path id="Контур_4830" data-name="Контур 4830" d="M458.441-449.088l-2.045,1.206v39.025l2.045-1.206v-39.025" transform="translate(-456.396 449.088)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-369">
        <path id="Контур_4833" data-name="Контур 4833" d="M398.421-483.781l-2.045,1.206L329.3-521.348l2.044-1.206,67.079,38.773" transform="translate(-329.298 522.554)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-371">
        <path id="Контур_4836" data-name="Контур 4836" d="M308.835-518.022l-27.214,15.715v39.025L308.835-479v-39.023" transform="translate(-281.621 518.022)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-373">
        <path id="Контур_4839" data-name="Контур 4839" d="M277.749-490.53l2.044,1.205V-450.3l-2.044-1.205V-490.53" transform="translate(-277.749 490.53)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-375">
        <path id="Контур_4842" data-name="Контур 4842" d="M277.749-504.574l2.044,1.205,27.214-15.715-2.044-1.186-27.214,15.7" transform="translate(-277.749 520.269)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-377">
        <path id="Контур_4845" data-name="Контур 4845" d="M369.506-480.753l36.127,20.867v25.118l-36.127-20.868v-25.117" transform="translate(-369.506 480.753)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-379">
        <path id="Контур_4848" data-name="Контур 4848" d="M439.274-442.685l-1.315.776v25.118l1.315-.776v-25.118" transform="translate(-437.959 442.685)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-381">
        <path id="Контур_4851" data-name="Контур 4851" d="M406.949-461.357l-1.315.776-36.127-20.867,1.315-.776,36.127,20.867" transform="translate(-369.506 482.224)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-383">
        <path id="Контур_4854" data-name="Контур 4854" d="M371.055-478.068l33.029,19.077v21.539L371.055-456.53Zm-1.549-2.685v25.117l36.127,20.868v-25.118l-36.127-20.867Z" transform="translate(-369.506 480.753)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-385">
        <path id="Контур_4857" data-name="Контур 4857" d="M441.414-364.509v32.449l3.1-1.784v-32.449l-3.1,1.784" transform="translate(-441.414 366.293)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-387">
        <path id="Контур_4860" data-name="Контур 4860" d="M441.725-367.9l-3.1,1.784-3.117-1.8,3.1-1.784,3.116,1.8" transform="translate(-435.508 369.701)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-389">
        <path id="Контур_4863" data-name="Контур 4863" d="M438.625-364.525v32.449l-3.117-1.8v-32.448l3.117,1.8" transform="translate(-435.508 366.324)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-391">
        <path id="Контур_4866" data-name="Контур 4866" d="M360.822-410.722v32.449l3.1-1.784v-32.45l-3.1,1.784" transform="translate(-360.822 412.506)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-393">
        <path id="Контур_4869" data-name="Контур 4869" d="M361.134-414.115l-3.1,1.784-3.116-1.8,3.1-1.784,3.116,1.8" transform="translate(-354.917 415.914)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-395">
        <path id="Контур_4872" data-name="Контур 4872" d="M358.033-410.738v32.449l-3.116-1.8v-32.449l3.116,1.8" transform="translate(-354.917 412.537)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-397">
        <path id="Контур_4875" data-name="Контур 4875" d="M361.594-361.978,390.5-345.3v14.445l-28.9-16.78v-14.346" transform="translate(-361.594 361.978)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-399">
        <path id="Контур_4878" data-name="Контур 4878" d="M416.357-335.128l9.138-5.317V-326l-9.138,5.317v-14.445" transform="translate(-416.357 340.445)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-401">
        <path id="Контур_4881" data-name="Контур 4881" d="M361.594-366.718l9.172-5.3,28.868,16.662-9.138,5.317-28.9-16.681" transform="translate(-361.594 372.015)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-403">
        <path id="Контур_4884" data-name="Контур 4884" d="M363.276-359.219l27.121,15.64V-331l-27.12-15.728v-12.487" transform="translate(-363.276 359.219)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-405">
        <path id="Контур_4887" data-name="Контур 4887" d="M363.278-359.219l8.4,4.845v2.753l-8.4,4.89,0-12.488" transform="translate(-363.276 359.219)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-407">
        <path id="Контур_4890" data-name="Контур 4890" d="M379.2-347.286l18.719,10.891v-2.849l-18.719-10.8v2.753" transform="translate(-379.195 350.039)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-409">
        <path id="Контур_4893" data-name="Контур 4893" d="M361.594-387.43l28.9,16.681V-356.3l-28.9-16.78V-387.43" transform="translate(-361.594 387.43)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-411">
        <path id="Контур_4896" data-name="Контур 4896" d="M416.357-360.581l9.138-5.317v14.446l-9.138,5.317v-14.445" transform="translate(-416.357 365.898)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-413">
        <path id="Контур_4899" data-name="Контур 4899" d="M361.594-392.17l9.172-5.3,28.868,16.661-9.138,5.317-28.9-16.681" transform="translate(-361.594 397.467)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-415">
        <path id="Контур_4902" data-name="Контур 4902" d="M363.276-384.672,390.4-369.031v12.574l-27.12-15.728v-12.488" transform="translate(-363.276 384.672)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-417">
        <path id="Контур_4905" data-name="Контур 4905" d="M363.278-384.672l8.4,4.845v2.753l-8.4,4.89,0-12.489" transform="translate(-363.276 384.672)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-419">
        <path id="Контур_4908" data-name="Контур 4908" d="M379.2-372.738l18.719,10.891V-364.7l-18.719-10.8v2.753" transform="translate(-379.195 375.491)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-421">
        <path id="Контур_4911" data-name="Контур 4911" d="M367.9-378.312v8.75l-.861-.516v-8.732l.861.5" transform="translate(-367.042 378.809)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-423">
        <path id="Контур_4914" data-name="Контур 4914" d="M367.042-377.445l.861.5v2.632l-.861-.515v-2.614" transform="translate(-367.042 377.445)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-425">
        <path id="Контур_4917" data-name="Контур 4917" d="M368.674-378.743l1.68-.978,4.956,2.858v3.007l-6.636,3.864v-8.75" transform="translate(-368.673 379.721)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-427">
        <path id="Контур_4920" data-name="Контур 4920" d="M367.042-379.671l1.7-.964.837.482-1.68.978-.861-.5" transform="translate(-367.042 380.635)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-429">
        <path id="Контур_4923" data-name="Контур 4923" d="M369.908-377.151v8.75l-.861-.516v-8.731l.862.5" transform="translate(-369.046 377.648)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-431">
        <path id="Контур_4926" data-name="Контур 4926" d="M369.046-376.285l.862.5v2.633l-.861-.515v-2.615" transform="translate(-369.046 376.285)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-433">
        <path id="Контур_4929" data-name="Контур 4929" d="M370.678-377.583l1.679-.978,4.956,2.858v3.006l-6.637,3.864v-8.75" transform="translate(-370.677 378.561)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-435">
        <path id="Контур_4932" data-name="Контур 4932" d="M369.046-378.511l1.7-.964.836.482-1.679.978-.862-.5" transform="translate(-369.046 379.475)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-437">
        <path id="Контур_4935" data-name="Контур 4935" d="M371.912-375.991v8.75l-.861-.514v-8.733l.862.5" transform="translate(-371.05 376.488)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-439">
        <path id="Контур_4938" data-name="Контур 4938" d="M371.05-375.125l.862.5V-372l-.861-.515v-2.615" transform="translate(-371.05 375.125)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-441">
        <path id="Контур_4941" data-name="Контур 4941" d="M372.683-376.422l1.679-.978,4.956,2.858v3.006l-6.636,3.864v-8.75" transform="translate(-372.682 377.4)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-443">
        <path id="Контур_4944" data-name="Контур 4944" d="M371.05-377.35l1.705-.964.837.482-1.679.978-.862-.5" transform="translate(-371.05 378.314)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-445">
        <path id="Контур_4947" data-name="Контур 4947" d="M373.917-374.831v8.75l-.861-.515v-8.732l.862.5" transform="translate(-373.055 375.328)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-447">
        <path id="Контур_4950" data-name="Контур 4950" d="M373.055-373.964l.862.5v2.632l-.861-.515v-2.615" transform="translate(-373.055 373.964)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-449">
        <path id="Контур_4953" data-name="Контур 4953" d="M374.687-375.262l1.679-.978,4.956,2.858v3.006l-6.636,3.864v-8.75" transform="translate(-374.686 376.24)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-451">
        <path id="Контур_4956" data-name="Контур 4956" d="M373.055-376.19l1.7-.964.837.482-1.679.978-.862-.5" transform="translate(-373.055 377.154)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-453">
        <path id="Контур_4959" data-name="Контур 4959" d="M375.92-373.671v8.75l-.86-.515v-8.733l.861.5" transform="translate(-375.059 374.168)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-455">
        <path id="Контур_4962" data-name="Контур 4962" d="M375.059-372.8l.861.5v2.632l-.86-.515V-372.8" transform="translate(-375.059 372.805)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-457">
        <path id="Контур_4965" data-name="Контур 4965" d="M376.69-374.1l1.68-.978,4.955,2.858v3.007l-6.637,3.864v-8.75" transform="translate(-376.689 375.079)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-459">
        <path id="Контур_4968" data-name="Контур 4968" d="M375.059-375.03l1.7-.964.837.483-1.68.978-.861-.5" transform="translate(-375.059 375.994)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-461">
        <path id="Контур_4971" data-name="Контур 4971" d="M377.925-372.51v8.75l-.861-.515v-8.732l.862.5" transform="translate(-377.063 373.007)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-463">
        <path id="Контур_4974" data-name="Контур 4974" d="M377.063-371.645l.862.5v2.632l-.861-.515v-2.616" transform="translate(-377.063 371.645)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-465">
        <path id="Контур_4977" data-name="Контур 4977" d="M378.7-372.94l1.68-.978,4.956,2.858v3.007l-6.637,3.864v-8.75" transform="translate(-378.694 373.919)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-467">
        <path id="Контур_4980" data-name="Контур 4980" d="M377.063-373.869l1.7-.964.837.482-1.68.978-.862-.5" transform="translate(-377.063 374.833)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-469">
        <path id="Контур_4983" data-name="Контур 4983" d="M379.929-371.35v8.75l-.861-.516v-8.733l.862.5" transform="translate(-379.067 371.848)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-471">
        <path id="Контур_4986" data-name="Контур 4986" d="M379.067-370.483l.862.5v2.632l-.861-.515v-2.615" transform="translate(-379.067 370.483)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-473">
        <path id="Контур_4989" data-name="Контур 4989" d="M380.7-371.779l1.68-.978,4.955,2.858v3.007l-6.636,3.864v-8.75" transform="translate(-380.699 372.758)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-475">
        <path id="Контур_4992" data-name="Контур 4992" d="M379.067-372.71l1.705-.963.837.483-1.68.978-.862-.5" transform="translate(-379.067 373.673)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-477">
        <path id="Контур_4995" data-name="Контур 4995" d="M381.934-370.189v8.75l-.861-.516v-8.732l.862.5" transform="translate(-381.072 370.687)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-479">
        <path id="Контур_4998" data-name="Контур 4998" d="M381.072-369.322l.862.5v2.633l-.861-.515v-2.614" transform="translate(-381.072 369.322)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-481">
        <path id="Контур_5001" data-name="Контур 5001" d="M382.7-370.62l1.68-.978,4.955,2.858v3.007l-6.636,3.864v-8.75" transform="translate(-382.703 371.598)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-483">
        <path id="Контур_5004" data-name="Контур 5004" d="M381.072-371.549l1.705-.963.837.482-1.68.978-.862-.5" transform="translate(-381.072 372.512)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-485">
        <path id="Контур_5007" data-name="Контур 5007" d="M383.938-369.028v8.75l-.861-.515v-8.732l.862.5" transform="translate(-383.076 369.525)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-487">
        <path id="Контур_5010" data-name="Контур 5010" d="M383.076-368.162l.862.5v2.633l-.861-.516v-2.614" transform="translate(-383.076 368.162)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-489">
        <path id="Контур_5013" data-name="Контур 5013" d="M384.708-369.459l1.68-.978,4.956,2.858v3.006l-6.637,3.864v-8.75" transform="translate(-384.707 370.437)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-491">
        <path id="Контур_5016" data-name="Контур 5016" d="M383.076-370.388l1.705-.964.837.483-1.68.978-.862-.5" transform="translate(-383.076 371.352)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-493">
        <path id="Контур_5019" data-name="Контур 5019" d="M385.942-367.868v8.75l-.861-.515v-8.732l.862.5" transform="translate(-385.08 368.365)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-495">
        <path id="Контур_5022" data-name="Контур 5022" d="M385.08-367l.862.5v2.633l-.861-.515V-367" transform="translate(-385.08 367.002)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-497">
        <path id="Контур_5025" data-name="Контур 5025" d="M386.713-368.3l1.679-.978,4.956,2.858v3.006l-6.637,3.864v-8.75" transform="translate(-386.712 369.277)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-499">
        <path id="Контур_5028" data-name="Контур 5028" d="M385.08-369.227l1.705-.964.837.482-1.679.978-.862-.5" transform="translate(-385.08 370.191)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-501">
        <path id="Контур_5031" data-name="Контур 5031" d="M387.947-366.708v8.751l-.861-.515v-8.733l.862.5" transform="translate(-387.085 367.205)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-503">
        <path id="Контур_5034" data-name="Контур 5034" d="M387.085-365.842l.862.5v2.632l-.861-.515v-2.615" transform="translate(-387.085 365.842)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-505">
        <path id="Контур_5037" data-name="Контур 5037" d="M388.717-367.139l1.679-.977,4.956,2.858v3.006l-6.637,3.864v-8.751" transform="translate(-388.716 368.116)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-507">
        <path id="Контур_5040" data-name="Контур 5040" d="M387.085-368.067l1.7-.964.837.483-1.679.977-.862-.5" transform="translate(-387.085 369.031)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-509">
        <path id="Контур_5043" data-name="Контур 5043" d="M389.952-365.548v8.751l-.861-.515v-8.733l.862.5" transform="translate(-389.09 366.045)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-511">
        <path id="Контур_5046" data-name="Контур 5046" d="M389.09-364.682l.862.5v2.632l-.861-.516v-2.615" transform="translate(-389.09 364.682)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-513">
        <path id="Контур_5049" data-name="Контур 5049" d="M390.722-365.979l1.679-.978,4.956,2.858v3.006l-6.636,3.865v-8.751" transform="translate(-390.721 366.957)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-515">
        <path id="Контур_5052" data-name="Контур 5052" d="M389.09-366.907l1.7-.964.837.482-1.679.978-.862-.5" transform="translate(-389.09 367.871)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-517">
        <path id="Контур_5055" data-name="Контур 5055" d="M391.956-364.387v8.75l-.861-.515v-8.733l.862.5" transform="translate(-391.094 364.885)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-519">
        <path id="Контур_5058" data-name="Контур 5058" d="M391.094-363.521l.862.5v2.632l-.861-.515v-2.615" transform="translate(-391.094 363.521)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-521">
        <path id="Контур_5061" data-name="Контур 5061" d="M392.726-364.818l1.68-.979,4.955,2.858v3.006l-6.636,3.865v-8.75" transform="translate(-392.725 365.796)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-523">
        <path id="Контур_5064" data-name="Контур 5064" d="M391.094-365.747l1.7-.964.837.483-1.68.978-.862-.5" transform="translate(-391.094 366.711)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-525">
        <path id="Контур_5067" data-name="Контур 5067" d="M393.959-363.226v8.75l-.861-.516v-8.732l.861.5" transform="translate(-393.098 363.724)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-527">
        <path id="Контур_5070" data-name="Контур 5070" d="M393.1-362.36l.861.5v2.632l-.861-.515v-2.615" transform="translate(-393.098 362.36)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-529">
        <path id="Контур_5073" data-name="Контур 5073" d="M394.73-363.657l1.68-.978,4.955,2.858v3.007l-6.636,3.864v-8.75" transform="translate(-394.729 364.635)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-531">
        <path id="Контур_5076" data-name="Контур 5076" d="M393.1-364.586l1.7-.964.837.483-1.68.978-.861-.5" transform="translate(-393.098 365.55)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-533">
        <path id="Контур_5079" data-name="Контур 5079" d="M395.964-362.066v8.751l-.861-.516v-8.732l.862.5" transform="translate(-395.102 362.563)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-535">
        <path id="Контур_5082" data-name="Контур 5082" d="M395.1-361.2l.862.5v2.633l-.861-.515V-361.2" transform="translate(-395.102 361.199)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-537">
        <path id="Контур_5085" data-name="Контур 5085" d="M396.734-362.5l1.68-.978,4.956,2.858v3.007l-6.637,3.864V-362.5" transform="translate(-396.734 363.475)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-539">
        <path id="Контур_5088" data-name="Контур 5088" d="M395.1-363.426l1.705-.964.837.483-1.68.978-.862-.5" transform="translate(-395.102 364.39)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-541">
        <path id="Контур_5091" data-name="Контур 5091" d="M366.958-353.4v8.75l-.861-.515V-353.9l.862.5" transform="translate(-366.096 353.9)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-543">
        <path id="Контур_5094" data-name="Контур 5094" d="M366.1-352.537l.862.5v2.633l-.861-.515v-2.615" transform="translate(-366.096 352.537)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-545">
        <path id="Контур_5097" data-name="Контур 5097" d="M367.728-353.833l1.68-.978,4.955,2.858v3.007l-6.636,3.863v-8.75" transform="translate(-367.727 354.812)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-547">
        <path id="Контур_5100" data-name="Контур 5100" d="M366.1-354.762l1.7-.964.837.482-1.68.978-.862-.5" transform="translate(-366.096 355.726)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-549">
        <path id="Контур_5103" data-name="Контур 5103" d="M368.961-352.242v8.75l-.86-.515v-8.733l.861.5" transform="translate(-368.1 352.74)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-551">
        <path id="Контур_5106" data-name="Контур 5106" d="M368.1-351.376l.861.5v2.633l-.86-.515v-2.615" transform="translate(-368.1 351.376)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-553">
        <path id="Контур_5109" data-name="Контур 5109" d="M369.731-352.673l1.68-.978,4.955,2.858v3.007l-6.637,3.864v-8.75" transform="translate(-369.73 353.651)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-555">
        <path id="Контур_5112" data-name="Контур 5112" d="M368.1-353.6l1.7-.963.837.482-1.68.978-.861-.5" transform="translate(-368.1 354.565)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-557">
        <path id="Контур_5115" data-name="Контур 5115" d="M370.966-351.082v8.75l-.861-.515v-8.732l.862.5" transform="translate(-370.104 351.579)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-559">
        <path id="Контур_5118" data-name="Контур 5118" d="M370.1-350.215l.862.5v2.633l-.861-.516v-2.614" transform="translate(-370.104 350.215)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-561">
        <path id="Контур_5121" data-name="Контур 5121" d="M371.736-351.512l1.68-.978,4.956,2.857v3.007l-6.637,3.864v-8.75" transform="translate(-371.736 352.49)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-563">
        <path id="Контур_5124" data-name="Контур 5124" d="M370.1-352.441l1.705-.964.837.483-1.68.978-.862-.5" transform="translate(-370.104 353.405)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-565">
        <path id="Контур_5127" data-name="Контур 5127" d="M372.97-349.921v8.751l-.861-.516v-8.732l.862.5" transform="translate(-372.108 350.418)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-567">
        <path id="Контур_5130" data-name="Контур 5130" d="M372.108-349.056l.862.5v2.632l-.861-.516v-2.615" transform="translate(-372.108 349.056)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-569">
        <path id="Контур_5133" data-name="Контур 5133" d="M373.741-350.352l1.68-.978,4.955,2.858v3.006L373.74-341.6v-8.751" transform="translate(-373.74 351.33)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-571">
        <path id="Контур_5136" data-name="Контур 5136" d="M372.108-351.28l1.705-.964.837.482-1.68.978-.862-.5" transform="translate(-372.108 352.244)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-573">
        <path id="Контур_5139" data-name="Контур 5139" d="M374.975-348.761v8.75l-.861-.515v-8.733l.862.5" transform="translate(-374.113 349.259)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-575">
        <path id="Контур_5142" data-name="Контур 5142" d="M374.113-347.895l.862.5v2.632l-.861-.515v-2.615" transform="translate(-374.113 347.895)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-577">
        <path id="Контур_5145" data-name="Контур 5145" d="M375.745-349.191l1.68-.978,4.955,2.858v3.006l-6.636,3.865v-8.75" transform="translate(-375.744 350.17)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-579">
        <path id="Контур_5148" data-name="Контур 5148" d="M374.113-350.121l1.705-.963.836.482-1.68.978-.862-.5" transform="translate(-374.113 351.084)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-581">
        <path id="Контур_5151" data-name="Контур 5151" d="M376.979-347.6v8.75l-.861-.515V-348.1l.862.5" transform="translate(-376.117 348.098)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-583">
        <path id="Контур_5154" data-name="Контур 5154" d="M376.117-346.734l.862.5v2.632l-.861-.515v-2.615" transform="translate(-376.117 346.734)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-585">
        <path id="Контур_5157" data-name="Контур 5157" d="M377.749-348.032l1.68-.978,4.955,2.858v3.007l-6.636,3.864v-8.75" transform="translate(-377.748 349.01)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-587">
        <path id="Контур_5160" data-name="Контур 5160" d="M376.117-348.96l1.705-.964.837.482-1.68.978-.862-.5" transform="translate(-376.117 349.924)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-589">
        <path id="Контур_5163" data-name="Контур 5163" d="M378.983-346.44v8.75l-.861-.515v-8.732l.862.5" transform="translate(-378.121 346.937)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-591">
        <path id="Контур_5166" data-name="Контур 5166" d="M378.122-345.574l.862.5v2.632l-.861-.515v-2.615" transform="translate(-378.122 345.574)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-593">
        <path id="Контур_5169" data-name="Контур 5169" d="M379.754-346.872l1.68-.979,4.955,2.858v3.007l-6.636,3.864v-8.75" transform="translate(-379.753 347.85)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-595">
        <path id="Контур_5172" data-name="Контур 5172" d="M378.122-347.8l1.705-.964.837.482-1.68.978-.862-.5" transform="translate(-378.122 348.764)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-597">
        <path id="Контур_5175" data-name="Контур 5175" d="M386.519-342.1v8.751l-.861-.515V-342.6l.862.5" transform="translate(-385.657 342.596)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-599">
        <path id="Контур_5178" data-name="Контур 5178" d="M385.657-341.232l.862.5v2.632l-.861-.515v-2.615" transform="translate(-385.657 341.232)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-601">
        <path id="Контур_5181" data-name="Контур 5181" d="M387.289-342.529l1.68-.978,4.956,2.858v3.006l-6.637,3.865v-8.751" transform="translate(-387.288 343.507)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-603">
        <path id="Контур_5184" data-name="Контур 5184" d="M385.657-343.458l1.705-.964.837.483-1.68.978-.862-.5" transform="translate(-385.657 344.422)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-605">
        <path id="Контур_5187" data-name="Контур 5187" d="M388.523-340.938v8.75l-.861-.515v-8.733l.862.5" transform="translate(-387.661 341.436)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-607">
        <path id="Контур_5190" data-name="Контур 5190" d="M387.661-340.072l.862.5v2.631l-.861-.514v-2.615" transform="translate(-387.661 340.072)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-609">
        <path id="Контур_5193" data-name="Контур 5193" d="M389.294-341.369l1.68-.979,4.955,2.858v3.007l-6.636,3.864v-8.75" transform="translate(-389.293 342.348)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-611">
        <path id="Контур_5196" data-name="Контур 5196" d="M387.661-342.3l1.705-.963.837.482-1.68.978-.862-.5" transform="translate(-387.661 343.261)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-613">
        <path id="Контур_5199" data-name="Контур 5199" d="M390.528-339.777v8.75l-.861-.515v-8.732l.862.5" transform="translate(-389.666 340.275)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-615">
        <path id="Контур_5202" data-name="Контур 5202" d="M389.666-338.911l.862.5v2.632l-.861-.515v-2.615" transform="translate(-389.666 338.911)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-617">
        <path id="Контур_5205" data-name="Контур 5205" d="M391.3-340.208l1.679-.979,4.956,2.858v3.007l-6.637,3.864v-8.75" transform="translate(-391.297 341.187)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-619">
        <path id="Контур_5208" data-name="Контур 5208" d="M389.666-341.137l1.7-.964.837.482-1.679.979-.862-.5" transform="translate(-389.666 342.101)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-621">
        <path id="Контур_5211" data-name="Контур 5211" d="M392.532-338.617v8.751l-.861-.515v-8.732l.862.5" transform="translate(-391.67 339.114)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-623">
        <path id="Контур_5214" data-name="Контур 5214" d="M391.67-337.75l.862.5v2.633l-.861-.515v-2.614" transform="translate(-391.67 337.75)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-625">
        <path id="Контур_5217" data-name="Контур 5217" d="M393.3-339.048l1.679-.977,4.956,2.857v3.007L393.3-330.3v-8.751" transform="translate(-393.302 340.025)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-627">
        <path id="Контур_5220" data-name="Контур 5220" d="M391.67-339.976l1.7-.964.837.483-1.679.978-.862-.5" transform="translate(-391.67 340.94)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-629">
        <path id="Контур_5223" data-name="Контур 5223" d="M394.536-337.457v8.75l-.861-.515v-8.733l.861.5" transform="translate(-393.675 337.954)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-631">
        <path id="Контур_5226" data-name="Контур 5226" d="M393.675-336.591l.861.5v2.632l-.861-.515v-2.615" transform="translate(-393.675 336.591)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-633">
        <path id="Контур_5229" data-name="Контур 5229" d="M395.307-337.887l1.68-.978,4.955,2.858V-333l-6.636,3.864v-8.75" transform="translate(-395.306 338.865)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-635">
        <path id="Контур_5232" data-name="Контур 5232" d="M393.675-338.816l1.7-.963.838.482-1.68.978-.861-.5" transform="translate(-393.675 339.779)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-637">
        <path id="Контур_5235" data-name="Контур 5235" d="M396.541-336.3v8.751l-.861-.516v-8.732l.862.5" transform="translate(-395.679 336.793)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-639">
        <path id="Контур_5238" data-name="Контур 5238" d="M395.679-335.43l.862.5v2.633l-.861-.516v-2.614" transform="translate(-395.679 335.43)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-641">
        <path id="Контур_5241" data-name="Контур 5241" d="M397.312-336.727l1.679-.978,4.956,2.858v3.006l-6.636,3.865v-8.751" transform="translate(-397.311 337.705)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-643">
        <path id="Контур_5244" data-name="Контур 5244" d="M395.679-337.655l1.7-.964.837.482-1.679.978-.862-.5" transform="translate(-395.679 338.619)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-645">
        <path id="Контур_5247" data-name="Контур 5247" d="M398.545-335.136v8.75l-.86-.515v-8.732l.861.5" transform="translate(-397.684 335.633)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-647">
        <path id="Контур_5250" data-name="Контур 5250" d="M397.684-334.27l.861.5v2.632l-.86-.515v-2.615" transform="translate(-397.684 334.27)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-649">
        <path id="Контур_5253" data-name="Контур 5253" d="M399.315-335.566l1.68-.978,4.956,2.858v3.006l-6.637,3.865v-8.75" transform="translate(-399.314 336.545)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-651">
        <path id="Контур_5256" data-name="Контур 5256" d="M397.684-336.5l1.7-.964.836.482-1.68.978-.861-.5" transform="translate(-397.684 337.459)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-653">
        <path id="Контур_5259" data-name="Контур 5259" d="M224.651-316.236l-6.3,3.638,6.1,3.508,6.284-3.649-6.086-3.5" transform="translate(-218.348 316.236)" fill="#afb5ca" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-655">
        <path id="Контур_5262" data-name="Контур 5262" d="M183.365-292.336l4.559,3.051,0,.48-4.558-2.636v-.895" transform="translate(-183.365 292.336)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-657">
        <path id="Контур_5265" data-name="Контур 5265" d="M192-294.262l14.831-8.613v.48L192-293.782l0-.48" transform="translate(-192.001 302.875)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-659">
        <path id="Контур_5268" data-name="Контур 5268" d="M183.365-300.027l14.891-8.6,4.5,3.036-14.831,8.613-4.559-3.053" transform="translate(-183.365 308.623)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-661">
        <path id="Контур_5271" data-name="Контур 5271" d="M194.5-293.175l5.23-3.019.521.368-5.211,3.025-.541-.374" transform="translate(-194.504 296.194)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-663">
        <path id="Контур_5274" data-name="Контур 5274" d="M193.315-289.487l.782-.454-.521-.368-.783.453.522.369" transform="translate(-192.793 290.309)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-665">
        <path id="Контур_5277" data-name="Контур 5277" d="M191.583-288.48l.781-.454-.521-.368-.783.452.523.37" transform="translate(-191.06 289.302)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-667">
        <path id="Контур_5280" data-name="Контур 5280" d="M188.41-292.968l.782-.454-.521-.368-.783.452.523.37" transform="translate(-187.887 293.79)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-669">
        <path id="Контур_5283" data-name="Контур 5283" d="M186.678-291.962l.781-.453-.521-.368-.784.452.524.369" transform="translate(-186.154 292.783)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-671">
        <path id="Контур_5286" data-name="Контур 5286" d="M195.689-292.465l.782-.454-.521-.368-.783.452.523.37" transform="translate(-195.166 293.287)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-673">
        <path id="Контур_5289" data-name="Контур 5289" d="M193.956-291.458l.781-.453-.52-.368-.783.452.523.369" transform="translate(-193.434 292.279)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-675">
        <path id="Контур_5292" data-name="Контур 5292" d="M199.171-294.489l.781-.454-.521-.368-.784.453.524.369" transform="translate(-198.647 295.311)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-677">
        <path id="Контур_5295" data-name="Контур 5295" d="M197.438-293.481l.781-.453-.521-.368-.783.453.524.369" transform="translate(-196.914 294.303)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-679">
        <path id="Контур_5298" data-name="Контур 5298" d="M202.651-296.512l.782-.454-.521-.368-.783.452.522.37" transform="translate(-202.129 297.334)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-681">
        <path id="Контур_5301" data-name="Контур 5301" d="M200.919-295.5l.781-.453-.521-.368-.783.452.523.369" transform="translate(-200.396 296.326)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-683">
        <path id="Контур_5304" data-name="Контур 5304" d="M204.4-297.528l.782-.453-.521-.368-.783.453.523.369" transform="translate(-203.877 298.35)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-685">
        <path id="Контур_5307" data-name="Контур 5307" d="M194.452-293.3l.781-.454-.52-.368-.784.452.523.37" transform="translate(-193.929 294.127)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-687">
        <path id="Контур_5310" data-name="Контур 5310" d="M192.72-292.3l.781-.454-.521-.368-.783.451.524.37" transform="translate(-192.196 293.119)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-689">
        <path id="Контур_5313" data-name="Контур 5313" d="M197.932-295.329l.782-.453-.521-.368-.784.452.522.369" transform="translate(-197.41 296.15)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-691">
        <path id="Контур_5316" data-name="Контур 5316" d="M196.2-294.321l.781-.454-.52-.368-.784.452.523.369" transform="translate(-195.677 295.143)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-693">
        <path id="Контур_5319" data-name="Контур 5319" d="M201.415-297.352l.781-.454-.521-.368-.783.453.523.369" transform="translate(-200.892 298.174)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-695">
        <path id="Контур_5322" data-name="Контур 5322" d="M199.682-296.344l.781-.454-.521-.368-.784.451.524.37" transform="translate(-199.158 297.166)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-697">
        <path id="Контур_5325" data-name="Контур 5325" d="M204.9-299.376l.782-.453-.521-.368-.783.452.522.369" transform="translate(-204.373 300.197)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-699">
        <path id="Контур_5328" data-name="Контур 5328" d="M203.163-298.368l.781-.453-.521-.368-.783.452.523.37" transform="translate(-202.64 299.19)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-701">
        <path id="Контур_5331" data-name="Контур 5331" d="M206.644-300.392l.781-.454-.521-.368-.783.452.523.369" transform="translate(-206.121 301.214)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-703">
        <path id="Контур_5334" data-name="Контур 5334" d="M193.266-294.131l.782-.454-.521-.367-.784.452.522.37" transform="translate(-192.744 294.953)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-705">
        <path id="Контур_5337" data-name="Контур 5337" d="M191.534-293.125l.781-.453-.521-.368-.783.452.523.369" transform="translate(-191.011 293.947)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-707">
        <path id="Контур_5340" data-name="Контур 5340" d="M196.749-296.156l.782-.454-.521-.368-.783.452.522.37" transform="translate(-196.226 296.978)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-709">
        <path id="Контур_5343" data-name="Контур 5343" d="M195.015-295.149l.781-.454-.52-.368-.784.453.523.369" transform="translate(-194.492 295.971)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-711">
        <path id="Контур_5346" data-name="Контур 5346" d="M200.229-298.179l.782-.454L200.49-299l-.783.452.522.37" transform="translate(-199.707 299.001)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-713">
        <path id="Контур_5349" data-name="Контур 5349" d="M198.5-297.172l.781-.454-.521-.368-.784.452.524.369" transform="translate(-197.973 297.994)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-715">
        <path id="Контур_5352" data-name="Контур 5352" d="M203.711-300.2l.781-.454-.521-.368-.783.452.523.37" transform="translate(-203.188 301.024)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-717">
        <path id="Контур_5355" data-name="Контур 5355" d="M201.977-299.2l.782-.454-.521-.368-.784.453.523.369" transform="translate(-201.454 300.018)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-719">
        <path id="Контур_5358" data-name="Контур 5358" d="M205.459-301.219l.781-.454-.521-.368-.783.452.523.37" transform="translate(-204.936 302.041)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-721">
        <path id="Контур_5361" data-name="Контур 5361" d="M191.995-295.015l.781-.454-.521-.368-.783.452.524.37" transform="translate(-191.471 295.837)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-723">
        <path id="Контур_5364" data-name="Контур 5364" d="M190.261-294.008l.782-.454-.521-.368-.784.452.523.37" transform="translate(-189.738 294.83)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-725">
        <path id="Контур_5367" data-name="Контур 5367" d="M195.475-297.039l.782-.454-.521-.368-.784.453.523.369" transform="translate(-194.952 297.861)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-727">
        <path id="Контур_5370" data-name="Контур 5370" d="M193.743-296.032l.781-.453-.521-.368-.784.453.524.369" transform="translate(-193.219 296.854)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-729">
        <path id="Контур_5373" data-name="Контур 5373" d="M198.957-299.062l.781-.454-.521-.368-.783.452.523.37" transform="translate(-198.434 299.884)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-731">
        <path id="Контур_5376" data-name="Контур 5376" d="M197.223-298.055l.782-.454-.521-.368-.784.452.522.37" transform="translate(-196.701 298.877)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-733">
        <path id="Контур_5379" data-name="Контур 5379" d="M202.438-301.085l.782-.454-.521-.367-.784.452.524.37" transform="translate(-201.914 301.907)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-735">
        <path id="Контур_5382" data-name="Контур 5382" d="M200.705-300.079l.782-.453-.521-.368-.783.452.523.369" transform="translate(-200.182 300.9)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-737">
        <path id="Контур_5385" data-name="Контур 5385" d="M204.186-302.1l.782-.454-.521-.368-.783.453.522.369" transform="translate(-203.664 302.924)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-739">
        <path id="Контур_5388" data-name="Контур 5388" d="M213.359-302.767l.782-.454-.521-.368-.783.453.523.37" transform="translate(-212.836 303.59)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-741">
        <path id="Контур_5391" data-name="Контур 5391" d="M211.626-301.76l.781-.454-.521-.368-.783.452.523.37" transform="translate(-211.103 302.582)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-743">
        <path id="Контур_5394" data-name="Контур 5394" d="M215.107-303.784l.781-.453-.521-.368-.783.452.523.369" transform="translate(-214.584 304.605)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-745">
        <path id="Контур_5397" data-name="Контур 5397" d="M212.122-303.608l.781-.453-.521-.368-.784.453.524.369" transform="translate(-211.598 304.43)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-747">
        <path id="Контур_5400" data-name="Контур 5400" d="M210.387-302.6l.782-.454-.521-.368-.784.452.522.369" transform="translate(-209.865 303.422)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-749">
        <path id="Контур_5403" data-name="Контур 5403" d="M213.87-304.624l.781-.454-.521-.367-.783.452.523.369" transform="translate(-213.347 305.445)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-751">
        <path id="Контур_5406" data-name="Контур 5406" d="M210.936-304.434l.782-.454-.521-.367-.783.452.523.369" transform="translate(-210.413 305.256)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-753">
        <path id="Контур_5409" data-name="Контур 5409" d="M209.2-303.428l.782-.454-.521-.368-.783.452.523.37" transform="translate(-208.681 304.25)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-755">
        <path id="Контур_5412" data-name="Контур 5412" d="M212.684-305.451l.782-.454-.521-.368-.783.452.522.369" transform="translate(-212.162 306.273)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-757">
        <path id="Контур_5415" data-name="Контур 5415" d="M209.663-305.318l.782-.453-.521-.369-.783.452.522.37" transform="translate(-209.141 306.14)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-759">
        <path id="Контур_5418" data-name="Контур 5418" d="M207.93-304.311l.782-.454-.521-.368-.784.453.523.369" transform="translate(-207.407 305.133)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-761">
        <path id="Контур_5421" data-name="Контур 5421" d="M211.412-306.334l.781-.454-.521-.368-.783.452.523.369" transform="translate(-210.889 307.156)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-763">
        <path id="Контур_5424" data-name="Контур 5424" d="M185.4-292.845l.782-.454-.521-.367-.783.452.523.37" transform="translate(-184.874 293.667)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-765">
        <path id="Контур_5427" data-name="Контур 5427" d="M190.333-289.834l1.707-.978-.52-.368-1.709.977.523.369" transform="translate(-189.81 291.18)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-767">
        <path id="Контур_5430" data-name="Контур 5430" d="M206.132-299l1.707-.978-.521-.368-1.71.977.524.369" transform="translate(-205.608 300.35)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-769">
        <path id="Контур_5433" data-name="Контур 5433" d="M189.136-290.674l1.707-.978-.52-.368-1.71.977.524.369" transform="translate(-188.612 292.02)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-771">
        <path id="Контур_5436" data-name="Контур 5436" d="M187.949-291.513l1.707-.978-.521-.368-1.709.976.523.369" transform="translate(-187.426 292.859)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-773">
        <path id="Контур_5439" data-name="Контур 5439" d="M90.305-235.732l4.559,3.05,0,.48-4.557-2.636v-.895" transform="translate(-90.305 235.732)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-775">
        <path id="Контур_5442" data-name="Контур 5442" d="M98.942-237.658l14.832-8.613v.48l-14.833,8.613v-.48" transform="translate(-98.941 246.271)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-777">
        <path id="Контур_5445" data-name="Контур 5445" d="M90.305-243.423l14.891-8.6,4.5,3.036L94.864-240.37l-4.559-3.053" transform="translate(-90.305 252.019)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-779">
        <path id="Контур_5448" data-name="Контур 5448" d="M101.443-236.572l5.231-3.019.521.368-5.21,3.026-.543-.374" transform="translate(-101.443 239.591)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-781">
        <path id="Контур_5451" data-name="Контур 5451" d="M100.256-232.883l.781-.454-.521-.368-.784.452.524.37" transform="translate(-99.732 233.705)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-783">
        <path id="Контур_5454" data-name="Контур 5454" d="M98.522-231.876l.782-.453-.521-.368-.784.452.523.369" transform="translate(-97.999 232.697)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-785">
        <path id="Контур_5457" data-name="Контур 5457" d="M95.35-236.365l.782-.453-.52-.369-.784.453.523.369" transform="translate(-94.827 237.187)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-787">
        <path id="Контур_5460" data-name="Контур 5460" d="M93.618-235.357l.782-.454-.521-.368-.783.452.522.369" transform="translate(-93.095 236.179)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-789">
        <path id="Контур_5463" data-name="Контур 5463" d="M102.629-235.861l.781-.453-.521-.368-.784.452.524.369" transform="translate(-102.105 236.683)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-791">
        <path id="Контур_5466" data-name="Контур 5466" d="M100.9-234.854l.782-.454-.521-.368-.783.453.523.369" transform="translate(-100.373 235.676)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-793">
        <path id="Контур_5469" data-name="Контур 5469" d="M106.111-237.885l.781-.454-.521-.368-.783.452.524.37" transform="translate(-105.587 238.707)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-795">
        <path id="Контур_5472" data-name="Контур 5472" d="M104.378-236.877l.781-.454-.521-.368-.784.452.524.369" transform="translate(-103.854 237.699)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-797">
        <path id="Контур_5475" data-name="Контур 5475" d="M109.592-239.908l.781-.453-.521-.368-.784.452.524.369" transform="translate(-109.068 240.73)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-799">
        <path id="Контур_5478" data-name="Контур 5478" d="M107.858-238.9l.782-.453-.521-.368-.783.453.522.369" transform="translate(-107.336 239.723)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-801">
        <path id="Контур_5481" data-name="Контур 5481" d="M111.34-240.924l.782-.454-.521-.368-.784.452.523.369" transform="translate(-110.817 241.746)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-803">
        <path id="Контур_5484" data-name="Контур 5484" d="M101.392-236.7l.782-.454-.52-.368-.784.452.522.369" transform="translate(-100.869 237.523)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-805">
        <path id="Контур_5487" data-name="Контур 5487" d="M99.659-235.694l.781-.453-.52-.368-.784.452.523.369" transform="translate(-99.136 236.516)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-807">
        <path id="Контур_5490" data-name="Контур 5490" d="M104.874-238.725l.781-.454-.521-.368-.783.453.524.369" transform="translate(-104.35 239.547)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-809">
        <path id="Контур_5493" data-name="Контур 5493" d="M103.14-237.717l.782-.454-.521-.368-.784.452.523.37" transform="translate(-102.617 238.539)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-811">
        <path id="Контур_5496" data-name="Контур 5496" d="M108.354-240.749l.782-.453-.521-.368-.783.452.523.369" transform="translate(-107.831 241.57)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-813">
        <path id="Контур_5499" data-name="Контур 5499" d="M106.622-239.741l.781-.453-.521-.368-.784.452.524.369" transform="translate(-106.098 240.563)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-815">
        <path id="Контур_5502" data-name="Контур 5502" d="M111.836-242.772l.782-.453-.521-.368-.783.452.522.369" transform="translate(-111.313 243.594)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-817">
        <path id="Контур_5505" data-name="Контур 5505" d="M110.1-241.764l.782-.454-.521-.367-.783.452.522.369" transform="translate(-109.58 242.586)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-819">
        <path id="Контур_5508" data-name="Контур 5508" d="M113.584-243.788l.782-.453-.521-.368-.783.452.523.369" transform="translate(-113.061 244.61)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-821">
        <path id="Контур_5511" data-name="Контур 5511" d="M100.207-237.528l.781-.453-.521-.368-.783.452.523.37" transform="translate(-99.684 238.35)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-823">
        <path id="Контур_5514" data-name="Контур 5514" d="M98.474-236.521l.781-.454-.52-.368-.784.452.523.37" transform="translate(-97.951 237.343)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-825">
        <path id="Контур_5517" data-name="Контур 5517" d="M103.688-239.552l.782-.454-.521-.368-.783.452.523.37" transform="translate(-103.165 240.374)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-827">
        <path id="Контур_5520" data-name="Контур 5520" d="M101.956-238.545l.781-.453-.521-.368-.783.452.524.369" transform="translate(-101.432 239.366)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-829">
        <path id="Контур_5523" data-name="Контур 5523" d="M107.169-241.576l.781-.453-.52-.368-.784.452.523.369" transform="translate(-106.646 242.397)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-831">
        <path id="Контур_5526" data-name="Контур 5526" d="M105.436-240.569l.782-.453-.521-.369-.784.453.523.369" transform="translate(-104.913 241.391)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-833">
        <path id="Контур_5529" data-name="Контур 5529" d="M110.651-243.6l.782-.454-.522-.368-.783.452.524.37" transform="translate(-110.127 244.421)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-835">
        <path id="Контур_5532" data-name="Контур 5532" d="M108.918-242.592l.781-.454-.521-.368-.783.452.523.37" transform="translate(-108.395 243.414)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-837">
        <path id="Контур_5535" data-name="Контур 5535" d="M112.4-244.616l.782-.453-.521-.368-.783.452.523.369" transform="translate(-111.876 245.437)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-839">
        <path id="Контур_5538" data-name="Контур 5538" d="M98.934-238.411l.782-.453-.521-.368-.784.452.523.369" transform="translate(-98.411 239.233)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-841">
        <path id="Контур_5541" data-name="Контур 5541" d="M97.2-237.4l.781-.453-.521-.369-.783.453.523.369" transform="translate(-96.678 238.227)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-843">
        <path id="Контур_5544" data-name="Контур 5544" d="M102.416-240.435l.781-.454-.52-.368-.783.453.523.369" transform="translate(-101.893 241.257)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-845">
        <path id="Контур_5547" data-name="Контур 5547" d="M100.682-239.427l.782-.454-.521-.368-.784.452.523.37" transform="translate(-100.159 240.249)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-847">
        <path id="Контур_5550" data-name="Контур 5550" d="M105.9-242.458l.782-.454-.522-.368-.783.452.524.37" transform="translate(-105.373 243.28)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-849">
        <path id="Контур_5553" data-name="Контур 5553" d="M104.163-241.451l.782-.454-.521-.368-.783.452.522.369" transform="translate(-103.641 242.273)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-851">
        <path id="Контур_5556" data-name="Контур 5556" d="M109.378-244.483l.781-.453-.52-.369-.784.453.524.369" transform="translate(-108.854 245.305)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-853">
        <path id="Контур_5559" data-name="Контур 5559" d="M107.645-243.475l.782-.454-.521-.368-.783.453.523.369" transform="translate(-107.122 244.297)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-855">
        <path id="Контур_5562" data-name="Контур 5562" d="M111.127-245.5l.781-.454-.52-.368-.783.452.523.369" transform="translate(-110.604 246.32)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-857">
        <path id="Контур_5565" data-name="Контур 5565" d="M120.3-246.164l.781-.453-.521-.368-.784.452.524.369" transform="translate(-119.775 246.985)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-859">
        <path id="Контур_5568" data-name="Контур 5568" d="M118.566-245.157l.781-.453-.521-.369-.784.453.524.369" transform="translate(-118.042 245.979)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-861">
        <path id="Контур_5571" data-name="Контур 5571" d="M122.047-247.18l.781-.454-.521-.368-.784.452.524.37" transform="translate(-121.523 248.002)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-863">
        <path id="Контур_5574" data-name="Контур 5574" d="M119.061-247l.782-.454-.521-.368-.784.452.523.369" transform="translate(-118.538 247.826)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-865">
        <path id="Контур_5577" data-name="Контур 5577" d="M117.329-246l.781-.453-.521-.368-.783.452.524.369" transform="translate(-116.805 246.818)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-867">
        <path id="Контур_5580" data-name="Контур 5580" d="M120.809-248.02l.782-.454-.521-.368-.784.452.523.369" transform="translate(-120.286 248.842)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-869">
        <path id="Контур_5583" data-name="Контур 5583" d="M117.876-247.831l.782-.454-.521-.367-.783.452.522.369" transform="translate(-117.354 248.652)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-871">
        <path id="Контур_5586" data-name="Контур 5586" d="M116.143-246.824l.782-.454-.521-.368-.784.452.523.37" transform="translate(-115.62 247.646)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-873">
        <path id="Контур_5589" data-name="Контур 5589" d="M119.624-248.847l.782-.454-.521-.368-.783.452.522.369" transform="translate(-119.102 249.669)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-875">
        <path id="Контур_5592" data-name="Контур 5592" d="M116.6-248.714l.782-.453-.521-.368-.784.452.523.369" transform="translate(-116.08 249.536)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-877">
        <path id="Контур_5595" data-name="Контур 5595" d="M114.87-247.707l.782-.453-.521-.368-.783.452.522.369" transform="translate(-114.348 248.529)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-879">
        <path id="Контур_5598" data-name="Контур 5598" d="M118.352-249.73l.782-.454-.521-.368-.784.452.524.37" transform="translate(-117.828 250.552)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-881">
        <path id="Контур_5601" data-name="Контур 5601" d="M92.337-236.242l.781-.454-.52-.367-.784.452.524.369" transform="translate(-91.813 237.063)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-883">
        <path id="Контур_5604" data-name="Контур 5604" d="M97.272-233.229l1.708-.979-.521-.368-1.709.976.523.37" transform="translate(-96.749 234.576)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-885">
        <path id="Контур_5607" data-name="Контур 5607" d="M113.071-242.4l1.707-.978-.521-.368-1.709.977.522.37" transform="translate(-112.549 243.747)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-887">
        <path id="Контур_5610" data-name="Контур 5610" d="M96.076-234.069l1.708-.979-.521-.368-1.709.977.522.369" transform="translate(-95.553 235.416)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-889">
        <path id="Контур_5613" data-name="Контур 5613" d="M94.888-234.91l1.708-.978-.521-.368-1.709.977.523.369" transform="translate(-94.365 236.256)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-891">
        <path id="Контур_5616" data-name="Контур 5616" d="M226.635-311.495s-.041-1.094,1.5-1.053c0,0,2.22.527,2.142,1.944,0,0-.806,1.741-3.64-.891" transform="translate(-226.635 312.549)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-893">
        <path id="Контур_5619" data-name="Контур 5619" d="M226.773-311.472a1,1,0,0,0-.138.486c2.834,2.632,3.641.891,3.641.891a1.292,1.292,0,0,0-.023-.3c-.335.367-1.309.93-3.48-1.078" transform="translate(-226.635 311.472)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-895">
        <path id="Контур_5622" data-name="Контур 5622" d="M130.312-263.9l-6.3,3.638,6.1,3.508,6.284-3.649-6.086-3.5" transform="translate(-124.009 263.905)" fill="#afb5ca" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-897">
        <path id="Контур_5625" data-name="Контур 5625" d="M132.295-259.164s-.04-1.093,1.5-1.052c0,0,2.22.526,2.142,1.944,0,0-.806,1.741-3.641-.891" transform="translate(-132.295 260.217)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-899">
        <path id="Контур_5628" data-name="Контур 5628" d="M132.434-259.141a1.009,1.009,0,0,0-.139.486c2.835,2.633,3.641.891,3.641.891a1.261,1.261,0,0,0-.022-.3c-.334.367-1.309.93-3.48-1.078" transform="translate(-132.295 259.141)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-901">
        <path id="Контур_5631" data-name="Контур 5631" d="M205.848-342.961l-2.222,1.284V-330.1l2.222-1.284v-11.577" transform="translate(-203.626 342.961)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-903">
        <path id="Контур_5634" data-name="Контур 5634" d="M202.636-341.1l.522.3v11.577l-.522-.3V-341.1" transform="translate(-202.636 341.105)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-905">
        <path id="Контур_5637" data-name="Контур 5637" d="M202.636-342.253l.522.3,2.222-1.284-.523-.3-2.222,1.284" transform="translate(-202.636 343.537)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-907">
        <path id="Контур_5640" data-name="Контур 5640" d="M209.9-319.549l-2.215,1.288v.465l2.215-1.288v-.465" transform="translate(-207.681 319.549)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-909">
        <path id="Контур_5643" data-name="Контур 5643" d="M203.626-319.477l2.14,1.25v.465l-2.14-1.249v-.466" transform="translate(-203.626 319.477)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-911">
        <path id="Контур_5646" data-name="Контур 5646" d="M203.626-320.625l2.14,1.25,2.215-1.288-2.133-1.245-2.222,1.283" transform="translate(-203.626 321.908)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-913">
        <path id="Контур_5649" data-name="Контур 5649" d="M177.02-326.2l-2.222,1.283v11.577l2.222-1.284V-326.2" transform="translate(-174.798 326.197)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-915">
        <path id="Контур_5652" data-name="Контур 5652" d="M173.808-324.342l.522.3v11.577l-.522-.3v-11.577" transform="translate(-173.808 324.342)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-917">
        <path id="Контур_5655" data-name="Контур 5655" d="M173.808-325.49l.522.3,2.222-1.283-.522-.3-2.222,1.284" transform="translate(-173.808 326.774)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-919">
        <path id="Контур_5658" data-name="Контур 5658" d="M181.068-302.785l-2.215,1.287v.466l2.215-1.288v-.465" transform="translate(-178.853 302.785)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-921">
        <path id="Контур_5661" data-name="Контур 5661" d="M174.8-302.713l2.14,1.249V-301l-2.14-1.249v-.466" transform="translate(-174.798 302.713)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-923">
        <path id="Контур_5664" data-name="Контур 5664" d="M174.8-303.861l2.14,1.249,2.215-1.287-2.133-1.246-2.222,1.284" transform="translate(-174.798 305.145)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-925">
        <path id="Контур_5667" data-name="Контур 5667" d="M192.275-358.1l-22.659,13.088v15.754l22.659-13.088V-358.1" transform="translate(-169.616 358.103)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-927">
        <path id="Контур_5670" data-name="Контур 5670" d="M167.1-334.777l1.33.777v15.754l-1.33-.778v-15.753" transform="translate(-167.096 334.777)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-929">
        <path id="Контур_5673" data-name="Контур 5673" d="M167.1-346.487l1.33.777L191.085-358.8l-1.331-.777L167.1-346.487" transform="translate(-167.096 359.575)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-931">
        <path id="Контур_5676" data-name="Контур 5676" d="M191.3-342.91l-20.715,11.965v-13.509L191.3-356.419Zm-21.687-2.105v15.754l22.659-13.088V-358.1l-22.659,13.088Z" transform="translate(-169.616 358.103)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-933">
        <path id="Контур_5679" data-name="Контур 5679" d="M106.284-286.328l-2.221,1.284v11.577l2.221-1.284v-11.577" transform="translate(-104.063 286.328)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-935">
        <path id="Контур_5682" data-name="Контур 5682" d="M103.072-284.473l.523.3v11.577l-.523-.3v-11.577" transform="translate(-103.072 284.473)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-937">
        <path id="Контур_5685" data-name="Контур 5685" d="M103.072-285.621l.523.3,2.221-1.284-.522-.3-2.221,1.283" transform="translate(-103.072 286.904)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-939">
        <path id="Контур_5688" data-name="Контур 5688" d="M110.332-262.916l-2.215,1.288v.465l2.215-1.288v-.465" transform="translate(-108.117 262.916)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-941">
        <path id="Контур_5691" data-name="Контур 5691" d="M104.063-262.843l2.14,1.249v.465l-2.14-1.249v-.465" transform="translate(-104.063 262.843)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-943">
        <path id="Контур_5694" data-name="Контур 5694" d="M104.063-263.991l2.14,1.249,2.215-1.288-2.133-1.245-2.221,1.284" transform="translate(-104.063 265.275)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-945">
        <path id="Контур_5697" data-name="Контур 5697" d="M77.455-269.564l-2.222,1.284V-256.7l2.222-1.284v-11.577" transform="translate(-75.233 269.564)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-947">
        <path id="Контур_5700" data-name="Контур 5700" d="M74.243-267.709l.522.3v11.577l-.522-.3v-11.577" transform="translate(-74.243 267.709)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-949">
        <path id="Контур_5703" data-name="Контур 5703" d="M74.243-268.857l.522.3,2.222-1.284-.522-.3-2.222,1.284" transform="translate(-74.243 270.141)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-951">
        <path id="Контур_5706" data-name="Контур 5706" d="M81.5-246.152l-2.215,1.288v.465l2.215-1.288v-.465" transform="translate(-79.289 246.152)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-953">
        <path id="Контур_5709" data-name="Контур 5709" d="M75.233-246.079l2.141,1.25v.465l-2.141-1.249v-.465" transform="translate(-75.233 246.079)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-955">
        <path id="Контур_5712" data-name="Контур 5712" d="M75.233-247.227l2.141,1.25,2.215-1.288-2.133-1.245-2.222,1.284" transform="translate(-75.233 248.511)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-957">
        <path id="Контур_5715" data-name="Контур 5715" d="M92.711-301.469,70.053-288.382v15.754l22.658-13.088v-15.753" transform="translate(-70.053 301.469)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-959">
        <path id="Контур_5718" data-name="Контур 5718" d="M67.531-278.145l1.331.777v15.754l-1.331-.777v-15.754" transform="translate(-67.531 278.145)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-961">
        <path id="Контур_5721" data-name="Контур 5721" d="M67.531-289.855l1.331.777L91.52-302.165l-1.33-.777L67.531-289.855" transform="translate(-67.531 302.942)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-963">
        <path id="Контур_5724" data-name="Контур 5724" d="M91.74-286.277,71.025-274.312V-287.82L91.74-299.785Zm-21.687-2.1v15.754l22.658-13.088v-15.753L70.053-288.382Z" transform="translate(-70.053 301.469)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-965">
        <path id="Контур_5727" data-name="Контур 5727" d="M213.886-342.546h28.04V-358.1h-28.04Z" transform="translate(-213.886 358.103)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-967">
        <path id="Контур_5730" data-name="Контур 5730" d="M216.092-342.658H241.8v-13.427H216.092Z" transform="translate(-216.092 356.085)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-969">
        <path id="Контур_5733" data-name="Контур 5733" d="M213.886-359.224h28.04v-1.252h-28.04Z" transform="translate(-213.886 360.476)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-971">
        <path id="Контур_5736" data-name="Контур 5736" d="M221.007-326.855h3.766v-1.771h-3.766Z" transform="translate(-221.007 328.626)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-973">
        <path id="Контур_5739" data-name="Контур 5739" d="M221.007-323.225h3.766v-2.045h-3.766Z" transform="translate(-221.007 325.27)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-975">
        <path id="Контур_5742" data-name="Контур 5742" d="M253-323.225h3.766v-2.045H253Z" transform="translate(-253 325.27)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-977">
        <path id="Контур_5745" data-name="Контур 5745" d="M221.007-320.91h3.766v-.486h-3.766Z" transform="translate(-221.007 321.396)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-979">
        <path id="Контур_5748" data-name="Контур 5748" d="M253-320.91h3.766v-.486H253Z" transform="translate(-253 321.396)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-981">
        <path id="Контур_5751" data-name="Контур 5751" d="M253-326.855h3.766v-1.771H253Z" transform="translate(-253 328.626)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-983">
        <path id="Контур_5754" data-name="Контур 5754" d="M149.789-219.243,138.2-225.936a1.579,1.579,0,0,0-1.154-.166,2.467,2.467,0,0,0-1.037.666c-.27.255-.584.846-.185,1.138a28.644,28.644,0,0,0,2.545,1.478l2.659,1.544q2.6,1.508,5.185,3.028c.6.349,1.384,1.037,2.117.984a2.75,2.75,0,0,0,1.764-1.042.746.746,0,0,0-.309-.937" transform="translate(-135.639 226.146)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-985">
        <path id="Контур_5757" data-name="Контур 5757" d="M147.067-217.813q-2.845-1.663-5.692-3.324l-2.919-1.695c-.86-.5-1.819-.946-2.639-1.516-.186.288-.29.678.011.9a28.644,28.644,0,0,0,2.545,1.478l2.659,1.544q2.6,1.508,5.185,3.028c.6.349,1.384,1.037,2.117.984a2.675,2.675,0,0,0,.916-.316,5.554,5.554,0,0,1-2.182-1.082" transform="translate(-135.639 224.348)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-987">
        <path id="Контур_5760" data-name="Контур 5760" d="M118.332-161.751a1.428,1.428,0,0,0,1.371,1.478,1.428,1.428,0,0,0,1.371-1.478,1.427,1.427,0,0,0-1.371-1.478,1.428,1.428,0,0,0-1.371,1.478" transform="translate(-118.332 163.229)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-989">
        <path id="Контур_5763" data-name="Контур 5763" d="M157.4-162.279a1.428,1.428,0,0,0,1.371,1.478,1.427,1.427,0,0,0,1.371-1.478,1.427,1.427,0,0,0-1.371-1.478,1.428,1.428,0,0,0-1.371,1.478" transform="translate(-157.404 163.757)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-991">
        <path id="Контур_5766" data-name="Контур 5766" d="M158.506-182.964a1.427,1.427,0,0,0,1.371,1.478,1.427,1.427,0,0,0,1.371-1.478,1.428,1.428,0,0,0-1.371-1.478,1.428,1.428,0,0,0-1.371,1.478" transform="translate(-158.506 184.442)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-993">
        <path id="Контур_5769" data-name="Контур 5769" d="M116.188-184.057a1.427,1.427,0,0,0,1.372,1.477,1.427,1.427,0,0,0,1.371-1.477,1.428,1.428,0,0,0-1.371-1.479,1.428,1.428,0,0,0-1.372,1.479" transform="translate(-116.188 185.536)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-995">
        <path id="Контур_5772" data-name="Контур 5772" d="M128.5-180.022l-9.474,6.689-1.394-.673,9.122-7.526,0-.89-8.864-2.537.449-.577,10.163,1.828h2.437l9.393-1.667.8.545-8.72,2.4v.895l8.954,7.606-.833.609-9.787-6.736-2.245.032" transform="translate(-117.631 185.536)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-997">
        <path id="Контур_5775" data-name="Контур 5775" d="M140.153-184.053a3.256,3.256,0,0,1-3.342,0V-190.3h3.342v6.252" transform="translate(-136.811 190.305)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-999">
        <path id="Контур_5778" data-name="Контур 5778" d="M118.116-184.442l.218.929,8.648,2.5,0-.89-8.864-2.537" transform="translate(-118.116 184.442)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1001">
        <path id="Контур_5781" data-name="Контур 5781" d="M154.354-184.2l-8.72,2.4v.895l8.481-2.308.239-.99" transform="translate(-145.634 184.199)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1003">
        <path id="Контур_5784" data-name="Контур 5784" d="M152.136-168.412l-.746.754-9.185-6.107.143-1.383,9.788,6.736" transform="translate(-142.205 175.148)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1005">
        <path id="Контур_5787" data-name="Контур 5787" d="M129.746-175.088v1.3l-8.887,6.323-.587-.937,9.474-6.689" transform="translate(-120.272 175.088)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1007">
        <path id="Контур_5790" data-name="Контур 5790" d="M140.325-173.765l-2.1-.048v-1.3l2.245-.032-.143,1.383" transform="translate(-138.223 175.148)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1009">
        <path id="Контур_5793" data-name="Контур 5793" d="M161.187-163.538l-.737.866-.842.5.746-.754.833-.609" transform="translate(-159.608 163.538)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1011">
        <path id="Контур_5796" data-name="Контур 5796" d="M119.612-162.079l-1.49-.7-.491-.913,1.394.673.587.937" transform="translate(-117.631 163.689)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1013">
        <path id="Контур_5799" data-name="Контур 5799" d="M151.293-202.2l-13.446,7.748v3.17l13.445-7.822v-3.1" transform="translate(-137.847 202.198)" fill="#9ba2ba" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1014">
        <path id="Контур_5798" data-name="Контур 5798" d="M-92-286.784H330.216V-709H-92Z" transform="translate(92 709)" fill="#9ba2ba"/>
      </clipPath>
      <clipPath id="clip-path-1015">
        <path id="Контур_5802" data-name="Контур 5802" d="M125.5-194.74l-13.8-8.071v3.17l13.8,8.071,0-3.17" transform="translate(-111.694 202.811)" fill="#a7aec6" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1016">
        <path id="Контур_5801" data-name="Контур 5801" d="M-92-286.784H330.216V-709H-92Z" transform="translate(92 709)" fill="#a7aec6"/>
      </clipPath>
      <clipPath id="clip-path-1017">
        <path id="Контур_5805" data-name="Контур 5805" d="M138.943-209.406l-13.854-8.056-13.395,7.732,13.8,8.071,13.446-7.748" transform="translate(-111.694 217.462)" fill="#b6bed3" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1018">
        <path id="Контур_5804" data-name="Контур 5804" d="M-92-286.784H330.216V-709H-92Z" transform="translate(92 709)" fill="#b6bed3"/>
      </clipPath>
      <clipPath id="clip-path-1019">
        <path id="Контур_5808" data-name="Контур 5808" d="M155.16-229.107l-13.451,7.672-3.871,14.277,13.451-7.748,3.871-14.2" transform="translate(-137.838 229.107)" fill="#949bb2" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1021">
        <path id="Контур_5811" data-name="Контур 5811" d="M139.574-215.8l-2.386-1.374L133.317-202.9l2.386,1.374,3.871-14.277" transform="translate(-133.317 217.173)" fill="#a7aec6" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1023">
        <path id="Контур_5814" data-name="Контур 5814" d="M156.489-230.342l-2.4-1.381-13.435,7.679,2.386,1.374,13.451-7.672" transform="translate(-140.652 231.723)" fill="#a7afc7" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1024">
        <path id="Контур_5813" data-name="Контур 5813" d="M-92-286.784H330.216V-709H-92Z" transform="translate(92 709)" fill="#a7afc7"/>
      </clipPath>
      <clipPath id="clip-path-1025">
        <path id="Контур_5817" data-name="Контур 5817" d="M125.166-205.04l-11.585-6.693a1.579,1.579,0,0,0-1.155-.166,2.474,2.474,0,0,0-1.037.665c-.269.255-.584.847-.185,1.138a28.665,28.665,0,0,0,2.545,1.478l2.659,1.544q2.6,1.508,5.185,3.028c.6.349,1.384,1.037,2.117.984a2.748,2.748,0,0,0,1.764-1.041.746.746,0,0,0-.308-.937" transform="translate(-111.015 211.943)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1027">
        <path id="Контур_5820" data-name="Контур 5820" d="M122.444-203.61q-2.845-1.664-5.692-3.324l-2.919-1.695c-.86-.5-1.819-.946-2.639-1.516-.186.288-.29.678.01.9a28.657,28.657,0,0,0,2.545,1.478l2.659,1.544q2.6,1.508,5.185,3.028c.6.349,1.384,1.037,2.117.984a2.664,2.664,0,0,0,.916-.316,5.546,5.546,0,0,1-2.182-1.081" transform="translate(-111.015 210.145)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1029">
        <path id="Контур_5823" data-name="Контур 5823" d="M272.027-292.276l-11.585-6.693a1.58,1.58,0,0,0-1.155-.167,2.476,2.476,0,0,0-1.037.665c-.27.255-.584.847-.185,1.137a28.389,28.389,0,0,0,2.546,1.479l2.659,1.544q2.6,1.507,5.185,3.028c.6.348,1.384,1.037,2.116.983a2.75,2.75,0,0,0,1.764-1.041.746.746,0,0,0-.308-.937" transform="translate(-257.876 299.179)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1031">
        <path id="Контур_5826" data-name="Контур 5826" d="M269.3-290.846q-2.844-1.665-5.692-3.324l-2.919-1.7c-.86-.5-1.819-.946-2.64-1.516-.185.288-.289.678.011.9a28.457,28.457,0,0,0,2.545,1.479l2.66,1.544q2.6,1.507,5.185,3.028c.6.348,1.384,1.037,2.116.983a2.662,2.662,0,0,0,.916-.315,5.553,5.553,0,0,1-2.182-1.081" transform="translate(-257.876 297.381)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1033">
        <path id="Контур_5829" data-name="Контур 5829" d="M240.569-234.784a1.427,1.427,0,0,0,1.371,1.478,1.428,1.428,0,0,0,1.372-1.478,1.428,1.428,0,0,0-1.372-1.478,1.427,1.427,0,0,0-1.371,1.478" transform="translate(-240.569 236.262)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1035">
        <path id="Контур_5832" data-name="Контур 5832" d="M279.641-235.313a1.428,1.428,0,0,0,1.371,1.478,1.428,1.428,0,0,0,1.372-1.478,1.428,1.428,0,0,0-1.372-1.478,1.428,1.428,0,0,0-1.371,1.478" transform="translate(-279.641 236.791)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1037">
        <path id="Контур_5835" data-name="Контур 5835" d="M280.743-256a1.428,1.428,0,0,0,1.372,1.478A1.427,1.427,0,0,0,283.485-256a1.427,1.427,0,0,0-1.371-1.478A1.428,1.428,0,0,0,280.743-256" transform="translate(-280.743 257.477)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1039">
        <path id="Контур_5838" data-name="Контур 5838" d="M238.426-257.091a1.427,1.427,0,0,0,1.371,1.478,1.428,1.428,0,0,0,1.372-1.478,1.427,1.427,0,0,0-1.372-1.478,1.427,1.427,0,0,0-1.371,1.478" transform="translate(-238.426 258.569)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1041">
        <path id="Контур_5841" data-name="Контур 5841" d="M250.735-253.055l-9.473,6.689-1.395-.674,9.122-7.525,0-.889-8.864-2.538.449-.576,10.162,1.827h2.437l9.394-1.667.8.545-8.721,2.4v.895l8.955,7.606-.834.609-9.787-6.736-2.245.032" transform="translate(-239.867 258.569)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1043">
        <path id="Контур_5844" data-name="Контур 5844" d="M262.391-257.086a3.255,3.255,0,0,1-3.342,0v-6.252h3.342v6.252" transform="translate(-259.049 263.338)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1045">
        <path id="Контур_5847" data-name="Контур 5847" d="M240.354-257.477l.218.93,8.648,2.5,0-.889-8.864-2.538" transform="translate(-240.354 257.477)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1047">
        <path id="Контур_5850" data-name="Контур 5850" d="M276.592-257.233l-8.721,2.4v.895l8.481-2.308.24-.99" transform="translate(-267.871 257.233)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1049">
        <path id="Контур_5853" data-name="Контур 5853" d="M274.372-241.446l-.745.754-9.185-6.107.143-1.383,9.787,6.736" transform="translate(-264.442 248.182)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1051">
        <path id="Контур_5856" data-name="Контур 5856" d="M251.983-248.121v1.3l-8.887,6.324-.587-.937,9.473-6.689" transform="translate(-242.51 248.121)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1053">
        <path id="Контур_5859" data-name="Контур 5859" d="M262.562-246.8l-2.1-.048v-1.3l2.245-.032-.143,1.383" transform="translate(-260.46 248.182)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1055">
        <path id="Контур_5862" data-name="Контур 5862" d="M283.425-236.571l-.738.865-.841.5.745-.754.834-.609" transform="translate(-281.846 236.571)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1057">
        <path id="Контур_5865" data-name="Контур 5865" d="M241.849-235.113l-1.491-.7-.491-.914,1.395.674.587.937" transform="translate(-239.867 236.724)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1059">
        <path id="Контур_5868" data-name="Контур 5868" d="M273.531-275.231l-13.447,7.747v3.171l13.445-7.822v-3.1" transform="translate(-260.084 275.231)" fill="#9ba2ba" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1061">
        <path id="Контур_5871" data-name="Контур 5871" d="M247.734-267.774l-13.8-8.071v3.17l13.8,8.071v-3.171" transform="translate(-233.932 275.845)" fill="#a7aec6" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1063">
        <path id="Контур_5874" data-name="Контур 5874" d="M261.181-282.44,247.327-290.5l-13.4,7.732,13.8,8.071,13.446-7.747" transform="translate(-233.932 290.496)" fill="#b6bed3" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1065">
        <path id="Контур_5877" data-name="Контур 5877" d="M277.4-302.141l-13.452,7.672-3.87,14.277,13.451-7.747,3.871-14.2" transform="translate(-260.076 302.141)" fill="#949bb2" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1067">
        <path id="Контур_5880" data-name="Контур 5880" d="M261.811-288.833l-2.384-1.374-3.872,14.277,2.386,1.374,3.87-14.277" transform="translate(-255.555 290.207)" fill="#a7aec6" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1069">
        <path id="Контур_5883" data-name="Контур 5883" d="M278.727-303.376l-2.4-1.38-13.434,7.679,2.384,1.374,13.452-7.672" transform="translate(-262.891 304.756)" fill="#a7afc7" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1071">
        <path id="Контур_5886" data-name="Контур 5886" d="M247.4-278.074l-11.585-6.693a1.582,1.582,0,0,0-1.155-.167,2.474,2.474,0,0,0-1.037.666c-.269.255-.583.846-.185,1.137a28.5,28.5,0,0,0,2.545,1.479l2.659,1.544q2.6,1.508,5.185,3.028c.6.349,1.384,1.037,2.116.985a2.755,2.755,0,0,0,1.764-1.041.746.746,0,0,0-.308-.937" transform="translate(-233.253 284.977)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1073">
        <path id="Контур_5889" data-name="Контур 5889" d="M244.681-276.643q-2.844-1.663-5.692-3.324l-2.919-1.7c-.86-.5-1.819-.946-2.64-1.516-.185.288-.289.677.011.9a28.521,28.521,0,0,0,2.545,1.479l2.659,1.544q2.6,1.508,5.185,3.028c.6.349,1.384,1.037,2.116.985a2.673,2.673,0,0,0,.916-.316,5.55,5.55,0,0,1-2.182-1.082" transform="translate(-233.253 283.178)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1075">
        <path id="Контур_5892" data-name="Контур 5892" d="M307.344-129.692l-11.585-6.693a1.584,1.584,0,0,0-1.156-.167,2.473,2.473,0,0,0-1.036.666c-.269.254-.583.846-.185,1.137a28.445,28.445,0,0,0,2.545,1.479l2.659,1.544q2.6,1.507,5.185,3.028c.6.349,1.384,1.037,2.117.984a2.749,2.749,0,0,0,1.764-1.041.748.748,0,0,0-.308-.937" transform="translate(-293.193 136.595)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1077">
        <path id="Контур_5895" data-name="Контур 5895" d="M304.622-128.262q-2.845-1.664-5.692-3.324l-2.92-1.695c-.86-.5-1.818-.946-2.639-1.516-.185.288-.29.678.011.9a28.446,28.446,0,0,0,2.545,1.479l2.659,1.544q2.6,1.507,5.185,3.028c.6.349,1.384,1.037,2.116.984a2.665,2.665,0,0,0,.916-.315,5.548,5.548,0,0,1-2.182-1.082" transform="translate(-293.192 134.797)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1079">
        <path id="Контур_5898" data-name="Контур 5898" d="M275.886-72.2a1.428,1.428,0,0,0,1.371,1.478,1.428,1.428,0,0,0,1.371-1.478,1.428,1.428,0,0,0-1.371-1.478,1.428,1.428,0,0,0-1.371,1.478" transform="translate(-275.886 73.679)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1081">
        <path id="Контур_5901" data-name="Контур 5901" d="M314.958-72.728a1.428,1.428,0,0,0,1.372,1.478,1.428,1.428,0,0,0,1.371-1.478,1.428,1.428,0,0,0-1.371-1.478,1.428,1.428,0,0,0-1.372,1.478" transform="translate(-314.958 74.206)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1083">
        <path id="Контур_5904" data-name="Контур 5904" d="M316.061-93.414a1.427,1.427,0,0,0,1.371,1.478,1.428,1.428,0,0,0,1.371-1.478,1.427,1.427,0,0,0-1.371-1.478,1.427,1.427,0,0,0-1.371,1.478" transform="translate(-316.061 94.892)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1085">
        <path id="Контур_5907" data-name="Контур 5907" d="M273.742-94.508a1.428,1.428,0,0,0,1.372,1.479,1.428,1.428,0,0,0,1.371-1.479,1.427,1.427,0,0,0-1.371-1.477,1.427,1.427,0,0,0-1.372,1.477" transform="translate(-273.742 95.985)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1087">
        <path id="Контур_5910" data-name="Контур 5910" d="M286.053-90.471l-9.474,6.688-1.394-.673,9.122-7.525,0-.89-8.864-2.538.449-.577,10.163,1.827h2.437l9.393-1.667.8.546-8.72,2.4v.895l8.954,7.606-.834.609L288.3-90.5l-2.244.032" transform="translate(-275.185 95.985)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1089">
        <path id="Контур_5913" data-name="Контур 5913" d="M297.706-94.5a3.256,3.256,0,0,1-3.342,0v-6.252h3.342V-94.5" transform="translate(-294.364 100.754)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1091">
        <path id="Контур_5916" data-name="Контур 5916" d="M275.67-94.892l.219.929,8.647,2.5,0-.89-8.864-2.538" transform="translate(-275.67 94.892)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1093">
        <path id="Контур_5919" data-name="Контур 5919" d="M311.908-94.648l-8.72,2.4v.895l8.481-2.308.239-.99" transform="translate(-303.188 94.648)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1095">
        <path id="Контур_5922" data-name="Контур 5922" d="M309.689-78.861l-.745.753-9.185-6.107L299.9-85.6l9.788,6.737" transform="translate(-299.759 85.598)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1097">
        <path id="Контур_5925" data-name="Контур 5925" d="M287.3-85.537v1.3l-8.887,6.323-.587-.938,9.474-6.688" transform="translate(-277.826 85.537)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1099">
        <path id="Контур_5928" data-name="Контур 5928" d="M297.879-84.215l-2.1-.048v-1.3l2.244-.032-.142,1.383" transform="translate(-295.777 85.598)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1101">
        <path id="Контур_5931" data-name="Контур 5931" d="M318.741-73.987l-.737.865-.842.5.745-.753.834-.609" transform="translate(-317.162 73.987)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1103">
        <path id="Контур_5934" data-name="Контур 5934" d="M277.166-72.528l-1.491-.7-.49-.914,1.394.673.587.938" transform="translate(-275.185 74.139)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1105">
        <path id="Контур_5937" data-name="Контур 5937" d="M308.846-112.647,295.4-104.9v3.17l13.445-7.822v-3.1" transform="translate(-295.4 112.647)" fill="#9ba2ba" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1107">
        <path id="Контур_5940" data-name="Контур 5940" d="M283.05-105.189l-13.8-8.071v3.17l13.8,8.071v-3.17" transform="translate(-269.248 113.26)" fill="#a7aec6" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1109">
        <path id="Контур_5943" data-name="Контур 5943" d="M296.5-119.855l-13.853-8.056-13.4,7.732,13.8,8.071,13.447-7.747" transform="translate(-269.248 127.911)" fill="#b6bed3" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1111">
        <path id="Контур_5946" data-name="Контур 5946" d="M312.714-139.557l-13.451,7.672-3.871,14.277,13.45-7.747,3.871-14.2" transform="translate(-295.393 139.557)" fill="#949bb2" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1113">
        <path id="Контур_5949" data-name="Контур 5949" d="M297.128-126.249l-2.386-1.374-3.871,14.277,2.387,1.374,3.871-14.277" transform="translate(-290.871 127.623)" fill="#a7aec6" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1115">
        <path id="Контур_5952" data-name="Контур 5952" d="M314.043-140.792l-2.4-1.38-13.435,7.679,2.386,1.374,13.451-7.672" transform="translate(-298.206 142.172)" fill="#a7afc7" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1117">
        <path id="Контур_5955" data-name="Контур 5955" d="M282.721-115.489l-11.585-6.693a1.584,1.584,0,0,0-1.156-.167,2.48,2.48,0,0,0-1.037.666c-.269.255-.583.846-.184,1.137a28.245,28.245,0,0,0,2.545,1.479l2.659,1.544q2.6,1.508,5.185,3.028c.6.349,1.384,1.037,2.117.984a2.752,2.752,0,0,0,1.764-1.042.746.746,0,0,0-.308-.936" transform="translate(-268.569 122.393)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1119">
        <path id="Контур_5958" data-name="Контур 5958" d="M280-114.059q-2.844-1.664-5.692-3.325l-2.919-1.7c-.861-.5-1.819-.945-2.639-1.515-.185.288-.29.678.011.9a28.509,28.509,0,0,0,2.545,1.479l2.659,1.544q2.6,1.508,5.185,3.028c.6.349,1.384,1.037,2.116.984a2.67,2.67,0,0,0,.916-.316A5.552,5.552,0,0,1,280-114.059" transform="translate(-268.569 120.594)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1121">
        <path id="Контур_5961" data-name="Контур 5961" d="M429.457-197.581l-11.585-6.693a1.582,1.582,0,0,0-1.155-.166,2.471,2.471,0,0,0-1.037.666c-.269.255-.583.846-.185,1.137a28.57,28.57,0,0,0,2.545,1.478l2.659,1.544q2.6,1.508,5.185,3.028c.6.349,1.384,1.037,2.116.984a2.747,2.747,0,0,0,1.764-1.041.747.747,0,0,0-.308-.937" transform="translate(-415.306 204.484)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1123">
        <path id="Контур_5964" data-name="Контур 5964" d="M426.735-196.151q-2.845-1.663-5.692-3.324l-2.919-1.695c-.861-.5-1.819-.946-2.639-1.516-.185.288-.29.679.011.9a28.573,28.573,0,0,0,2.545,1.478l2.659,1.544q2.6,1.508,5.185,3.028c.6.349,1.384,1.037,2.116.984a2.663,2.663,0,0,0,.916-.315,5.543,5.543,0,0,1-2.182-1.082" transform="translate(-415.306 202.686)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1125">
        <path id="Контур_5967" data-name="Контур 5967" d="M398-140.088a1.428,1.428,0,0,0,1.372,1.478,1.427,1.427,0,0,0,1.371-1.478,1.427,1.427,0,0,0-1.371-1.478A1.428,1.428,0,0,0,398-140.088" transform="translate(-397.999 141.566)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1127">
        <path id="Контур_5970" data-name="Контур 5970" d="M437.071-140.617a1.428,1.428,0,0,0,1.372,1.478,1.428,1.428,0,0,0,1.371-1.478,1.428,1.428,0,0,0-1.371-1.478,1.428,1.428,0,0,0-1.372,1.478" transform="translate(-437.071 142.095)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1129">
        <path id="Контур_5973" data-name="Контур 5973" d="M438.174-161.3a1.427,1.427,0,0,0,1.371,1.477,1.428,1.428,0,0,0,1.372-1.477,1.428,1.428,0,0,0-1.372-1.478,1.428,1.428,0,0,0-1.371,1.478" transform="translate(-438.174 162.78)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1131">
        <path id="Контур_5976" data-name="Контур 5976" d="M395.855-162.4a1.428,1.428,0,0,0,1.372,1.478A1.428,1.428,0,0,0,398.6-162.4a1.428,1.428,0,0,0-1.371-1.478,1.428,1.428,0,0,0-1.372,1.478" transform="translate(-395.855 163.874)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1133">
        <path id="Контур_5979" data-name="Контур 5979" d="M408.166-158.36l-9.474,6.689-1.394-.673,9.122-7.526v-.89l-8.864-2.537.449-.577,10.163,1.828H410.6L420-163.714l.8.545-8.72,2.4v.894l8.955,7.606-.834.609-9.787-6.736-2.244.032" transform="translate(-397.298 163.874)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1135">
        <path id="Контур_5982" data-name="Контур 5982" d="M419.821-162.391a3.254,3.254,0,0,1-3.342,0v-6.252h3.342v6.252" transform="translate(-416.479 168.643)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1137">
        <path id="Контур_5985" data-name="Контур 5985" d="M397.784-162.78l.218.929,8.648,2.5v-.89l-8.864-2.537" transform="translate(-397.784 162.78)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1139">
        <path id="Контур_5988" data-name="Контур 5988" d="M434.021-162.537l-8.72,2.4v.894l8.481-2.308.239-.99" transform="translate(-425.301 162.537)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1141">
        <path id="Контур_5991" data-name="Контур 5991" d="M431.8-146.75l-.746.754-9.185-6.107.143-1.383,9.788,6.736" transform="translate(-421.872 153.486)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1143">
        <path id="Контур_5994" data-name="Контур 5994" d="M409.414-153.426v1.3l-8.887,6.323-.587-.937,9.474-6.689" transform="translate(-399.94 153.426)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1145">
        <path id="Контур_5997" data-name="Контур 5997" d="M419.992-152.1l-2.1-.048v-1.3l2.244-.032-.143,1.383" transform="translate(-417.891 153.486)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1147">
        <path id="Контур_6000" data-name="Контур 6000" d="M440.855-141.876l-.738.866-.842.5.746-.754.834-.609" transform="translate(-439.275 141.876)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1149">
        <path id="Контур_6003" data-name="Контур 6003" d="M399.279-140.417l-1.49-.7-.491-.913,1.394.673.587.937" transform="translate(-397.298 142.027)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1151">
        <path id="Контур_6006" data-name="Контур 6006" d="M430.96-180.536l-13.447,7.748,0,3.17,13.445-7.822v-3.1" transform="translate(-417.514 180.536)" fill="#9ba2ba" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1153">
        <path id="Контур_6009" data-name="Контур 6009" d="M405.164-173.078l-13.8-8.071v3.17l13.8,8.071,0-3.17" transform="translate(-391.362 181.149)" fill="#a7aec6" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1155">
        <path id="Контур_6012" data-name="Контур 6012" d="M418.611-187.744,404.757-195.8l-13.395,7.732,13.8,8.071,13.447-7.748" transform="translate(-391.362 195.8)" fill="#b6bed3" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1157">
        <path id="Контур_6015" data-name="Контур 6015" d="M434.827-207.445l-13.451,7.672-3.87,14.277,13.451-7.748,3.871-14.2" transform="translate(-417.506 207.445)" fill="#949bb2" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1159">
        <path id="Контур_6018" data-name="Контур 6018" d="M419.241-194.138l-2.385-1.374-3.871,14.277,2.387,1.374,3.87-14.277" transform="translate(-412.984 195.512)" fill="#a7aec6" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1161">
        <path id="Контур_6021" data-name="Контур 6021" d="M436.156-208.68l-2.4-1.381-13.435,7.679,2.386,1.374,13.451-7.672" transform="translate(-420.319 210.061)" fill="#a7afc7" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1163">
        <path id="Контур_6024" data-name="Контур 6024" d="M404.833-183.378l-11.585-6.693a1.582,1.582,0,0,0-1.155-.167,2.471,2.471,0,0,0-1.037.666c-.269.254-.583.846-.185,1.137a28.657,28.657,0,0,0,2.545,1.479l2.659,1.544q2.6,1.508,5.185,3.028c.6.349,1.384,1.036,2.116.984a2.753,2.753,0,0,0,1.764-1.041.747.747,0,0,0-.309-.937" transform="translate(-390.683 190.281)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1165">
        <path id="Контур_6027" data-name="Контур 6027" d="M402.112-181.947q-2.845-1.663-5.692-3.324l-2.92-1.7c-.86-.5-1.818-.946-2.639-1.516-.185.288-.289.678.011.9a28.657,28.657,0,0,0,2.545,1.479l2.659,1.544q2.6,1.508,5.185,3.028c.6.349,1.384,1.036,2.116.984a2.67,2.67,0,0,0,.916-.316,5.542,5.542,0,0,1-2.182-1.081" transform="translate(-390.683 188.482)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1167">
        <path id="Контур_6138" data-name="Контур 6138" d="M316.552-300.825v1.265l9.533-5.486v-1.265l-9.533,5.486" transform="translate(-316.552 306.311)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1169">
        <path id="Контур_6141" data-name="Контур 6141" d="M314.039-313.285l-9.533,5.486-13.463-7.8,9.533-5.485,13.463,7.794" transform="translate(-291.043 321.079)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1171">
        <path id="Контур_6144" data-name="Контур 6144" d="M304.506-302.9v1.266l-13.463-7.8V-310.7l13.463,7.8" transform="translate(-291.043 310.697)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1173">
        <path id="Контур_6147" data-name="Контур 6147" d="M313.185-309.908l-2.807,1.616-3.965-2.3,2.807-1.615,3.965,2.3" transform="translate(-306.413 312.203)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1175">
        <path id="Контур_6150" data-name="Контур 6150" d="M130.188-301.526l1.158.665v8.983l-1.158-.676v-8.972" transform="translate(-130.188 301.526)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1177">
        <path id="Контур_6153" data-name="Контур 6153" d="M132.383-303.291l5.823-3.38v8.982l-5.824,3.381v-8.983" transform="translate(-132.382 306.671)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1179">
        <path id="Контур_6156" data-name="Контур 6156" d="M130.188-304.542l5.842-3.37,1.139.655-5.823,3.38-1.158-.665" transform="translate(-130.188 307.912)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1181">
        <path id="Контур_6159" data-name="Контур 6159" d="M130.52-300.067l.816.475v3.039l-.815-.474v-3.039" transform="translate(-130.52 300.067)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1183">
        <path id="Контур_6162" data-name="Контур 6162" d="M132.6-300.123l1.158.666v8.981l-1.157-.676v-8.972" transform="translate(-132.598 300.123)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1185">
        <path id="Контур_6165" data-name="Контур 6165" d="M134.792-301.887l5.823-3.381v8.982l-5.824,3.38v-8.981" transform="translate(-134.791 305.268)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1187">
        <path id="Контур_6168" data-name="Контур 6168" d="M132.6-303.138l5.842-3.37,1.139.654-5.823,3.381-1.158-.666" transform="translate(-132.598 306.508)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1189">
        <path id="Контур_6171" data-name="Контур 6171" d="M132.93-298.664l.816.475v3.039l-.815-.474v-3.04" transform="translate(-132.93 298.664)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1191">
        <path id="Контур_6174" data-name="Контур 6174" d="M135.008-298.719l1.158.665v8.983l-1.158-.676v-8.972" transform="translate(-135.008 298.719)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1193">
        <path id="Контур_6177" data-name="Контур 6177" d="M137.2-300.484l5.824-3.38v8.982L137.2-291.5v-8.983" transform="translate(-137.202 303.864)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1195">
        <path id="Контур_6180" data-name="Контур 6180" d="M135.008-301.734l5.843-3.37,1.139.654-5.824,3.38-1.158-.665" transform="translate(-135.008 305.104)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1197">
        <path id="Контур_6183" data-name="Контур 6183" d="M135.34-297.26l.816.476v3.038l-.816-.473v-3.04" transform="translate(-135.34 297.26)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1199">
        <path id="Контур_6186" data-name="Контур 6186" d="M139.739-295.873l1.159.666v8.981l-1.158-.676v-8.972" transform="translate(-139.739 295.873)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1201">
        <path id="Контур_6189" data-name="Контур 6189" d="M141.935-297.637l5.823-3.381v8.982l-5.824,3.381v-8.981" transform="translate(-141.934 301.018)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1203">
        <path id="Контур_6192" data-name="Контур 6192" d="M139.739-298.889l5.843-3.37,1.139.655-5.823,3.381-1.159-.666" transform="translate(-139.739 302.259)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1205">
        <path id="Контур_6195" data-name="Контур 6195" d="M140.071-294.414l.816.475v3.039l-.815-.474v-3.04" transform="translate(-140.071 294.414)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1207">
        <path id="Контур_6198" data-name="Контур 6198" d="M142.149-294.471l1.158.666v8.983l-1.158-.677v-8.972" transform="translate(-142.149 294.471)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1209">
        <path id="Контур_6201" data-name="Контур 6201" d="M144.345-296.234l5.823-3.381v8.982l-5.824,3.381v-8.983" transform="translate(-144.344 299.615)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1211">
        <path id="Контур_6204" data-name="Контур 6204" d="M142.149-297.486l5.843-3.369,1.139.654-5.824,3.381-1.158-.666" transform="translate(-142.149 300.855)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1213">
        <path id="Контур_6207" data-name="Контур 6207" d="M142.482-293.011l.815.475v3.038l-.815-.475v-3.039" transform="translate(-142.482 293.011)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1215">
        <path id="Контур_6210" data-name="Контур 6210" d="M144.561-293.066l1.158.665v8.982l-1.157-.675v-8.972" transform="translate(-144.561 293.066)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1217">
        <path id="Контур_6213" data-name="Контур 6213" d="M146.755-294.83l5.823-3.381v8.982l-5.824,3.38v-8.982" transform="translate(-146.754 298.211)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1219">
        <path id="Контур_6216" data-name="Контур 6216" d="M144.561-296.082l5.842-3.37,1.139.655-5.823,3.381-1.158-.665" transform="translate(-144.561 299.452)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1221">
        <path id="Контур_6219" data-name="Контур 6219" d="M144.893-291.607l.815.475v3.039l-.815-.474v-3.039" transform="translate(-144.893 291.607)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1223">
        <path id="Контур_6222" data-name="Контур 6222" d="M340.686-179.306l1.158.666v8.983l-1.157-.677v-8.972" transform="translate(-340.686 179.306)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1225">
        <path id="Контур_6225" data-name="Контур 6225" d="M342.88-181.07l5.824-3.38v8.982l-5.824,3.381v-8.983" transform="translate(-342.879 184.45)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1227">
        <path id="Контур_6228" data-name="Контур 6228" d="M340.686-182.321l5.842-3.37,1.139.655-5.824,3.38-1.158-.666" transform="translate(-340.686 185.691)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1229">
        <path id="Контур_6231" data-name="Контур 6231" d="M341.018-177.847l.815.476v3.039l-.815-.474v-3.04" transform="translate(-341.018 177.847)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1231">
        <path id="Контур_6234" data-name="Контур 6234" d="M343.1-177.9l1.158.665v8.982l-1.157-.676V-177.9" transform="translate(-343.096 177.902)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1233">
        <path id="Контур_6237" data-name="Контур 6237" d="M345.29-179.666l5.823-3.381v8.982l-5.824,3.38v-8.982" transform="translate(-345.289 183.047)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1235">
        <path id="Контур_6240" data-name="Контур 6240" d="M343.1-180.918l5.842-3.37,1.139.655-5.823,3.381-1.158-.666" transform="translate(-343.096 184.288)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1237">
        <path id="Контур_6243" data-name="Контур 6243" d="M343.428-176.442l.816.474v3.039l-.815-.474v-3.039" transform="translate(-343.428 176.442)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1239">
        <path id="Контур_6246" data-name="Контур 6246" d="M345.506-176.5l1.158.666v8.982l-1.157-.676V-176.5" transform="translate(-345.506 176.499)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1241">
        <path id="Контур_6249" data-name="Контур 6249" d="M347.7-178.263l5.823-3.381v8.982l-5.824,3.381v-8.982" transform="translate(-347.699 181.644)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1243">
        <path id="Контур_6252" data-name="Контур 6252" d="M345.506-179.514l5.843-3.37,1.139.654-5.823,3.381-1.158-.666" transform="translate(-345.506 182.884)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1245">
        <path id="Контур_6255" data-name="Контур 6255" d="M345.839-175.039l.816.474v3.039l-.815-.473v-3.04" transform="translate(-345.839 175.039)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1247">
        <path id="Контур_6258" data-name="Контур 6258" d="M348.8-174.473l1.158.666v8.982L348.8-165.5v-8.972" transform="translate(-348.799 174.473)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1249">
        <path id="Контур_6261" data-name="Контур 6261" d="M350.993-176.236l5.823-3.381v8.982l-5.824,3.381v-8.982" transform="translate(-350.992 179.617)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1251">
        <path id="Контур_6264" data-name="Контур 6264" d="M348.8-177.488l5.842-3.369,1.139.654-5.823,3.381-1.158-.666" transform="translate(-348.799 180.857)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1253">
        <path id="Контур_6267" data-name="Контур 6267" d="M349.131-173.013l.816.475v3.039l-.815-.474v-3.039" transform="translate(-349.131 173.013)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1255">
        <path id="Контур_6270" data-name="Контур 6270" d="M351.209-173.069l1.158.666v8.982l-1.157-.676v-8.972" transform="translate(-351.209 173.069)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1257">
        <path id="Контур_6273" data-name="Контур 6273" d="M353.4-174.833l5.823-3.381v8.982l-5.824,3.381v-8.982" transform="translate(-353.402 178.214)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1259">
        <path id="Контур_6276" data-name="Контур 6276" d="M351.209-176.084l5.842-3.37,1.139.654-5.823,3.381-1.158-.665" transform="translate(-351.209 179.454)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1261">
        <path id="Контур_6279" data-name="Контур 6279" d="M351.541-171.609l.816.475v3.038l-.815-.474v-3.039" transform="translate(-351.541 171.609)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1263">
        <path id="Контур_6282" data-name="Контур 6282" d="M353.619-171.666l1.158.666v8.982l-1.158-.676v-8.972" transform="translate(-353.619 171.666)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1265">
        <path id="Контур_6285" data-name="Контур 6285" d="M355.814-173.43l5.824-3.381v8.982l-5.825,3.381v-8.982" transform="translate(-355.813 176.811)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1267">
        <path id="Контур_6288" data-name="Контур 6288" d="M353.619-174.681l5.843-3.37,1.14.654-5.824,3.381-1.158-.666" transform="translate(-353.619 178.051)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1269">
        <path id="Контур_6291" data-name="Контур 6291" d="M353.951-170.206l.816.475v3.039l-.815-.474v-3.04" transform="translate(-353.951 170.206)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1271">
        <path id="Контур_6294" data-name="Контур 6294" d="M356.912-169.639l1.158.666v8.982l-1.157-.676v-8.972" transform="translate(-356.912 169.639)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1273">
        <path id="Контур_6297" data-name="Контур 6297" d="M359.106-171.4l5.824-3.38v8.981l-5.826,3.381V-171.4" transform="translate(-359.105 174.783)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1275">
        <path id="Контур_6300" data-name="Контур 6300" d="M356.912-172.654l5.842-3.37,1.14.655-5.824,3.38-1.158-.666" transform="translate(-356.912 176.024)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1277">
        <path id="Контур_6303" data-name="Контур 6303" d="M357.244-168.18l.816.476v3.039l-.815-.474v-3.04" transform="translate(-357.244 168.18)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1279">
        <path id="Контур_6306" data-name="Контур 6306" d="M359.322-168.235l1.159.665v8.983l-1.158-.676v-8.972" transform="translate(-359.322 168.235)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1281">
        <path id="Контур_6309" data-name="Контур 6309" d="M361.518-170l5.823-3.38v8.982l-5.824,3.381V-170" transform="translate(-361.517 173.38)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1283">
        <path id="Контур_6312" data-name="Контур 6312" d="M359.322-171.251l5.843-3.37,1.139.655-5.823,3.38-1.159-.665" transform="translate(-359.322 174.621)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1285">
        <path id="Контур_6315" data-name="Контур 6315" d="M359.655-166.775l.815.474v3.039l-.815-.474v-3.039" transform="translate(-359.655 166.775)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1287">
        <path id="Контур_6318" data-name="Контур 6318" d="M361.732-166.832l1.159.666v8.982l-1.158-.676v-8.972" transform="translate(-361.732 166.832)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1289">
        <path id="Контур_6321" data-name="Контур 6321" d="M363.927-168.6l5.823-3.381v8.982l-5.824,3.381V-168.6" transform="translate(-363.926 171.977)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1291">
        <path id="Контур_6324" data-name="Контур 6324" d="M361.732-169.847l5.843-3.37,1.139.654-5.823,3.381-1.159-.666" transform="translate(-361.732 173.217)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1293">
        <path id="Контур_6327" data-name="Контур 6327" d="M362.065-165.373l.816.475v3.039l-.815-.474v-3.04" transform="translate(-362.065 165.373)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1295">
        <path id="Контур_6330" data-name="Контур 6330" d="M365.025-164.806l1.158.666v8.982l-1.158-.677v-8.972" transform="translate(-365.025 164.806)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1297">
        <path id="Контур_6333" data-name="Контур 6333" d="M367.22-166.569l5.824-3.381v8.982l-5.824,3.381v-8.982" transform="translate(-367.219 169.95)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1299">
        <path id="Контур_6336" data-name="Контур 6336" d="M365.025-167.821l5.843-3.369,1.139.654-5.824,3.381-1.158-.666" transform="translate(-365.025 171.19)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1301">
        <path id="Контур_6339" data-name="Контур 6339" d="M365.357-163.346l.816.476v3.038l-.815-.474v-3.039" transform="translate(-365.357 163.346)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1303">
        <path id="Контур_6342" data-name="Контур 6342" d="M367.436-163.4l1.158.666v8.982l-1.157-.676V-163.4" transform="translate(-367.436 163.402)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1305">
        <path id="Контур_6345" data-name="Контур 6345" d="M369.63-165.166l5.823-3.381v8.982l-5.824,3.381v-8.982" transform="translate(-369.629 168.547)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1307">
        <path id="Контур_6348" data-name="Контур 6348" d="M367.436-166.417l5.842-3.37,1.139.654-5.823,3.381-1.158-.666" transform="translate(-367.436 169.787)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1309">
        <path id="Контур_6351" data-name="Контур 6351" d="M367.768-161.942l.815.474v3.039l-.815-.474v-3.039" transform="translate(-367.768 161.942)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1311">
        <path id="Контур_6354" data-name="Контур 6354" d="M369.846-162l1.158.666v8.982l-1.158-.676V-162" transform="translate(-369.846 161.998)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1313">
        <path id="Контур_6357" data-name="Контур 6357" d="M372.04-163.762l5.824-3.381v8.982l-5.824,3.381v-8.982" transform="translate(-372.04 167.143)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1315">
        <path id="Контур_6360" data-name="Контур 6360" d="M369.846-165.014l5.843-3.37,1.139.655L371-164.348l-1.158-.666" transform="translate(-369.846 168.384)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1317">
        <path id="Контур_6363" data-name="Контур 6363" d="M370.178-160.539l.816.475v3.039l-.815-.474v-3.04" transform="translate(-370.178 160.539)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1319">
        <path id="Контур_6366" data-name="Контур 6366" d="M427.764-273.734v4.786l2.981-1.736.691-5.188-3.672,2.138" transform="translate(-427.764 275.873)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1321">
        <path id="Контур_6372" data-name="Контур 6372" d="M428.349-277.628l-3.672,2.138-3.45-1.994,3.717-2.106,3.4,1.962" transform="translate(-421.227 279.59)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1323">
        <rect id="Прямоугольник_823" data-name="Прямоугольник 823" width="7.389" height="4.75" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1325">
        <path id="Контур_6369" data-name="Контур 6369" d="M421-275.25h7.389V-280H421Z" transform="translate(-421 280)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient" x1="0.661" y1="0.509" x2="0.661" y2="2.306" gradientUnits="objectBoundingBox">
        <stop offset="0"/>
        <stop offset="0" stop-color="#9ca663"/>
        <stop offset="0.1" stop-color="#97a363"/>
        <stop offset="0.2" stop-color="#93a164"/>
        <stop offset="0.3" stop-color="#8f9f64"/>
        <stop offset="0.4" stop-color="#8a9d64"/>
        <stop offset="0.5" stop-color="#869a65"/>
        <stop offset="0.6" stop-color="#829865"/>
        <stop offset="0.7" stop-color="#7d9665"/>
        <stop offset="0.8" stop-color="#799466"/>
        <stop offset="0.9" stop-color="#759166"/>
        <stop offset="1" stop-color="#718f66"/>
        <stop offset="1"/>
      </linearGradient>
      <linearGradient id="linear-gradient-2" x1="0.664" y1="0.52" x2="0.684" y2="0.51" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#9ca663"/>
        <stop offset="0.1" stop-color="#97a363"/>
        <stop offset="0.2" stop-color="#93a164"/>
        <stop offset="0.3" stop-color="#8f9f64"/>
        <stop offset="0.4" stop-color="#8a9d64"/>
        <stop offset="0.5" stop-color="#869a65"/>
        <stop offset="0.6" stop-color="#829865"/>
        <stop offset="0.7" stop-color="#7d9665"/>
        <stop offset="0.8" stop-color="#799466"/>
        <stop offset="0.9" stop-color="#759166"/>
        <stop offset="1" stop-color="#718f66"/>
      </linearGradient>
      <clipPath id="clip-path-1326">
        <path id="Контур_6375" data-name="Контур 6375" d="M424.677-273.608v4.786l-2.864-1.656-.586-5.125,3.45,1.994" transform="translate(-421.227 275.602)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1328">
        <path id="Контур_6378" data-name="Контур 6378" d="M421.813-277.478l3.131-1.768,2.809,1.618-3.072,1.79Zm-.586-.006,3.45,1.994,3.672-2.138-3.4-1.962-3.717,2.106Z" transform="translate(-421.227 279.59)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1330">
        <path id="Контур_6381" data-name="Контур 6381" d="M427.768-279.634v2.29l.945-.544v-2.29l-.945.544" transform="translate(-427.768 280.178)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1332">
        <path id="Контур_6384" data-name="Контур 6384" d="M426.918-279.639v2.29l-.949-.548v-2.29l.949.548" transform="translate(-425.969 280.187)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1334">
        <path id="Контур_6387" data-name="Контур 6387" d="M427.776-285.163v4.006l2.571-1.48v-4.006l-2.571,1.48" transform="translate(-427.776 286.643)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1336">
        <path id="Контур_6390" data-name="Контур 6390" d="M428.035-287.978l-2.571,1.48-2.585-1.492,2.572-1.48,2.584,1.491" transform="translate(-422.879 289.469)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1338">
        <path id="Контур_6393" data-name="Контур 6393" d="M425.464-285.177v4.006l-2.585-1.491v-4.006l2.585,1.491" transform="translate(-422.879 286.668)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1340">
        <path id="Контур_6540" data-name="Контур 6540" d="M443.354-315.461l28.9,16.681v14.446l-28.9-16.78v-14.346" transform="translate(-443.354 315.461)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1342">
        <path id="Контур_6543" data-name="Контур 6543" d="M498.116-288.613l9.137-5.317v14.445l-9.138,5.318v-14.446" transform="translate(-498.115 293.93)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1344">
        <path id="Контур_6546" data-name="Контур 6546" d="M443.354-320.2l9.171-5.3,28.869,16.661-9.137,5.317-28.9-16.681" transform="translate(-443.354 325.499)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1346">
        <path id="Контур_6549" data-name="Контур 6549" d="M445.036-312.7l27.12,15.64v12.575l-27.119-15.728V-312.7" transform="translate(-445.036 312.703)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1348">
        <path id="Контур_6552" data-name="Контур 6552" d="M445.037-312.7l8.4,4.845v2.753l-8.4,4.89V-312.7" transform="translate(-445.036 312.703)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1350">
        <path id="Контур_6555" data-name="Контур 6555" d="M460.954-300.77l18.719,10.892v-2.849l-18.719-10.8v2.753" transform="translate(-460.954 303.523)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1352">
        <path id="Контур_6558" data-name="Контур 6558" d="M496.418-284.834l28.9,16.681v14.446l-28.9-16.78v-14.346" transform="translate(-496.418 284.834)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1354">
        <path id="Контур_6561" data-name="Контур 6561" d="M551.182-257.985l9.137-5.317v14.445l-9.138,5.318v-14.446" transform="translate(-551.181 263.302)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1356">
        <path id="Контур_6564" data-name="Контур 6564" d="M496.418-289.574l9.172-5.3,28.868,16.661-9.137,5.317-28.9-16.681" transform="translate(-496.418 294.871)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1358">
        <path id="Контур_6567" data-name="Контур 6567" d="M498.1-282.076l27.12,15.641v12.574L498.1-269.588v-12.488" transform="translate(-498.102 282.076)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1360">
        <path id="Контур_6570" data-name="Контур 6570" d="M555.511-245.256l.862.5v8.75l-.861-.516v-8.732" transform="translate(-555.511 245.256)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1362">
        <path id="Контур_6573" data-name="Контур 6573" d="M498.1-282.076l8.4,4.845v2.754l-8.4,4.89v-12.489" transform="translate(-498.102 282.076)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1364">
        <path id="Контур_6576" data-name="Контур 6576" d="M514.02-270.142l18.718,10.891V-262.1L514.02-272.9v2.754" transform="translate(-514.02 272.896)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1366">
        <path id="Контур_6579" data-name="Контур 6579" d="M549.488-254.205l28.9,16.681v14.445l-28.9-16.78v-14.346" transform="translate(-549.488 254.205)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1368">
        <path id="Контур_6582" data-name="Контур 6582" d="M604.251-227.357l9.137-5.317v14.445l-9.138,5.317v-14.445" transform="translate(-604.251 232.674)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1370">
        <path id="Контур_6585" data-name="Контур 6585" d="M549.488-258.945l9.171-5.3,28.869,16.661-9.137,5.317-28.9-16.681" transform="translate(-549.488 264.242)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1372">
        <path id="Контур_6588" data-name="Контур 6588" d="M551.171-251.447l27.12,15.64v12.575l-27.12-15.728v-12.488" transform="translate(-551.171 251.447)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1374">
        <path id="Контур_6591" data-name="Контур 6591" d="M551.172-251.447l8.4,4.844v2.753l-8.4,4.891v-12.489" transform="translate(-551.171 251.447)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1376">
        <path id="Контур_6594" data-name="Контур 6594" d="M567.089-239.515l18.719,10.892v-2.849l-18.719-10.8v2.753" transform="translate(-567.089 242.268)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1378">
        <path id="Контур_6597" data-name="Контур 6597" d="M498.1-282.076v12.489l27.119,15.726v-12.574L498.1-282.076" transform="translate(-498.102 282.076)" fill="#e6e7e3" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1380">
        <path id="Контур_6600" data-name="Контур 6600" d="M551.172-251.447l27.119,15.64v12.575l-27.12-15.726v-12.489" transform="translate(-551.171 251.447)" fill="#e6e7e3" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1382">
        <path id="Контур_6711" data-name="Контур 6711" d="M518.075-287.475v3.8l4.407-2.568v-3.795l-4.407,2.567" transform="translate(-518.075 290.042)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1384">
        <path id="Контур_6717" data-name="Контур 6717" d="M518.777-292.149l-4.407,2.567-4.141-2.394,4.462-2.528,4.087,2.355" transform="translate(-510.228 294.504)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1386">
        <rect id="Прямоугольник_824" data-name="Прямоугольник 824" width="8.972" height="5.278" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1388">
        <path id="Контур_6714" data-name="Контур 6714" d="M510-289.722h8.972V-295H510Z" transform="translate(-510 295)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-3" x1="0.801" y1="0.483" x2="0.801" y2="2.28" xlink:href="#linear-gradient"/>
      <linearGradient id="linear-gradient-4" x1="0.805" y1="0.496" x2="0.828" y2="0.484" xlink:href="#linear-gradient-2"/>
      <clipPath id="clip-path-1389">
        <path id="Контур_6720" data-name="Контур 6720" d="M514.369-287.323v3.8l-4.141-2.394v-3.8l4.141,2.394" transform="translate(-510.228 289.717)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1391">
        <path id="Контур_6723" data-name="Контур 6723" d="M510.933-291.968l3.757-2.122,3.371,1.943L514.373-290Zm-.7-.008,4.141,2.394,4.407-2.567L514.69-294.5l-4.462,2.528Z" transform="translate(-510.228 294.504)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1393">
        <path id="Контур_6726" data-name="Контур 6726" d="M518.08-294.557v2.749l1.134-.652v-2.749l-1.134.652" transform="translate(-518.08 295.209)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1395">
        <path id="Контур_6729" data-name="Контур 6729" d="M517.06-294.563v2.75l-1.14-.658v-2.749l1.14.658" transform="translate(-515.92 295.221)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1397">
        <path id="Контур_6732" data-name="Контур 6732" d="M518.09-301.193v4.809l3.086-1.777v-4.808l-3.086,1.776" transform="translate(-518.09 302.969)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1399">
        <path id="Контур_6735" data-name="Контур 6735" d="M518.4-304.571l-3.086,1.776-3.1-1.79,3.086-1.776,3.1,1.791" transform="translate(-512.211 306.362)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1401">
        <path id="Контур_6738" data-name="Контур 6738" d="M515.314-301.209v4.808l-3.1-1.792V-303l3.1,1.791" transform="translate(-512.211 303)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1403">
        <path id="Контур_6741" data-name="Контур 6741" d="M552.943-267.123v3.794l4.408-2.567v-3.795l-4.408,2.567" transform="translate(-552.943 269.69)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1405">
        <path id="Контур_6747" data-name="Контур 6747" d="M553.646-271.8l-4.408,2.567-4.141-2.394,4.462-2.528,4.087,2.355" transform="translate(-545.097 274.152)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1407">
        <rect id="Прямоугольник_825" data-name="Прямоугольник 825" width="8.972" height="5.805" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1409">
        <path id="Контур_6744" data-name="Контур 6744" d="M545-269.195h8.972V-275H545Z" transform="translate(-545 275)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-5" x1="0.856" y1="0.519" x2="0.856" y2="2.316" xlink:href="#linear-gradient"/>
      <linearGradient id="linear-gradient-6" x1="0.86" y1="0.532" x2="0.883" y2="0.519" xlink:href="#linear-gradient-2"/>
      <clipPath id="clip-path-1410">
        <path id="Контур_6750" data-name="Контур 6750" d="M549.238-266.971v3.795l-4.141-2.394v-3.794l4.141,2.394" transform="translate(-545.097 269.365)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1412">
        <path id="Контур_6753" data-name="Контур 6753" d="M545.8-271.617l3.758-2.122,3.371,1.942-3.688,2.149Zm-.7-.007,4.141,2.394,4.408-2.567-4.087-2.355-4.462,2.528Z" transform="translate(-545.097 274.152)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1414">
        <path id="Контур_6756" data-name="Контур 6756" d="M552.949-274.2v2.749l1.134-.652v-2.749l-1.134.652" transform="translate(-552.949 274.857)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1416">
        <path id="Контур_6759" data-name="Контур 6759" d="M551.929-274.211v2.749l-1.14-.658v-2.749l1.14.658" transform="translate(-550.789 274.869)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1418">
        <path id="Контур_6762" data-name="Контур 6762" d="M552.959-280.841v4.808l3.087-1.776v-4.807l-3.087,1.776" transform="translate(-552.959 282.617)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1420">
        <path id="Контур_6765" data-name="Контур 6765" d="M553.269-284.22l-3.087,1.776-3.1-1.791,3.087-1.776,3.1,1.791" transform="translate(-547.079 286.011)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1422">
        <path id="Контур_6768" data-name="Контур 6768" d="M550.182-280.858v4.808l-3.1-1.791v-4.807l3.1,1.79" transform="translate(-547.079 282.648)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1424">
        <path id="Контур_6771" data-name="Контур 6771" d="M587.813-246.772v3.795l4.407-2.567v-3.8l-4.407,2.568" transform="translate(-587.813 249.34)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1426">
        <path id="Контур_6777" data-name="Контур 6777" d="M588.514-251.447l-4.407,2.568-4.142-2.394,4.462-2.528,4.087,2.355" transform="translate(-579.965 253.802)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1428">
        <rect id="Прямоугольник_826" data-name="Прямоугольник 826" width="9.5" height="5.278" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1430">
        <path id="Контур_6774" data-name="Контур 6774" d="M579-248.722h9.5V-254H579Z" transform="translate(-579 254)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-7" x1="0.91" y1="0.555" x2="0.91" y2="2.351" xlink:href="#linear-gradient"/>
      <linearGradient id="linear-gradient-8" x1="0.914" y1="0.568" x2="0.937" y2="0.555" xlink:href="#linear-gradient-2"/>
      <clipPath id="clip-path-1431">
        <path id="Контур_6780" data-name="Контур 6780" d="M584.107-246.621v3.8l-4.142-2.394v-3.795l4.142,2.393" transform="translate(-579.965 249.014)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1433">
        <path id="Контур_6783" data-name="Контур 6783" d="M580.67-251.266l3.758-2.122,3.371,1.942-3.688,2.149Zm-.7-.008,4.142,2.394,4.407-2.568-4.087-2.355-4.462,2.528Z" transform="translate(-579.965 253.802)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1435">
        <path id="Контур_6786" data-name="Контур 6786" d="M587.818-253.854v2.749l1.134-.653v-2.749l-1.134.653" transform="translate(-587.818 254.507)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1437">
        <path id="Контур_6789" data-name="Контур 6789" d="M586.8-253.86v2.749l-1.14-.658v-2.749l1.14.658" transform="translate(-585.658 254.518)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1439">
        <path id="Контур_6792" data-name="Контур 6792" d="M587.827-260.49v4.808l3.087-1.776v-4.808l-3.087,1.776" transform="translate(-587.827 262.266)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1441">
        <path id="Контур_6795" data-name="Контур 6795" d="M588.138-263.869l-3.087,1.776-3.1-1.792,3.087-1.776,3.1,1.791" transform="translate(-581.947 265.66)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1443">
        <path id="Контур_6798" data-name="Контур 6798" d="M585.05-260.506v4.808l-3.1-1.791V-262.3l3.1,1.791" transform="translate(-581.947 262.297)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1445">
        <path id="Контур_6801" data-name="Контур 6801" d="M363.364-142.479l-2.286,1.306v2.05l2.285-1.33v-2.026" transform="translate(-361.078 142.479)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1447">
        <path id="Контур_6804" data-name="Контур 6804" d="M350.747-146.011,339.2-152.723v2.05l11.547,6.713v-2.05" transform="translate(-339.202 152.723)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1449">
        <path id="Контур_6807" data-name="Контур 6807" d="M353.034-148.473l-11.594-6.7-2.238,1.292,11.545,6.712,2.286-1.306" transform="translate(-339.202 155.171)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1451">
        <path id="Контур_6810" data-name="Контур 6810" d="M170.4-274.449v2.209l4.528-2.637v-2.209l-4.528,2.637" transform="translate(-170.4 277.086)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1453">
        <path id="Контур_6816" data-name="Контур 6816" d="M171.122-279.25l-4.528,2.637-4.254-2.459,4.583-2.6,4.2,2.418" transform="translate(-162.34 281.668)" fill="#a7afc7" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1455">
        <rect id="Прямоугольник_827" data-name="Прямоугольник 827" width="8.972" height="5.278" fill="#a7afc7"/>
      </clipPath>
      <clipPath id="clip-path-1457">
        <path id="Контур_6813" data-name="Контур 6813" d="M162-276.722h8.972V-282H162Z" transform="translate(-162 282)" fill="#a7afc7"/>
      </clipPath>
      <clipPath id="clip-path-1458">
        <path id="Контур_6819" data-name="Контур 6819" d="M166.594-274.292v2.209l-4.254-2.459v-2.209l4.254,2.459" transform="translate(-162.34 276.751)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1460">
        <path id="Контур_6822" data-name="Контур 6822" d="M163.064-279.063l3.859-2.18,3.464,1.995-3.788,2.207Zm-.724-.008,4.254,2.459,4.528-2.637-4.2-2.418-4.583,2.6Z" transform="translate(-162.34 281.668)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1462">
        <path id="Контур_6825" data-name="Контур 6825" d="M417.936-236.177v2.208l4.527-2.637v-2.208l-4.527,2.637" transform="translate(-417.936 238.814)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1464">
        <path id="Контур_6831" data-name="Контур 6831" d="M418.657-240.979l-4.527,2.637-4.254-2.459,4.583-2.6,4.2,2.419" transform="translate(-409.876 243.398)" fill="#a7afc7" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1466">
        <rect id="Прямоугольник_828" data-name="Прямоугольник 828" width="9.5" height="5.805" fill="#a7afc7"/>
      </clipPath>
      <clipPath id="clip-path-1468">
        <path id="Контур_6828" data-name="Контур 6828" d="M409-238.195h9.5V-244H409Z" transform="translate(-409 244)" fill="#a7afc7"/>
      </clipPath>
      <clipPath id="clip-path-1469">
        <path id="Контур_6834" data-name="Контур 6834" d="M414.13-236.021v2.208l-4.254-2.459v-2.208l4.254,2.459" transform="translate(-409.876 238.48)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1471">
        <path id="Контур_6837" data-name="Контур 6837" d="M410.6-240.793l3.86-2.179,3.463,1.995-3.788,2.207Zm-.723-.008,4.254,2.459,4.527-2.637-4.2-2.419-4.583,2.6Z" transform="translate(-409.876 243.398)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1473">
        <path id="Контур_6840" data-name="Контур 6840" d="M331.45-202.483v7.162l3.886-2.263v-7.162l-3.886,2.264" transform="translate(-331.45 204.747)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1475">
        <path id="Контур_6846" data-name="Контур 6846" d="M332.069-206.6l-3.886,2.264-3.651-2.111,3.933-2.229,3.6,2.076" transform="translate(-324.532 208.681)" fill="#a7afc7" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1477">
        <rect id="Прямоугольник_829" data-name="Прямоугольник 829" width="7.917" height="4.75" fill="#a7afc7"/>
      </clipPath>
      <clipPath id="clip-path-1479">
        <path id="Контур_6843" data-name="Контур 6843" d="M324-204.25h7.917V-209H324Z" transform="translate(-324 209)" fill="#a7afc7"/>
      </clipPath>
      <clipPath id="clip-path-1480">
        <path id="Контур_6849" data-name="Контур 6849" d="M328.183-202.349v7.162l-3.651-2.111v-7.162l3.651,2.111" transform="translate(-324.532 204.46)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1482">
        <path id="Контур_6852" data-name="Контур 6852" d="M325.153-206.445l3.313-1.87,2.973,1.713-3.252,1.894Zm-.621-.007,3.651,2.111,3.886-2.264-3.6-2.076-3.933,2.229Z" transform="translate(-324.532 208.681)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1484">
        <path id="Контур_6855" data-name="Контур 6855" d="M209.511-269.28l-13.528-7.827a.273.273,0,0,0-.272,0h0l0,0h0l-.518.3.38,1.362v8.957a.914.914,0,0,0,.457.791l13.034,7.542.151.409.521-.3a.494.494,0,0,0,.253-.433v-9.978a.95.95,0,0,0-.475-.822" transform="translate(-195.188 277.144)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1486">
        <path id="Контур_6858" data-name="Контур 6858" d="M209.337-267.883v9.977a.5.5,0,0,1-.745.43l-13.215-7.646a.914.914,0,0,1-.457-.791v-10.382a.276.276,0,0,1,.414-.239l13.528,7.827a.949.949,0,0,1,.475.823" transform="translate(-194.92 276.57)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1488">
        <path id="Контур_6861" data-name="Контур 6861" d="M209.26-266.461v8.879l-13.726-7.941V-274.4l13.726,7.941" transform="translate(-195.534 274.402)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1490">
        <path id="Контур_6864" data-name="Контур 6864" d="M192.027-243.435v.247a.24.24,0,0,0-.22,0v-.247a.242.242,0,0,1,.22,0" transform="translate(-191.807 243.461)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1492">
        <path id="Контур_6867" data-name="Контур 6867" d="M189.965-243.411v.247l-2.058,1.2v-.247l2.058-1.2" transform="translate(-187.907 243.411)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1494">
        <path id="Контур_6870" data-name="Контур 6870" d="M221.662-239.41v.649a.222.222,0,0,1-.13.183v-.649a.224.224,0,0,0,.131-.183" transform="translate(-221.531 239.41)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1496">
        <path id="Контур_6873" data-name="Контур 6873" d="M196.91-240.705v.247l-4.687-2.706v-.247l4.687,2.706" transform="translate(-192.223 243.411)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1498">
        <path id="Контур_6876" data-name="Контур 6876" d="M201.1-238.037v-.247l.046.064v.247l-.045-.064" transform="translate(-201.104 238.284)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1500">
        <path id="Контур_6879" data-name="Контур 6879" d="M201.15-238.162v.247l-.045.063v-.248l.045-.063" transform="translate(-201.104 238.162)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1502">
        <path id="Контур_6882" data-name="Контур 6882" d="M192.6-238.2v.246l-4.687-2.706v-.246L192.6-238.2" transform="translate(-187.908 240.903)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1504">
        <path id="Контур_6885" data-name="Контур 6885" d="M197.01-235.776v.246a.241.241,0,0,1-.22,0v-.246a.245.245,0,0,0,.22,0" transform="translate(-196.789 235.776)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1506">
        <path id="Контур_6888" data-name="Контур 6888" d="M194.832-240.729a.067.067,0,0,1,0,.127l-2.058,1.2a.245.245,0,0,1-.22,0l-4.687-2.706a.068.068,0,0,1,0-.128l2.058-1.2a.242.242,0,0,1,.22,0l4.687,2.706" transform="translate(-187.823 243.461)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1508">
        <path id="Контур_6891" data-name="Контур 6891" d="M214.3-239.064v.649l-8.08,4.7v-.649l8.08-4.7" transform="translate(-206.221 239.064)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1510">
        <path id="Контур_6894" data-name="Контур 6894" d="M195.248-253.6a.065.065,0,0,1,0,.122l-.608.353a.228.228,0,0,1-.211,0l-.612-.353a.065.065,0,0,1,0-.123l.609-.354a.236.236,0,0,1,.211,0l.612.354" transform="translate(-193.773 253.983)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1512">
        <path id="Контур_6897" data-name="Контур 6897" d="M199.338-251.271a.065.065,0,0,1,0,.123l-.608.354a.237.237,0,0,1-.212,0l-.611-.354c-.059-.034-.059-.089,0-.123l.608-.353a.234.234,0,0,1,.212,0l.612.353" transform="translate(-197.863 251.649)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1514">
        <path id="Контур_6900" data-name="Контур 6900" d="M197.18-252.524a.065.065,0,0,1,0,.123l-.608.353a.237.237,0,0,1-.212,0l-.612-.354c-.058-.034-.059-.089,0-.123l.607-.353a.231.231,0,0,1,.212,0l.612.353" transform="translate(-195.705 252.903)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1516">
        <path id="Контур_6903" data-name="Контур 6903" d="M201.38-250.081a.064.064,0,0,1,0,.122l-.608.353a.232.232,0,0,1-.212,0l-.612-.354c-.059-.033-.059-.088,0-.121l.609-.354a.231.231,0,0,1,.211,0l.611.354" transform="translate(-199.905 250.461)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1518">
        <path id="Контур_6906" data-name="Контур 6906" d="M203.393-248.923a.065.065,0,0,1,0,.122l-.609.353a.232.232,0,0,1-.212,0l-.612-.354a.065.065,0,0,1,0-.122l.608-.353a.234.234,0,0,1,.212,0l.612.354" transform="translate(-201.918 249.302)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1520">
        <path id="Контур_6909" data-name="Контур 6909" d="M205.408-247.763a.065.065,0,0,1,0,.123l-.609.353a.232.232,0,0,1-.211,0l-.612-.354c-.059-.034-.06-.088,0-.122l.609-.353a.236.236,0,0,1,.211,0l.613.354" transform="translate(-203.932 248.142)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1522">
        <path id="Контур_6912" data-name="Контур 6912" d="M194.383-251.707a.065.065,0,0,1,0,.123l-.609.353a.234.234,0,0,1-.212,0l-1.679-.97c-.059-.034-.059-.089,0-.122l.608-.354a.234.234,0,0,1,.212,0l1.68.97" transform="translate(-191.84 252.702)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1524">
        <path id="Контур_6915" data-name="Контур 6915" d="M197.266-250.083a.065.065,0,0,1,0,.122l-.608.353a.23.23,0,0,1-.212,0l-.612-.354c-.059-.034-.059-.089,0-.122l.608-.354a.232.232,0,0,1,.212,0l.613.354" transform="translate(-195.79 250.462)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1526">
        <path id="Контур_6918" data-name="Контур 6918" d="M199.44-248.833a.064.064,0,0,1,0,.122l-.609.354a.226.226,0,0,1-.21,0l-.612-.353a.064.064,0,0,1,0-.122l.608-.354a.234.234,0,0,1,.212,0l.612.354" transform="translate(-197.965 249.212)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1528">
        <path id="Контур_6921" data-name="Контур 6921" d="M201.463-247.664c.059.034.06.089,0,.122l-.608.354a.235.235,0,0,1-.212,0l-.612-.354a.064.064,0,0,1,0-.121l.609-.354a.229.229,0,0,1,.211,0l.612.354" transform="translate(-199.988 248.044)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1530">
        <path id="Контур_6924" data-name="Контур 6924" d="M203.487-246.5a.064.064,0,0,1,0,.122l-.609.353a.231.231,0,0,1-.211,0l-.612-.354c-.059-.034-.06-.088,0-.122l.608-.353a.23.23,0,0,1,.211,0l.613.354" transform="translate(-202.011 246.874)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1532">
        <path id="Контур_6927" data-name="Контур 6927" d="M192.43-250.464a.064.064,0,0,1,0,.122l-.608.353a.234.234,0,0,1-.212,0l-1.68-.971a.064.064,0,0,1,0-.122l.608-.354a.233.233,0,0,1,.211,0l1.68.971" transform="translate(-189.888 251.46)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1534">
        <path id="Контур_6930" data-name="Контур 6930" d="M195.409-248.852a.065.065,0,0,1,0,.122l-.608.354a.236.236,0,0,1-.211,0l-.612-.354a.065.065,0,0,1,0-.123l.608-.353a.235.235,0,0,1,.212,0l.612.353" transform="translate(-193.934 249.23)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1536">
        <path id="Контур_6933" data-name="Контур 6933" d="M197.5-247.616a.065.065,0,0,1,0,.122l-.609.354a.235.235,0,0,1-.211,0l-.613-.354a.065.065,0,0,1,0-.122l.609-.354a.23.23,0,0,1,.211,0l.612.354" transform="translate(-196.023 247.995)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1538">
        <path id="Контур_6936" data-name="Контур 6936" d="M199.522-246.447a.064.064,0,0,1,0,.122l-.608.354a.235.235,0,0,1-.212,0l-.612-.354a.065.065,0,0,1,0-.122l.609-.353a.229.229,0,0,1,.211,0l.612.354" transform="translate(-198.047 246.827)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1540">
        <path id="Контур_6939" data-name="Контур 6939" d="M207.432-246.595a.065.065,0,0,1,0,.123l-.609.353a.232.232,0,0,1-.211,0l-.612-.354c-.059-.034-.06-.089,0-.122l.608-.354a.237.237,0,0,1,.212,0l.612.354" transform="translate(-205.957 246.973)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1542">
        <path id="Контур_6942" data-name="Контур 6942" d="M205.51-245.326a.065.065,0,0,1,0,.122l-.609.354a.233.233,0,0,1-.211,0l-.612-.354a.065.065,0,0,1,0-.122l.609-.354a.231.231,0,0,1,.211,0l.612.354" transform="translate(-204.036 245.706)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1544">
        <path id="Контур_6945" data-name="Контур 6945" d="M201.545-245.278a.065.065,0,0,1,0,.122l-.608.353a.235.235,0,0,1-.212,0l-.612-.354a.064.064,0,0,1,0-.122l.609-.353a.229.229,0,0,1,.211,0l.612.354" transform="translate(-200.07 245.658)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1546">
        <path id="Контур_6948" data-name="Контур 6948" d="M203.569-244.11a.065.065,0,0,1,0,.122l-.609.354a.238.238,0,0,1-.211,0l-.613-.354a.065.065,0,0,1,0-.122l.609-.354a.233.233,0,0,1,.211,0l.612.354" transform="translate(-202.094 244.489)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1548">
        <path id="Контур_6951" data-name="Контур 6951" d="M191.662-248.7a.065.065,0,0,1,0,.122l-.609.353a.229.229,0,0,1-.211,0l-2.748-1.587c-.059-.034-.06-.089,0-.123l.607-.353a.236.236,0,0,1,.211,0l2.748,1.588" transform="translate(-188.051 250.312)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1550">
        <path id="Контур_6954" data-name="Контур 6954" d="M195.557-246.423a.064.064,0,0,1,0,.122l-.607.353a.234.234,0,0,1-.212,0l-.613-.354a.065.065,0,0,1,0-.122l.608-.354a.235.235,0,0,1,.212,0l.612.354" transform="translate(-194.082 246.802)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1552">
        <path id="Контур_6957" data-name="Контур 6957" d="M197.58-245.255a.065.065,0,0,1,0,.122l-.608.353a.23.23,0,0,1-.212,0l-.612-.354a.064.064,0,0,1,0-.122l.608-.353a.232.232,0,0,1,.211,0l.612.353" transform="translate(-196.105 245.633)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1554">
        <path id="Контур_6960" data-name="Контур 6960" d="M199.6-244.086c.059.034.059.089,0,.122l-.609.354a.229.229,0,0,1-.211,0l-.611-.354c-.059-.034-.059-.089,0-.122l.608-.354a.233.233,0,0,1,.211,0l.612.354" transform="translate(-198.129 244.465)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1556">
        <path id="Контур_6963" data-name="Контур 6963" d="M201.627-242.917a.065.065,0,0,1,0,.122l-.609.354a.233.233,0,0,1-.211,0l-.613-.354a.064.064,0,0,1,0-.121l.609-.354a.232.232,0,0,1,.211,0l.613.354" transform="translate(-200.152 243.296)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1558">
        <path id="Контур_6966" data-name="Контур 6966" d="M209.455-245.425c.059.034.059.088,0,.122l-.608.353a.228.228,0,0,1-.211,0l-.613-.353a.065.065,0,0,1,0-.122l.609-.353a.233.233,0,0,1,.211,0l.612.354" transform="translate(-207.98 245.804)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1560">
        <path id="Контур_6969" data-name="Контур 6969" d="M207.534-244.157a.064.064,0,0,1,0,.121l-.609.354a.232.232,0,0,1-.211,0l-.612-.354c-.059-.034-.059-.089,0-.122l.609-.353a.228.228,0,0,1,.211,0l.613.354" transform="translate(-206.059 244.537)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1562">
        <path id="Контур_6972" data-name="Контур 6972" d="M205.592-242.941a.065.065,0,0,1,0,.122l-.609.353a.235.235,0,0,1-.212,0l-.612-.354a.065.065,0,0,1,0-.123l.609-.353a.23.23,0,0,1,.211,0l.612.353" transform="translate(-204.117 243.32)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1564">
        <path id="Контур_6975" data-name="Контур 6975" d="M203.65-241.748a.064.064,0,0,1,0,.122l-.609.354a.232.232,0,0,1-.211,0l-.612-.354a.065.065,0,0,1,0-.122l.608-.353a.235.235,0,0,1,.212,0l.612.354" transform="translate(-202.175 242.127)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1566">
        <path id="Контур_6978" data-name="Контур 6978" d="M189.6-247.654c.059.034.059.089,0,.122l-.609.354a.236.236,0,0,1-.211,0l-.612-.354a.065.065,0,0,1,0-.123l.609-.353a.233.233,0,0,1,.211,0l.612.354" transform="translate(-188.128 248.033)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1568">
        <path id="Контур_6981" data-name="Контур 6981" d="M191.627-246.485c.059.034.059.088,0,.122l-.608.354a.235.235,0,0,1-.211,0l-.613-.354a.065.065,0,0,1,0-.123l.609-.353a.233.233,0,0,1,.211,0l.612.354" transform="translate(-190.151 246.864)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1570">
        <path id="Контур_6984" data-name="Контур 6984" d="M197.922-242.849c.059.034.06.088,0,.122l-.608.354a.234.234,0,0,1-.212,0l-4.883-2.821a.065.065,0,0,1,0-.123l.607-.352a.232.232,0,0,1,.212,0l4.882,2.82" transform="translate(-192.176 245.695)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1572">
        <path id="Контур_6987" data-name="Контур 6987" d="M211.479-244.257a.065.065,0,0,1,0,.122l-.608.353a.232.232,0,0,1-.212,0l-.612-.354a.065.065,0,0,1,0-.122l.608-.353a.232.232,0,0,1,.212,0l.612.354" transform="translate(-210.004 244.636)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1574">
        <path id="Контур_6990" data-name="Контур 6990" d="M213.5-243.089a.065.065,0,0,1,0,.122l-.609.354a.232.232,0,0,1-.211,0l-.612-.354c-.059-.034-.059-.089,0-.121l.609-.354a.232.232,0,0,1,.211,0l.612.353" transform="translate(-212.027 243.467)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1576">
        <path id="Контур_6993" data-name="Контур 6993" d="M216.594-241.3a.064.064,0,0,1,0,.122l-.607.354a.234.234,0,0,1-.212,0l-1.68-.971a.064.064,0,0,1,0-.121l.609-.354a.236.236,0,0,1,.211,0l1.68.971" transform="translate(-214.051 242.297)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1578">
        <path id="Контур_6996" data-name="Контур 6996" d="M209.557-242.989a.065.065,0,0,1,0,.123l-.607.353a.232.232,0,0,1-.212,0l-.612-.354a.064.064,0,0,1,0-.122l.608-.353a.23.23,0,0,1,.212,0l.612.353" transform="translate(-208.083 243.368)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1580">
        <path id="Контур_6999" data-name="Контур 6999" d="M211.58-241.819c.059.034.059.089,0,.121l-.608.353a.228.228,0,0,1-.211,0l-.613-.353a.065.065,0,0,1,0-.122l.608-.353a.229.229,0,0,1,.211,0l.612.354" transform="translate(-210.106 242.199)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1582">
        <path id="Контур_7002" data-name="Контур 7002" d="M213.6-240.652a.065.065,0,0,1,0,.122l-.609.354a.237.237,0,0,1-.21,0l-.612-.355a.064.064,0,0,1,0-.122l.607-.353a.233.233,0,0,1,.212,0l.612.353" transform="translate(-212.13 241.03)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1584">
        <path id="Контур_7005" data-name="Контур 7005" d="M215.627-239.483a.065.065,0,0,1,0,.123l-.609.353a.233.233,0,0,1-.211,0l-.612-.354a.065.065,0,0,1,0-.122l.609-.353a.23.23,0,0,1,.211,0l.612.353" transform="translate(-214.152 239.861)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1586">
        <path id="Контур_7008" data-name="Контур 7008" d="M207.615-241.773a.065.065,0,0,1,0,.123l-.609.353a.233.233,0,0,1-.211,0l-.612-.354c-.059-.034-.06-.089,0-.122l.609-.354a.235.235,0,0,1,.211,0l.612.354" transform="translate(-206.14 242.151)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1588">
        <path id="Контур_7011" data-name="Контур 7011" d="M209.639-240.6a.065.065,0,0,1,0,.122l-.608.353a.234.234,0,0,1-.211,0l-.612-.353a.066.066,0,0,1,0-.123l.608-.353a.237.237,0,0,1,.212,0l.612.354" transform="translate(-208.164 240.983)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1590">
        <path id="Контур_7014" data-name="Контур 7014" d="M212.731-238.709a.064.064,0,0,1,0,.122l-.609.353a.234.234,0,0,1-.212,0l-1.68-.97a.065.065,0,0,1,0-.122l.608-.353a.225.225,0,0,1,.211,0l1.68.97" transform="translate(-210.188 239.706)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1592">
        <path id="Контур_7017" data-name="Контур 7017" d="M205.673-240.579c.059.034.059.089,0,.122l-.608.353a.229.229,0,0,1-.212,0l-.612-.353a.064.064,0,0,1,0-.122l.609-.354a.229.229,0,0,1,.211,0l.611.354" transform="translate(-204.199 240.959)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1594">
        <path id="Контур_7020" data-name="Контур 7020" d="M207.7-239.41a.065.065,0,0,1,0,.122l-.609.354a.233.233,0,0,1-.211,0l-.612-.353c-.059-.034-.059-.089,0-.123l.608-.353a.231.231,0,0,1,.211,0l.613.354" transform="translate(-206.222 239.79)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1596">
        <path id="Контур_7023" data-name="Контур 7023" d="M210.789-237.625a.065.065,0,0,1,0,.122l-.6.378a.254.254,0,0,1-.218-.025l-1.681-.971a.065.065,0,0,1,0-.122l.607-.353a.234.234,0,0,1,.212,0l1.68.971" transform="translate(-208.246 238.621)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1598">
        <path id="Контур_7026" data-name="Контур 7026" d="M203.768-239.473a.065.065,0,0,1,0,.123l-.609.353a.234.234,0,0,1-.212,0l-.612-.354a.065.065,0,0,1,0-.122l.609-.354a.233.233,0,0,1,.211,0l.613.353" transform="translate(-202.293 239.851)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1600">
        <path id="Контур_7029" data-name="Контур 7029" d="M208.966-236.472a.064.064,0,0,1,0,.122l-.607.353a.232.232,0,0,1-.212,0l-1.679-.971a.064.064,0,0,1,0-.122l.609-.354a.234.234,0,0,1,.211,0l1.679.971" transform="translate(-206.424 237.468)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1602">
        <path id="Контур_7032" data-name="Контур 7032" d="M205.791-238.3a.064.064,0,0,1,0,.122l-.609.354a.238.238,0,0,1-.211,0l-.613-.354a.065.065,0,0,1,0-.123l.609-.354a.236.236,0,0,1,.211,0l.612.354" transform="translate(-204.316 238.682)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1618">
        <path id="Контур_7056" data-name="Контур 7056" d="M190.17-246.235l-1.029.6-1.029.6-.052.02h-.117l-.052-.02-2.343-1.353-2.344-1.353-.034-.03-.012-.034.045-.064,1.029-.6,1.029-.6.11-.026.11.026,4.687,2.706.035.1Zm10.836-.866-.058-.041-14.155-8.173a.573.573,0,0,0-.148-.057.765.765,0,0,0-.339,0,.555.555,0,0,0-.148.057l-8.081,4.7-.1.086c-.065.094-.031.206.1.282v.649l14.156,8.173v-.649a.7.7,0,0,0,.635,0l4.04-2.348,4.04-2.348.1-.086a.173.173,0,0,0-.042-.241Z" transform="translate(-177.947 255.39)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1622">
        <path id="Контур_7062" data-name="Контур 7062" d="M206.222-234.532l8.08-4.7a.224.224,0,0,0,.131-.183v.649a.222.222,0,0,1-.13.183l-8.08,4.7v-.649" transform="translate(-206.221 239.41)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1624">
        <path id="Контур_7065" data-name="Контур 7065" d="M177.947-246a.226.226,0,0,0,.132.185l14.156,8.172a.7.7,0,0,0,.635,0V-237a.7.7,0,0,1-.635,0l-14.156-8.173a.224.224,0,0,1-.132-.185V-246" transform="translate(-177.946 246.002)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1720">
        <path id="Контур_7209" data-name="Контур 7209" d="M209.26-266.461l-13.726.937V-274.4l13.726,7.941" transform="translate(-195.534 274.402)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1722">
        <path id="Контур_7212" data-name="Контур 7212" d="M233.18-178.31l13.528-7.827a.273.273,0,0,1,.272,0h0l0,0h0l.518.3-.381,1.363v8.957a.915.915,0,0,1-.457.791l-13.034,7.542-.151.409-.521-.3a.492.492,0,0,1-.253-.433v-9.977a.951.951,0,0,1,.475-.822" transform="translate(-232.705 186.174)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1724">
        <path id="Контур_7215" data-name="Контур 7215" d="M233.694-176.913v9.978a.5.5,0,0,0,.746.43l13.215-7.646a.914.914,0,0,0,.457-.791v-10.382a.276.276,0,0,0-.413-.239l-13.528,7.827a.951.951,0,0,0-.476.822" transform="translate(-233.694 185.6)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1726">
        <path id="Контур_7218" data-name="Контур 7218" d="M234.39-175.491v8.879l13.726-7.941v-8.879l-13.726,7.942" transform="translate(-234.39 183.433)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1728">
        <path id="Контур_7221" data-name="Контур 7221" d="M263.707-152.465v.246a.247.247,0,0,1,.22,0v-.246a.24.24,0,0,0-.22,0" transform="translate(-263.707 152.491)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1730">
        <path id="Контур_7224" data-name="Контур 7224" d="M264.123-152.441v.246l2.058,1.2v-.248l-2.058-1.2" transform="translate(-264.123 152.441)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1732">
        <path id="Контур_7227" data-name="Контур 7227" d="M234.152-148.44v.649a.223.223,0,0,0,.13.183v-.65a.222.222,0,0,1-.13-.183" transform="translate(-234.152 148.44)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1734">
        <path id="Контур_7230" data-name="Контур 7230" d="M254.826-149.735v.246l4.687-2.706v-.246l-4.687,2.706" transform="translate(-254.826 152.441)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1736">
        <path id="Контур_7233" data-name="Контур 7233" d="M254.786-147.067c0-.082,0-.165,0-.246l-.046.064V-147l.046-.064" transform="translate(-254.739 147.313)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1738">
        <path id="Контур_7236" data-name="Контур 7236" d="M254.739-147.192v.246l.045.064v-.247l-.045-.063" transform="translate(-254.739 147.192)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1740">
        <path id="Контур_7239" data-name="Контур 7239" d="M259.141-147.228v.248l4.686-2.706v-.247l-4.687,2.706" transform="translate(-259.141 149.934)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1742">
        <path id="Контур_7242" data-name="Контур 7242" d="M258.725-144.807v.248a.244.244,0,0,0,.22,0v-.248a.24.24,0,0,1-.22,0" transform="translate(-258.725 144.807)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1744">
        <path id="Контур_7245" data-name="Контур 7245" d="M254.785-149.758a.067.067,0,0,0,0,.127l2.059,1.2a.24.24,0,0,0,.22,0l4.687-2.706a.067.067,0,0,0,0-.128l-2.058-1.2a.24.24,0,0,0-.22,0l-4.687,2.706" transform="translate(-254.74 152.491)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1746">
        <path id="Контур_7248" data-name="Контур 7248" d="M234.4-148.094v.65l8.081,4.7v-.649l-8.081-4.7" transform="translate(-234.398 148.094)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1748">
        <path id="Контур_7251" data-name="Контур 7251" d="M259.323-162.634a.065.065,0,0,0,0,.122l.609.353a.229.229,0,0,0,.211,0l.612-.353a.065.065,0,0,0,0-.122l-.607-.353a.234.234,0,0,0-.212,0l-.613.354" transform="translate(-259.279 163.013)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1750">
        <path id="Контур_7254" data-name="Контур 7254" d="M255.234-160.3a.065.065,0,0,0,0,.122l.608.354a.235.235,0,0,0,.212,0l.612-.353a.065.065,0,0,0,0-.123l-.608-.353a.234.234,0,0,0-.212,0l-.612.354" transform="translate(-255.19 160.679)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1752">
        <path id="Контур_7257" data-name="Контур 7257" d="M257.392-161.554a.065.065,0,0,0,0,.123l.608.353a.236.236,0,0,0,.211,0l.612-.354a.065.065,0,0,0,0-.122l-.608-.353a.233.233,0,0,0-.211,0l-.612.354" transform="translate(-257.348 161.933)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1754">
        <path id="Контур_7260" data-name="Контур 7260" d="M253.191-159.111a.065.065,0,0,0,0,.122l.609.354a.235.235,0,0,0,.211,0l.612-.354c.059-.034.059-.089,0-.122l-.608-.354a.231.231,0,0,0-.211,0l-.613.354" transform="translate(-253.147 159.491)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1756">
        <path id="Контур_7263" data-name="Контур 7263" d="M251.179-157.954a.065.065,0,0,0,0,.122l.608.353a.23.23,0,0,0,.212,0l.612-.354a.064.064,0,0,0,0-.122l-.61-.354a.232.232,0,0,0-.211,0l-.612.354" transform="translate(-251.135 158.333)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1758">
        <path id="Контур_7266" data-name="Контур 7266" d="M249.164-156.793a.064.064,0,0,0,0,.122l.608.353a.227.227,0,0,0,.212,0l.612-.354a.064.064,0,0,0,0-.122l-.609-.353a.234.234,0,0,0-.211,0l-.612.354" transform="translate(-249.12 157.172)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1760">
        <path id="Контур_7269" data-name="Контур 7269" d="M259.234-160.737a.065.065,0,0,0,0,.123l.609.354a.234.234,0,0,0,.211,0l1.68-.971c.059-.034.059-.089,0-.122l-.609-.353a.233.233,0,0,0-.211,0l-1.68.971" transform="translate(-259.189 161.732)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1762">
        <path id="Контур_7272" data-name="Контур 7272" d="M257.306-159.112a.064.064,0,0,0,0,.122l.607.353a.234.234,0,0,0,.212,0l.613-.354a.064.064,0,0,0,0-.122l-.609-.353a.229.229,0,0,0-.211,0l-.612.354" transform="translate(-257.262 159.492)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1764">
        <path id="Контур_7275" data-name="Контур 7275" d="M255.132-157.863a.064.064,0,0,0,0,.121l.608.354a.233.233,0,0,0,.211,0l.612-.354a.065.065,0,0,0,0-.122l-.61-.353a.226.226,0,0,0-.21,0l-.613.354" transform="translate(-255.088 158.242)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1766">
        <path id="Контур_7278" data-name="Контур 7278" d="M253.109-156.694c-.058.034-.059.089,0,.122l.609.354a.233.233,0,0,0,.211,0l.612-.354c.06-.033.06-.088,0-.121l-.608-.354a.229.229,0,0,0-.211,0l-.612.354" transform="translate(-253.064 157.074)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1768">
        <path id="Контур_7281" data-name="Контур 7281" d="M251.086-155.526a.065.065,0,0,0,0,.123l.608.353a.234.234,0,0,0,.212,0l.612-.354a.065.065,0,0,0,0-.122l-.609-.353a.23.23,0,0,0-.211,0l-.612.353" transform="translate(-251.041 155.904)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1770">
        <path id="Контур_7284" data-name="Контур 7284" d="M261.186-159.494c-.059.034-.06.089,0,.122l.607.353a.237.237,0,0,0,.212,0l1.68-.971a.065.065,0,0,0,0-.122l-.608-.353a.229.229,0,0,0-.211,0l-1.68.97" transform="translate(-261.142 160.49)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1772">
        <path id="Контур_7287" data-name="Контур 7287" d="M259.162-157.881a.064.064,0,0,0,0,.122l.609.354a.232.232,0,0,0,.211,0l.612-.354a.065.065,0,0,0,0-.122l-.608-.354a.235.235,0,0,0-.212,0l-.612.354" transform="translate(-259.118 158.26)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1774">
        <path id="Контур_7290" data-name="Контур 7290" d="M257.073-156.647a.065.065,0,0,0,0,.123l.609.353a.232.232,0,0,0,.211,0l.612-.354c.059-.034.059-.088,0-.123L257.9-157a.236.236,0,0,0-.211,0l-.612.354" transform="translate(-257.029 157.025)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1776">
        <path id="Контур_7293" data-name="Контур 7293" d="M255.05-155.478a.065.065,0,0,0,0,.122l.608.353a.232.232,0,0,0,.212,0l.612-.354a.065.065,0,0,0,0-.122l-.609-.354a.232.232,0,0,0-.211,0l-.612.354" transform="translate(-255.006 155.857)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1778">
        <path id="Контур_7296" data-name="Контур 7296" d="M247.14-155.625a.064.064,0,0,0,0,.122l.609.353a.227.227,0,0,0,.21,0l.613-.354a.064.064,0,0,0,0-.122l-.609-.354a.233.233,0,0,0-.211,0l-.612.354" transform="translate(-247.096 156.004)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1780">
        <path id="Контур_7299" data-name="Контур 7299" d="M249.062-154.357c-.059.034-.059.089,0,.122l.609.354a.235.235,0,0,0,.211,0l.612-.354a.064.064,0,0,0,0-.122l-.609-.353a.23.23,0,0,0-.211,0l-.612.353" transform="translate(-249.018 154.735)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1782">
        <path id="Контур_7302" data-name="Контур 7302" d="M253.027-154.309a.065.065,0,0,0,0,.122l.608.354a.24.24,0,0,0,.212,0l.612-.354a.065.065,0,0,0,0-.123l-.609-.353a.231.231,0,0,0-.211,0l-.613.354" transform="translate(-252.983 154.688)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1784">
        <path id="Контур_7305" data-name="Контур 7305" d="M251-153.14a.064.064,0,0,0,0,.122l.608.354a.234.234,0,0,0,.212,0l.612-.354a.065.065,0,0,0,0-.122l-.609-.354a.235.235,0,0,0-.212,0l-.612.354" transform="translate(-250.959 153.519)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1786">
        <path id="Контур_7308" data-name="Контур 7308" d="M261-157.73c-.059.034-.059.089,0,.122l.609.353a.23.23,0,0,0,.212,0l2.748-1.587a.064.064,0,0,0,0-.122l-.608-.354a.236.236,0,0,0-.211,0L261-157.73" transform="translate(-260.954 159.342)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1788">
        <path id="Контур_7311" data-name="Контур 7311" d="M259.015-155.454a.065.065,0,0,0,0,.122l.608.353a.229.229,0,0,0,.211,0l.613-.354a.064.064,0,0,0,0-.122l-.608-.354a.231.231,0,0,0-.211,0l-.612.353" transform="translate(-258.971 155.832)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1790">
        <path id="Контур_7314" data-name="Контур 7314" d="M256.992-154.284a.064.064,0,0,0,0,.122l.609.354a.237.237,0,0,0,.212,0l.611-.354a.065.065,0,0,0,0-.122l-.608-.353a.229.229,0,0,0-.211,0l-.612.354" transform="translate(-256.948 154.664)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1792">
        <path id="Контур_7317" data-name="Контур 7317" d="M254.968-153.116a.064.064,0,0,0,0,.122l.609.353a.233.233,0,0,0,.212,0L256.4-153a.064.064,0,0,0,0-.121l-.609-.354a.228.228,0,0,0-.211,0l-.612.354" transform="translate(-254.924 153.496)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1794">
        <path id="Контур_7320" data-name="Контур 7320" d="M252.945-151.947a.064.064,0,0,0,0,.122l.609.354a.236.236,0,0,0,.211,0l.612-.354a.064.064,0,0,0,0-.122l-.608-.354a.234.234,0,0,0-.211,0l-.613.354" transform="translate(-252.9 152.326)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1796">
        <path id="Контур_7323" data-name="Контур 7323" d="M245.116-154.455a.064.064,0,0,0,0,.122l.609.353a.228.228,0,0,0,.211,0l.612-.354a.064.064,0,0,0,0-.122l-.609-.353a.23.23,0,0,0-.211,0l-.613.354" transform="translate(-245.072 154.835)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1798">
        <path id="Контур_7326" data-name="Контур 7326" d="M247.038-153.188c-.059.034-.059.088,0,.122l.609.354a.236.236,0,0,0,.211,0l.612-.354a.065.065,0,0,0,0-.123l-.609-.353a.231.231,0,0,0-.211,0l-.612.354" transform="translate(-246.994 153.567)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1800">
        <path id="Контур_7329" data-name="Контур 7329" d="M248.98-151.971a.065.065,0,0,0,0,.122l.608.353a.233.233,0,0,0,.211,0l.612-.354a.065.065,0,0,0,0-.123l-.609-.354a.235.235,0,0,0-.211,0l-.612.354" transform="translate(-248.936 152.35)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1802">
        <path id="Контур_7332" data-name="Контур 7332" d="M250.922-150.778a.064.064,0,0,0,0,.122l.607.354a.234.234,0,0,0,.211,0l.612-.354a.064.064,0,0,0,0-.121l-.608-.354a.229.229,0,0,0-.211,0l-.612.354" transform="translate(-250.878 151.158)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1804">
        <path id="Контур_7335" data-name="Контур 7335" d="M264.968-156.684c-.059.034-.059.089,0,.122l.608.353a.235.235,0,0,0,.212,0l.612-.353a.065.065,0,0,0,0-.123l-.61-.354a.234.234,0,0,0-.21,0l-.612.354" transform="translate(-264.924 157.063)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1806">
        <path id="Контур_7338" data-name="Контур 7338" d="M262.945-155.516a.065.065,0,0,0,0,.123l.609.354a.235.235,0,0,0,.211,0l.613-.354a.065.065,0,0,0,0-.123l-.609-.353a.236.236,0,0,0-.211,0l-.612.354" transform="translate(-262.9 155.894)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1808">
        <path id="Контур_7341" data-name="Контур 7341" d="M252.828-151.879a.064.064,0,0,0,0,.121l.609.354a.229.229,0,0,0,.211,0l4.883-2.821a.065.065,0,0,0,0-.122l-.609-.353a.234.234,0,0,0-.211,0l-4.883,2.821" transform="translate(-252.784 154.725)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1810">
        <path id="Контур_7344" data-name="Контур 7344" d="M243.093-153.287a.065.065,0,0,0,0,.122l.609.353a.229.229,0,0,0,.211,0l.612-.354a.065.065,0,0,0,0-.122l-.609-.353a.232.232,0,0,0-.212,0l-.612.354" transform="translate(-243.049 153.666)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1812">
        <path id="Контур_7347" data-name="Контур 7347" d="M241.069-152.118a.065.065,0,0,0,0,.122l.609.353a.23.23,0,0,0,.211,0L242.5-152c.059-.034.059-.089,0-.122l-.609-.354a.229.229,0,0,0-.211,0l-.613.354" transform="translate(-241.025 152.498)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1814">
        <path id="Контур_7350" data-name="Контур 7350" d="M237.023-150.332a.065.065,0,0,0,0,.122l.607.353a.232.232,0,0,0,.211,0l1.68-.971a.064.064,0,0,0,0-.121l-.608-.354a.234.234,0,0,0-.212,0l-1.679.971" transform="translate(-236.979 151.328)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1816">
        <path id="Контур_7353" data-name="Контур 7353" d="M245.015-152.019a.065.065,0,0,0,0,.123l.608.353a.234.234,0,0,0,.211,0l.613-.354a.064.064,0,0,0,0-.122l-.609-.354a.233.233,0,0,0-.211,0l-.611.354" transform="translate(-244.97 152.398)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1818">
        <path id="Контур_7356" data-name="Контур 7356" d="M242.991-150.851a.065.065,0,0,0,0,.123l.609.353a.235.235,0,0,0,.211,0l.612-.354a.065.065,0,0,0,0-.122l-.608-.354a.232.232,0,0,0-.212,0l-.612.353" transform="translate(-242.947 151.23)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1820">
        <path id="Контур_7359" data-name="Контур 7359" d="M240.968-149.682a.065.065,0,0,0,0,.122l.609.354a.236.236,0,0,0,.211,0l.612-.354c.058-.034.059-.089,0-.122l-.609-.353a.231.231,0,0,0-.211,0l-.612.353" transform="translate(-240.924 150.06)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1822">
        <path id="Контур_7362" data-name="Контур 7362" d="M238.945-148.513a.065.065,0,0,0,0,.123l.609.354a.236.236,0,0,0,.211,0l.612-.354c.059-.034.059-.089,0-.122l-.608-.354a.233.233,0,0,0-.211,0l-.612.354" transform="translate(-238.9 148.892)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1824">
        <path id="Контур_7365" data-name="Контур 7365" d="M246.956-150.8a.065.065,0,0,0,0,.123l.608.353a.234.234,0,0,0,.212,0l.612-.354a.065.065,0,0,0,0-.122l-.608-.354a.236.236,0,0,0-.211,0l-.612.354" transform="translate(-246.912 151.181)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1826">
        <path id="Контур_7368" data-name="Контур 7368" d="M244.932-149.634a.065.065,0,0,0,0,.123l.608.353a.228.228,0,0,0,.211,0l.612-.353a.065.065,0,0,0,0-.124l-.609-.353a.236.236,0,0,0-.211,0l-.613.354" transform="translate(-244.888 150.012)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1828">
        <path id="Контур_7371" data-name="Контур 7371" d="M240.886-147.74a.065.065,0,0,0,0,.122l.607.354a.24.24,0,0,0,.212,0l1.68-.971a.065.065,0,0,0,0-.122l-.608-.353a.229.229,0,0,0-.211,0l-1.68.97" transform="translate(-240.842 148.736)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1830">
        <path id="Контур_7374" data-name="Контур 7374" d="M248.9-149.61a.065.065,0,0,0,0,.122l.608.354a.234.234,0,0,0,.211,0l.613-.354a.065.065,0,0,0,0-.122l-.607-.353a.231.231,0,0,0-.211,0l-.612.353" transform="translate(-248.854 149.988)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1832">
        <path id="Контур_7377" data-name="Контур 7377" d="M246.875-148.441c-.059.034-.059.089,0,.122l.609.354a.234.234,0,0,0,.211,0l.612-.354a.065.065,0,0,0,0-.122l-.607-.353a.233.233,0,0,0-.212,0l-.612.353" transform="translate(-246.83 148.82)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1834">
        <path id="Контур_7380" data-name="Контур 7380" d="M242.828-146.656c-.058.035-.059.089,0,.123l.6.378a.251.251,0,0,0,.219-.025l1.68-.97a.066.066,0,0,0,0-.123l-.609-.353a.235.235,0,0,0-.211,0l-1.68.971" transform="translate(-242.784 147.651)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1836">
        <path id="Контур_7383" data-name="Контур 7383" d="M250.8-148.5a.065.065,0,0,0,0,.122l.609.354a.23.23,0,0,0,.212,0l.612-.354a.064.064,0,0,0,0-.122l-.609-.354a.235.235,0,0,0-.21,0l-.613.354" transform="translate(-250.759 148.881)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1838">
        <path id="Контур_7386" data-name="Контур 7386" d="M244.65-145.5a.065.065,0,0,0,0,.122l.608.353a.231.231,0,0,0,.211,0l1.68-.971a.065.065,0,0,0,0-.122l-.608-.353a.231.231,0,0,0-.211,0l-1.68.971" transform="translate(-244.606 146.498)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1840">
        <path id="Контур_7389" data-name="Контур 7389" d="M248.781-147.334a.064.064,0,0,0,0,.122l.609.354a.233.233,0,0,0,.211,0l.612-.354a.065.065,0,0,0,0-.122l-.608-.353a.23.23,0,0,0-.212,0l-.612.353" transform="translate(-248.736 147.713)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1856">
        <path id="Контур_7413" data-name="Контур 7413" d="M245.029-155.295l.034-.1,4.687-2.706.11-.026.109.026,1.029.6,1.029.6.045.064-.011.034-.034.03-2.343,1.353-2.343,1.353-.052.02h-.117l-.051-.02-1.03-.6-1.029-.6Zm-10.845-.6.1.086,4.041,2.348,4.04,2.349a.7.7,0,0,0,.635,0v.649l14.155-8.172v-.649c.131-.076.165-.188.1-.282l-.1-.086-8.08-4.7a.543.543,0,0,0-.148-.057.745.745,0,0,0-.338,0,.547.547,0,0,0-.149.057l-14.155,8.173-.058.041a.173.173,0,0,0-.042.241Z" transform="translate(-234.152 164.421)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1860">
        <path id="Контур_7419" data-name="Контур 7419" d="M242.362-143.561l-8.081-4.7a.222.222,0,0,1-.13-.183v.649a.223.223,0,0,0,.13.183l8.081,4.7v-.649" transform="translate(-234.152 148.44)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1862">
        <path id="Контур_7422" data-name="Контур 7422" d="M264.631-155.033a.223.223,0,0,1-.132.185l-14.155,8.173a.7.7,0,0,1-.635,0v.649a.7.7,0,0,0,.634,0L264.5-154.2a.225.225,0,0,0,.132-.185v-.649" transform="translate(-249.709 155.033)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1958">
        <path id="Контур_7566" data-name="Контур 7566" d="M234.39-175.491l13.726.937v-8.879l-13.726,7.942" transform="translate(-234.39 183.433)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1960">
        <path id="Контур_7569" data-name="Контур 7569" d="M352.224-246.771l13.528-7.827a.273.273,0,0,1,.272,0h0l0,0h0l.518.3-.38,1.362v8.957a.913.913,0,0,1-.457.791l-13.034,7.542-.151.409-.521-.3a.493.493,0,0,1-.253-.433v-9.978a.951.951,0,0,1,.475-.822" transform="translate(-351.749 254.636)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1962">
        <path id="Контур_7572" data-name="Контур 7572" d="M352.738-245.375v9.978a.5.5,0,0,0,.745.43l13.215-7.646a.913.913,0,0,0,.457-.791v-10.382a.276.276,0,0,0-.414-.239L353.214-246.2a.95.95,0,0,0-.475.822" transform="translate(-352.738 254.062)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1964">
        <path id="Контур_7575" data-name="Контур 7575" d="M353.434-243.954v8.879l13.726-7.941V-251.9l-13.726,7.941" transform="translate(-353.434 251.895)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1966">
        <path id="Контур_7578" data-name="Контур 7578" d="M382.751-220.927v.246a.244.244,0,0,1,.22,0v-.246a.242.242,0,0,0-.22,0" transform="translate(-382.751 220.953)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1968">
        <path id="Контур_7581" data-name="Контур 7581" d="M383.168-220.9v.246l2.058,1.2v-.247l-2.058-1.2" transform="translate(-383.168 220.903)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1970">
        <path id="Контур_7584" data-name="Контур 7584" d="M353.2-216.9v.649a.224.224,0,0,0,.13.183v-.649a.223.223,0,0,1-.13-.183" transform="translate(-353.196 216.902)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1972">
        <path id="Контур_7587" data-name="Контур 7587" d="M373.87-218.2v.246l4.687-2.706v-.246L373.87-218.2" transform="translate(-373.87 220.903)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1974">
        <path id="Контур_7590" data-name="Контур 7590" d="M373.829-215.529c0-.082,0-.165,0-.246l-.046.064v.246l.045-.064" transform="translate(-373.783 215.775)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1976">
        <path id="Контур_7593" data-name="Контур 7593" d="M373.783-215.654v.246l.045.064v-.247l-.045-.063" transform="translate(-373.783 215.654)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1978">
        <path id="Контур_7596" data-name="Контур 7596" d="M378.185-215.69v.246l4.687-2.706v-.247l-4.687,2.706" transform="translate(-378.185 218.396)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1980">
        <path id="Контур_7599" data-name="Контур 7599" d="M377.769-213.268v.246a.241.241,0,0,0,.22,0v-.246a.244.244,0,0,1-.22,0" transform="translate(-377.769 213.268)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1982">
        <path id="Контур_7602" data-name="Контур 7602" d="M373.829-218.22a.067.067,0,0,0,0,.127l2.058,1.2a.244.244,0,0,0,.22,0l4.687-2.706a.067.067,0,0,0,0-.127l-2.058-1.2a.242.242,0,0,0-.22,0l-4.687,2.706" transform="translate(-373.784 220.953)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1984">
        <path id="Контур_7605" data-name="Контур 7605" d="M353.443-216.556v.649l8.081,4.7v-.649l-8.08-4.7" transform="translate(-353.443 216.556)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1986">
        <path id="Контур_7608" data-name="Контур 7608" d="M378.368-231.1a.065.065,0,0,0,0,.122l.609.353a.233.233,0,0,0,.211,0l.612-.353a.065.065,0,0,0,0-.123l-.608-.353a.235.235,0,0,0-.212,0l-.612.354" transform="translate(-378.324 231.475)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1988">
        <path id="Контур_7611" data-name="Контур 7611" d="M374.278-228.762c-.059.035-.059.089,0,.123l.608.354a.233.233,0,0,0,.211,0l.611-.354c.06-.034.06-.089,0-.123l-.608-.353a.237.237,0,0,0-.212,0l-.612.353" transform="translate(-374.234 229.14)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1990">
        <path id="Контур_7614" data-name="Контур 7614" d="M376.436-230.016a.065.065,0,0,0,0,.123l.608.353a.234.234,0,0,0,.211,0l.612-.354c.058-.034.059-.089,0-.123l-.608-.353a.236.236,0,0,0-.211,0l-.613.354" transform="translate(-376.393 230.394)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1992">
        <path id="Контур_7617" data-name="Контур 7617" d="M372.236-227.573a.065.065,0,0,0,0,.122l.608.353a.234.234,0,0,0,.211,0l.612-.354a.064.064,0,0,0,0-.121l-.609-.354a.23.23,0,0,0-.211,0l-.612.354" transform="translate(-372.192 227.953)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1994">
        <path id="Контур_7620" data-name="Контур 7620" d="M370.223-226.415a.064.064,0,0,0,0,.122l.608.353a.23.23,0,0,0,.212,0l.613-.354a.064.064,0,0,0,0-.122l-.609-.353a.229.229,0,0,0-.211,0l-.612.354" transform="translate(-370.179 226.795)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1996">
        <path id="Контур_7623" data-name="Контур 7623" d="M368.207-225.255a.065.065,0,0,0,0,.122l.608.353a.233.233,0,0,0,.212,0l.612-.354a.064.064,0,0,0,0-.122l-.609-.353a.235.235,0,0,0-.212,0l-.612.354" transform="translate(-368.164 225.634)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1998">
        <path id="Контур_7626" data-name="Контур 7626" d="M378.277-229.2a.065.065,0,0,0,0,.123l.609.354a.235.235,0,0,0,.212,0l1.68-.97a.065.065,0,0,0,0-.123l-.609-.353a.233.233,0,0,0-.211,0l-1.68.97" transform="translate(-378.233 230.194)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2000">
        <path id="Контур_7629" data-name="Контур 7629" d="M376.351-227.575a.064.064,0,0,0,0,.122l.608.354a.236.236,0,0,0,.211,0l.613-.354a.064.064,0,0,0,0-.122l-.609-.354a.231.231,0,0,0-.211,0l-.613.354" transform="translate(-376.307 227.954)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2002">
        <path id="Контур_7632" data-name="Контур 7632" d="M374.176-226.325a.065.065,0,0,0,0,.122l.608.353a.231.231,0,0,0,.211,0l.612-.353a.065.065,0,0,0,0-.123l-.609-.353a.232.232,0,0,0-.211,0l-.612.354" transform="translate(-374.132 226.704)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2004">
        <path id="Контур_7635" data-name="Контур 7635" d="M372.153-225.156c-.059.033-.06.089,0,.121l.608.354a.235.235,0,0,0,.212,0l.612-.354c.059-.034.059-.089,0-.121l-.608-.354a.233.233,0,0,0-.211,0l-.612.354" transform="translate(-372.109 225.535)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2006">
        <path id="Контур_7638" data-name="Контур 7638" d="M370.129-223.987a.065.065,0,0,0,0,.122l.608.353a.233.233,0,0,0,.212,0l.611-.354c.059-.033.06-.088,0-.122l-.609-.353a.231.231,0,0,0-.211,0l-.612.354" transform="translate(-370.085 224.366)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2008">
        <path id="Контур_7641" data-name="Контур 7641" d="M380.23-227.956c-.059.033-.06.089,0,.122l.608.353a.235.235,0,0,0,.212,0l1.68-.97a.065.065,0,0,0,0-.122l-.609-.354a.233.233,0,0,0-.211,0l-1.68.971" transform="translate(-380.185 228.952)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2010">
        <path id="Контур_7644" data-name="Контур 7644" d="M378.206-226.343a.065.065,0,0,0,0,.122l.608.354a.234.234,0,0,0,.212,0l.612-.354a.065.065,0,0,0,0-.122l-.608-.354a.235.235,0,0,0-.212,0l-.612.354" transform="translate(-378.162 226.722)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2012">
        <path id="Контур_7647" data-name="Контур 7647" d="M376.117-225.109a.065.065,0,0,0,0,.123l.609.354a.24.24,0,0,0,.212,0l.612-.354a.065.065,0,0,0,0-.122l-.608-.353a.232.232,0,0,0-.212,0l-.612.353" transform="translate(-376.073 225.487)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2014">
        <path id="Контур_7650" data-name="Контур 7650" d="M374.094-223.939a.064.064,0,0,0,0,.122l.607.354a.236.236,0,0,0,.213,0l.612-.354a.065.065,0,0,0,0-.122l-.609-.354a.236.236,0,0,0-.211,0l-.612.354" transform="translate(-374.049 224.318)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2016">
        <path id="Контур_7653" data-name="Контур 7653" d="M366.184-224.086a.065.065,0,0,0,0,.122l.609.354a.232.232,0,0,0,.211,0l.612-.354c.059-.033.06-.089,0-.122l-.608-.354a.236.236,0,0,0-.211,0l-.612.354" transform="translate(-366.14 224.465)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2018">
        <path id="Контур_7656" data-name="Контур 7656" d="M368.106-222.818c-.059.034-.059.088,0,.122l.608.354a.235.235,0,0,0,.211,0l.612-.354c.059-.034.06-.089,0-.122l-.609-.354a.233.233,0,0,0-.211,0l-.612.354" transform="translate(-368.062 223.198)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2020">
        <path id="Контур_7659" data-name="Контур 7659" d="M372.07-222.771c-.059.034-.059.089,0,.123l.608.353a.236.236,0,0,0,.212,0l.612-.353a.065.065,0,0,0,0-.122l-.608-.354a.232.232,0,0,0-.211,0l-.613.354" transform="translate(-372.026 223.15)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2022">
        <path id="Контур_7662" data-name="Контур 7662" d="M370.047-221.6a.065.065,0,0,0,0,.122l.608.354a.237.237,0,0,0,.212,0l.613-.354a.065.065,0,0,0,0-.123l-.609-.354a.236.236,0,0,0-.211,0l-.612.354" transform="translate(-370.003 221.981)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2024">
        <path id="Контур_7665" data-name="Контур 7665" d="M380.043-226.192a.064.064,0,0,0,0,.122l.608.354a.23.23,0,0,0,.212,0l2.748-1.587a.065.065,0,0,0,0-.122l-.608-.354a.236.236,0,0,0-.211,0l-2.748,1.588" transform="translate(-379.999 227.804)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2026">
        <path id="Контур_7668" data-name="Контур 7668" d="M378.059-223.916a.065.065,0,0,0,0,.122l.608.353a.229.229,0,0,0,.211,0l.613-.354a.065.065,0,0,0,0-.122l-.609-.353a.23.23,0,0,0-.211,0l-.612.353" transform="translate(-378.015 224.295)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2028">
        <path id="Контур_7671" data-name="Контур 7671" d="M376.036-222.746a.065.065,0,0,0,0,.122l.609.354a.232.232,0,0,0,.212,0l.612-.354a.064.064,0,0,0,0-.122l-.608-.353a.228.228,0,0,0-.211,0l-.612.354" transform="translate(-375.992 223.126)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2030">
        <path id="Контур_7674" data-name="Контур 7674" d="M374.012-221.578a.064.064,0,0,0,0,.122l.608.353a.23.23,0,0,0,.212,0l.612-.354c.058-.034.059-.088,0-.122l-.609-.354a.232.232,0,0,0-.211,0l-.612.354" transform="translate(-373.968 221.957)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2032">
        <path id="Контур_7677" data-name="Контур 7677" d="M371.989-220.409a.065.065,0,0,0,0,.122l.609.354a.232.232,0,0,0,.212,0l.612-.354a.064.064,0,0,0,0-.121l-.609-.354a.232.232,0,0,0-.212,0l-.612.353" transform="translate(-371.945 220.788)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2034">
        <path id="Контур_7680" data-name="Контур 7680" d="M364.16-222.917a.064.064,0,0,0,0,.122l.609.353a.227.227,0,0,0,.21,0l.613-.354a.064.064,0,0,0,0-.122l-.608-.353a.234.234,0,0,0-.212,0l-.612.354" transform="translate(-364.116 223.296)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2036">
        <path id="Контур_7683" data-name="Контур 7683" d="M366.083-221.649c-.058.034-.059.088,0,.122l.608.354a.236.236,0,0,0,.211,0l.613-.354c.058-.034.059-.089,0-.122l-.608-.352a.229.229,0,0,0-.212,0l-.612.354" transform="translate(-366.039 222.029)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2038">
        <path id="Контур_7686" data-name="Контур 7686" d="M368.024-220.433c-.059.034-.059.088,0,.122l.608.354a.234.234,0,0,0,.212,0l.612-.354a.065.065,0,0,0,0-.122l-.608-.354a.234.234,0,0,0-.211,0l-.612.354" transform="translate(-367.98 220.812)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2040">
        <path id="Контур_7689" data-name="Контур 7689" d="M369.966-219.24a.064.064,0,0,0,0,.122l.608.354a.237.237,0,0,0,.212,0l.612-.354a.065.065,0,0,0,0-.122l-.609-.353a.232.232,0,0,0-.211,0l-.612.354" transform="translate(-369.922 219.619)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2042">
        <path id="Контур_7692" data-name="Контур 7692" d="M384.013-225.146c-.059.034-.06.089,0,.122l.608.354a.237.237,0,0,0,.212,0l.612-.354a.065.065,0,0,0,0-.123l-.609-.354a.236.236,0,0,0-.211,0l-.611.354" transform="translate(-383.968 225.525)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2044">
        <path id="Контур_7695" data-name="Контур 7695" d="M381.988-223.977a.065.065,0,0,0,0,.123l.609.354a.236.236,0,0,0,.211,0l.612-.354a.065.065,0,0,0,0-.122l-.608-.353a.235.235,0,0,0-.212,0l-.612.353" transform="translate(-381.944 224.356)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2046">
        <path id="Контур_7698" data-name="Контур 7698" d="M371.872-220.341a.065.065,0,0,0,0,.122l.609.353a.229.229,0,0,0,.211,0l4.883-2.821a.065.065,0,0,0,0-.123l-.609-.353a.232.232,0,0,0-.211,0l-4.883,2.821" transform="translate(-371.828 223.187)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2048">
        <path id="Контур_7701" data-name="Контур 7701" d="M362.137-221.749a.065.065,0,0,0,0,.122l.608.353a.232.232,0,0,0,.212,0l.612-.354a.065.065,0,0,0,0-.122l-.609-.353a.231.231,0,0,0-.211,0l-.612.354" transform="translate(-362.092 222.128)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2050">
        <path id="Контур_7704" data-name="Контур 7704" d="M360.113-220.581a.065.065,0,0,0,0,.123l.609.353a.228.228,0,0,0,.211,0l.612-.353c.06-.034.06-.089,0-.122l-.609-.354a.233.233,0,0,0-.211,0l-.613.353" transform="translate(-360.069 220.959)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2052">
        <path id="Контур_7707" data-name="Контур 7707" d="M356.066-218.794a.065.065,0,0,0,0,.122l.608.353a.231.231,0,0,0,.211,0l1.68-.971a.064.064,0,0,0,0-.121l-.608-.354a.233.233,0,0,0-.211,0l-1.68.971" transform="translate(-356.023 219.79)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2054">
        <path id="Контур_7710" data-name="Контур 7710" d="M364.059-220.481c-.059.034-.059.088,0,.123l.607.353a.233.233,0,0,0,.211,0l.613-.353a.065.065,0,0,0,0-.123l-.609-.353a.231.231,0,0,0-.211,0l-.612.354" transform="translate(-364.014 220.86)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2056">
        <path id="Контур_7713" data-name="Контур 7713" d="M362.035-219.312c-.058.034-.059.089,0,.122l.608.353a.231.231,0,0,0,.211,0l.612-.354a.065.065,0,0,0,0-.123l-.608-.353a.234.234,0,0,0-.212,0l-.612.354" transform="translate(-361.991 219.691)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2058">
        <path id="Контур_7716" data-name="Контур 7716" d="M360.012-218.144c-.059.034-.06.089,0,.123l.609.354a.236.236,0,0,0,.211,0l.612-.354a.065.065,0,0,0,0-.122l-.609-.354a.236.236,0,0,0-.212,0l-.611.354" transform="translate(-359.968 218.523)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2060">
        <path id="Контур_7719" data-name="Контур 7719" d="M357.988-216.974a.065.065,0,0,0,0,.122l.609.353a.234.234,0,0,0,.211,0l.611-.354c.059-.034.059-.089,0-.122l-.608-.353a.23.23,0,0,0-.211,0l-.613.354" transform="translate(-357.944 217.353)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2062">
        <path id="Контур_7722" data-name="Контур 7722" d="M366-219.264a.065.065,0,0,0,0,.122l.607.353a.234.234,0,0,0,.212,0l.612-.353a.065.065,0,0,0,0-.123l-.608-.354a.237.237,0,0,0-.212,0l-.612.354" transform="translate(-365.956 219.643)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2064">
        <path id="Контур_7725" data-name="Контур 7725" d="M363.977-218.1a.065.065,0,0,0,0,.122l.608.353a.233.233,0,0,0,.211,0l.613-.353a.065.065,0,0,0,0-.124l-.609-.353a.234.234,0,0,0-.211,0l-.612.354" transform="translate(-363.933 218.474)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2066">
        <path id="Контур_7728" data-name="Контур 7728" d="M359.93-216.2a.065.065,0,0,0,0,.122l.607.353a.233.233,0,0,0,.212,0l1.679-.97a.065.065,0,0,0,0-.122l-.609-.353a.229.229,0,0,0-.211,0l-1.68.97" transform="translate(-359.886 217.197)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2068">
        <path id="Контур_7731" data-name="Контур 7731" d="M367.943-218.071a.065.065,0,0,0,0,.122l.609.354a.235.235,0,0,0,.212,0l.612-.354a.064.064,0,0,0,0-.121l-.608-.354a.228.228,0,0,0-.211,0l-.612.354" transform="translate(-367.898 218.451)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2070">
        <path id="Контур_7734" data-name="Контур 7734" d="M365.918-216.9a.064.064,0,0,0,0,.122l.608.354a.236.236,0,0,0,.211,0l.612-.354a.065.065,0,0,0,0-.122l-.608-.354a.232.232,0,0,0-.212,0l-.612.354" transform="translate(-365.875 217.282)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2072">
        <path id="Контур_7737" data-name="Контур 7737" d="M361.873-215.118c-.059.035-.059.089,0,.123l.6.379a.259.259,0,0,0,.219-.025l1.68-.971a.065.065,0,0,0,0-.123l-.608-.353a.228.228,0,0,0-.211,0l-1.68.97" transform="translate(-361.828 216.113)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2074">
        <path id="Контур_7740" data-name="Контур 7740" d="M369.848-216.965c-.058.034-.059.089,0,.122l.608.353a.226.226,0,0,0,.211,0l.613-.354a.065.065,0,0,0,0-.122l-.609-.354a.233.233,0,0,0-.211,0l-.612.353" transform="translate(-369.804 217.343)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2076">
        <path id="Контур_7743" data-name="Контур 7743" d="M363.694-213.964a.065.065,0,0,0,0,.122l.608.353a.23.23,0,0,0,.212,0l1.68-.971a.064.064,0,0,0,0-.122l-.608-.354a.231.231,0,0,0-.211,0l-1.679.971" transform="translate(-363.649 214.96)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2078">
        <path id="Контур_7746" data-name="Контур 7746" d="M367.824-215.8a.064.064,0,0,0,0,.121l.609.354a.232.232,0,0,0,.211,0l.612-.354a.065.065,0,0,0,0-.122l-.608-.354a.237.237,0,0,0-.212,0l-.612.354" transform="translate(-367.78 216.174)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2094">
        <path id="Контур_7770" data-name="Контур 7770" d="M364.073-223.756l.034-.1,4.687-2.706.11-.026.11.026,1.029.6,1.029.6.045.063-.012.034-.034.03-4.686,2.706-.052.02h-.117l-.051-.02-1.03-.6-1.029-.6Zm-10.844-.6.1.086,8.08,4.7a.7.7,0,0,0,.635,0v.649l14.156-8.173v-.649c.131-.075.165-.188.1-.282l-.1-.086-8.08-4.7a.538.538,0,0,0-.148-.057.763.763,0,0,0-.338,0,.545.545,0,0,0-.149.057l-14.155,8.173-.058.041a.172.172,0,0,0-.042.241Z" transform="translate(-353.196 232.882)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2098">
        <path id="Контур_7776" data-name="Контур 7776" d="M361.407-212.023l-8.08-4.7a.223.223,0,0,1-.13-.183v.649a.224.224,0,0,0,.13.183l8.081,4.7v-.649" transform="translate(-353.196 216.902)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2100">
        <path id="Контур_7779" data-name="Контур 7779" d="M383.675-223.494a.224.224,0,0,1-.132.185l-14.155,8.173a.7.7,0,0,1-.635,0v.649a.7.7,0,0,0,.634,0l14.156-8.173a.226.226,0,0,0,.132-.185v-.649" transform="translate(-368.753 223.494)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2196">
        <path id="Контур_7923" data-name="Контур 7923" d="M353.434-243.954l13.726.938V-251.9l-13.726,7.941" transform="translate(-353.434 251.895)" fill="none" clip-rule="evenodd"/>
      </clipPath>
    </defs>
    <g id="space" transform="translate(-0.787 569.164)">
      <path id="Контур_7924" data-name="Контур 7924" d="M160.316-189.365.787-282.189,176.9-383.84l159.492,92L160.316-189.365" transform="translate(0 -87.516)" fill="#dcdfe9" fill-rule="evenodd"/>
      <g id="Сгруппировать_4593" data-name="Сгруппировать 4593" transform="translate(0.787 -369.705)" clip-path="url(#clip-path)">
        <g id="Сгруппировать_4592" data-name="Сгруппировать 4592" transform="translate(-48.97 -273.26)" clip-path="url(#clip-path-2)">
          <path id="Контур_4273" data-name="Контур 4273" d="M-4.213-196.236H160.594V-90.417H-4.213Z" transform="translate(50.544 466.857)" fill="#c1c6d5"/>
        </g>
      </g>
      <g id="Сгруппировать_4595" data-name="Сгруппировать 4595" transform="translate(160.316 -379.352)" clip-path="url(#clip-path-3)">
        <g id="Сгруппировать_4594" data-name="Сгруппировать 4594" transform="translate(-208.499 -263.613)" clip-path="url(#clip-path-2)">
          <path id="Контур_4276" data-name="Контур 4276" d="M298.057-214.514H479.409V-99.049H298.057Z" transform="translate(-92.197 475.489)" fill="#c1c6d5"/>
        </g>
      </g>
      <g id="Сгруппировать_4597" data-name="Сгруппировать 4597" transform="translate(140.468 -565.814)" clip-path="url(#clip-path-5)">
        <g id="Сгруппировать_4596" data-name="Сгруппировать 4596" transform="translate(-188.651 -77.151)" clip-path="url(#clip-path-6)">
          <path id="Контур_4279" data-name="Контур 4279" d="M260.45-567.816H307.3v127.53H260.45Z" transform="translate(-74.438 642.329)" fill="#d9deea"/>
        </g>
      </g>
      <g id="Сгруппировать_4599" data-name="Сгруппировать 4599" transform="translate(134.69 -545.119)" clip-path="url(#clip-path-7)">
        <g id="Сгруппировать_4598" data-name="Сгруппировать 4598" transform="translate(-182.873 -97.846)" clip-path="url(#clip-path-2)">
          <path id="Контур_4282" data-name="Контур 4282" d="M249.5-528.6h11.056v106.835H249.5Z" transform="translate(-69.268 623.812)" fill="#c1c6d5"/>
        </g>
      </g>
      <g id="Сгруппировать_4601" data-name="Сгруппировать 4601" transform="translate(134.69 -569.164)" clip-path="url(#clip-path-9)">
        <g id="Сгруппировать_4600" data-name="Сгруппировать 4600" transform="translate(-182.873 -73.801)" clip-path="url(#clip-path-10)">
          <path id="Контур_4285" data-name="Контур 4285" d="M249.5-574.164h52.63v32.686H249.5Z" transform="translate(-69.268 645.326)" fill="#ced3e1"/>
        </g>
      </g>
      <g id="Сгруппировать_4603" data-name="Сгруппировать 4603" transform="translate(176.242 -562.465)" clip-path="url(#clip-path-11)">
        <g id="Сгруппировать_4602" data-name="Сгруппировать 4602" transform="translate(-224.426 -80.5)" clip-path="url(#clip-path-10)">
          <path id="Контур_4288" data-name="Контур 4288" d="M328.234-561.471H487.881v191.758H328.234Z" transform="translate(-106.447 639.332)" fill="#ced3e1"/>
        </g>
      </g>
      <g id="Сгруппировать_4605" data-name="Сгруппировать 4605" transform="translate(330.611 -477.542)" clip-path="url(#clip-path-13)">
        <g id="Сгруппировать_4604" data-name="Сгруппировать 4604" transform="translate(-378.794 -165.423)" clip-path="url(#clip-path-2)">
          <path id="Контур_4291" data-name="Контур 4291" d="M620.727-400.562h11.056v106.835H620.727Z" transform="translate(-244.571 563.346)" fill="#c1c6d5"/>
        </g>
      </g>
      <g id="Сгруппировать_4607" data-name="Сгруппировать 4607" transform="translate(176.242 -565.815)" clip-path="url(#clip-path-15)">
        <g id="Сгруппировать_4606" data-name="Сгруппировать 4606" transform="translate(-224.426 -77.15)" clip-path="url(#clip-path-10)">
          <path id="Контур_4294" data-name="Контур 4294" d="M328.234-567.818H493.659v96.913H328.234Z" transform="translate(-106.447 642.33)" fill="#ced3e1"/>
        </g>
      </g>
      <g id="Сгруппировать_4609" data-name="Сгруппировать 4609" transform="translate(112.601 -537.215)" clip-path="url(#clip-path-17)">
        <g id="Сгруппировать_4608" data-name="Сгруппировать 4608" transform="translate(-160.784 -105.75)" clip-path="url(#clip-path-18)">
          <path id="Контур_4297" data-name="Контур 4297" d="M207.648-513.629h26.9v107.644h-26.9Z" transform="translate(-49.503 616.74)" fill="#bbd3e2"/>
        </g>
      </g>
      <g id="Сгруппировать_4611" data-name="Сгруппировать 4611" transform="translate(107.922 -432.149)" clip-path="url(#clip-path-19)">
        <g id="Сгруппировать_4610" data-name="Сгруппировать 4610" transform="translate(-156.105 -210.816)" clip-path="url(#clip-path-18)">
          <path id="Контур_4300" data-name="Контур 4300" d="M198.783-314.552h7.682v9.443h-7.682Z" transform="translate(-45.317 522.73)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4613" data-name="Сгруппировать 4613" transform="translate(107.922 -448.634)" clip-path="url(#clip-path-21)">
        <g id="Сгруппировать_4612" data-name="Сгруппировать 4612" transform="translate(-156.105 -194.331)" clip-path="url(#clip-path-18)">
          <path id="Контур_4303" data-name="Контур 4303" d="M198.783-345.788h36.256v23.166H198.783Z" transform="translate(-45.317 537.48)" fill="#f0f0ed"/>
        </g>
      </g>
      <g id="Сгруппировать_4615" data-name="Сгруппировать 4615" transform="translate(110.326 -447.228)" clip-path="url(#clip-path-23)">
        <g id="Сгруппировать_4614" data-name="Сгруппировать 4614" transform="translate(-158.509 -195.738)" clip-path="url(#clip-path-18)">
          <path id="Контур_4306" data-name="Контур 4306" d="M203.338-343.123H237.19V-318.6H203.338Z" transform="translate(-47.468 536.222)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4617" data-name="Сгруппировать 4617" transform="translate(134.219 -539.986)" clip-path="url(#clip-path-25)">
        <g id="Сгруппировать_4616" data-name="Сгруппировать 4616" transform="translate(-182.402 -102.979)" clip-path="url(#clip-path-18)">
          <path id="Контур_4309" data-name="Контур 4309" d="M248.609-518.879h7.685v99.357h-7.685Z" transform="translate(-68.846 619.219)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4619" data-name="Сгруппировать 4619" transform="translate(134.219 -541.303)" clip-path="url(#clip-path-27)">
        <g id="Сгруппировать_4618" data-name="Сгруппировать 4618" transform="translate(-182.402 -101.663)" clip-path="url(#clip-path-18)">
          <path id="Контур_4312" data-name="Контур 4312" d="M248.609-521.373h9.959v8h-9.959Z" transform="translate(-68.846 620.397)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4621" data-name="Сгруппировать 4621" transform="translate(136.626 -539.898)" clip-path="url(#clip-path-29)">
        <g id="Сгруппировать_4620" data-name="Сгруппировать 4620" transform="translate(-184.809 -103.067)" clip-path="url(#clip-path-18)">
          <path id="Контур_4315" data-name="Контур 4315" d="M253.17-518.712h7.552v99.269H253.17Z" transform="translate(-71 619.14)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4623" data-name="Сгруппировать 4623" transform="translate(107.919 -524.826)" clip-path="url(#clip-path-31)">
        <g id="Сгруппировать_4622" data-name="Сгруппировать 4622" transform="translate(-156.103 -118.139)" clip-path="url(#clip-path-18)">
          <path id="Контур_4318" data-name="Контур 4318" d="M198.778-490.154h7.685V-390.8h-7.685Z" transform="translate(-45.314 605.654)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4625" data-name="Сгруппировать 4625" transform="translate(107.919 -526.143)" clip-path="url(#clip-path-33)">
        <g id="Сгруппировать_4624" data-name="Сгруппировать 4624" transform="translate(-156.103 -116.822)" clip-path="url(#clip-path-18)">
          <path id="Контур_4321" data-name="Контур 4321" d="M198.778-492.65h9.959v8h-9.959Z" transform="translate(-45.314 606.833)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4627" data-name="Сгруппировать 4627" transform="translate(110.327 -524.739)" clip-path="url(#clip-path-35)">
        <g id="Сгруппировать_4626" data-name="Сгруппировать 4626" transform="translate(-158.51 -118.227)" clip-path="url(#clip-path-18)">
          <path id="Контур_4324" data-name="Контур 4324" d="M203.339-489.988h7.552v99.268h-7.552Z" transform="translate(-47.468 605.576)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4629" data-name="Сгруппировать 4629" transform="translate(107.922 -527.59)" clip-path="url(#clip-path-37)">
        <g id="Сгруппировать_4628" data-name="Сгруппировать 4628" transform="translate(-156.105 -115.375)" clip-path="url(#clip-path-18)">
          <path id="Контур_4327" data-name="Контур 4327" d="M198.783-495.391h7.682v9.443h-7.682Z" transform="translate(-45.317 608.127)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4631" data-name="Сгруппировать 4631" transform="translate(107.922 -544.075)" clip-path="url(#clip-path-39)">
        <g id="Сгруппировать_4630" data-name="Сгруппировать 4630" transform="translate(-156.105 -98.89)" clip-path="url(#clip-path-18)">
          <path id="Контур_4330" data-name="Контур 4330" d="M198.783-526.627h36.256v23.166H198.783Z" transform="translate(-45.317 622.878)" fill="#f0f0ed"/>
        </g>
      </g>
      <g id="Сгруппировать_4633" data-name="Сгруппировать 4633" transform="translate(110.326 -542.669)" clip-path="url(#clip-path-41)">
        <g id="Сгруппировать_4632" data-name="Сгруппировать 4632" transform="translate(-158.509 -100.296)" clip-path="url(#clip-path-18)">
          <path id="Контур_4333" data-name="Контур 4333" d="M203.338-523.963H237.19v24.522H203.338Z" transform="translate(-47.468 621.62)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4635" data-name="Сгруппировать 4635" transform="translate(112.601 -468.982)" clip-path="url(#clip-path-43)">
        <g id="Сгруппировать_4634" data-name="Сгруппировать 4634" transform="translate(-160.784 -173.983)" clip-path="url(#clip-path-18)">
          <path id="Контур_4336" data-name="Контур 4336" d="M207.648-384.342h29.3V-363.8h-29.3Z" transform="translate(-49.503 555.687)" fill="#f0f0ed"/>
        </g>
      </g>
      <g id="Сгруппировать_4637" data-name="Сгруппировать 4637" transform="translate(112.601 -467.575)" clip-path="url(#clip-path-45)">
        <g id="Сгруппировать_4636" data-name="Сгруппировать 4636" transform="translate(-160.784 -175.39)" clip-path="url(#clip-path-18)">
          <path id="Контур_4339" data-name="Контур 4339" d="M207.648-381.677h29.3v21.9h-29.3Z" transform="translate(-49.503 554.428)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4639" data-name="Сгруппировать 4639" transform="translate(112.601 -530.367)" clip-path="url(#clip-path-47)">
        <g id="Сгруппировать_4638" data-name="Сгруппировать 4638" transform="translate(-160.784 -112.599)" clip-path="url(#clip-path-18)">
          <path id="Контур_4342" data-name="Контур 4342" d="M207.648-500.652h29.3v20.542h-29.3Z" transform="translate(-49.503 610.612)" fill="#f0f0ed"/>
        </g>
      </g>
      <g id="Сгруппировать_4641" data-name="Сгруппировать 4641" transform="translate(112.601 -528.96)" clip-path="url(#clip-path-49)">
        <g id="Сгруппировать_4640" data-name="Сгруппировать 4640" transform="translate(-160.784 -114.005)" clip-path="url(#clip-path-18)">
          <path id="Контур_4345" data-name="Контур 4345" d="M207.648-497.987h29.3v21.9h-29.3Z" transform="translate(-49.503 609.353)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4643" data-name="Сгруппировать 4643" transform="translate(86.301 -522.029)" clip-path="url(#clip-path-51)">
        <g id="Сгруппировать_4642" data-name="Сгруппировать 4642" transform="translate(-134.484 -120.936)" clip-path="url(#clip-path-18)">
          <path id="Контур_4348" data-name="Контур 4348" d="M157.816-484.854h26.9V-377.21h-26.9Z" transform="translate(-25.971 603.151)" fill="#bbd3e2"/>
        </g>
      </g>
      <g id="Сгруппировать_4645" data-name="Сгруппировать 4645" transform="translate(81.622 -416.962)" clip-path="url(#clip-path-53)">
        <g id="Сгруппировать_4644" data-name="Сгруппировать 4644" transform="translate(-129.805 -226.003)" clip-path="url(#clip-path-18)">
          <path id="Контур_4351" data-name="Контур 4351" d="M148.951-285.777h7.683v9.442h-7.683Z" transform="translate(-21.784 509.141)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4647" data-name="Сгруппировать 4647" transform="translate(81.622 -433.448)" clip-path="url(#clip-path-55)">
        <g id="Сгруппировать_4646" data-name="Сгруппировать 4646" transform="translate(-129.805 -209.517)" clip-path="url(#clip-path-18)">
          <path id="Контур_4354" data-name="Контур 4354" d="M148.951-317.014h36.256v23.166H148.951Z" transform="translate(-21.784 523.892)" fill="#f0f0ed"/>
        </g>
      </g>
      <g id="Сгруппировать_4649" data-name="Сгруппировать 4649" transform="translate(84.027 -432.042)" clip-path="url(#clip-path-57)">
        <g id="Сгруппировать_4648" data-name="Сгруппировать 4648" transform="translate(-132.21 -210.923)" clip-path="url(#clip-path-18)">
          <path id="Контур_4357" data-name="Контур 4357" d="M153.508-314.35h33.851v24.522H153.508Z" transform="translate(-23.936 522.634)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4651" data-name="Сгруппировать 4651" transform="translate(107.919 -524.8)" clip-path="url(#clip-path-59)">
        <g id="Сгруппировать_4650" data-name="Сгруппировать 4650" transform="translate(-156.103 -118.165)" clip-path="url(#clip-path-18)">
          <path id="Контур_4360" data-name="Контур 4360" d="M198.778-490.1h7.685v99.356h-7.685Z" transform="translate(-45.314 605.631)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4653" data-name="Сгруппировать 4653" transform="translate(107.919 -526.117)" clip-path="url(#clip-path-61)">
        <g id="Сгруппировать_4652" data-name="Сгруппировать 4652" transform="translate(-156.103 -116.848)" clip-path="url(#clip-path-18)">
          <path id="Контур_4363" data-name="Контур 4363" d="M198.778-492.6h9.959v8h-9.959Z" transform="translate(-45.314 606.809)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4655" data-name="Сгруппировать 4655" transform="translate(110.327 -524.712)" clip-path="url(#clip-path-63)">
        <g id="Сгруппировать_4654" data-name="Сгруппировать 4654" transform="translate(-158.51 -118.254)" clip-path="url(#clip-path-18)">
          <path id="Контур_4366" data-name="Контур 4366" d="M203.339-489.937h7.552v99.268h-7.552Z" transform="translate(-47.468 605.552)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4657" data-name="Сгруппировать 4657" transform="translate(81.62 -509.641)" clip-path="url(#clip-path-65)">
        <g id="Сгруппировать_4656" data-name="Сгруппировать 4656" transform="translate(-129.803 -133.325)" clip-path="url(#clip-path-18)">
          <path id="Контур_4369" data-name="Контур 4369" d="M148.947-461.381h7.685v99.356h-7.685Z" transform="translate(-21.783 592.067)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4659" data-name="Сгруппировать 4659" transform="translate(81.62 -510.958)" clip-path="url(#clip-path-67)">
        <g id="Сгруппировать_4658" data-name="Сгруппировать 4658" transform="translate(-129.803 -132.007)" clip-path="url(#clip-path-18)">
          <path id="Контур_4372" data-name="Контур 4372" d="M148.947-463.877h9.958v8h-9.958Z" transform="translate(-21.783 593.245)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4661" data-name="Сгруппировать 4661" transform="translate(84.027 -509.553)" clip-path="url(#clip-path-69)">
        <g id="Сгруппировать_4660" data-name="Сгруппировать 4660" transform="translate(-132.21 -133.412)" clip-path="url(#clip-path-18)">
          <path id="Контур_4375" data-name="Контур 4375" d="M153.508-461.215h7.551v99.269h-7.551Z" transform="translate(-23.936 591.988)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4663" data-name="Сгруппировать 4663" transform="translate(81.622 -512.404)" clip-path="url(#clip-path-71)">
        <g id="Сгруппировать_4662" data-name="Сгруппировать 4662" transform="translate(-129.805 -130.561)" clip-path="url(#clip-path-18)">
          <path id="Контур_4378" data-name="Контур 4378" d="M148.951-466.617h7.683v9.443h-7.683Z" transform="translate(-21.784 594.539)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4665" data-name="Сгруппировать 4665" transform="translate(81.622 -528.89)" clip-path="url(#clip-path-73)">
        <g id="Сгруппировать_4664" data-name="Сгруппировать 4664" transform="translate(-129.805 -114.075)" clip-path="url(#clip-path-18)">
          <path id="Контур_4381" data-name="Контур 4381" d="M148.951-497.854h36.256v23.166H148.951Z" transform="translate(-21.784 609.29)" fill="#f0f0ed"/>
        </g>
      </g>
      <g id="Сгруппировать_4667" data-name="Сгруппировать 4667" transform="translate(84.027 -527.483)" clip-path="url(#clip-path-75)">
        <g id="Сгруппировать_4666" data-name="Сгруппировать 4666" transform="translate(-132.21 -115.482)" clip-path="url(#clip-path-18)">
          <path id="Контур_4384" data-name="Контур 4384" d="M153.508-495.188h33.851v24.522H153.508Z" transform="translate(-23.936 608.031)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4669" data-name="Сгруппировать 4669" transform="translate(86.301 -453.796)" clip-path="url(#clip-path-77)">
        <g id="Сгруппировать_4668" data-name="Сгруппировать 4668" transform="translate(-134.484 -189.169)" clip-path="url(#clip-path-18)">
          <path id="Контур_4387" data-name="Контур 4387" d="M157.816-355.568h29.3v20.542h-29.3Z" transform="translate(-25.971 542.099)" fill="#f0f0ed"/>
        </g>
      </g>
      <g id="Сгруппировать_4671" data-name="Сгруппировать 4671" transform="translate(86.301 -452.389)" clip-path="url(#clip-path-79)">
        <g id="Сгруппировать_4670" data-name="Сгруппировать 4670" transform="translate(-134.484 -190.576)" clip-path="url(#clip-path-18)">
          <path id="Контур_4390" data-name="Контур 4390" d="M157.816-352.9h29.3v21.9h-29.3Z" transform="translate(-25.971 540.84)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4673" data-name="Сгруппировать 4673" transform="translate(86.301 -515.181)" clip-path="url(#clip-path-81)">
        <g id="Сгруппировать_4672" data-name="Сгруппировать 4672" transform="translate(-134.484 -127.785)" clip-path="url(#clip-path-18)">
          <path id="Контур_4393" data-name="Контур 4393" d="M157.816-471.878h29.3v20.542h-29.3Z" transform="translate(-25.971 597.024)" fill="#f0f0ed"/>
        </g>
      </g>
      <g id="Сгруппировать_4675" data-name="Сгруппировать 4675" transform="translate(86.301 -513.774)" clip-path="url(#clip-path-83)">
        <g id="Сгруппировать_4674" data-name="Сгруппировать 4674" transform="translate(-134.484 -129.191)" clip-path="url(#clip-path-18)">
          <path id="Контур_4396" data-name="Контур 4396" d="M157.816-469.213h29.3v21.9h-29.3Z" transform="translate(-25.971 595.765)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4677" data-name="Сгруппировать 4677" transform="translate(60.001 -506.852)" clip-path="url(#clip-path-85)">
        <g id="Сгруппировать_4676" data-name="Сгруппировать 4676" transform="translate(-108.184 -136.113)" clip-path="url(#clip-path-18)">
          <path id="Контур_4399" data-name="Контур 4399" d="M107.984-456.1h26.9v107.644h-26.9Z" transform="translate(-2.439 589.571)" fill="#bbd3e2"/>
        </g>
      </g>
      <g id="Сгруппировать_4679" data-name="Сгруппировать 4679" transform="translate(81.62 -506.852)" clip-path="url(#clip-path-87)">
        <g id="Сгруппировать_4678" data-name="Сгруппировать 4678" transform="translate(-245.947 -136.113)" clip-path="url(#clip-path-88)">
          <path id="Контур_4402" data-name="Контур 4402" d="M148.947-456.1h10v95.167h-10Z" transform="translate(92 589.571)" fill="#a4bccd"/>
        </g>
      </g>
      <g id="Сгруппировать_4681" data-name="Сгруппировать 4681" transform="translate(55.323 -401.785)" clip-path="url(#clip-path-89)">
        <g id="Сгруппировать_4680" data-name="Сгруппировать 4680" transform="translate(-103.506 -241.181)" clip-path="url(#clip-path-18)">
          <path id="Контур_4405" data-name="Контур 4405" d="M99.12-257.019H106.8v9.443H99.12Z" transform="translate(1.747 495.561)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4683" data-name="Сгруппировать 4683" transform="translate(55.323 -418.27)" clip-path="url(#clip-path-91)">
        <g id="Сгруппировать_4682" data-name="Сгруппировать 4682" transform="translate(-103.506 -224.695)" clip-path="url(#clip-path-18)">
          <path id="Контур_4408" data-name="Контур 4408" d="M99.12-288.255h36.256v23.166H99.12Z" transform="translate(1.747 510.311)" fill="#f0f0ed"/>
        </g>
      </g>
      <g id="Сгруппировать_4685" data-name="Сгруппировать 4685" transform="translate(57.728 -416.864)" clip-path="url(#clip-path-93)">
        <g id="Сгруппировать_4684" data-name="Сгруппировать 4684" transform="translate(-105.911 -226.101)" clip-path="url(#clip-path-18)">
          <path id="Контур_4411" data-name="Контур 4411" d="M103.676-285.591h33.851v24.522H103.676Z" transform="translate(-0.404 509.053)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4687" data-name="Сгруппировать 4687" transform="translate(81.62 -509.622)" clip-path="url(#clip-path-95)">
        <g id="Сгруппировать_4686" data-name="Сгруппировать 4686" transform="translate(-129.803 -133.343)" clip-path="url(#clip-path-18)">
          <path id="Контур_4414" data-name="Контур 4414" d="M148.947-461.346h7.685v99.357h-7.685Z" transform="translate(-21.783 592.05)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4689" data-name="Сгруппировать 4689" transform="translate(81.62 -510.938)" clip-path="url(#clip-path-97)">
        <g id="Сгруппировать_4688" data-name="Сгруппировать 4688" transform="translate(-129.803 -132.027)" clip-path="url(#clip-path-18)">
          <path id="Контур_4417" data-name="Контур 4417" d="M148.947-463.84h9.958v8h-9.958Z" transform="translate(-21.783 593.228)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4691" data-name="Сгруппировать 4691" transform="translate(84.027 -509.534)" clip-path="url(#clip-path-99)">
        <g id="Сгруппировать_4690" data-name="Сгруппировать 4690" transform="translate(-132.21 -133.431)" clip-path="url(#clip-path-18)">
          <path id="Контур_4420" data-name="Контур 4420" d="M153.508-461.18h7.551v99.269h-7.551Z" transform="translate(-23.936 591.972)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4693" data-name="Сгруппировать 4693" transform="translate(55.32 -494.462)" clip-path="url(#clip-path-101)">
        <g id="Сгруппировать_4692" data-name="Сгруппировать 4692" transform="translate(-103.504 -148.503)" clip-path="url(#clip-path-18)">
          <path id="Контур_4423" data-name="Контур 4423" d="M99.115-432.622H106.8v99.356H99.115Z" transform="translate(1.75 578.486)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4695" data-name="Сгруппировать 4695" transform="translate(55.32 -495.779)" clip-path="url(#clip-path-103)">
        <g id="Сгруппировать_4694" data-name="Сгруппировать 4694" transform="translate(-103.504 -147.186)" clip-path="url(#clip-path-18)">
          <path id="Контур_4426" data-name="Контур 4426" d="M99.115-435.117h9.958v8H99.115Z" transform="translate(1.75 579.664)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4697" data-name="Сгруппировать 4697" transform="translate(57.728 -494.375)" clip-path="url(#clip-path-105)">
        <g id="Сгруппировать_4696" data-name="Сгруппировать 4696" transform="translate(-105.911 -148.59)" clip-path="url(#clip-path-18)">
          <path id="Контур_4429" data-name="Контур 4429" d="M103.676-432.456h7.551v99.269h-7.551Z" transform="translate(-0.404 578.407)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4699" data-name="Сгруппировать 4699" transform="translate(55.323 -497.225)" clip-path="url(#clip-path-107)">
        <g id="Сгруппировать_4698" data-name="Сгруппировать 4698" transform="translate(-103.506 -145.74)" clip-path="url(#clip-path-18)">
          <path id="Контур_4432" data-name="Контур 4432" d="M99.12-437.857H106.8v9.442H99.12Z" transform="translate(1.747 580.958)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4701" data-name="Сгруппировать 4701" transform="translate(55.323 -513.711)" clip-path="url(#clip-path-109)">
        <g id="Сгруппировать_4700" data-name="Сгруппировать 4700" transform="translate(-103.506 -129.254)" clip-path="url(#clip-path-18)">
          <path id="Контур_4435" data-name="Контур 4435" d="M99.12-469.094h36.256v23.166H99.12Z" transform="translate(1.747 595.709)" fill="#f0f0ed"/>
        </g>
      </g>
      <g id="Сгруппировать_4703" data-name="Сгруппировать 4703" transform="translate(57.728 -512.305)" clip-path="url(#clip-path-111)">
        <g id="Сгруппировать_4702" data-name="Сгруппировать 4702" transform="translate(-105.911 -130.66)" clip-path="url(#clip-path-18)">
          <path id="Контур_4438" data-name="Контур 4438" d="M103.676-466.43h33.851v24.522H103.676Z" transform="translate(-0.404 594.451)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4705" data-name="Сгруппировать 4705" transform="translate(60.001 -438.618)" clip-path="url(#clip-path-113)">
        <g id="Сгруппировать_4704" data-name="Сгруппировать 4704" transform="translate(-108.184 -204.348)" clip-path="url(#clip-path-18)">
          <path id="Контур_4441" data-name="Контур 4441" d="M107.984-326.809h29.3v20.542h-29.3Z" transform="translate(-2.439 528.518)" fill="#f0f0ed"/>
        </g>
      </g>
      <g id="Сгруппировать_4707" data-name="Сгруппировать 4707" transform="translate(60.001 -437.212)" clip-path="url(#clip-path-115)">
        <g id="Сгруппировать_4706" data-name="Сгруппировать 4706" transform="translate(-108.184 -205.754)" clip-path="url(#clip-path-18)">
          <path id="Контур_4444" data-name="Контур 4444" d="M107.984-324.145h29.3v21.9h-29.3Z" transform="translate(-2.439 527.26)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4709" data-name="Сгруппировать 4709" transform="translate(60.001 -500.002)" clip-path="url(#clip-path-117)">
        <g id="Сгруппировать_4708" data-name="Сгруппировать 4708" transform="translate(-108.184 -142.963)" clip-path="url(#clip-path-18)">
          <path id="Контур_4447" data-name="Контур 4447" d="M107.984-443.119h29.3v20.542h-29.3Z" transform="translate(-2.439 583.443)" fill="#f0f0ed"/>
        </g>
      </g>
      <g id="Сгруппировать_4711" data-name="Сгруппировать 4711" transform="translate(60.001 -498.596)" clip-path="url(#clip-path-119)">
        <g id="Сгруппировать_4710" data-name="Сгруппировать 4710" transform="translate(-108.184 -144.369)" clip-path="url(#clip-path-18)">
          <path id="Контур_4450" data-name="Контур 4450" d="M107.984-440.455h29.3v21.9h-29.3Z" transform="translate(-2.439 582.185)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4713" data-name="Сгруппировать 4713" transform="translate(33.702 -491.681)" clip-path="url(#clip-path-121)">
        <g id="Сгруппировать_4712" data-name="Сгруппировать 4712" transform="translate(-81.885 -151.284)" clip-path="url(#clip-path-18)">
          <path id="Контур_4453" data-name="Контур 4453" d="M58.153-427.352h26.9v107.644h-26.9Z" transform="translate(21.093 575.997)" fill="#bbd3e2"/>
        </g>
      </g>
      <g id="Сгруппировать_4715" data-name="Сгруппировать 4715" transform="translate(29.023 -386.614)" clip-path="url(#clip-path-123)">
        <g id="Сгруппировать_4714" data-name="Сгруппировать 4714" transform="translate(-77.206 -256.351)" clip-path="url(#clip-path-18)">
          <path id="Контур_4456" data-name="Контур 4456" d="M49.288-228.275H56.97v9.442H49.288Z" transform="translate(25.279 481.987)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4717" data-name="Сгруппировать 4717" transform="translate(29.023 -403.1)" clip-path="url(#clip-path-125)">
        <g id="Сгруппировать_4716" data-name="Сгруппировать 4716" transform="translate(-77.206 -239.865)" clip-path="url(#clip-path-18)">
          <path id="Контур_4459" data-name="Контур 4459" d="M49.288-259.512H85.544v23.166H49.288Z" transform="translate(25.279 496.738)" fill="#f0f0ed"/>
        </g>
      </g>
      <g id="Сгруппировать_4719" data-name="Сгруппировать 4719" transform="translate(31.428 -401.694)" clip-path="url(#clip-path-127)">
        <g id="Сгруппировать_4718" data-name="Сгруппировать 4718" transform="translate(-79.611 -241.271)" clip-path="url(#clip-path-18)">
          <path id="Контур_4462" data-name="Контур 4462" d="M53.844-256.848H87.7v24.522H53.844Z" transform="translate(23.128 495.48)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4721" data-name="Сгруппировать 4721" transform="translate(55.32 -494.452)" clip-path="url(#clip-path-129)">
        <g id="Сгруппировать_4720" data-name="Сгруппировать 4720" transform="translate(-103.504 -148.513)" clip-path="url(#clip-path-18)">
          <path id="Контур_4465" data-name="Контур 4465" d="M99.115-432.6H106.8v99.356H99.115Z" transform="translate(1.75 578.476)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4723" data-name="Сгруппировать 4723" transform="translate(55.32 -495.769)" clip-path="url(#clip-path-131)">
        <g id="Сгруппировать_4722" data-name="Сгруппировать 4722" transform="translate(-103.504 -147.196)" clip-path="url(#clip-path-18)">
          <path id="Контур_4468" data-name="Контур 4468" d="M99.115-435.1h9.958v8H99.115Z" transform="translate(1.75 579.655)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4725" data-name="Сгруппировать 4725" transform="translate(57.728 -494.364)" clip-path="url(#clip-path-133)">
        <g id="Сгруппировать_4724" data-name="Сгруппировать 4724" transform="translate(-105.911 -148.601)" clip-path="url(#clip-path-18)">
          <path id="Контур_4471" data-name="Контур 4471" d="M103.676-432.436h7.551v99.269h-7.551Z" transform="translate(-0.404 578.398)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4727" data-name="Сгруппировать 4727" transform="translate(29.021 -479.293)" clip-path="url(#clip-path-135)">
        <g id="Сгруппировать_4726" data-name="Сгруппировать 4726" transform="translate(-77.204 -163.672)" clip-path="url(#clip-path-18)">
          <path id="Контур_4474" data-name="Контур 4474" d="M49.283-403.879h7.685v99.357H49.283Z" transform="translate(25.282 564.913)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4729" data-name="Сгруппировать 4729" transform="translate(29.021 -480.609)" clip-path="url(#clip-path-137)">
        <g id="Сгруппировать_4728" data-name="Сгруппировать 4728" transform="translate(-77.204 -162.356)" clip-path="url(#clip-path-18)">
          <path id="Контур_4477" data-name="Контур 4477" d="M49.283-406.373h9.959v8H49.283Z" transform="translate(25.282 566.09)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4731" data-name="Сгруппировать 4731" transform="translate(31.428 -479.205)" clip-path="url(#clip-path-139)">
        <g id="Сгруппировать_4730" data-name="Сгруппировать 4730" transform="translate(-79.611 -163.76)" clip-path="url(#clip-path-18)">
          <path id="Контур_4480" data-name="Контур 4480" d="M53.845-403.713H61.4v99.269H53.845Z" transform="translate(23.128 564.834)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4733" data-name="Сгруппировать 4733" transform="translate(29.023 -482.055)" clip-path="url(#clip-path-141)">
        <g id="Сгруппировать_4732" data-name="Сгруппировать 4732" transform="translate(-77.206 -160.91)" clip-path="url(#clip-path-18)">
          <path id="Контур_4483" data-name="Контур 4483" d="M49.288-409.113H56.97v9.442H49.288Z" transform="translate(25.279 567.384)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4735" data-name="Сгруппировать 4735" transform="translate(29.023 -498.542)" clip-path="url(#clip-path-143)">
        <g id="Сгруппировать_4734" data-name="Сгруппировать 4734" transform="translate(-77.206 -144.424)" clip-path="url(#clip-path-18)">
          <path id="Контур_4486" data-name="Контур 4486" d="M49.288-440.351H85.544v23.166H49.288Z" transform="translate(25.279 582.136)" fill="#f0f0ed"/>
        </g>
      </g>
      <g id="Сгруппировать_4737" data-name="Сгруппировать 4737" transform="translate(31.428 -497.135)" clip-path="url(#clip-path-145)">
        <g id="Сгруппировать_4736" data-name="Сгруппировать 4736" transform="translate(-79.611 -145.83)" clip-path="url(#clip-path-18)">
          <path id="Контур_4489" data-name="Контур 4489" d="M53.844-437.686H87.7v24.522H53.844Z" transform="translate(23.128 580.877)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4739" data-name="Сгруппировать 4739" transform="translate(33.702 -423.447)" clip-path="url(#clip-path-147)">
        <g id="Сгруппировать_4738" data-name="Сгруппировать 4738" transform="translate(-81.885 -219.518)" clip-path="url(#clip-path-18)">
          <path id="Контур_4492" data-name="Контур 4492" d="M58.153-298.064h29.3v20.541h-29.3Z" transform="translate(21.093 514.943)" fill="#f0f0ed"/>
        </g>
      </g>
      <g id="Сгруппировать_4741" data-name="Сгруппировать 4741" transform="translate(33.702 -422.041)" clip-path="url(#clip-path-149)">
        <g id="Сгруппировать_4740" data-name="Сгруппировать 4740" transform="translate(-81.885 -220.924)" clip-path="url(#clip-path-18)">
          <path id="Контур_4495" data-name="Контур 4495" d="M58.153-295.4h29.3v21.9h-29.3Z" transform="translate(21.093 513.685)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4743" data-name="Сгруппировать 4743" transform="translate(33.702 -484.832)" clip-path="url(#clip-path-151)">
        <g id="Сгруппировать_4742" data-name="Сгруппировать 4742" transform="translate(-81.885 -158.133)" clip-path="url(#clip-path-18)">
          <path id="Контур_4498" data-name="Контур 4498" d="M58.153-414.375h29.3v20.542h-29.3Z" transform="translate(21.093 569.869)" fill="#f0f0ed"/>
        </g>
      </g>
      <g id="Сгруппировать_4745" data-name="Сгруппировать 4745" transform="translate(33.702 -483.426)" clip-path="url(#clip-path-153)">
        <g id="Сгруппировать_4744" data-name="Сгруппировать 4744" transform="translate(-81.885 -159.539)" clip-path="url(#clip-path-18)">
          <path id="Контур_4501" data-name="Контур 4501" d="M58.153-411.711h29.3v21.9h-29.3Z" transform="translate(21.093 568.611)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4747" data-name="Сгруппировать 4747" transform="translate(7.403 -476.499)" clip-path="url(#clip-path-155)">
        <g id="Сгруппировать_4746" data-name="Сгруппировать 4746" transform="translate(-55.586 -166.466)" clip-path="url(#clip-path-18)">
          <path id="Контур_4504" data-name="Контур 4504" d="M8.322-398.586h26.9v107.644H8.322Z" transform="translate(44.625 562.413)" fill="#bbd3e2"/>
        </g>
      </g>
      <g id="Сгруппировать_4749" data-name="Сгруппировать 4749" transform="translate(2.724 -371.433)" clip-path="url(#clip-path-157)">
        <g id="Сгруппировать_4748" data-name="Сгруппировать 4748" transform="translate(-50.907 -271.533)" clip-path="url(#clip-path-18)">
          <path id="Контур_4507" data-name="Контур 4507" d="M-.543-199.509H7.139v9.443H-.543Z" transform="translate(48.811 468.403)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4751" data-name="Сгруппировать 4751" transform="translate(2.724 -387.918)" clip-path="url(#clip-path-159)">
        <g id="Сгруппировать_4750" data-name="Сгруппировать 4750" transform="translate(-50.907 -255.047)" clip-path="url(#clip-path-18)">
          <path id="Контур_4510" data-name="Контур 4510" d="M-.543-230.746H35.713v23.166H-.543Z" transform="translate(48.811 483.154)" fill="#f0f0ed"/>
        </g>
      </g>
      <g id="Сгруппировать_4753" data-name="Сгруппировать 4753" transform="translate(5.128 -386.512)" clip-path="url(#clip-path-161)">
        <g id="Сгруппировать_4752" data-name="Сгруппировать 4752" transform="translate(-53.311 -256.453)" clip-path="url(#clip-path-18)">
          <path id="Контур_4513" data-name="Контур 4513" d="M4.012-228.081H37.864v24.522H4.012Z" transform="translate(46.66 481.895)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4755" data-name="Сгруппировать 4755" transform="translate(29.021 -479.27)" clip-path="url(#clip-path-163)">
        <g id="Сгруппировать_4754" data-name="Сгруппировать 4754" transform="translate(-77.204 -163.695)" clip-path="url(#clip-path-18)">
          <path id="Контур_4516" data-name="Контур 4516" d="M49.283-403.836h7.685v99.356H49.283Z" transform="translate(25.282 564.892)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4757" data-name="Сгруппировать 4757" transform="translate(29.021 -480.587)" clip-path="url(#clip-path-165)">
        <g id="Сгруппировать_4756" data-name="Сгруппировать 4756" transform="translate(-77.204 -162.378)" clip-path="url(#clip-path-18)">
          <path id="Контур_4519" data-name="Контур 4519" d="M49.283-406.331h9.959v8H49.283Z" transform="translate(25.282 566.07)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4759" data-name="Сгруппировать 4759" transform="translate(31.428 -479.182)" clip-path="url(#clip-path-167)">
        <g id="Сгруппировать_4758" data-name="Сгруппировать 4758" transform="translate(-79.611 -163.783)" clip-path="url(#clip-path-18)">
          <path id="Контур_4522" data-name="Контур 4522" d="M53.845-403.67H61.4V-304.4H53.845Z" transform="translate(23.128 564.814)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4761" data-name="Сгруппировать 4761" transform="translate(2.722 -464.111)" clip-path="url(#clip-path-169)">
        <g id="Сгруппировать_4760" data-name="Сгруппировать 4760" transform="translate(-50.905 -178.854)" clip-path="url(#clip-path-18)">
          <path id="Контур_4525" data-name="Контур 4525" d="M-.547-375.113H7.137v99.357H-.547Z" transform="translate(48.813 551.328)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4763" data-name="Сгруппировать 4763" transform="translate(2.722 -465.427)" clip-path="url(#clip-path-171)">
        <g id="Сгруппировать_4762" data-name="Сгруппировать 4762" transform="translate(-50.905 -177.538)" clip-path="url(#clip-path-18)">
          <path id="Контур_4528" data-name="Контур 4528" d="M-.547-377.607H9.411v7.995H-.547Z" transform="translate(48.813 552.506)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4765" data-name="Сгруппировать 4765" transform="translate(5.128 -464.023)" clip-path="url(#clip-path-173)">
        <g id="Сгруппировать_4764" data-name="Сгруппировать 4764" transform="translate(-53.312 -178.942)" clip-path="url(#clip-path-18)">
          <path id="Контур_4531" data-name="Контур 4531" d="M4.013-374.947h7.552v99.269H4.013Z" transform="translate(46.66 551.25)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4767" data-name="Сгруппировать 4767" transform="translate(2.724 -466.874)" clip-path="url(#clip-path-175)">
        <g id="Сгруппировать_4766" data-name="Сгруппировать 4766" transform="translate(-50.907 -176.091)" clip-path="url(#clip-path-18)">
          <path id="Контур_4534" data-name="Контур 4534" d="M-.543-380.348H7.139v9.442H-.543Z" transform="translate(48.811 553.8)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4769" data-name="Сгруппировать 4769" transform="translate(2.724 -483.36)" clip-path="url(#clip-path-177)">
        <g id="Сгруппировать_4768" data-name="Сгруппировать 4768" transform="translate(-50.907 -159.605)" clip-path="url(#clip-path-18)">
          <path id="Контур_4537" data-name="Контур 4537" d="M-.543-411.585H35.713v23.166H-.543Z" transform="translate(48.811 568.552)" fill="#f0f0ed"/>
        </g>
      </g>
      <g id="Сгруппировать_4771" data-name="Сгруппировать 4771" transform="translate(5.128 -481.953)" clip-path="url(#clip-path-179)">
        <g id="Сгруппировать_4770" data-name="Сгруппировать 4770" transform="translate(-53.311 -161.012)" clip-path="url(#clip-path-18)">
          <path id="Контур_4540" data-name="Контур 4540" d="M4.012-408.92H37.864V-384.4H4.012Z" transform="translate(46.66 567.293)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4773" data-name="Сгруппировать 4773" transform="translate(7.403 -408.266)" clip-path="url(#clip-path-181)">
        <g id="Сгруппировать_4772" data-name="Сгруппировать 4772" transform="translate(-55.586 -234.699)" clip-path="url(#clip-path-18)">
          <path id="Контур_4543" data-name="Контур 4543" d="M8.322-269.3h29.3v20.542H8.322Z" transform="translate(44.625 501.36)" fill="#f0f0ed"/>
        </g>
      </g>
      <g id="Сгруппировать_4775" data-name="Сгруппировать 4775" transform="translate(7.403 -406.86)" clip-path="url(#clip-path-183)">
        <g id="Сгруппировать_4774" data-name="Сгруппировать 4774" transform="translate(-55.586 -236.106)" clip-path="url(#clip-path-18)">
          <path id="Контур_4546" data-name="Контур 4546" d="M8.322-266.635h29.3v21.9H8.322Z" transform="translate(44.625 500.102)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4777" data-name="Сгруппировать 4777" transform="translate(7.403 -469.65)" clip-path="url(#clip-path-185)">
        <g id="Сгруппировать_4776" data-name="Сгруппировать 4776" transform="translate(-55.586 -173.315)" clip-path="url(#clip-path-18)">
          <path id="Контур_4549" data-name="Контур 4549" d="M8.322-385.609h29.3v20.542H8.322Z" transform="translate(44.625 556.285)" fill="#f0f0ed"/>
        </g>
      </g>
      <g id="Сгруппировать_4779" data-name="Сгруппировать 4779" transform="translate(7.403 -468.244)" clip-path="url(#clip-path-187)">
        <g id="Сгруппировать_4778" data-name="Сгруппировать 4778" transform="translate(-55.586 -174.721)" clip-path="url(#clip-path-18)">
          <path id="Контур_4552" data-name="Контур 4552" d="M8.322-382.945h29.3v21.9H8.322Z" transform="translate(44.625 555.027)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4781" data-name="Сгруппировать 4781" transform="translate(21.419 -436.218)" clip-path="url(#clip-path-189)">
        <g id="Сгруппировать_4780" data-name="Сгруппировать 4780" transform="translate(-69.602 -206.747)" clip-path="url(#clip-path-190)">
          <path id="Контур_4555" data-name="Контур 4555" d="M34.879-322.262H208.407V-218H34.879Z" transform="translate(32.084 526.37)" fill="#d1d4de"/>
        </g>
      </g>
      <g id="Сгруппировать_4783" data-name="Сгруппировать 4783" transform="translate(104.529 -387.803)" clip-path="url(#clip-path-191)">
        <g id="Сгруппировать_4782" data-name="Сгруппировать 4782" transform="translate(-152.712 -255.162)" clip-path="url(#clip-path-190)">
          <path id="Контур_4558" data-name="Контур 4558" d="M192.354-230.528H365.883v104.261H192.354Z" transform="translate(-42.281 483.051)" fill="#d1d4de"/>
        </g>
      </g>
      <g id="Сгруппировать_4785" data-name="Сгруппировать 4785" transform="translate(175.651 -458.736)" clip-path="url(#clip-path-193)">
        <g id="Сгруппировать_4784" data-name="Сгруппировать 4784" transform="translate(-223.834 -184.229)" clip-path="url(#clip-path-18)">
          <path id="Контур_4561" data-name="Контур 4561" d="M327.113-364.929h18.059v12.636H327.113Z" transform="translate(-105.918 546.519)" fill="#55514f"/>
        </g>
      </g>
      <g id="Сгруппировать_4787" data-name="Сгруппировать 4787" transform="translate(197.592 -447.143)" clip-path="url(#clip-path-195)">
        <g id="Сгруппировать_4786" data-name="Сгруппировать 4786" transform="translate(-245.775 -195.822)" clip-path="url(#clip-path-18)">
          <path id="Контур_4564" data-name="Контур 4564" d="M368.687-342.963h52.35V-310.5h-52.35Z" transform="translate(-125.55 536.146)" fill="#55514f"/>
        </g>
      </g>
      <g id="Сгруппировать_4789" data-name="Сгруппировать 4789" transform="translate(297.646 -465.787)" clip-path="url(#clip-path-197)">
        <g id="Сгруппировать_4788" data-name="Сгруппировать 4788" transform="translate(-345.829 -177.179)" clip-path="url(#clip-path-198)">
          <path id="Контур_4567" data-name="Контур 4567" d="M558.266-378.288h22.356v46.606H558.266Z" transform="translate(-215.075 552.828)" fill="#afb5ca"/>
        </g>
      </g>
      <g id="Сгруппировать_4791" data-name="Сгруппировать 4791" transform="translate(314.724 -456.736)" clip-path="url(#clip-path-199)">
        <g id="Сгруппировать_4790" data-name="Сгруппировать 4790" transform="translate(-362.907 -186.229)" clip-path="url(#clip-path-200)">
          <path id="Контур_4570" data-name="Контур 4570" d="M590.625-361.139h6.608v37.555h-6.608Z" transform="translate(-230.356 544.729)" fill="#949bb2"/>
        </g>
      </g>
      <g id="Сгруппировать_4793" data-name="Сгруппировать 4793" transform="translate(297.646 -466.564)" clip-path="url(#clip-path-201)">
        <g id="Сгруппировать_4792" data-name="Сгруппировать 4792" transform="translate(-345.829 -176.401)" clip-path="url(#clip-path-202)">
          <path id="Контур_4573" data-name="Контур 4573" d="M558.266-379.761h23.686v15.883H558.266Z" transform="translate(-215.075 553.523)" fill="#a1a8c3"/>
        </g>
      </g>
      <g id="Сгруппировать_4795" data-name="Сгруппировать 4795" transform="translate(278.249 -476.884)" clip-path="url(#clip-path-203)">
        <g id="Сгруппировать_4794" data-name="Сгруппировать 4794" transform="translate(-326.432 -166.082)" clip-path="url(#clip-path-198)">
          <path id="Контур_4576" data-name="Контур 4576" d="M521.512-399.314h22.356v46.605H521.512Z" transform="translate(-197.719 562.757)" fill="#afb5ca"/>
        </g>
      </g>
      <g id="Сгруппировать_4797" data-name="Сгруппировать 4797" transform="translate(295.327 -467.833)" clip-path="url(#clip-path-205)">
        <g id="Сгруппировать_4796" data-name="Сгруппировать 4796" transform="translate(-343.51 -175.132)" clip-path="url(#clip-path-200)">
          <path id="Контур_4579" data-name="Контур 4579" d="M553.871-382.166h6.608v37.555h-6.608Z" transform="translate(-213 554.659)" fill="#949bb2"/>
        </g>
      </g>
      <g id="Сгруппировать_4799" data-name="Сгруппировать 4799" transform="translate(278.249 -477.661)" clip-path="url(#clip-path-207)">
        <g id="Сгруппировать_4798" data-name="Сгруппировать 4798" transform="translate(-326.432 -165.304)" clip-path="url(#clip-path-202)">
          <path id="Контур_4582" data-name="Контур 4582" d="M521.512-400.787H545.2V-384.9H521.512Z" transform="translate(-197.719 563.452)" fill="#a1a8c3"/>
        </g>
      </g>
      <g id="Сгруппировать_4801" data-name="Сгруппировать 4801" transform="translate(258.851 -487.976)" clip-path="url(#clip-path-209)">
        <g id="Сгруппировать_4800" data-name="Сгруппировать 4800" transform="translate(-307.034 -154.99)" clip-path="url(#clip-path-198)">
          <path id="Контур_4585" data-name="Контур 4585" d="M484.758-420.331h22.355v46.606H484.758Z" transform="translate(-180.363 572.682)" fill="#afb5ca"/>
        </g>
      </g>
      <g id="Сгруппировать_4803" data-name="Сгруппировать 4803" transform="translate(275.928 -478.925)" clip-path="url(#clip-path-211)">
        <g id="Сгруппировать_4802" data-name="Сгруппировать 4802" transform="translate(-324.111 -164.04)" clip-path="url(#clip-path-200)">
          <path id="Контур_4588" data-name="Контур 4588" d="M517.115-403.182h6.609v37.555h-6.609Z" transform="translate(-195.643 564.583)" fill="#949bb2"/>
        </g>
      </g>
      <g id="Сгруппировать_4805" data-name="Сгруппировать 4805" transform="translate(258.851 -488.753)" clip-path="url(#clip-path-213)">
        <g id="Сгруппировать_4804" data-name="Сгруппировать 4804" transform="translate(-307.034 -154.212)" clip-path="url(#clip-path-202)">
          <path id="Контур_4591" data-name="Контур 4591" d="M484.758-421.8h23.686v15.883H484.758Z" transform="translate(-180.363 573.377)" fill="#a1a8c3"/>
        </g>
      </g>
      <g id="Сгруппировать_4807" data-name="Сгруппировать 4807" transform="translate(175.631 -470.757)" clip-path="url(#clip-path-215)">
        <g id="Сгруппировать_4806" data-name="Сгруппировать 4806" transform="translate(-223.814 -172.208)" clip-path="url(#clip-path-18)">
          <path id="Контур_4594" data-name="Контур 4594" d="M327.076-387.706h12.659v21.022H327.076Z" transform="translate(-105.9 557.275)" fill="#cecdc8"/>
        </g>
      </g>
      <g id="Сгруппировать_4809" data-name="Сгруппировать 4809" transform="translate(168.212 -475.038)" clip-path="url(#clip-path-217)">
        <g id="Сгруппировать_4808" data-name="Сгруппировать 4808" transform="translate(-216.396 -167.927)" clip-path="url(#clip-path-18)">
          <path id="Контур_4597" data-name="Контур 4597" d="M313.019-395.818H333.1v13.806H313.019Z" transform="translate(-99.262 561.106)" fill="#cecdc8"/>
        </g>
      </g>
      <g id="Сгруппировать_4811" data-name="Сгруппировать 4811" transform="translate(168.212 -470.796)" clip-path="url(#clip-path-219)">
        <g id="Сгруппировать_4810" data-name="Сгруппировать 4810" transform="translate(-216.396 -172.169)" clip-path="url(#clip-path-18)">
          <path id="Контур_4600" data-name="Контур 4600" d="M313.019-387.78h12.7v21.056h-12.7Z" transform="translate(-99.262 557.31)" fill="#bcbbb7"/>
        </g>
      </g>
      <g id="Сгруппировать_4813" data-name="Сгруппировать 4813" transform="translate(168.212 -475.038)" clip-path="url(#clip-path-221)">
        <g id="Сгруппировать_4812" data-name="Сгруппировать 4812" transform="translate(-216.396 -167.927)" clip-path="url(#clip-path-18)">
          <path id="Контур_4603" data-name="Контур 4603" d="M313.019-395.818H333.1v13.806H313.019Z" transform="translate(-99.262 561.106)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4815" data-name="Сгруппировать 4815" transform="translate(175.592 -474.291)" clip-path="url(#clip-path-223)">
        <g id="Сгруппировать_4814" data-name="Сгруппировать 4814" transform="translate(-223.775 -168.674)" clip-path="url(#clip-path-18)">
          <path id="Контур_4606" data-name="Контур 4606" d="M327-394.4h11.4v12.312H327Z" transform="translate(-105.866 560.437)" fill="#bcbbb7"/>
        </g>
      </g>
      <g id="Сгруппировать_4819" data-name="Сгруппировать 4819" transform="translate(163.204 -450.59)" clip-path="url(#clip-path-225)">
        <g id="Сгруппировать_4818" data-name="Сгруппировать 4818" transform="translate(-211.387 -192.376)" clip-path="url(#clip-path-18)">
          <path id="Контур_4612" data-name="Контур 4612" d="M303.529-349.493h6.681v32.258h-6.681Z" transform="translate(-94.781 539.23)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4823" data-name="Сгруппировать 4823" transform="translate(85.966 -408.015)" clip-path="url(#clip-path-227)">
        <g id="Сгруппировать_4822" data-name="Сгруппировать 4822" transform="translate(-134.149 -234.95)" clip-path="url(#clip-path-18)">
          <path id="Контур_4618" data-name="Контур 4618" d="M157.181-268.824h21.671v34.341H157.181Z" transform="translate(-25.671 501.135)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4825" data-name="Сгруппировать 4825" transform="translate(84.564 -399.343)" clip-path="url(#clip-path-229)">
        <g id="Сгруппировать_4824" data-name="Сгруппировать 4824" transform="translate(-132.747 -243.622)" clip-path="url(#clip-path-18)">
          <path id="Контур_4621" data-name="Контур 4621" d="M154.525-252.393h6.682v25.669h-6.682Z" transform="translate(-24.417 493.376)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_4827" data-name="Сгруппировать 4827" transform="translate(84.564 -408.788)" clip-path="url(#clip-path-231)">
        <g id="Сгруппировать_4826" data-name="Сгруппировать 4826" transform="translate(-132.747 -234.177)" clip-path="url(#clip-path-18)">
          <path id="Контур_4624" data-name="Контур 4624" d="M154.525-270.289H177.6v15.535H154.525Z" transform="translate(-24.417 501.827)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4829" data-name="Сгруппировать 4829" transform="translate(85.965 -390.595)" clip-path="url(#clip-path-233)">
        <g id="Сгруппировать_4828" data-name="Сгруппировать 4828" transform="translate(-134.148 -252.37)" clip-path="url(#clip-path-18)">
          <path id="Контур_4627" data-name="Контур 4627" d="M157.18-235.818h27.53v19.608H157.18Z" transform="translate(-25.67 485.549)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_4831" data-name="Сгруппировать 4831" transform="translate(108.216 -387.271)" clip-path="url(#clip-path-235)">
        <g id="Сгруппировать_4830" data-name="Сгруппировать 4830" transform="translate(-156.399 -255.694)" clip-path="url(#clip-path-18)">
          <path id="Контур_4630" data-name="Контур 4630" d="M199.339-229.519h21.614v16.284H199.339Z" transform="translate(-45.579 482.574)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4833" data-name="Сгруппировать 4833" transform="translate(85.965 -400.079)" clip-path="url(#clip-path-237)">
        <g id="Сгруппировать_4832" data-name="Сгруппировать 4832" transform="translate(-134.148 -242.886)" clip-path="url(#clip-path-18)">
          <path id="Контур_4633" data-name="Контур 4633" d="M157.18-253.787h43.864V-226.2H157.18Z" transform="translate(-25.67 494.035)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4835" data-name="Сгруппировать 4835" transform="translate(85.965 -398.531)" clip-path="url(#clip-path-239)">
        <g id="Сгруппировать_4834" data-name="Сгруппировать 4834" transform="translate(-134.148 -244.434)" clip-path="url(#clip-path-18)">
          <path id="Контур_4636" data-name="Контур 4636" d="M157.18-250.855h27.53v19.609H157.18Z" transform="translate(-25.67 492.65)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_4837" data-name="Сгруппировать 4837" transform="translate(108.216 -395.207)" clip-path="url(#clip-path-241)">
        <g id="Сгруппировать_4836" data-name="Сгруппировать 4836" transform="translate(-156.399 -247.758)" clip-path="url(#clip-path-18)">
          <path id="Контур_4639" data-name="Контур 4639" d="M199.339-244.556h21.614v16.284H199.339Z" transform="translate(-45.579 489.675)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4839" data-name="Сгруппировать 4839" transform="translate(85.965 -408.014)" clip-path="url(#clip-path-243)">
        <g id="Сгруппировать_4838" data-name="Сгруппировать 4838" transform="translate(-134.148 -234.951)" clip-path="url(#clip-path-18)">
          <path id="Контур_4642" data-name="Контур 4642" d="M157.18-268.823h43.864v27.585H157.18Z" transform="translate(-25.67 501.135)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4841" data-name="Сгруппировать 4841" transform="translate(109.618 -394.434)" clip-path="url(#clip-path-245)">
        <g id="Сгруппировать_4840" data-name="Сгруппировать 4840" transform="translate(-157.801 -248.531)" clip-path="url(#clip-path-18)">
          <path id="Контур_4645" data-name="Контур 4645" d="M202-243.092h21.542v34.4H202Z" transform="translate(-46.834 488.984)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4843" data-name="Сгруппировать 4843" transform="translate(108.217 -385.708)" clip-path="url(#clip-path-247)">
        <g id="Сгруппировать_4842" data-name="Сгруппировать 4842" transform="translate(-156.4 -257.258)" clip-path="url(#clip-path-18)">
          <path id="Контур_4648" data-name="Контур 4648" d="M199.341-226.557h6.681v25.669h-6.681Z" transform="translate(-45.58 481.176)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_4845" data-name="Сгруппировать 4845" transform="translate(108.217 -395.207)" clip-path="url(#clip-path-249)">
        <g id="Сгруппировать_4844" data-name="Сгруппировать 4844" transform="translate(-156.4 -247.758)" clip-path="url(#clip-path-18)">
          <path id="Контур_4651" data-name="Контур 4651" d="M199.341-244.556h22.943v15.589H199.341Z" transform="translate(-45.58 489.675)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4847" data-name="Сгруппировать 4847" transform="translate(145.214 -442.152)" clip-path="url(#clip-path-251)">
        <g id="Сгруппировать_4846" data-name="Сгруппировать 4846" transform="translate(-193.397 -200.814)" clip-path="url(#clip-path-18)">
          <path id="Контур_4654" data-name="Контур 4654" d="M269.443-333.505h21.671v34.341H269.443Z" transform="translate(-78.684 531.68)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4849" data-name="Сгруппировать 4849" transform="translate(143.813 -433.479)" clip-path="url(#clip-path-253)">
        <g id="Сгруппировать_4848" data-name="Сгруппировать 4848" transform="translate(-191.996 -209.486)" clip-path="url(#clip-path-18)">
          <path id="Контур_4657" data-name="Контур 4657" d="M266.787-317.072h6.681V-291.4h-6.681Z" transform="translate(-77.43 523.92)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_4851" data-name="Сгруппировать 4851" transform="translate(143.813 -442.924)" clip-path="url(#clip-path-255)">
        <g id="Сгруппировать_4850" data-name="Сгруппировать 4850" transform="translate(-191.996 -200.041)" clip-path="url(#clip-path-18)">
          <path id="Контур_4660" data-name="Контур 4660" d="M266.787-334.969H289.86v15.534H266.787Z" transform="translate(-77.43 532.371)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4853" data-name="Сгруппировать 4853" transform="translate(145.214 -424.732)" clip-path="url(#clip-path-257)">
        <g id="Сгруппировать_4852" data-name="Сгруппировать 4852" transform="translate(-193.397 -218.233)" clip-path="url(#clip-path-18)">
          <path id="Контур_4663" data-name="Контур 4663" d="M269.442-300.5h27.53v19.609h-27.53Z" transform="translate(-78.684 516.093)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_4855" data-name="Сгруппировать 4855" transform="translate(167.464 -421.407)" clip-path="url(#clip-path-259)">
        <g id="Сгруппировать_4854" data-name="Сгруппировать 4854" transform="translate(-215.647 -221.558)" clip-path="url(#clip-path-18)">
          <path id="Контур_4666" data-name="Контур 4666" d="M311.6-294.2h21.614v16.284H311.6Z" transform="translate(-98.593 513.118)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4857" data-name="Сгруппировать 4857" transform="translate(145.214 -434.215)" clip-path="url(#clip-path-261)">
        <g id="Сгруппировать_4856" data-name="Сгруппировать 4856" transform="translate(-193.397 -208.75)" clip-path="url(#clip-path-18)">
          <path id="Контур_4669" data-name="Контур 4669" d="M269.442-318.467h43.865v27.586H269.442Z" transform="translate(-78.684 524.578)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4859" data-name="Сгруппировать 4859" transform="translate(145.214 -432.668)" clip-path="url(#clip-path-263)">
        <g id="Сгруппировать_4858" data-name="Сгруппировать 4858" transform="translate(-193.397 -210.298)" clip-path="url(#clip-path-18)">
          <path id="Контур_4672" data-name="Контур 4672" d="M269.442-315.535h27.53v19.608h-27.53Z" transform="translate(-78.684 523.194)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_4861" data-name="Сгруппировать 4861" transform="translate(167.464 -429.343)" clip-path="url(#clip-path-265)">
        <g id="Сгруппировать_4860" data-name="Сгруппировать 4860" transform="translate(-215.647 -213.622)" clip-path="url(#clip-path-18)">
          <path id="Контур_4675" data-name="Контур 4675" d="M311.6-309.236h21.614v16.284H311.6Z" transform="translate(-98.593 520.219)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4863" data-name="Сгруппировать 4863" transform="translate(145.214 -442.151)" clip-path="url(#clip-path-267)">
        <g id="Сгруппировать_4862" data-name="Сгруппировать 4862" transform="translate(-193.397 -200.814)" clip-path="url(#clip-path-18)">
          <path id="Контур_4678" data-name="Контур 4678" d="M269.442-333.5h43.865v27.587H269.442Z" transform="translate(-78.684 531.679)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4865" data-name="Сгруппировать 4865" transform="translate(168.866 -428.57)" clip-path="url(#clip-path-269)">
        <g id="Сгруппировать_4864" data-name="Сгруппировать 4864" transform="translate(-217.049 -214.395)" clip-path="url(#clip-path-18)">
          <path id="Контур_4681" data-name="Контур 4681" d="M314.258-307.771H335.8v34.395H314.258Z" transform="translate(-99.847 519.527)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4867" data-name="Сгруппировать 4867" transform="translate(167.466 -419.843)" clip-path="url(#clip-path-271)">
        <g id="Сгруппировать_4866" data-name="Сгруппировать 4866" transform="translate(-215.649 -223.122)" clip-path="url(#clip-path-18)">
          <path id="Контур_4684" data-name="Контур 4684" d="M311.6-291.236h6.681v25.668H311.6Z" transform="translate(-98.594 511.719)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_4869" data-name="Сгруппировать 4869" transform="translate(167.466 -429.343)" clip-path="url(#clip-path-273)">
        <g id="Сгруппировать_4868" data-name="Сгруппировать 4868" transform="translate(-215.649 -213.622)" clip-path="url(#clip-path-18)">
          <path id="Контур_4687" data-name="Контур 4687" d="M311.6-309.236h22.942v15.589H311.6Z" transform="translate(-98.594 520.219)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4871" data-name="Сгруппировать 4871" transform="translate(44.478 -448.222)" clip-path="url(#clip-path-275)">
        <g id="Сгруппировать_4870" data-name="Сгруппировать 4870" transform="translate(-92.661 -194.743)" clip-path="url(#clip-path-276)">
          <path id="Контур_4690" data-name="Контур 4690" d="M78.571-345.007H202.576v78.841H78.571Z" transform="translate(11.451 537.111)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4873" data-name="Сгруппировать 4873" transform="translate(44.478 -449.778)" clip-path="url(#clip-path-277)">
        <g id="Сгруппировать_4872" data-name="Сгруппировать 4872" transform="translate(-92.661 -193.187)" clip-path="url(#clip-path-18)">
          <path id="Контур_4693" data-name="Контур 4693" d="M78.571-347.955H202.576v75.73H78.571Z" transform="translate(11.451 538.504)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4875" data-name="Сгруппировать 4875" transform="translate(22.736 -393.732)" clip-path="url(#clip-path-279)">
        <g id="Сгруппировать_4874" data-name="Сгруппировать 4874" transform="translate(-70.919 -249.233)" clip-path="url(#clip-path-18)">
          <path id="Контур_4696" data-name="Контур 4696" d="M37.375-241.761H64.4v19.684H37.375Z" transform="translate(30.905 488.356)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4877" data-name="Сгруппировать 4877" transform="translate(22.736 -462.369)" clip-path="url(#clip-path-281)">
        <g id="Сгруппировать_4876" data-name="Сгруппировать 4876" transform="translate(-70.919 -180.596)" clip-path="url(#clip-path-18)">
          <path id="Контур_4699" data-name="Контур 4699" d="M37.375-371.813H183.122v86.546H37.375Z" transform="translate(30.905 549.77)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4879" data-name="Сгруппировать 4879" transform="translate(21.406 -392.959)" clip-path="url(#clip-path-283)">
        <g id="Сгруппировать_4878" data-name="Сгруппировать 4878" transform="translate(-69.589 -250.007)" clip-path="url(#clip-path-18)">
          <path id="Контур_4702" data-name="Контур 4702" d="M34.855-240.3H61.806v44.116H34.855Z" transform="translate(32.095 487.664)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_4881" data-name="Сгруппировать 4881" transform="translate(43.077 -381.101)" clip-path="url(#clip-path-285)">
        <g id="Сгруппировать_4880" data-name="Сгруппировать 4880" transform="translate(-91.26 -261.864)" clip-path="url(#clip-path-18)">
          <path id="Контур_4705" data-name="Контур 4705" d="M75.916-217.828H82.6v32.258H75.916Z" transform="translate(12.705 477.054)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4883" data-name="Сгруппировать 4883" transform="translate(21.406 -393.732)" clip-path="url(#clip-path-287)">
        <g id="Сгруппировать_4882" data-name="Сгруппировать 4882" transform="translate(-69.589 -249.233)" clip-path="url(#clip-path-18)">
          <path id="Контур_4708" data-name="Контур 4708" d="M34.855-241.761H63.207v18.72H34.855Z" transform="translate(32.095 488.356)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4885" data-name="Сгруппировать 4885" transform="translate(85.965 -397.11)" clip-path="url(#clip-path-289)">
        <g id="Сгруппировать_4884" data-name="Сгруппировать 4884" transform="translate(-134.148 -245.855)" clip-path="url(#clip-path-276)">
          <path id="Контур_4711" data-name="Контур 4711" d="M157.18-248.162h27.53v24.617H157.18Z" transform="translate(-25.67 491.378)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4887" data-name="Сгруппировать 4887" transform="translate(145.214 -431.246)" clip-path="url(#clip-path-291)">
        <g id="Сгруппировать_4886" data-name="Сгруппировать 4886" transform="translate(-193.397 -211.719)" clip-path="url(#clip-path-276)">
          <path id="Контур_4714" data-name="Контур 4714" d="M269.442-312.842h27.53v24.617h-27.53Z" transform="translate(-78.684 521.922)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4889" data-name="Сгруппировать 4889" transform="translate(224.657 -414.032)" clip-path="url(#clip-path-293)">
        <g id="Сгруппировать_4888" data-name="Сгруппировать 4888" transform="translate(-272.84 -228.933)" clip-path="url(#clip-path-18)">
          <path id="Контур_4717" data-name="Контур 4717" d="M419.968-280.225h26.951v44.116H419.968Z" transform="translate(-149.767 506.519)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_4891" data-name="Сгруппировать 4891" transform="translate(246.328 -402.174)" clip-path="url(#clip-path-295)">
        <g id="Сгруппировать_4890" data-name="Сгруппировать 4890" transform="translate(-294.511 -240.791)" clip-path="url(#clip-path-18)">
          <path id="Контур_4720" data-name="Контур 4720" d="M461.029-257.757h6.681V-225.5h-6.681Z" transform="translate(-169.157 495.909)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4893" data-name="Сгруппировать 4893" transform="translate(224.657 -414.805)" clip-path="url(#clip-path-297)">
        <g id="Сгруппировать_4892" data-name="Сгруппировать 4892" transform="translate(-272.84 -228.161)" clip-path="url(#clip-path-18)">
          <path id="Контур_4723" data-name="Контур 4723" d="M419.968-281.689H448.32v18.719H419.968Z" transform="translate(-149.767 507.211)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4895" data-name="Сгруппировать 4895" transform="translate(169.09 -359.599)" clip-path="url(#clip-path-299)">
        <g id="Сгруппировать_4894" data-name="Сгруппировать 4894" transform="translate(-217.273 -283.366)" clip-path="url(#clip-path-18)">
          <path id="Контур_4726" data-name="Контур 4726" d="M314.681-177.088h21.671v34.341H314.681Z" transform="translate(-100.047 457.815)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4897" data-name="Сгруппировать 4897" transform="translate(167.688 -350.927)" clip-path="url(#clip-path-301)">
        <g id="Сгруппировать_4896" data-name="Сгруппировать 4896" transform="translate(-215.871 -292.038)" clip-path="url(#clip-path-18)">
          <path id="Контур_4729" data-name="Контур 4729" d="M312.025-160.656h6.682v25.669h-6.682Z" transform="translate(-98.793 450.055)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_4899" data-name="Сгруппировать 4899" transform="translate(167.688 -360.373)" clip-path="url(#clip-path-303)">
        <g id="Сгруппировать_4898" data-name="Сгруппировать 4898" transform="translate(-215.871 -282.593)" clip-path="url(#clip-path-18)">
          <path id="Контур_4732" data-name="Контур 4732" d="M312.025-178.553H335.1v15.534H312.025Z" transform="translate(-98.793 458.507)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4901" data-name="Сгруппировать 4901" transform="translate(169.089 -342.18)" clip-path="url(#clip-path-305)">
        <g id="Сгруппировать_4900" data-name="Сгруппировать 4900" transform="translate(-217.272 -300.785)" clip-path="url(#clip-path-18)">
          <path id="Контур_4735" data-name="Контур 4735" d="M314.68-144.083h27.528v19.609H314.68Z" transform="translate(-100.047 442.229)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_4903" data-name="Сгруппировать 4903" transform="translate(191.339 -338.855)" clip-path="url(#clip-path-307)">
        <g id="Сгруппировать_4902" data-name="Сгруппировать 4902" transform="translate(-239.522 -304.11)" clip-path="url(#clip-path-18)">
          <path id="Контур_4738" data-name="Контур 4738" d="M356.838-137.783h21.615V-121.5H356.838Z" transform="translate(-119.955 439.254)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4905" data-name="Сгруппировать 4905" transform="translate(169.089 -351.663)" clip-path="url(#clip-path-309)">
        <g id="Сгруппировать_4904" data-name="Сгруппировать 4904" transform="translate(-217.272 -291.302)" clip-path="url(#clip-path-18)">
          <path id="Контур_4741" data-name="Контур 4741" d="M314.68-162.051h43.864v27.587H314.68Z" transform="translate(-100.047 450.714)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4907" data-name="Сгруппировать 4907" transform="translate(169.089 -350.116)" clip-path="url(#clip-path-311)">
        <g id="Сгруппировать_4906" data-name="Сгруппировать 4906" transform="translate(-217.272 -292.849)" clip-path="url(#clip-path-18)">
          <path id="Контур_4744" data-name="Контур 4744" d="M314.68-159.119h27.528v19.608H314.68Z" transform="translate(-100.047 449.329)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_4909" data-name="Сгруппировать 4909" transform="translate(191.339 -346.791)" clip-path="url(#clip-path-313)">
        <g id="Сгруппировать_4908" data-name="Сгруппировать 4908" transform="translate(-239.522 -296.174)" clip-path="url(#clip-path-18)">
          <path id="Контур_4747" data-name="Контур 4747" d="M356.838-152.819h21.615v16.283H356.838Z" transform="translate(-119.955 446.354)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4911" data-name="Сгруппировать 4911" transform="translate(169.089 -359.599)" clip-path="url(#clip-path-315)">
        <g id="Сгруппировать_4910" data-name="Сгруппировать 4910" transform="translate(-217.272 -283.366)" clip-path="url(#clip-path-18)">
          <path id="Контур_4750" data-name="Контур 4750" d="M314.68-177.088h43.864V-149.5H314.68Z" transform="translate(-100.047 457.815)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4913" data-name="Сгруппировать 4913" transform="translate(192.742 -346.018)" clip-path="url(#clip-path-317)">
        <g id="Сгруппировать_4912" data-name="Сгруппировать 4912" transform="translate(-240.925 -296.947)" clip-path="url(#clip-path-18)">
          <path id="Контур_4753" data-name="Контур 4753" d="M359.5-151.354h21.542v34.395H359.5Z" transform="translate(-121.21 445.663)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4915" data-name="Сгруппировать 4915" transform="translate(191.34 -337.292)" clip-path="url(#clip-path-319)">
        <g id="Сгруппировать_4914" data-name="Сгруппировать 4914" transform="translate(-239.523 -305.673)" clip-path="url(#clip-path-18)">
          <path id="Контур_4756" data-name="Контур 4756" d="M356.84-134.82h6.681v25.669H356.84Z" transform="translate(-119.956 437.855)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_4917" data-name="Сгруппировать 4917" transform="translate(191.34 -346.792)" clip-path="url(#clip-path-321)">
        <g id="Сгруппировать_4916" data-name="Сгруппировать 4916" transform="translate(-239.523 -296.174)" clip-path="url(#clip-path-18)">
          <path id="Контур_4759" data-name="Контур 4759" d="M356.84-152.82h22.944v15.589H356.84Z" transform="translate(-119.956 446.355)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4919" data-name="Сгруппировать 4919" transform="translate(228.337 -393.736)" clip-path="url(#clip-path-323)">
        <g id="Сгруппировать_4918" data-name="Сгруппировать 4918" transform="translate(-276.521 -249.229)" clip-path="url(#clip-path-18)">
          <path id="Контур_4762" data-name="Контур 4762" d="M426.942-241.769h21.672v34.341H426.942Z" transform="translate(-153.06 488.359)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4921" data-name="Сгруппировать 4921" transform="translate(226.936 -385.063)" clip-path="url(#clip-path-325)">
        <g id="Сгруппировать_4920" data-name="Сгруппировать 4920" transform="translate(-275.119 -257.902)" clip-path="url(#clip-path-18)">
          <path id="Контур_4765" data-name="Контур 4765" d="M424.287-225.336h6.681v25.669h-6.681Z" transform="translate(-151.806 480.599)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_4923" data-name="Сгруппировать 4923" transform="translate(226.936 -394.509)" clip-path="url(#clip-path-327)">
        <g id="Сгруппировать_4922" data-name="Сгруппировать 4922" transform="translate(-275.119 -248.456)" clip-path="url(#clip-path-18)">
          <path id="Контур_4768" data-name="Контур 4768" d="M424.287-243.233H447.36V-227.7H424.287Z" transform="translate(-151.806 489.051)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4925" data-name="Сгруппировать 4925" transform="translate(228.337 -376.316)" clip-path="url(#clip-path-329)">
        <g id="Сгруппировать_4924" data-name="Сгруппировать 4924" transform="translate(-276.52 -266.649)" clip-path="url(#clip-path-18)">
          <path id="Контур_4771" data-name="Контур 4771" d="M426.941-208.763H454.47v19.609H426.941Z" transform="translate(-153.06 472.773)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_4927" data-name="Сгруппировать 4927" transform="translate(250.587 -372.992)" clip-path="url(#clip-path-331)">
        <g id="Сгруппировать_4926" data-name="Сгруппировать 4926" transform="translate(-298.77 -269.974)" clip-path="url(#clip-path-18)">
          <path id="Контур_4774" data-name="Контур 4774" d="M469.1-202.463h21.614v16.284H469.1Z" transform="translate(-172.969 469.798)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4929" data-name="Сгруппировать 4929" transform="translate(228.337 -385.799)" clip-path="url(#clip-path-333)">
        <g id="Сгруппировать_4928" data-name="Сгруппировать 4928" transform="translate(-276.52 -257.166)" clip-path="url(#clip-path-18)">
          <path id="Контур_4777" data-name="Контур 4777" d="M426.941-226.73h43.865v27.585H426.941Z" transform="translate(-153.06 481.257)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4931" data-name="Сгруппировать 4931" transform="translate(228.337 -384.253)" clip-path="url(#clip-path-335)">
        <g id="Сгруппировать_4930" data-name="Сгруппировать 4930" transform="translate(-276.52 -258.713)" clip-path="url(#clip-path-18)">
          <path id="Контур_4780" data-name="Контур 4780" d="M426.941-223.8H454.47v19.609H426.941Z" transform="translate(-153.06 479.874)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_4933" data-name="Сгруппировать 4933" transform="translate(250.587 -380.928)" clip-path="url(#clip-path-337)">
        <g id="Сгруппировать_4932" data-name="Сгруппировать 4932" transform="translate(-298.77 -262.038)" clip-path="url(#clip-path-18)">
          <path id="Контур_4783" data-name="Контур 4783" d="M469.1-217.5h21.614v16.284H469.1Z" transform="translate(-172.969 476.899)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4935" data-name="Сгруппировать 4935" transform="translate(228.337 -393.736)" clip-path="url(#clip-path-339)">
        <g id="Сгруппировать_4934" data-name="Сгруппировать 4934" transform="translate(-276.52 -249.23)" clip-path="url(#clip-path-18)">
          <path id="Контур_4786" data-name="Контур 4786" d="M426.941-241.768h43.865v27.586H426.941Z" transform="translate(-153.06 488.359)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4937" data-name="Сгруппировать 4937" transform="translate(251.99 -380.154)" clip-path="url(#clip-path-341)">
        <g id="Сгруппировать_4936" data-name="Сгруппировать 4936" transform="translate(-300.173 -262.811)" clip-path="url(#clip-path-18)">
          <path id="Контур_4789" data-name="Контур 4789" d="M471.758-216.035H493.3v34.4H471.758Z" transform="translate(-174.224 476.207)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4939" data-name="Сгруппировать 4939" transform="translate(250.588 -371.428)" clip-path="url(#clip-path-343)">
        <g id="Сгруппировать_4938" data-name="Сгруппировать 4938" transform="translate(-298.771 -271.537)" clip-path="url(#clip-path-18)">
          <path id="Контур_4792" data-name="Контур 4792" d="M469.1-199.5h6.681v25.669H469.1Z" transform="translate(-172.97 468.399)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_4941" data-name="Сгруппировать 4941" transform="translate(250.588 -380.928)" clip-path="url(#clip-path-345)">
        <g id="Сгруппировать_4940" data-name="Сгруппировать 4940" transform="translate(-298.771 -262.038)" clip-path="url(#clip-path-18)">
          <path id="Контур_4795" data-name="Контур 4795" d="M469.1-217.5h22.943v15.589H469.1Z" transform="translate(-172.97 476.899)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4943" data-name="Сгруппировать 4943" transform="translate(127.601 -399.806)" clip-path="url(#clip-path-347)">
        <g id="Сгруппировать_4942" data-name="Сгруппировать 4942" transform="translate(-175.784 -243.159)" clip-path="url(#clip-path-276)">
          <path id="Контур_4798" data-name="Контур 4798" d="M236.07-253.271H360.075v78.84H236.07Z" transform="translate(-62.925 493.791)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4945" data-name="Сгруппировать 4945" transform="translate(127.601 -401.363)" clip-path="url(#clip-path-349)">
        <g id="Сгруппировать_4944" data-name="Сгруппировать 4944" transform="translate(-175.784 -241.602)" clip-path="url(#clip-path-18)">
          <path id="Контур_4801" data-name="Контур 4801" d="M236.07-256.22H360.075v75.73H236.07Z" transform="translate(-62.925 495.183)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4947" data-name="Сгруппировать 4947" transform="translate(105.86 -345.316)" clip-path="url(#clip-path-351)">
        <g id="Сгруппировать_4946" data-name="Сгруппировать 4946" transform="translate(-154.043 -297.649)" clip-path="url(#clip-path-18)">
          <path id="Контур_4804" data-name="Контур 4804" d="M194.875-150.025H221.9v19.684H194.875Z" transform="translate(-43.471 445.035)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4949" data-name="Сгруппировать 4949" transform="translate(105.86 -413.954)" clip-path="url(#clip-path-353)">
        <g id="Сгруппировать_4948" data-name="Сгруппировать 4948" transform="translate(-154.043 -229.011)" clip-path="url(#clip-path-18)">
          <path id="Контур_4807" data-name="Контур 4807" d="M194.875-280.078H340.622v86.547H194.875Z" transform="translate(-43.471 506.45)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4951" data-name="Сгруппировать 4951" transform="translate(104.529 -344.543)" clip-path="url(#clip-path-355)">
        <g id="Сгруппировать_4950" data-name="Сгруппировать 4950" transform="translate(-152.712 -298.422)" clip-path="url(#clip-path-18)">
          <path id="Контур_4810" data-name="Контур 4810" d="M192.354-148.56H219.3v44.116H192.354Z" transform="translate(-42.281 444.343)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_4953" data-name="Сгруппировать 4953" transform="translate(126.2 -332.685)" clip-path="url(#clip-path-357)">
        <g id="Сгруппировать_4952" data-name="Сгруппировать 4952" transform="translate(-174.383 -310.28)" clip-path="url(#clip-path-18)">
          <path id="Контур_4813" data-name="Контур 4813" d="M233.416-126.092h6.68v32.258h-6.68Z" transform="translate(-61.671 433.733)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4955" data-name="Сгруппировать 4955" transform="translate(104.529 -345.316)" clip-path="url(#clip-path-359)">
        <g id="Сгруппировать_4954" data-name="Сгруппировать 4954" transform="translate(-152.712 -297.649)" clip-path="url(#clip-path-18)">
          <path id="Контур_4816" data-name="Контур 4816" d="M192.354-150.025h28.352v18.72H192.354Z" transform="translate(-42.281 445.035)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4957" data-name="Сгруппировать 4957" transform="translate(169.089 -348.695)" clip-path="url(#clip-path-361)">
        <g id="Сгруппировать_4956" data-name="Сгруппировать 4956" transform="translate(-217.272 -294.271)" clip-path="url(#clip-path-276)">
          <path id="Контур_4819" data-name="Контур 4819" d="M314.68-156.426h27.528v24.618H314.68Z" transform="translate(-100.047 448.058)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4959" data-name="Сгруппировать 4959" transform="translate(228.337 -382.831)" clip-path="url(#clip-path-363)">
        <g id="Сгруппировать_4958" data-name="Сгруппировать 4958" transform="translate(-276.52 -260.134)" clip-path="url(#clip-path-276)">
          <path id="Контур_4822" data-name="Контур 4822" d="M426.941-221.106H454.47v24.617H426.941Z" transform="translate(-153.06 478.602)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4961" data-name="Сгруппировать 4961" transform="translate(174.165 -543.359)" clip-path="url(#clip-path-365)">
        <g id="Сгруппировать_4960" data-name="Сгруппировать 4960" transform="translate(-222.348 -99.606)" clip-path="url(#clip-path-18)">
          <path id="Контур_4825" data-name="Контур 4825" d="M324.3-525.269h72.356v83.076H324.3Z" transform="translate(-104.589 622.237)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_4963" data-name="Сгруппировать 4963" transform="translate(241.244 -505.792)" clip-path="url(#clip-path-367)">
        <g id="Сгруппировать_4962" data-name="Сгруппировать 4962" transform="translate(-289.427 -137.174)" clip-path="url(#clip-path-18)">
          <path id="Контур_4828" data-name="Контур 4828" d="M451.4-454.088h7.322v45.509H451.4Z" transform="translate(-164.608 588.623)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4965" data-name="Сгруппировать 4965" transform="translate(174.165 -544.565)" clip-path="url(#clip-path-369)">
        <g id="Сгруппировать_4964" data-name="Сгруппировать 4964" transform="translate(-222.348 -98.4)" clip-path="url(#clip-path-18)">
          <path id="Контур_4831" data-name="Контур 4831" d="M324.3-527.554h74.4V-482.3H324.3Z" transform="translate(-104.589 623.316)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4967" data-name="Сгруппировать 4967" transform="translate(149.003 -542.173)" clip-path="url(#clip-path-371)">
        <g id="Сгруппировать_4966" data-name="Сгруппировать 4966" transform="translate(-197.186 -100.792)" clip-path="url(#clip-path-18)">
          <path id="Контур_4834" data-name="Контур 4834" d="M276.621-523.022h32.492V-463H276.621Z" transform="translate(-82.074 621.175)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4969" data-name="Сгруппировать 4969" transform="translate(146.959 -527.663)" clip-path="url(#clip-path-373)">
        <g id="Сгруппировать_4968" data-name="Сгруппировать 4968" transform="translate(-195.142 -115.302)" clip-path="url(#clip-path-18)">
          <path id="Контур_4837" data-name="Контур 4837" d="M272.749-495.53h7.321v45.508h-7.321Z" transform="translate(-80.246 608.193)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_4971" data-name="Сгруппировать 4971" transform="translate(146.959 -543.359)" clip-path="url(#clip-path-375)">
        <g id="Сгруппировать_4970" data-name="Сгруппировать 4970" transform="translate(-195.142 -99.606)" clip-path="url(#clip-path-18)">
          <path id="Контур_4840" data-name="Контур 4840" d="M272.749-525.269h34.535v22.178H272.749Z" transform="translate(-80.246 622.237)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_4973" data-name="Сгруппировать 4973" transform="translate(195.386 -522.503)" clip-path="url(#clip-path-377)">
        <g id="Сгруппировать_4972" data-name="Сгруппировать 4972" transform="translate(-243.569 -120.462)" clip-path="url(#clip-path-18)">
          <path id="Контур_4843" data-name="Контур 4843" d="M364.506-485.753h41.405v51.263H364.506Z" transform="translate(-123.576 603.576)" fill="#262b2b"/>
        </g>
      </g>
      <g id="Сгруппировать_4975" data-name="Сгруппировать 4975" transform="translate(231.513 -502.412)" clip-path="url(#clip-path-379)">
        <g id="Сгруппировать_4974" data-name="Сгруппировать 4974" transform="translate(-279.696 -140.553)" clip-path="url(#clip-path-18)">
          <path id="Контур_4846" data-name="Контур 4846" d="M432.959-447.685h6.593v31.172h-6.593Z" transform="translate(-155.902 585.599)" fill="#47504f"/>
        </g>
      </g>
      <g id="Сгруппировать_4977" data-name="Сгруппировать 4977" transform="translate(195.386 -523.28)" clip-path="url(#clip-path-381)">
        <g id="Сгруппировать_4976" data-name="Сгруппировать 4976" transform="translate(-243.569 -119.685)" clip-path="url(#clip-path-18)">
          <path id="Контур_4849" data-name="Контур 4849" d="M364.506-487.224h42.72V-460.3h-42.72Z" transform="translate(-123.576 604.271)" fill="#6b7373"/>
        </g>
      </g>
      <g id="Сгруппировать_4979" data-name="Сгруппировать 4979" transform="translate(195.386 -522.503)" clip-path="url(#clip-path-383)">
        <g id="Сгруппировать_4978" data-name="Сгруппировать 4978" transform="translate(-243.569 -120.462)" clip-path="url(#clip-path-18)">
          <path id="Контур_4852" data-name="Контур 4852" d="M364.506-485.753h41.405v51.263H364.506Z" transform="translate(-123.576 603.576)" fill="#363e3e"/>
        </g>
      </g>
      <g id="Сгруппировать_4981" data-name="Сгруппировать 4981" transform="translate(233.336 -462.095)" clip-path="url(#clip-path-385)">
        <g id="Сгруппировать_4980" data-name="Сгруппировать 4980" transform="translate(-281.52 -180.87)" clip-path="url(#clip-path-18)">
          <path id="Контур_4855" data-name="Контур 4855" d="M436.414-371.293h8.378v39.511h-8.378Z" transform="translate(-157.533 549.524)" fill="#47504f"/>
        </g>
      </g>
      <g id="Сгруппировать_4983" data-name="Сгруппировать 4983" transform="translate(230.219 -463.893)" clip-path="url(#clip-path-387)">
        <g id="Сгруппировать_4982" data-name="Сгруппировать 4982" transform="translate(-278.403 -179.072)" clip-path="url(#clip-path-18)">
          <path id="Контур_4858" data-name="Контур 4858" d="M430.508-374.7H442v8.861H430.508Z" transform="translate(-154.744 551.134)" fill="#6b7373"/>
        </g>
      </g>
      <g id="Сгруппировать_4985" data-name="Сгруппировать 4985" transform="translate(230.219 -462.111)" clip-path="url(#clip-path-389)">
        <g id="Сгруппировать_4984" data-name="Сгруппировать 4984" transform="translate(-278.403 -180.854)" clip-path="url(#clip-path-18)">
          <path id="Контур_4861" data-name="Контур 4861" d="M430.508-371.324H438.9V-331.8h-8.395Z" transform="translate(-154.744 549.539)" fill="#363e3e"/>
        </g>
      </g>
      <g id="Сгруппировать_4987" data-name="Сгруппировать 4987" transform="translate(190.802 -486.485)" clip-path="url(#clip-path-391)">
        <g id="Сгруппировать_4986" data-name="Сгруппировать 4986" transform="translate(-238.986 -156.48)" clip-path="url(#clip-path-18)">
          <path id="Контур_4864" data-name="Контур 4864" d="M355.822-417.506H364.2v39.512h-8.378Z" transform="translate(-119.475 571.348)" fill="#47504f"/>
        </g>
      </g>
      <g id="Сгруппировать_4989" data-name="Сгруппировать 4989" transform="translate(187.686 -488.283)" clip-path="url(#clip-path-393)">
        <g id="Сгруппировать_4988" data-name="Сгруппировать 4988" transform="translate(-235.869 -154.682)" clip-path="url(#clip-path-18)">
          <path id="Контур_4867" data-name="Контур 4867" d="M349.917-420.914h11.495v8.861H349.917Z" transform="translate(-116.687 572.957)" fill="#6b7373"/>
        </g>
      </g>
      <g id="Сгруппировать_4991" data-name="Сгруппировать 4991" transform="translate(187.686 -486.501)" clip-path="url(#clip-path-395)">
        <g id="Сгруппировать_4990" data-name="Сгруппировать 4990" transform="translate(-235.869 -156.464)" clip-path="url(#clip-path-18)">
          <path id="Контур_4870" data-name="Контур 4870" d="M349.917-417.537h8.394v39.526h-8.394Z" transform="translate(-116.687 571.362)" fill="#363e3e"/>
        </g>
      </g>
      <g id="Сгруппировать_4993" data-name="Сгруппировать 4993" transform="translate(191.21 -459.818)" clip-path="url(#clip-path-397)">
        <g id="Сгруппировать_4992" data-name="Сгруппировать 4992" transform="translate(-239.393 -183.148)" clip-path="url(#clip-path-18)">
          <path id="Контур_4873" data-name="Контур 4873" d="M356.594-366.978h34.18v36.4h-34.18Z" transform="translate(-119.84 547.487)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_4995" data-name="Сгруппировать 4995" transform="translate(220.112 -448.453)" clip-path="url(#clip-path-399)">
        <g id="Сгруппировать_4994" data-name="Сгруппировать 4994" transform="translate(-268.295 -194.512)" clip-path="url(#clip-path-18)">
          <path id="Контур_4876" data-name="Контур 4876" d="M411.357-345.445h14.416v25.039H411.357Z" transform="translate(-145.701 537.318)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_4997" data-name="Сгруппировать 4997" transform="translate(191.21 -465.115)" clip-path="url(#clip-path-401)">
        <g id="Сгруппировать_4996" data-name="Сгруппировать 4996" transform="translate(-239.393 -177.85)" clip-path="url(#clip-path-18)">
          <path id="Контур_4879" data-name="Контур 4879" d="M356.594-377.015h43.318v27.256H356.594Z" transform="translate(-119.84 552.227)" fill="#d9ba8b"/>
        </g>
      </g>
      <g id="Сгруппировать_4999" data-name="Сгруппировать 4999" transform="translate(192.098 -458.361)" clip-path="url(#clip-path-403)">
        <g id="Сгруппировать_4998" data-name="Сгруппировать 4998" transform="translate(-240.281 -184.604)" clip-path="url(#clip-path-18)">
          <path id="Контур_4882" data-name="Контур 4882" d="M358.276-364.219h32.4v33.493h-32.4Z" transform="translate(-120.634 546.184)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_5001" data-name="Сгруппировать 5001" transform="translate(192.098 -458.361)" clip-path="url(#clip-path-405)">
        <g id="Сгруппировать_5000" data-name="Сгруппировать 5000" transform="translate(-240.281 -184.604)" clip-path="url(#clip-path-18)">
          <path id="Контур_4885" data-name="Контур 4885" d="M358.276-364.219h13.679v17.766H358.276Z" transform="translate(-120.634 546.184)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5003" data-name="Сгруппировать 5003" transform="translate(200.499 -453.517)" clip-path="url(#clip-path-407)">
        <g id="Сгруппировать_5002" data-name="Сгруппировать 5002" transform="translate(-248.682 -189.449)" clip-path="url(#clip-path-18)">
          <path id="Контур_4888" data-name="Контур 4888" d="M374.195-355.039h24v18.922h-24Z" transform="translate(-128.151 541.849)" fill="#c2b3a8"/>
        </g>
      </g>
      <g id="Сгруппировать_5005" data-name="Сгруппировать 5005" transform="translate(191.21 -473.25)" clip-path="url(#clip-path-409)">
        <g id="Сгруппировать_5004" data-name="Сгруппировать 5004" transform="translate(-239.393 -169.715)" clip-path="url(#clip-path-18)">
          <path id="Контур_4891" data-name="Контур 4891" d="M356.594-392.43h34.18v36.4h-34.18Z" transform="translate(-119.84 559.506)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_5007" data-name="Сгруппировать 5007" transform="translate(220.112 -461.886)" clip-path="url(#clip-path-411)">
        <g id="Сгруппировать_5006" data-name="Сгруппировать 5006" transform="translate(-268.295 -181.079)" clip-path="url(#clip-path-18)">
          <path id="Контур_4894" data-name="Контур 4894" d="M411.357-370.9h14.416v25.04H411.357Z" transform="translate(-145.701 549.338)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5009" data-name="Сгруппировать 5009" transform="translate(191.21 -478.548)" clip-path="url(#clip-path-413)">
        <g id="Сгруппировать_5008" data-name="Сгруппировать 5008" transform="translate(-239.393 -164.418)" clip-path="url(#clip-path-18)">
          <path id="Контур_4897" data-name="Контур 4897" d="M356.594-402.467h43.318v27.256H356.594Z" transform="translate(-119.84 564.246)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_5011" data-name="Сгруппировать 5011" transform="translate(192.098 -471.795)" clip-path="url(#clip-path-415)">
        <g id="Сгруппировать_5010" data-name="Сгруппировать 5010" transform="translate(-240.281 -171.17)" clip-path="url(#clip-path-18)">
          <path id="Контур_4900" data-name="Контур 4900" d="M358.276-389.672h32.4v33.493h-32.4Z" transform="translate(-120.634 558.204)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_5013" data-name="Сгруппировать 5013" transform="translate(192.098 -471.795)" clip-path="url(#clip-path-417)">
        <g id="Сгруппировать_5012" data-name="Сгруппировать 5012" transform="translate(-240.281 -171.17)" clip-path="url(#clip-path-18)">
          <path id="Контур_4903" data-name="Контур 4903" d="M358.276-389.672h13.679v17.767H358.276Z" transform="translate(-120.634 558.204)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5015" data-name="Сгруппировать 5015" transform="translate(200.499 -466.949)" clip-path="url(#clip-path-419)">
        <g id="Сгруппировать_5014" data-name="Сгруппировать 5014" transform="translate(-248.682 -176.016)" clip-path="url(#clip-path-18)">
          <path id="Контур_4906" data-name="Контур 4906" d="M374.195-380.491h24v18.922h-24Z" transform="translate(-128.151 553.868)" fill="#c2b3a8"/>
        </g>
      </g>
      <g id="Сгруппировать_5017" data-name="Сгруппировать 5017" transform="translate(194.085 -468.7)" clip-path="url(#clip-path-421)">
        <g id="Сгруппировать_5016" data-name="Сгруппировать 5016" transform="translate(-242.268 -174.265)" clip-path="url(#clip-path-18)">
          <path id="Контур_4909" data-name="Контур 4909" d="M362.042-383.809h6.139v14.525h-6.139Z" transform="translate(-122.412 555.435)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5019" data-name="Сгруппировать 5019" transform="translate(194.085 -467.981)" clip-path="url(#clip-path-423)">
        <g id="Сгруппировать_5018" data-name="Сгруппировать 5018" transform="translate(-242.268 -174.985)" clip-path="url(#clip-path-18)">
          <path id="Контур_4912" data-name="Контур 4912" d="M362.042-382.445h6.139v8.407h-6.139Z" transform="translate(-122.412 554.791)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5021" data-name="Сгруппировать 5021" transform="translate(194.946 -469.182)" clip-path="url(#clip-path-425)">
        <g id="Сгруппировать_5020" data-name="Сгруппировать 5020" transform="translate(-243.129 -173.783)" clip-path="url(#clip-path-18)">
          <path id="Контур_4915" data-name="Контур 4915" d="M363.673-384.721h11.914v15.007H363.673Z" transform="translate(-123.183 555.866)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5023" data-name="Сгруппировать 5023" transform="translate(194.085 -469.664)" clip-path="url(#clip-path-427)">
        <g id="Сгруппировать_5022" data-name="Сгруппировать 5022" transform="translate(-242.268 -173.301)" clip-path="url(#clip-path-18)">
          <path id="Контур_4918" data-name="Контур 4918" d="M362.042-385.635h7.819v6.739h-7.819Z" transform="translate(-122.412 556.297)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5025" data-name="Сгруппировать 5025" transform="translate(195.143 -468.088)" clip-path="url(#clip-path-429)">
        <g id="Сгруппировать_5024" data-name="Сгруппировать 5024" transform="translate(-243.326 -174.877)" clip-path="url(#clip-path-18)">
          <path id="Контур_4921" data-name="Контур 4921" d="M364.046-382.648h6.14v14.525h-6.14Z" transform="translate(-123.359 554.887)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5027" data-name="Сгруппировать 5027" transform="translate(195.143 -467.368)" clip-path="url(#clip-path-431)">
        <g id="Сгруппировать_5026" data-name="Сгруппировать 5026" transform="translate(-243.326 -175.597)" clip-path="url(#clip-path-18)">
          <path id="Контур_4924" data-name="Контур 4924" d="M364.046-381.285h6.14v8.407h-6.14Z" transform="translate(-123.359 554.243)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5029" data-name="Сгруппировать 5029" transform="translate(196.004 -468.57)" clip-path="url(#clip-path-433)">
        <g id="Сгруппировать_5028" data-name="Сгруппировать 5028" transform="translate(-244.187 -174.396)" clip-path="url(#clip-path-18)">
          <path id="Контур_4927" data-name="Контур 4927" d="M365.677-383.561h11.914v15.007H365.677Z" transform="translate(-124.129 555.318)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5031" data-name="Сгруппировать 5031" transform="translate(195.143 -469.052)" clip-path="url(#clip-path-435)">
        <g id="Сгруппировать_5030" data-name="Сгруппировать 5030" transform="translate(-243.326 -173.913)" clip-path="url(#clip-path-18)">
          <path id="Контур_4930" data-name="Контур 4930" d="M364.046-384.475h7.819v6.739h-7.819Z" transform="translate(-123.359 555.749)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5033" data-name="Сгруппировать 5033" transform="translate(196.201 -467.475)" clip-path="url(#clip-path-437)">
        <g id="Сгруппировать_5032" data-name="Сгруппировать 5032" transform="translate(-244.384 -175.49)" clip-path="url(#clip-path-18)">
          <path id="Контур_4933" data-name="Контур 4933" d="M366.05-381.488h6.14v14.525h-6.14Z" transform="translate(-124.305 554.339)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5035" data-name="Сгруппировать 5035" transform="translate(196.201 -466.756)" clip-path="url(#clip-path-439)">
        <g id="Сгруппировать_5034" data-name="Сгруппировать 5034" transform="translate(-244.384 -176.209)" clip-path="url(#clip-path-18)">
          <path id="Контур_4936" data-name="Контур 4936" d="M366.05-380.125h6.14v8.407h-6.14Z" transform="translate(-124.305 553.695)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5037" data-name="Сгруппировать 5037" transform="translate(197.062 -467.957)" clip-path="url(#clip-path-441)">
        <g id="Сгруппировать_5036" data-name="Сгруппировать 5036" transform="translate(-245.245 -175.008)" clip-path="url(#clip-path-18)">
          <path id="Контур_4939" data-name="Контур 4939" d="M367.682-382.4H379.6v15.006H367.682Z" transform="translate(-125.076 554.769)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5039" data-name="Сгруппировать 5039" transform="translate(196.201 -468.439)" clip-path="url(#clip-path-443)">
        <g id="Сгруппировать_5038" data-name="Сгруппировать 5038" transform="translate(-244.384 -174.526)" clip-path="url(#clip-path-18)">
          <path id="Контур_4942" data-name="Контур 4942" d="M366.05-383.314h7.819v6.738H366.05Z" transform="translate(-124.305 555.201)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5041" data-name="Сгруппировать 5041" transform="translate(197.259 -466.863)" clip-path="url(#clip-path-445)">
        <g id="Сгруппировать_5040" data-name="Сгруппировать 5040" transform="translate(-245.442 -176.102)" clip-path="url(#clip-path-18)">
          <path id="Контур_4945" data-name="Контур 4945" d="M368.055-380.328h6.14V-365.8h-6.14Z" transform="translate(-125.252 553.791)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5043" data-name="Сгруппировать 5043" transform="translate(197.259 -466.143)" clip-path="url(#clip-path-447)">
        <g id="Сгруппировать_5042" data-name="Сгруппировать 5042" transform="translate(-245.442 -176.822)" clip-path="url(#clip-path-18)">
          <path id="Контур_4948" data-name="Контур 4948" d="M368.055-378.964h6.14v8.407h-6.14Z" transform="translate(-125.252 553.147)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5045" data-name="Сгруппировать 5045" transform="translate(198.119 -467.345)" clip-path="url(#clip-path-449)">
        <g id="Сгруппировать_5044" data-name="Сгруппировать 5044" transform="translate(-246.303 -175.621)" clip-path="url(#clip-path-18)">
          <path id="Контур_4951" data-name="Контур 4951" d="M369.686-381.24H381.6v15.006H369.686Z" transform="translate(-126.022 554.222)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5047" data-name="Сгруппировать 5047" transform="translate(197.259 -467.827)" clip-path="url(#clip-path-451)">
        <g id="Сгруппировать_5046" data-name="Сгруппировать 5046" transform="translate(-245.442 -175.138)" clip-path="url(#clip-path-18)">
          <path id="Контур_4954" data-name="Контур 4954" d="M368.055-382.154h7.819v6.739h-7.819Z" transform="translate(-125.252 554.653)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5049" data-name="Сгруппировать 5049" transform="translate(198.316 -466.251)" clip-path="url(#clip-path-453)">
        <g id="Сгруппировать_5048" data-name="Сгруппировать 5048" transform="translate(-246.499 -176.714)" clip-path="url(#clip-path-18)">
          <path id="Контур_4957" data-name="Контур 4957" d="M370.059-379.168H376.2v14.525h-6.139Z" transform="translate(-126.198 553.243)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5051" data-name="Сгруппировать 5051" transform="translate(198.316 -465.532)" clip-path="url(#clip-path-455)">
        <g id="Сгруппировать_5050" data-name="Сгруппировать 5050" transform="translate(-246.499 -177.433)" clip-path="url(#clip-path-18)">
          <path id="Контур_4960" data-name="Контур 4960" d="M370.059-377.8H376.2v8.408h-6.139Z" transform="translate(-126.198 552.6)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5053" data-name="Сгруппировать 5053" transform="translate(199.177 -466.732)" clip-path="url(#clip-path-457)">
        <g id="Сгруппировать_5052" data-name="Сгруппировать 5052" transform="translate(-247.36 -176.233)" clip-path="url(#clip-path-18)">
          <path id="Контур_4963" data-name="Контур 4963" d="M371.689-380.079H383.6v15.006H371.689Z" transform="translate(-126.968 553.673)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5055" data-name="Сгруппировать 5055" transform="translate(198.316 -467.215)" clip-path="url(#clip-path-459)">
        <g id="Сгруппировать_5054" data-name="Сгруппировать 5054" transform="translate(-246.499 -175.75)" clip-path="url(#clip-path-18)">
          <path id="Контур_4966" data-name="Контур 4966" d="M370.059-380.994h7.819v6.739h-7.819Z" transform="translate(-126.198 554.106)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5057" data-name="Сгруппировать 5057" transform="translate(199.374 -465.638)" clip-path="url(#clip-path-461)">
        <g id="Сгруппировать_5056" data-name="Сгруппировать 5056" transform="translate(-247.557 -177.327)" clip-path="url(#clip-path-18)">
          <path id="Контур_4969" data-name="Контур 4969" d="M372.063-378.007h6.14v14.525h-6.14Z" transform="translate(-127.145 552.695)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5059" data-name="Сгруппировать 5059" transform="translate(199.374 -464.919)" clip-path="url(#clip-path-463)">
        <g id="Сгруппировать_5058" data-name="Сгруппировать 5058" transform="translate(-247.557 -178.046)" clip-path="url(#clip-path-18)">
          <path id="Контур_4972" data-name="Контур 4972" d="M372.063-376.645h6.14v8.408h-6.14Z" transform="translate(-127.145 552.052)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5061" data-name="Сгруппировать 5061" transform="translate(200.235 -466.12)" clip-path="url(#clip-path-465)">
        <g id="Сгруппировать_5060" data-name="Сгруппировать 5060" transform="translate(-248.418 -176.846)" clip-path="url(#clip-path-18)">
          <path id="Контур_4975" data-name="Контур 4975" d="M373.694-378.919h11.915v15.007H373.694Z" transform="translate(-127.915 553.126)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5063" data-name="Сгруппировать 5063" transform="translate(199.374 -466.602)" clip-path="url(#clip-path-467)">
        <g id="Сгруппировать_5062" data-name="Сгруппировать 5062" transform="translate(-247.557 -176.363)" clip-path="url(#clip-path-18)">
          <path id="Контур_4978" data-name="Контур 4978" d="M372.063-379.833h7.819v6.739h-7.819Z" transform="translate(-127.145 553.557)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5065" data-name="Сгруппировать 5065" transform="translate(200.432 -465.027)" clip-path="url(#clip-path-469)">
        <g id="Сгруппировать_5064" data-name="Сгруппировать 5064" transform="translate(-248.615 -177.939)" clip-path="url(#clip-path-18)">
          <path id="Контур_4981" data-name="Контур 4981" d="M374.067-376.848h6.14v14.526h-6.14Z" transform="translate(-128.091 552.148)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5067" data-name="Сгруппировать 5067" transform="translate(200.432 -464.306)" clip-path="url(#clip-path-471)">
        <g id="Сгруппировать_5066" data-name="Сгруппировать 5066" transform="translate(-248.615 -178.659)" clip-path="url(#clip-path-18)">
          <path id="Контур_4984" data-name="Контур 4984" d="M374.067-375.483h6.14v8.407h-6.14Z" transform="translate(-128.091 551.503)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5069" data-name="Сгруппировать 5069" transform="translate(201.293 -465.507)" clip-path="url(#clip-path-473)">
        <g id="Сгруппировать_5068" data-name="Сгруппировать 5068" transform="translate(-249.476 -177.458)" clip-path="url(#clip-path-18)">
          <path id="Контур_4987" data-name="Контур 4987" d="M375.7-377.758h11.914v15.007H375.7Z" transform="translate(-128.862 552.577)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5071" data-name="Сгруппировать 5071" transform="translate(200.432 -465.99)" clip-path="url(#clip-path-475)">
        <g id="Сгруппировать_5070" data-name="Сгруппировать 5070" transform="translate(-248.615 -176.975)" clip-path="url(#clip-path-18)">
          <path id="Контур_4990" data-name="Контур 4990" d="M374.067-378.673h7.82v6.739h-7.82Z" transform="translate(-128.091 553.01)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5073" data-name="Сгруппировать 5073" transform="translate(201.49 -464.414)" clip-path="url(#clip-path-477)">
        <g id="Сгруппировать_5072" data-name="Сгруппировать 5072" transform="translate(-249.673 -178.551)" clip-path="url(#clip-path-18)">
          <path id="Контур_4993" data-name="Контур 4993" d="M376.072-375.687h6.139v14.526h-6.139Z" transform="translate(-129.038 551.599)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5075" data-name="Сгруппировать 5075" transform="translate(201.49 -463.694)" clip-path="url(#clip-path-479)">
        <g id="Сгруппировать_5074" data-name="Сгруппировать 5074" transform="translate(-249.673 -179.272)" clip-path="url(#clip-path-18)">
          <path id="Контур_4996" data-name="Контур 4996" d="M376.072-374.322h6.139v8.407h-6.139Z" transform="translate(-129.038 550.955)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5077" data-name="Сгруппировать 5077" transform="translate(202.351 -464.895)" clip-path="url(#clip-path-481)">
        <g id="Сгруппировать_5076" data-name="Сгруппировать 5076" transform="translate(-250.534 -178.07)" clip-path="url(#clip-path-18)">
          <path id="Контур_4999" data-name="Контур 4999" d="M377.7-376.6h11.914v15.007H377.7Z" transform="translate(-129.808 552.03)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5079" data-name="Сгруппировать 5079" transform="translate(201.49 -465.377)" clip-path="url(#clip-path-483)">
        <g id="Сгруппировать_5078" data-name="Сгруппировать 5078" transform="translate(-249.673 -177.588)" clip-path="url(#clip-path-18)">
          <path id="Контур_5002" data-name="Контур 5002" d="M376.072-377.512h7.819v6.739h-7.819Z" transform="translate(-129.038 552.461)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5081" data-name="Сгруппировать 5081" transform="translate(202.547 -463.801)" clip-path="url(#clip-path-485)">
        <g id="Сгруппировать_5080" data-name="Сгруппировать 5080" transform="translate(-250.731 -179.165)" clip-path="url(#clip-path-18)">
          <path id="Контур_5005" data-name="Контур 5005" d="M378.076-374.525h6.14V-360h-6.14Z" transform="translate(-129.984 551.051)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5083" data-name="Сгруппировать 5083" transform="translate(202.547 -463.081)" clip-path="url(#clip-path-487)">
        <g id="Сгруппировать_5082" data-name="Сгруппировать 5082" transform="translate(-250.731 -179.884)" clip-path="url(#clip-path-18)">
          <path id="Контур_5008" data-name="Контур 5008" d="M378.076-373.162h6.14v8.407h-6.14Z" transform="translate(-129.984 550.407)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5085" data-name="Сгруппировать 5085" transform="translate(203.408 -464.282)" clip-path="url(#clip-path-489)">
        <g id="Сгруппировать_5084" data-name="Сгруппировать 5084" transform="translate(-251.591 -178.683)" clip-path="url(#clip-path-18)">
          <path id="Контур_5011" data-name="Контур 5011" d="M379.707-375.437h11.915v15.006H379.707Z" transform="translate(-130.754 551.481)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5087" data-name="Сгруппировать 5087" transform="translate(202.547 -464.765)" clip-path="url(#clip-path-491)">
        <g id="Сгруппировать_5086" data-name="Сгруппировать 5086" transform="translate(-250.731 -178.2)" clip-path="url(#clip-path-18)">
          <path id="Контур_5014" data-name="Контур 5014" d="M378.076-376.352h7.82v6.739h-7.82Z" transform="translate(-129.984 551.914)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5089" data-name="Сгруппировать 5089" transform="translate(203.605 -463.188)" clip-path="url(#clip-path-493)">
        <g id="Сгруппировать_5088" data-name="Сгруппировать 5088" transform="translate(-251.788 -179.777)" clip-path="url(#clip-path-18)">
          <path id="Контур_5017" data-name="Контур 5017" d="M380.08-373.365h6.14v14.525h-6.14Z" transform="translate(-130.931 550.503)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5091" data-name="Сгруппировать 5091" transform="translate(203.605 -462.469)" clip-path="url(#clip-path-495)">
        <g id="Сгруппировать_5090" data-name="Сгруппировать 5090" transform="translate(-251.788 -180.496)" clip-path="url(#clip-path-18)">
          <path id="Контур_5020" data-name="Контур 5020" d="M380.08-372h6.14v8.407h-6.14Z" transform="translate(-130.931 549.859)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5093" data-name="Сгруппировать 5093" transform="translate(204.466 -463.67)" clip-path="url(#clip-path-497)">
        <g id="Сгруппировать_5092" data-name="Сгруппировать 5092" transform="translate(-252.65 -179.295)" clip-path="url(#clip-path-18)">
          <path id="Контур_5023" data-name="Контур 5023" d="M381.712-374.277h11.914v15.006H381.712Z" transform="translate(-131.701 550.934)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5095" data-name="Сгруппировать 5095" transform="translate(203.605 -464.152)" clip-path="url(#clip-path-499)">
        <g id="Сгруппировать_5094" data-name="Сгруппировать 5094" transform="translate(-251.788 -178.813)" clip-path="url(#clip-path-18)">
          <path id="Контур_5026" data-name="Контур 5026" d="M380.08-375.191H387.9v6.738H380.08Z" transform="translate(-130.931 551.365)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5097" data-name="Сгруппировать 5097" transform="translate(204.663 -462.576)" clip-path="url(#clip-path-501)">
        <g id="Сгруппировать_5096" data-name="Сгруппировать 5096" transform="translate(-252.846 -180.389)" clip-path="url(#clip-path-18)">
          <path id="Контур_5029" data-name="Контур 5029" d="M382.085-372.205h6.139v14.525h-6.139Z" transform="translate(-131.877 549.955)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5099" data-name="Сгруппировать 5099" transform="translate(204.663 -461.857)" clip-path="url(#clip-path-503)">
        <g id="Сгруппировать_5098" data-name="Сгруппировать 5098" transform="translate(-252.846 -181.108)" clip-path="url(#clip-path-18)">
          <path id="Контур_5032" data-name="Контур 5032" d="M382.085-370.842h6.139v8.407h-6.139Z" transform="translate(-131.877 549.311)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5101" data-name="Сгруппировать 5101" transform="translate(205.524 -463.057)" clip-path="url(#clip-path-505)">
        <g id="Сгруппировать_5100" data-name="Сгруппировать 5100" transform="translate(-253.707 -179.908)" clip-path="url(#clip-path-18)">
          <path id="Контур_5035" data-name="Контур 5035" d="M383.716-373.116H395.63v15.006H383.716Z" transform="translate(-132.648 550.385)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5103" data-name="Сгруппировать 5103" transform="translate(204.663 -463.54)" clip-path="url(#clip-path-507)">
        <g id="Сгруппировать_5102" data-name="Сгруппировать 5102" transform="translate(-252.846 -179.425)" clip-path="url(#clip-path-18)">
          <path id="Контур_5038" data-name="Контур 5038" d="M382.085-374.031H389.9v6.738h-7.819Z" transform="translate(-131.877 550.817)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5105" data-name="Сгруппировать 5105" transform="translate(205.721 -461.964)" clip-path="url(#clip-path-509)">
        <g id="Сгруппировать_5104" data-name="Сгруппировать 5104" transform="translate(-253.905 -181.001)" clip-path="url(#clip-path-18)">
          <path id="Контур_5041" data-name="Контур 5041" d="M384.09-371.045h6.14v14.526h-6.14Z" transform="translate(-132.824 549.407)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5107" data-name="Сгруппировать 5107" transform="translate(205.721 -461.245)" clip-path="url(#clip-path-511)">
        <g id="Сгруппировать_5106" data-name="Сгруппировать 5106" transform="translate(-253.905 -181.721)" clip-path="url(#clip-path-18)">
          <path id="Контур_5044" data-name="Контур 5044" d="M384.09-369.682h6.14v8.408h-6.14Z" transform="translate(-132.824 548.764)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5109" data-name="Сгруппировать 5109" transform="translate(206.582 -462.445)" clip-path="url(#clip-path-513)">
        <g id="Сгруппировать_5108" data-name="Сгруппировать 5108" transform="translate(-254.765 -180.52)" clip-path="url(#clip-path-18)">
          <path id="Контур_5047" data-name="Контур 5047" d="M385.721-371.957h11.914v15.007H385.721Z" transform="translate(-133.594 549.838)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5111" data-name="Сгруппировать 5111" transform="translate(205.721 -462.928)" clip-path="url(#clip-path-515)">
        <g id="Сгруппировать_5110" data-name="Сгруппировать 5110" transform="translate(-253.905 -180.037)" clip-path="url(#clip-path-18)">
          <path id="Контур_5050" data-name="Контур 5050" d="M384.09-372.871h7.819v6.738H384.09Z" transform="translate(-132.824 550.27)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5113" data-name="Сгруппировать 5113" transform="translate(206.779 -461.352)" clip-path="url(#clip-path-517)">
        <g id="Сгруппировать_5112" data-name="Сгруппировать 5112" transform="translate(-254.962 -181.613)" clip-path="url(#clip-path-18)">
          <path id="Контур_5053" data-name="Контур 5053" d="M386.094-369.885h6.14v14.526h-6.14Z" transform="translate(-133.771 548.859)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5115" data-name="Сгруппировать 5115" transform="translate(206.779 -460.632)" clip-path="url(#clip-path-519)">
        <g id="Сгруппировать_5114" data-name="Сгруппировать 5114" transform="translate(-254.962 -182.333)" clip-path="url(#clip-path-18)">
          <path id="Контур_5056" data-name="Контур 5056" d="M386.094-368.521h6.14v8.407h-6.14Z" transform="translate(-133.771 548.215)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5117" data-name="Сгруппировать 5117" transform="translate(207.64 -461.833)" clip-path="url(#clip-path-521)">
        <g id="Сгруппировать_5116" data-name="Сгруппировать 5116" transform="translate(-255.823 -181.133)" clip-path="url(#clip-path-18)">
          <path id="Контур_5059" data-name="Контур 5059" d="M387.725-370.8h11.914v15.007H387.725Z" transform="translate(-134.541 549.29)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5119" data-name="Сгруппировать 5119" transform="translate(206.779 -462.315)" clip-path="url(#clip-path-523)">
        <g id="Сгруппировать_5118" data-name="Сгруппировать 5118" transform="translate(-254.962 -180.65)" clip-path="url(#clip-path-18)">
          <path id="Контур_5062" data-name="Контур 5062" d="M386.094-371.711h7.819v6.739h-7.819Z" transform="translate(-133.771 549.722)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5121" data-name="Сгруппировать 5121" transform="translate(207.837 -460.739)" clip-path="url(#clip-path-525)">
        <g id="Сгруппировать_5120" data-name="Сгруппировать 5120" transform="translate(-256.02 -182.226)" clip-path="url(#clip-path-18)">
          <path id="Контур_5065" data-name="Контур 5065" d="M388.1-368.724h6.139V-354.2H388.1Z" transform="translate(-134.717 548.311)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5123" data-name="Сгруппировать 5123" transform="translate(207.837 -460.019)" clip-path="url(#clip-path-527)">
        <g id="Сгруппировать_5122" data-name="Сгруппировать 5122" transform="translate(-256.02 -182.946)" clip-path="url(#clip-path-18)">
          <path id="Контур_5068" data-name="Контур 5068" d="M388.1-367.36h6.139v8.407H388.1Z" transform="translate(-134.717 547.667)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5125" data-name="Сгруппировать 5125" transform="translate(208.698 -461.22)" clip-path="url(#clip-path-529)">
        <g id="Сгруппировать_5124" data-name="Сгруппировать 5124" transform="translate(-256.881 -181.745)" clip-path="url(#clip-path-18)">
          <path id="Контур_5071" data-name="Контур 5071" d="M389.729-369.635h11.914v15.007H389.729Z" transform="translate(-135.487 548.741)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5127" data-name="Сгруппировать 5127" transform="translate(207.837 -461.703)" clip-path="url(#clip-path-531)">
        <g id="Сгруппировать_5126" data-name="Сгруппировать 5126" transform="translate(-256.02 -181.262)" clip-path="url(#clip-path-18)">
          <path id="Контур_5074" data-name="Контур 5074" d="M388.1-370.55h7.819v6.739H388.1Z" transform="translate(-134.717 549.174)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5129" data-name="Сгруппировать 5129" transform="translate(208.894 -460.126)" clip-path="url(#clip-path-533)">
        <g id="Сгруппировать_5128" data-name="Сгруппировать 5128" transform="translate(-257.078 -182.839)" clip-path="url(#clip-path-18)">
          <path id="Контур_5077" data-name="Контур 5077" d="M390.1-367.563h6.14v14.525H390.1Z" transform="translate(-135.663 547.763)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5131" data-name="Сгруппировать 5131" transform="translate(208.894 -459.406)" clip-path="url(#clip-path-535)">
        <g id="Сгруппировать_5130" data-name="Сгруппировать 5130" transform="translate(-257.078 -183.559)" clip-path="url(#clip-path-18)">
          <path id="Контур_5080" data-name="Контур 5080" d="M390.1-366.2h6.14v8.407H390.1Z" transform="translate(-135.663 547.119)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5133" data-name="Сгруппировать 5133" transform="translate(209.756 -460.608)" clip-path="url(#clip-path-537)">
        <g id="Сгруппировать_5132" data-name="Сгруппировать 5132" transform="translate(-257.939 -182.358)" clip-path="url(#clip-path-18)">
          <path id="Контур_5083" data-name="Контур 5083" d="M391.734-368.475h11.914v15.007H391.734Z" transform="translate(-136.434 548.194)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5135" data-name="Сгруппировать 5135" transform="translate(208.894 -461.091)" clip-path="url(#clip-path-539)">
        <g id="Сгруппировать_5134" data-name="Сгруппировать 5134" transform="translate(-257.078 -181.875)" clip-path="url(#clip-path-18)">
          <path id="Контур_5086" data-name="Контур 5086" d="M390.1-369.39h7.819v6.739H390.1Z" transform="translate(-135.663 548.626)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5137" data-name="Сгруппировать 5137" transform="translate(193.586 -455.554)" clip-path="url(#clip-path-541)">
        <g id="Сгруппировать_5136" data-name="Сгруппировать 5136" transform="translate(-241.769 -187.411)" clip-path="url(#clip-path-18)">
          <path id="Контур_5089" data-name="Контур 5089" d="M361.1-358.9h6.14v14.525H361.1Z" transform="translate(-121.966 543.672)" fill="#a5cbd4"/>
        </g>
      </g>
      <g id="Сгруппировать_5139" data-name="Сгруппировать 5139" transform="translate(193.586 -454.835)" clip-path="url(#clip-path-543)">
        <g id="Сгруппировать_5138" data-name="Сгруппировать 5138" transform="translate(-241.769 -188.13)" clip-path="url(#clip-path-18)">
          <path id="Контур_5092" data-name="Контур 5092" d="M361.1-357.537h6.14v8.408H361.1Z" transform="translate(-121.966 543.028)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5141" data-name="Сгруппировать 5141" transform="translate(194.447 -456.036)" clip-path="url(#clip-path-545)">
        <g id="Сгруппировать_5140" data-name="Сгруппировать 5140" transform="translate(-242.63 -186.93)" clip-path="url(#clip-path-18)">
          <path id="Контур_5095" data-name="Контур 5095" d="M362.727-359.812h11.914v15.006H362.727Z" transform="translate(-122.736 544.103)" fill="#90b9c0"/>
        </g>
      </g>
      <g id="Сгруппировать_5143" data-name="Сгруппировать 5143" transform="translate(193.586 -456.518)" clip-path="url(#clip-path-547)">
        <g id="Сгруппировать_5142" data-name="Сгруппировать 5142" transform="translate(-241.769 -186.447)" clip-path="url(#clip-path-18)">
          <path id="Контур_5098" data-name="Контур 5098" d="M361.1-360.726h7.819v6.739H361.1Z" transform="translate(-121.966 544.534)" fill="#bfe0e9"/>
        </g>
      </g>
      <g id="Сгруппировать_5145" data-name="Сгруппировать 5145" transform="translate(194.644 -454.942)" clip-path="url(#clip-path-549)">
        <g id="Сгруппировать_5144" data-name="Сгруппировать 5144" transform="translate(-242.827 -188.023)" clip-path="url(#clip-path-18)">
          <path id="Контур_5101" data-name="Контур 5101" d="M363.1-357.74h6.139v14.525H363.1Z" transform="translate(-122.912 543.124)" fill="#b27b50"/>
        </g>
      </g>
      <g id="Сгруппировать_5147" data-name="Сгруппировать 5147" transform="translate(194.644 -454.222)" clip-path="url(#clip-path-551)">
        <g id="Сгруппировать_5146" data-name="Сгруппировать 5146" transform="translate(-242.827 -188.743)" clip-path="url(#clip-path-18)">
          <path id="Контур_5104" data-name="Контур 5104" d="M363.1-356.376h6.139v8.407H363.1Z" transform="translate(-122.912 542.48)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5149" data-name="Сгруппировать 5149" transform="translate(195.504 -455.423)" clip-path="url(#clip-path-553)">
        <g id="Сгруппировать_5148" data-name="Сгруппировать 5148" transform="translate(-243.687 -187.542)" clip-path="url(#clip-path-18)">
          <path id="Контур_5107" data-name="Контур 5107" d="M364.73-358.651h11.914v15.006H364.73Z" transform="translate(-123.682 543.555)" fill="#9c6b40"/>
        </g>
      </g>
      <g id="Сгруппировать_5151" data-name="Сгруппировать 5151" transform="translate(194.644 -455.905)" clip-path="url(#clip-path-555)">
        <g id="Сгруппировать_5150" data-name="Сгруппировать 5150" transform="translate(-242.827 -187.06)" clip-path="url(#clip-path-18)">
          <path id="Контур_5110" data-name="Контур 5110" d="M363.1-359.565h7.819v6.739H363.1Z" transform="translate(-122.912 543.986)" fill="#cb9562"/>
        </g>
      </g>
      <g id="Сгруппировать_5153" data-name="Сгруппировать 5153" transform="translate(195.701 -454.329)" clip-path="url(#clip-path-557)">
        <g id="Сгруппировать_5152" data-name="Сгруппировать 5152" transform="translate(-243.884 -188.636)" clip-path="url(#clip-path-18)">
          <path id="Контур_5113" data-name="Контур 5113" d="M365.1-356.579h6.14v14.525H365.1Z" transform="translate(-123.858 542.576)" fill="#a5cbd4"/>
        </g>
      </g>
      <g id="Сгруппировать_5155" data-name="Сгруппировать 5155" transform="translate(195.701 -453.609)" clip-path="url(#clip-path-559)">
        <g id="Сгруппировать_5154" data-name="Сгруппировать 5154" transform="translate(-243.884 -189.356)" clip-path="url(#clip-path-18)">
          <path id="Контур_5116" data-name="Контур 5116" d="M365.1-355.215h6.14v8.407H365.1Z" transform="translate(-123.858 541.932)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5157" data-name="Сгруппировать 5157" transform="translate(196.563 -454.81)" clip-path="url(#clip-path-561)">
        <g id="Сгруппировать_5156" data-name="Сгруппировать 5156" transform="translate(-244.746 -188.155)" clip-path="url(#clip-path-18)">
          <path id="Контур_5119" data-name="Контур 5119" d="M366.736-357.49H378.65v15.006H366.736Z" transform="translate(-124.629 543.006)" fill="#90b9c0"/>
        </g>
      </g>
      <g id="Сгруппировать_5159" data-name="Сгруппировать 5159" transform="translate(195.701 -455.293)" clip-path="url(#clip-path-563)">
        <g id="Сгруппировать_5158" data-name="Сгруппировать 5158" transform="translate(-243.884 -187.672)" clip-path="url(#clip-path-18)">
          <path id="Контур_5122" data-name="Контур 5122" d="M365.1-358.4h7.819v6.739H365.1Z" transform="translate(-123.858 543.438)" fill="#bfe0e9"/>
        </g>
      </g>
      <g id="Сгруппировать_5161" data-name="Сгруппировать 5161" transform="translate(196.759 -453.717)" clip-path="url(#clip-path-565)">
        <g id="Сгруппировать_5160" data-name="Сгруппировать 5160" transform="translate(-244.942 -189.249)" clip-path="url(#clip-path-18)">
          <path id="Контур_5125" data-name="Контур 5125" d="M367.108-355.418h6.14v14.525h-6.14Z" transform="translate(-124.805 542.028)" fill="#a5cbd4"/>
        </g>
      </g>
      <g id="Сгруппировать_5163" data-name="Сгруппировать 5163" transform="translate(196.759 -452.998)" clip-path="url(#clip-path-567)">
        <g id="Сгруппировать_5162" data-name="Сгруппировать 5162" transform="translate(-244.942 -189.967)" clip-path="url(#clip-path-18)">
          <path id="Контур_5128" data-name="Контур 5128" d="M367.108-354.056h6.14v8.408h-6.14Z" transform="translate(-124.805 541.385)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5165" data-name="Сгруппировать 5165" transform="translate(197.62 -454.198)" clip-path="url(#clip-path-569)">
        <g id="Сгруппировать_5164" data-name="Сгруппировать 5164" transform="translate(-245.803 -188.767)" clip-path="url(#clip-path-18)">
          <path id="Контур_5131" data-name="Контур 5131" d="M368.74-356.33h11.914v15.007H368.74Z" transform="translate(-125.575 542.458)" fill="#90b9c0"/>
        </g>
      </g>
      <g id="Сгруппировать_5167" data-name="Сгруппировать 5167" transform="translate(196.759 -454.68)" clip-path="url(#clip-path-571)">
        <g id="Сгруппировать_5166" data-name="Сгруппировать 5166" transform="translate(-244.942 -188.285)" clip-path="url(#clip-path-18)">
          <path id="Контур_5134" data-name="Контур 5134" d="M367.108-357.244h7.82v6.738h-7.82Z" transform="translate(-124.805 542.89)" fill="#bfe0e9"/>
        </g>
      </g>
      <g id="Сгруппировать_5169" data-name="Сгруппировать 5169" transform="translate(197.817 -453.105)" clip-path="url(#clip-path-573)">
        <g id="Сгруппировать_5168" data-name="Сгруппировать 5168" transform="translate(-246 -189.86)" clip-path="url(#clip-path-18)">
          <path id="Контур_5137" data-name="Контур 5137" d="M369.113-354.259h6.14v14.526h-6.14Z" transform="translate(-125.752 541.48)" fill="#b27b50"/>
        </g>
      </g>
      <g id="Сгруппировать_5171" data-name="Сгруппировать 5171" transform="translate(197.817 -452.385)" clip-path="url(#clip-path-575)">
        <g id="Сгруппировать_5170" data-name="Сгруппировать 5170" transform="translate(-246 -190.58)" clip-path="url(#clip-path-18)">
          <path id="Контур_5140" data-name="Контур 5140" d="M369.113-352.895h6.14v8.407h-6.14Z" transform="translate(-125.752 540.836)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5173" data-name="Сгруппировать 5173" transform="translate(198.678 -453.586)" clip-path="url(#clip-path-577)">
        <g id="Сгруппировать_5172" data-name="Сгруппировать 5172" transform="translate(-246.861 -189.38)" clip-path="url(#clip-path-18)">
          <path id="Контур_5143" data-name="Контур 5143" d="M370.744-355.17h11.914v15.007H370.744Z" transform="translate(-126.522 541.911)" fill="#9c6b40"/>
        </g>
      </g>
      <g id="Сгруппировать_5175" data-name="Сгруппировать 5175" transform="translate(197.817 -454.068)" clip-path="url(#clip-path-579)">
        <g id="Сгруппировать_5174" data-name="Сгруппировать 5174" transform="translate(-246 -188.897)" clip-path="url(#clip-path-18)">
          <path id="Контур_5146" data-name="Контур 5146" d="M369.113-356.084h7.819v6.739h-7.819Z" transform="translate(-125.752 542.342)" fill="#cb9562"/>
        </g>
      </g>
      <g id="Сгруппировать_5177" data-name="Сгруппировать 5177" transform="translate(198.875 -452.492)" clip-path="url(#clip-path-581)">
        <g id="Сгруппировать_5176" data-name="Сгруппировать 5176" transform="translate(-247.058 -190.473)" clip-path="url(#clip-path-18)">
          <path id="Контур_5149" data-name="Контур 5149" d="M371.117-353.1h6.14v14.525h-6.14Z" transform="translate(-126.698 540.932)" fill="#a5cbd4"/>
        </g>
      </g>
      <g id="Сгруппировать_5179" data-name="Сгруппировать 5179" transform="translate(198.875 -451.772)" clip-path="url(#clip-path-583)">
        <g id="Сгруппировать_5178" data-name="Сгруппировать 5178" transform="translate(-247.058 -191.193)" clip-path="url(#clip-path-18)">
          <path id="Контур_5152" data-name="Контур 5152" d="M371.117-351.734h6.14v8.407h-6.14Z" transform="translate(-126.698 540.288)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5181" data-name="Сгруппировать 5181" transform="translate(199.736 -452.973)" clip-path="url(#clip-path-585)">
        <g id="Сгруппировать_5180" data-name="Сгруппировать 5180" transform="translate(-247.919 -189.992)" clip-path="url(#clip-path-18)">
          <path id="Контур_5155" data-name="Контур 5155" d="M372.748-354.01h11.914V-339H372.748Z" transform="translate(-127.468 541.363)" fill="#90b9c0"/>
        </g>
      </g>
      <g id="Сгруппировать_5183" data-name="Сгруппировать 5183" transform="translate(198.875 -453.456)" clip-path="url(#clip-path-587)">
        <g id="Сгруппировать_5182" data-name="Сгруппировать 5182" transform="translate(-247.058 -189.509)" clip-path="url(#clip-path-18)">
          <path id="Контур_5158" data-name="Контур 5158" d="M371.117-354.924h7.82v6.739h-7.82Z" transform="translate(-126.698 541.795)" fill="#bfe0e9"/>
        </g>
      </g>
      <g id="Сгруппировать_5185" data-name="Сгруппировать 5185" transform="translate(199.932 -451.879)" clip-path="url(#clip-path-589)">
        <g id="Сгруппировать_5184" data-name="Сгруппировать 5184" transform="translate(-248.116 -191.086)" clip-path="url(#clip-path-18)">
          <path id="Контур_5161" data-name="Контур 5161" d="M373.121-351.937h6.14v14.525h-6.14Z" transform="translate(-127.644 540.384)" fill="#ae91af"/>
        </g>
      </g>
      <g id="Сгруппировать_5187" data-name="Сгруппировать 5187" transform="translate(199.933 -451.16)" clip-path="url(#clip-path-591)">
        <g id="Сгруппировать_5186" data-name="Сгруппировать 5186" transform="translate(-248.116 -191.805)" clip-path="url(#clip-path-18)">
          <path id="Контур_5164" data-name="Контур 5164" d="M373.122-350.574h6.14v8.407h-6.14Z" transform="translate(-127.645 539.74)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5189" data-name="Сгруппировать 5189" transform="translate(200.794 -452.361)" clip-path="url(#clip-path-593)">
        <g id="Сгруппировать_5188" data-name="Сгруппировать 5188" transform="translate(-248.977 -190.604)" clip-path="url(#clip-path-18)">
          <path id="Контур_5167" data-name="Контур 5167" d="M374.753-352.85h11.914v15.007H374.753Z" transform="translate(-128.415 540.815)" fill="#8c718e"/>
        </g>
      </g>
      <g id="Сгруппировать_5191" data-name="Сгруппировать 5191" transform="translate(199.933 -452.844)" clip-path="url(#clip-path-595)">
        <g id="Сгруппировать_5190" data-name="Сгруппировать 5190" transform="translate(-248.116 -190.122)" clip-path="url(#clip-path-18)">
          <path id="Контур_5170" data-name="Контур 5170" d="M373.122-353.764h7.819v6.739h-7.819Z" transform="translate(-127.645 541.247)" fill="#c9b2c9"/>
        </g>
      </g>
      <g id="Сгруппировать_5193" data-name="Сгруппировать 5193" transform="translate(203.91 -449.588)" clip-path="url(#clip-path-597)">
        <g id="Сгруппировать_5192" data-name="Сгруппировать 5192" transform="translate(-252.093 -193.377)" clip-path="url(#clip-path-18)">
          <path id="Контур_5173" data-name="Контур 5173" d="M380.657-347.6h6.14v14.526h-6.14Z" transform="translate(-131.203 538.334)" fill="#a5cbd4"/>
        </g>
      </g>
      <g id="Сгруппировать_5195" data-name="Сгруппировать 5195" transform="translate(203.91 -448.868)" clip-path="url(#clip-path-599)">
        <g id="Сгруппировать_5194" data-name="Сгруппировать 5194" transform="translate(-252.093 -194.097)" clip-path="url(#clip-path-18)">
          <path id="Контур_5176" data-name="Контур 5176" d="M380.657-346.232h6.14v8.407h-6.14Z" transform="translate(-131.203 537.69)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5197" data-name="Сгруппировать 5197" transform="translate(204.77 -450.069)" clip-path="url(#clip-path-601)">
        <g id="Сгруппировать_5196" data-name="Сгруппировать 5196" transform="translate(-252.954 -192.896)" clip-path="url(#clip-path-18)">
          <path id="Контур_5179" data-name="Контур 5179" d="M382.288-348.507H394.2V-333.5H382.288Z" transform="translate(-131.973 538.764)" fill="#90b9c0"/>
        </g>
      </g>
      <g id="Сгруппировать_5199" data-name="Сгруппировать 5199" transform="translate(203.91 -450.552)" clip-path="url(#clip-path-603)">
        <g id="Сгруппировать_5198" data-name="Сгруппировать 5198" transform="translate(-252.093 -192.413)" clip-path="url(#clip-path-18)">
          <path id="Контур_5182" data-name="Контур 5182" d="M380.657-349.422h7.819v6.739h-7.819Z" transform="translate(-131.203 539.196)" fill="#bfe0e9"/>
        </g>
      </g>
      <g id="Сгруппировать_5201" data-name="Сгруппировать 5201" transform="translate(204.967 -448.976)" clip-path="url(#clip-path-605)">
        <g id="Сгруппировать_5200" data-name="Сгруппировать 5200" transform="translate(-253.15 -193.989)" clip-path="url(#clip-path-18)">
          <path id="Контур_5185" data-name="Контур 5185" d="M382.661-346.436h6.14v14.525h-6.14Z" transform="translate(-132.149 537.786)" fill="#b27b50"/>
        </g>
      </g>
      <g id="Сгруппировать_5203" data-name="Сгруппировать 5203" transform="translate(204.967 -448.256)" clip-path="url(#clip-path-607)">
        <g id="Сгруппировать_5202" data-name="Сгруппировать 5202" transform="translate(-253.15 -194.709)" clip-path="url(#clip-path-18)">
          <path id="Контур_5188" data-name="Контур 5188" d="M382.661-345.072h6.14v8.407h-6.14Z" transform="translate(-132.149 537.142)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5205" data-name="Сгруппировать 5205" transform="translate(205.829 -449.457)" clip-path="url(#clip-path-609)">
        <g id="Сгруппировать_5204" data-name="Сгруппировать 5204" transform="translate(-254.012 -193.508)" clip-path="url(#clip-path-18)">
          <path id="Контур_5191" data-name="Контур 5191" d="M384.293-347.348h11.914v15.007H384.293Z" transform="translate(-132.92 538.217)" fill="#9c6b40"/>
        </g>
      </g>
      <g id="Сгруппировать_5207" data-name="Сгруппировать 5207" transform="translate(204.967 -449.939)" clip-path="url(#clip-path-611)">
        <g id="Сгруппировать_5206" data-name="Сгруппировать 5206" transform="translate(-253.15 -193.026)" clip-path="url(#clip-path-18)">
          <path id="Контур_5194" data-name="Контур 5194" d="M382.661-348.261h7.82v6.739h-7.82Z" transform="translate(-132.149 538.648)" fill="#cb9562"/>
        </g>
      </g>
      <g id="Сгруппировать_5209" data-name="Сгруппировать 5209" transform="translate(206.025 -448.363)" clip-path="url(#clip-path-613)">
        <g id="Сгруппировать_5208" data-name="Сгруппировать 5208" transform="translate(-254.209 -194.602)" clip-path="url(#clip-path-18)">
          <path id="Контур_5197" data-name="Контур 5197" d="M384.666-345.275h6.14v14.525h-6.14Z" transform="translate(-133.096 537.238)" fill="#a5cbd4"/>
        </g>
      </g>
      <g id="Сгруппировать_5211" data-name="Сгруппировать 5211" transform="translate(206.025 -447.643)" clip-path="url(#clip-path-615)">
        <g id="Сгруппировать_5210" data-name="Сгруппировать 5210" transform="translate(-254.209 -195.322)" clip-path="url(#clip-path-18)">
          <path id="Контур_5200" data-name="Контур 5200" d="M384.666-343.911h6.14v8.407h-6.14Z" transform="translate(-133.096 536.594)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5213" data-name="Сгруппировать 5213" transform="translate(206.886 -448.845)" clip-path="url(#clip-path-617)">
        <g id="Сгруппировать_5212" data-name="Сгруппировать 5212" transform="translate(-255.069 -194.12)" clip-path="url(#clip-path-18)">
          <path id="Контур_5203" data-name="Контур 5203" d="M386.3-346.187h11.914v15.007H386.3Z" transform="translate(-133.866 537.669)" fill="#90b9c0"/>
        </g>
      </g>
      <g id="Сгруппировать_5215" data-name="Сгруппировать 5215" transform="translate(206.025 -449.327)" clip-path="url(#clip-path-619)">
        <g id="Сгруппировать_5214" data-name="Сгруппировать 5214" transform="translate(-254.209 -193.638)" clip-path="url(#clip-path-18)">
          <path id="Контур_5206" data-name="Контур 5206" d="M384.666-347.1h7.819v6.739h-7.819Z" transform="translate(-133.096 538.1)" fill="#bfe0e9"/>
        </g>
      </g>
      <g id="Сгруппировать_5217" data-name="Сгруппировать 5217" transform="translate(207.083 -447.751)" clip-path="url(#clip-path-621)">
        <g id="Сгруппировать_5216" data-name="Сгруппировать 5216" transform="translate(-255.266 -195.215)" clip-path="url(#clip-path-18)">
          <path id="Контур_5209" data-name="Контур 5209" d="M386.67-344.114h6.14v14.525h-6.14Z" transform="translate(-134.043 536.69)" fill="#a5cbd4"/>
        </g>
      </g>
      <g id="Сгруппировать_5219" data-name="Сгруппировать 5219" transform="translate(207.083 -447.031)" clip-path="url(#clip-path-623)">
        <g id="Сгруппировать_5218" data-name="Сгруппировать 5218" transform="translate(-255.266 -195.934)" clip-path="url(#clip-path-18)">
          <path id="Контур_5212" data-name="Контур 5212" d="M386.67-342.75h6.14v8.407h-6.14Z" transform="translate(-134.043 536.046)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5221" data-name="Сгруппировать 5221" transform="translate(207.944 -448.231)" clip-path="url(#clip-path-625)">
        <g id="Сгруппировать_5220" data-name="Сгруппировать 5220" transform="translate(-256.128 -194.734)" clip-path="url(#clip-path-18)">
          <path id="Контур_5215" data-name="Контур 5215" d="M388.3-345.025h11.914v15.006H388.3Z" transform="translate(-134.813 537.12)" fill="#90b9c0"/>
        </g>
      </g>
      <g id="Сгруппировать_5223" data-name="Сгруппировать 5223" transform="translate(207.083 -448.714)" clip-path="url(#clip-path-627)">
        <g id="Сгруппировать_5222" data-name="Сгруппировать 5222" transform="translate(-255.266 -194.251)" clip-path="url(#clip-path-18)">
          <path id="Контур_5218" data-name="Контур 5218" d="M386.67-345.94h7.819v6.739H386.67Z" transform="translate(-134.043 537.552)" fill="#bfe0e9"/>
        </g>
      </g>
      <g id="Сгруппировать_5225" data-name="Сгруппировать 5225" transform="translate(208.141 -447.138)" clip-path="url(#clip-path-629)">
        <g id="Сгруппировать_5224" data-name="Сгруппировать 5224" transform="translate(-256.324 -195.827)" clip-path="url(#clip-path-18)">
          <path id="Контур_5221" data-name="Контур 5221" d="M388.675-342.954h6.139v14.525h-6.139Z" transform="translate(-134.989 536.142)" fill="#b27b50"/>
        </g>
      </g>
      <g id="Сгруппировать_5227" data-name="Сгруппировать 5227" transform="translate(208.141 -446.419)" clip-path="url(#clip-path-631)">
        <g id="Сгруппировать_5226" data-name="Сгруппировать 5226" transform="translate(-256.324 -196.546)" clip-path="url(#clip-path-18)">
          <path id="Контур_5224" data-name="Контур 5224" d="M388.675-341.591h6.139v8.407h-6.139Z" transform="translate(-134.989 535.498)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5229" data-name="Сгруппировать 5229" transform="translate(209.002 -447.619)" clip-path="url(#clip-path-633)">
        <g id="Сгруппировать_5228" data-name="Сгруппировать 5228" transform="translate(-257.185 -195.346)" clip-path="url(#clip-path-18)">
          <path id="Контур_5227" data-name="Контур 5227" d="M390.306-343.865H402.22v15.006H390.306Z" transform="translate(-135.76 536.572)" fill="#9c6b40"/>
        </g>
      </g>
      <g id="Сгруппировать_5231" data-name="Сгруппировать 5231" transform="translate(208.141 -448.102)" clip-path="url(#clip-path-635)">
        <g id="Сгруппировать_5230" data-name="Сгруппировать 5230" transform="translate(-256.324 -194.864)" clip-path="url(#clip-path-18)">
          <path id="Контур_5230" data-name="Контур 5230" d="M388.675-344.779h7.819v6.738h-7.819Z" transform="translate(-134.989 537.004)" fill="#cb9562"/>
        </g>
      </g>
      <g id="Сгруппировать_5233" data-name="Сгруппировать 5233" transform="translate(209.199 -446.526)" clip-path="url(#clip-path-637)">
        <g id="Сгруппировать_5232" data-name="Сгруппировать 5232" transform="translate(-257.382 -196.439)" clip-path="url(#clip-path-18)">
          <path id="Контур_5233" data-name="Контур 5233" d="M390.679-341.793h6.14v14.525h-6.14Z" transform="translate(-135.936 535.594)" fill="#a5cbd4"/>
        </g>
      </g>
      <g id="Сгруппировать_5235" data-name="Сгруппировать 5235" transform="translate(209.199 -445.806)" clip-path="url(#clip-path-639)">
        <g id="Сгруппировать_5234" data-name="Сгруппировать 5234" transform="translate(-257.382 -197.159)" clip-path="url(#clip-path-18)">
          <path id="Контур_5236" data-name="Контур 5236" d="M390.679-340.43h6.14v8.407h-6.14Z" transform="translate(-135.936 534.95)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5237" data-name="Сгруппировать 5237" transform="translate(210.06 -447.007)" clip-path="url(#clip-path-641)">
        <g id="Сгруппировать_5236" data-name="Сгруппировать 5236" transform="translate(-258.243 -195.958)" clip-path="url(#clip-path-18)">
          <path id="Контур_5239" data-name="Контур 5239" d="M392.311-342.7h11.914V-327.7H392.311Z" transform="translate(-136.706 536.024)" fill="#90b9c0"/>
        </g>
      </g>
      <g id="Сгруппировать_5239" data-name="Сгруппировать 5239" transform="translate(209.199 -447.489)" clip-path="url(#clip-path-643)">
        <g id="Сгруппировать_5238" data-name="Сгруппировать 5238" transform="translate(-257.382 -195.476)" clip-path="url(#clip-path-18)">
          <path id="Контур_5242" data-name="Контур 5242" d="M390.679-343.619H398.5v6.738h-7.819Z" transform="translate(-135.936 536.456)" fill="#bfe0e9"/>
        </g>
      </g>
      <g id="Сгруппировать_5241" data-name="Сгруппировать 5241" transform="translate(210.257 -445.913)" clip-path="url(#clip-path-645)">
        <g id="Сгруппировать_5240" data-name="Сгруппировать 5240" transform="translate(-258.44 -197.052)" clip-path="url(#clip-path-18)">
          <path id="Контур_5245" data-name="Контур 5245" d="M392.684-340.633h6.139v14.525h-6.139Z" transform="translate(-136.883 535.046)" fill="#ae91af"/>
        </g>
      </g>
      <g id="Сгруппировать_5243" data-name="Сгруппировать 5243" transform="translate(210.257 -445.194)" clip-path="url(#clip-path-647)">
        <g id="Сгруппировать_5242" data-name="Сгруппировать 5242" transform="translate(-258.44 -197.771)" clip-path="url(#clip-path-18)">
          <path id="Контур_5248" data-name="Контур 5248" d="M392.684-339.27h6.139v8.407h-6.139Z" transform="translate(-136.883 534.402)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5245" data-name="Сгруппировать 5245" transform="translate(211.117 -446.395)" clip-path="url(#clip-path-649)">
        <g id="Сгруппировать_5244" data-name="Сгруппировать 5244" transform="translate(-259.301 -196.57)" clip-path="url(#clip-path-18)">
          <path id="Контур_5251" data-name="Контур 5251" d="M394.314-341.545h11.914v15.007H394.314Z" transform="translate(-137.652 535.477)" fill="#8c718e"/>
        </g>
      </g>
      <g id="Сгруппировать_5247" data-name="Сгруппировать 5247" transform="translate(210.257 -446.877)" clip-path="url(#clip-path-651)">
        <g id="Сгруппировать_5246" data-name="Сгруппировать 5246" transform="translate(-258.44 -196.088)" clip-path="url(#clip-path-18)">
          <path id="Контур_5254" data-name="Контур 5254" d="M392.684-342.459H400.5v6.739h-7.819Z" transform="translate(-136.883 535.908)" fill="#c9b2c9"/>
        </g>
      </g>
      <g id="Сгруппировать_5249" data-name="Сгруппировать 5249" transform="translate(115.609 -435.676)" clip-path="url(#clip-path-653)">
        <g id="Сгруппировать_5248" data-name="Сгруппировать 5248" transform="translate(-163.792 -207.289)" clip-path="url(#clip-path-198)">
          <path id="Контур_5257" data-name="Контур 5257" d="M213.348-321.236h17.667v12.424H213.348Z" transform="translate(-52.195 525.886)" fill="#afb5ca"/>
        </g>
      </g>
      <g id="Сгруппировать_5251" data-name="Сгруппировать 5251" transform="translate(97.146 -423.063)" clip-path="url(#clip-path-655)">
        <g id="Сгруппировать_5250" data-name="Сгруппировать 5250" transform="translate(-145.329 -219.903)" clip-path="url(#clip-path-18)">
          <path id="Контур_5260" data-name="Контур 5260" d="M178.365-297.336H188.2v8.808h-9.837Z" transform="translate(-35.675 514.6)" fill="#2e3636"/>
        </g>
      </g>
      <g id="Сгруппировать_5253" data-name="Сгруппировать 5253" transform="translate(101.704 -428.625)" clip-path="url(#clip-path-657)">
        <g id="Сгруппировать_5252" data-name="Сгруппировать 5252" transform="translate(-149.887 -214.34)" clip-path="url(#clip-path-18)">
          <path id="Контур_5263" data-name="Контур 5263" d="M187-307.875h20.111V-293.5H187Z" transform="translate(-39.753 519.577)" fill="#363e3e"/>
        </g>
      </g>
      <g id="Сгруппировать_5255" data-name="Сгруппировать 5255" transform="translate(97.146 -431.658)" clip-path="url(#clip-path-659)">
        <g id="Сгруппировать_5254" data-name="Сгруппировать 5254" transform="translate(-145.329 -211.307)" clip-path="url(#clip-path-18)">
          <path id="Контур_5266" data-name="Контур 5266" d="M178.365-313.623h24.668V-296.7H178.365Z" transform="translate(-35.675 522.291)" fill="#6b7373"/>
        </g>
      </g>
      <g id="Сгруппировать_5257" data-name="Сгруппировать 5257" transform="translate(103.025 -425.099)" clip-path="url(#clip-path-661)">
        <g id="Сгруппировать_5256" data-name="Сгруппировать 5256" transform="translate(-151.208 -217.866)" clip-path="url(#clip-path-18)">
          <path id="Контур_5269" data-name="Контур 5269" d="M189.5-301.194h11.029v8.671H189.5Z" transform="translate(-40.935 516.422)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5259" data-name="Сгруппировать 5259" transform="translate(102.122 -421.993)" clip-path="url(#clip-path-663)">
        <g id="Сгруппировать_5258" data-name="Сгруппировать 5258" transform="translate(-150.305 -220.972)" clip-path="url(#clip-path-18)">
          <path id="Контур_5272" data-name="Контур 5272" d="M187.793-295.309h6.582v6.1h-6.582Z" transform="translate(-40.127 513.642)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5261" data-name="Сгруппировать 5261" transform="translate(101.207 -421.461)" clip-path="url(#clip-path-665)">
        <g id="Сгруппировать_5260" data-name="Сгруппировать 5260" transform="translate(-149.39 -221.504)" clip-path="url(#clip-path-18)">
          <path id="Контур_5275" data-name="Контур 5275" d="M186.06-294.3h6.582v6.1H186.06Z" transform="translate(-39.308 513.167)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5263" data-name="Сгруппировать 5263" transform="translate(99.533 -423.83)" clip-path="url(#clip-path-667)">
        <g id="Сгруппировать_5262" data-name="Сгруппировать 5262" transform="translate(-147.716 -219.135)" clip-path="url(#clip-path-18)">
          <path id="Контур_5278" data-name="Контур 5278" d="M182.887-298.79h6.582v6.1h-6.582Z" transform="translate(-37.81 515.286)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5265" data-name="Сгруппировать 5265" transform="translate(98.618 -423.299)" clip-path="url(#clip-path-669)">
        <g id="Сгруппировать_5264" data-name="Сгруппировать 5264" transform="translate(-146.801 -219.667)" clip-path="url(#clip-path-18)">
          <path id="Контур_5281" data-name="Контур 5281" d="M181.154-297.783h6.582v6.1h-6.582Z" transform="translate(-36.992 514.811)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5267" data-name="Сгруппировать 5267" transform="translate(103.374 -423.565)" clip-path="url(#clip-path-671)">
        <g id="Сгруппировать_5266" data-name="Сгруппировать 5266" transform="translate(-151.557 -219.401)" clip-path="url(#clip-path-18)">
          <path id="Контур_5284" data-name="Контур 5284" d="M190.166-298.287h6.582v6.1h-6.582Z" transform="translate(-41.247 515.049)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5269" data-name="Сгруппировать 5269" transform="translate(102.46 -423.033)" clip-path="url(#clip-path-673)">
        <g id="Сгруппировать_5268" data-name="Сгруппировать 5268" transform="translate(-150.643 -219.933)" clip-path="url(#clip-path-18)">
          <path id="Контур_5287" data-name="Контур 5287" d="M188.434-297.279h6.581v6.1h-6.581Z" transform="translate(-40.429 514.573)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5271" data-name="Сгруппировать 5271" transform="translate(105.211 -424.633)" clip-path="url(#clip-path-675)">
        <g id="Сгруппировать_5270" data-name="Сгруппировать 5270" transform="translate(-153.395 -218.332)" clip-path="url(#clip-path-18)">
          <path id="Контур_5290" data-name="Контур 5290" d="M193.647-300.311h6.582v6.1h-6.582Z" transform="translate(-42.891 516.005)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5273" data-name="Сгруппировать 5273" transform="translate(104.297 -424.101)" clip-path="url(#clip-path-677)">
        <g id="Сгруппировать_5272" data-name="Сгруппировать 5272" transform="translate(-152.48 -218.864)" clip-path="url(#clip-path-18)">
          <path id="Контур_5293" data-name="Контур 5293" d="M191.914-299.3H198.5v6.1h-6.582Z" transform="translate(-42.073 515.529)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5275" data-name="Сгруппировать 5275" transform="translate(107.049 -425.7)" clip-path="url(#clip-path-679)">
        <g id="Сгруппировать_5274" data-name="Сгруппировать 5274" transform="translate(-155.232 -217.265)" clip-path="url(#clip-path-18)">
          <path id="Контур_5296" data-name="Контур 5296" d="M197.129-302.334h6.582v6.1h-6.582Z" transform="translate(-44.536 516.96)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5277" data-name="Сгруппировать 5277" transform="translate(106.135 -425.168)" clip-path="url(#clip-path-681)">
        <g id="Сгруппировать_5276" data-name="Сгруппировать 5276" transform="translate(-154.318 -217.797)" clip-path="url(#clip-path-18)">
          <path id="Контур_5299" data-name="Контур 5299" d="M195.4-301.326h6.582v6.1H195.4Z" transform="translate(-43.717 516.484)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5279" data-name="Сгруппировать 5279" transform="translate(107.972 -426.237)" clip-path="url(#clip-path-683)">
        <g id="Сгруппировать_5278" data-name="Сгруппировать 5278" transform="translate(-156.155 -216.729)" clip-path="url(#clip-path-18)">
          <path id="Контур_5302" data-name="Контур 5302" d="M198.877-303.35h6.582v6.1h-6.582Z" transform="translate(-45.361 517.44)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5281" data-name="Сгруппировать 5281" transform="translate(102.721 -424.008)" clip-path="url(#clip-path-685)">
        <g id="Сгруппировать_5280" data-name="Сгруппировать 5280" transform="translate(-150.905 -218.957)" clip-path="url(#clip-path-18)">
          <path id="Контур_5305" data-name="Контур 5305" d="M188.929-299.127h6.582v6.1h-6.582Z" transform="translate(-40.663 515.445)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5283" data-name="Сгруппировать 5283" transform="translate(101.807 -423.476)" clip-path="url(#clip-path-687)">
        <g id="Сгруппировать_5282" data-name="Сгруппировать 5282" transform="translate(-149.99 -219.489)" clip-path="url(#clip-path-18)">
          <path id="Контур_5308" data-name="Контур 5308" d="M187.2-298.119h6.582v6.1H187.2Z" transform="translate(-39.845 514.969)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5285" data-name="Сгруппировать 5285" transform="translate(104.559 -425.076)" clip-path="url(#clip-path-689)">
        <g id="Сгруппировать_5284" data-name="Сгруппировать 5284" transform="translate(-152.742 -217.89)" clip-path="url(#clip-path-18)">
          <path id="Контур_5311" data-name="Контур 5311" d="M192.41-301.15h6.582v6.1H192.41Z" transform="translate(-42.307 516.401)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5287" data-name="Сгруппировать 5287" transform="translate(103.644 -424.544)" clip-path="url(#clip-path-691)">
        <g id="Сгруппировать_5286" data-name="Сгруппировать 5286" transform="translate(-151.827 -218.421)" clip-path="url(#clip-path-18)">
          <path id="Контур_5314" data-name="Контур 5314" d="M190.677-300.143h6.582v6.1h-6.582Z" transform="translate(-41.489 515.925)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5289" data-name="Сгруппировать 5289" transform="translate(106.396 -426.144)" clip-path="url(#clip-path-693)">
        <g id="Сгруппировать_5288" data-name="Сгруппировать 5288" transform="translate(-154.579 -216.821)" clip-path="url(#clip-path-18)">
          <path id="Контур_5317" data-name="Контур 5317" d="M195.892-303.174h6.582v6.1h-6.582Z" transform="translate(-43.951 517.357)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5291" data-name="Сгруппировать 5291" transform="translate(105.481 -425.612)" clip-path="url(#clip-path-695)">
        <g id="Сгруппировать_5290" data-name="Сгруппировать 5290" transform="translate(-153.664 -217.353)" clip-path="url(#clip-path-18)">
          <path id="Контур_5320" data-name="Контур 5320" d="M194.158-302.166h6.582v6.1h-6.582Z" transform="translate(-43.133 516.881)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5293" data-name="Сгруппировать 5293" transform="translate(108.233 -427.211)" clip-path="url(#clip-path-697)">
        <g id="Сгруппировать_5292" data-name="Сгруппировать 5292" transform="translate(-156.417 -215.754)" clip-path="url(#clip-path-18)">
          <path id="Контур_5323" data-name="Контур 5323" d="M199.373-305.2h6.582v6.1h-6.582Z" transform="translate(-45.595 518.312)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5295" data-name="Сгруппировать 5295" transform="translate(107.319 -426.68)" clip-path="url(#clip-path-699)">
        <g id="Сгруппировать_5294" data-name="Сгруппировать 5294" transform="translate(-155.502 -216.285)" clip-path="url(#clip-path-18)">
          <path id="Контур_5326" data-name="Контур 5326" d="M197.64-304.19h6.582v6.1H197.64Z" transform="translate(-44.777 517.836)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5297" data-name="Сгруппировать 5297" transform="translate(109.156 -427.748)" clip-path="url(#clip-path-701)">
        <g id="Сгруппировать_5296" data-name="Сгруппировать 5296" transform="translate(-157.339 -215.217)" clip-path="url(#clip-path-18)">
          <path id="Контур_5329" data-name="Контур 5329" d="M201.121-306.214H207.7v6.1h-6.582Z" transform="translate(-46.421 518.792)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5299" data-name="Сгруппировать 5299" transform="translate(102.096 -424.444)" clip-path="url(#clip-path-703)">
        <g id="Сгруппировать_5298" data-name="Сгруппировать 5298" transform="translate(-150.279 -218.521)" clip-path="url(#clip-path-18)">
          <path id="Контур_5332" data-name="Контур 5332" d="M187.744-299.953h6.582v6.1h-6.582Z" transform="translate(-40.104 515.836)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5301" data-name="Сгруппировать 5301" transform="translate(101.181 -423.913)" clip-path="url(#clip-path-705)">
        <g id="Сгруппировать_5300" data-name="Сгруппировать 5300" transform="translate(-149.365 -219.052)" clip-path="url(#clip-path-18)">
          <path id="Контур_5335" data-name="Контур 5335" d="M186.011-298.947h6.582v6.1h-6.582Z" transform="translate(-39.285 515.36)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5303" data-name="Сгруппировать 5303" transform="translate(103.934 -425.513)" clip-path="url(#clip-path-707)">
        <g id="Сгруппировать_5302" data-name="Сгруппировать 5302" transform="translate(-152.117 -217.453)" clip-path="url(#clip-path-18)">
          <path id="Контур_5338" data-name="Контур 5338" d="M191.226-301.978h6.582v6.1h-6.582Z" transform="translate(-41.748 516.792)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5305" data-name="Сгруппировать 5305" transform="translate(103.019 -424.981)" clip-path="url(#clip-path-709)">
        <g id="Сгруппировать_5304" data-name="Сгруппировать 5304" transform="translate(-151.202 -217.984)" clip-path="url(#clip-path-18)">
          <path id="Контур_5341" data-name="Контур 5341" d="M189.492-300.971h6.582v6.1h-6.582Z" transform="translate(-40.929 516.316)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5307" data-name="Сгруппировать 5307" transform="translate(105.771 -426.58)" clip-path="url(#clip-path-711)">
        <g id="Сгруппировать_5306" data-name="Сгруппировать 5306" transform="translate(-153.954 -216.385)" clip-path="url(#clip-path-18)">
          <path id="Контур_5344" data-name="Контур 5344" d="M194.707-304h6.582v6.1h-6.582Z" transform="translate(-43.392 517.747)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5309" data-name="Сгруппировать 5309" transform="translate(104.856 -426.049)" clip-path="url(#clip-path-713)">
        <g id="Сгруппировать_5308" data-name="Сгруппировать 5308" transform="translate(-153.039 -216.916)" clip-path="url(#clip-path-18)">
          <path id="Контур_5347" data-name="Контур 5347" d="M192.973-302.994h6.582v6.1h-6.582Z" transform="translate(-42.573 517.272)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5311" data-name="Сгруппировать 5311" transform="translate(107.608 -427.648)" clip-path="url(#clip-path-715)">
        <g id="Сгруппировать_5310" data-name="Сгруппировать 5310" transform="translate(-155.791 -215.317)" clip-path="url(#clip-path-18)">
          <path id="Контур_5350" data-name="Контур 5350" d="M198.188-306.024h6.582v6.1h-6.582Z" transform="translate(-45.036 518.702)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5313" data-name="Сгруппировать 5313" transform="translate(106.693 -427.117)" clip-path="url(#clip-path-717)">
        <g id="Сгруппировать_5312" data-name="Сгруппировать 5312" transform="translate(-154.876 -215.848)" clip-path="url(#clip-path-18)">
          <path id="Контур_5353" data-name="Контур 5353" d="M196.454-305.018h6.582v6.1h-6.582Z" transform="translate(-44.217 518.227)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5315" data-name="Сгруппировать 5315" transform="translate(108.531 -428.185)" clip-path="url(#clip-path-719)">
        <g id="Сгруппировать_5314" data-name="Сгруппировать 5314" transform="translate(-156.714 -214.781)" clip-path="url(#clip-path-18)">
          <path id="Контур_5356" data-name="Контур 5356" d="M199.936-307.041h6.582v6.1h-6.582Z" transform="translate(-45.861 519.183)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5317" data-name="Сгруппировать 5317" transform="translate(101.424 -424.91)" clip-path="url(#clip-path-721)">
        <g id="Сгруппировать_5316" data-name="Сгруппировать 5316" transform="translate(-149.607 -218.055)" clip-path="url(#clip-path-18)">
          <path id="Контур_5359" data-name="Контур 5359" d="M186.471-300.837h6.582v6.1h-6.582Z" transform="translate(-39.502 516.253)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5319" data-name="Сгруппировать 5319" transform="translate(100.51 -424.379)" clip-path="url(#clip-path-723)">
        <g id="Сгруппировать_5318" data-name="Сгруппировать 5318" transform="translate(-148.693 -218.586)" clip-path="url(#clip-path-18)">
          <path id="Контур_5362" data-name="Контур 5362" d="M184.738-299.83h6.582v6.1h-6.582Z" transform="translate(-38.684 515.777)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5321" data-name="Сгруппировать 5321" transform="translate(103.261 -425.979)" clip-path="url(#clip-path-725)">
        <g id="Сгруппировать_5320" data-name="Сгруппировать 5320" transform="translate(-151.445 -216.987)" clip-path="url(#clip-path-18)">
          <path id="Контур_5365" data-name="Контур 5365" d="M189.952-302.861h6.582v6.1h-6.582Z" transform="translate(-41.146 517.209)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5323" data-name="Сгруппировать 5323" transform="translate(102.347 -425.447)" clip-path="url(#clip-path-727)">
        <g id="Сгруппировать_5322" data-name="Сгруппировать 5322" transform="translate(-150.53 -217.518)" clip-path="url(#clip-path-18)">
          <path id="Контур_5368" data-name="Контур 5368" d="M188.219-301.854H194.8v6.1h-6.582Z" transform="translate(-40.328 516.733)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5325" data-name="Сгруппировать 5325" transform="translate(105.099 -427.046)" clip-path="url(#clip-path-729)">
        <g id="Сгруппировать_5324" data-name="Сгруппировать 5324" transform="translate(-153.282 -215.919)" clip-path="url(#clip-path-18)">
          <path id="Контур_5371" data-name="Контур 5371" d="M193.434-304.884h6.582v6.1h-6.582Z" transform="translate(-42.791 518.164)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5327" data-name="Сгруппировать 5327" transform="translate(104.184 -426.515)" clip-path="url(#clip-path-731)">
        <g id="Сгруппировать_5326" data-name="Сгруппировать 5326" transform="translate(-152.368 -216.45)" clip-path="url(#clip-path-18)">
          <path id="Контур_5374" data-name="Контур 5374" d="M191.7-303.877h6.582v6.1H191.7Z" transform="translate(-41.972 517.689)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5329" data-name="Сгруппировать 5329" transform="translate(106.936 -428.114)" clip-path="url(#clip-path-733)">
        <g id="Сгруппировать_5328" data-name="Сгруппировать 5328" transform="translate(-155.119 -214.851)" clip-path="url(#clip-path-18)">
          <path id="Контур_5377" data-name="Контур 5377" d="M196.914-306.907H203.5v6.1h-6.583Z" transform="translate(-44.434 519.119)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5331" data-name="Сгруппировать 5331" transform="translate(106.022 -427.582)" clip-path="url(#clip-path-735)">
        <g id="Сгруппировать_5330" data-name="Сгруппировать 5330" transform="translate(-154.205 -215.383)" clip-path="url(#clip-path-18)">
          <path id="Контур_5380" data-name="Контур 5380" d="M195.182-305.9h6.582v6.1h-6.582Z" transform="translate(-43.616 518.644)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5333" data-name="Сгруппировать 5333" transform="translate(107.859 -428.651)" clip-path="url(#clip-path-737)">
        <g id="Сгруппировать_5332" data-name="Сгруппировать 5332" transform="translate(-156.042 -214.315)" clip-path="url(#clip-path-18)">
          <path id="Контур_5383" data-name="Контур 5383" d="M198.664-307.924h6.582v6.1h-6.582Z" transform="translate(-45.26 519.6)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5335" data-name="Сгруппировать 5335" transform="translate(112.7 -429.002)" clip-path="url(#clip-path-739)">
        <g id="Сгруппировать_5334" data-name="Сгруппировать 5334" transform="translate(-160.883 -213.963)" clip-path="url(#clip-path-18)">
          <path id="Контур_5386" data-name="Контур 5386" d="M207.836-308.59h6.582v6.1h-6.582Z" transform="translate(-49.592 519.914)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5337" data-name="Сгруппировать 5337" transform="translate(111.785 -428.47)" clip-path="url(#clip-path-741)">
        <g id="Сгруппировать_5336" data-name="Сгруппировать 5336" transform="translate(-159.969 -214.495)" clip-path="url(#clip-path-18)">
          <path id="Контур_5389" data-name="Контур 5389" d="M206.1-307.582h6.582v6.1H206.1Z" transform="translate(-48.773 519.438)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5339" data-name="Сгруппировать 5339" transform="translate(113.623 -429.538)" clip-path="url(#clip-path-743)">
        <g id="Сгруппировать_5338" data-name="Сгруппировать 5338" transform="translate(-161.806 -213.427)" clip-path="url(#clip-path-18)">
          <path id="Контур_5392" data-name="Контур 5392" d="M209.584-309.605h6.582v6.1h-6.582Z" transform="translate(-50.417 520.393)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5341" data-name="Сгруппировать 5341" transform="translate(112.047 -429.445)" clip-path="url(#clip-path-745)">
        <g id="Сгруппировать_5340" data-name="Сгруппировать 5340" transform="translate(-160.23 -213.52)" clip-path="url(#clip-path-18)">
          <path id="Контур_5395" data-name="Контур 5395" d="M206.6-309.43h6.582v6.1H206.6Z" transform="translate(-49.007 520.311)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5343" data-name="Сгруппировать 5343" transform="translate(111.132 -428.913)" clip-path="url(#clip-path-747)">
        <g id="Сгруппировать_5342" data-name="Сгруппировать 5342" transform="translate(-159.315 -214.052)" clip-path="url(#clip-path-18)">
          <path id="Контур_5398" data-name="Контур 5398" d="M204.865-308.422h6.582v6.1h-6.582Z" transform="translate(-48.189 519.835)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5345" data-name="Сгруппировать 5345" transform="translate(112.97 -429.981)" clip-path="url(#clip-path-749)">
        <g id="Сгруппировать_5344" data-name="Сгруппировать 5344" transform="translate(-161.153 -212.984)" clip-path="url(#clip-path-18)">
          <path id="Контур_5401" data-name="Контур 5401" d="M208.347-310.445h6.582v6.1h-6.582Z" transform="translate(-49.833 520.79)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5347" data-name="Сгруппировать 5347" transform="translate(111.421 -429.881)" clip-path="url(#clip-path-751)">
        <g id="Сгруппировать_5346" data-name="Сгруппировать 5346" transform="translate(-159.604 -213.084)" clip-path="url(#clip-path-18)">
          <path id="Контур_5404" data-name="Контур 5404" d="M205.413-310.256H212v6.1h-6.582Z" transform="translate(-48.447 520.701)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5349" data-name="Сгруппировать 5349" transform="translate(110.507 -429.35)" clip-path="url(#clip-path-753)">
        <g id="Сгруппировать_5348" data-name="Сгруппировать 5348" transform="translate(-158.69 -213.615)" clip-path="url(#clip-path-18)">
          <path id="Контур_5407" data-name="Контур 5407" d="M203.681-309.25h6.582v6.1h-6.582Z" transform="translate(-47.63 520.226)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5351" data-name="Сгруппировать 5351" transform="translate(112.344 -430.418)" clip-path="url(#clip-path-755)">
        <g id="Сгруппировать_5350" data-name="Сгруппировать 5350" transform="translate(-160.527 -212.547)" clip-path="url(#clip-path-18)">
          <path id="Контур_5410" data-name="Контур 5410" d="M207.162-311.273h6.582v6.1h-6.582Z" transform="translate(-49.273 521.181)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5353" data-name="Сгруппировать 5353" transform="translate(110.75 -430.348)" clip-path="url(#clip-path-757)">
        <g id="Сгруппировать_5352" data-name="Сгруппировать 5352" transform="translate(-158.933 -212.617)" clip-path="url(#clip-path-18)">
          <path id="Контур_5413" data-name="Контур 5413" d="M204.141-311.14h6.582v6.1h-6.582Z" transform="translate(-47.847 521.118)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5355" data-name="Сгруппировать 5355" transform="translate(109.835 -429.816)" clip-path="url(#clip-path-759)">
        <g id="Сгруппировать_5354" data-name="Сгруппировать 5354" transform="translate(-158.018 -213.149)" clip-path="url(#clip-path-18)">
          <path id="Контур_5416" data-name="Контур 5416" d="M202.407-310.133h6.582v6.1h-6.582Z" transform="translate(-47.028 520.643)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5357" data-name="Сгруппировать 5357" transform="translate(111.672 -430.884)" clip-path="url(#clip-path-761)">
        <g id="Сгруппировать_5356" data-name="Сгруппировать 5356" transform="translate(-159.856 -212.081)" clip-path="url(#clip-path-18)">
          <path id="Контур_5419" data-name="Контур 5419" d="M205.889-312.156h6.582v6.1h-6.582Z" transform="translate(-48.672 521.598)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5359" data-name="Сгруппировать 5359" transform="translate(97.943 -423.765)" clip-path="url(#clip-path-763)">
        <g id="Сгруппировать_5358" data-name="Сгруппировать 5358" transform="translate(-146.126 -219.2)" clip-path="url(#clip-path-18)">
          <path id="Контур_5422" data-name="Контур 5422" d="M179.874-298.667h6.582v6.1h-6.582Z" transform="translate(-36.387 515.228)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5361" data-name="Сгруппировать 5361" transform="translate(100.548 -422.453)" clip-path="url(#clip-path-765)">
        <g id="Сгруппировать_5360" data-name="Сгруппировать 5360" transform="translate(-148.731 -220.513)" clip-path="url(#clip-path-18)">
          <path id="Контур_5425" data-name="Контур 5425" d="M184.81-296.18h7.508v6.624H184.81Z" transform="translate(-38.718 514.054)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5363" data-name="Сгруппировать 5363" transform="translate(108.885 -427.292)" clip-path="url(#clip-path-767)">
        <g id="Сгруппировать_5362" data-name="Сгруппировать 5362" transform="translate(-157.068 -215.673)" clip-path="url(#clip-path-18)">
          <path id="Контур_5428" data-name="Контур 5428" d="M200.608-305.35h7.509v6.624h-7.509Z" transform="translate(-46.178 518.384)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5365" data-name="Сгруппировать 5365" transform="translate(99.915 -422.896)" clip-path="url(#clip-path-769)">
        <g id="Сгруппировать_5364" data-name="Сгруппировать 5364" transform="translate(-148.098 -220.069)" clip-path="url(#clip-path-18)">
          <path id="Контур_5431" data-name="Контур 5431" d="M183.612-297.02h7.508v6.624h-7.508Z" transform="translate(-38.152 514.451)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5367" data-name="Сгруппировать 5367" transform="translate(99.289 -423.339)" clip-path="url(#clip-path-771)">
        <g id="Сгруппировать_5366" data-name="Сгруппировать 5366" transform="translate(-147.473 -219.627)" clip-path="url(#clip-path-18)">
          <path id="Контур_5434" data-name="Контур 5434" d="M182.426-297.859h7.508v6.624h-7.508Z" transform="translate(-37.592 514.847)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5369" data-name="Сгруппировать 5369" transform="translate(48.032 -393.189)" clip-path="url(#clip-path-773)">
        <g id="Сгруппировать_5368" data-name="Сгруппировать 5368" transform="translate(-96.215 -249.776)" clip-path="url(#clip-path-18)">
          <path id="Контур_5437" data-name="Контур 5437" d="M85.305-240.732h9.837v8.808H85.305Z" transform="translate(8.271 487.87)" fill="#2e3636"/>
        </g>
      </g>
      <g id="Сгруппировать_5371" data-name="Сгруппировать 5371" transform="translate(52.59 -398.751)" clip-path="url(#clip-path-775)">
        <g id="Сгруппировать_5370" data-name="Сгруппировать 5370" transform="translate(-100.773 -244.214)" clip-path="url(#clip-path-18)">
          <path id="Контур_5440" data-name="Контур 5440" d="M93.941-251.271h20.111V-236.9H93.941Z" transform="translate(4.193 492.846)" fill="#363e3e"/>
        </g>
      </g>
      <g id="Сгруппировать_5373" data-name="Сгруппировать 5373" transform="translate(48.032 -401.785)" clip-path="url(#clip-path-777)">
        <g id="Сгруппировать_5372" data-name="Сгруппировать 5372" transform="translate(-96.215 -241.181)" clip-path="url(#clip-path-18)">
          <path id="Контур_5443" data-name="Контур 5443" d="M85.305-257.019h24.669v16.927H85.305Z" transform="translate(8.271 495.561)" fill="#6b7373"/>
        </g>
      </g>
      <g id="Сгруппировать_5375" data-name="Сгруппировать 5375" transform="translate(53.91 -395.225)" clip-path="url(#clip-path-779)">
        <g id="Сгруппировать_5374" data-name="Сгруппировать 5374" transform="translate(-102.093 -247.74)" clip-path="url(#clip-path-18)">
          <path id="Контур_5446" data-name="Контур 5446" d="M96.443-244.591h11.03v8.671H96.443Z" transform="translate(3.011 489.692)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5377" data-name="Сгруппировать 5377" transform="translate(53.007 -392.119)" clip-path="url(#clip-path-781)">
        <g id="Сгруппировать_5376" data-name="Сгруппировать 5376" transform="translate(-101.19 -250.846)" clip-path="url(#clip-path-18)">
          <path id="Контур_5449" data-name="Контур 5449" d="M94.732-238.705h6.582v6.1H94.732Z" transform="translate(3.819 486.912)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5379" data-name="Сгруппировать 5379" transform="translate(52.093 -391.587)" clip-path="url(#clip-path-783)">
        <g id="Сгруппировать_5378" data-name="Сгруппировать 5378" transform="translate(-100.276 -251.378)" clip-path="url(#clip-path-18)">
          <path id="Контур_5452" data-name="Контур 5452" d="M93-237.7h6.583v6.1H93Z" transform="translate(4.638 486.436)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5381" data-name="Сгруппировать 5381" transform="translate(50.418 -393.957)" clip-path="url(#clip-path-785)">
        <g id="Сгруппировать_5380" data-name="Сгруппировать 5380" transform="translate(-98.602 -249.008)" clip-path="url(#clip-path-18)">
          <path id="Контур_5455" data-name="Контур 5455" d="M89.827-242.187h6.582v6.1H89.827Z" transform="translate(6.136 488.557)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5383" data-name="Сгруппировать 5383" transform="translate(49.504 -393.425)" clip-path="url(#clip-path-787)">
        <g id="Сгруппировать_5382" data-name="Сгруппировать 5382" transform="translate(-97.687 -249.54)" clip-path="url(#clip-path-18)">
          <path id="Контур_5458" data-name="Контур 5458" d="M88.095-241.179h6.582v6.1H88.095Z" transform="translate(6.954 488.081)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5385" data-name="Сгруппировать 5385" transform="translate(54.26 -393.691)" clip-path="url(#clip-path-789)">
        <g id="Сгруппировать_5384" data-name="Сгруппировать 5384" transform="translate(-102.443 -249.275)" clip-path="url(#clip-path-18)">
          <path id="Контур_5461" data-name="Контур 5461" d="M97.1-241.683h6.583v6.1H97.1Z" transform="translate(2.699 488.319)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5387" data-name="Сгруппировать 5387" transform="translate(53.345 -393.159)" clip-path="url(#clip-path-791)">
        <g id="Сгруппировать_5386" data-name="Сгруппировать 5386" transform="translate(-101.529 -249.806)" clip-path="url(#clip-path-18)">
          <path id="Контур_5464" data-name="Контур 5464" d="M95.373-240.676h6.582v6.1H95.373Z" transform="translate(3.517 487.843)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5389" data-name="Сгруппировать 5389" transform="translate(56.097 -394.759)" clip-path="url(#clip-path-793)">
        <g id="Сгруппировать_5388" data-name="Сгруппировать 5388" transform="translate(-104.28 -248.206)" clip-path="url(#clip-path-18)">
          <path id="Контур_5467" data-name="Контур 5467" d="M100.587-243.707h6.582v6.1h-6.582Z" transform="translate(1.055 489.274)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5391" data-name="Сгруппировать 5391" transform="translate(55.183 -394.227)" clip-path="url(#clip-path-795)">
        <g id="Сгруппировать_5390" data-name="Сгруппировать 5390" transform="translate(-103.366 -248.738)" clip-path="url(#clip-path-18)">
          <path id="Контур_5470" data-name="Контур 5470" d="M98.854-242.7h6.582v6.1H98.854Z" transform="translate(1.873 488.798)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5393" data-name="Сгруппировать 5393" transform="translate(57.934 -395.827)" clip-path="url(#clip-path-797)">
        <g id="Сгруппировать_5392" data-name="Сгруппировать 5392" transform="translate(-106.118 -247.139)" clip-path="url(#clip-path-18)">
          <path id="Контур_5473" data-name="Контур 5473" d="M104.068-245.73h6.582v6.1h-6.582Z" transform="translate(-0.589 490.23)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5395" data-name="Сгруппировать 5395" transform="translate(57.02 -395.295)" clip-path="url(#clip-path-799)">
        <g id="Сгруппировать_5394" data-name="Сгруппировать 5394" transform="translate(-105.203 -247.67)" clip-path="url(#clip-path-18)">
          <path id="Контур_5476" data-name="Контур 5476" d="M102.336-244.723h6.582v6.1h-6.582Z" transform="translate(0.229 489.754)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5397" data-name="Сгруппировать 5397" transform="translate(58.857 -396.363)" clip-path="url(#clip-path-801)">
        <g id="Сгруппировать_5396" data-name="Сгруппировать 5396" transform="translate(-107.041 -246.602)" clip-path="url(#clip-path-18)">
          <path id="Контур_5479" data-name="Контур 5479" d="M105.817-246.746H112.4v6.1h-6.582Z" transform="translate(-1.415 490.71)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5399" data-name="Сгруппировать 5399" transform="translate(53.607 -394.134)" clip-path="url(#clip-path-803)">
        <g id="Сгруппировать_5398" data-name="Сгруппировать 5398" transform="translate(-101.79 -248.831)" clip-path="url(#clip-path-18)">
          <path id="Контур_5482" data-name="Контур 5482" d="M95.869-242.523h6.582v6.1H95.869Z" transform="translate(3.283 488.715)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5401" data-name="Сгруппировать 5401" transform="translate(52.693 -393.603)" clip-path="url(#clip-path-805)">
        <g id="Сгруппировать_5400" data-name="Сгруппировать 5400" transform="translate(-100.876 -249.363)" clip-path="url(#clip-path-18)">
          <path id="Контур_5485" data-name="Контур 5485" d="M94.136-241.516h6.582v6.1H94.136Z" transform="translate(4.101 488.24)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5403" data-name="Сгруппировать 5403" transform="translate(55.444 -395.202)" clip-path="url(#clip-path-807)">
        <g id="Сгруппировать_5402" data-name="Сгруппировать 5402" transform="translate(-103.628 -247.763)" clip-path="url(#clip-path-18)">
          <path id="Контур_5488" data-name="Контур 5488" d="M99.35-244.547h6.582v6.1H99.35Z" transform="translate(1.639 489.671)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5405" data-name="Сгруппировать 5405" transform="translate(54.53 -394.67)" clip-path="url(#clip-path-809)">
        <g id="Сгруппировать_5404" data-name="Сгруппировать 5404" transform="translate(-102.713 -248.295)" clip-path="url(#clip-path-18)">
          <path id="Контур_5491" data-name="Контур 5491" d="M97.617-243.539H104.2v6.1H97.617Z" transform="translate(2.457 489.195)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5407" data-name="Сгруппировать 5407" transform="translate(57.282 -396.27)" clip-path="url(#clip-path-811)">
        <g id="Сгруппировать_5406" data-name="Сгруппировать 5406" transform="translate(-105.465 -246.695)" clip-path="url(#clip-path-18)">
          <path id="Контур_5494" data-name="Контур 5494" d="M102.831-246.57h6.582v6.1h-6.582Z" transform="translate(-0.005 490.626)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5409" data-name="Сгруппировать 5409" transform="translate(56.367 -395.738)" clip-path="url(#clip-path-813)">
        <g id="Сгруппировать_5408" data-name="Сгруппировать 5408" transform="translate(-104.55 -247.227)" clip-path="url(#clip-path-18)">
          <path id="Контур_5497" data-name="Контур 5497" d="M101.1-245.563h6.582v6.1H101.1Z" transform="translate(0.813 490.151)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5411" data-name="Сгруппировать 5411" transform="translate(59.119 -397.338)" clip-path="url(#clip-path-815)">
        <g id="Сгруппировать_5410" data-name="Сгруппировать 5410" transform="translate(-107.302 -245.627)" clip-path="url(#clip-path-18)">
          <path id="Контур_5500" data-name="Контур 5500" d="M106.313-248.594h6.582v6.1h-6.582Z" transform="translate(-1.649 491.582)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5413" data-name="Сгруппировать 5413" transform="translate(58.205 -396.806)" clip-path="url(#clip-path-817)">
        <g id="Сгруппировать_5412" data-name="Сгруппировать 5412" transform="translate(-106.388 -246.159)" clip-path="url(#clip-path-18)">
          <path id="Контур_5503" data-name="Контур 5503" d="M104.58-247.586h6.582v6.1H104.58Z" transform="translate(-0.831 491.106)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5415" data-name="Сгруппировать 5415" transform="translate(60.042 -397.874)" clip-path="url(#clip-path-819)">
        <g id="Сгруппировать_5414" data-name="Сгруппировать 5414" transform="translate(-108.225 -245.091)" clip-path="url(#clip-path-18)">
          <path id="Контур_5506" data-name="Контур 5506" d="M108.061-249.61h6.582v6.1h-6.582Z" transform="translate(-2.475 492.062)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5417" data-name="Сгруппировать 5417" transform="translate(52.982 -394.57)" clip-path="url(#clip-path-821)">
        <g id="Сгруппировать_5416" data-name="Сгруппировать 5416" transform="translate(-101.165 -248.395)" clip-path="url(#clip-path-18)">
          <path id="Контур_5509" data-name="Контур 5509" d="M94.684-243.35h6.582v6.1H94.684Z" transform="translate(3.842 489.106)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5419" data-name="Сгруппировать 5419" transform="translate(52.067 -394.039)" clip-path="url(#clip-path-823)">
        <g id="Сгруппировать_5418" data-name="Сгруппировать 5418" transform="translate(-100.25 -248.926)" clip-path="url(#clip-path-18)">
          <path id="Контур_5512" data-name="Контур 5512" d="M92.951-242.343h6.582v6.1H92.951Z" transform="translate(4.661 488.63)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5421" data-name="Сгруппировать 5421" transform="translate(54.819 -395.639)" clip-path="url(#clip-path-825)">
        <g id="Сгруппировать_5420" data-name="Сгруппировать 5420" transform="translate(-103.002 -247.327)" clip-path="url(#clip-path-18)">
          <path id="Контур_5515" data-name="Контур 5515" d="M98.165-245.374h6.582v6.1H98.165Z" transform="translate(2.198 490.062)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5423" data-name="Сгруппировать 5423" transform="translate(53.904 -395.107)" clip-path="url(#clip-path-827)">
        <g id="Сгруппировать_5422" data-name="Сгруппировать 5422" transform="translate(-102.088 -247.858)" clip-path="url(#clip-path-18)">
          <path id="Контур_5518" data-name="Контур 5518" d="M96.432-244.366h6.582v6.1H96.432Z" transform="translate(3.017 489.586)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5425" data-name="Сгруппировать 5425" transform="translate(56.656 -396.706)" clip-path="url(#clip-path-829)">
        <g id="Сгруппировать_5424" data-name="Сгруппировать 5424" transform="translate(-104.839 -246.259)" clip-path="url(#clip-path-18)">
          <path id="Контур_5521" data-name="Контур 5521" d="M101.646-247.4h6.582v6.1h-6.582Z" transform="translate(0.554 491.017)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5427" data-name="Сгруппировать 5427" transform="translate(55.742 -396.175)" clip-path="url(#clip-path-831)">
        <g id="Сгруппировать_5426" data-name="Сгруппировать 5426" transform="translate(-103.925 -246.79)" clip-path="url(#clip-path-18)">
          <path id="Контур_5524" data-name="Контур 5524" d="M99.913-246.391H106.5v6.1H99.913Z" transform="translate(1.373 490.542)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5429" data-name="Сгруппировать 5429" transform="translate(58.493 -397.775)" clip-path="url(#clip-path-833)">
        <g id="Сгруппировать_5428" data-name="Сгруппировать 5428" transform="translate(-106.676 -245.191)" clip-path="url(#clip-path-18)">
          <path id="Контур_5527" data-name="Контур 5527" d="M105.127-249.421h6.583v6.1h-6.583Z" transform="translate(-1.089 491.973)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5431" data-name="Сгруппировать 5431" transform="translate(57.579 -397.243)" clip-path="url(#clip-path-835)">
        <g id="Сгруппировать_5430" data-name="Сгруппировать 5430" transform="translate(-105.762 -245.722)" clip-path="url(#clip-path-18)">
          <path id="Контур_5530" data-name="Контур 5530" d="M103.395-248.414h6.582v6.1h-6.582Z" transform="translate(-0.271 491.497)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5433" data-name="Сгруппировать 5433" transform="translate(59.416 -398.311)" clip-path="url(#clip-path-837)">
        <g id="Сгруппировать_5432" data-name="Сгруппировать 5432" transform="translate(-107.6 -244.654)" clip-path="url(#clip-path-18)">
          <path id="Контур_5533" data-name="Контур 5533" d="M106.876-250.437h6.582v6.1h-6.582Z" transform="translate(-1.915 492.453)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5435" data-name="Сгруппировать 5435" transform="translate(52.31 -395.036)" clip-path="url(#clip-path-839)">
        <g id="Сгруппировать_5434" data-name="Сгруппировать 5434" transform="translate(-100.493 -247.929)" clip-path="url(#clip-path-18)">
          <path id="Контур_5536" data-name="Контур 5536" d="M93.411-244.233h6.582v6.1H93.411Z" transform="translate(4.443 489.523)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5437" data-name="Сгруппировать 5437" transform="translate(51.395 -394.506)" clip-path="url(#clip-path-841)">
        <g id="Сгруппировать_5436" data-name="Сгруппировать 5436" transform="translate(-99.578 -248.46)" clip-path="url(#clip-path-18)">
          <path id="Контур_5539" data-name="Контур 5539" d="M91.678-243.227H98.26v6.1H91.678Z" transform="translate(5.262 489.048)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5439" data-name="Сгруппировать 5439" transform="translate(54.148 -396.105)" clip-path="url(#clip-path-843)">
        <g id="Сгруппировать_5438" data-name="Сгруппировать 5438" transform="translate(-102.331 -246.86)" clip-path="url(#clip-path-18)">
          <path id="Контур_5542" data-name="Контур 5542" d="M96.893-246.257h6.581v6.1H96.893Z" transform="translate(2.799 490.479)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5441" data-name="Сгруппировать 5441" transform="translate(53.233 -395.573)" clip-path="url(#clip-path-845)">
        <g id="Сгруппировать_5440" data-name="Сгруппировать 5440" transform="translate(-101.416 -247.392)" clip-path="url(#clip-path-18)">
          <path id="Контур_5545" data-name="Контур 5545" d="M95.159-245.249h6.582v6.1H95.159Z" transform="translate(3.618 490.003)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5443" data-name="Сгруппировать 5443" transform="translate(55.984 -397.172)" clip-path="url(#clip-path-847)">
        <g id="Сгруппировать_5442" data-name="Сгруппировать 5442" transform="translate(-104.167 -245.793)" clip-path="url(#clip-path-18)">
          <path id="Контур_5548" data-name="Контур 5548" d="M100.373-248.28h6.583v6.1h-6.583Z" transform="translate(1.156 491.434)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5445" data-name="Сгруппировать 5445" transform="translate(55.07 -396.641)" clip-path="url(#clip-path-849)">
        <g id="Сгруппировать_5444" data-name="Сгруппировать 5444" transform="translate(-103.253 -246.324)" clip-path="url(#clip-path-18)">
          <path id="Контур_5551" data-name="Контур 5551" d="M98.641-247.273h6.582v6.1H98.641Z" transform="translate(1.974 490.958)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5447" data-name="Сгруппировать 5447" transform="translate(57.821 -398.241)" clip-path="url(#clip-path-851)">
        <g id="Сгруппировать_5446" data-name="Сгруппировать 5446" transform="translate(-106.005 -244.724)" clip-path="url(#clip-path-18)">
          <path id="Контур_5554" data-name="Контур 5554" d="M103.854-250.3h6.582v6.1h-6.582Z" transform="translate(-0.488 492.39)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5449" data-name="Сгруппировать 5449" transform="translate(56.907 -397.709)" clip-path="url(#clip-path-853)">
        <g id="Сгруппировать_5448" data-name="Сгруппировать 5448" transform="translate(-105.091 -245.256)" clip-path="url(#clip-path-18)">
          <path id="Контур_5557" data-name="Контур 5557" d="M102.122-249.3H108.7v6.1h-6.582Z" transform="translate(0.33 491.914)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5451" data-name="Сгруппировать 5451" transform="translate(58.745 -398.777)" clip-path="url(#clip-path-855)">
        <g id="Сгруппировать_5450" data-name="Сгруппировать 5450" transform="translate(-106.928 -244.188)" clip-path="url(#clip-path-18)">
          <path id="Контур_5560" data-name="Контур 5560" d="M105.6-251.32h6.581v6.1H105.6Z" transform="translate(-1.315 492.87)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5453" data-name="Сгруппировать 5453" transform="translate(63.585 -399.128)" clip-path="url(#clip-path-857)">
        <g id="Сгруппировать_5452" data-name="Сгруппировать 5452" transform="translate(-111.768 -243.837)" clip-path="url(#clip-path-18)">
          <path id="Контур_5563" data-name="Контур 5563" d="M114.775-251.985h6.582v6.1h-6.582Z" transform="translate(-5.645 493.184)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5455" data-name="Сгруппировать 5455" transform="translate(62.671 -398.597)" clip-path="url(#clip-path-859)">
        <g id="Сгруппировать_5454" data-name="Сгруппировать 5454" transform="translate(-110.854 -244.368)" clip-path="url(#clip-path-18)">
          <path id="Контур_5566" data-name="Контур 5566" d="M113.042-250.979h6.582v6.1h-6.582Z" transform="translate(-4.827 492.709)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5457" data-name="Сгруппировать 5457" transform="translate(64.508 -399.665)" clip-path="url(#clip-path-861)">
        <g id="Сгруппировать_5456" data-name="Сгруппировать 5456" transform="translate(-112.691 -243.301)" clip-path="url(#clip-path-18)">
          <path id="Контур_5569" data-name="Контур 5569" d="M116.523-253h6.582v6.1h-6.582Z" transform="translate(-6.471 493.664)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5459" data-name="Сгруппировать 5459" transform="translate(62.932 -399.572)" clip-path="url(#clip-path-863)">
        <g id="Сгруппировать_5458" data-name="Сгруппировать 5458" transform="translate(-111.116 -243.394)" clip-path="url(#clip-path-18)">
          <path id="Контур_5572" data-name="Контур 5572" d="M113.538-252.826h6.582v6.1h-6.582Z" transform="translate(-5.061 493.581)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5461" data-name="Сгруппировать 5461" transform="translate(62.018 -399.04)" clip-path="url(#clip-path-865)">
        <g id="Сгруппировать_5460" data-name="Сгруппировать 5460" transform="translate(-110.201 -243.926)" clip-path="url(#clip-path-18)">
          <path id="Контур_5575" data-name="Контур 5575" d="M111.8-251.818h6.582v6.1H111.8Z" transform="translate(-4.243 493.105)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5463" data-name="Сгруппировать 5463" transform="translate(63.855 -400.108)" clip-path="url(#clip-path-867)">
        <g id="Сгруппировать_5462" data-name="Сгруппировать 5462" transform="translate(-112.038 -242.857)" clip-path="url(#clip-path-18)">
          <path id="Контур_5578" data-name="Контур 5578" d="M115.286-253.842h6.582v6.1h-6.582Z" transform="translate(-5.887 494.06)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5465" data-name="Сгруппировать 5465" transform="translate(62.308 -400.008)" clip-path="url(#clip-path-869)">
        <g id="Сгруппировать_5464" data-name="Сгруппировать 5464" transform="translate(-110.491 -242.958)" clip-path="url(#clip-path-18)">
          <path id="Контур_5581" data-name="Контур 5581" d="M112.354-253.652h6.582v6.1h-6.582Z" transform="translate(-4.502 493.971)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5467" data-name="Сгруппировать 5467" transform="translate(61.392 -399.477)" clip-path="url(#clip-path-871)">
        <g id="Сгруппировать_5466" data-name="Сгруппировать 5466" transform="translate(-109.576 -243.489)" clip-path="url(#clip-path-18)">
          <path id="Контур_5584" data-name="Контур 5584" d="M110.62-252.646H117.2v6.1H110.62Z" transform="translate(-3.683 493.496)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5469" data-name="Сгруппировать 5469" transform="translate(63.23 -400.544)" clip-path="url(#clip-path-873)">
        <g id="Сгруппировать_5468" data-name="Сгруппировать 5468" transform="translate(-111.413 -242.421)" clip-path="url(#clip-path-18)">
          <path id="Контур_5587" data-name="Контур 5587" d="M114.1-254.669h6.582v6.1H114.1Z" transform="translate(-5.328 494.451)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5471" data-name="Сгруппировать 5471" transform="translate(61.635 -400.474)" clip-path="url(#clip-path-875)">
        <g id="Сгруппировать_5470" data-name="Сгруппировать 5470" transform="translate(-109.818 -242.491)" clip-path="url(#clip-path-18)">
          <path id="Контур_5590" data-name="Контур 5590" d="M111.08-254.536h6.582v6.1H111.08Z" transform="translate(-3.901 494.388)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5473" data-name="Сгруппировать 5473" transform="translate(60.721 -399.943)" clip-path="url(#clip-path-877)">
        <g id="Сгруппировать_5472" data-name="Сгруппировать 5472" transform="translate(-108.904 -243.023)" clip-path="url(#clip-path-18)">
          <path id="Контур_5593" data-name="Контур 5593" d="M109.348-253.529h6.582v6.1h-6.582Z" transform="translate(-3.083 493.913)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5475" data-name="Сгруппировать 5475" transform="translate(62.558 -401.01)" clip-path="url(#clip-path-879)">
        <g id="Сгруппировать_5474" data-name="Сгруппировать 5474" transform="translate(-110.741 -241.955)" clip-path="url(#clip-path-18)">
          <path id="Контур_5596" data-name="Контур 5596" d="M112.828-255.552h6.583v6.1h-6.583Z" transform="translate(-4.726 494.868)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5477" data-name="Сгруппировать 5477" transform="translate(48.828 -393.891)" clip-path="url(#clip-path-881)">
        <g id="Сгруппировать_5476" data-name="Сгруппировать 5476" transform="translate(-97.011 -249.074)" clip-path="url(#clip-path-18)">
          <path id="Контур_5599" data-name="Контур 5599" d="M86.813-242.063H93.4v6.1H86.813Z" transform="translate(7.559 488.498)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5479" data-name="Сгруппировать 5479" transform="translate(51.433 -392.579)" clip-path="url(#clip-path-883)">
        <g id="Сгруппировать_5478" data-name="Сгруппировать 5478" transform="translate(-99.616 -250.386)" clip-path="url(#clip-path-18)">
          <path id="Контур_5602" data-name="Контур 5602" d="M91.749-239.576h7.509v6.625H91.749Z" transform="translate(5.228 487.324)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5481" data-name="Сгруппировать 5481" transform="translate(59.772 -397.419)" clip-path="url(#clip-path-885)">
        <g id="Сгруппировать_5480" data-name="Сгруппировать 5480" transform="translate(-107.955 -245.546)" clip-path="url(#clip-path-18)">
          <path id="Контур_5605" data-name="Контур 5605" d="M107.549-248.747h7.508v6.625h-7.508Z" transform="translate(-2.233 491.654)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5483" data-name="Сгруппировать 5483" transform="translate(50.802 -393.022)" clip-path="url(#clip-path-887)">
        <g id="Сгруппировать_5482" data-name="Сгруппировать 5482" transform="translate(-98.985 -249.943)" clip-path="url(#clip-path-18)">
          <path id="Контур_5608" data-name="Контур 5608" d="M90.553-240.416h7.508v6.625H90.553Z" transform="translate(5.793 487.72)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5485" data-name="Сгруппировать 5485" transform="translate(50.175 -393.465)" clip-path="url(#clip-path-889)">
        <g id="Сгруппировать_5484" data-name="Сгруппировать 5484" transform="translate(-98.358 -249.5)" clip-path="url(#clip-path-18)">
          <path id="Контур_5611" data-name="Контур 5611" d="M89.365-241.256h7.509v6.624H89.365Z" transform="translate(6.354 488.117)" fill="#3f4747"/>
        </g>
      </g>
      <g id="Сгруппировать_5487" data-name="Сгруппировать 5487" transform="translate(119.983 -433.73)" clip-path="url(#clip-path-891)">
        <g id="Сгруппировать_5486" data-name="Сгруппировать 5486" transform="translate(-168.166 -209.235)" clip-path="url(#clip-path-18)">
          <path id="Контур_5614" data-name="Контур 5614" d="M221.635-317.549h8.92v7.788h-8.92Z" transform="translate(-56.108 524.145)" fill="#5c6463"/>
        </g>
      </g>
      <g id="Сгруппировать_5489" data-name="Сгруппировать 5489" transform="translate(119.983 -433.162)" clip-path="url(#clip-path-893)">
        <g id="Сгруппировать_5488" data-name="Сгруппировать 5488" transform="translate(-168.166 -209.803)" clip-path="url(#clip-path-18)">
          <path id="Контур_5617" data-name="Контур 5617" d="M221.635-316.472h8.92v7.219h-8.92Z" transform="translate(-56.108 523.636)" fill="#4a5250"/>
        </g>
      </g>
      <g id="Сгруппировать_5491" data-name="Сгруппировать 5491" transform="translate(65.82 -408.058)" clip-path="url(#clip-path-895)">
        <g id="Сгруппировать_5490" data-name="Сгруппировать 5490" transform="translate(-114.003 -234.908)" clip-path="url(#clip-path-198)">
          <path id="Контур_5620" data-name="Контур 5620" d="M119.009-268.9h17.667v12.424H119.009Z" transform="translate(-7.645 501.174)" fill="#afb5ca"/>
        </g>
      </g>
      <g id="Сгруппировать_5493" data-name="Сгруппировать 5493" transform="translate(70.193 -406.111)" clip-path="url(#clip-path-897)">
        <g id="Сгруппировать_5492" data-name="Сгруппировать 5492" transform="translate(-118.376 -236.854)" clip-path="url(#clip-path-18)">
          <path id="Контур_5623" data-name="Контур 5623" d="M127.295-265.217h8.92v7.787h-8.92Z" transform="translate(-11.558 499.432)" fill="#5c6463"/>
        </g>
      </g>
      <g id="Сгруппировать_5495" data-name="Сгруппировать 5495" transform="translate(70.193 -405.543)" clip-path="url(#clip-path-899)">
        <g id="Сгруппировать_5494" data-name="Сгруппировать 5494" transform="translate(-118.376 -237.422)" clip-path="url(#clip-path-18)">
          <path id="Контур_5626" data-name="Контур 5626" d="M127.295-264.141h8.92v7.219h-8.92Z" transform="translate(-11.558 498.924)" fill="#4a5250"/>
        </g>
      </g>
      <g id="Сгруппировать_5497" data-name="Сгруппировать 5497" transform="translate(107.839 -449.781)" clip-path="url(#clip-path-901)">
        <g id="Сгруппировать_5496" data-name="Сгруппировать 5496" transform="translate(-156.022 -193.184)" clip-path="url(#clip-path-18)">
          <path id="Контур_5629" data-name="Контур 5629" d="M198.626-347.961h7.5v18.138h-7.5Z" transform="translate(-45.242 538.506)" fill="#2e3636"/>
        </g>
      </g>
      <g id="Сгруппировать_5499" data-name="Сгруппировать 5499" transform="translate(107.317 -448.801)" clip-path="url(#clip-path-903)">
        <g id="Сгруппировать_5498" data-name="Сгруппировать 5498" transform="translate(-155.5 -194.164)" clip-path="url(#clip-path-18)">
          <path id="Контур_5632" data-name="Контур 5632" d="M197.636-346.105h5.8v17.158h-5.8Z" transform="translate(-44.775 537.63)" fill="#47504f"/>
        </g>
      </g>
      <g id="Сгруппировать_5501" data-name="Сгруппировать 5501" transform="translate(107.317 -450.085)" clip-path="url(#clip-path-905)">
        <g id="Сгруппировать_5500" data-name="Сгруппировать 5500" transform="translate(-155.5 -192.88)" clip-path="url(#clip-path-18)">
          <path id="Контур_5635" data-name="Контур 5635" d="M197.636-348.537h8.022v6.865h-8.022Z" transform="translate(-44.775 538.778)" fill="#6b7373"/>
        </g>
      </g>
      <g id="Сгруппировать_5503" data-name="Сгруппировать 5503" transform="translate(109.979 -437.425)" clip-path="url(#clip-path-907)">
        <g id="Сгруппировать_5502" data-name="Сгруппировать 5502" transform="translate(-158.162 -205.54)" clip-path="url(#clip-path-18)">
          <path id="Контур_5638" data-name="Контур 5638" d="M202.681-324.549h7.492v7.03h-7.492Z" transform="translate(-47.157 527.451)" fill="#2e3636"/>
        </g>
      </g>
      <g id="Сгруппировать_5505" data-name="Сгруппировать 5505" transform="translate(107.839 -437.387)" clip-path="url(#clip-path-909)">
        <g id="Сгруппировать_5504" data-name="Сгруппировать 5504" transform="translate(-156.022 -205.578)" clip-path="url(#clip-path-18)">
          <path id="Контур_5641" data-name="Контур 5641" d="M198.626-324.477h7.418v6.992h-7.418Z" transform="translate(-45.242 527.417)" fill="#47504f"/>
        </g>
      </g>
      <g id="Сгруппировать_5507" data-name="Сгруппировать 5507" transform="translate(107.839 -438.67)" clip-path="url(#clip-path-911)">
        <g id="Сгруппировать_5506" data-name="Сгруппировать 5506" transform="translate(-156.022 -204.295)" clip-path="url(#clip-path-18)">
          <path id="Контур_5644" data-name="Контур 5644" d="M198.626-326.908h9.632v7.81h-9.632Z" transform="translate(-45.242 528.565)" fill="#6b7373"/>
        </g>
      </g>
      <g id="Сгруппировать_5509" data-name="Сгруппировать 5509" transform="translate(92.625 -440.933)" clip-path="url(#clip-path-913)">
        <g id="Сгруппировать_5508" data-name="Сгруппировать 5508" transform="translate(-140.808 -202.032)" clip-path="url(#clip-path-18)">
          <path id="Контур_5647" data-name="Контур 5647" d="M169.8-331.2h7.5v18.138h-7.5Z" transform="translate(-31.629 530.59)" fill="#2e3636"/>
        </g>
      </g>
      <g id="Сгруппировать_5511" data-name="Сгруппировать 5511" transform="translate(92.102 -439.954)" clip-path="url(#clip-path-915)">
        <g id="Сгруппировать_5510" data-name="Сгруппировать 5510" transform="translate(-140.285 -203.011)" clip-path="url(#clip-path-18)">
          <path id="Контур_5650" data-name="Контур 5650" d="M168.808-329.342h5.8v17.159h-5.8Z" transform="translate(-31.161 529.714)" fill="#47504f"/>
        </g>
      </g>
      <g id="Сгруппировать_5513" data-name="Сгруппировать 5513" transform="translate(92.102 -441.238)" clip-path="url(#clip-path-917)">
        <g id="Сгруппировать_5512" data-name="Сгруппировать 5512" transform="translate(-140.285 -201.727)" clip-path="url(#clip-path-18)">
          <path id="Контур_5653" data-name="Контур 5653" d="M168.808-331.774h8.022v6.865h-8.022Z" transform="translate(-31.161 530.862)" fill="#6b7373"/>
        </g>
      </g>
      <g id="Сгруппировать_5515" data-name="Сгруппировать 5515" transform="translate(94.765 -428.577)" clip-path="url(#clip-path-919)">
        <g id="Сгруппировать_5514" data-name="Сгруппировать 5514" transform="translate(-142.948 -214.388)" clip-path="url(#clip-path-18)">
          <path id="Контур_5656" data-name="Контур 5656" d="M173.853-307.785h7.492v7.03h-7.492Z" transform="translate(-33.544 519.534)" fill="#2e3636"/>
        </g>
      </g>
      <g id="Сгруппировать_5517" data-name="Сгруппировать 5517" transform="translate(92.625 -428.539)" clip-path="url(#clip-path-921)">
        <g id="Сгруппировать_5516" data-name="Сгруппировать 5516" transform="translate(-140.808 -214.426)" clip-path="url(#clip-path-18)">
          <path id="Контур_5659" data-name="Контур 5659" d="M169.8-307.713h7.418v6.992H169.8Z" transform="translate(-31.629 519.5)" fill="#47504f"/>
        </g>
      </g>
      <g id="Сгруппировать_5519" data-name="Сгруппировать 5519" transform="translate(92.625 -429.823)" clip-path="url(#clip-path-923)">
        <g id="Сгруппировать_5518" data-name="Сгруппировать 5518" transform="translate(-140.808 -213.142)" clip-path="url(#clip-path-18)">
          <path id="Контур_5662" data-name="Контур 5662" d="M169.8-310.145h9.632v7.81H169.8Z" transform="translate(-31.629 520.648)" fill="#6b7373"/>
        </g>
      </g>
      <g id="Сгруппировать_5521" data-name="Сгруппировать 5521" transform="translate(89.89 -457.772)" clip-path="url(#clip-path-925)">
        <g id="Сгруппировать_5520" data-name="Сгруппировать 5520" transform="translate(-138.073 -185.193)" clip-path="url(#clip-path-18)">
          <path id="Контур_5665" data-name="Контур 5665" d="M164.616-363.1h27.936v34.119H164.616Z" transform="translate(-29.182 545.657)" fill="#262b2b"/>
        </g>
      </g>
      <g id="Сгруппировать_5523" data-name="Сгруппировать 5523" transform="translate(88.56 -445.462)" clip-path="url(#clip-path-927)">
        <g id="Сгруппировать_5522" data-name="Сгруппировать 5522" transform="translate(-136.743 -197.503)" clip-path="url(#clip-path-18)">
          <path id="Контур_5668" data-name="Контур 5668" d="M162.1-339.777H168.7v21.809H162.1Z" transform="translate(-27.992 534.642)" fill="#47504f"/>
        </g>
      </g>
      <g id="Сгруппировать_5525" data-name="Сгруппировать 5525" transform="translate(88.56 -458.549)" clip-path="url(#clip-path-929)">
        <g id="Сгруппировать_5524" data-name="Сгруппировать 5524" transform="translate(-136.743 -184.416)" clip-path="url(#clip-path-18)">
          <path id="Контур_5671" data-name="Контур 5671" d="M162.1-364.575h29.266v19.142H162.1Z" transform="translate(-27.992 546.352)" fill="#6b7373"/>
        </g>
      </g>
      <g id="Сгруппировать_5527" data-name="Сгруппировать 5527" transform="translate(89.89 -457.772)" clip-path="url(#clip-path-931)">
        <g id="Сгруппировать_5526" data-name="Сгруппировать 5526" transform="translate(-138.073 -185.193)" clip-path="url(#clip-path-18)">
          <path id="Контур_5674" data-name="Контур 5674" d="M164.616-363.1h27.936v34.119H164.616Z" transform="translate(-29.182 545.657)" fill="#363e3e"/>
        </g>
      </g>
      <g id="Сгруппировать_5529" data-name="Сгруппировать 5529" transform="translate(55.293 -419.892)" clip-path="url(#clip-path-933)">
        <g id="Сгруппировать_5528" data-name="Сгруппировать 5528" transform="translate(-103.476 -223.073)" clip-path="url(#clip-path-18)">
          <path id="Контур_5677" data-name="Контур 5677" d="M99.063-291.328h7.5v18.138h-7.5Z" transform="translate(1.774 511.763)" fill="#2e3636"/>
        </g>
      </g>
      <g id="Сгруппировать_5531" data-name="Сгруппировать 5531" transform="translate(54.77 -418.913)" clip-path="url(#clip-path-935)">
        <g id="Сгруппировать_5530" data-name="Сгруппировать 5530" transform="translate(-102.953 -224.052)" clip-path="url(#clip-path-18)">
          <path id="Контур_5680" data-name="Контур 5680" d="M98.072-289.473h5.8v17.159h-5.8Z" transform="translate(2.242 510.887)" fill="#47504f"/>
        </g>
      </g>
      <g id="Сгруппировать_5533" data-name="Сгруппировать 5533" transform="translate(54.77 -420.196)" clip-path="url(#clip-path-937)">
        <g id="Сгруппировать_5532" data-name="Сгруппировать 5532" transform="translate(-102.953 -222.769)" clip-path="url(#clip-path-18)">
          <path id="Контур_5683" data-name="Контур 5683" d="M98.072-291.9h8.022v6.865H98.072Z" transform="translate(2.242 512.034)" fill="#6b7373"/>
        </g>
      </g>
      <g id="Сгруппировать_5535" data-name="Сгруппировать 5535" transform="translate(57.432 -407.536)" clip-path="url(#clip-path-939)">
        <g id="Сгруппировать_5534" data-name="Сгруппировать 5534" transform="translate(-105.616 -235.43)" clip-path="url(#clip-path-18)">
          <path id="Контур_5686" data-name="Контур 5686" d="M103.117-267.916h7.492v7.031h-7.492Z" transform="translate(-0.14 500.707)" fill="#2e3636"/>
        </g>
      </g>
      <g id="Сгруппировать_5537" data-name="Сгруппировать 5537" transform="translate(55.293 -407.497)" clip-path="url(#clip-path-941)">
        <g id="Сгруппировать_5536" data-name="Сгруппировать 5536" transform="translate(-103.476 -235.468)" clip-path="url(#clip-path-18)">
          <path id="Контур_5689" data-name="Контур 5689" d="M99.063-267.843h7.417v6.992H99.063Z" transform="translate(1.774 500.672)" fill="#47504f"/>
        </g>
      </g>
      <g id="Сгруппировать_5539" data-name="Сгруппировать 5539" transform="translate(55.293 -408.781)" clip-path="url(#clip-path-943)">
        <g id="Сгруппировать_5538" data-name="Сгруппировать 5538" transform="translate(-103.476 -234.185)" clip-path="url(#clip-path-18)">
          <path id="Контур_5692" data-name="Контур 5692" d="M99.063-270.275h9.632v7.811H99.063Z" transform="translate(1.774 501.821)" fill="#6b7373"/>
        </g>
      </g>
      <g id="Сгруппировать_5541" data-name="Сгруппировать 5541" transform="translate(40.077 -411.044)" clip-path="url(#clip-path-945)">
        <g id="Сгруппировать_5540" data-name="Сгруппировать 5540" transform="translate(-88.26 -231.921)" clip-path="url(#clip-path-18)">
          <path id="Контур_5695" data-name="Контур 5695" d="M70.233-274.564h7.5v18.138h-7.5Z" transform="translate(15.389 503.846)" fill="#2e3636"/>
        </g>
      </g>
      <g id="Сгруппировать_5543" data-name="Сгруппировать 5543" transform="translate(39.555 -410.065)" clip-path="url(#clip-path-947)">
        <g id="Сгруппировать_5542" data-name="Сгруппировать 5542" transform="translate(-87.738 -232.9)" clip-path="url(#clip-path-18)">
          <path id="Контур_5698" data-name="Контур 5698" d="M69.243-272.709h5.8v17.159h-5.8Z" transform="translate(15.856 502.97)" fill="#47504f"/>
        </g>
      </g>
      <g id="Сгруппировать_5545" data-name="Сгруппировать 5545" transform="translate(39.555 -411.349)" clip-path="url(#clip-path-949)">
        <g id="Сгруппировать_5544" data-name="Сгруппировать 5544" transform="translate(-87.738 -231.616)" clip-path="url(#clip-path-18)">
          <path id="Контур_5701" data-name="Контур 5701" d="M69.243-275.141h8.022v6.866H69.243Z" transform="translate(15.856 504.119)" fill="#6b7373"/>
        </g>
      </g>
      <g id="Сгруппировать_5547" data-name="Сгруппировать 5547" transform="translate(42.218 -398.688)" clip-path="url(#clip-path-951)">
        <g id="Сгруппировать_5546" data-name="Сгруппировать 5546" transform="translate(-90.401 -244.277)" clip-path="url(#clip-path-18)">
          <path id="Контур_5704" data-name="Контур 5704" d="M74.289-251.152h7.492v7.031H74.289Z" transform="translate(13.473 492.79)" fill="#2e3636"/>
        </g>
      </g>
      <g id="Сгруппировать_5549" data-name="Сгруппировать 5549" transform="translate(40.077 -398.65)" clip-path="url(#clip-path-953)">
        <g id="Сгруппировать_5548" data-name="Сгруппировать 5548" transform="translate(-88.26 -244.316)" clip-path="url(#clip-path-18)">
          <path id="Контур_5707" data-name="Контур 5707" d="M70.233-251.079h7.418v6.992H70.233Z" transform="translate(15.389 492.756)" fill="#47504f"/>
        </g>
      </g>
      <g id="Сгруппировать_5551" data-name="Сгруппировать 5551" transform="translate(40.077 -399.933)" clip-path="url(#clip-path-955)">
        <g id="Сгруппировать_5550" data-name="Сгруппировать 5550" transform="translate(-88.26 -243.032)" clip-path="url(#clip-path-18)">
          <path id="Контур_5710" data-name="Контур 5710" d="M70.233-253.511h9.633v7.811H70.233Z" transform="translate(15.389 493.904)" fill="#6b7373"/>
        </g>
      </g>
      <g id="Сгруппировать_5553" data-name="Сгруппировать 5553" transform="translate(37.343 -427.883)" clip-path="url(#clip-path-957)">
        <g id="Сгруппировать_5552" data-name="Сгруппировать 5552" transform="translate(-85.527 -215.082)" clip-path="url(#clip-path-18)">
          <path id="Контур_5713" data-name="Контур 5713" d="M65.053-306.469H92.988v34.119H65.053Z" transform="translate(17.835 518.913)" fill="#262b2b"/>
        </g>
      </g>
      <g id="Сгруппировать_5555" data-name="Сгруппировать 5555" transform="translate(36.012 -415.573)" clip-path="url(#clip-path-959)">
        <g id="Сгруппировать_5554" data-name="Сгруппировать 5554" transform="translate(-84.196 -227.392)" clip-path="url(#clip-path-18)">
          <path id="Контур_5716" data-name="Контур 5716" d="M62.531-283.145H69.14v21.809H62.531Z" transform="translate(19.026 507.898)" fill="#47504f"/>
        </g>
      </g>
      <g id="Сгруппировать_5557" data-name="Сгруппировать 5557" transform="translate(36.012 -428.66)" clip-path="url(#clip-path-961)">
        <g id="Сгруппировать_5556" data-name="Сгруппировать 5556" transform="translate(-84.196 -214.305)" clip-path="url(#clip-path-18)">
          <path id="Контур_5719" data-name="Контур 5719" d="M62.531-307.942H91.8V-288.8H62.531Z" transform="translate(19.026 519.608)" fill="#6b7373"/>
        </g>
      </g>
      <g id="Сгруппировать_5559" data-name="Сгруппировать 5559" transform="translate(37.343 -427.883)" clip-path="url(#clip-path-963)">
        <g id="Сгруппировать_5558" data-name="Сгруппировать 5558" transform="translate(-85.527 -215.082)" clip-path="url(#clip-path-18)">
          <path id="Контур_5722" data-name="Контур 5722" d="M65.053-306.469H92.988v34.119H65.053Z" transform="translate(17.835 518.913)" fill="#363e3e"/>
        </g>
      </g>
      <g id="Сгруппировать_5561" data-name="Сгруппировать 5561" transform="translate(113.254 -457.772)" clip-path="url(#clip-path-965)">
        <g id="Сгруппировать_5560" data-name="Сгруппировать 5560" transform="translate(-161.437 -185.193)" clip-path="url(#clip-path-18)">
          <path id="Контур_5725" data-name="Контур 5725" d="M208.886-363.1H242.2v20.835H208.886Z" transform="translate(-50.088 545.657)" fill="#363e3e"/>
        </g>
      </g>
      <g id="Сгруппировать_5563" data-name="Сгруппировать 5563" transform="translate(114.418 -456.707)" clip-path="url(#clip-path-967)">
        <g id="Сгруппировать_5562" data-name="Сгруппировать 5562" transform="translate(-162.602 -186.258)" clip-path="url(#clip-path-18)">
          <path id="Контур_5728" data-name="Контур 5728" d="M211.092-361.085h30.99v18.7h-30.99Z" transform="translate(-51.129 544.704)" fill="#262b2b"/>
        </g>
      </g>
      <g id="Сгруппировать_5565" data-name="Сгруппировать 5565" transform="translate(113.254 -459.025)" clip-path="url(#clip-path-969)">
        <g id="Сгруппировать_5564" data-name="Сгруппировать 5564" transform="translate(-161.437 -183.94)" clip-path="url(#clip-path-18)">
          <path id="Контур_5731" data-name="Контур 5731" d="M208.886-365.476H242.2v6.53H208.886Z" transform="translate(-50.088 546.778)" fill="#6b7373"/>
        </g>
      </g>
      <g id="Сгруппировать_5567" data-name="Сгруппировать 5567" transform="translate(117.012 -442.215)" clip-path="url(#clip-path-971)">
        <g id="Сгруппировать_5566" data-name="Сгруппировать 5566" transform="translate(-165.196 -200.75)" clip-path="url(#clip-path-18)">
          <path id="Контур_5734" data-name="Контур 5734" d="M216.007-333.626h9.043v7.049h-9.043Z" transform="translate(-53.45 531.737)" fill="#2e3636"/>
        </g>
      </g>
      <g id="Сгруппировать_5569" data-name="Сгруппировать 5569" transform="translate(117.012 -440.444)" clip-path="url(#clip-path-973)">
        <g id="Сгруппировать_5568" data-name="Сгруппировать 5568" transform="translate(-165.196 -202.521)" clip-path="url(#clip-path-18)">
          <path id="Контур_5737" data-name="Контур 5737" d="M216.007-330.27h9.043v7.322h-9.043Z" transform="translate(-53.45 530.152)" fill="#6b7373"/>
        </g>
      </g>
      <g id="Сгруппировать_5571" data-name="Сгруппировать 5571" transform="translate(133.897 -440.444)" clip-path="url(#clip-path-975)">
        <g id="Сгруппировать_5570" data-name="Сгруппировать 5570" transform="translate(-182.08 -202.521)" clip-path="url(#clip-path-18)">
          <path id="Контур_5740" data-name="Контур 5740" d="M248-330.27h9.043v7.322H248Z" transform="translate(-68.558 530.152)" fill="#6b7373"/>
        </g>
      </g>
      <g id="Сгруппировать_5573" data-name="Сгруппировать 5573" transform="translate(117.012 -438.4)" clip-path="url(#clip-path-977)">
        <g id="Сгруппировать_5572" data-name="Сгруппировать 5572" transform="translate(-165.196 -204.566)" clip-path="url(#clip-path-18)">
          <path id="Контур_5743" data-name="Контур 5743" d="M216.007-326.4h9.043v5.764h-9.043Z" transform="translate(-53.45 528.323)" fill="#2e3636"/>
        </g>
      </g>
      <g id="Сгруппировать_5575" data-name="Сгруппировать 5575" transform="translate(133.897 -438.4)" clip-path="url(#clip-path-979)">
        <g id="Сгруппировать_5574" data-name="Сгруппировать 5574" transform="translate(-182.08 -204.566)" clip-path="url(#clip-path-18)">
          <path id="Контур_5746" data-name="Контур 5746" d="M248-326.4h9.043v5.764H248Z" transform="translate(-68.558 528.323)" fill="#2e3636"/>
        </g>
      </g>
      <g id="Сгруппировать_5577" data-name="Сгруппировать 5577" transform="translate(133.897 -442.215)" clip-path="url(#clip-path-981)">
        <g id="Сгруппировать_5576" data-name="Сгруппировать 5576" transform="translate(-182.08 -200.75)" clip-path="url(#clip-path-18)">
          <path id="Контур_5749" data-name="Контур 5749" d="M248-333.626h9.043v7.049H248Z" transform="translate(-68.558 531.737)" fill="#2e3636"/>
        </g>
      </g>
      <g id="Сгруппировать_5579" data-name="Сгруппировать 5579" transform="translate(71.958 -388.129)" clip-path="url(#clip-path-983)">
        <g id="Сгруппировать_5578" data-name="Сгруппировать 5578" transform="translate(-120.141 -254.836)" clip-path="url(#clip-path-18)">
          <path id="Контур_5752" data-name="Контур 5752" d="M130.639-231.146h19.8v14.162h-19.8Z" transform="translate(-13.137 483.343)" fill="#c8c8c5"/>
        </g>
      </g>
      <g id="Сгруппировать_5581" data-name="Сгруппировать 5581" transform="translate(71.958 -387.181)" clip-path="url(#clip-path-985)">
        <g id="Сгруппировать_5580" data-name="Сгруппировать 5580" transform="translate(-120.141 -255.785)" clip-path="url(#clip-path-18)">
          <path id="Контур_5755" data-name="Контур 5755" d="M130.639-229.348h18.889v13.213H130.639Z" transform="translate(-13.137 482.494)" fill="#adafad"/>
        </g>
      </g>
      <g id="Сгруппировать_5583" data-name="Сгруппировать 5583" transform="translate(62.824 -354.924)" clip-path="url(#clip-path-987)">
        <g id="Сгруппировать_5582" data-name="Сгруппировать 5582" transform="translate(-111.007 -288.041)" clip-path="url(#clip-path-18)">
          <path id="Контур_5758" data-name="Контур 5758" d="M113.332-168.229h8.019v8.234h-8.019Z" transform="translate(-4.964 453.631)" fill="#3a3a39"/>
        </g>
      </g>
      <g id="Сгруппировать_5585" data-name="Сгруппировать 5585" transform="translate(83.445 -355.203)" clip-path="url(#clip-path-989)">
        <g id="Сгруппировать_5584" data-name="Сгруппировать 5584" transform="translate(-131.628 -287.763)" clip-path="url(#clip-path-18)">
          <path id="Контур_5761" data-name="Контур 5761" d="M152.4-168.757h8.02v8.234H152.4Z" transform="translate(-23.415 453.881)" fill="#3a3a39"/>
        </g>
      </g>
      <g id="Сгруппировать_5587" data-name="Сгруппировать 5587" transform="translate(84.026 -366.119)" clip-path="url(#clip-path-991)">
        <g id="Сгруппировать_5586" data-name="Сгруппировать 5586" transform="translate(-132.209 -276.846)" clip-path="url(#clip-path-18)">
          <path id="Контур_5764" data-name="Контур 5764" d="M153.506-189.442h8.02v8.233h-8.02Z" transform="translate(-23.935 463.649)" fill="#3a3a39"/>
        </g>
      </g>
      <g id="Сгруппировать_5589" data-name="Сгруппировать 5589" transform="translate(61.692 -366.697)" clip-path="url(#clip-path-993)">
        <g id="Сгруппировать_5588" data-name="Сгруппировать 5588" transform="translate(-109.875 -276.268)" clip-path="url(#clip-path-18)">
          <path id="Контур_5767" data-name="Контур 5767" d="M111.188-190.536h8.021v8.234h-8.021Z" transform="translate(-3.952 464.165)" fill="#3a3a39"/>
        </g>
      </g>
      <g id="Сгруппировать_5591" data-name="Сгруппировать 5591" transform="translate(62.454 -366.697)" clip-path="url(#clip-path-995)">
        <g id="Сгруппировать_5590" data-name="Сгруппировать 5590" transform="translate(-110.637 -276.268)" clip-path="url(#clip-path-18)">
          <path id="Контур_5770" data-name="Контур 5770" d="M112.631-190.536h29.011v17.5H112.631Z" transform="translate(-4.633 464.165)" fill="#c8c8c5"/>
        </g>
      </g>
      <g id="Сгруппировать_5593" data-name="Сгруппировать 5593" transform="translate(72.576 -369.214)" clip-path="url(#clip-path-997)">
        <g id="Сгруппировать_5592" data-name="Сгруппировать 5592" transform="translate(-120.759 -273.751)" clip-path="url(#clip-path-18)">
          <path id="Контур_5773" data-name="Контур 5773" d="M131.811-195.3h8.62v11.978h-8.62Z" transform="translate(-13.69 466.418)" fill="#b3b5b3"/>
        </g>
      </g>
      <g id="Сгруппировать_5595" data-name="Сгруппировать 5595" transform="translate(62.71 -366.119)" clip-path="url(#clip-path-999)">
        <g id="Сгруппировать_5594" data-name="Сгруппировать 5594" transform="translate(-110.893 -276.846)" clip-path="url(#clip-path-18)">
          <path id="Контур_5776" data-name="Контур 5776" d="M113.116-189.442H127.26v8.7H113.116Z" transform="translate(-4.862 463.649)" fill="#a6a7a6"/>
        </g>
      </g>
      <g id="Сгруппировать_5597" data-name="Сгруппировать 5597" transform="translate(77.233 -365.991)" clip-path="url(#clip-path-1001)">
        <g id="Сгруппировать_5596" data-name="Сгруппировать 5596" transform="translate(-125.416 -276.974)" clip-path="url(#clip-path-18)">
          <path id="Контур_5779" data-name="Контур 5779" d="M140.634-189.2h14v8.576h-14Z" transform="translate(-17.857 463.534)" fill="#a6a7a6"/>
        </g>
      </g>
      <g id="Сгруппировать_5599" data-name="Сгруппировать 5599" transform="translate(75.423 -361.214)" clip-path="url(#clip-path-1003)">
        <g id="Сгруппировать_5598" data-name="Сгруппировать 5598" transform="translate(-123.606 -281.751)" clip-path="url(#clip-path-18)">
          <path id="Контур_5782" data-name="Контур 5782" d="M137.205-180.148h15.208v12.768H137.205Z" transform="translate(-16.238 459.26)" fill="#a6a7a6"/>
        </g>
      </g>
      <g id="Сгруппировать_5601" data-name="Сгруппировать 5601" transform="translate(63.848 -361.183)" clip-path="url(#clip-path-1005)">
        <g id="Сгруппировать_5600" data-name="Сгруппировать 5600" transform="translate(-112.031 -281.782)" clip-path="url(#clip-path-18)">
          <path id="Контур_5785" data-name="Контур 5785" d="M115.272-180.088h14.752v12.9H115.272Z" transform="translate(-5.88 459.232)" fill="#a6a7a6"/>
        </g>
      </g>
      <g id="Сгруппировать_5603" data-name="Сгруппировать 5603" transform="translate(73.322 -361.214)" clip-path="url(#clip-path-1007)">
        <g id="Сгруппировать_5602" data-name="Сгруппировать 5602" transform="translate(-121.505 -281.751)" clip-path="url(#clip-path-18)">
          <path id="Контур_5788" data-name="Контур 5788" d="M133.223-180.148h7.522v6.66h-7.522Z" transform="translate(-14.357 459.26)" fill="#a6a7a6"/>
        </g>
      </g>
      <g id="Сгруппировать_5605" data-name="Сгруппировать 5605" transform="translate(84.608 -355.087)" clip-path="url(#clip-path-1009)">
        <g id="Сгруппировать_5604" data-name="Сгруппировать 5604" transform="translate(-132.791 -287.878)" clip-path="url(#clip-path-18)">
          <path id="Контур_5791" data-name="Контур 5791" d="M154.608-168.538h6.857v6.64h-6.857Z" transform="translate(-24.456 453.777)" fill="#929493"/>
        </g>
      </g>
      <g id="Сгруппировать_5607" data-name="Сгруппировать 5607" transform="translate(62.454 -355.167)" clip-path="url(#clip-path-1011)">
        <g id="Сгруппировать_5606" data-name="Сгруппировать 5606" transform="translate(-110.637 -287.799)" clip-path="url(#clip-path-18)">
          <path id="Контур_5794" data-name="Контур 5794" d="M112.631-168.689h7.259v6.888h-7.259Z" transform="translate(-4.633 453.849)" fill="#929493"/>
        </g>
      </g>
      <g id="Сгруппировать_5609" data-name="Сгруппировать 5609" transform="translate(73.123 -375.491)" clip-path="url(#clip-path-1013)">
        <g id="Сгруппировать_5608" data-name="Сгруппировать 5608" transform="translate(-121.306 -267.475)" clip-path="url(#clip-path-1014)">
          <path id="Контур_5797" data-name="Контур 5797" d="M132.847-207.2h18.724V-191H132.847Z" transform="translate(-14.18 472.034)" fill="#9ba2ba"/>
        </g>
      </g>
      <g id="Сгруппировать_5611" data-name="Сгруппировать 5611" transform="translate(59.32 -375.814)" clip-path="url(#clip-path-1015)">
        <g id="Сгруппировать_5610" data-name="Сгруппировать 5610" transform="translate(-107.503 -267.151)" clip-path="url(#clip-path-1016)">
          <path id="Контур_5800" data-name="Контур 5800" d="M106.694-207.811h19.082v16.519H106.694Z" transform="translate(-1.829 472.323)" fill="#a7aec6"/>
        </g>
      </g>
      <g id="Сгруппировать_5613" data-name="Сгруппировать 5613" transform="translate(59.32 -383.546)" clip-path="url(#clip-path-1017)">
        <g id="Сгруппировать_5612" data-name="Сгруппировать 5612" transform="translate(-107.503 -259.419)" clip-path="url(#clip-path-1018)">
          <path id="Контур_5803" data-name="Контур 5803" d="M106.694-222.462H139.22v21.081H106.694Z" transform="translate(-1.829 479.242)" fill="#b6bed3"/>
        </g>
      </g>
      <g id="Сгруппировать_5615" data-name="Сгруппировать 5615" transform="translate(73.118 -389.692)" clip-path="url(#clip-path-1019)">
        <g id="Сгруппировать_5614" data-name="Сгруппировать 5614" transform="translate(-121.301 -253.273)" clip-path="url(#clip-path-200)">
          <path id="Контур_5806" data-name="Контур 5806" d="M132.838-234.107h22.6v27.227h-22.6Z" transform="translate(-14.175 484.741)" fill="#949bb2"/>
        </g>
      </g>
      <g id="Сгруппировать_5617" data-name="Сгруппировать 5617" transform="translate(70.732 -383.394)" clip-path="url(#clip-path-1021)">
        <g id="Сгруппировать_5616" data-name="Сгруппировать 5616" transform="translate(-118.915 -259.571)" clip-path="url(#clip-path-1016)">
          <path id="Контур_5809" data-name="Контур 5809" d="M128.317-222.173h11.534v20.929H128.317Z" transform="translate(-12.04 479.105)" fill="#a7aec6"/>
        </g>
      </g>
      <g id="Сгруппировать_5619" data-name="Сгруппировать 5619" transform="translate(74.603 -391.073)" clip-path="url(#clip-path-1023)">
        <g id="Сгруппировать_5618" data-name="Сгруппировать 5618" transform="translate(-122.787 -251.892)" clip-path="url(#clip-path-1024)">
          <path id="Контур_5812" data-name="Контур 5812" d="M135.652-236.723h21.114v14.331H135.652Z" transform="translate(-15.504 485.976)" fill="#a7afc7"/>
        </g>
      </g>
      <g id="Сгруппировать_5621" data-name="Сгруппировать 5621" transform="translate(58.962 -380.634)" clip-path="url(#clip-path-1025)">
        <g id="Сгруппировать_5620" data-name="Сгруппировать 5620" transform="translate(-107.145 -262.332)" clip-path="url(#clip-path-18)">
          <path id="Контур_5815" data-name="Контур 5815" d="M106.015-216.943h19.8v14.162h-19.8Z" transform="translate(-1.509 476.636)" fill="#c8c8c5"/>
        </g>
      </g>
      <g id="Сгруппировать_5623" data-name="Сгруппировать 5623" transform="translate(58.962 -379.685)" clip-path="url(#clip-path-1027)">
        <g id="Сгруппировать_5622" data-name="Сгруппировать 5622" transform="translate(-107.145 -263.28)" clip-path="url(#clip-path-18)">
          <path id="Контур_5818" data-name="Контур 5818" d="M106.015-215.145H124.9v13.213H106.015Z" transform="translate(-1.509 475.787)" fill="#adafad"/>
        </g>
      </g>
      <g id="Сгруппировать_5625" data-name="Сгруппировать 5625" transform="translate(136.471 -426.674)" clip-path="url(#clip-path-1029)">
        <g id="Сгруппировать_5624" data-name="Сгруппировать 5624" transform="translate(-184.654 -216.291)" clip-path="url(#clip-path-18)">
          <path id="Контур_5821" data-name="Контур 5821" d="M252.876-304.179h19.8v14.162h-19.8Z" transform="translate(-70.861 517.831)" fill="#c8c8c5"/>
        </g>
      </g>
      <g id="Сгруппировать_5627" data-name="Сгруппировать 5627" transform="translate(136.471 -425.725)" clip-path="url(#clip-path-1031)">
        <g id="Сгруппировать_5626" data-name="Сгруппировать 5626" transform="translate(-184.654 -217.24)" clip-path="url(#clip-path-18)">
          <path id="Контур_5824" data-name="Контур 5824" d="M252.876-302.381h18.889v13.212H252.876Z" transform="translate(-70.861 516.982)" fill="#adafad"/>
        </g>
      </g>
      <g id="Сгруппировать_5629" data-name="Сгруппировать 5629" transform="translate(127.337 -393.469)" clip-path="url(#clip-path-1033)">
        <g id="Сгруппировать_5628" data-name="Сгруппировать 5628" transform="translate(-175.52 -249.497)" clip-path="url(#clip-path-18)">
          <path id="Контур_5827" data-name="Контур 5827" d="M235.569-241.262h8.021v8.233h-8.021Z" transform="translate(-62.688 488.12)" fill="#3a3a39"/>
        </g>
      </g>
      <g id="Сгруппировать_5631" data-name="Сгруппировать 5631" transform="translate(147.958 -393.748)" clip-path="url(#clip-path-1035)">
        <g id="Сгруппировать_5630" data-name="Сгруппировать 5630" transform="translate(-196.141 -249.217)" clip-path="url(#clip-path-18)">
          <path id="Контур_5830" data-name="Контур 5830" d="M274.641-241.791h8.021v8.234h-8.021Z" transform="translate(-81.139 488.37)" fill="#3a3a39"/>
        </g>
      </g>
      <g id="Сгруппировать_5633" data-name="Сгруппировать 5633" transform="translate(148.539 -404.665)" clip-path="url(#clip-path-1037)">
        <g id="Сгруппировать_5632" data-name="Сгруппировать 5632" transform="translate(-196.722 -238.3)" clip-path="url(#clip-path-18)">
          <path id="Контур_5833" data-name="Контур 5833" d="M275.743-262.477h8.02v8.234h-8.02Z" transform="translate(-81.659 498.138)" fill="#3a3a39"/>
        </g>
      </g>
      <g id="Сгруппировать_5635" data-name="Сгруппировать 5635" transform="translate(126.206 -405.241)" clip-path="url(#clip-path-1039)">
        <g id="Сгруппировать_5634" data-name="Сгруппировать 5634" transform="translate(-174.389 -237.724)" clip-path="url(#clip-path-18)">
          <path id="Контур_5836" data-name="Контур 5836" d="M233.426-263.569h8.02v8.233h-8.02Z" transform="translate(-61.676 498.654)" fill="#3a3a39"/>
        </g>
      </g>
      <g id="Сгруппировать_5637" data-name="Сгруппировать 5637" transform="translate(126.966 -405.241)" clip-path="url(#clip-path-1041)">
        <g id="Сгруппировать_5636" data-name="Сгруппировать 5636" transform="translate(-175.149 -237.724)" clip-path="url(#clip-path-18)">
          <path id="Контур_5839" data-name="Контур 5839" d="M234.867-263.569h29.012v17.5H234.867Z" transform="translate(-62.357 498.654)" fill="#c8c8c5"/>
        </g>
      </g>
      <g id="Сгруппировать_5639" data-name="Сгруппировать 5639" transform="translate(137.09 -407.758)" clip-path="url(#clip-path-1043)">
        <g id="Сгруппировать_5638" data-name="Сгруппировать 5638" transform="translate(-185.273 -235.207)" clip-path="url(#clip-path-18)">
          <path id="Контур_5842" data-name="Контур 5842" d="M254.049-268.338h8.62v11.978h-8.62Z" transform="translate(-71.415 500.906)" fill="#b3b5b3"/>
        </g>
      </g>
      <g id="Сгруппировать_5641" data-name="Сгруппировать 5641" transform="translate(127.223 -404.665)" clip-path="url(#clip-path-1045)">
        <g id="Сгруппировать_5640" data-name="Сгруппировать 5640" transform="translate(-175.406 -238.3)" clip-path="url(#clip-path-18)">
          <path id="Контур_5845" data-name="Контур 5845" d="M235.354-262.477H249.5v8.705H235.354Z" transform="translate(-62.587 498.138)" fill="#a6a7a6"/>
        </g>
      </g>
      <g id="Сгруппировать_5643" data-name="Сгруппировать 5643" transform="translate(141.746 -404.536)" clip-path="url(#clip-path-1047)">
        <g id="Сгруппировать_5642" data-name="Сгруппировать 5642" transform="translate(-189.929 -238.429)" clip-path="url(#clip-path-18)">
          <path id="Контур_5848" data-name="Контур 5848" d="M262.871-262.233h14v8.576h-14Z" transform="translate(-75.581 498.023)" fill="#a6a7a6"/>
        </g>
      </g>
      <g id="Сгруппировать_5645" data-name="Сгруппировать 5645" transform="translate(139.936 -399.76)" clip-path="url(#clip-path-1049)">
        <g id="Сгруппировать_5644" data-name="Сгруппировать 5644" transform="translate(-188.119 -243.206)" clip-path="url(#clip-path-18)">
          <path id="Контур_5851" data-name="Контур 5851" d="M259.442-253.182H274.65v12.768H259.442Z" transform="translate(-73.962 493.749)" fill="#a6a7a6"/>
        </g>
      </g>
      <g id="Сгруппировать_5647" data-name="Сгруппировать 5647" transform="translate(128.361 -399.727)" clip-path="url(#clip-path-1051)">
        <g id="Сгруппировать_5646" data-name="Сгруппировать 5646" transform="translate(-176.544 -243.238)" clip-path="url(#clip-path-18)">
          <path id="Контур_5854" data-name="Контур 5854" d="M237.51-253.121h14.751v12.9H237.51Z" transform="translate(-63.605 493.72)" fill="#a6a7a6"/>
        </g>
      </g>
      <g id="Сгруппировать_5649" data-name="Сгруппировать 5649" transform="translate(137.834 -399.76)" clip-path="url(#clip-path-1053)">
        <g id="Сгруппировать_5648" data-name="Сгруппировать 5648" transform="translate(-186.018 -243.206)" clip-path="url(#clip-path-18)">
          <path id="Контур_5857" data-name="Контур 5857" d="M255.46-253.182h7.522v6.66H255.46Z" transform="translate(-72.081 493.749)" fill="#a6a7a6"/>
        </g>
      </g>
      <g id="Сгруппировать_5651" data-name="Сгруппировать 5651" transform="translate(149.121 -393.632)" clip-path="url(#clip-path-1055)">
        <g id="Сгруппировать_5650" data-name="Сгруппировать 5650" transform="translate(-197.305 -249.334)" clip-path="url(#clip-path-18)">
          <path id="Контур_5860" data-name="Контур 5860" d="M276.846-241.571H283.7v6.64h-6.857Z" transform="translate(-82.18 488.266)" fill="#929493"/>
        </g>
      </g>
      <g id="Сгруппировать_5653" data-name="Сгруппировать 5653" transform="translate(126.966 -393.712)" clip-path="url(#clip-path-1057)">
        <g id="Сгруппировать_5652" data-name="Сгруппировать 5652" transform="translate(-175.149 -249.253)" clip-path="url(#clip-path-18)">
          <path id="Контур_5863" data-name="Контур 5863" d="M234.867-241.724h7.259v6.889h-7.259Z" transform="translate(-62.357 488.338)" fill="#929493"/>
        </g>
      </g>
      <g id="Сгруппировать_5655" data-name="Сгруппировать 5655" transform="translate(137.636 -414.035)" clip-path="url(#clip-path-1059)">
        <g id="Сгруппировать_5654" data-name="Сгруппировать 5654" transform="translate(-185.819 -228.93)" clip-path="url(#clip-path-1014)">
          <path id="Контур_5866" data-name="Контур 5866" d="M255.084-280.231h18.724v16.2H255.084Z" transform="translate(-71.904 506.522)" fill="#9ba2ba"/>
        </g>
      </g>
      <g id="Сгруппировать_5657" data-name="Сгруппировать 5657" transform="translate(123.834 -414.359)" clip-path="url(#clip-path-1061)">
        <g id="Сгруппировать_5656" data-name="Сгруппировать 5656" transform="translate(-172.017 -228.606)" clip-path="url(#clip-path-1016)">
          <path id="Контур_5869" data-name="Контур 5869" d="M228.932-280.845h19.081v16.519H228.932Z" transform="translate(-59.554 506.812)" fill="#a7aec6"/>
        </g>
      </g>
      <g id="Сгруппировать_5659" data-name="Сгруппировать 5659" transform="translate(123.834 -422.092)" clip-path="url(#clip-path-1063)">
        <g id="Сгруппировать_5658" data-name="Сгруппировать 5658" transform="translate(-172.017 -220.874)" clip-path="url(#clip-path-1018)">
          <path id="Контур_5872" data-name="Контур 5872" d="M228.932-295.5h32.526v21.081H228.932Z" transform="translate(-59.554 513.731)" fill="#b6bed3"/>
        </g>
      </g>
      <g id="Сгруппировать_5661" data-name="Сгруппировать 5661" transform="translate(137.632 -428.237)" clip-path="url(#clip-path-1065)">
        <g id="Сгруппировать_5660" data-name="Сгруппировать 5660" transform="translate(-185.815 -214.728)" clip-path="url(#clip-path-200)">
          <path id="Контур_5875" data-name="Контур 5875" d="M255.076-307.141h22.6v27.227h-22.6Z" transform="translate(-71.9 519.23)" fill="#949bb2"/>
        </g>
      </g>
      <g id="Сгруппировать_5663" data-name="Сгруппировать 5663" transform="translate(135.246 -421.939)" clip-path="url(#clip-path-1067)">
        <g id="Сгруппировать_5662" data-name="Сгруппировать 5662" transform="translate(-183.429 -221.026)" clip-path="url(#clip-path-1016)">
          <path id="Контур_5878" data-name="Контур 5878" d="M250.555-295.207h11.534v20.928H250.555Z" transform="translate(-69.765 513.594)" fill="#a7aec6"/>
        </g>
      </g>
      <g id="Сгруппировать_5665" data-name="Сгруппировать 5665" transform="translate(139.117 -429.618)" clip-path="url(#clip-path-1069)">
        <g id="Сгруппировать_5664" data-name="Сгруппировать 5664" transform="translate(-187.301 -213.348)" clip-path="url(#clip-path-1024)">
          <path id="Контур_5881" data-name="Контур 5881" d="M257.891-309.756H279v14.33H257.891Z" transform="translate(-73.229 520.465)" fill="#a7afc7"/>
        </g>
      </g>
      <g id="Сгруппировать_5667" data-name="Сгруппировать 5667" transform="translate(123.475 -419.179)" clip-path="url(#clip-path-1071)">
        <g id="Сгруппировать_5666" data-name="Сгруппировать 5666" transform="translate(-171.658 -223.786)" clip-path="url(#clip-path-18)">
          <path id="Контур_5884" data-name="Контур 5884" d="M228.253-289.977h19.8v14.162h-19.8Z" transform="translate(-59.233 511.125)" fill="#c8c8c5"/>
        </g>
      </g>
      <g id="Сгруппировать_5669" data-name="Сгруппировать 5669" transform="translate(123.475 -418.229)" clip-path="url(#clip-path-1073)">
        <g id="Сгруппировать_5668" data-name="Сгруппировать 5668" transform="translate(-171.658 -224.736)" clip-path="url(#clip-path-18)">
          <path id="Контур_5887" data-name="Контур 5887" d="M228.253-288.178h18.889v13.213H228.253Z" transform="translate(-59.233 510.275)" fill="#adafad"/>
        </g>
      </g>
      <g id="Сгруппировать_5671" data-name="Сгруппировать 5671" transform="translate(155.11 -340.867)" clip-path="url(#clip-path-1075)">
        <g id="Сгруппировать_5670" data-name="Сгруппировать 5670" transform="translate(-203.293 -302.098)" clip-path="url(#clip-path-18)">
          <path id="Контур_5890" data-name="Контур 5890" d="M288.193-141.6h19.8v14.161h-19.8Z" transform="translate(-87.539 441.054)" fill="#c8c8c5"/>
        </g>
      </g>
      <g id="Сгруппировать_5673" data-name="Сгруппировать 5673" transform="translate(155.11 -339.918)" clip-path="url(#clip-path-1077)">
        <g id="Сгруппировать_5672" data-name="Сгруппировать 5672" transform="translate(-203.293 -303.047)" clip-path="url(#clip-path-18)">
          <path id="Контур_5893" data-name="Контур 5893" d="M288.192-139.8h18.889v13.212H288.192Z" transform="translate(-87.538 440.205)" fill="#adafad"/>
        </g>
      </g>
      <g id="Сгруппировать_5675" data-name="Сгруппировать 5675" transform="translate(145.976 -307.662)" clip-path="url(#clip-path-1079)">
        <g id="Сгруппировать_5674" data-name="Сгруппировать 5674" transform="translate(-194.159 -335.303)" clip-path="url(#clip-path-18)">
          <path id="Контур_5896" data-name="Контур 5896" d="M270.886-78.679h8.02v8.234h-8.02Z" transform="translate(-79.366 411.343)" fill="#3a3a39"/>
        </g>
      </g>
      <g id="Сгруппировать_5677" data-name="Сгруппировать 5677" transform="translate(166.597 -307.94)" clip-path="url(#clip-path-1081)">
        <g id="Сгруппировать_5676" data-name="Сгруппировать 5676" transform="translate(-214.78 -335.025)" clip-path="url(#clip-path-18)">
          <path id="Контур_5899" data-name="Контур 5899" d="M309.958-79.206h8.02v8.234h-8.02Z" transform="translate(-97.817 411.592)" fill="#3a3a39"/>
        </g>
      </g>
      <g id="Сгруппировать_5679" data-name="Сгруппировать 5679" transform="translate(167.179 -318.858)" clip-path="url(#clip-path-1083)">
        <g id="Сгруппировать_5678" data-name="Сгруппировать 5678" transform="translate(-215.362 -324.107)" clip-path="url(#clip-path-18)">
          <path id="Контур_5902" data-name="Контур 5902" d="M311.061-99.892h8.02v8.234h-8.02Z" transform="translate(-98.338 421.361)" fill="#3a3a39"/>
        </g>
      </g>
      <g id="Сгруппировать_5681" data-name="Сгруппировать 5681" transform="translate(144.844 -319.435)" clip-path="url(#clip-path-1085)">
        <g id="Сгруппировать_5680" data-name="Сгруппировать 5680" transform="translate(-193.027 -323.531)" clip-path="url(#clip-path-18)">
          <path id="Контур_5905" data-name="Контур 5905" d="M268.742-100.985h8.02v8.234h-8.02Z" transform="translate(-78.353 421.877)" fill="#3a3a39"/>
        </g>
      </g>
      <g id="Сгруппировать_5683" data-name="Сгруппировать 5683" transform="translate(145.606 -319.435)" clip-path="url(#clip-path-1087)">
        <g id="Сгруппировать_5682" data-name="Сгруппировать 5682" transform="translate(-193.789 -323.531)" clip-path="url(#clip-path-18)">
          <path id="Контур_5908" data-name="Контур 5908" d="M270.185-100.985H299.2v17.5H270.185Z" transform="translate(-79.035 421.877)" fill="#c8c8c5"/>
        </g>
      </g>
      <g id="Сгруппировать_5685" data-name="Сгруппировать 5685" transform="translate(155.728 -321.952)" clip-path="url(#clip-path-1089)">
        <g id="Сгруппировать_5684" data-name="Сгруппировать 5684" transform="translate(-203.911 -321.014)" clip-path="url(#clip-path-18)">
          <path id="Контур_5911" data-name="Контур 5911" d="M289.364-105.754h8.62v11.978h-8.62Z" transform="translate(-88.092 424.129)" fill="#b3b5b3"/>
        </g>
      </g>
      <g id="Сгруппировать_5687" data-name="Сгруппировать 5687" transform="translate(145.862 -318.858)" clip-path="url(#clip-path-1091)">
        <g id="Сгруппировать_5686" data-name="Сгруппировать 5686" transform="translate(-194.045 -324.107)" clip-path="url(#clip-path-18)">
          <path id="Контур_5914" data-name="Контур 5914" d="M270.67-99.892h14.144v8.705H270.67Z" transform="translate(-79.264 421.361)" fill="#a6a7a6"/>
        </g>
      </g>
      <g id="Сгруппировать_5689" data-name="Сгруппировать 5689" transform="translate(160.385 -318.729)" clip-path="url(#clip-path-1093)">
        <g id="Сгруппировать_5688" data-name="Сгруппировать 5688" transform="translate(-208.568 -324.236)" clip-path="url(#clip-path-18)">
          <path id="Контур_5917" data-name="Контур 5917" d="M298.188-99.648h14v8.576h-14Z" transform="translate(-92.259 421.245)" fill="#a6a7a6"/>
        </g>
      </g>
      <g id="Сгруппировать_5691" data-name="Сгруппировать 5691" transform="translate(158.575 -313.953)" clip-path="url(#clip-path-1095)">
        <g id="Сгруппировать_5690" data-name="Сгруппировать 5690" transform="translate(-206.758 -329.013)" clip-path="url(#clip-path-18)">
          <path id="Контур_5920" data-name="Контур 5920" d="M294.759-90.6h15.208V-77.83H294.759Z" transform="translate(-90.639 416.972)" fill="#a6a7a6"/>
        </g>
      </g>
      <g id="Сгруппировать_5693" data-name="Сгруппировать 5693" transform="translate(147 -313.92)" clip-path="url(#clip-path-1097)">
        <g id="Сгруппировать_5692" data-name="Сгруппировать 5692" transform="translate(-195.183 -329.045)" clip-path="url(#clip-path-18)">
          <path id="Контур_5923" data-name="Контур 5923" d="M272.826-90.537h14.752v12.9H272.826Z" transform="translate(-80.282 416.943)" fill="#a6a7a6"/>
        </g>
      </g>
      <g id="Сгруппировать_5695" data-name="Сгруппировать 5695" transform="translate(156.474 -313.953)" clip-path="url(#clip-path-1099)">
        <g id="Сгруппировать_5694" data-name="Сгруппировать 5694" transform="translate(-204.657 -329.013)" clip-path="url(#clip-path-18)">
          <path id="Контур_5926" data-name="Контур 5926" d="M290.777-90.6H298.3v6.66h-7.522Z" transform="translate(-88.759 416.972)" fill="#a6a7a6"/>
        </g>
      </g>
      <g id="Сгруппировать_5697" data-name="Сгруппировать 5697" transform="translate(167.76 -307.825)" clip-path="url(#clip-path-1101)">
        <g id="Сгруппировать_5696" data-name="Сгруппировать 5696" transform="translate(-215.943 -335.14)" clip-path="url(#clip-path-18)">
          <path id="Контур_5929" data-name="Контур 5929" d="M312.162-78.987h6.857v6.64h-6.857Z" transform="translate(-98.858 411.489)" fill="#929493"/>
        </g>
      </g>
      <g id="Сгруппировать_5699" data-name="Сгруппировать 5699" transform="translate(145.606 -307.905)" clip-path="url(#clip-path-1103)">
        <g id="Сгруппировать_5698" data-name="Сгруппировать 5698" transform="translate(-193.789 -335.06)" clip-path="url(#clip-path-18)">
          <path id="Контур_5932" data-name="Контур 5932" d="M270.185-79.139h7.258v6.888h-7.258Z" transform="translate(-79.035 411.56)" fill="#929493"/>
        </g>
      </g>
      <g id="Сгруппировать_5701" data-name="Сгруппировать 5701" transform="translate(156.275 -328.228)" clip-path="url(#clip-path-1105)">
        <g id="Сгруппировать_5700" data-name="Сгруппировать 5700" transform="translate(-204.458 -314.737)" clip-path="url(#clip-path-1014)">
          <path id="Контур_5935" data-name="Контур 5935" d="M290.4-117.647h18.724v16.2H290.4Z" transform="translate(-88.581 429.745)" fill="#9ba2ba"/>
        </g>
      </g>
      <g id="Сгруппировать_5703" data-name="Сгруппировать 5703" transform="translate(142.473 -328.552)" clip-path="url(#clip-path-1107)">
        <g id="Сгруппировать_5702" data-name="Сгруппировать 5702" transform="translate(-190.656 -314.413)" clip-path="url(#clip-path-1016)">
          <path id="Контур_5938" data-name="Контур 5938" d="M264.248-118.26h19.081v16.519H264.248Z" transform="translate(-76.231 430.035)" fill="#a7aec6"/>
        </g>
      </g>
      <g id="Сгруппировать_5705" data-name="Сгруппировать 5705" transform="translate(142.473 -336.284)" clip-path="url(#clip-path-1109)">
        <g id="Сгруппировать_5704" data-name="Сгруппировать 5704" transform="translate(-190.656 -306.681)" clip-path="url(#clip-path-1018)">
          <path id="Контур_5941" data-name="Контур 5941" d="M264.248-132.911h32.526v21.081H264.248Z" transform="translate(-76.231 436.953)" fill="#b6bed3"/>
        </g>
      </g>
      <g id="Сгруппировать_5707" data-name="Сгруппировать 5707" transform="translate(156.271 -342.431)" clip-path="url(#clip-path-1111)">
        <g id="Сгруппировать_5706" data-name="Сгруппировать 5706" transform="translate(-204.454 -300.535)" clip-path="url(#clip-path-200)">
          <path id="Контур_5944" data-name="Контур 5944" d="M290.393-144.557h22.6v27.227h-22.6Z" transform="translate(-88.578 442.453)" fill="#949bb2"/>
        </g>
      </g>
      <g id="Сгруппировать_5709" data-name="Сгруппировать 5709" transform="translate(153.884 -336.132)" clip-path="url(#clip-path-1113)">
        <g id="Сгруппировать_5708" data-name="Сгруппировать 5708" transform="translate(-202.068 -306.833)" clip-path="url(#clip-path-1016)">
          <path id="Контур_5947" data-name="Контур 5947" d="M285.871-132.623h11.535v20.929H285.871Z" transform="translate(-86.442 436.817)" fill="#a7aec6"/>
        </g>
      </g>
      <g id="Сгруппировать_5711" data-name="Сгруппировать 5711" transform="translate(157.756 -343.811)" clip-path="url(#clip-path-1115)">
        <g id="Сгруппировать_5710" data-name="Сгруппировать 5710" transform="translate(-205.939 -299.155)" clip-path="url(#clip-path-1024)">
          <path id="Контур_5950" data-name="Контур 5950" d="M293.206-147.172H314.32v14.33H293.206Z" transform="translate(-89.906 443.688)" fill="#a7afc7"/>
        </g>
      </g>
      <g id="Сгруппировать_5713" data-name="Сгруппировать 5713" transform="translate(142.114 -333.372)" clip-path="url(#clip-path-1117)">
        <g id="Сгруппировать_5712" data-name="Сгруппировать 5712" transform="translate(-190.297 -309.593)" clip-path="url(#clip-path-18)">
          <path id="Контур_5953" data-name="Контур 5953" d="M263.569-127.393h19.8v14.162h-19.8Z" transform="translate(-75.911 434.347)" fill="#c8c8c5"/>
        </g>
      </g>
      <g id="Сгруппировать_5715" data-name="Сгруппировать 5715" transform="translate(142.114 -332.422)" clip-path="url(#clip-path-1119)">
        <g id="Сгруппировать_5714" data-name="Сгруппировать 5714" transform="translate(-190.298 -310.543)" clip-path="url(#clip-path-18)">
          <path id="Контур_5956" data-name="Контур 5956" d="M263.569-125.594h18.889v13.213H263.569Z" transform="translate(-75.911 433.498)" fill="#adafad"/>
        </g>
      </g>
      <g id="Сгруппировать_5717" data-name="Сгруппировать 5717" transform="translate(219.558 -376.697)" clip-path="url(#clip-path-1121)">
        <g id="Сгруппировать_5716" data-name="Сгруппировать 5716" transform="translate(-267.741 -266.268)" clip-path="url(#clip-path-18)">
          <path id="Контур_5959" data-name="Контур 5959" d="M410.306-209.484h19.8v14.162h-19.8Z" transform="translate(-145.204 473.113)" fill="#c8c8c5"/>
        </g>
      </g>
      <g id="Сгруппировать_5719" data-name="Сгруппировать 5719" transform="translate(219.557 -375.748)" clip-path="url(#clip-path-1123)">
        <g id="Сгруппировать_5718" data-name="Сгруппировать 5718" transform="translate(-267.741 -267.217)" clip-path="url(#clip-path-18)">
          <path id="Контур_5962" data-name="Контур 5962" d="M410.306-207.686h18.889v13.213H410.306Z" transform="translate(-145.204 472.264)" fill="#adafad"/>
        </g>
      </g>
      <g id="Сгруппировать_5721" data-name="Сгруппировать 5721" transform="translate(210.423 -343.491)" clip-path="url(#clip-path-1125)">
        <g id="Сгруппировать_5720" data-name="Сгруппировать 5720" transform="translate(-258.607 -299.474)" clip-path="url(#clip-path-18)">
          <path id="Контур_5965" data-name="Контур 5965" d="M393-146.566h8.02v8.233H393Z" transform="translate(-137.031 443.401)" fill="#3a3a39"/>
        </g>
      </g>
      <g id="Сгруппировать_5723" data-name="Сгруппировать 5723" transform="translate(231.044 -343.77)" clip-path="url(#clip-path-1127)">
        <g id="Сгруппировать_5722" data-name="Сгруппировать 5722" transform="translate(-279.228 -299.195)" clip-path="url(#clip-path-18)">
          <path id="Контур_5968" data-name="Контур 5968" d="M432.071-147.1h8.021v8.234h-8.021Z" transform="translate(-155.482 443.651)" fill="#3a3a39"/>
        </g>
      </g>
      <g id="Сгруппировать_5725" data-name="Сгруппировать 5725" transform="translate(231.627 -354.687)" clip-path="url(#clip-path-1129)">
        <g id="Сгруппировать_5724" data-name="Сгруппировать 5724" transform="translate(-279.81 -288.278)" clip-path="url(#clip-path-18)">
          <path id="Контур_5971" data-name="Контур 5971" d="M433.174-167.78h8.02v8.233h-8.02Z" transform="translate(-156.003 453.419)" fill="#3a3a39"/>
        </g>
      </g>
      <g id="Сгруппировать_5727" data-name="Сгруппировать 5727" transform="translate(209.292 -355.264)" clip-path="url(#clip-path-1131)">
        <g id="Сгруппировать_5726" data-name="Сгруппировать 5726" transform="translate(-257.475 -287.701)" clip-path="url(#clip-path-18)">
          <path id="Контур_5974" data-name="Контур 5974" d="M390.855-168.874h8.021v8.234h-8.021Z" transform="translate(-136.019 453.936)" fill="#3a3a39"/>
        </g>
      </g>
      <g id="Сгруппировать_5729" data-name="Сгруппировать 5729" transform="translate(210.053 -355.264)" clip-path="url(#clip-path-1133)">
        <g id="Сгруппировать_5728" data-name="Сгруппировать 5728" transform="translate(-258.237 -287.701)" clip-path="url(#clip-path-18)">
          <path id="Контур_5977" data-name="Контур 5977" d="M392.3-168.874h29.011v17.5H392.3Z" transform="translate(-136.7 453.936)" fill="#c8c8c5"/>
        </g>
      </g>
      <g id="Сгруппировать_5731" data-name="Сгруппировать 5731" transform="translate(220.177 -357.781)" clip-path="url(#clip-path-1135)">
        <g id="Сгруппировать_5730" data-name="Сгруппировать 5730" transform="translate(-268.36 -285.184)" clip-path="url(#clip-path-18)">
          <path id="Контур_5980" data-name="Контур 5980" d="M411.479-173.643h8.62v11.979h-8.62Z" transform="translate(-145.758 456.188)" fill="#b3b5b3"/>
        </g>
      </g>
      <g id="Сгруппировать_5733" data-name="Сгруппировать 5733" transform="translate(210.31 -354.687)" clip-path="url(#clip-path-1137)">
        <g id="Сгруппировать_5732" data-name="Сгруппировать 5732" transform="translate(-258.493 -288.278)" clip-path="url(#clip-path-18)">
          <path id="Контур_5983" data-name="Контур 5983" d="M392.784-167.78h14.143v8.7H392.784Z" transform="translate(-136.93 453.419)" fill="#a6a7a6"/>
        </g>
      </g>
      <g id="Сгруппировать_5735" data-name="Сгруппировать 5735" transform="translate(224.833 -354.559)" clip-path="url(#clip-path-1139)">
        <g id="Сгруппировать_5734" data-name="Сгруппировать 5734" transform="translate(-273.016 -288.406)" clip-path="url(#clip-path-18)">
          <path id="Контур_5986" data-name="Контур 5986" d="M420.3-167.537h14v8.576h-14Z" transform="translate(-149.924 453.305)" fill="#a6a7a6"/>
        </g>
      </g>
      <g id="Сгруппировать_5737" data-name="Сгруппировать 5737" transform="translate(223.023 -349.782)" clip-path="url(#clip-path-1141)">
        <g id="Сгруппировать_5736" data-name="Сгруппировать 5736" transform="translate(-271.206 -293.183)" clip-path="url(#clip-path-18)">
          <path id="Контур_5989" data-name="Контур 5989" d="M416.872-158.486H432.08v12.768H416.872Z" transform="translate(-148.305 449.03)" fill="#a6a7a6"/>
        </g>
      </g>
      <g id="Сгруппировать_5739" data-name="Сгруппировать 5739" transform="translate(211.448 -349.75)" clip-path="url(#clip-path-1143)">
        <g id="Сгруппировать_5738" data-name="Сгруппировать 5738" transform="translate(-259.631 -293.215)" clip-path="url(#clip-path-18)">
          <path id="Контур_5992" data-name="Контур 5992" d="M394.94-158.426h14.752v12.9H394.94Z" transform="translate(-137.948 449.002)" fill="#a6a7a6"/>
        </g>
      </g>
      <g id="Сгруппировать_5741" data-name="Сгруппировать 5741" transform="translate(220.922 -349.782)" clip-path="url(#clip-path-1145)">
        <g id="Сгруппировать_5740" data-name="Сгруппировать 5740" transform="translate(-269.105 -293.183)" clip-path="url(#clip-path-18)">
          <path id="Контур_5995" data-name="Контур 5995" d="M412.891-158.486h7.522v6.66h-7.522Z" transform="translate(-146.425 449.03)" fill="#a6a7a6"/>
        </g>
      </g>
      <g id="Сгруппировать_5743" data-name="Сгруппировать 5743" transform="translate(232.208 -343.654)" clip-path="url(#clip-path-1147)">
        <g id="Сгруппировать_5742" data-name="Сгруппировать 5742" transform="translate(-280.391 -299.311)" clip-path="url(#clip-path-18)">
          <path id="Контур_5998" data-name="Контур 5998" d="M434.275-146.876h6.857v6.64h-6.857Z" transform="translate(-156.523 443.548)" fill="#929493"/>
        </g>
      </g>
      <g id="Сгруппировать_5745" data-name="Сгруппировать 5745" transform="translate(210.053 -343.734)" clip-path="url(#clip-path-1149)">
        <g id="Сгруппировать_5744" data-name="Сгруппировать 5744" transform="translate(-258.237 -299.231)" clip-path="url(#clip-path-18)">
          <path id="Контур_6001" data-name="Контур 6001" d="M392.3-147.027h7.259v6.888H392.3Z" transform="translate(-136.7 443.619)" fill="#929493"/>
        </g>
      </g>
      <g id="Сгруппировать_5747" data-name="Сгруппировать 5747" transform="translate(220.723 -364.058)" clip-path="url(#clip-path-1151)">
        <g id="Сгруппировать_5746" data-name="Сгруппировать 5746" transform="translate(-268.906 -278.907)" clip-path="url(#clip-path-1014)">
          <path id="Контур_6004" data-name="Контур 6004" d="M412.514-185.536h18.724v16.2H412.514Z" transform="translate(-146.247 461.804)" fill="#9ba2ba"/>
        </g>
      </g>
      <g id="Сгруппировать_5749" data-name="Сгруппировать 5749" transform="translate(206.921 -364.382)" clip-path="url(#clip-path-1153)">
        <g id="Сгруппировать_5748" data-name="Сгруппировать 5748" transform="translate(-255.104 -278.584)" clip-path="url(#clip-path-1016)">
          <path id="Контур_6007" data-name="Контур 6007" d="M386.362-186.149h19.081v16.519H386.362Z" transform="translate(-133.897 462.094)" fill="#a7aec6"/>
        </g>
      </g>
      <g id="Сгруппировать_5751" data-name="Сгруппировать 5751" transform="translate(206.921 -372.114)" clip-path="url(#clip-path-1155)">
        <g id="Сгруппировать_5750" data-name="Сгруппировать 5750" transform="translate(-255.104 -270.851)" clip-path="url(#clip-path-1018)">
          <path id="Контур_6010" data-name="Контур 6010" d="M386.362-200.8h32.526v21.081H386.362Z" transform="translate(-133.897 469.012)" fill="#b6bed3"/>
        </g>
      </g>
      <g id="Сгруппировать_5753" data-name="Сгруппировать 5753" transform="translate(220.719 -378.26)" clip-path="url(#clip-path-1157)">
        <g id="Сгруппировать_5752" data-name="Сгруппировать 5752" transform="translate(-268.902 -264.705)" clip-path="url(#clip-path-200)">
          <path id="Контур_6013" data-name="Контур 6013" d="M412.506-212.445h22.6v27.227h-22.6Z" transform="translate(-146.243 474.512)" fill="#949bb2"/>
        </g>
      </g>
      <g id="Сгруппировать_5755" data-name="Сгруппировать 5755" transform="translate(218.332 -371.962)" clip-path="url(#clip-path-1159)">
        <g id="Сгруппировать_5754" data-name="Сгруппировать 5754" transform="translate(-266.515 -271.003)" clip-path="url(#clip-path-1016)">
          <path id="Контур_6016" data-name="Контур 6016" d="M407.984-200.512h11.534v20.929H407.984Z" transform="translate(-144.108 468.876)" fill="#a7aec6"/>
        </g>
      </g>
      <g id="Сгруппировать_5757" data-name="Сгруппировать 5757" transform="translate(222.203 -379.64)" clip-path="url(#clip-path-1161)">
        <g id="Сгруппировать_5756" data-name="Сгруппировать 5756" transform="translate(-270.386 -263.325)" clip-path="url(#clip-path-1024)">
          <path id="Контур_6019" data-name="Контур 6019" d="M415.319-215.061h21.115v14.331H415.319Z" transform="translate(-147.571 475.747)" fill="#a7afc7"/>
        </g>
      </g>
      <g id="Сгруппировать_5759" data-name="Сгруппировать 5759" transform="translate(206.562 -369.201)" clip-path="url(#clip-path-1163)">
        <g id="Сгруппировать_5758" data-name="Сгруппировать 5758" transform="translate(-254.745 -273.764)" clip-path="url(#clip-path-18)">
          <path id="Контур_6022" data-name="Контур 6022" d="M385.683-195.281h19.8v14.162h-19.8Z" transform="translate(-133.576 466.406)" fill="#c8c8c5"/>
        </g>
      </g>
      <g id="Сгруппировать_5761" data-name="Сгруппировать 5761" transform="translate(206.562 -368.252)" clip-path="url(#clip-path-1165)">
        <g id="Сгруппировать_5760" data-name="Сгруппировать 5760" transform="translate(-254.745 -274.714)" clip-path="url(#clip-path-18)">
          <path id="Контур_6025" data-name="Контур 6025" d="M385.683-193.482h18.889v13.212H385.683Z" transform="translate(-133.577 465.557)" fill="#adafad"/>
        </g>
      </g>
      <g id="Сгруппировать_5835" data-name="Сгруппировать 5835" transform="translate(167.438 -430.438)" clip-path="url(#clip-path-1167)">
        <g id="Сгруппировать_5834" data-name="Сгруппировать 5834" transform="translate(-215.621 -212.527)" clip-path="url(#clip-path-18)">
          <path id="Контур_6136" data-name="Контур 6136" d="M311.552-311.311h14.811v12.029H311.552Z" transform="translate(-98.57 521.199)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_5837" data-name="Сгруппировать 5837" transform="translate(153.975 -438.232)" clip-path="url(#clip-path-1169)">
        <g id="Сгруппировать_5836" data-name="Сгруппировать 5836" transform="translate(-202.158 -204.733)" clip-path="url(#clip-path-18)">
          <path id="Контур_6139" data-name="Контур 6139" d="M286.043-326.079h28.274v18.558H286.043Z" transform="translate(-86.523 528.173)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5839" data-name="Сгруппировать 5839" transform="translate(153.975 -432.753)" clip-path="url(#clip-path-1171)">
        <g id="Сгруппировать_5838" data-name="Сгруппировать 5838" transform="translate(-202.158 -210.212)" clip-path="url(#clip-path-18)">
          <path id="Контур_6142" data-name="Контур 6142" d="M286.043-315.7h18.741v14.338H286.043Z" transform="translate(-86.523 523.27)" fill="#adafad"/>
        </g>
      </g>
      <g id="Сгруппировать_5841" data-name="Сгруппировать 5841" transform="translate(162.087 -433.548)" clip-path="url(#clip-path-1173)">
        <g id="Сгруппировать_5840" data-name="Сгруппировать 5840" transform="translate(-210.27 -209.417)" clip-path="url(#clip-path-18)">
          <path id="Контур_6145" data-name="Контур 6145" d="M301.413-317.2h12.05v9.188h-12.05Z" transform="translate(-93.782 523.982)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_5843" data-name="Сгруппировать 5843" transform="translate(69.081 -427.913)" clip-path="url(#clip-path-1175)">
        <g id="Сгруппировать_5842" data-name="Сгруппировать 5842" transform="translate(-117.264 -215.052)" clip-path="url(#clip-path-18)">
          <path id="Контур_6148" data-name="Контур 6148" d="M125.188-306.526h6.436V-291.6h-6.436Z" transform="translate(-10.563 518.94)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5845" data-name="Сгруппировать 5845" transform="translate(70.239 -430.628)" clip-path="url(#clip-path-1177)">
        <g id="Сгруппировать_5844" data-name="Сгруппировать 5844" transform="translate(-118.422 -212.337)" clip-path="url(#clip-path-18)">
          <path id="Контур_6151" data-name="Контур 6151" d="M127.382-311.671h11.1v17.641h-11.1Z" transform="translate(-11.599 521.369)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5847" data-name="Сгруппировать 5847" transform="translate(69.081 -431.283)" clip-path="url(#clip-path-1179)">
        <g id="Сгруппировать_5846" data-name="Сгруппировать 5846" transform="translate(-117.264 -211.682)" clip-path="url(#clip-path-18)">
          <path id="Контур_6154" data-name="Контур 6154" d="M125.188-312.912h12.26v9.313h-12.26Z" transform="translate(-10.563 521.955)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5849" data-name="Сгруппировать 5849" transform="translate(69.256 -427.143)" clip-path="url(#clip-path-1181)">
        <g id="Сгруппировать_5848" data-name="Сгруппировать 5848" transform="translate(-117.439 -215.822)" clip-path="url(#clip-path-18)">
          <path id="Контур_6157" data-name="Контур 6157" d="M125.52-305.067h6.094v8.792H125.52Z" transform="translate(-10.72 518.25)" fill="#abadab"/>
        </g>
      </g>
      <g id="Сгруппировать_5851" data-name="Сгруппировать 5851" transform="translate(70.353 -427.172)" clip-path="url(#clip-path-1183)">
        <g id="Сгруппировать_5850" data-name="Сгруппировать 5850" transform="translate(-118.536 -215.793)" clip-path="url(#clip-path-18)">
          <path id="Контур_6160" data-name="Контур 6160" d="M127.6-305.123h6.436V-290.2H127.6Z" transform="translate(-11.701 518.277)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5853" data-name="Сгруппировать 5853" transform="translate(71.51 -429.888)" clip-path="url(#clip-path-1185)">
        <g id="Сгруппировать_5852" data-name="Сгруппировать 5852" transform="translate(-119.693 -213.077)" clip-path="url(#clip-path-18)">
          <path id="Контур_6163" data-name="Контур 6163" d="M129.791-310.268h11.1v17.64h-11.1Z" transform="translate(-12.736 520.707)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5855" data-name="Сгруппировать 5855" transform="translate(70.353 -430.542)" clip-path="url(#clip-path-1187)">
        <g id="Сгруппировать_5854" data-name="Сгруппировать 5854" transform="translate(-118.536 -212.423)" clip-path="url(#clip-path-18)">
          <path id="Контур_6166" data-name="Контур 6166" d="M127.6-311.508h12.26v9.314H127.6Z" transform="translate(-11.701 521.292)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5857" data-name="Сгруппировать 5857" transform="translate(70.528 -426.402)" clip-path="url(#clip-path-1189)">
        <g id="Сгруппировать_5856" data-name="Сгруппировать 5856" transform="translate(-118.711 -216.563)" clip-path="url(#clip-path-18)">
          <path id="Контур_6169" data-name="Контур 6169" d="M127.93-303.664h6.094v8.792H127.93Z" transform="translate(-11.858 517.588)" fill="#abadab"/>
        </g>
      </g>
      <g id="Сгруппировать_5859" data-name="Сгруппировать 5859" transform="translate(71.625 -426.431)" clip-path="url(#clip-path-1191)">
        <g id="Сгруппировать_5858" data-name="Сгруппировать 5858" transform="translate(-119.808 -216.534)" clip-path="url(#clip-path-18)">
          <path id="Контур_6172" data-name="Контур 6172" d="M130.008-303.719h6.436v14.925h-6.436Z" transform="translate(-12.839 517.614)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5861" data-name="Сгруппировать 5861" transform="translate(72.783 -429.147)" clip-path="url(#clip-path-1193)">
        <g id="Сгруппировать_5860" data-name="Сгруппировать 5860" transform="translate(-120.966 -213.818)" clip-path="url(#clip-path-18)">
          <path id="Контур_6175" data-name="Контур 6175" d="M132.2-308.864h11.1v17.641H132.2Z" transform="translate(-13.875 520.044)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5863" data-name="Сгруппировать 5863" transform="translate(71.625 -429.801)" clip-path="url(#clip-path-1195)">
        <g id="Сгруппировать_5862" data-name="Сгруппировать 5862" transform="translate(-119.808 -213.164)" clip-path="url(#clip-path-18)">
          <path id="Контур_6178" data-name="Контур 6178" d="M130.008-310.1h12.26v9.313h-12.26Z" transform="translate(-12.839 520.629)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5865" data-name="Сгруппировать 5865" transform="translate(71.8 -425.661)" clip-path="url(#clip-path-1197)">
        <g id="Сгруппировать_5864" data-name="Сгруппировать 5864" transform="translate(-119.983 -217.304)" clip-path="url(#clip-path-18)">
          <path id="Контур_6181" data-name="Контур 6181" d="M130.34-302.26h6.094v8.791H130.34Z" transform="translate(-12.996 516.925)" fill="#abadab"/>
        </g>
      </g>
      <g id="Сгруппировать_5867" data-name="Сгруппировать 5867" transform="translate(74.122 -424.929)" clip-path="url(#clip-path-1199)">
        <g id="Сгруппировать_5866" data-name="Сгруппировать 5866" transform="translate(-122.305 -218.036)" clip-path="url(#clip-path-18)">
          <path id="Контур_6184" data-name="Контур 6184" d="M134.739-300.873h6.437v14.925h-6.437Z" transform="translate(-15.073 516.27)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5869" data-name="Сгруппировать 5869" transform="translate(75.28 -427.645)" clip-path="url(#clip-path-1201)">
        <g id="Сгруппировать_5868" data-name="Сгруппировать 5868" transform="translate(-123.463 -215.32)" clip-path="url(#clip-path-18)">
          <path id="Контур_6187" data-name="Контур 6187" d="M136.934-306.018h11.1v17.64h-11.1Z" transform="translate(-16.11 518.7)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5871" data-name="Сгруппировать 5871" transform="translate(74.122 -428.3)" clip-path="url(#clip-path-1203)">
        <g id="Сгруппировать_5870" data-name="Сгруппировать 5870" transform="translate(-122.305 -214.665)" clip-path="url(#clip-path-18)">
          <path id="Контур_6190" data-name="Контур 6190" d="M134.739-307.259H147v9.314h-12.26Z" transform="translate(-15.073 519.286)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5873" data-name="Сгруппировать 5873" transform="translate(74.297 -424.159)" clip-path="url(#clip-path-1205)">
        <g id="Сгруппировать_5872" data-name="Сгруппировать 5872" transform="translate(-122.48 -218.806)" clip-path="url(#clip-path-18)">
          <path id="Контур_6193" data-name="Контур 6193" d="M135.071-299.414h6.094v8.792h-6.094Z" transform="translate(-15.23 515.581)" fill="#abadab"/>
        </g>
      </g>
      <g id="Сгруппировать_5875" data-name="Сгруппировать 5875" transform="translate(75.394 -424.189)" clip-path="url(#clip-path-1207)">
        <g id="Сгруппировать_5874" data-name="Сгруппировать 5874" transform="translate(-123.577 -218.776)" clip-path="url(#clip-path-18)">
          <path id="Контур_6196" data-name="Контур 6196" d="M137.149-299.471h6.436v14.926h-6.436Z" transform="translate(-16.211 515.608)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5877" data-name="Сгруппировать 5877" transform="translate(76.552 -426.904)" clip-path="url(#clip-path-1209)">
        <g id="Сгруппировать_5876" data-name="Сгруппировать 5876" transform="translate(-124.735 -216.061)" clip-path="url(#clip-path-18)">
          <path id="Контур_6199" data-name="Контур 6199" d="M139.344-304.615h11.1v17.641h-11.1Z" transform="translate(-17.248 518.037)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5879" data-name="Сгруппировать 5879" transform="translate(75.394 -427.559)" clip-path="url(#clip-path-1211)">
        <g id="Сгруппировать_5878" data-name="Сгруппировать 5878" transform="translate(-123.577 -215.406)" clip-path="url(#clip-path-18)">
          <path id="Контур_6202" data-name="Контур 6202" d="M137.149-305.855h12.26v9.313h-12.26Z" transform="translate(-16.211 518.623)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5881" data-name="Сгруппировать 5881" transform="translate(75.569 -423.419)" clip-path="url(#clip-path-1213)">
        <g id="Сгруппировать_5880" data-name="Сгруппировать 5880" transform="translate(-123.752 -219.546)" clip-path="url(#clip-path-18)">
          <path id="Контур_6205" data-name="Контур 6205" d="M137.482-298.011h6.093v8.792h-6.093Z" transform="translate(-16.368 514.918)" fill="#abadab"/>
        </g>
      </g>
      <g id="Сгруппировать_5883" data-name="Сгруппировать 5883" transform="translate(76.667 -423.448)" clip-path="url(#clip-path-1215)">
        <g id="Сгруппировать_5882" data-name="Сгруппировать 5882" transform="translate(-124.85 -219.517)" clip-path="url(#clip-path-18)">
          <path id="Контур_6208" data-name="Контур 6208" d="M139.561-298.066H146v14.925h-6.436Z" transform="translate(-17.35 514.944)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5885" data-name="Сгруппировать 5885" transform="translate(77.824 -426.163)" clip-path="url(#clip-path-1217)">
        <g id="Сгруппировать_5884" data-name="Сгруппировать 5884" transform="translate(-126.007 -216.802)" clip-path="url(#clip-path-18)">
          <path id="Контур_6211" data-name="Контур 6211" d="M141.754-303.211h11.1v17.64h-11.1Z" transform="translate(-18.386 517.374)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5887" data-name="Сгруппировать 5887" transform="translate(76.667 -426.818)" clip-path="url(#clip-path-1219)">
        <g id="Сгруппировать_5886" data-name="Сгруппировать 5886" transform="translate(-124.85 -216.147)" clip-path="url(#clip-path-18)">
          <path id="Контур_6214" data-name="Контур 6214" d="M139.561-304.452H151.82v9.314H139.561Z" transform="translate(-17.35 517.96)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5889" data-name="Сгруппировать 5889" transform="translate(76.842 -422.678)" clip-path="url(#clip-path-1221)">
        <g id="Сгруппировать_5888" data-name="Сгруппировать 5888" transform="translate(-125.025 -220.287)" clip-path="url(#clip-path-18)">
          <path id="Контур_6217" data-name="Контур 6217" d="M139.893-296.607h6.093v8.792h-6.093Z" transform="translate(-17.507 514.255)" fill="#abadab"/>
        </g>
      </g>
      <g id="Сгруппировать_5891" data-name="Сгруппировать 5891" transform="translate(180.175 -363.409)" clip-path="url(#clip-path-1223)">
        <g id="Сгруппировать_5890" data-name="Сгруппировать 5890" transform="translate(-228.358 -279.556)" clip-path="url(#clip-path-18)">
          <path id="Контур_6220" data-name="Контур 6220" d="M335.686-184.306h6.436v14.926h-6.436Z" transform="translate(-109.966 461.223)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5893" data-name="Сгруппировать 5893" transform="translate(181.333 -366.124)" clip-path="url(#clip-path-1225)">
        <g id="Сгруппировать_5892" data-name="Сгруппировать 5892" transform="translate(-229.516 -276.841)" clip-path="url(#clip-path-18)">
          <path id="Контур_6223" data-name="Контур 6223" d="M337.879-189.45h11.1v17.641h-11.1Z" transform="translate(-111.002 463.653)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5895" data-name="Сгруппировать 5895" transform="translate(180.175 -366.779)" clip-path="url(#clip-path-1227)">
        <g id="Сгруппировать_5894" data-name="Сгруппировать 5894" transform="translate(-228.358 -276.186)" clip-path="url(#clip-path-18)">
          <path id="Контур_6226" data-name="Контур 6226" d="M335.686-190.691h12.259v9.313H335.686Z" transform="translate(-109.966 464.239)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5897" data-name="Сгруппировать 5897" transform="translate(180.351 -362.639)" clip-path="url(#clip-path-1229)">
        <g id="Сгруппировать_5896" data-name="Сгруппировать 5896" transform="translate(-228.534 -280.326)" clip-path="url(#clip-path-18)">
          <path id="Контур_6229" data-name="Контур 6229" d="M336.018-182.847h6.093v8.792h-6.093Z" transform="translate(-110.123 460.535)" fill="#abadab"/>
        </g>
      </g>
      <g id="Сгруппировать_5899" data-name="Сгруппировать 5899" transform="translate(181.447 -362.668)" clip-path="url(#clip-path-1231)">
        <g id="Сгруппировать_5898" data-name="Сгруппировать 5898" transform="translate(-229.63 -280.297)" clip-path="url(#clip-path-18)">
          <path id="Контур_6232" data-name="Контур 6232" d="M338.1-182.9h6.436v14.925H338.1Z" transform="translate(-111.104 460.56)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5901" data-name="Сгруппировать 5901" transform="translate(182.605 -365.383)" clip-path="url(#clip-path-1233)">
        <g id="Сгруппировать_5900" data-name="Сгруппировать 5900" transform="translate(-230.788 -277.582)" clip-path="url(#clip-path-18)">
          <path id="Контур_6235" data-name="Контур 6235" d="M340.289-188.047h11.1v17.64h-11.1Z" transform="translate(-112.14 462.99)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5903" data-name="Сгруппировать 5903" transform="translate(181.447 -366.038)" clip-path="url(#clip-path-1235)">
        <g id="Сгруппировать_5902" data-name="Сгруппировать 5902" transform="translate(-229.63 -276.927)" clip-path="url(#clip-path-18)">
          <path id="Контур_6238" data-name="Контур 6238" d="M338.1-189.288h12.26v9.314H338.1Z" transform="translate(-111.104 463.576)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5905" data-name="Сгруппировать 5905" transform="translate(181.622 -361.897)" clip-path="url(#clip-path-1237)">
        <g id="Сгруппировать_5904" data-name="Сгруппировать 5904" transform="translate(-229.806 -281.068)" clip-path="url(#clip-path-18)">
          <path id="Контур_6241" data-name="Контур 6241" d="M338.428-181.442h6.094v8.791h-6.094Z" transform="translate(-111.261 459.871)" fill="#abadab"/>
        </g>
      </g>
      <g id="Сгруппировать_5907" data-name="Сгруппировать 5907" transform="translate(182.719 -361.927)" clip-path="url(#clip-path-1239)">
        <g id="Сгруппировать_5906" data-name="Сгруппировать 5906" transform="translate(-230.902 -281.038)" clip-path="url(#clip-path-18)">
          <path id="Контур_6244" data-name="Контур 6244" d="M340.506-181.5h6.436v14.925h-6.436Z" transform="translate(-112.243 459.898)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5909" data-name="Сгруппировать 5909" transform="translate(183.877 -364.643)" clip-path="url(#clip-path-1241)">
        <g id="Сгруппировать_5908" data-name="Сгруппировать 5908" transform="translate(-232.06 -278.322)" clip-path="url(#clip-path-18)">
          <path id="Контур_6247" data-name="Контур 6247" d="M342.7-186.644h11.1V-169H342.7Z" transform="translate(-113.278 462.328)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5911" data-name="Сгруппировать 5911" transform="translate(182.719 -365.297)" clip-path="url(#clip-path-1243)">
        <g id="Сгруппировать_5910" data-name="Сгруппировать 5910" transform="translate(-230.902 -277.668)" clip-path="url(#clip-path-18)">
          <path id="Контур_6250" data-name="Контур 6250" d="M340.506-187.884h12.26v9.313h-12.26Z" transform="translate(-112.243 462.913)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5913" data-name="Сгруппировать 5913" transform="translate(182.895 -361.157)" clip-path="url(#clip-path-1245)">
        <g id="Сгруппировать_5912" data-name="Сгруппировать 5912" transform="translate(-231.078 -281.808)" clip-path="url(#clip-path-18)">
          <path id="Контур_6253" data-name="Контур 6253" d="M340.839-180.039h6.094v8.791h-6.094Z" transform="translate(-112.4 459.208)" fill="#abadab"/>
        </g>
      </g>
      <g id="Сгруппировать_5915" data-name="Сгруппировать 5915" transform="translate(184.457 -360.858)" clip-path="url(#clip-path-1247)">
        <g id="Сгруппировать_5914" data-name="Сгруппировать 5914" transform="translate(-232.64 -282.107)" clip-path="url(#clip-path-18)">
          <path id="Контур_6256" data-name="Контур 6256" d="M343.8-179.473h6.436v14.926H343.8Z" transform="translate(-113.798 458.941)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5917" data-name="Сгруппировать 5917" transform="translate(185.615 -363.573)" clip-path="url(#clip-path-1249)">
        <g id="Сгруппировать_5916" data-name="Сгруппировать 5916" transform="translate(-233.798 -279.392)" clip-path="url(#clip-path-18)">
          <path id="Контур_6259" data-name="Контур 6259" d="M345.992-184.617h11.1v17.641h-11.1Z" transform="translate(-114.833 461.37)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5919" data-name="Сгруппировать 5919" transform="translate(184.457 -364.227)" clip-path="url(#clip-path-1251)">
        <g id="Сгруппировать_5918" data-name="Сгруппировать 5918" transform="translate(-232.64 -278.738)" clip-path="url(#clip-path-18)">
          <path id="Контур_6262" data-name="Контур 6262" d="M343.8-185.857h12.26v9.313H343.8Z" transform="translate(-113.798 461.956)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5921" data-name="Сгруппировать 5921" transform="translate(184.632 -360.088)" clip-path="url(#clip-path-1253)">
        <g id="Сгруппировать_5920" data-name="Сгруппировать 5920" transform="translate(-232.815 -282.878)" clip-path="url(#clip-path-18)">
          <path id="Контур_6265" data-name="Контур 6265" d="M344.131-178.013h6.094v8.792h-6.094Z" transform="translate(-113.954 458.252)" fill="#abadab"/>
        </g>
      </g>
      <g id="Сгруппировать_5923" data-name="Сгруппировать 5923" transform="translate(185.729 -360.117)" clip-path="url(#clip-path-1255)">
        <g id="Сгруппировать_5922" data-name="Сгруппировать 5922" transform="translate(-233.912 -282.848)" clip-path="url(#clip-path-18)">
          <path id="Контур_6268" data-name="Контур 6268" d="M346.209-178.069h6.436v14.925h-6.436Z" transform="translate(-114.936 458.278)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5925" data-name="Сгруппировать 5925" transform="translate(186.886 -362.833)" clip-path="url(#clip-path-1257)">
        <g id="Сгруппировать_5924" data-name="Сгруппировать 5924" transform="translate(-235.07 -280.133)" clip-path="url(#clip-path-18)">
          <path id="Контур_6271" data-name="Контур 6271" d="M348.4-183.214h11.1v17.641H348.4Z" transform="translate(-115.971 460.708)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5927" data-name="Сгруппировать 5927" transform="translate(185.729 -363.487)" clip-path="url(#clip-path-1259)">
        <g id="Сгруппировать_5926" data-name="Сгруппировать 5926" transform="translate(-233.912 -279.478)" clip-path="url(#clip-path-18)">
          <path id="Контур_6274" data-name="Контур 6274" d="M346.209-184.454h12.26v9.313h-12.26Z" transform="translate(-114.936 461.293)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5929" data-name="Сгруппировать 5929" transform="translate(185.904 -359.347)" clip-path="url(#clip-path-1261)">
        <g id="Сгруппировать_5928" data-name="Сгруппировать 5928" transform="translate(-234.087 -283.619)" clip-path="url(#clip-path-18)">
          <path id="Контур_6277" data-name="Контур 6277" d="M346.541-176.609h6.094v8.791h-6.094Z" transform="translate(-115.092 457.589)" fill="#abadab"/>
        </g>
      </g>
      <g id="Сгруппировать_5931" data-name="Сгруппировать 5931" transform="translate(187.001 -359.377)" clip-path="url(#clip-path-1263)">
        <g id="Сгруппировать_5930" data-name="Сгруппировать 5930" transform="translate(-235.184 -283.588)" clip-path="url(#clip-path-18)">
          <path id="Контур_6280" data-name="Контур 6280" d="M348.619-176.666h6.436v14.925h-6.436Z" transform="translate(-116.074 457.616)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5933" data-name="Сгруппировать 5933" transform="translate(188.159 -362.092)" clip-path="url(#clip-path-1265)">
        <g id="Сгруппировать_5932" data-name="Сгруппировать 5932" transform="translate(-236.342 -280.873)" clip-path="url(#clip-path-18)">
          <path id="Контур_6283" data-name="Контур 6283" d="M350.813-181.811h11.1v17.641h-11.1Z" transform="translate(-117.11 460.045)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5935" data-name="Сгруппировать 5935" transform="translate(187.001 -362.746)" clip-path="url(#clip-path-1267)">
        <g id="Сгруппировать_5934" data-name="Сгруппировать 5934" transform="translate(-235.184 -280.219)" clip-path="url(#clip-path-18)">
          <path id="Контур_6286" data-name="Контур 6286" d="M348.619-183.051H360.88v9.314H348.619Z" transform="translate(-116.074 460.631)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5937" data-name="Сгруппировать 5937" transform="translate(187.176 -358.606)" clip-path="url(#clip-path-1269)">
        <g id="Сгруппировать_5936" data-name="Сгруппировать 5936" transform="translate(-235.359 -284.359)" clip-path="url(#clip-path-18)">
          <path id="Контур_6289" data-name="Контур 6289" d="M348.951-175.206h6.094v8.792h-6.094Z" transform="translate(-116.231 456.926)" fill="#abadab"/>
        </g>
      </g>
      <g id="Сгруппировать_5939" data-name="Сгруппировать 5939" transform="translate(188.739 -358.307)" clip-path="url(#clip-path-1271)">
        <g id="Сгруппировать_5938" data-name="Сгруппировать 5938" transform="translate(-236.922 -284.658)" clip-path="url(#clip-path-18)">
          <path id="Контур_6292" data-name="Контур 6292" d="M351.912-174.639h6.436v14.925h-6.436Z" transform="translate(-117.629 456.658)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5941" data-name="Сгруппировать 5941" transform="translate(189.896 -361.022)" clip-path="url(#clip-path-1273)">
        <g id="Сгруппировать_5940" data-name="Сгруппировать 5940" transform="translate(-238.079 -281.943)" clip-path="url(#clip-path-18)">
          <path id="Контур_6295" data-name="Контур 6295" d="M354.1-179.783h11.1v17.64H354.1Z" transform="translate(-118.664 459.088)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5943" data-name="Сгруппировать 5943" transform="translate(188.739 -361.677)" clip-path="url(#clip-path-1275)">
        <g id="Сгруппировать_5942" data-name="Сгруппировать 5942" transform="translate(-236.922 -281.288)" clip-path="url(#clip-path-18)">
          <path id="Контур_6298" data-name="Контур 6298" d="M351.912-181.024h12.261v9.313H351.912Z" transform="translate(-117.629 459.674)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5945" data-name="Сгруппировать 5945" transform="translate(188.914 -357.537)" clip-path="url(#clip-path-1277)">
        <g id="Сгруппировать_5944" data-name="Сгруппировать 5944" transform="translate(-237.097 -285.428)" clip-path="url(#clip-path-18)">
          <path id="Контур_6301" data-name="Контур 6301" d="M352.244-173.18h6.094v8.792h-6.094Z" transform="translate(-117.786 455.969)" fill="#abadab"/>
        </g>
      </g>
      <g id="Сгруппировать_5947" data-name="Сгруппировать 5947" transform="translate(190.011 -357.566)" clip-path="url(#clip-path-1279)">
        <g id="Сгруппировать_5946" data-name="Сгруппировать 5946" transform="translate(-238.194 -285.399)" clip-path="url(#clip-path-18)">
          <path id="Контур_6304" data-name="Контур 6304" d="M354.322-173.235h6.437v14.925h-6.437Z" transform="translate(-118.767 455.995)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5949" data-name="Сгруппировать 5949" transform="translate(191.169 -360.281)" clip-path="url(#clip-path-1281)">
        <g id="Сгруппировать_5948" data-name="Сгруппировать 5948" transform="translate(-239.352 -282.684)" clip-path="url(#clip-path-18)">
          <path id="Контур_6307" data-name="Контур 6307" d="M356.517-178.38h11.1v17.641h-11.1Z" transform="translate(-119.803 458.425)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5951" data-name="Сгруппировать 5951" transform="translate(190.011 -360.936)" clip-path="url(#clip-path-1283)">
        <g id="Сгруппировать_5950" data-name="Сгруппировать 5950" transform="translate(-238.194 -282.029)" clip-path="url(#clip-path-18)">
          <path id="Контур_6310" data-name="Контур 6310" d="M354.322-179.621h12.26v9.313h-12.26Z" transform="translate(-118.767 459.011)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5953" data-name="Сгруппировать 5953" transform="translate(190.187 -356.795)" clip-path="url(#clip-path-1285)">
        <g id="Сгруппировать_5952" data-name="Сгруппировать 5952" transform="translate(-238.37 -286.17)" clip-path="url(#clip-path-18)">
          <path id="Контур_6313" data-name="Контур 6313" d="M354.655-171.775h6.093v8.791h-6.093Z" transform="translate(-118.924 455.306)" fill="#abadab"/>
        </g>
      </g>
      <g id="Сгруппировать_5955" data-name="Сгруппировать 5955" transform="translate(191.283 -356.825)" clip-path="url(#clip-path-1287)">
        <g id="Сгруппировать_5954" data-name="Сгруппировать 5954" transform="translate(-239.466 -286.14)" clip-path="url(#clip-path-18)">
          <path id="Контур_6316" data-name="Контур 6316" d="M356.732-171.832h6.437v14.925h-6.437Z" transform="translate(-119.905 455.333)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5957" data-name="Сгруппировать 5957" transform="translate(192.441 -359.541)" clip-path="url(#clip-path-1289)">
        <g id="Сгруппировать_5956" data-name="Сгруппировать 5956" transform="translate(-240.624 -283.424)" clip-path="url(#clip-path-18)">
          <path id="Контур_6319" data-name="Контур 6319" d="M358.926-176.977h11.1v17.641h-11.1Z" transform="translate(-120.941 457.763)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5959" data-name="Сгруппировать 5959" transform="translate(191.283 -360.195)" clip-path="url(#clip-path-1291)">
        <g id="Сгруппировать_5958" data-name="Сгруппировать 5958" transform="translate(-239.466 -282.77)" clip-path="url(#clip-path-18)">
          <path id="Контур_6322" data-name="Контур 6322" d="M356.732-178.217h12.26v9.314h-12.26Z" transform="translate(-119.905 458.348)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5961" data-name="Сгруппировать 5961" transform="translate(191.458 -356.055)" clip-path="url(#clip-path-1293)">
        <g id="Сгруппировать_5960" data-name="Сгруппировать 5960" transform="translate(-239.642 -286.91)" clip-path="url(#clip-path-18)">
          <path id="Контур_6325" data-name="Контур 6325" d="M357.065-170.373h6.094v8.792h-6.094Z" transform="translate(-120.062 454.644)" fill="#abadab"/>
        </g>
      </g>
      <g id="Сгруппировать_5963" data-name="Сгруппировать 5963" transform="translate(193.021 -355.756)" clip-path="url(#clip-path-1295)">
        <g id="Сгруппировать_5962" data-name="Сгруппировать 5962" transform="translate(-241.204 -287.209)" clip-path="url(#clip-path-18)">
          <path id="Контур_6328" data-name="Контур 6328" d="M360.025-169.806h6.436v14.926h-6.436Z" transform="translate(-121.46 454.376)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5965" data-name="Сгруппировать 5965" transform="translate(194.179 -358.471)" clip-path="url(#clip-path-1297)">
        <g id="Сгруппировать_5964" data-name="Сгруппировать 5964" transform="translate(-242.362 -284.494)" clip-path="url(#clip-path-18)">
          <path id="Контур_6331" data-name="Контур 6331" d="M362.219-174.95h11.1v17.641h-11.1Z" transform="translate(-122.496 456.805)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5967" data-name="Сгруппировать 5967" transform="translate(193.021 -359.125)" clip-path="url(#clip-path-1299)">
        <g id="Сгруппировать_5966" data-name="Сгруппировать 5966" transform="translate(-241.204 -283.84)" clip-path="url(#clip-path-18)">
          <path id="Контур_6334" data-name="Контур 6334" d="M360.025-176.19h12.26v9.313h-12.26Z" transform="translate(-121.46 457.391)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5969" data-name="Сгруппировать 5969" transform="translate(193.196 -354.986)" clip-path="url(#clip-path-1301)">
        <g id="Сгруппировать_5968" data-name="Сгруппировать 5968" transform="translate(-241.379 -287.98)" clip-path="url(#clip-path-18)">
          <path id="Контур_6337" data-name="Контур 6337" d="M360.357-168.346h6.094v8.792h-6.094Z" transform="translate(-121.617 453.687)" fill="#abadab"/>
        </g>
      </g>
      <g id="Сгруппировать_5971" data-name="Сгруппировать 5971" transform="translate(194.293 -355.015)" clip-path="url(#clip-path-1303)">
        <g id="Сгруппировать_5970" data-name="Сгруппировать 5970" transform="translate(-242.476 -287.95)" clip-path="url(#clip-path-18)">
          <path id="Контур_6340" data-name="Контур 6340" d="M362.436-168.4h6.436v14.925h-6.436Z" transform="translate(-122.599 453.713)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5973" data-name="Сгруппировать 5973" transform="translate(195.451 -357.731)" clip-path="url(#clip-path-1305)">
        <g id="Сгруппировать_5972" data-name="Сгруппировать 5972" transform="translate(-243.634 -285.235)" clip-path="url(#clip-path-18)">
          <path id="Контур_6343" data-name="Контур 6343" d="M364.629-173.547h11.1v17.641h-11.1Z" transform="translate(-123.634 456.143)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5975" data-name="Сгруппировать 5975" transform="translate(194.293 -358.385)" clip-path="url(#clip-path-1307)">
        <g id="Сгруппировать_5974" data-name="Сгруппировать 5974" transform="translate(-242.476 -284.58)" clip-path="url(#clip-path-18)">
          <path id="Контур_6346" data-name="Контур 6346" d="M362.436-174.787H374.7v9.313h-12.26Z" transform="translate(-122.599 456.728)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5977" data-name="Сгруппировать 5977" transform="translate(194.468 -354.245)" clip-path="url(#clip-path-1309)">
        <g id="Сгруппировать_5976" data-name="Сгруппировать 5976" transform="translate(-242.652 -288.72)" clip-path="url(#clip-path-18)">
          <path id="Контур_6349" data-name="Контур 6349" d="M362.768-166.942h6.093v8.791h-6.093Z" transform="translate(-122.755 453.024)" fill="#abadab"/>
        </g>
      </g>
      <g id="Сгруппировать_5979" data-name="Сгруппировать 5979" transform="translate(195.565 -354.274)" clip-path="url(#clip-path-1311)">
        <g id="Сгруппировать_5978" data-name="Сгруппировать 5978" transform="translate(-243.748 -288.691)" clip-path="url(#clip-path-18)">
          <path id="Контур_6352" data-name="Контур 6352" d="M364.846-167h6.436v14.925h-6.436Z" transform="translate(-123.737 453.05)" fill="#333432"/>
        </g>
      </g>
      <g id="Сгруппировать_5981" data-name="Сгруппировать 5981" transform="translate(196.723 -356.99)" clip-path="url(#clip-path-1313)">
        <g id="Сгруппировать_5980" data-name="Сгруппировать 5980" transform="translate(-244.906 -285.976)" clip-path="url(#clip-path-18)">
          <path id="Контур_6355" data-name="Контур 6355" d="M367.04-172.143h11.1v17.64h-11.1Z" transform="translate(-124.773 455.48)" fill="#4e4f4c"/>
        </g>
      </g>
      <g id="Сгруппировать_5983" data-name="Сгруппировать 5983" transform="translate(195.565 -357.645)" clip-path="url(#clip-path-1315)">
        <g id="Сгруппировать_5982" data-name="Сгруппировать 5982" transform="translate(-243.748 -285.321)" clip-path="url(#clip-path-18)">
          <path id="Контур_6358" data-name="Контур 6358" d="M364.846-173.384h12.26v9.314h-12.26Z" transform="translate(-123.737 456.066)" fill="#6e6f6b"/>
        </g>
      </g>
      <g id="Сгруппировать_5985" data-name="Сгруппировать 5985" transform="translate(195.74 -353.504)" clip-path="url(#clip-path-1317)">
        <g id="Сгруппировать_5984" data-name="Сгруппировать 5984" transform="translate(-243.923 -289.461)" clip-path="url(#clip-path-18)">
          <path id="Контур_6361" data-name="Контур 6361" d="M365.178-165.539h6.094v8.792h-6.094Z" transform="translate(-123.893 452.361)" fill="#abadab"/>
        </g>
      </g>
      <g id="Сгруппировать_5987" data-name="Сгруппировать 5987" transform="translate(226.132 -414.374)" clip-path="url(#clip-path-1319)">
        <g id="Сгруппировать_5986" data-name="Сгруппировать 5986" transform="translate(-274.316 -228.591)" clip-path="url(#clip-path-18)">
          <path id="Контур_6364" data-name="Контур 6364" d="M422.764-280.873h8.949v12.2h-8.949Z" transform="translate(-151.087 506.825)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_5996" data-name="Сгруппировать 5996" transform="translate(222.682 -416.336)" clip-path="url(#clip-path-1321)">
        <g id="Сгруппировать_5995" data-name="Сгруппировать 5995" transform="translate(-270.866 -226.63)" clip-path="url(#clip-path-18)">
          <g id="Сгруппировать_5994" data-name="Сгруппировать 5994" transform="translate(270.746 226.413)" style="isolation: isolate">
            <g id="Сгруппировать_5993" data-name="Сгруппировать 5993" transform="translate(0 0)" clip-path="url(#clip-path-1323)">
              <g id="Сгруппировать_5992" data-name="Сгруппировать 5992" transform="translate(0.12 0.216)" clip-path="url(#clip-path-1321)">
                <g id="Сгруппировать_5991" data-name="Сгруппировать 5991" transform="translate(-0.12 -0.216)" clip-path="url(#clip-path-1325)">
                  <g id="Сгруппировать_5989" data-name="Сгруппировать 5989" transform="translate(-222.191 -153.053)" opacity="0">
                    <g id="Сгруппировать_5988" data-name="Сгруппировать 5988">
                      <path id="Контур_6367" data-name="Контур 6367" d="M-421.227-290.41H-85.038V10.419H-421.227Z" transform="translate(421.227 290.41)" fill="url(#linear-gradient)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_5990" data-name="Сгруппировать 5990" transform="translate(-222.191 -153.053)">
                    <path id="Контур_6368" data-name="Контур 6368" d="M-421.227-290.41H-85.038V10.419H-421.227Z" transform="translate(421.227 290.41)" fill="url(#linear-gradient-2)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_5998" data-name="Сгруппировать 5998" transform="translate(222.682 -414.231)" clip-path="url(#clip-path-1326)">
        <g id="Сгруппировать_5997" data-name="Сгруппировать 5997" transform="translate(-270.866 -228.734)" clip-path="url(#clip-path-18)">
          <path id="Контур_6373" data-name="Контур 6373" d="M416.227-280.6h8.728v12.058h-8.728Z" transform="translate(-148 506.697)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_6000" data-name="Сгруппировать 6000" transform="translate(222.682 -416.336)" clip-path="url(#clip-path-1328)">
        <g id="Сгруппировать_5999" data-name="Сгруппировать 5999" transform="translate(-270.866 -226.63)" clip-path="url(#clip-path-18)">
          <path id="Контур_6376" data-name="Контур 6376" d="M416.227-284.59h12.4v9.378h-12.4Z" transform="translate(-148 508.581)" fill="#f4f3f6"/>
        </g>
      </g>
      <g id="Сгруппировать_6002" data-name="Сгруппировать 6002" transform="translate(226.135 -416.646)" clip-path="url(#clip-path-1330)">
        <g id="Сгруппировать_6001" data-name="Сгруппировать 6001" transform="translate(-274.318 -226.319)" clip-path="url(#clip-path-18)">
          <path id="Контур_6379" data-name="Контур 6379" d="M422.768-285.178h6.223v8.112h-6.223Z" transform="translate(-151.089 508.858)" fill="#724e38"/>
        </g>
      </g>
      <g id="Сгруппировать_6004" data-name="Сгруппировать 6004" transform="translate(225.185 -416.651)" clip-path="url(#clip-path-1332)">
        <g id="Сгруппировать_6003" data-name="Сгруппировать 6003" transform="translate(-273.368 -226.314)" clip-path="url(#clip-path-18)">
          <path id="Контур_6382" data-name="Контур 6382" d="M420.969-285.187H427.2v8.116h-6.227Z" transform="translate(-150.24 508.863)" fill="#64402e"/>
        </g>
      </g>
      <g id="Сгруппировать_6006" data-name="Сгруппировать 6006" transform="translate(226.139 -420.058)" clip-path="url(#clip-path-1334)">
        <g id="Сгруппировать_6005" data-name="Сгруппировать 6005" transform="translate(-274.322 -222.907)" clip-path="url(#clip-path-18)">
          <path id="Контур_6385" data-name="Контур 6385" d="M422.776-291.643h7.849v10.764h-7.849Z" transform="translate(-151.093 511.911)" fill="#78b36f"/>
        </g>
      </g>
      <g id="Сгруппировать_6008" data-name="Сгруппировать 6008" transform="translate(223.554 -421.55)" clip-path="url(#clip-path-1336)">
        <g id="Сгруппировать_6007" data-name="Сгруппировать 6007" transform="translate(-271.737 -221.416)" clip-path="url(#clip-path-18)">
          <path id="Контур_6388" data-name="Контур 6388" d="M417.879-294.469h10.434v8.25H417.879Z" transform="translate(-148.78 513.246)" fill="#82ba79"/>
        </g>
      </g>
      <g id="Сгруппировать_6010" data-name="Сгруппировать 6010" transform="translate(223.554 -420.071)" clip-path="url(#clip-path-1338)">
        <g id="Сгруппировать_6009" data-name="Сгруппировать 6009" transform="translate(-271.737 -222.894)" clip-path="url(#clip-path-18)">
          <path id="Контур_6391" data-name="Контур 6391" d="M417.879-291.668h7.862v10.775h-7.862Z" transform="translate(-148.78 511.923)" fill="#4a9463"/>
        </g>
      </g>
      <g id="Сгруппировать_6108" data-name="Сгруппировать 6108" transform="translate(234.36 -435.267)" clip-path="url(#clip-path-1340)">
        <g id="Сгруппировать_6107" data-name="Сгруппировать 6107" transform="translate(-282.544 -207.698)" clip-path="url(#clip-path-18)">
          <path id="Контур_6538" data-name="Контур 6538" d="M438.354-320.461h34.18v36.4h-34.18Z" transform="translate(-158.449 525.52)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_6110" data-name="Сгруппировать 6110" transform="translate(263.262 -423.904)" clip-path="url(#clip-path-1342)">
        <g id="Сгруппировать_6109" data-name="Сгруппировать 6109" transform="translate(-311.445 -219.061)" clip-path="url(#clip-path-18)">
          <path id="Контур_6541" data-name="Контур 6541" d="M493.115-298.93h14.416v25.041H493.115Z" transform="translate(-184.309 515.352)" fill="#9a794f"/>
        </g>
      </g>
      <g id="Сгруппировать_6112" data-name="Сгруппировать 6112" transform="translate(234.36 -440.565)" clip-path="url(#clip-path-1344)">
        <g id="Сгруппировать_6111" data-name="Сгруппировать 6111" transform="translate(-282.544 -202.4)" clip-path="url(#clip-path-18)">
          <path id="Контур_6544" data-name="Контур 6544" d="M438.354-330.5h43.317v27.256H438.354Z" transform="translate(-158.449 530.26)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_6114" data-name="Сгруппировать 6114" transform="translate(235.248 -433.812)" clip-path="url(#clip-path-1346)">
        <g id="Сгруппировать_6113" data-name="Сгруппировать 6113" transform="translate(-283.431 -209.153)" clip-path="url(#clip-path-18)">
          <path id="Контур_6547" data-name="Контур 6547" d="M440.036-317.7h32.4v33.493h-32.4Z" transform="translate(-159.244 524.218)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_6116" data-name="Сгруппировать 6116" transform="translate(235.248 -433.812)" clip-path="url(#clip-path-1348)">
        <g id="Сгруппировать_6115" data-name="Сгруппировать 6115" transform="translate(-283.431 -209.153)" clip-path="url(#clip-path-18)">
          <path id="Контур_6550" data-name="Контур 6550" d="M440.036-317.7h13.679v17.766H440.036Z" transform="translate(-159.244 524.218)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_6118" data-name="Сгруппировать 6118" transform="translate(243.649 -428.967)" clip-path="url(#clip-path-1350)">
        <g id="Сгруппировать_6117" data-name="Сгруппировать 6117" transform="translate(-291.832 -213.998)" clip-path="url(#clip-path-18)">
          <path id="Контур_6553" data-name="Контур 6553" d="M455.954-308.523h24V-289.6h-24Z" transform="translate(-166.761 519.883)" fill="#363e3e"/>
        </g>
      </g>
      <g id="Сгруппировать_6120" data-name="Сгруппировать 6120" transform="translate(262.366 -419.103)" clip-path="url(#clip-path-1352)">
        <g id="Сгруппировать_6119" data-name="Сгруппировать 6119" transform="translate(-310.549 -223.862)" clip-path="url(#clip-path-18)">
          <path id="Контур_6556" data-name="Контур 6556" d="M491.418-289.834H525.6v36.4H491.418Z" transform="translate(-183.508 511.057)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_6122" data-name="Сгруппировать 6122" transform="translate(291.268 -407.739)" clip-path="url(#clip-path-1354)">
        <g id="Сгруппировать_6121" data-name="Сгруппировать 6121" transform="translate(-339.451 -235.226)" clip-path="url(#clip-path-18)">
          <path id="Контур_6559" data-name="Контур 6559" d="M546.181-268.3H560.6v25.041H546.181Z" transform="translate(-209.369 500.889)" fill="#9a794f"/>
        </g>
      </g>
      <g id="Сгруппировать_6124" data-name="Сгруппировать 6124" transform="translate(262.366 -424.401)" clip-path="url(#clip-path-1356)">
        <g id="Сгруппировать_6123" data-name="Сгруппировать 6123" transform="translate(-310.549 -218.565)" clip-path="url(#clip-path-18)">
          <path id="Контур_6562" data-name="Контур 6562" d="M491.418-299.871h43.318v27.256H491.418Z" transform="translate(-183.508 515.797)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_6126" data-name="Сгруппировать 6126" transform="translate(263.255 -417.648)" clip-path="url(#clip-path-1358)">
        <g id="Сгруппировать_6125" data-name="Сгруппировать 6125" transform="translate(-311.438 -225.317)" clip-path="url(#clip-path-18)">
          <path id="Контур_6565" data-name="Контур 6565" d="M493.1-287.076h32.4v33.493H493.1Z" transform="translate(-184.303 509.755)" fill="#d9ba8b"/>
        </g>
      </g>
      <g id="Сгруппировать_6128" data-name="Сгруппировать 6128" transform="translate(293.553 -398.215)" clip-path="url(#clip-path-1360)">
        <g id="Сгруппировать_6127" data-name="Сгруппировать 6127" transform="translate(-341.737 -244.75)" clip-path="url(#clip-path-18)">
          <path id="Контур_6568" data-name="Контур 6568" d="M550.511-250.256h6.14v14.526h-6.14Z" transform="translate(-211.413 492.367)" fill="#a5cbd4"/>
        </g>
      </g>
      <g id="Сгруппировать_6130" data-name="Сгруппировать 6130" transform="translate(263.255 -417.648)" clip-path="url(#clip-path-1362)">
        <g id="Сгруппировать_6129" data-name="Сгруппировать 6129" transform="translate(-311.438 -225.317)" clip-path="url(#clip-path-18)">
          <path id="Контур_6571" data-name="Контур 6571" d="M493.1-287.076h13.679v17.767H493.1Z" transform="translate(-184.303 509.755)" fill="#9a794f"/>
        </g>
      </g>
      <g id="Сгруппировать_6132" data-name="Сгруппировать 6132" transform="translate(271.656 -412.803)" clip-path="url(#clip-path-1364)">
        <g id="Сгруппировать_6131" data-name="Сгруппировать 6131" transform="translate(-319.839 -230.162)" clip-path="url(#clip-path-18)">
          <path id="Контур_6574" data-name="Контур 6574" d="M509.02-277.9h24v18.923h-24Z" transform="translate(-191.82 505.42)" fill="#c2b3a8"/>
        </g>
      </g>
      <g id="Сгруппировать_6134" data-name="Сгруппировать 6134" transform="translate(290.375 -402.938)" clip-path="url(#clip-path-1366)">
        <g id="Сгруппировать_6133" data-name="Сгруппировать 6133" transform="translate(-338.558 -240.027)" clip-path="url(#clip-path-18)">
          <path id="Контур_6577" data-name="Контур 6577" d="M544.488-259.205h34.18v36.4h-34.18Z" transform="translate(-208.569 496.593)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_6136" data-name="Сгруппировать 6136" transform="translate(319.277 -391.575)" clip-path="url(#clip-path-1368)">
        <g id="Сгруппировать_6135" data-name="Сгруппировать 6135" transform="translate(-367.46 -251.39)" clip-path="url(#clip-path-18)">
          <path id="Контур_6580" data-name="Контур 6580" d="M599.251-237.674h14.416v25.04H599.251Z" transform="translate(-234.43 486.426)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_6138" data-name="Сгруппировать 6138" transform="translate(290.375 -408.235)" clip-path="url(#clip-path-1370)">
        <g id="Сгруппировать_6137" data-name="Сгруппировать 6137" transform="translate(-338.558 -234.73)" clip-path="url(#clip-path-18)">
          <path id="Контур_6583" data-name="Контур 6583" d="M544.488-269.242h43.318v27.256H544.488Z" transform="translate(-208.569 501.333)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_6140" data-name="Сгруппировать 6140" transform="translate(291.263 -401.483)" clip-path="url(#clip-path-1372)">
        <g id="Сгруппировать_6139" data-name="Сгруппировать 6139" transform="translate(-339.446 -241.483)" clip-path="url(#clip-path-18)">
          <path id="Контур_6586" data-name="Контур 6586" d="M546.171-256.447h32.4v33.493h-32.4Z" transform="translate(-209.364 495.291)" fill="#d9ba8b"/>
        </g>
      </g>
      <g id="Сгруппировать_6142" data-name="Сгруппировать 6142" transform="translate(291.263 -401.483)" clip-path="url(#clip-path-1374)">
        <g id="Сгруппировать_6141" data-name="Сгруппировать 6141" transform="translate(-339.446 -241.483)" clip-path="url(#clip-path-18)">
          <path id="Контур_6589" data-name="Контур 6589" d="M546.171-256.447H559.85v17.766H546.171Z" transform="translate(-209.364 495.291)" fill="#9a794f"/>
        </g>
      </g>
      <g id="Сгруппировать_6144" data-name="Сгруппировать 6144" transform="translate(299.664 -396.638)" clip-path="url(#clip-path-1376)">
        <g id="Сгруппировать_6143" data-name="Сгруппировать 6143" transform="translate(-347.847 -246.327)" clip-path="url(#clip-path-18)">
          <path id="Контур_6592" data-name="Контур 6592" d="M562.089-247.268h24v18.923h-24Z" transform="translate(-216.881 490.956)" fill="#c2b3a8"/>
        </g>
      </g>
      <g id="Сгруппировать_6146" data-name="Сгруппировать 6146" transform="translate(263.255 -417.648)" clip-path="url(#clip-path-1378)">
        <g id="Сгруппировать_6145" data-name="Сгруппировать 6145" transform="translate(-311.438 -225.317)" clip-path="url(#clip-path-276)">
          <path id="Контур_6595" data-name="Контур 6595" d="M493.1-287.076h32.4v33.493H493.1Z" transform="translate(-184.303 509.755)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_6148" data-name="Сгруппировать 6148" transform="translate(291.263 -401.483)" clip-path="url(#clip-path-1380)">
        <g id="Сгруппировать_6147" data-name="Сгруппировать 6147" transform="translate(-339.446 -241.483)" clip-path="url(#clip-path-276)">
          <path id="Контур_6598" data-name="Контур 6598" d="M546.171-256.447h32.4v33.493h-32.4Z" transform="translate(-209.364 495.291)" fill="#e6e7e3"/>
        </g>
      </g>
      <g id="Сгруппировать_6222" data-name="Сгруппировать 6222" transform="translate(273.796 -421.852)" clip-path="url(#clip-path-1382)">
        <g id="Сгруппировать_6221" data-name="Сгруппировать 6221" transform="translate(-321.979 -221.113)" clip-path="url(#clip-path-18)">
          <path id="Контур_6709" data-name="Контур 6709" d="M513.075-295.042h9.685v11.64h-9.685Z" transform="translate(-193.735 513.516)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_6231" data-name="Сгруппировать 6231" transform="translate(269.654 -424.207)" clip-path="url(#clip-path-1384)">
        <g id="Сгруппировать_6230" data-name="Сгруппировать 6230" transform="translate(-317.838 -218.758)" clip-path="url(#clip-path-18)">
          <g id="Сгруппировать_6229" data-name="Сгруппировать 6229" transform="translate(317.717 218.497)" style="isolation: isolate">
            <g id="Сгруппировать_6228" data-name="Сгруппировать 6228" clip-path="url(#clip-path-1386)">
              <g id="Сгруппировать_6227" data-name="Сгруппировать 6227" transform="translate(0.12 0.262)" clip-path="url(#clip-path-1384)">
                <g id="Сгруппировать_6226" data-name="Сгруппировать 6226" transform="translate(-0.12 -0.262)" clip-path="url(#clip-path-1388)">
                  <g id="Сгруппировать_6224" data-name="Сгруппировать 6224" transform="translate(-269.162 -145.137)" opacity="0">
                    <g id="Сгруппировать_6223" data-name="Сгруппировать 6223">
                      <path id="Контур_6712" data-name="Контур 6712" d="M-510.228-275.5h336.189V25.333H-510.228Z" transform="translate(510.228 275.496)" fill="url(#linear-gradient-3)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_6225" data-name="Сгруппировать 6225" transform="translate(-269.162 -145.137)">
                    <path id="Контур_6713" data-name="Контур 6713" d="M-510.228-275.5h336.189V25.333H-510.228Z" transform="translate(510.228 275.496)" fill="url(#linear-gradient-4)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_6233" data-name="Сгруппировать 6233" transform="translate(269.654 -421.68)" clip-path="url(#clip-path-1389)">
        <g id="Сгруппировать_6232" data-name="Сгруппировать 6232" transform="translate(-317.838 -221.285)" clip-path="url(#clip-path-18)">
          <path id="Контур_6718" data-name="Контур 6718" d="M505.228-294.717h9.419v11.467h-9.419Z" transform="translate(-190.029 513.363)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_6235" data-name="Сгруппировать 6235" transform="translate(269.654 -424.207)" clip-path="url(#clip-path-1391)">
        <g id="Сгруппировать_6234" data-name="Сгруппировать 6234" transform="translate(-317.838 -218.758)" clip-path="url(#clip-path-18)">
          <path id="Контур_6721" data-name="Контур 6721" d="M505.228-299.5h13.826v10.2H505.228Z" transform="translate(-190.029 515.624)" fill="#f4f3f6"/>
        </g>
      </g>
      <g id="Сгруппировать_6237" data-name="Сгруппировать 6237" transform="translate(273.798 -424.579)" clip-path="url(#clip-path-1393)">
        <g id="Сгруппировать_6236" data-name="Сгруппировать 6236" transform="translate(-321.982 -218.386)" clip-path="url(#clip-path-18)">
          <path id="Контур_6724" data-name="Контур 6724" d="M513.08-300.209h6.412v8.679H513.08Z" transform="translate(-193.737 515.956)" fill="#724e38"/>
        </g>
      </g>
      <g id="Сгруппировать_6239" data-name="Сгруппировать 6239" transform="translate(272.658 -424.585)" clip-path="url(#clip-path-1395)">
        <g id="Сгруппировать_6238" data-name="Сгруппировать 6238" transform="translate(-320.842 -218.38)" clip-path="url(#clip-path-18)">
          <path id="Контур_6727" data-name="Контур 6727" d="M510.92-300.221h6.418v8.685H510.92Z" transform="translate(-192.717 515.962)" fill="#64402e"/>
        </g>
      </g>
      <g id="Сгруппировать_6241" data-name="Сгруппировать 6241" transform="translate(273.804 -428.674)" clip-path="url(#clip-path-1397)">
        <g id="Сгруппировать_6240" data-name="Сгруппировать 6240" transform="translate(-321.987 -214.291)" clip-path="url(#clip-path-18)">
          <path id="Контур_6730" data-name="Контур 6730" d="M513.09-307.969h8.364v11.863H513.09Z" transform="translate(-193.742 519.621)" fill="#78b36f"/>
        </g>
      </g>
      <g id="Сгруппировать_6243" data-name="Сгруппировать 6243" transform="translate(270.701 -430.465)" clip-path="url(#clip-path-1399)">
        <g id="Сгруппировать_6242" data-name="Сгруппировать 6242" transform="translate(-318.884 -212.5)" clip-path="url(#clip-path-18)">
          <path id="Контур_6733" data-name="Контур 6733" d="M507.211-311.362h11.467v8.844H507.211Z" transform="translate(-190.966 521.223)" fill="#82ba79"/>
        </g>
      </g>
      <g id="Сгруппировать_6245" data-name="Сгруппировать 6245" transform="translate(270.701 -428.691)" clip-path="url(#clip-path-1401)">
        <g id="Сгруппировать_6244" data-name="Сгруппировать 6244" transform="translate(-318.884 -214.274)" clip-path="url(#clip-path-18)">
          <path id="Контур_6736" data-name="Контур 6736" d="M507.211-308h8.38v11.877h-8.38Z" transform="translate(-190.966 519.636)" fill="#4a9463"/>
        </g>
      </g>
      <g id="Сгруппировать_6247" data-name="Сгруппировать 6247" transform="translate(292.198 -411.111)" clip-path="url(#clip-path-1403)">
        <g id="Сгруппировать_6246" data-name="Сгруппировать 6246" transform="translate(-340.381 -231.854)" clip-path="url(#clip-path-18)">
          <path id="Контур_6739" data-name="Контур 6739" d="M547.943-274.69h9.686v11.639h-9.686Z" transform="translate(-210.201 503.906)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_6256" data-name="Сгруппировать 6256" transform="translate(288.057 -413.466)" clip-path="url(#clip-path-1405)">
        <g id="Сгруппировать_6255" data-name="Сгруппировать 6255" transform="translate(-336.24 -229.499)" clip-path="url(#clip-path-18)">
          <g id="Сгруппировать_6254" data-name="Сгруппировать 6254" transform="translate(336.189 229.052)" style="isolation: isolate">
            <g id="Сгруппировать_6253" data-name="Сгруппировать 6253" transform="translate(0 0)" clip-path="url(#clip-path-1407)">
              <g id="Сгруппировать_6252" data-name="Сгруппировать 6252" transform="translate(0.051 0.448)" clip-path="url(#clip-path-1405)">
                <g id="Сгруппировать_6251" data-name="Сгруппировать 6251" transform="translate(-0.051 -0.448)" clip-path="url(#clip-path-1409)">
                  <g id="Сгруппировать_6249" data-name="Сгруппировать 6249" transform="translate(-287.634 -155.692)" opacity="0">
                    <g id="Сгруппировать_6248" data-name="Сгруппировать 6248">
                      <path id="Контур_6742" data-name="Контур 6742" d="M-545.1-295.848h336.189V4.981H-545.1Z" transform="translate(545.097 295.848)" fill="url(#linear-gradient-5)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_6250" data-name="Сгруппировать 6250" transform="translate(-287.634 -155.692)">
                    <path id="Контур_6743" data-name="Контур 6743" d="M-545.1-295.848h336.189V4.981H-545.1Z" transform="translate(545.097 295.848)" fill="url(#linear-gradient-6)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_6258" data-name="Сгруппировать 6258" transform="translate(288.057 -410.939)" clip-path="url(#clip-path-1410)">
        <g id="Сгруппировать_6257" data-name="Сгруппировать 6257" transform="translate(-336.24 -232.026)" clip-path="url(#clip-path-18)">
          <path id="Контур_6748" data-name="Контур 6748" d="M540.1-274.365h9.419V-262.9H540.1Z" transform="translate(-206.495 503.752)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_6260" data-name="Сгруппировать 6260" transform="translate(288.057 -413.466)" clip-path="url(#clip-path-1412)">
        <g id="Сгруппировать_6259" data-name="Сгруппировать 6259" transform="translate(-336.24 -229.499)" clip-path="url(#clip-path-18)">
          <path id="Контур_6751" data-name="Контур 6751" d="M540.1-279.152h13.827v10.2H540.1Z" transform="translate(-206.495 506.013)" fill="#f4f3f6"/>
        </g>
      </g>
      <g id="Сгруппировать_6262" data-name="Сгруппировать 6262" transform="translate(292.201 -413.838)" clip-path="url(#clip-path-1414)">
        <g id="Сгруппировать_6261" data-name="Сгруппировать 6261" transform="translate(-340.384 -229.127)" clip-path="url(#clip-path-18)">
          <path id="Контур_6754" data-name="Контур 6754" d="M547.949-279.857h6.412v8.679h-6.412Z" transform="translate(-210.203 506.346)" fill="#724e38"/>
        </g>
      </g>
      <g id="Сгруппировать_6264" data-name="Сгруппировать 6264" transform="translate(291.061 -413.844)" clip-path="url(#clip-path-1416)">
        <g id="Сгруппировать_6263" data-name="Сгруппировать 6263" transform="translate(-339.244 -229.121)" clip-path="url(#clip-path-18)">
          <path id="Контур_6757" data-name="Контур 6757" d="M545.789-279.869h6.418v8.685h-6.418Z" transform="translate(-209.183 506.351)" fill="#64402e"/>
        </g>
      </g>
      <g id="Сгруппировать_6266" data-name="Сгруппировать 6266" transform="translate(292.207 -417.933)" clip-path="url(#clip-path-1418)">
        <g id="Сгруппировать_6265" data-name="Сгруппировать 6265" transform="translate(-340.39 -225.032)" clip-path="url(#clip-path-18)">
          <path id="Контур_6760" data-name="Контур 6760" d="M547.959-287.617h8.365v11.862h-8.365Z" transform="translate(-210.208 510.01)" fill="#78b36f"/>
        </g>
      </g>
      <g id="Сгруппировать_6268" data-name="Сгруппировать 6268" transform="translate(289.103 -419.724)" clip-path="url(#clip-path-1420)">
        <g id="Сгруппировать_6267" data-name="Сгруппировать 6267" transform="translate(-337.286 -223.241)" clip-path="url(#clip-path-18)">
          <path id="Контур_6763" data-name="Контур 6763" d="M542.079-291.011h11.468v8.845H542.079Z" transform="translate(-207.431 511.613)" fill="#82ba79"/>
        </g>
      </g>
      <g id="Сгруппировать_6270" data-name="Сгруппировать 6270" transform="translate(289.103 -417.95)" clip-path="url(#clip-path-1422)">
        <g id="Сгруппировать_6269" data-name="Сгруппировать 6269" transform="translate(-337.286 -225.016)" clip-path="url(#clip-path-18)">
          <path id="Контур_6766" data-name="Контур 6766" d="M542.079-287.648h8.381v11.876h-8.381Z" transform="translate(-207.431 510.025)" fill="#4a9463"/>
        </g>
      </g>
      <g id="Сгруппировать_6272" data-name="Сгруппировать 6272" transform="translate(310.601 -400.371)" clip-path="url(#clip-path-1424)">
        <g id="Сгруппировать_6271" data-name="Сгруппировать 6271" transform="translate(-358.785 -242.595)" clip-path="url(#clip-path-18)">
          <path id="Контур_6769" data-name="Контур 6769" d="M582.813-254.34H592.5v11.64h-9.685Z" transform="translate(-226.667 494.296)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_6281" data-name="Сгруппировать 6281" transform="translate(306.459 -402.726)" clip-path="url(#clip-path-1426)">
        <g id="Сгруппировать_6280" data-name="Сгруппировать 6280" transform="translate(-354.643 -240.24)" clip-path="url(#clip-path-18)">
          <g id="Сгруппировать_6279" data-name="Сгруппировать 6279" transform="translate(354.133 240.135)" style="isolation: isolate">
            <g id="Сгруппировать_6278" data-name="Сгруппировать 6278" transform="translate(0)" clip-path="url(#clip-path-1428)">
              <g id="Сгруппировать_6277" data-name="Сгруппировать 6277" transform="translate(0.509 0.104)" clip-path="url(#clip-path-1426)">
                <g id="Сгруппировать_6276" data-name="Сгруппировать 6276" transform="translate(-0.509 -0.104)" clip-path="url(#clip-path-1430)">
                  <g id="Сгруппировать_6274" data-name="Сгруппировать 6274" transform="translate(-305.579 -166.775)" opacity="0">
                    <g id="Сгруппировать_6273" data-name="Сгруппировать 6273">
                      <path id="Контур_6772" data-name="Контур 6772" d="M-579.965-316.2h336.189V-15.369H-579.965Z" transform="translate(579.965 316.198)" fill="url(#linear-gradient-7)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_6275" data-name="Сгруппировать 6275" transform="translate(-305.579 -166.775)">
                    <path id="Контур_6773" data-name="Контур 6773" d="M-579.965-316.2h336.189V-15.369H-579.965Z" transform="translate(579.965 316.198)" fill="url(#linear-gradient-8)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_6283" data-name="Сгруппировать 6283" transform="translate(306.459 -400.199)" clip-path="url(#clip-path-1431)">
        <g id="Сгруппировать_6282" data-name="Сгруппировать 6282" transform="translate(-354.643 -242.767)" clip-path="url(#clip-path-18)">
          <path id="Контур_6778" data-name="Контур 6778" d="M574.965-254.014h9.42v11.466h-9.42Z" transform="translate(-222.961 494.142)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_6285" data-name="Сгруппировать 6285" transform="translate(306.459 -402.726)" clip-path="url(#clip-path-1433)">
        <g id="Сгруппировать_6284" data-name="Сгруппировать 6284" transform="translate(-354.643 -240.24)" clip-path="url(#clip-path-18)">
          <path id="Контур_6781" data-name="Контур 6781" d="M574.965-258.8h13.827v10.2H574.965Z" transform="translate(-222.961 496.403)" fill="#f4f3f6"/>
        </g>
      </g>
      <g id="Сгруппировать_6287" data-name="Сгруппировать 6287" transform="translate(310.604 -403.098)" clip-path="url(#clip-path-1435)">
        <g id="Сгруппировать_6286" data-name="Сгруппировать 6286" transform="translate(-358.787 -239.868)" clip-path="url(#clip-path-18)">
          <path id="Контур_6784" data-name="Контур 6784" d="M582.818-259.507h6.412v8.68h-6.412Z" transform="translate(-226.67 496.736)" fill="#724e38"/>
        </g>
      </g>
      <g id="Сгруппировать_6289" data-name="Сгруппировать 6289" transform="translate(309.464 -403.103)" clip-path="url(#clip-path-1437)">
        <g id="Сгруппировать_6288" data-name="Сгруппировать 6288" transform="translate(-357.647 -239.862)" clip-path="url(#clip-path-18)">
          <path id="Контур_6787" data-name="Контур 6787" d="M580.658-259.518h6.418v8.685h-6.418Z" transform="translate(-225.65 496.741)" fill="#64402e"/>
        </g>
      </g>
      <g id="Сгруппировать_6291" data-name="Сгруппировать 6291" transform="translate(310.609 -407.193)" clip-path="url(#clip-path-1439)">
        <g id="Сгруппировать_6290" data-name="Сгруппировать 6290" transform="translate(-358.792 -235.773)" clip-path="url(#clip-path-18)">
          <path id="Контур_6790" data-name="Контур 6790" d="M582.827-267.266h8.365V-255.4h-8.365Z" transform="translate(-226.674 500.4)" fill="#78b36f"/>
        </g>
      </g>
      <g id="Сгруппировать_6293" data-name="Сгруппировать 6293" transform="translate(307.505 -408.984)" clip-path="url(#clip-path-1441)">
        <g id="Сгруппировать_6292" data-name="Сгруппировать 6292" transform="translate(-355.689 -233.981)" clip-path="url(#clip-path-18)">
          <path id="Контур_6793" data-name="Контур 6793" d="M576.947-270.66h11.468v8.845H576.947Z" transform="translate(-223.897 502.003)" fill="#82ba79"/>
        </g>
      </g>
      <g id="Сгруппировать_6295" data-name="Сгруппировать 6295" transform="translate(307.505 -407.209)" clip-path="url(#clip-path-1443)">
        <g id="Сгруппировать_6294" data-name="Сгруппировать 6294" transform="translate(-355.689 -235.756)" clip-path="url(#clip-path-18)">
          <path id="Контур_6796" data-name="Контур 6796" d="M576.947-267.3h8.381v11.876h-8.381Z" transform="translate(-223.897 500.414)" fill="#4a9463"/>
        </g>
      </g>
      <g id="Сгруппировать_6297" data-name="Сгруппировать 6297" transform="translate(190.938 -343.973)" clip-path="url(#clip-path-1445)">
        <g id="Сгруппировать_6296" data-name="Сгруппировать 6296" transform="translate(-239.121 -298.992)" clip-path="url(#clip-path-18)">
          <path id="Контур_6799" data-name="Контур 6799" d="M356.078-147.479h7.564v8.634h-7.564Z" transform="translate(-119.596 443.833)" fill="#a5cbd4"/>
        </g>
      </g>
      <g id="Сгруппировать_6299" data-name="Сгруппировать 6299" transform="translate(179.392 -349.379)" clip-path="url(#clip-path-1447)">
        <g id="Сгруппировать_6298" data-name="Сгруппировать 6298" transform="translate(-227.575 -293.586)" clip-path="url(#clip-path-18)">
          <path id="Контур_6802" data-name="Контур 6802" d="M334.2-157.723h16.824v14.04H334.2Z" transform="translate(-109.266 448.67)" fill="#90b9c0"/>
        </g>
      </g>
      <g id="Сгруппировать_6301" data-name="Сгруппировать 6301" transform="translate(179.392 -350.671)" clip-path="url(#clip-path-1449)">
        <g id="Сгруппировать_6300" data-name="Сгруппировать 6300" transform="translate(-227.575 -292.294)" clip-path="url(#clip-path-18)">
          <path id="Контур_6805" data-name="Контур 6805" d="M334.2-160.171h19.109v13.282H334.2Z" transform="translate(-109.266 449.826)" fill="#bfe0e9"/>
        </g>
      </g>
      <g id="Сгруппировать_6303" data-name="Сгруппировать 6303" transform="translate(90.304 -415.014)" clip-path="url(#clip-path-1451)">
        <g id="Сгруппировать_6302" data-name="Сгруппировать 6302" transform="translate(-138.487 -227.951)" clip-path="url(#clip-path-18)">
          <path id="Контур_6808" data-name="Контур 6808" d="M165.4-282.086h9.805v10.124H165.4Z" transform="translate(-29.552 507.398)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_6312" data-name="Сгруппировать 6312" transform="translate(86.05 -417.432)" clip-path="url(#clip-path-1453)">
        <g id="Сгруппировать_6311" data-name="Сгруппировать 6311" transform="translate(-134.233 -225.533)" clip-path="url(#clip-path-1024)">
          <g id="Сгруппировать_6310" data-name="Сгруппировать 6310" transform="translate(134.053 225.358)" style="isolation: isolate">
            <g id="Сгруппировать_6309" data-name="Сгруппировать 6309" transform="translate(0 0)" clip-path="url(#clip-path-1455)">
              <g id="Сгруппировать_6308" data-name="Сгруппировать 6308" transform="translate(0.179 0.175)" clip-path="url(#clip-path-1453)">
                <g id="Сгруппировать_6307" data-name="Сгруппировать 6307" transform="translate(-0.179 -0.175)" clip-path="url(#clip-path-1457)">
                  <g id="Сгруппировать_6305" data-name="Сгруппировать 6305" transform="translate(-85.499 -151.998)" opacity="0">
                    <g id="Сгруппировать_6304" data-name="Сгруппировать 6304">
                      <path id="Контур_6811" data-name="Контур 6811" d="M-162.34-288.332H173.849V12.5H-162.34Z" transform="translate(162.34 288.332)" fill="#a7afc7"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_6306" data-name="Сгруппировать 6306" transform="translate(-85.499 -151.998)">
                    <path id="Контур_6812" data-name="Контур 6812" d="M-162.34-288.332H173.849V12.5H-162.34Z" transform="translate(162.34 288.332)" fill="#a7afc7"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_6314" data-name="Сгруппировать 6314" transform="translate(86.05 -414.837)" clip-path="url(#clip-path-1458)">
        <g id="Сгруппировать_6313" data-name="Сгруппировать 6313" transform="translate(-134.233 -228.128)" clip-path="url(#clip-path-18)">
          <path id="Контур_6817" data-name="Контур 6817" d="M157.34-281.751h9.532v9.945H157.34Z" transform="translate(-25.746 507.24)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_6316" data-name="Сгруппировать 6316" transform="translate(86.05 -417.432)" clip-path="url(#clip-path-1460)">
        <g id="Сгруппировать_6315" data-name="Сгруппировать 6315" transform="translate(-134.233 -225.533)" clip-path="url(#clip-path-18)">
          <path id="Контур_6820" data-name="Контур 6820" d="M157.34-286.668H171.4v10.333H157.34Z" transform="translate(-25.746 509.562)" fill="#f4f3f6"/>
        </g>
      </g>
      <g id="Сгруппировать_6318" data-name="Сгруппировать 6318" transform="translate(220.946 -394.815)" clip-path="url(#clip-path-1462)">
        <g id="Сгруппировать_6317" data-name="Сгруппировать 6317" transform="translate(-269.129 -248.15)" clip-path="url(#clip-path-18)">
          <path id="Контур_6823" data-name="Контур 6823" d="M412.936-243.814h9.8v10.123h-9.8Z" transform="translate(-146.446 489.325)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_6327" data-name="Сгруппировать 6327" transform="translate(216.692 -397.235)" clip-path="url(#clip-path-1464)">
        <g id="Сгруппировать_6326" data-name="Сгруппировать 6326" transform="translate(-264.875 -245.731)" clip-path="url(#clip-path-1024)">
          <g id="Сгруппировать_6325" data-name="Сгруппировать 6325" transform="translate(264.413 245.413)" style="isolation: isolate">
            <g id="Сгруппировать_6324" data-name="Сгруппировать 6324" clip-path="url(#clip-path-1466)">
              <g id="Сгруппировать_6323" data-name="Сгруппировать 6323" transform="translate(0.462 0.318)" clip-path="url(#clip-path-1464)">
                <g id="Сгруппировать_6322" data-name="Сгруппировать 6322" transform="translate(-0.462 -0.318)" clip-path="url(#clip-path-1468)">
                  <g id="Сгруппировать_6320" data-name="Сгруппировать 6320" transform="translate(-215.858 -172.053)" opacity="0">
                    <g id="Сгруппировать_6319" data-name="Сгруппировать 6319" transform="translate(0 0)">
                      <path id="Контур_6826" data-name="Контур 6826" d="M-409.876-326.6H-73.687V-25.773H-409.876Z" transform="translate(409.876 326.602)" fill="#a7afc7"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_6321" data-name="Сгруппировать 6321" transform="translate(-215.858 -172.053)">
                    <path id="Контур_6827" data-name="Контур 6827" d="M-409.876-326.6H-73.687V-25.773H-409.876Z" transform="translate(409.876 326.602)" fill="#a7afc7"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_6329" data-name="Сгруппировать 6329" transform="translate(216.692 -394.639)" clip-path="url(#clip-path-1469)">
        <g id="Сгруппировать_6328" data-name="Сгруппировать 6328" transform="translate(-264.875 -248.326)" clip-path="url(#clip-path-18)">
          <path id="Контур_6832" data-name="Контур 6832" d="M404.876-243.48h9.532v9.945h-9.532Z" transform="translate(-142.64 489.167)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_6331" data-name="Сгруппировать 6331" transform="translate(216.692 -397.235)" clip-path="url(#clip-path-1471)">
        <g id="Сгруппировать_6330" data-name="Сгруппировать 6330" transform="translate(-264.875 -245.731)" clip-path="url(#clip-path-18)">
          <path id="Контур_6835" data-name="Контур 6835" d="M404.876-248.4h14.059v10.334H404.876Z" transform="translate(-142.64 491.49)" fill="#f4f3f6"/>
        </g>
      </g>
      <g id="Сгруппировать_6333" data-name="Сгруппировать 6333" transform="translate(175.301 -376.836)" clip-path="url(#clip-path-1473)">
        <g id="Сгруппировать_6332" data-name="Сгруппировать 6332" transform="translate(-223.484 -266.129)" clip-path="url(#clip-path-18)">
          <path id="Контур_6838" data-name="Контур 6838" d="M326.45-209.747h9.164v14.7H326.45Z" transform="translate(-105.605 473.238)" fill="#d2d6d1"/>
        </g>
      </g>
      <g id="Сгруппировать_6342" data-name="Сгруппировать 6342" transform="translate(171.65 -378.912)" clip-path="url(#clip-path-1475)">
        <g id="Сгруппировать_6341" data-name="Сгруппировать 6341" transform="translate(-219.833 -264.053)" clip-path="url(#clip-path-1024)">
          <g id="Сгруппировать_6340" data-name="Сгруппировать 6340" transform="translate(219.552 263.885)" style="isolation: isolate">
            <g id="Сгруппировать_6339" data-name="Сгруппировать 6339" clip-path="url(#clip-path-1477)">
              <g id="Сгруппировать_6338" data-name="Сгруппировать 6338" transform="translate(0.281 0.168)" clip-path="url(#clip-path-1475)">
                <g id="Сгруппировать_6337" data-name="Сгруппировать 6337" transform="translate(-0.281 -0.168)" clip-path="url(#clip-path-1479)">
                  <g id="Сгруппировать_6335" data-name="Сгруппировать 6335" transform="translate(-170.997 -190.525)" opacity="0">
                    <g id="Сгруппировать_6334" data-name="Сгруппировать 6334">
                      <path id="Контур_6841" data-name="Контур 6841" d="M-324.532-361.319H11.657V-60.49H-324.532Z" transform="translate(324.532 361.319)" fill="#a7afc7"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_6336" data-name="Сгруппировать 6336" transform="translate(-170.997 -190.525)">
                    <path id="Контур_6842" data-name="Контур 6842" d="M-324.532-361.319H11.657V-60.49H-324.532Z" transform="translate(324.532 361.319)" fill="#a7afc7"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_6344" data-name="Сгруппировать 6344" transform="translate(171.65 -376.684)" clip-path="url(#clip-path-1480)">
        <g id="Сгруппировать_6343" data-name="Сгруппировать 6343" transform="translate(-219.833 -266.281)" clip-path="url(#clip-path-18)">
          <path id="Контур_6847" data-name="Контур 6847" d="M319.532-209.46h8.929v14.551h-8.929Z" transform="translate(-102.338 473.102)" fill="#c3c2be"/>
        </g>
      </g>
      <g id="Сгруппировать_6346" data-name="Сгруппировать 6346" transform="translate(171.65 -378.912)" clip-path="url(#clip-path-1482)">
        <g id="Сгруппировать_6345" data-name="Сгруппировать 6345" transform="translate(-219.833 -264.053)" clip-path="url(#clip-path-18)">
          <path id="Контур_6850" data-name="Контур 6850" d="M319.532-213.681h12.815v9.618H319.532Z" transform="translate(-102.338 475.095)" fill="#f4f3f6"/>
        </g>
      </g>
      <g id="Сгруппировать_6348" data-name="Сгруппировать 6348" transform="translate(103.386 -415.045)" clip-path="url(#clip-path-1484)">
        <g id="Сгруппировать_6347" data-name="Сгруппировать 6347" transform="translate(-151.569 -227.921)" clip-path="url(#clip-path-18)">
          <path id="Контур_6853" data-name="Контур 6853" d="M190.188-282.143h20.075v24.677H190.188Z" transform="translate(-41.258 507.425)" fill="#c2bdbf"/>
        </g>
      </g>
      <g id="Сгруппировать_6350" data-name="Сгруппировать 6350" transform="translate(103.244 -414.742)" clip-path="url(#clip-path-1486)">
        <g id="Сгруппировать_6349" data-name="Сгруппировать 6349" transform="translate(-151.428 -228.223)" clip-path="url(#clip-path-18)">
          <path id="Контур_6856" data-name="Контур 6856" d="M189.92-281.57h19.694v24.44H189.92Z" transform="translate(-41.131 507.155)" fill="#151919"/>
        </g>
      </g>
      <g id="Сгруппировать_6352" data-name="Сгруппировать 6352" transform="translate(103.569 -413.598)" clip-path="url(#clip-path-1488)">
        <g id="Сгруппировать_6351" data-name="Сгруппировать 6351" transform="translate(-151.752 -229.368)" clip-path="url(#clip-path-18)">
          <path id="Контур_6859" data-name="Контур 6859" d="M190.534-279.4h19v22.1h-19Z" transform="translate(-41.421 506.131)" fill="#262b2b"/>
        </g>
      </g>
      <g id="Сгруппировать_6354" data-name="Сгруппировать 6354" transform="translate(101.602 -397.268)" clip-path="url(#clip-path-1490)">
        <g id="Сгруппировать_6353" data-name="Сгруппировать 6353" transform="translate(-149.785 -245.697)" clip-path="url(#clip-path-18)">
          <path id="Контур_6862" data-name="Контур 6862" d="M186.807-248.461h5.5v5.551h-5.5Z" transform="translate(-39.661 491.52)" fill="#3f4954"/>
        </g>
      </g>
      <g id="Сгруппировать_6356" data-name="Сгруппировать 6356" transform="translate(99.543 -397.242)" clip-path="url(#clip-path-1492)">
        <g id="Сгруппировать_6355" data-name="Сгруппировать 6355" transform="translate(-147.726 -245.724)" clip-path="url(#clip-path-18)">
          <path id="Контур_6865" data-name="Контур 6865" d="M182.907-248.411h7.336v6.72h-7.336Z" transform="translate(-37.819 491.496)" fill="#3f4954"/>
        </g>
      </g>
      <g id="Сгруппировать_6358" data-name="Сгруппировать 6358" transform="translate(117.289 -395.13)" clip-path="url(#clip-path-1494)">
        <g id="Сгруппировать_6357" data-name="Сгруппировать 6357" transform="translate(-165.472 -247.835)" clip-path="url(#clip-path-18)">
          <path id="Контур_6868" data-name="Контур 6868" d="M216.531-244.41h5.409v6.109h-5.409Z" transform="translate(-53.698 489.606)" fill="#293341"/>
        </g>
      </g>
      <g id="Сгруппировать_6360" data-name="Сгруппировать 6360" transform="translate(101.821 -397.242)" clip-path="url(#clip-path-1496)">
        <g id="Сгруппировать_6359" data-name="Сгруппировать 6359" transform="translate(-150.004 -245.724)" clip-path="url(#clip-path-18)">
          <path id="Контур_6871" data-name="Контур 6871" d="M187.223-248.411h9.965v8.231h-9.965Z" transform="translate(-39.858 491.496)" fill="#3f4954"/>
        </g>
      </g>
      <g id="Сгруппировать_6362" data-name="Сгруппировать 6362" transform="translate(106.508 -394.536)" clip-path="url(#clip-path-1498)">
        <g id="Сгруппировать_6361" data-name="Сгруппировать 6361" transform="translate(-154.691 -248.43)" clip-path="url(#clip-path-18)">
          <path id="Контур_6874" data-name="Контур 6874" d="M196.1-243.284h5.324v5.589H196.1Z" transform="translate(-44.051 489.075)" fill="#3f4954"/>
        </g>
      </g>
      <g id="Сгруппировать_6364" data-name="Сгруппировать 6364" transform="translate(106.508 -394.471)" clip-path="url(#clip-path-1500)">
        <g id="Сгруппировать_6363" data-name="Сгруппировать 6363" transform="translate(-154.691 -248.494)" clip-path="url(#clip-path-18)">
          <path id="Контур_6877" data-name="Контур 6877" d="M196.1-243.162h5.324v5.588H196.1Z" transform="translate(-44.051 489.017)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6366" data-name="Сгруппировать 6366" transform="translate(99.544 -395.918)" clip-path="url(#clip-path-1502)">
        <g id="Сгруппировать_6365" data-name="Сгруппировать 6365" transform="translate(-147.727 -247.047)" clip-path="url(#clip-path-18)">
          <path id="Контур_6880" data-name="Контур 6880" d="M182.908-245.9h9.965v8.23h-9.965Z" transform="translate(-37.82 490.311)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6368" data-name="Сгруппировать 6368" transform="translate(104.231 -393.212)" clip-path="url(#clip-path-1504)">
        <g id="Сгруппировать_6367" data-name="Сгруппировать 6367" transform="translate(-152.414 -249.753)" clip-path="url(#clip-path-18)">
          <path id="Контур_6883" data-name="Контур 6883" d="M191.789-240.776h5.5v5.551h-5.5Z" transform="translate(-42.014 487.89)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6370" data-name="Сгруппировать 6370" transform="translate(99.499 -397.268)" clip-path="url(#clip-path-1506)">
        <g id="Сгруппировать_6369" data-name="Сгруппировать 6369" transform="translate(-147.682 -245.697)" clip-path="url(#clip-path-18)">
          <path id="Контур_6886" data-name="Контур 6886" d="M182.823-248.461h12.333v9.36H182.823Z" transform="translate(-37.78 491.52)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6372" data-name="Сгруппировать 6372" transform="translate(109.209 -394.947)" clip-path="url(#clip-path-1508)">
        <g id="Сгруппировать_6371" data-name="Сгруппировать 6371" transform="translate(-157.392 -248.018)" clip-path="url(#clip-path-18)">
          <path id="Контур_6889" data-name="Контур 6889" d="M201.221-244.064h13.358v10.622H201.221Z" transform="translate(-46.468 489.443)" fill="#293341"/>
        </g>
      </g>
      <g id="Сгруппировать_6374" data-name="Сгруппировать 6374" transform="translate(102.639 -402.821)" clip-path="url(#clip-path-1510)">
        <g id="Сгруппировать_6373" data-name="Сгруппировать 6373" transform="translate(-150.823 -240.144)" clip-path="url(#clip-path-18)">
          <path id="Контур_6892" data-name="Контур 6892" d="M188.773-258.983h6.8v6.157h-6.8Z" transform="translate(-40.59 496.488)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6376" data-name="Сгруппировать 6376" transform="translate(104.798 -401.589)" clip-path="url(#clip-path-1512)">
        <g id="Сгруппировать_6375" data-name="Сгруппировать 6375" transform="translate(-152.981 -241.376)" clip-path="url(#clip-path-18)">
          <path id="Контур_6895" data-name="Контур 6895" d="M192.863-256.649h6.8v6.158h-6.8Z" transform="translate(-42.521 495.386)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6378" data-name="Сгруппировать 6378" transform="translate(103.659 -402.251)" clip-path="url(#clip-path-1514)">
        <g id="Сгруппировать_6377" data-name="Сгруппировать 6377" transform="translate(-151.842 -240.714)" clip-path="url(#clip-path-18)">
          <path id="Контур_6898" data-name="Контур 6898" d="M190.7-257.9h6.8v6.158h-6.8Z" transform="translate(-41.502 495.978)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6380" data-name="Сгруппировать 6380" transform="translate(105.875 -400.962)" clip-path="url(#clip-path-1516)">
        <g id="Сгруппировать_6379" data-name="Сгруппировать 6379" transform="translate(-154.059 -242.003)" clip-path="url(#clip-path-18)">
          <path id="Контур_6901" data-name="Контур 6901" d="M194.905-255.461h6.8v6.158h-6.8Z" transform="translate(-43.485 494.825)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6382" data-name="Сгруппировать 6382" transform="translate(106.938 -400.351)" clip-path="url(#clip-path-1518)">
        <g id="Сгруппировать_6381" data-name="Сгруппировать 6381" transform="translate(-155.121 -242.615)" clip-path="url(#clip-path-18)">
          <path id="Контур_6904" data-name="Контур 6904" d="M196.918-254.3h6.8v6.157h-6.8Z" transform="translate(-44.436 494.278)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6384" data-name="Сгруппировать 6384" transform="translate(108.001 -399.738)" clip-path="url(#clip-path-1520)">
        <g id="Сгруппировать_6383" data-name="Сгруппировать 6383" transform="translate(-156.184 -243.227)" clip-path="url(#clip-path-18)">
          <path id="Контур_6907" data-name="Контур 6907" d="M198.932-253.142h6.8v6.157h-6.8Z" transform="translate(-45.387 493.73)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6386" data-name="Сгруппировать 6386" transform="translate(101.619 -402.145)" clip-path="url(#clip-path-1522)">
        <g id="Сгруппировать_6385" data-name="Сгруппировать 6385" transform="translate(-149.802 -240.82)" clip-path="url(#clip-path-18)">
          <path id="Контур_6910" data-name="Контур 6910" d="M186.841-257.7h7.865v6.774h-7.865Z" transform="translate(-39.677 495.883)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6388" data-name="Сгруппировать 6388" transform="translate(103.704 -400.963)" clip-path="url(#clip-path-1524)">
        <g id="Сгруппировать_6387" data-name="Сгруппировать 6387" transform="translate(-151.887 -242.002)" clip-path="url(#clip-path-18)">
          <path id="Контур_6913" data-name="Контур 6913" d="M190.79-255.462h6.8v6.158h-6.8Z" transform="translate(-41.542 494.826)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6390" data-name="Сгруппировать 6390" transform="translate(104.852 -400.303)" clip-path="url(#clip-path-1526)">
        <g id="Сгруппировать_6389" data-name="Сгруппировать 6389" transform="translate(-153.035 -242.662)" clip-path="url(#clip-path-18)">
          <path id="Контур_6916" data-name="Контур 6916" d="M192.965-254.212h6.8v6.158h-6.8Z" transform="translate(-42.569 494.235)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6392" data-name="Сгруппировать 6392" transform="translate(105.919 -399.687)" clip-path="url(#clip-path-1528)">
        <g id="Сгруппировать_6391" data-name="Сгруппировать 6391" transform="translate(-154.102 -243.279)" clip-path="url(#clip-path-18)">
          <path id="Контур_6919" data-name="Контур 6919" d="M194.988-253.044h6.8v6.158h-6.8Z" transform="translate(-43.525 493.684)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6394" data-name="Сгруппировать 6394" transform="translate(106.987 -399.069)" clip-path="url(#clip-path-1530)">
        <g id="Сгруппировать_6393" data-name="Сгруппировать 6393" transform="translate(-155.17 -243.896)" clip-path="url(#clip-path-18)">
          <path id="Контур_6922" data-name="Контур 6922" d="M197.012-251.874h6.8v6.158h-6.8Z" transform="translate(-44.48 493.131)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6396" data-name="Сгруппировать 6396" transform="translate(100.589 -401.49)" clip-path="url(#clip-path-1532)">
        <g id="Сгруппировать_6395" data-name="Сгруппировать 6395" transform="translate(-148.772 -241.476)" clip-path="url(#clip-path-18)">
          <path id="Контур_6925" data-name="Контур 6925" d="M184.888-256.46h7.864v6.774h-7.864Z" transform="translate(-38.755 495.297)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6398" data-name="Сгруппировать 6398" transform="translate(102.724 -400.313)" clip-path="url(#clip-path-1534)">
        <g id="Сгруппировать_6397" data-name="Сгруппировать 6397" transform="translate(-150.907 -242.652)" clip-path="url(#clip-path-18)">
          <path id="Контур_6928" data-name="Контур 6928" d="M188.934-254.23h6.8v6.158h-6.8Z" transform="translate(-40.666 494.244)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6400" data-name="Сгруппировать 6400" transform="translate(103.827 -399.661)" clip-path="url(#clip-path-1536)">
        <g id="Сгруппировать_6399" data-name="Сгруппировать 6399" transform="translate(-152.01 -243.304)" clip-path="url(#clip-path-18)">
          <path id="Контур_6931" data-name="Контур 6931" d="M191.023-253h6.8v6.158h-6.8Z" transform="translate(-41.652 493.661)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6402" data-name="Сгруппировать 6402" transform="translate(104.895 -399.044)" clip-path="url(#clip-path-1538)">
        <g id="Сгруппировать_6401" data-name="Сгруппировать 6401" transform="translate(-153.078 -243.921)" clip-path="url(#clip-path-18)">
          <path id="Контур_6934" data-name="Контур 6934" d="M193.047-251.827h6.8v6.158h-6.8Z" transform="translate(-42.608 493.109)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6404" data-name="Сгруппировать 6404" transform="translate(109.069 -399.122)" clip-path="url(#clip-path-1540)">
        <g id="Сгруппировать_6403" data-name="Сгруппировать 6403" transform="translate(-157.252 -243.844)" clip-path="url(#clip-path-18)">
          <path id="Контур_6937" data-name="Контур 6937" d="M200.957-251.973h6.8v6.158h-6.8Z" transform="translate(-46.343 493.178)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6406" data-name="Сгруппировать 6406" transform="translate(108.055 -398.453)" clip-path="url(#clip-path-1542)">
        <g id="Сгруппировать_6405" data-name="Сгруппировать 6405" transform="translate(-156.239 -244.512)" clip-path="url(#clip-path-18)">
          <path id="Контур_6940" data-name="Контур 6940" d="M199.035-250.706h6.8v6.158h-6.8Z" transform="translate(-45.436 492.58)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6408" data-name="Сгруппировать 6408" transform="translate(105.962 -398.427)" clip-path="url(#clip-path-1544)">
        <g id="Сгруппировать_6407" data-name="Сгруппировать 6407" transform="translate(-154.145 -244.538)" clip-path="url(#clip-path-18)">
          <path id="Контур_6943" data-name="Контур 6943" d="M195.07-250.658h6.8v6.157h-6.8Z" transform="translate(-43.563 492.557)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6410" data-name="Сгруппировать 6410" transform="translate(107.03 -397.81)" clip-path="url(#clip-path-1546)">
        <g id="Сгруппировать_6409" data-name="Сгруппировать 6409" transform="translate(-155.214 -245.155)" clip-path="url(#clip-path-18)">
          <path id="Контур_6946" data-name="Контур 6946" d="M197.094-249.489h6.8v6.157h-6.8Z" transform="translate(-44.519 492.005)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6412" data-name="Сгруппировать 6412" transform="translate(99.619 -400.884)" clip-path="url(#clip-path-1548)">
        <g id="Сгруппировать_6411" data-name="Сгруппировать 6411" transform="translate(-147.803 -242.081)" clip-path="url(#clip-path-18)">
          <path id="Контур_6949" data-name="Контур 6949" d="M183.051-255.312h8.932v7.391h-8.932Z" transform="translate(-37.888 494.755)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6414" data-name="Сгруппировать 6414" transform="translate(102.802 -399.031)" clip-path="url(#clip-path-1550)">
        <g id="Сгруппировать_6413" data-name="Сгруппировать 6413" transform="translate(-150.985 -243.934)" clip-path="url(#clip-path-18)">
          <path id="Контур_6952" data-name="Контур 6952" d="M189.082-251.8h6.8v6.157h-6.8Z" transform="translate(-40.735 493.097)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6416" data-name="Сгруппировать 6416" transform="translate(103.87 -398.414)" clip-path="url(#clip-path-1552)">
        <g id="Сгруппировать_6415" data-name="Сгруппировать 6415" transform="translate(-152.053 -244.551)" clip-path="url(#clip-path-18)">
          <path id="Контур_6955" data-name="Контур 6955" d="M191.106-250.633h6.8v6.157h-6.8Z" transform="translate(-41.691 492.545)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6418" data-name="Сгруппировать 6418" transform="translate(104.938 -397.798)" clip-path="url(#clip-path-1554)">
        <g id="Сгруппировать_6417" data-name="Сгруппировать 6417" transform="translate(-153.121 -245.167)" clip-path="url(#clip-path-18)">
          <path id="Контур_6958" data-name="Контур 6958" d="M193.129-249.465h6.8v6.158h-6.8Z" transform="translate(-42.647 491.994)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6420" data-name="Сгруппировать 6420" transform="translate(106.006 -397.181)" clip-path="url(#clip-path-1556)">
        <g id="Сгруппировать_6419" data-name="Сгруппировать 6419" transform="translate(-154.189 -245.784)" clip-path="url(#clip-path-18)">
          <path id="Контур_6961" data-name="Контур 6961" d="M195.152-248.3h6.8v6.158h-6.8Z" transform="translate(-43.602 491.442)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6422" data-name="Сгруппировать 6422" transform="translate(110.137 -398.505)" clip-path="url(#clip-path-1558)">
        <g id="Сгруппировать_6421" data-name="Сгруппировать 6421" transform="translate(-158.32 -244.461)" clip-path="url(#clip-path-18)">
          <path id="Контур_6964" data-name="Контур 6964" d="M202.98-250.8h6.8v6.157h-6.8Z" transform="translate(-47.299 492.626)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6424" data-name="Сгруппировать 6424" transform="translate(109.123 -397.836)" clip-path="url(#clip-path-1560)">
        <g id="Сгруппировать_6423" data-name="Сгруппировать 6423" transform="translate(-157.306 -245.13)" clip-path="url(#clip-path-18)">
          <path id="Контур_6967" data-name="Контур 6967" d="M201.059-249.537h6.8v6.157h-6.8Z" transform="translate(-46.391 492.027)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6426" data-name="Сгруппировать 6426" transform="translate(108.099 -397.193)" clip-path="url(#clip-path-1562)">
        <g id="Сгруппировать_6425" data-name="Сгруппировать 6425" transform="translate(-156.282 -245.772)" clip-path="url(#clip-path-18)">
          <path id="Контур_6970" data-name="Контур 6970" d="M199.117-248.32h6.8v6.158h-6.8Z" transform="translate(-45.475 491.453)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6428" data-name="Сгруппировать 6428" transform="translate(107.074 -396.564)" clip-path="url(#clip-path-1564)">
        <g id="Сгруппировать_6427" data-name="Сгруппировать 6427" transform="translate(-155.257 -246.401)" clip-path="url(#clip-path-18)">
          <path id="Контур_6973" data-name="Контур 6973" d="M197.175-247.127h6.8v6.158h-6.8Z" transform="translate(-44.557 490.89)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6430" data-name="Сгруппировать 6430" transform="translate(99.66 -399.681)" clip-path="url(#clip-path-1566)">
        <g id="Сгруппировать_6429" data-name="Сгруппировать 6429" transform="translate(-147.843 -243.284)" clip-path="url(#clip-path-18)">
          <path id="Контур_6976" data-name="Контур 6976" d="M183.128-253.033h6.8v6.158h-6.8Z" transform="translate(-37.924 493.678)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6432" data-name="Сгруппировать 6432" transform="translate(100.728 -399.064)" clip-path="url(#clip-path-1568)">
        <g id="Сгруппировать_6431" data-name="Сгруппировать 6431" transform="translate(-148.911 -243.901)" clip-path="url(#clip-path-18)">
          <path id="Контур_6979" data-name="Контур 6979" d="M185.152-251.864h6.8v6.158h-6.8Z" transform="translate(-38.879 493.126)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6434" data-name="Сгруппировать 6434" transform="translate(101.796 -398.447)" clip-path="url(#clip-path-1570)">
        <g id="Сгруппировать_6433" data-name="Сгруппировать 6433" transform="translate(-149.979 -244.518)" clip-path="url(#clip-path-18)">
          <path id="Контур_6982" data-name="Контур 6982" d="M187.176-250.695h11.068v8.625H187.176Z" transform="translate(-39.835 492.574)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6436" data-name="Сгруппировать 6436" transform="translate(111.205 -397.888)" clip-path="url(#clip-path-1572)">
        <g id="Сгруппировать_6435" data-name="Сгруппировать 6435" transform="translate(-159.389 -245.077)" clip-path="url(#clip-path-18)">
          <path id="Контур_6985" data-name="Контур 6985" d="M205-249.636h6.8v6.158H205Z" transform="translate(-48.255 492.074)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6438" data-name="Сгруппировать 6438" transform="translate(112.273 -397.271)" clip-path="url(#clip-path-1574)">
        <g id="Сгруппировать_6437" data-name="Сгруппировать 6437" transform="translate(-160.456 -245.694)" clip-path="url(#clip-path-18)">
          <path id="Контур_6988" data-name="Контур 6988" d="M207.027-248.467h6.8v6.157h-6.8Z" transform="translate(-49.21 491.522)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6440" data-name="Сгруппировать 6440" transform="translate(113.341 -396.654)" clip-path="url(#clip-path-1576)">
        <g id="Сгруппировать_6439" data-name="Сгруппировать 6439" transform="translate(-161.524 -246.311)" clip-path="url(#clip-path-18)">
          <path id="Контур_6991" data-name="Контур 6991" d="M209.051-247.3h7.864v6.774h-7.864Z" transform="translate(-50.165 490.97)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6442" data-name="Сгруппировать 6442" transform="translate(110.191 -397.219)" clip-path="url(#clip-path-1578)">
        <g id="Сгруппировать_6441" data-name="Сгруппировать 6441" transform="translate(-158.374 -245.746)" clip-path="url(#clip-path-18)">
          <path id="Контур_6994" data-name="Контур 6994" d="M203.082-248.368h6.8v6.158h-6.8Z" transform="translate(-47.347 491.475)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6444" data-name="Сгруппировать 6444" transform="translate(111.259 -396.602)" clip-path="url(#clip-path-1580)">
        <g id="Сгруппировать_6443" data-name="Сгруппировать 6443" transform="translate(-159.442 -246.363)" clip-path="url(#clip-path-18)">
          <path id="Контур_6997" data-name="Контур 6997" d="M205.106-247.2h6.8v6.157h-6.8Z" transform="translate(-48.302 490.923)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6446" data-name="Сгруппировать 6446" transform="translate(112.327 -395.985)" clip-path="url(#clip-path-1582)">
        <g id="Сгруппировать_6445" data-name="Сгруппировать 6445" transform="translate(-160.511 -246.98)" clip-path="url(#clip-path-18)">
          <path id="Контур_7000" data-name="Контур 7000" d="M207.13-246.03h6.8v6.157h-6.8Z" transform="translate(-49.258 490.372)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6448" data-name="Сгруппировать 6448" transform="translate(113.395 -395.368)" clip-path="url(#clip-path-1584)">
        <g id="Сгруппировать_6447" data-name="Сгруппировать 6447" transform="translate(-161.578 -247.597)" clip-path="url(#clip-path-18)">
          <path id="Контур_7003" data-name="Контур 7003" d="M209.152-244.861h6.8v6.157h-6.8Z" transform="translate(-50.213 489.82)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6450" data-name="Сгруппировать 6450" transform="translate(109.166 -396.577)" clip-path="url(#clip-path-1586)">
        <g id="Сгруппировать_6449" data-name="Сгруппировать 6449" transform="translate(-157.349 -246.389)" clip-path="url(#clip-path-18)">
          <path id="Контур_7006" data-name="Контур 7006" d="M201.14-247.151h6.8v6.158h-6.8Z" transform="translate(-46.43 490.901)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6452" data-name="Сгруппировать 6452" transform="translate(110.235 -395.96)" clip-path="url(#clip-path-1588)">
        <g id="Сгруппировать_6451" data-name="Сгруппировать 6451" transform="translate(-158.418 -247.005)" clip-path="url(#clip-path-18)">
          <path id="Контур_7009" data-name="Контур 7009" d="M203.165-245.983h6.8v6.158h-6.8Z" transform="translate(-47.386 490.349)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6454" data-name="Сгруппировать 6454" transform="translate(111.302 -395.286)" clip-path="url(#clip-path-1590)">
        <g id="Сгруппировать_6453" data-name="Сгруппировать 6453" transform="translate(-159.485 -247.679)" clip-path="url(#clip-path-18)">
          <path id="Контур_7012" data-name="Контур 7012" d="M205.188-244.706h7.865v6.774h-7.865Z" transform="translate(-48.341 489.746)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6456" data-name="Сгруппировать 6456" transform="translate(108.141 -395.947)" clip-path="url(#clip-path-1592)">
        <g id="Сгруппировать_6455" data-name="Сгруппировать 6455" transform="translate(-156.325 -247.018)" clip-path="url(#clip-path-18)">
          <path id="Контур_7015" data-name="Контур 7015" d="M199.2-245.959H206v6.158h-6.8Z" transform="translate(-45.513 490.338)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6458" data-name="Сгруппировать 6458" transform="translate(109.209 -395.33)" clip-path="url(#clip-path-1594)">
        <g id="Сгруппировать_6457" data-name="Сгруппировать 6457" transform="translate(-157.393 -247.635)" clip-path="url(#clip-path-18)">
          <path id="Контур_7018" data-name="Контур 7018" d="M201.222-244.79h6.8v6.157h-6.8Z" transform="translate(-46.468 489.786)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6460" data-name="Сгруппировать 6460" transform="translate(110.278 -394.714)" clip-path="url(#clip-path-1596)">
        <g id="Сгруппировать_6459" data-name="Сгруппировать 6459" transform="translate(-158.461 -248.252)" clip-path="url(#clip-path-18)">
          <path id="Контур_7021" data-name="Контур 7021" d="M203.246-243.621h7.864v6.79h-7.864Z" transform="translate(-47.424 489.234)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6462" data-name="Сгруппировать 6462" transform="translate(107.136 -395.363)" clip-path="url(#clip-path-1598)">
        <g id="Сгруппировать_6461" data-name="Сгруппировать 6461" transform="translate(-155.319 -247.602)" clip-path="url(#clip-path-18)">
          <path id="Контур_7024" data-name="Контур 7024" d="M197.293-244.851h6.8v6.158h-6.8Z" transform="translate(-44.613 489.815)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6464" data-name="Сгруппировать 6464" transform="translate(109.316 -394.105)" clip-path="url(#clip-path-1600)">
        <g id="Сгруппировать_6463" data-name="Сгруппировать 6463" transform="translate(-157.499 -248.86)" clip-path="url(#clip-path-18)">
          <path id="Контур_7027" data-name="Контур 7027" d="M201.424-242.468h7.865v6.774h-7.865Z" transform="translate(-46.564 488.689)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6466" data-name="Сгруппировать 6466" transform="translate(108.203 -394.746)" clip-path="url(#clip-path-1602)">
        <g id="Сгруппировать_6465" data-name="Сгруппировать 6465" transform="translate(-156.386 -248.219)" clip-path="url(#clip-path-18)">
          <path id="Контур_7030" data-name="Контур 7030" d="M199.316-243.682h6.8v6.158h-6.8Z" transform="translate(-45.568 489.263)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6468" data-name="Сгруппировать 6468" transform="translate(101.602 -397.268)" clip-path="url(#clip-path-1490)">
        <g id="Сгруппировать_6467" data-name="Сгруппировать 6467" transform="translate(-149.785 -245.697)" clip-path="url(#clip-path-18)">
          <path id="Контур_7033" data-name="Контур 7033" d="M186.807-248.461h5.5v5.551h-5.5Z" transform="translate(-39.661 491.52)" fill="#3f4954"/>
        </g>
      </g>
      <g id="Сгруппировать_6470" data-name="Сгруппировать 6470" transform="translate(99.543 -397.242)" clip-path="url(#clip-path-1492)">
        <g id="Сгруппировать_6469" data-name="Сгруппировать 6469" transform="translate(-147.726 -245.724)" clip-path="url(#clip-path-18)">
          <path id="Контур_7036" data-name="Контур 7036" d="M182.907-248.411h7.336v6.72h-7.336Z" transform="translate(-37.819 491.496)" fill="#3f4954"/>
        </g>
      </g>
      <g id="Сгруппировать_6472" data-name="Сгруппировать 6472" transform="translate(101.821 -397.242)" clip-path="url(#clip-path-1496)">
        <g id="Сгруппировать_6471" data-name="Сгруппировать 6471" transform="translate(-150.004 -245.724)" clip-path="url(#clip-path-18)">
          <path id="Контур_7039" data-name="Контур 7039" d="M187.223-248.411h9.965v8.231h-9.965Z" transform="translate(-39.858 491.496)" fill="#3f4954"/>
        </g>
      </g>
      <g id="Сгруппировать_6474" data-name="Сгруппировать 6474" transform="translate(106.508 -394.536)" clip-path="url(#clip-path-1498)">
        <g id="Сгруппировать_6473" data-name="Сгруппировать 6473" transform="translate(-154.691 -248.43)" clip-path="url(#clip-path-18)">
          <path id="Контур_7042" data-name="Контур 7042" d="M196.1-243.284h5.324v5.589H196.1Z" transform="translate(-44.051 489.075)" fill="#3f4954"/>
        </g>
      </g>
      <g id="Сгруппировать_6476" data-name="Сгруппировать 6476" transform="translate(106.508 -394.471)" clip-path="url(#clip-path-1500)">
        <g id="Сгруппировать_6475" data-name="Сгруппировать 6475" transform="translate(-154.691 -248.494)" clip-path="url(#clip-path-18)">
          <path id="Контур_7045" data-name="Контур 7045" d="M196.1-243.162h5.324v5.588H196.1Z" transform="translate(-44.051 489.017)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6478" data-name="Сгруппировать 6478" transform="translate(99.544 -395.918)" clip-path="url(#clip-path-1502)">
        <g id="Сгруппировать_6477" data-name="Сгруппировать 6477" transform="translate(-147.727 -247.047)" clip-path="url(#clip-path-18)">
          <path id="Контур_7048" data-name="Контур 7048" d="M182.908-245.9h9.965v8.23h-9.965Z" transform="translate(-37.82 490.311)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6480" data-name="Сгруппировать 6480" transform="translate(104.231 -393.212)" clip-path="url(#clip-path-1504)">
        <g id="Сгруппировать_6479" data-name="Сгруппировать 6479" transform="translate(-152.414 -249.753)" clip-path="url(#clip-path-18)">
          <path id="Контур_7051" data-name="Контур 7051" d="M191.789-240.776h5.5v5.551h-5.5Z" transform="translate(-42.014 487.89)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6482" data-name="Сгруппировать 6482" transform="translate(94.287 -403.564)" clip-path="url(#clip-path-1618)">
        <g id="Сгруппировать_6481" data-name="Сгруппировать 6481" transform="translate(-142.47 -239.402)" clip-path="url(#clip-path-18)">
          <path id="Контур_7054" data-name="Контур 7054" d="M172.947-260.39h28.41v19.239h-28.41Z" transform="translate(-33.116 497.153)" fill="#f4eeef"/>
        </g>
      </g>
      <g id="Сгруппировать_6484" data-name="Сгруппировать 6484" transform="translate(99.499 -397.268)" clip-path="url(#clip-path-1506)">
        <g id="Сгруппировать_6483" data-name="Сгруппировать 6483" transform="translate(-147.682 -245.697)" clip-path="url(#clip-path-18)">
          <path id="Контур_7057" data-name="Контур 7057" d="M182.823-248.461h12.333v9.36H182.823Z" transform="translate(-37.78 491.52)" fill="#c2bdbf"/>
        </g>
      </g>
      <g id="Сгруппировать_6486" data-name="Сгруппировать 6486" transform="translate(109.209 -395.13)" clip-path="url(#clip-path-1622)">
        <g id="Сгруппировать_6485" data-name="Сгруппировать 6485" transform="translate(-157.392 -247.835)" clip-path="url(#clip-path-18)">
          <path id="Контур_7060" data-name="Контур 7060" d="M201.221-244.41H214.71V-233.6H201.221Z" transform="translate(-46.468 489.606)" fill="#d3cdcc"/>
        </g>
      </g>
      <g id="Сгруппировать_6488" data-name="Сгруппировать 6488" transform="translate(94.286 -398.609)" clip-path="url(#clip-path-1624)">
        <g id="Сгруппировать_6487" data-name="Сгруппировать 6487" transform="translate(-142.469 -244.356)" clip-path="url(#clip-path-18)">
          <path id="Контур_7063" data-name="Контур 7063" d="M172.946-251h20.2v14.36h-20.2Z" transform="translate(-33.116 492.719)" fill="#c2bdbf"/>
        </g>
      </g>
      <g id="Сгруппировать_6490" data-name="Сгруппировать 6490" transform="translate(102.639 -402.821)" clip-path="url(#clip-path-1510)">
        <g id="Сгруппировать_6489" data-name="Сгруппировать 6489" transform="translate(-150.823 -240.144)" clip-path="url(#clip-path-18)">
          <path id="Контур_7066" data-name="Контур 7066" d="M188.773-258.983h6.8v6.157h-6.8Z" transform="translate(-40.59 496.488)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6492" data-name="Сгруппировать 6492" transform="translate(104.798 -401.589)" clip-path="url(#clip-path-1512)">
        <g id="Сгруппировать_6491" data-name="Сгруппировать 6491" transform="translate(-152.981 -241.376)" clip-path="url(#clip-path-18)">
          <path id="Контур_7069" data-name="Контур 7069" d="M192.863-256.649h6.8v6.158h-6.8Z" transform="translate(-42.521 495.386)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6494" data-name="Сгруппировать 6494" transform="translate(103.659 -402.251)" clip-path="url(#clip-path-1514)">
        <g id="Сгруппировать_6493" data-name="Сгруппировать 6493" transform="translate(-151.842 -240.714)" clip-path="url(#clip-path-18)">
          <path id="Контур_7072" data-name="Контур 7072" d="M190.7-257.9h6.8v6.158h-6.8Z" transform="translate(-41.502 495.978)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6496" data-name="Сгруппировать 6496" transform="translate(105.875 -400.962)" clip-path="url(#clip-path-1516)">
        <g id="Сгруппировать_6495" data-name="Сгруппировать 6495" transform="translate(-154.059 -242.003)" clip-path="url(#clip-path-18)">
          <path id="Контур_7075" data-name="Контур 7075" d="M194.905-255.461h6.8v6.158h-6.8Z" transform="translate(-43.485 494.825)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6498" data-name="Сгруппировать 6498" transform="translate(106.938 -400.351)" clip-path="url(#clip-path-1518)">
        <g id="Сгруппировать_6497" data-name="Сгруппировать 6497" transform="translate(-155.121 -242.615)" clip-path="url(#clip-path-18)">
          <path id="Контур_7078" data-name="Контур 7078" d="M196.918-254.3h6.8v6.157h-6.8Z" transform="translate(-44.436 494.278)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6500" data-name="Сгруппировать 6500" transform="translate(108.001 -399.738)" clip-path="url(#clip-path-1520)">
        <g id="Сгруппировать_6499" data-name="Сгруппировать 6499" transform="translate(-156.184 -243.227)" clip-path="url(#clip-path-18)">
          <path id="Контур_7081" data-name="Контур 7081" d="M198.932-253.142h6.8v6.157h-6.8Z" transform="translate(-45.387 493.73)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6502" data-name="Сгруппировать 6502" transform="translate(101.619 -402.145)" clip-path="url(#clip-path-1522)">
        <g id="Сгруппировать_6501" data-name="Сгруппировать 6501" transform="translate(-149.802 -240.82)" clip-path="url(#clip-path-18)">
          <path id="Контур_7084" data-name="Контур 7084" d="M186.841-257.7h7.865v6.774h-7.865Z" transform="translate(-39.677 495.883)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6504" data-name="Сгруппировать 6504" transform="translate(103.704 -400.963)" clip-path="url(#clip-path-1524)">
        <g id="Сгруппировать_6503" data-name="Сгруппировать 6503" transform="translate(-151.887 -242.002)" clip-path="url(#clip-path-18)">
          <path id="Контур_7087" data-name="Контур 7087" d="M190.79-255.462h6.8v6.158h-6.8Z" transform="translate(-41.542 494.826)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6506" data-name="Сгруппировать 6506" transform="translate(104.852 -400.303)" clip-path="url(#clip-path-1526)">
        <g id="Сгруппировать_6505" data-name="Сгруппировать 6505" transform="translate(-153.035 -242.662)" clip-path="url(#clip-path-18)">
          <path id="Контур_7090" data-name="Контур 7090" d="M192.965-254.212h6.8v6.158h-6.8Z" transform="translate(-42.569 494.235)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6508" data-name="Сгруппировать 6508" transform="translate(105.919 -399.687)" clip-path="url(#clip-path-1528)">
        <g id="Сгруппировать_6507" data-name="Сгруппировать 6507" transform="translate(-154.102 -243.279)" clip-path="url(#clip-path-18)">
          <path id="Контур_7093" data-name="Контур 7093" d="M194.988-253.044h6.8v6.158h-6.8Z" transform="translate(-43.525 493.684)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6510" data-name="Сгруппировать 6510" transform="translate(106.987 -399.069)" clip-path="url(#clip-path-1530)">
        <g id="Сгруппировать_6509" data-name="Сгруппировать 6509" transform="translate(-155.17 -243.896)" clip-path="url(#clip-path-18)">
          <path id="Контур_7096" data-name="Контур 7096" d="M197.012-251.874h6.8v6.158h-6.8Z" transform="translate(-44.48 493.131)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6512" data-name="Сгруппировать 6512" transform="translate(100.589 -401.49)" clip-path="url(#clip-path-1532)">
        <g id="Сгруппировать_6511" data-name="Сгруппировать 6511" transform="translate(-148.772 -241.476)" clip-path="url(#clip-path-18)">
          <path id="Контур_7099" data-name="Контур 7099" d="M184.888-256.46h7.864v6.774h-7.864Z" transform="translate(-38.755 495.297)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6514" data-name="Сгруппировать 6514" transform="translate(102.724 -400.313)" clip-path="url(#clip-path-1534)">
        <g id="Сгруппировать_6513" data-name="Сгруппировать 6513" transform="translate(-150.907 -242.652)" clip-path="url(#clip-path-18)">
          <path id="Контур_7102" data-name="Контур 7102" d="M188.934-254.23h6.8v6.158h-6.8Z" transform="translate(-40.666 494.244)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6516" data-name="Сгруппировать 6516" transform="translate(103.827 -399.661)" clip-path="url(#clip-path-1536)">
        <g id="Сгруппировать_6515" data-name="Сгруппировать 6515" transform="translate(-152.01 -243.304)" clip-path="url(#clip-path-18)">
          <path id="Контур_7105" data-name="Контур 7105" d="M191.023-253h6.8v6.158h-6.8Z" transform="translate(-41.652 493.661)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6518" data-name="Сгруппировать 6518" transform="translate(104.895 -399.044)" clip-path="url(#clip-path-1538)">
        <g id="Сгруппировать_6517" data-name="Сгруппировать 6517" transform="translate(-153.078 -243.921)" clip-path="url(#clip-path-18)">
          <path id="Контур_7108" data-name="Контур 7108" d="M193.047-251.827h6.8v6.158h-6.8Z" transform="translate(-42.608 493.109)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6520" data-name="Сгруппировать 6520" transform="translate(109.069 -399.122)" clip-path="url(#clip-path-1540)">
        <g id="Сгруппировать_6519" data-name="Сгруппировать 6519" transform="translate(-157.252 -243.844)" clip-path="url(#clip-path-18)">
          <path id="Контур_7111" data-name="Контур 7111" d="M200.957-251.973h6.8v6.158h-6.8Z" transform="translate(-46.343 493.178)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6522" data-name="Сгруппировать 6522" transform="translate(108.055 -398.453)" clip-path="url(#clip-path-1542)">
        <g id="Сгруппировать_6521" data-name="Сгруппировать 6521" transform="translate(-156.239 -244.512)" clip-path="url(#clip-path-18)">
          <path id="Контур_7114" data-name="Контур 7114" d="M199.035-250.706h6.8v6.158h-6.8Z" transform="translate(-45.436 492.58)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6524" data-name="Сгруппировать 6524" transform="translate(105.962 -398.427)" clip-path="url(#clip-path-1544)">
        <g id="Сгруппировать_6523" data-name="Сгруппировать 6523" transform="translate(-154.145 -244.538)" clip-path="url(#clip-path-18)">
          <path id="Контур_7117" data-name="Контур 7117" d="M195.07-250.658h6.8v6.157h-6.8Z" transform="translate(-43.563 492.557)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6526" data-name="Сгруппировать 6526" transform="translate(107.03 -397.81)" clip-path="url(#clip-path-1546)">
        <g id="Сгруппировать_6525" data-name="Сгруппировать 6525" transform="translate(-155.214 -245.155)" clip-path="url(#clip-path-18)">
          <path id="Контур_7120" data-name="Контур 7120" d="M197.094-249.489h6.8v6.157h-6.8Z" transform="translate(-44.519 492.005)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6528" data-name="Сгруппировать 6528" transform="translate(99.619 -400.884)" clip-path="url(#clip-path-1548)">
        <g id="Сгруппировать_6527" data-name="Сгруппировать 6527" transform="translate(-147.803 -242.081)" clip-path="url(#clip-path-18)">
          <path id="Контур_7123" data-name="Контур 7123" d="M183.051-255.312h8.932v7.391h-8.932Z" transform="translate(-37.888 494.755)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6530" data-name="Сгруппировать 6530" transform="translate(102.802 -399.031)" clip-path="url(#clip-path-1550)">
        <g id="Сгруппировать_6529" data-name="Сгруппировать 6529" transform="translate(-150.985 -243.934)" clip-path="url(#clip-path-18)">
          <path id="Контур_7126" data-name="Контур 7126" d="M189.082-251.8h6.8v6.157h-6.8Z" transform="translate(-40.735 493.097)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6532" data-name="Сгруппировать 6532" transform="translate(103.87 -398.414)" clip-path="url(#clip-path-1552)">
        <g id="Сгруппировать_6531" data-name="Сгруппировать 6531" transform="translate(-152.053 -244.551)" clip-path="url(#clip-path-18)">
          <path id="Контур_7129" data-name="Контур 7129" d="M191.106-250.633h6.8v6.157h-6.8Z" transform="translate(-41.691 492.545)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6534" data-name="Сгруппировать 6534" transform="translate(104.938 -397.798)" clip-path="url(#clip-path-1554)">
        <g id="Сгруппировать_6533" data-name="Сгруппировать 6533" transform="translate(-153.121 -245.167)" clip-path="url(#clip-path-18)">
          <path id="Контур_7132" data-name="Контур 7132" d="M193.129-249.465h6.8v6.158h-6.8Z" transform="translate(-42.647 491.994)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6536" data-name="Сгруппировать 6536" transform="translate(106.006 -397.181)" clip-path="url(#clip-path-1556)">
        <g id="Сгруппировать_6535" data-name="Сгруппировать 6535" transform="translate(-154.189 -245.784)" clip-path="url(#clip-path-18)">
          <path id="Контур_7135" data-name="Контур 7135" d="M195.152-248.3h6.8v6.158h-6.8Z" transform="translate(-43.602 491.442)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6538" data-name="Сгруппировать 6538" transform="translate(110.137 -398.505)" clip-path="url(#clip-path-1558)">
        <g id="Сгруппировать_6537" data-name="Сгруппировать 6537" transform="translate(-158.32 -244.461)" clip-path="url(#clip-path-18)">
          <path id="Контур_7138" data-name="Контур 7138" d="M202.98-250.8h6.8v6.157h-6.8Z" transform="translate(-47.299 492.626)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6540" data-name="Сгруппировать 6540" transform="translate(109.123 -397.836)" clip-path="url(#clip-path-1560)">
        <g id="Сгруппировать_6539" data-name="Сгруппировать 6539" transform="translate(-157.306 -245.13)" clip-path="url(#clip-path-18)">
          <path id="Контур_7141" data-name="Контур 7141" d="M201.059-249.537h6.8v6.157h-6.8Z" transform="translate(-46.391 492.027)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6542" data-name="Сгруппировать 6542" transform="translate(108.099 -397.193)" clip-path="url(#clip-path-1562)">
        <g id="Сгруппировать_6541" data-name="Сгруппировать 6541" transform="translate(-156.282 -245.772)" clip-path="url(#clip-path-18)">
          <path id="Контур_7144" data-name="Контур 7144" d="M199.117-248.32h6.8v6.158h-6.8Z" transform="translate(-45.475 491.453)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6544" data-name="Сгруппировать 6544" transform="translate(107.074 -396.564)" clip-path="url(#clip-path-1564)">
        <g id="Сгруппировать_6543" data-name="Сгруппировать 6543" transform="translate(-155.257 -246.401)" clip-path="url(#clip-path-18)">
          <path id="Контур_7147" data-name="Контур 7147" d="M197.175-247.127h6.8v6.158h-6.8Z" transform="translate(-44.557 490.89)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6546" data-name="Сгруппировать 6546" transform="translate(99.66 -399.681)" clip-path="url(#clip-path-1566)">
        <g id="Сгруппировать_6545" data-name="Сгруппировать 6545" transform="translate(-147.843 -243.284)" clip-path="url(#clip-path-18)">
          <path id="Контур_7150" data-name="Контур 7150" d="M183.128-253.033h6.8v6.158h-6.8Z" transform="translate(-37.924 493.678)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6548" data-name="Сгруппировать 6548" transform="translate(100.728 -399.064)" clip-path="url(#clip-path-1568)">
        <g id="Сгруппировать_6547" data-name="Сгруппировать 6547" transform="translate(-148.911 -243.901)" clip-path="url(#clip-path-18)">
          <path id="Контур_7153" data-name="Контур 7153" d="M185.152-251.864h6.8v6.158h-6.8Z" transform="translate(-38.879 493.126)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6550" data-name="Сгруппировать 6550" transform="translate(101.796 -398.447)" clip-path="url(#clip-path-1570)">
        <g id="Сгруппировать_6549" data-name="Сгруппировать 6549" transform="translate(-149.979 -244.518)" clip-path="url(#clip-path-18)">
          <path id="Контур_7156" data-name="Контур 7156" d="M187.176-250.695h11.068v8.625H187.176Z" transform="translate(-39.835 492.574)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6552" data-name="Сгруппировать 6552" transform="translate(111.205 -397.888)" clip-path="url(#clip-path-1572)">
        <g id="Сгруппировать_6551" data-name="Сгруппировать 6551" transform="translate(-159.389 -245.077)" clip-path="url(#clip-path-18)">
          <path id="Контур_7159" data-name="Контур 7159" d="M205-249.636h6.8v6.158H205Z" transform="translate(-48.255 492.074)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6554" data-name="Сгруппировать 6554" transform="translate(112.273 -397.271)" clip-path="url(#clip-path-1574)">
        <g id="Сгруппировать_6553" data-name="Сгруппировать 6553" transform="translate(-160.456 -245.694)" clip-path="url(#clip-path-18)">
          <path id="Контур_7162" data-name="Контур 7162" d="M207.027-248.467h6.8v6.157h-6.8Z" transform="translate(-49.21 491.522)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6556" data-name="Сгруппировать 6556" transform="translate(113.341 -396.654)" clip-path="url(#clip-path-1576)">
        <g id="Сгруппировать_6555" data-name="Сгруппировать 6555" transform="translate(-161.524 -246.311)" clip-path="url(#clip-path-18)">
          <path id="Контур_7165" data-name="Контур 7165" d="M209.051-247.3h7.864v6.774h-7.864Z" transform="translate(-50.165 490.97)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6558" data-name="Сгруппировать 6558" transform="translate(110.191 -397.219)" clip-path="url(#clip-path-1578)">
        <g id="Сгруппировать_6557" data-name="Сгруппировать 6557" transform="translate(-158.374 -245.746)" clip-path="url(#clip-path-18)">
          <path id="Контур_7168" data-name="Контур 7168" d="M203.082-248.368h6.8v6.158h-6.8Z" transform="translate(-47.347 491.475)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6560" data-name="Сгруппировать 6560" transform="translate(111.259 -396.602)" clip-path="url(#clip-path-1580)">
        <g id="Сгруппировать_6559" data-name="Сгруппировать 6559" transform="translate(-159.442 -246.363)" clip-path="url(#clip-path-18)">
          <path id="Контур_7171" data-name="Контур 7171" d="M205.106-247.2h6.8v6.157h-6.8Z" transform="translate(-48.302 490.923)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6562" data-name="Сгруппировать 6562" transform="translate(112.327 -395.985)" clip-path="url(#clip-path-1582)">
        <g id="Сгруппировать_6561" data-name="Сгруппировать 6561" transform="translate(-160.511 -246.98)" clip-path="url(#clip-path-18)">
          <path id="Контур_7174" data-name="Контур 7174" d="M207.13-246.03h6.8v6.157h-6.8Z" transform="translate(-49.258 490.372)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6564" data-name="Сгруппировать 6564" transform="translate(113.395 -395.368)" clip-path="url(#clip-path-1584)">
        <g id="Сгруппировать_6563" data-name="Сгруппировать 6563" transform="translate(-161.578 -247.597)" clip-path="url(#clip-path-18)">
          <path id="Контур_7177" data-name="Контур 7177" d="M209.152-244.861h6.8v6.157h-6.8Z" transform="translate(-50.213 489.82)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6566" data-name="Сгруппировать 6566" transform="translate(109.166 -396.577)" clip-path="url(#clip-path-1586)">
        <g id="Сгруппировать_6565" data-name="Сгруппировать 6565" transform="translate(-157.349 -246.389)" clip-path="url(#clip-path-18)">
          <path id="Контур_7180" data-name="Контур 7180" d="M201.14-247.151h6.8v6.158h-6.8Z" transform="translate(-46.43 490.901)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6568" data-name="Сгруппировать 6568" transform="translate(110.235 -395.96)" clip-path="url(#clip-path-1588)">
        <g id="Сгруппировать_6567" data-name="Сгруппировать 6567" transform="translate(-158.418 -247.005)" clip-path="url(#clip-path-18)">
          <path id="Контур_7183" data-name="Контур 7183" d="M203.165-245.983h6.8v6.158h-6.8Z" transform="translate(-47.386 490.349)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6570" data-name="Сгруппировать 6570" transform="translate(111.302 -395.286)" clip-path="url(#clip-path-1590)">
        <g id="Сгруппировать_6569" data-name="Сгруппировать 6569" transform="translate(-159.485 -247.679)" clip-path="url(#clip-path-18)">
          <path id="Контур_7186" data-name="Контур 7186" d="M205.188-244.706h7.865v6.774h-7.865Z" transform="translate(-48.341 489.746)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6572" data-name="Сгруппировать 6572" transform="translate(108.141 -395.947)" clip-path="url(#clip-path-1592)">
        <g id="Сгруппировать_6571" data-name="Сгруппировать 6571" transform="translate(-156.325 -247.018)" clip-path="url(#clip-path-18)">
          <path id="Контур_7189" data-name="Контур 7189" d="M199.2-245.959H206v6.158h-6.8Z" transform="translate(-45.513 490.338)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6574" data-name="Сгруппировать 6574" transform="translate(109.209 -395.33)" clip-path="url(#clip-path-1594)">
        <g id="Сгруппировать_6573" data-name="Сгруппировать 6573" transform="translate(-157.393 -247.635)" clip-path="url(#clip-path-18)">
          <path id="Контур_7192" data-name="Контур 7192" d="M201.222-244.79h6.8v6.157h-6.8Z" transform="translate(-46.468 489.786)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6576" data-name="Сгруппировать 6576" transform="translate(110.278 -394.714)" clip-path="url(#clip-path-1596)">
        <g id="Сгруппировать_6575" data-name="Сгруппировать 6575" transform="translate(-158.461 -248.252)" clip-path="url(#clip-path-18)">
          <path id="Контур_7195" data-name="Контур 7195" d="M203.246-243.621h7.864v6.79h-7.864Z" transform="translate(-47.424 489.234)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6578" data-name="Сгруппировать 6578" transform="translate(107.136 -395.363)" clip-path="url(#clip-path-1598)">
        <g id="Сгруппировать_6577" data-name="Сгруппировать 6577" transform="translate(-155.319 -247.602)" clip-path="url(#clip-path-18)">
          <path id="Контур_7198" data-name="Контур 7198" d="M197.293-244.851h6.8v6.158h-6.8Z" transform="translate(-44.613 489.815)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6580" data-name="Сгруппировать 6580" transform="translate(109.316 -394.105)" clip-path="url(#clip-path-1600)">
        <g id="Сгруппировать_6579" data-name="Сгруппировать 6579" transform="translate(-157.499 -248.86)" clip-path="url(#clip-path-18)">
          <path id="Контур_7201" data-name="Контур 7201" d="M201.424-242.468h7.865v6.774h-7.865Z" transform="translate(-46.564 488.689)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6582" data-name="Сгруппировать 6582" transform="translate(108.203 -394.746)" clip-path="url(#clip-path-1602)">
        <g id="Сгруппировать_6581" data-name="Сгруппировать 6581" transform="translate(-156.386 -248.219)" clip-path="url(#clip-path-18)">
          <path id="Контур_7204" data-name="Контур 7204" d="M199.316-243.682h6.8v6.158h-6.8Z" transform="translate(-45.568 489.263)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6584" data-name="Сгруппировать 6584" transform="translate(103.569 -413.598)" clip-path="url(#clip-path-1720)">
        <g id="Сгруппировать_6583" data-name="Сгруппировать 6583" transform="translate(-151.752 -229.368)" clip-path="url(#clip-path-18)">
          <path id="Контур_7207" data-name="Контур 7207" d="M190.534-279.4h19v14.156h-19Z" transform="translate(-41.421 506.131)" fill="#353b3c"/>
        </g>
      </g>
      <g id="Сгруппировать_6586" data-name="Сгруппировать 6586" transform="translate(123.186 -367.034)" clip-path="url(#clip-path-1722)">
        <g id="Сгруппировать_6585" data-name="Сгруппировать 6585" transform="translate(-171.369 -275.932)" clip-path="url(#clip-path-18)">
          <path id="Контур_7210" data-name="Контур 7210" d="M227.705-191.174H247.78V-166.5H227.705Z" transform="translate(-58.974 464.467)" fill="#c2bdbf"/>
        </g>
      </g>
      <g id="Сгруппировать_6588" data-name="Сгруппировать 6588" transform="translate(123.708 -366.731)" clip-path="url(#clip-path-1724)">
        <g id="Сгруппировать_6587" data-name="Сгруппировать 6587" transform="translate(-171.891 -276.235)" clip-path="url(#clip-path-18)">
          <path id="Контур_7213" data-name="Контур 7213" d="M228.694-190.6h19.695v24.439H228.694Z" transform="translate(-59.441 464.196)" fill="#151919"/>
        </g>
      </g>
      <g id="Сгруппировать_6590" data-name="Сгруппировать 6590" transform="translate(124.076 -365.587)" clip-path="url(#clip-path-1726)">
        <g id="Сгруппировать_6589" data-name="Сгруппировать 6589" transform="translate(-172.259 -277.378)" clip-path="url(#clip-path-18)">
          <path id="Контур_7216" data-name="Контур 7216" d="M229.39-188.433h19v22.1h-19Z" transform="translate(-59.77 463.172)" fill="#262b2b"/>
        </g>
      </g>
      <g id="Сгруппировать_6592" data-name="Сгруппировать 6592" transform="translate(139.548 -349.257)" clip-path="url(#clip-path-1728)">
        <g id="Сгруппировать_6591" data-name="Сгруппировать 6591" transform="translate(-187.731 -293.708)" clip-path="url(#clip-path-18)">
          <path id="Контур_7219" data-name="Контур 7219" d="M258.707-157.491h5.5v5.551h-5.5Z" transform="translate(-73.615 448.561)" fill="#3f4954"/>
        </g>
      </g>
      <g id="Сгруппировать_6594" data-name="Сгруппировать 6594" transform="translate(139.768 -349.23)" clip-path="url(#clip-path-1730)">
        <g id="Сгруппировать_6593" data-name="Сгруппировать 6593" transform="translate(-187.951 -293.735)" clip-path="url(#clip-path-18)">
          <path id="Контур_7222" data-name="Контур 7222" d="M259.123-157.441h7.337v6.721h-7.337Z" transform="translate(-73.811 448.537)" fill="#3f4954"/>
        </g>
      </g>
      <g id="Сгруппировать_6596" data-name="Сгруппировать 6596" transform="translate(123.95 -347.119)" clip-path="url(#clip-path-1732)">
        <g id="Сгруппировать_6595" data-name="Сгруппировать 6595" transform="translate(-172.133 -295.846)" clip-path="url(#clip-path-18)">
          <path id="Контур_7225" data-name="Контур 7225" d="M229.152-153.44h5.409v6.11h-5.409Z" transform="translate(-59.658 446.648)" fill="#293341"/>
        </g>
      </g>
      <g id="Сгруппировать_6598" data-name="Сгруппировать 6598" transform="translate(134.861 -349.23)" clip-path="url(#clip-path-1734)">
        <g id="Сгруппировать_6597" data-name="Сгруппировать 6597" transform="translate(-183.044 -293.735)" clip-path="url(#clip-path-18)">
          <path id="Контур_7228" data-name="Контур 7228" d="M249.826-157.441h9.966v8.231h-9.966Z" transform="translate(-69.421 448.537)" fill="#3f4954"/>
        </g>
      </g>
      <g id="Сгруппировать_6600" data-name="Сгруппировать 6600" transform="translate(134.815 -346.524)" clip-path="url(#clip-path-1736)">
        <g id="Сгруппировать_6599" data-name="Сгруппировать 6599" transform="translate(-182.998 -296.441)" clip-path="url(#clip-path-18)">
          <path id="Контур_7231" data-name="Контур 7231" d="M249.739-152.313h5.325v5.588h-5.325Z" transform="translate(-69.38 446.115)" fill="#3f4954"/>
        </g>
      </g>
      <g id="Сгруппировать_6602" data-name="Сгруппировать 6602" transform="translate(134.815 -346.46)" clip-path="url(#clip-path-1738)">
        <g id="Сгруппировать_6601" data-name="Сгруппировать 6601" transform="translate(-182.998 -296.505)" clip-path="url(#clip-path-18)">
          <path id="Контур_7234" data-name="Контур 7234" d="M249.739-152.192h5.324v5.588h-5.324Z" transform="translate(-69.38 446.058)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6604" data-name="Сгруппировать 6604" transform="translate(137.138 -347.907)" clip-path="url(#clip-path-1740)">
        <g id="Сгруппировать_6603" data-name="Сгруппировать 6603" transform="translate(-185.321 -295.058)" clip-path="url(#clip-path-18)">
          <path id="Контур_7237" data-name="Контур 7237" d="M254.141-154.934h9.965v8.231h-9.965Z" transform="translate(-71.458 447.353)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6606" data-name="Сгруппировать 6606" transform="translate(136.919 -345.201)" clip-path="url(#clip-path-1742)">
        <g id="Сгруппировать_6605" data-name="Сгруппировать 6605" transform="translate(-185.102 -297.764)" clip-path="url(#clip-path-18)">
          <path id="Контур_7240" data-name="Контур 7240" d="M253.725-149.807h5.5v5.551h-5.5Z" transform="translate(-71.262 444.932)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6608" data-name="Сгруппировать 6608" transform="translate(134.815 -349.257)" clip-path="url(#clip-path-1744)">
        <g id="Сгруппировать_6607" data-name="Сгруппировать 6607" transform="translate(-182.999 -293.708)" clip-path="url(#clip-path-18)">
          <path id="Контур_7243" data-name="Контур 7243" d="M249.74-157.491h12.333v9.36H249.74Z" transform="translate(-69.38 448.561)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6610" data-name="Сгруппировать 6610" transform="translate(124.08 -346.936)" clip-path="url(#clip-path-1746)">
        <g id="Сгруппировать_6609" data-name="Сгруппировать 6609" transform="translate(-172.263 -296.029)" clip-path="url(#clip-path-18)">
          <path id="Контур_7246" data-name="Контур 7246" d="M229.4-153.094h13.359v10.622H229.4Z" transform="translate(-59.774 446.484)" fill="#293341"/>
        </g>
      </g>
      <g id="Сгруппировать_6612" data-name="Сгруппировать 6612" transform="translate(137.211 -354.81)" clip-path="url(#clip-path-1748)">
        <g id="Сгруппировать_6611" data-name="Сгруппировать 6611" transform="translate(-185.395 -288.155)" clip-path="url(#clip-path-18)">
          <path id="Контур_7249" data-name="Контур 7249" d="M254.279-168.013h6.8v6.157h-6.8Z" transform="translate(-71.524 453.529)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6614" data-name="Сгруппировать 6614" transform="translate(135.053 -353.578)" clip-path="url(#clip-path-1750)">
        <g id="Сгруппировать_6613" data-name="Сгруппировать 6613" transform="translate(-183.236 -289.387)" clip-path="url(#clip-path-18)">
          <path id="Контур_7252" data-name="Контур 7252" d="M250.19-165.679h6.8v6.158h-6.8Z" transform="translate(-69.592 452.427)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6616" data-name="Сгруппировать 6616" transform="translate(136.192 -354.24)" clip-path="url(#clip-path-1752)">
        <g id="Сгруппировать_6615" data-name="Сгруппировать 6615" transform="translate(-184.375 -288.725)" clip-path="url(#clip-path-18)">
          <path id="Контур_7255" data-name="Контур 7255" d="M252.348-166.933h6.8v6.158h-6.8Z" transform="translate(-70.612 453.019)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6618" data-name="Сгруппировать 6618" transform="translate(133.975 -352.951)" clip-path="url(#clip-path-1754)">
        <g id="Сгруппировать_6617" data-name="Сгруппировать 6617" transform="translate(-182.158 -290.014)" clip-path="url(#clip-path-18)">
          <path id="Контур_7258" data-name="Контур 7258" d="M248.147-164.491h6.8v6.158h-6.8Z" transform="translate(-68.628 451.866)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6620" data-name="Сгруппировать 6620" transform="translate(132.913 -352.34)" clip-path="url(#clip-path-1756)">
        <g id="Сгруппировать_6619" data-name="Сгруппировать 6619" transform="translate(-181.096 -290.625)" clip-path="url(#clip-path-18)">
          <path id="Контур_7261" data-name="Контур 7261" d="M246.135-163.333h6.8v6.158h-6.8Z" transform="translate(-67.677 451.319)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6622" data-name="Сгруппировать 6622" transform="translate(131.849 -351.727)" clip-path="url(#clip-path-1758)">
        <g id="Сгруппировать_6621" data-name="Сгруппировать 6621" transform="translate(-180.033 -291.238)" clip-path="url(#clip-path-18)">
          <path id="Контур_7264" data-name="Контур 7264" d="M244.12-162.172h6.8v6.158h-6.8Z" transform="translate(-66.726 450.771)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6624" data-name="Сгруппировать 6624" transform="translate(137.164 -354.134)" clip-path="url(#clip-path-1760)">
        <g id="Сгруппировать_6623" data-name="Сгруппировать 6623" transform="translate(-185.347 -288.831)" clip-path="url(#clip-path-18)">
          <path id="Контур_7267" data-name="Контур 7267" d="M254.19-166.732h7.865v6.775H254.19Z" transform="translate(-71.481 452.925)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6626" data-name="Сгруппировать 6626" transform="translate(136.147 -352.952)" clip-path="url(#clip-path-1762)">
        <g id="Сгруппировать_6625" data-name="Сгруппировать 6625" transform="translate(-184.33 -290.014)" clip-path="url(#clip-path-18)">
          <path id="Контур_7270" data-name="Контур 7270" d="M252.262-164.492h6.8v6.157h-6.8Z" transform="translate(-70.571 451.867)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6628" data-name="Сгруппировать 6628" transform="translate(134.999 -352.292)" clip-path="url(#clip-path-1764)">
        <g id="Сгруппировать_6627" data-name="Сгруппировать 6627" transform="translate(-183.182 -290.673)" clip-path="url(#clip-path-18)">
          <path id="Контур_7273" data-name="Контур 7273" d="M250.088-163.242h6.8v6.158h-6.8Z" transform="translate(-69.544 451.276)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6630" data-name="Сгруппировать 6630" transform="translate(133.931 -351.675)" clip-path="url(#clip-path-1766)">
        <g id="Сгруппировать_6629" data-name="Сгруппировать 6629" transform="translate(-182.115 -291.29)" clip-path="url(#clip-path-18)">
          <path id="Контур_7276" data-name="Контур 7276" d="M248.064-162.074h6.8v6.158h-6.8Z" transform="translate(-68.589 450.725)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6632" data-name="Сгруппировать 6632" transform="translate(132.864 -351.058)" clip-path="url(#clip-path-1768)">
        <g id="Сгруппировать_6631" data-name="Сгруппировать 6631" transform="translate(-181.047 -291.907)" clip-path="url(#clip-path-18)">
          <path id="Контур_7279" data-name="Контур 7279" d="M246.042-160.9h6.8v6.158h-6.8Z" transform="translate(-67.634 450.173)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6634" data-name="Сгруппировать 6634" transform="translate(138.194 -353.478)" clip-path="url(#clip-path-1770)">
        <g id="Сгруппировать_6633" data-name="Сгруппировать 6633" transform="translate(-186.378 -289.487)" clip-path="url(#clip-path-18)">
          <path id="Контур_7282" data-name="Контур 7282" d="M256.142-165.49h7.864v6.774h-7.864Z" transform="translate(-72.403 452.338)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6636" data-name="Сгруппировать 6636" transform="translate(137.126 -352.301)" clip-path="url(#clip-path-1772)">
        <g id="Сгруппировать_6635" data-name="Сгруппировать 6635" transform="translate(-185.31 -290.664)" clip-path="url(#clip-path-18)">
          <path id="Контур_7285" data-name="Контур 7285" d="M254.119-163.26h6.8v6.158h-6.8Z" transform="translate(-71.448 451.285)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6638" data-name="Сгруппировать 6638" transform="translate(136.024 -351.65)" clip-path="url(#clip-path-1774)">
        <g id="Сгруппировать_6637" data-name="Сгруппировать 6637" transform="translate(-184.207 -291.315)" clip-path="url(#clip-path-18)">
          <path id="Контур_7288" data-name="Контур 7288" d="M252.029-162.025h6.8v6.158h-6.8Z" transform="translate(-70.461 450.702)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6640" data-name="Сгруппировать 6640" transform="translate(134.956 -351.033)" clip-path="url(#clip-path-1776)">
        <g id="Сгруппировать_6639" data-name="Сгруппировать 6639" transform="translate(-183.139 -291.932)" clip-path="url(#clip-path-18)">
          <path id="Контур_7291" data-name="Контур 7291" d="M250.006-160.857h6.8v6.158h-6.8Z" transform="translate(-69.506 450.15)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6642" data-name="Сгруппировать 6642" transform="translate(130.781 -351.111)" clip-path="url(#clip-path-1778)">
        <g id="Сгруппировать_6641" data-name="Сгруппировать 6641" transform="translate(-178.964 -291.854)" clip-path="url(#clip-path-18)">
          <path id="Контур_7294" data-name="Контур 7294" d="M242.1-161h6.8v6.158h-6.8Z" transform="translate(-65.77 450.22)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6644" data-name="Сгруппировать 6644" transform="translate(131.796 -350.441)" clip-path="url(#clip-path-1780)">
        <g id="Сгруппировать_6643" data-name="Сгруппировать 6643" transform="translate(-179.979 -292.524)" clip-path="url(#clip-path-18)">
          <path id="Контур_7297" data-name="Контур 7297" d="M244.018-159.735h6.8v6.157h-6.8Z" transform="translate(-66.678 449.62)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6646" data-name="Сгруппировать 6646" transform="translate(133.888 -350.416)" clip-path="url(#clip-path-1782)">
        <g id="Сгруппировать_6645" data-name="Сгруппировать 6645" transform="translate(-182.071 -292.549)" clip-path="url(#clip-path-18)">
          <path id="Контур_7300" data-name="Контур 7300" d="M247.982-159.688h6.8v6.157h-6.8Z" transform="translate(-68.55 449.598)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6648" data-name="Сгруппировать 6648" transform="translate(132.82 -349.799)" clip-path="url(#clip-path-1784)">
        <g id="Сгруппировать_6647" data-name="Сгруппировать 6647" transform="translate(-181.003 -293.166)" clip-path="url(#clip-path-18)">
          <path id="Контур_7303" data-name="Контур 7303" d="M245.959-158.519h6.8v6.158h-6.8Z" transform="translate(-67.595 449.046)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6650" data-name="Сгруппировать 6650" transform="translate(138.095 -352.873)" clip-path="url(#clip-path-1786)">
        <g id="Сгруппировать_6649" data-name="Сгруппировать 6649" transform="translate(-186.278 -290.093)" clip-path="url(#clip-path-18)">
          <path id="Контур_7306" data-name="Контур 7306" d="M255.954-164.342h8.932v7.391h-8.932Z" transform="translate(-72.314 451.796)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6652" data-name="Сгруппировать 6652" transform="translate(137.049 -351.02)" clip-path="url(#clip-path-1788)">
        <g id="Сгруппировать_6651" data-name="Сгруппировать 6651" transform="translate(-185.232 -291.945)" clip-path="url(#clip-path-18)">
          <path id="Контур_7309" data-name="Контур 7309" d="M253.971-160.832h6.8v6.158h-6.8Z" transform="translate(-71.378 450.139)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6654" data-name="Сгруппировать 6654" transform="translate(135.981 -350.403)" clip-path="url(#clip-path-1790)">
        <g id="Сгруппировать_6653" data-name="Сгруппировать 6653" transform="translate(-184.164 -292.562)" clip-path="url(#clip-path-18)">
          <path id="Контур_7312" data-name="Контур 7312" d="M251.947-159.664h6.8v6.157h-6.8Z" transform="translate(-70.422 449.587)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6656" data-name="Сгруппировать 6656" transform="translate(134.913 -349.787)" clip-path="url(#clip-path-1792)">
        <g id="Сгруппировать_6655" data-name="Сгруппировать 6655" transform="translate(-183.096 -293.178)" clip-path="url(#clip-path-18)">
          <path id="Контур_7315" data-name="Контур 7315" d="M249.924-158.5h6.8v6.158h-6.8Z" transform="translate(-69.467 449.035)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6658" data-name="Сгруппировать 6658" transform="translate(133.845 -349.17)" clip-path="url(#clip-path-1794)">
        <g id="Сгруппировать_6657" data-name="Сгруппировать 6657" transform="translate(-182.028 -293.796)" clip-path="url(#clip-path-18)">
          <path id="Контур_7318" data-name="Контур 7318" d="M247.9-157.326h6.8v6.157h-6.8Z" transform="translate(-68.511 448.483)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6660" data-name="Сгруппировать 6660" transform="translate(129.713 -350.494)" clip-path="url(#clip-path-1796)">
        <g id="Сгруппировать_6659" data-name="Сгруппировать 6659" transform="translate(-177.897 -292.471)" clip-path="url(#clip-path-18)">
          <path id="Контур_7321" data-name="Контур 7321" d="M240.073-159.835h6.8v6.158h-6.8Z" transform="translate(-64.815 449.667)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6662" data-name="Сгруппировать 6662" transform="translate(130.728 -349.825)" clip-path="url(#clip-path-1798)">
        <g id="Сгруппировать_6661" data-name="Сгруппировать 6661" transform="translate(-178.911 -293.141)" clip-path="url(#clip-path-18)">
          <path id="Контур_7324" data-name="Контур 7324" d="M241.994-158.567h6.8v6.158h-6.8Z" transform="translate(-65.722 449.069)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6664" data-name="Сгруппировать 6664" transform="translate(131.752 -349.182)" clip-path="url(#clip-path-1800)">
        <g id="Сгруппировать_6663" data-name="Сгруппировать 6663" transform="translate(-179.935 -293.783)" clip-path="url(#clip-path-18)">
          <path id="Контур_7327" data-name="Контур 7327" d="M243.936-157.35h6.8v6.158h-6.8Z" transform="translate(-66.639 448.494)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6666" data-name="Сгруппировать 6666" transform="translate(132.777 -348.553)" clip-path="url(#clip-path-1802)">
        <g id="Сгруппировать_6665" data-name="Сгруппировать 6665" transform="translate(-180.961 -294.412)" clip-path="url(#clip-path-18)">
          <path id="Контур_7330" data-name="Контур 7330" d="M245.878-156.158h6.8V-150h-6.8Z" transform="translate(-67.556 447.931)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6668" data-name="Сгруппировать 6668" transform="translate(140.19 -351.67)" clip-path="url(#clip-path-1804)">
        <g id="Сгруппировать_6667" data-name="Сгруппировать 6667" transform="translate(-188.373 -291.295)" clip-path="url(#clip-path-18)">
          <path id="Контур_7333" data-name="Контур 7333" d="M259.924-162.063h6.8v6.158h-6.8Z" transform="translate(-74.189 450.72)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6670" data-name="Сгруппировать 6670" transform="translate(139.122 -351.053)" clip-path="url(#clip-path-1806)">
        <g id="Сгруппировать_6669" data-name="Сгруппировать 6669" transform="translate(-187.306 -291.912)" clip-path="url(#clip-path-18)">
          <path id="Контур_7336" data-name="Контур 7336" d="M257.9-160.894h6.8v6.158h-6.8Z" transform="translate(-73.234 450.168)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6672" data-name="Сгруппировать 6672" transform="translate(133.783 -350.436)" clip-path="url(#clip-path-1808)">
        <g id="Сгруппировать_6671" data-name="Сгруппировать 6671" transform="translate(-181.966 -292.529)" clip-path="url(#clip-path-18)">
          <path id="Контур_7339" data-name="Контур 7339" d="M247.783-159.725h11.068v8.625H247.783Z" transform="translate(-68.456 449.616)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6674" data-name="Сгруппировать 6674" transform="translate(128.645 -349.877)" clip-path="url(#clip-path-1810)">
        <g id="Сгруппировать_6673" data-name="Сгруппировать 6673" transform="translate(-176.829 -293.088)" clip-path="url(#clip-path-18)">
          <path id="Контур_7342" data-name="Контур 7342" d="M238.049-158.666h6.8v6.158h-6.8Z" transform="translate(-63.859 449.115)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6676" data-name="Сгруппировать 6676" transform="translate(127.577 -349.26)" clip-path="url(#clip-path-1812)">
        <g id="Сгруппировать_6675" data-name="Сгруппировать 6675" transform="translate(-175.761 -293.705)" clip-path="url(#clip-path-18)">
          <path id="Контур_7345" data-name="Контур 7345" d="M236.025-157.5h6.8v6.158h-6.8Z" transform="translate(-62.904 448.564)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6678" data-name="Сгруппировать 6678" transform="translate(125.442 -348.643)" clip-path="url(#clip-path-1814)">
        <g id="Сгруппировать_6677" data-name="Сгруппировать 6677" transform="translate(-173.625 -294.322)" clip-path="url(#clip-path-18)">
          <path id="Контур_7348" data-name="Контур 7348" d="M231.979-156.328h7.864v6.774h-7.864Z" transform="translate(-60.993 448.011)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6680" data-name="Сгруппировать 6680" transform="translate(129.66 -349.208)" clip-path="url(#clip-path-1816)">
        <g id="Сгруппировать_6679" data-name="Сгруппировать 6679" transform="translate(-177.843 -293.758)" clip-path="url(#clip-path-18)">
          <path id="Контур_7351" data-name="Контур 7351" d="M239.97-157.4h6.8v6.158h-6.8Z" transform="translate(-64.767 448.517)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6682" data-name="Сгруппировать 6682" transform="translate(128.592 -348.591)" clip-path="url(#clip-path-1818)">
        <g id="Сгруппировать_6681" data-name="Сгруппировать 6681" transform="translate(-176.775 -294.374)" clip-path="url(#clip-path-18)">
          <path id="Контур_7354" data-name="Контур 7354" d="M237.947-156.23h6.8v6.158h-6.8Z" transform="translate(-63.811 447.965)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6684" data-name="Сгруппировать 6684" transform="translate(127.524 -347.974)" clip-path="url(#clip-path-1820)">
        <g id="Сгруппировать_6683" data-name="Сгруппировать 6683" transform="translate(-175.707 -294.991)" clip-path="url(#clip-path-18)">
          <path id="Контур_7357" data-name="Контур 7357" d="M235.924-155.06h6.8v6.158h-6.8Z" transform="translate(-62.856 447.413)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6686" data-name="Сгруппировать 6686" transform="translate(126.456 -347.357)" clip-path="url(#clip-path-1822)">
        <g id="Сгруппировать_6685" data-name="Сгруппировать 6685" transform="translate(-174.639 -295.608)" clip-path="url(#clip-path-18)">
          <path id="Контур_7360" data-name="Контур 7360" d="M233.9-153.892h6.8v6.158h-6.8Z" transform="translate(-61.9 446.861)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6688" data-name="Сгруппировать 6688" transform="translate(130.684 -348.565)" clip-path="url(#clip-path-1824)">
        <g id="Сгруппировать_6687" data-name="Сгруппировать 6687" transform="translate(-178.867 -294.4)" clip-path="url(#clip-path-18)">
          <path id="Контур_7363" data-name="Контур 7363" d="M241.912-156.181h6.8v6.158h-6.8Z" transform="translate(-65.683 447.942)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6690" data-name="Сгруппировать 6690" transform="translate(129.616 -347.949)" clip-path="url(#clip-path-1826)">
        <g id="Сгруппировать_6689" data-name="Сгруппировать 6689" transform="translate(-177.799 -295.017)" clip-path="url(#clip-path-18)">
          <path id="Контур_7366" data-name="Контур 7366" d="M239.889-155.012h6.8v6.158h-6.8Z" transform="translate(-64.728 447.39)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6692" data-name="Сгруппировать 6692" transform="translate(127.48 -347.275)" clip-path="url(#clip-path-1828)">
        <g id="Сгруппировать_6691" data-name="Сгруппировать 6691" transform="translate(-175.664 -295.69)" clip-path="url(#clip-path-18)">
          <path id="Контур_7369" data-name="Контур 7369" d="M235.842-153.736h7.864v6.774h-7.864Z" transform="translate(-62.817 446.787)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6694" data-name="Сгруппировать 6694" transform="translate(131.709 -347.936)" clip-path="url(#clip-path-1830)">
        <g id="Сгруппировать_6693" data-name="Сгруппировать 6693" transform="translate(-179.892 -295.029)" clip-path="url(#clip-path-18)">
          <path id="Контур_7372" data-name="Контур 7372" d="M243.854-154.988h6.8v6.157h-6.8Z" transform="translate(-66.601 447.379)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6696" data-name="Сгруппировать 6696" transform="translate(130.641 -347.319)" clip-path="url(#clip-path-1832)">
        <g id="Сгруппировать_6695" data-name="Сгруппировать 6695" transform="translate(-178.824 -295.646)" clip-path="url(#clip-path-18)">
          <path id="Контур_7375" data-name="Контур 7375" d="M241.831-153.82h6.8v6.157h-6.8Z" transform="translate(-65.645 446.827)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6698" data-name="Сгруппировать 6698" transform="translate(128.506 -346.702)" clip-path="url(#clip-path-1834)">
        <g id="Сгруппировать_6697" data-name="Сгруппировать 6697" transform="translate(-176.689 -296.263)" clip-path="url(#clip-path-18)">
          <path id="Контур_7378" data-name="Контур 7378" d="M237.784-152.651h7.864v6.791h-7.864Z" transform="translate(-63.734 446.275)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6700" data-name="Сгруппировать 6700" transform="translate(132.715 -347.351)" clip-path="url(#clip-path-1836)">
        <g id="Сгруппировать_6699" data-name="Сгруппировать 6699" transform="translate(-180.898 -295.614)" clip-path="url(#clip-path-18)">
          <path id="Контур_7381" data-name="Контур 7381" d="M245.759-153.881h6.8v6.158h-6.8Z" transform="translate(-67.5 446.856)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6702" data-name="Сгруппировать 6702" transform="translate(129.467 -346.094)" clip-path="url(#clip-path-1838)">
        <g id="Сгруппировать_6701" data-name="Сгруппировать 6701" transform="translate(-177.65 -296.871)" clip-path="url(#clip-path-18)">
          <path id="Контур_7384" data-name="Контур 7384" d="M239.606-151.5h7.865v6.775h-7.865Z" transform="translate(-64.594 445.731)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6704" data-name="Сгруппировать 6704" transform="translate(131.647 -346.735)" clip-path="url(#clip-path-1840)">
        <g id="Сгруппировать_6703" data-name="Сгруппировать 6703" transform="translate(-179.83 -296.23)" clip-path="url(#clip-path-18)">
          <path id="Контур_7387" data-name="Контур 7387" d="M243.736-152.713h6.8v6.158h-6.8Z" transform="translate(-66.545 446.304)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6706" data-name="Сгруппировать 6706" transform="translate(139.548 -349.257)" clip-path="url(#clip-path-1728)">
        <g id="Сгруппировать_6705" data-name="Сгруппировать 6705" transform="translate(-187.731 -293.708)" clip-path="url(#clip-path-18)">
          <path id="Контур_7390" data-name="Контур 7390" d="M258.707-157.491h5.5v5.551h-5.5Z" transform="translate(-73.615 448.561)" fill="#3f4954"/>
        </g>
      </g>
      <g id="Сгруппировать_6708" data-name="Сгруппировать 6708" transform="translate(139.768 -349.23)" clip-path="url(#clip-path-1730)">
        <g id="Сгруппировать_6707" data-name="Сгруппировать 6707" transform="translate(-187.951 -293.735)" clip-path="url(#clip-path-18)">
          <path id="Контур_7393" data-name="Контур 7393" d="M259.123-157.441h7.337v6.721h-7.337Z" transform="translate(-73.811 448.537)" fill="#3f4954"/>
        </g>
      </g>
      <g id="Сгруппировать_6710" data-name="Сгруппировать 6710" transform="translate(134.861 -349.23)" clip-path="url(#clip-path-1734)">
        <g id="Сгруппировать_6709" data-name="Сгруппировать 6709" transform="translate(-183.044 -293.735)" clip-path="url(#clip-path-18)">
          <path id="Контур_7396" data-name="Контур 7396" d="M249.826-157.441h9.966v8.231h-9.966Z" transform="translate(-69.421 448.537)" fill="#3f4954"/>
        </g>
      </g>
      <g id="Сгруппировать_6712" data-name="Сгруппировать 6712" transform="translate(134.815 -346.524)" clip-path="url(#clip-path-1736)">
        <g id="Сгруппировать_6711" data-name="Сгруппировать 6711" transform="translate(-182.998 -296.441)" clip-path="url(#clip-path-18)">
          <path id="Контур_7399" data-name="Контур 7399" d="M249.739-152.313h5.325v5.588h-5.325Z" transform="translate(-69.38 446.115)" fill="#3f4954"/>
        </g>
      </g>
      <g id="Сгруппировать_6714" data-name="Сгруппировать 6714" transform="translate(134.815 -346.46)" clip-path="url(#clip-path-1738)">
        <g id="Сгруппировать_6713" data-name="Сгруппировать 6713" transform="translate(-182.998 -296.505)" clip-path="url(#clip-path-18)">
          <path id="Контур_7402" data-name="Контур 7402" d="M249.739-152.192h5.324v5.588h-5.324Z" transform="translate(-69.38 446.058)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6716" data-name="Сгруппировать 6716" transform="translate(137.138 -347.907)" clip-path="url(#clip-path-1740)">
        <g id="Сгруппировать_6715" data-name="Сгруппировать 6715" transform="translate(-185.321 -295.058)" clip-path="url(#clip-path-18)">
          <path id="Контур_7405" data-name="Контур 7405" d="M254.141-154.934h9.965v8.231h-9.965Z" transform="translate(-71.458 447.353)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6718" data-name="Сгруппировать 6718" transform="translate(136.919 -345.201)" clip-path="url(#clip-path-1742)">
        <g id="Сгруппировать_6717" data-name="Сгруппировать 6717" transform="translate(-185.102 -297.764)" clip-path="url(#clip-path-18)">
          <path id="Контур_7408" data-name="Контур 7408" d="M253.725-149.807h5.5v5.551h-5.5Z" transform="translate(-71.262 444.932)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6720" data-name="Сгруппировать 6720" transform="translate(123.95 -355.553)" clip-path="url(#clip-path-1856)">
        <g id="Сгруппировать_6719" data-name="Сгруппировать 6719" transform="translate(-172.133 -287.412)" clip-path="url(#clip-path-18)">
          <path id="Контур_7411" data-name="Контур 7411" d="M229.152-169.421h28.41v19.239h-28.41Z" transform="translate(-59.658 454.194)" fill="#f4eeef"/>
        </g>
      </g>
      <g id="Сгруппировать_6722" data-name="Сгруппировать 6722" transform="translate(134.815 -349.257)" clip-path="url(#clip-path-1744)">
        <g id="Сгруппировать_6721" data-name="Сгруппировать 6721" transform="translate(-182.999 -293.708)" clip-path="url(#clip-path-18)">
          <path id="Контур_7414" data-name="Контур 7414" d="M249.74-157.491h12.333v9.36H249.74Z" transform="translate(-69.38 448.561)" fill="#c2bdbf"/>
        </g>
      </g>
      <g id="Сгруппировать_6724" data-name="Сгруппировать 6724" transform="translate(123.95 -347.119)" clip-path="url(#clip-path-1860)">
        <g id="Сгруппировать_6723" data-name="Сгруппировать 6723" transform="translate(-172.133 -295.846)" clip-path="url(#clip-path-18)">
          <path id="Контур_7417" data-name="Контур 7417" d="M229.152-153.44h13.489v10.805H229.152Z" transform="translate(-59.658 446.648)" fill="#d3cdcc"/>
        </g>
      </g>
      <g id="Сгруппировать_6726" data-name="Сгруппировать 6726" transform="translate(132.16 -350.598)" clip-path="url(#clip-path-1862)">
        <g id="Сгруппировать_6725" data-name="Сгруппировать 6725" transform="translate(-180.344 -292.367)" clip-path="url(#clip-path-18)">
          <path id="Контур_7420" data-name="Контур 7420" d="M244.709-160.033h20.2v14.361h-20.2Z" transform="translate(-67.004 449.761)" fill="#c2bdbf"/>
        </g>
      </g>
      <g id="Сгруппировать_6728" data-name="Сгруппировать 6728" transform="translate(137.211 -354.81)" clip-path="url(#clip-path-1748)">
        <g id="Сгруппировать_6727" data-name="Сгруппировать 6727" transform="translate(-185.395 -288.155)" clip-path="url(#clip-path-18)">
          <path id="Контур_7423" data-name="Контур 7423" d="M254.279-168.013h6.8v6.157h-6.8Z" transform="translate(-71.524 453.529)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6730" data-name="Сгруппировать 6730" transform="translate(135.053 -353.578)" clip-path="url(#clip-path-1750)">
        <g id="Сгруппировать_6729" data-name="Сгруппировать 6729" transform="translate(-183.236 -289.387)" clip-path="url(#clip-path-18)">
          <path id="Контур_7426" data-name="Контур 7426" d="M250.19-165.679h6.8v6.158h-6.8Z" transform="translate(-69.592 452.427)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6732" data-name="Сгруппировать 6732" transform="translate(136.192 -354.24)" clip-path="url(#clip-path-1752)">
        <g id="Сгруппировать_6731" data-name="Сгруппировать 6731" transform="translate(-184.375 -288.725)" clip-path="url(#clip-path-18)">
          <path id="Контур_7429" data-name="Контур 7429" d="M252.348-166.933h6.8v6.158h-6.8Z" transform="translate(-70.612 453.019)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6734" data-name="Сгруппировать 6734" transform="translate(133.975 -352.951)" clip-path="url(#clip-path-1754)">
        <g id="Сгруппировать_6733" data-name="Сгруппировать 6733" transform="translate(-182.158 -290.014)" clip-path="url(#clip-path-18)">
          <path id="Контур_7432" data-name="Контур 7432" d="M248.147-164.491h6.8v6.158h-6.8Z" transform="translate(-68.628 451.866)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6736" data-name="Сгруппировать 6736" transform="translate(132.913 -352.34)" clip-path="url(#clip-path-1756)">
        <g id="Сгруппировать_6735" data-name="Сгруппировать 6735" transform="translate(-181.096 -290.625)" clip-path="url(#clip-path-18)">
          <path id="Контур_7435" data-name="Контур 7435" d="M246.135-163.333h6.8v6.158h-6.8Z" transform="translate(-67.677 451.319)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6738" data-name="Сгруппировать 6738" transform="translate(131.849 -351.727)" clip-path="url(#clip-path-1758)">
        <g id="Сгруппировать_6737" data-name="Сгруппировать 6737" transform="translate(-180.033 -291.238)" clip-path="url(#clip-path-18)">
          <path id="Контур_7438" data-name="Контур 7438" d="M244.12-162.172h6.8v6.158h-6.8Z" transform="translate(-66.726 450.771)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6740" data-name="Сгруппировать 6740" transform="translate(137.164 -354.134)" clip-path="url(#clip-path-1760)">
        <g id="Сгруппировать_6739" data-name="Сгруппировать 6739" transform="translate(-185.347 -288.831)" clip-path="url(#clip-path-18)">
          <path id="Контур_7441" data-name="Контур 7441" d="M254.19-166.732h7.865v6.775H254.19Z" transform="translate(-71.481 452.925)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6742" data-name="Сгруппировать 6742" transform="translate(136.147 -352.952)" clip-path="url(#clip-path-1762)">
        <g id="Сгруппировать_6741" data-name="Сгруппировать 6741" transform="translate(-184.33 -290.014)" clip-path="url(#clip-path-18)">
          <path id="Контур_7444" data-name="Контур 7444" d="M252.262-164.492h6.8v6.157h-6.8Z" transform="translate(-70.571 451.867)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6744" data-name="Сгруппировать 6744" transform="translate(134.999 -352.292)" clip-path="url(#clip-path-1764)">
        <g id="Сгруппировать_6743" data-name="Сгруппировать 6743" transform="translate(-183.182 -290.673)" clip-path="url(#clip-path-18)">
          <path id="Контур_7447" data-name="Контур 7447" d="M250.088-163.242h6.8v6.158h-6.8Z" transform="translate(-69.544 451.276)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6746" data-name="Сгруппировать 6746" transform="translate(133.931 -351.675)" clip-path="url(#clip-path-1766)">
        <g id="Сгруппировать_6745" data-name="Сгруппировать 6745" transform="translate(-182.115 -291.29)" clip-path="url(#clip-path-18)">
          <path id="Контур_7450" data-name="Контур 7450" d="M248.064-162.074h6.8v6.158h-6.8Z" transform="translate(-68.589 450.725)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6748" data-name="Сгруппировать 6748" transform="translate(132.864 -351.058)" clip-path="url(#clip-path-1768)">
        <g id="Сгруппировать_6747" data-name="Сгруппировать 6747" transform="translate(-181.047 -291.907)" clip-path="url(#clip-path-18)">
          <path id="Контур_7453" data-name="Контур 7453" d="M246.042-160.9h6.8v6.158h-6.8Z" transform="translate(-67.634 450.173)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6750" data-name="Сгруппировать 6750" transform="translate(138.194 -353.478)" clip-path="url(#clip-path-1770)">
        <g id="Сгруппировать_6749" data-name="Сгруппировать 6749" transform="translate(-186.378 -289.487)" clip-path="url(#clip-path-18)">
          <path id="Контур_7456" data-name="Контур 7456" d="M256.142-165.49h7.864v6.774h-7.864Z" transform="translate(-72.403 452.338)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6752" data-name="Сгруппировать 6752" transform="translate(137.126 -352.301)" clip-path="url(#clip-path-1772)">
        <g id="Сгруппировать_6751" data-name="Сгруппировать 6751" transform="translate(-185.31 -290.664)" clip-path="url(#clip-path-18)">
          <path id="Контур_7459" data-name="Контур 7459" d="M254.119-163.26h6.8v6.158h-6.8Z" transform="translate(-71.448 451.285)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6754" data-name="Сгруппировать 6754" transform="translate(136.024 -351.65)" clip-path="url(#clip-path-1774)">
        <g id="Сгруппировать_6753" data-name="Сгруппировать 6753" transform="translate(-184.207 -291.315)" clip-path="url(#clip-path-18)">
          <path id="Контур_7462" data-name="Контур 7462" d="M252.029-162.025h6.8v6.158h-6.8Z" transform="translate(-70.461 450.702)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6756" data-name="Сгруппировать 6756" transform="translate(134.956 -351.033)" clip-path="url(#clip-path-1776)">
        <g id="Сгруппировать_6755" data-name="Сгруппировать 6755" transform="translate(-183.139 -291.932)" clip-path="url(#clip-path-18)">
          <path id="Контур_7465" data-name="Контур 7465" d="M250.006-160.857h6.8v6.158h-6.8Z" transform="translate(-69.506 450.15)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6758" data-name="Сгруппировать 6758" transform="translate(130.781 -351.111)" clip-path="url(#clip-path-1778)">
        <g id="Сгруппировать_6757" data-name="Сгруппировать 6757" transform="translate(-178.964 -291.854)" clip-path="url(#clip-path-18)">
          <path id="Контур_7468" data-name="Контур 7468" d="M242.1-161h6.8v6.158h-6.8Z" transform="translate(-65.77 450.22)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6760" data-name="Сгруппировать 6760" transform="translate(131.796 -350.441)" clip-path="url(#clip-path-1780)">
        <g id="Сгруппировать_6759" data-name="Сгруппировать 6759" transform="translate(-179.979 -292.524)" clip-path="url(#clip-path-18)">
          <path id="Контур_7471" data-name="Контур 7471" d="M244.018-159.735h6.8v6.157h-6.8Z" transform="translate(-66.678 449.62)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6762" data-name="Сгруппировать 6762" transform="translate(133.888 -350.416)" clip-path="url(#clip-path-1782)">
        <g id="Сгруппировать_6761" data-name="Сгруппировать 6761" transform="translate(-182.071 -292.549)" clip-path="url(#clip-path-18)">
          <path id="Контур_7474" data-name="Контур 7474" d="M247.982-159.688h6.8v6.157h-6.8Z" transform="translate(-68.55 449.598)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6764" data-name="Сгруппировать 6764" transform="translate(132.82 -349.799)" clip-path="url(#clip-path-1784)">
        <g id="Сгруппировать_6763" data-name="Сгруппировать 6763" transform="translate(-181.003 -293.166)" clip-path="url(#clip-path-18)">
          <path id="Контур_7477" data-name="Контур 7477" d="M245.959-158.519h6.8v6.158h-6.8Z" transform="translate(-67.595 449.046)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6766" data-name="Сгруппировать 6766" transform="translate(138.095 -352.873)" clip-path="url(#clip-path-1786)">
        <g id="Сгруппировать_6765" data-name="Сгруппировать 6765" transform="translate(-186.278 -290.093)" clip-path="url(#clip-path-18)">
          <path id="Контур_7480" data-name="Контур 7480" d="M255.954-164.342h8.932v7.391h-8.932Z" transform="translate(-72.314 451.796)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6768" data-name="Сгруппировать 6768" transform="translate(137.049 -351.02)" clip-path="url(#clip-path-1788)">
        <g id="Сгруппировать_6767" data-name="Сгруппировать 6767" transform="translate(-185.232 -291.945)" clip-path="url(#clip-path-18)">
          <path id="Контур_7483" data-name="Контур 7483" d="M253.971-160.832h6.8v6.158h-6.8Z" transform="translate(-71.378 450.139)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6770" data-name="Сгруппировать 6770" transform="translate(135.981 -350.403)" clip-path="url(#clip-path-1790)">
        <g id="Сгруппировать_6769" data-name="Сгруппировать 6769" transform="translate(-184.164 -292.562)" clip-path="url(#clip-path-18)">
          <path id="Контур_7486" data-name="Контур 7486" d="M251.947-159.664h6.8v6.157h-6.8Z" transform="translate(-70.422 449.587)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6772" data-name="Сгруппировать 6772" transform="translate(134.913 -349.787)" clip-path="url(#clip-path-1792)">
        <g id="Сгруппировать_6771" data-name="Сгруппировать 6771" transform="translate(-183.096 -293.178)" clip-path="url(#clip-path-18)">
          <path id="Контур_7489" data-name="Контур 7489" d="M249.924-158.5h6.8v6.158h-6.8Z" transform="translate(-69.467 449.035)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6774" data-name="Сгруппировать 6774" transform="translate(133.845 -349.17)" clip-path="url(#clip-path-1794)">
        <g id="Сгруппировать_6773" data-name="Сгруппировать 6773" transform="translate(-182.028 -293.796)" clip-path="url(#clip-path-18)">
          <path id="Контур_7492" data-name="Контур 7492" d="M247.9-157.326h6.8v6.157h-6.8Z" transform="translate(-68.511 448.483)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6776" data-name="Сгруппировать 6776" transform="translate(129.713 -350.494)" clip-path="url(#clip-path-1796)">
        <g id="Сгруппировать_6775" data-name="Сгруппировать 6775" transform="translate(-177.897 -292.471)" clip-path="url(#clip-path-18)">
          <path id="Контур_7495" data-name="Контур 7495" d="M240.073-159.835h6.8v6.158h-6.8Z" transform="translate(-64.815 449.667)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6778" data-name="Сгруппировать 6778" transform="translate(130.728 -349.825)" clip-path="url(#clip-path-1798)">
        <g id="Сгруппировать_6777" data-name="Сгруппировать 6777" transform="translate(-178.911 -293.141)" clip-path="url(#clip-path-18)">
          <path id="Контур_7498" data-name="Контур 7498" d="M241.994-158.567h6.8v6.158h-6.8Z" transform="translate(-65.722 449.069)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6780" data-name="Сгруппировать 6780" transform="translate(131.752 -349.182)" clip-path="url(#clip-path-1800)">
        <g id="Сгруппировать_6779" data-name="Сгруппировать 6779" transform="translate(-179.935 -293.783)" clip-path="url(#clip-path-18)">
          <path id="Контур_7501" data-name="Контур 7501" d="M243.936-157.35h6.8v6.158h-6.8Z" transform="translate(-66.639 448.494)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6782" data-name="Сгруппировать 6782" transform="translate(132.777 -348.553)" clip-path="url(#clip-path-1802)">
        <g id="Сгруппировать_6781" data-name="Сгруппировать 6781" transform="translate(-180.961 -294.412)" clip-path="url(#clip-path-18)">
          <path id="Контур_7504" data-name="Контур 7504" d="M245.878-156.158h6.8V-150h-6.8Z" transform="translate(-67.556 447.931)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6784" data-name="Сгруппировать 6784" transform="translate(140.19 -351.67)" clip-path="url(#clip-path-1804)">
        <g id="Сгруппировать_6783" data-name="Сгруппировать 6783" transform="translate(-188.373 -291.295)" clip-path="url(#clip-path-18)">
          <path id="Контур_7507" data-name="Контур 7507" d="M259.924-162.063h6.8v6.158h-6.8Z" transform="translate(-74.189 450.72)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6786" data-name="Сгруппировать 6786" transform="translate(139.122 -351.053)" clip-path="url(#clip-path-1806)">
        <g id="Сгруппировать_6785" data-name="Сгруппировать 6785" transform="translate(-187.306 -291.912)" clip-path="url(#clip-path-18)">
          <path id="Контур_7510" data-name="Контур 7510" d="M257.9-160.894h6.8v6.158h-6.8Z" transform="translate(-73.234 450.168)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6788" data-name="Сгруппировать 6788" transform="translate(133.783 -350.436)" clip-path="url(#clip-path-1808)">
        <g id="Сгруппировать_6787" data-name="Сгруппировать 6787" transform="translate(-181.966 -292.529)" clip-path="url(#clip-path-18)">
          <path id="Контур_7513" data-name="Контур 7513" d="M247.783-159.725h11.068v8.625H247.783Z" transform="translate(-68.456 449.616)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6790" data-name="Сгруппировать 6790" transform="translate(128.645 -349.877)" clip-path="url(#clip-path-1810)">
        <g id="Сгруппировать_6789" data-name="Сгруппировать 6789" transform="translate(-176.829 -293.088)" clip-path="url(#clip-path-18)">
          <path id="Контур_7516" data-name="Контур 7516" d="M238.049-158.666h6.8v6.158h-6.8Z" transform="translate(-63.859 449.115)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6792" data-name="Сгруппировать 6792" transform="translate(127.577 -349.26)" clip-path="url(#clip-path-1812)">
        <g id="Сгруппировать_6791" data-name="Сгруппировать 6791" transform="translate(-175.761 -293.705)" clip-path="url(#clip-path-18)">
          <path id="Контур_7519" data-name="Контур 7519" d="M236.025-157.5h6.8v6.158h-6.8Z" transform="translate(-62.904 448.564)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6794" data-name="Сгруппировать 6794" transform="translate(125.442 -348.643)" clip-path="url(#clip-path-1814)">
        <g id="Сгруппировать_6793" data-name="Сгруппировать 6793" transform="translate(-173.625 -294.322)" clip-path="url(#clip-path-18)">
          <path id="Контур_7522" data-name="Контур 7522" d="M231.979-156.328h7.864v6.774h-7.864Z" transform="translate(-60.993 448.011)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6796" data-name="Сгруппировать 6796" transform="translate(129.66 -349.208)" clip-path="url(#clip-path-1816)">
        <g id="Сгруппировать_6795" data-name="Сгруппировать 6795" transform="translate(-177.843 -293.758)" clip-path="url(#clip-path-18)">
          <path id="Контур_7525" data-name="Контур 7525" d="M239.97-157.4h6.8v6.158h-6.8Z" transform="translate(-64.767 448.517)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6798" data-name="Сгруппировать 6798" transform="translate(128.592 -348.591)" clip-path="url(#clip-path-1818)">
        <g id="Сгруппировать_6797" data-name="Сгруппировать 6797" transform="translate(-176.775 -294.374)" clip-path="url(#clip-path-18)">
          <path id="Контур_7528" data-name="Контур 7528" d="M237.947-156.23h6.8v6.158h-6.8Z" transform="translate(-63.811 447.965)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6800" data-name="Сгруппировать 6800" transform="translate(127.524 -347.974)" clip-path="url(#clip-path-1820)">
        <g id="Сгруппировать_6799" data-name="Сгруппировать 6799" transform="translate(-175.707 -294.991)" clip-path="url(#clip-path-18)">
          <path id="Контур_7531" data-name="Контур 7531" d="M235.924-155.06h6.8v6.158h-6.8Z" transform="translate(-62.856 447.413)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6802" data-name="Сгруппировать 6802" transform="translate(126.456 -347.357)" clip-path="url(#clip-path-1822)">
        <g id="Сгруппировать_6801" data-name="Сгруппировать 6801" transform="translate(-174.639 -295.608)" clip-path="url(#clip-path-18)">
          <path id="Контур_7534" data-name="Контур 7534" d="M233.9-153.892h6.8v6.158h-6.8Z" transform="translate(-61.9 446.861)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6804" data-name="Сгруппировать 6804" transform="translate(130.684 -348.565)" clip-path="url(#clip-path-1824)">
        <g id="Сгруппировать_6803" data-name="Сгруппировать 6803" transform="translate(-178.867 -294.4)" clip-path="url(#clip-path-18)">
          <path id="Контур_7537" data-name="Контур 7537" d="M241.912-156.181h6.8v6.158h-6.8Z" transform="translate(-65.683 447.942)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6806" data-name="Сгруппировать 6806" transform="translate(129.616 -347.949)" clip-path="url(#clip-path-1826)">
        <g id="Сгруппировать_6805" data-name="Сгруппировать 6805" transform="translate(-177.799 -295.017)" clip-path="url(#clip-path-18)">
          <path id="Контур_7540" data-name="Контур 7540" d="M239.889-155.012h6.8v6.158h-6.8Z" transform="translate(-64.728 447.39)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6808" data-name="Сгруппировать 6808" transform="translate(127.48 -347.275)" clip-path="url(#clip-path-1828)">
        <g id="Сгруппировать_6807" data-name="Сгруппировать 6807" transform="translate(-175.664 -295.69)" clip-path="url(#clip-path-18)">
          <path id="Контур_7543" data-name="Контур 7543" d="M235.842-153.736h7.864v6.774h-7.864Z" transform="translate(-62.817 446.787)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6810" data-name="Сгруппировать 6810" transform="translate(131.709 -347.936)" clip-path="url(#clip-path-1830)">
        <g id="Сгруппировать_6809" data-name="Сгруппировать 6809" transform="translate(-179.892 -295.029)" clip-path="url(#clip-path-18)">
          <path id="Контур_7546" data-name="Контур 7546" d="M243.854-154.988h6.8v6.157h-6.8Z" transform="translate(-66.601 447.379)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6812" data-name="Сгруппировать 6812" transform="translate(130.641 -347.319)" clip-path="url(#clip-path-1832)">
        <g id="Сгруппировать_6811" data-name="Сгруппировать 6811" transform="translate(-178.824 -295.646)" clip-path="url(#clip-path-18)">
          <path id="Контур_7549" data-name="Контур 7549" d="M241.831-153.82h6.8v6.157h-6.8Z" transform="translate(-65.645 446.827)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6814" data-name="Сгруппировать 6814" transform="translate(128.506 -346.702)" clip-path="url(#clip-path-1834)">
        <g id="Сгруппировать_6813" data-name="Сгруппировать 6813" transform="translate(-176.689 -296.263)" clip-path="url(#clip-path-18)">
          <path id="Контур_7552" data-name="Контур 7552" d="M237.784-152.651h7.864v6.791h-7.864Z" transform="translate(-63.734 446.275)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6816" data-name="Сгруппировать 6816" transform="translate(132.715 -347.351)" clip-path="url(#clip-path-1836)">
        <g id="Сгруппировать_6815" data-name="Сгруппировать 6815" transform="translate(-180.898 -295.614)" clip-path="url(#clip-path-18)">
          <path id="Контур_7555" data-name="Контур 7555" d="M245.759-153.881h6.8v6.158h-6.8Z" transform="translate(-67.5 446.856)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6818" data-name="Сгруппировать 6818" transform="translate(129.467 -346.094)" clip-path="url(#clip-path-1838)">
        <g id="Сгруппировать_6817" data-name="Сгруппировать 6817" transform="translate(-177.65 -296.871)" clip-path="url(#clip-path-18)">
          <path id="Контур_7558" data-name="Контур 7558" d="M239.606-151.5h7.865v6.775h-7.865Z" transform="translate(-64.594 445.731)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6820" data-name="Сгруппировать 6820" transform="translate(131.647 -346.735)" clip-path="url(#clip-path-1840)">
        <g id="Сгруппировать_6819" data-name="Сгруппировать 6819" transform="translate(-179.83 -296.23)" clip-path="url(#clip-path-18)">
          <path id="Контур_7561" data-name="Контур 7561" d="M243.736-152.713h6.8v6.158h-6.8Z" transform="translate(-66.545 446.304)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6822" data-name="Сгруппировать 6822" transform="translate(124.076 -365.587)" clip-path="url(#clip-path-1958)">
        <g id="Сгруппировать_6821" data-name="Сгруппировать 6821" transform="translate(-172.259 -277.378)" clip-path="url(#clip-path-18)">
          <path id="Контур_7564" data-name="Контур 7564" d="M229.39-188.433h19v14.157h-19Z" transform="translate(-59.77 463.172)" fill="#353b3c"/>
        </g>
      </g>
      <g id="Сгруппировать_6824" data-name="Сгруппировать 6824" transform="translate(186.014 -403.165)" clip-path="url(#clip-path-1960)">
        <g id="Сгруппировать_6823" data-name="Сгруппировать 6823" transform="translate(-234.197 -239.8)" clip-path="url(#clip-path-18)">
          <path id="Контур_7567" data-name="Контур 7567" d="M346.749-259.635h20.075v24.677H346.749Z" transform="translate(-115.191 496.796)" fill="#c2bdbf"/>
        </g>
      </g>
      <g id="Сгруппировать_6826" data-name="Сгруппировать 6826" transform="translate(186.536 -402.863)" clip-path="url(#clip-path-1962)">
        <g id="Сгруппировать_6825" data-name="Сгруппировать 6825" transform="translate(-234.719 -240.103)" clip-path="url(#clip-path-18)">
          <path id="Контур_7570" data-name="Контур 7570" d="M347.738-259.062h19.695v24.439H347.738Z" transform="translate(-115.658 496.525)" fill="#151919"/>
        </g>
      </g>
      <g id="Сгруппировать_6828" data-name="Сгруппировать 6828" transform="translate(186.903 -401.719)" clip-path="url(#clip-path-1964)">
        <g id="Сгруппировать_6827" data-name="Сгруппировать 6827" transform="translate(-235.086 -241.246)" clip-path="url(#clip-path-18)">
          <path id="Контур_7573" data-name="Контур 7573" d="M348.434-256.895h19v22.1h-19Z" transform="translate(-115.986 495.502)" fill="#262b2b"/>
        </g>
      </g>
      <g id="Сгруппировать_6830" data-name="Сгруппировать 6830" transform="translate(202.376 -385.389)" clip-path="url(#clip-path-1966)">
        <g id="Сгруппировать_6829" data-name="Сгруппировать 6829" transform="translate(-250.559 -257.576)" clip-path="url(#clip-path-18)">
          <path id="Контур_7576" data-name="Контур 7576" d="M377.751-225.953h5.5v5.551h-5.5Z" transform="translate(-129.831 480.891)" fill="#3f4954"/>
        </g>
      </g>
      <g id="Сгруппировать_6832" data-name="Сгруппировать 6832" transform="translate(202.596 -385.362)" clip-path="url(#clip-path-1968)">
        <g id="Сгруппировать_6831" data-name="Сгруппировать 6831" transform="translate(-250.779 -257.603)" clip-path="url(#clip-path-18)">
          <path id="Контур_7579" data-name="Контур 7579" d="M378.168-225.9H385.5v6.721h-7.336Z" transform="translate(-130.028 480.867)" fill="#3f4954"/>
        </g>
      </g>
      <g id="Сгруппировать_6834" data-name="Сгруппировать 6834" transform="translate(186.778 -383.251)" clip-path="url(#clip-path-1970)">
        <g id="Сгруппировать_6833" data-name="Сгруппировать 6833" transform="translate(-234.961 -259.714)" clip-path="url(#clip-path-18)">
          <path id="Контур_7582" data-name="Контур 7582" d="M348.2-221.9H353.6v6.109H348.2Z" transform="translate(-115.874 478.977)" fill="#293341"/>
        </g>
      </g>
      <g id="Сгруппировать_6836" data-name="Сгруппировать 6836" transform="translate(197.689 -385.362)" clip-path="url(#clip-path-1972)">
        <g id="Сгруппировать_6835" data-name="Сгруппировать 6835" transform="translate(-245.872 -257.603)" clip-path="url(#clip-path-18)">
          <path id="Контур_7585" data-name="Контур 7585" d="M368.87-225.9h9.965v8.231H368.87Z" transform="translate(-125.637 480.867)" fill="#3f4954"/>
        </g>
      </g>
      <g id="Сгруппировать_6838" data-name="Сгруппировать 6838" transform="translate(197.643 -382.656)" clip-path="url(#clip-path-1974)">
        <g id="Сгруппировать_6837" data-name="Сгруппировать 6837" transform="translate(-245.826 -260.309)" clip-path="url(#clip-path-18)">
          <path id="Контур_7588" data-name="Контур 7588" d="M368.783-220.775h5.324v5.588h-5.324Z" transform="translate(-125.596 478.445)" fill="#3f4954"/>
        </g>
      </g>
      <g id="Сгруппировать_6840" data-name="Сгруппировать 6840" transform="translate(197.643 -382.592)" clip-path="url(#clip-path-1976)">
        <g id="Сгруппировать_6839" data-name="Сгруппировать 6839" transform="translate(-245.826 -260.373)" clip-path="url(#clip-path-18)">
          <path id="Контур_7591" data-name="Контур 7591" d="M368.783-220.654h5.324v5.588h-5.324Z" transform="translate(-125.596 478.388)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6842" data-name="Сгруппировать 6842" transform="translate(199.966 -384.039)" clip-path="url(#clip-path-1978)">
        <g id="Сгруппировать_6841" data-name="Сгруппировать 6841" transform="translate(-248.149 -258.926)" clip-path="url(#clip-path-18)">
          <path id="Контур_7594" data-name="Контур 7594" d="M373.185-223.4h9.965v8.231h-9.965Z" transform="translate(-127.675 479.683)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6844" data-name="Сгруппировать 6844" transform="translate(199.747 -381.333)" clip-path="url(#clip-path-1980)">
        <g id="Сгруппировать_6843" data-name="Сгруппировать 6843" transform="translate(-247.93 -261.632)" clip-path="url(#clip-path-18)">
          <path id="Контур_7597" data-name="Контур 7597" d="M372.769-218.268h5.5v5.551h-5.5Z" transform="translate(-127.478 477.261)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6846" data-name="Сгруппировать 6846" transform="translate(197.643 -385.389)" clip-path="url(#clip-path-1982)">
        <g id="Сгруппировать_6845" data-name="Сгруппировать 6845" transform="translate(-245.826 -257.576)" clip-path="url(#clip-path-18)">
          <path id="Контур_7600" data-name="Контур 7600" d="M368.784-225.953h12.333v9.36H368.784Z" transform="translate(-125.596 480.891)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6848" data-name="Сгруппировать 6848" transform="translate(186.908 -383.068)" clip-path="url(#clip-path-1984)">
        <g id="Сгруппировать_6847" data-name="Сгруппировать 6847" transform="translate(-235.091 -259.897)" clip-path="url(#clip-path-18)">
          <path id="Контур_7603" data-name="Контур 7603" d="M348.443-221.556H361.8v10.622H348.443Z" transform="translate(-115.991 478.814)" fill="#293341"/>
        </g>
      </g>
      <g id="Сгруппировать_6850" data-name="Сгруппировать 6850" transform="translate(200.039 -390.942)" clip-path="url(#clip-path-1986)">
        <g id="Сгруппировать_6849" data-name="Сгруппировать 6849" transform="translate(-248.222 -252.023)" clip-path="url(#clip-path-18)">
          <path id="Контур_7606" data-name="Контур 7606" d="M373.324-236.475h6.8v6.157h-6.8Z" transform="translate(-127.74 485.859)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6852" data-name="Сгруппировать 6852" transform="translate(197.881 -389.71)" clip-path="url(#clip-path-1988)">
        <g id="Сгруппировать_6851" data-name="Сгруппировать 6851" transform="translate(-246.064 -253.255)" clip-path="url(#clip-path-18)">
          <path id="Контур_7609" data-name="Контур 7609" d="M369.234-234.14h6.8v6.158h-6.8Z" transform="translate(-125.809 484.757)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6854" data-name="Сгруппировать 6854" transform="translate(199.02 -390.372)" clip-path="url(#clip-path-1990)">
        <g id="Сгруппировать_6853" data-name="Сгруппировать 6853" transform="translate(-247.203 -252.594)" clip-path="url(#clip-path-18)">
          <path id="Контур_7612" data-name="Контур 7612" d="M371.392-235.394h6.8v6.157h-6.8Z" transform="translate(-126.828 485.349)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6856" data-name="Сгруппировать 6856" transform="translate(196.803 -389.083)" clip-path="url(#clip-path-1992)">
        <g id="Сгруппировать_6855" data-name="Сгруппировать 6855" transform="translate(-244.987 -253.882)" clip-path="url(#clip-path-18)">
          <path id="Контур_7615" data-name="Контур 7615" d="M367.192-232.953h6.8v6.157h-6.8Z" transform="translate(-124.845 484.196)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6858" data-name="Сгруппировать 6858" transform="translate(195.741 -388.472)" clip-path="url(#clip-path-1994)">
        <g id="Сгруппировать_6857" data-name="Сгруппировать 6857" transform="translate(-243.924 -254.493)" clip-path="url(#clip-path-18)">
          <path id="Контур_7618" data-name="Контур 7618" d="M365.179-231.795h6.8v6.158h-6.8Z" transform="translate(-123.894 483.649)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6860" data-name="Сгруппировать 6860" transform="translate(194.677 -387.859)" clip-path="url(#clip-path-1996)">
        <g id="Сгруппировать_6859" data-name="Сгруппировать 6859" transform="translate(-242.86 -255.106)" clip-path="url(#clip-path-18)">
          <path id="Контур_7621" data-name="Контур 7621" d="M363.163-230.634h6.8v6.158h-6.8Z" transform="translate(-122.942 483.101)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6862" data-name="Сгруппировать 6862" transform="translate(199.991 -390.266)" clip-path="url(#clip-path-1998)">
        <g id="Сгруппировать_6861" data-name="Сгруппировать 6861" transform="translate(-248.175 -252.699)" clip-path="url(#clip-path-18)">
          <path id="Контур_7624" data-name="Контур 7624" d="M373.233-235.194H381.1v6.774h-7.865Z" transform="translate(-127.697 485.254)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6864" data-name="Сгруппировать 6864" transform="translate(198.975 -389.084)" clip-path="url(#clip-path-2000)">
        <g id="Сгруппировать_6863" data-name="Сгруппировать 6863" transform="translate(-247.158 -253.881)" clip-path="url(#clip-path-18)">
          <path id="Контур_7627" data-name="Контур 7627" d="M371.307-232.954h6.8v6.158h-6.8Z" transform="translate(-126.788 484.197)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6866" data-name="Сгруппировать 6866" transform="translate(197.827 -388.424)" clip-path="url(#clip-path-2002)">
        <g id="Сгруппировать_6865" data-name="Сгруппировать 6865" transform="translate(-246.01 -254.541)" clip-path="url(#clip-path-18)">
          <path id="Контур_7630" data-name="Контур 7630" d="M369.132-231.7h6.8v6.157h-6.8Z" transform="translate(-125.761 483.606)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6868" data-name="Сгруппировать 6868" transform="translate(196.759 -387.807)" clip-path="url(#clip-path-2004)">
        <g id="Сгруппировать_6867" data-name="Сгруппировать 6867" transform="translate(-244.942 -255.158)" clip-path="url(#clip-path-18)">
          <path id="Контур_7633" data-name="Контур 7633" d="M367.109-230.535h6.8v6.157h-6.8Z" transform="translate(-124.805 483.054)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6870" data-name="Сгруппировать 6870" transform="translate(195.691 -387.19)" clip-path="url(#clip-path-2006)">
        <g id="Сгруппировать_6869" data-name="Сгруппировать 6869" transform="translate(-243.874 -255.775)" clip-path="url(#clip-path-18)">
          <path id="Контур_7636" data-name="Контур 7636" d="M365.085-229.366h6.8v6.158h-6.8Z" transform="translate(-123.849 482.502)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6872" data-name="Сгруппировать 6872" transform="translate(201.022 -389.61)" clip-path="url(#clip-path-2008)">
        <g id="Сгруппировать_6871" data-name="Сгруппировать 6871" transform="translate(-249.205 -253.355)" clip-path="url(#clip-path-18)">
          <path id="Контур_7639" data-name="Контур 7639" d="M375.185-233.952h7.865v6.774h-7.865Z" transform="translate(-128.619 484.668)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6874" data-name="Сгруппировать 6874" transform="translate(199.954 -388.434)" clip-path="url(#clip-path-2010)">
        <g id="Сгруппировать_6873" data-name="Сгруппировать 6873" transform="translate(-248.137 -254.532)" clip-path="url(#clip-path-18)">
          <path id="Контур_7642" data-name="Контур 7642" d="M373.162-231.722h6.8v6.158h-6.8Z" transform="translate(-127.664 483.615)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6876" data-name="Сгруппировать 6876" transform="translate(198.852 -387.782)" clip-path="url(#clip-path-2012)">
        <g id="Сгруппировать_6875" data-name="Сгруппировать 6875" transform="translate(-247.035 -255.183)" clip-path="url(#clip-path-18)">
          <path id="Контур_7645" data-name="Контур 7645" d="M371.073-230.487h6.8v6.157h-6.8Z" transform="translate(-126.677 483.032)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6878" data-name="Сгруппировать 6878" transform="translate(197.783 -387.165)" clip-path="url(#clip-path-2014)">
        <g id="Сгруппировать_6877" data-name="Сгруппировать 6877" transform="translate(-245.967 -255.8)" clip-path="url(#clip-path-18)">
          <path id="Контур_7648" data-name="Контур 7648" d="M369.049-229.318h6.8v6.158h-6.8Z" transform="translate(-125.722 482.48)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6880" data-name="Сгруппировать 6880" transform="translate(193.609 -387.243)" clip-path="url(#clip-path-2016)">
        <g id="Сгруппировать_6879" data-name="Сгруппировать 6879" transform="translate(-241.793 -255.723)" clip-path="url(#clip-path-18)">
          <path id="Контур_7651" data-name="Контур 7651" d="M361.14-229.465h6.8v6.158h-6.8Z" transform="translate(-121.987 482.549)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6882" data-name="Сгруппировать 6882" transform="translate(194.623 -386.573)" clip-path="url(#clip-path-2018)">
        <g id="Сгруппировать_6881" data-name="Сгруппировать 6881" transform="translate(-242.807 -256.392)" clip-path="url(#clip-path-18)">
          <path id="Контур_7654" data-name="Контур 7654" d="M363.062-228.2h6.8v6.157h-6.8Z" transform="translate(-122.894 481.95)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6884" data-name="Сгруппировать 6884" transform="translate(196.716 -386.548)" clip-path="url(#clip-path-2020)">
        <g id="Сгруппировать_6883" data-name="Сгруппировать 6883" transform="translate(-244.899 -256.417)" clip-path="url(#clip-path-18)">
          <path id="Контур_7657" data-name="Контур 7657" d="M367.026-228.15h6.8v6.158h-6.8Z" transform="translate(-124.766 481.928)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6886" data-name="Сгруппировать 6886" transform="translate(195.648 -385.931)" clip-path="url(#clip-path-2022)">
        <g id="Сгруппировать_6885" data-name="Сгруппировать 6885" transform="translate(-243.831 -257.034)" clip-path="url(#clip-path-18)">
          <path id="Контур_7660" data-name="Контур 7660" d="M365-226.981h6.8v6.158H365Z" transform="translate(-123.811 481.376)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6888" data-name="Сгруппировать 6888" transform="translate(200.923 -389.005)" clip-path="url(#clip-path-2024)">
        <g id="Сгруппировать_6887" data-name="Сгруппировать 6887" transform="translate(-249.106 -253.96)" clip-path="url(#clip-path-18)">
          <path id="Контур_7663" data-name="Контур 7663" d="M375-232.8h8.932v7.391H375Z" transform="translate(-128.531 484.126)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6890" data-name="Сгруппировать 6890" transform="translate(199.877 -387.153)" clip-path="url(#clip-path-2026)">
        <g id="Сгруппировать_6889" data-name="Сгруппировать 6889" transform="translate(-248.06 -255.813)" clip-path="url(#clip-path-18)">
          <path id="Контур_7666" data-name="Контур 7666" d="M373.015-229.295h6.8v6.158h-6.8Z" transform="translate(-127.594 482.469)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6892" data-name="Сгруппировать 6892" transform="translate(198.809 -386.536)" clip-path="url(#clip-path-2028)">
        <g id="Сгруппировать_6891" data-name="Сгруппировать 6891" transform="translate(-246.992 -256.43)" clip-path="url(#clip-path-18)">
          <path id="Контур_7669" data-name="Контур 7669" d="M370.992-228.126h6.8v6.158h-6.8Z" transform="translate(-126.639 481.916)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6894" data-name="Сгруппировать 6894" transform="translate(197.74 -385.919)" clip-path="url(#clip-path-2030)">
        <g id="Сгруппировать_6893" data-name="Сгруппировать 6893" transform="translate(-245.924 -257.046)" clip-path="url(#clip-path-18)">
          <path id="Контур_7672" data-name="Контур 7672" d="M368.968-226.957h6.8v6.158h-6.8Z" transform="translate(-125.683 481.365)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6896" data-name="Сгруппировать 6896" transform="translate(196.673 -385.302)" clip-path="url(#clip-path-2032)">
        <g id="Сгруппировать_6895" data-name="Сгруппировать 6895" transform="translate(-244.856 -257.663)" clip-path="url(#clip-path-18)">
          <path id="Контур_7675" data-name="Контур 7675" d="M366.945-225.788h6.8v6.157h-6.8Z" transform="translate(-124.728 480.813)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6898" data-name="Сгруппировать 6898" transform="translate(192.541 -386.625)" clip-path="url(#clip-path-2034)">
        <g id="Сгруппировать_6897" data-name="Сгруппировать 6897" transform="translate(-240.724 -256.34)" clip-path="url(#clip-path-18)">
          <path id="Контур_7678" data-name="Контур 7678" d="M359.116-228.3h6.8v6.157h-6.8Z" transform="translate(-121.031 481.997)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6900" data-name="Сгруппировать 6900" transform="translate(193.556 -385.957)" clip-path="url(#clip-path-2036)">
        <g id="Сгруппировать_6899" data-name="Сгруппировать 6899" transform="translate(-241.739 -257.009)" clip-path="url(#clip-path-18)">
          <path id="Контур_7681" data-name="Контур 7681" d="M361.039-227.029h6.8v6.157h-6.8Z" transform="translate(-121.939 481.398)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6902" data-name="Сгруппировать 6902" transform="translate(194.58 -385.315)" clip-path="url(#clip-path-2038)">
        <g id="Сгруппировать_6901" data-name="Сгруппировать 6901" transform="translate(-242.763 -257.651)" clip-path="url(#clip-path-18)">
          <path id="Контур_7684" data-name="Контур 7684" d="M362.98-225.812h6.8v6.158h-6.8Z" transform="translate(-122.855 480.824)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6904" data-name="Сгруппировать 6904" transform="translate(195.605 -384.685)" clip-path="url(#clip-path-2040)">
        <g id="Сгруппировать_6903" data-name="Сгруппировать 6903" transform="translate(-243.788 -258.28)" clip-path="url(#clip-path-18)">
          <path id="Контур_7687" data-name="Контур 7687" d="M364.922-224.619h6.8v6.157h-6.8Z" transform="translate(-123.773 480.26)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6906" data-name="Сгруппировать 6906" transform="translate(203.018 -387.802)" clip-path="url(#clip-path-2042)">
        <g id="Сгруппировать_6905" data-name="Сгруппировать 6905" transform="translate(-251.202 -255.163)" clip-path="url(#clip-path-18)">
          <path id="Контур_7690" data-name="Контур 7690" d="M378.968-230.525h6.8v6.158h-6.8Z" transform="translate(-130.406 483.05)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6908" data-name="Сгруппировать 6908" transform="translate(201.95 -387.185)" clip-path="url(#clip-path-2044)">
        <g id="Сгруппировать_6907" data-name="Сгруппировать 6907" transform="translate(-250.133 -255.781)" clip-path="url(#clip-path-18)">
          <path id="Контур_7693" data-name="Контур 7693" d="M376.944-229.356h6.8v6.157h-6.8Z" transform="translate(-129.45 482.497)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6910" data-name="Сгруппировать 6910" transform="translate(196.611 -386.568)" clip-path="url(#clip-path-2046)">
        <g id="Сгруппировать_6909" data-name="Сгруппировать 6909" transform="translate(-244.794 -256.397)" clip-path="url(#clip-path-18)">
          <path id="Контур_7696" data-name="Контур 7696" d="M366.828-228.187H377.9v8.625H366.828Z" transform="translate(-124.673 481.945)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6912" data-name="Сгруппировать 6912" transform="translate(191.473 -386.009)" clip-path="url(#clip-path-2048)">
        <g id="Сгруппировать_6911" data-name="Сгруппировать 6911" transform="translate(-239.656 -256.956)" clip-path="url(#clip-path-18)">
          <path id="Контур_7699" data-name="Контур 7699" d="M357.092-227.128h6.8v6.158h-6.8Z" transform="translate(-120.075 481.445)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6914" data-name="Сгруппировать 6914" transform="translate(190.405 -385.392)" clip-path="url(#clip-path-2050)">
        <g id="Сгруппировать_6913" data-name="Сгруппировать 6913" transform="translate(-238.588 -257.573)" clip-path="url(#clip-path-18)">
          <path id="Контур_7702" data-name="Контур 7702" d="M355.069-225.959h6.8v6.158h-6.8Z" transform="translate(-119.12 480.893)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6916" data-name="Сгруппировать 6916" transform="translate(188.27 -384.775)" clip-path="url(#clip-path-2052)">
        <g id="Сгруппировать_6915" data-name="Сгруппировать 6915" transform="translate(-236.453 -258.19)" clip-path="url(#clip-path-18)">
          <path id="Контур_7705" data-name="Контур 7705" d="M351.023-224.79h7.864v6.774h-7.864Z" transform="translate(-117.209 480.341)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6918" data-name="Сгруппировать 6918" transform="translate(192.487 -385.34)" clip-path="url(#clip-path-2054)">
        <g id="Сгруппировать_6917" data-name="Сгруппировать 6917" transform="translate(-240.67 -257.625)" clip-path="url(#clip-path-18)">
          <path id="Контур_7708" data-name="Контур 7708" d="M359.014-225.86h6.8v6.158h-6.8Z" transform="translate(-120.983 480.847)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6920" data-name="Сгруппировать 6920" transform="translate(191.42 -384.723)" clip-path="url(#clip-path-2056)">
        <g id="Сгруппировать_6919" data-name="Сгруппировать 6919" transform="translate(-239.603 -258.242)" clip-path="url(#clip-path-18)">
          <path id="Контур_7711" data-name="Контур 7711" d="M356.991-224.691h6.8v6.157h-6.8Z" transform="translate(-120.027 480.295)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6922" data-name="Сгруппировать 6922" transform="translate(190.352 -384.106)" clip-path="url(#clip-path-2058)">
        <g id="Сгруппировать_6921" data-name="Сгруппировать 6921" transform="translate(-238.535 -258.859)" clip-path="url(#clip-path-18)">
          <path id="Контур_7714" data-name="Контур 7714" d="M354.968-223.523h6.8v6.158h-6.8Z" transform="translate(-119.072 479.743)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6924" data-name="Сгруппировать 6924" transform="translate(189.284 -383.489)" clip-path="url(#clip-path-2060)">
        <g id="Сгруппировать_6923" data-name="Сгруппировать 6923" transform="translate(-237.467 -259.476)" clip-path="url(#clip-path-18)">
          <path id="Контур_7717" data-name="Контур 7717" d="M352.944-222.353h6.8v6.157h-6.8Z" transform="translate(-118.116 479.191)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6926" data-name="Сгруппировать 6926" transform="translate(193.512 -384.698)" clip-path="url(#clip-path-2062)">
        <g id="Сгруппировать_6925" data-name="Сгруппировать 6925" transform="translate(-241.695 -258.268)" clip-path="url(#clip-path-18)">
          <path id="Контур_7720" data-name="Контур 7720" d="M360.956-224.643h6.8v6.158h-6.8Z" transform="translate(-121.9 480.272)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6928" data-name="Сгруппировать 6928" transform="translate(192.444 -384.081)" clip-path="url(#clip-path-2064)">
        <g id="Сгруппировать_6927" data-name="Сгруппировать 6927" transform="translate(-240.627 -258.885)" clip-path="url(#clip-path-18)">
          <path id="Контур_7723" data-name="Контур 7723" d="M358.933-223.474h6.8v6.158h-6.8Z" transform="translate(-120.944 479.72)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6930" data-name="Сгруппировать 6930" transform="translate(190.308 -383.406)" clip-path="url(#clip-path-2066)">
        <g id="Сгруппировать_6929" data-name="Сгруппировать 6929" transform="translate(-238.491 -259.559)" clip-path="url(#clip-path-18)">
          <path id="Контур_7726" data-name="Контур 7726" d="M354.886-222.2h7.864v6.774h-7.864Z" transform="translate(-119.033 479.117)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6932" data-name="Сгруппировать 6932" transform="translate(194.537 -384.068)" clip-path="url(#clip-path-2068)">
        <g id="Сгруппировать_6931" data-name="Сгруппировать 6931" transform="translate(-242.72 -258.897)" clip-path="url(#clip-path-18)">
          <path id="Контур_7729" data-name="Контур 7729" d="M362.9-223.451h6.8v6.158h-6.8Z" transform="translate(-122.817 479.709)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6934" data-name="Сгруппировать 6934" transform="translate(193.469 -383.451)" clip-path="url(#clip-path-2070)">
        <g id="Сгруппировать_6933" data-name="Сгруппировать 6933" transform="translate(-241.652 -259.514)" clip-path="url(#clip-path-18)">
          <path id="Контур_7732" data-name="Контур 7732" d="M360.875-222.282h6.8v6.158h-6.8Z" transform="translate(-121.861 479.157)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6936" data-name="Сгруппировать 6936" transform="translate(191.334 -382.834)" clip-path="url(#clip-path-2072)">
        <g id="Сгруппировать_6935" data-name="Сгруппировать 6935" transform="translate(-239.517 -260.131)" clip-path="url(#clip-path-18)">
          <path id="Контур_7735" data-name="Контур 7735" d="M356.828-221.113h7.864v6.79h-7.864Z" transform="translate(-119.951 478.605)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6938" data-name="Сгруппировать 6938" transform="translate(195.543 -383.484)" clip-path="url(#clip-path-2074)">
        <g id="Сгруппировать_6937" data-name="Сгруппировать 6937" transform="translate(-243.726 -259.481)" clip-path="url(#clip-path-18)">
          <path id="Контур_7738" data-name="Контур 7738" d="M364.8-222.343h6.8v6.158h-6.8Z" transform="translate(-123.717 479.186)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6940" data-name="Сгруппировать 6940" transform="translate(192.295 -382.226)" clip-path="url(#clip-path-2076)">
        <g id="Сгруппировать_6939" data-name="Сгруппировать 6939" transform="translate(-240.478 -260.739)" clip-path="url(#clip-path-18)">
          <path id="Контур_7741" data-name="Контур 7741" d="M358.649-219.96h7.865v6.775h-7.865Z" transform="translate(-120.81 478.061)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6942" data-name="Сгруппировать 6942" transform="translate(194.475 -382.867)" clip-path="url(#clip-path-2078)">
        <g id="Сгруппировать_6941" data-name="Сгруппировать 6941" transform="translate(-242.658 -260.098)" clip-path="url(#clip-path-18)">
          <path id="Контур_7744" data-name="Контур 7744" d="M362.78-221.174h6.8v6.158h-6.8Z" transform="translate(-122.761 478.634)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6944" data-name="Сгруппировать 6944" transform="translate(202.376 -385.389)" clip-path="url(#clip-path-1966)">
        <g id="Сгруппировать_6943" data-name="Сгруппировать 6943" transform="translate(-250.559 -257.576)" clip-path="url(#clip-path-18)">
          <path id="Контур_7747" data-name="Контур 7747" d="M377.751-225.953h5.5v5.551h-5.5Z" transform="translate(-129.831 480.891)" fill="#3f4954"/>
        </g>
      </g>
      <g id="Сгруппировать_6946" data-name="Сгруппировать 6946" transform="translate(202.596 -385.362)" clip-path="url(#clip-path-1968)">
        <g id="Сгруппировать_6945" data-name="Сгруппировать 6945" transform="translate(-250.779 -257.603)" clip-path="url(#clip-path-18)">
          <path id="Контур_7750" data-name="Контур 7750" d="M378.168-225.9H385.5v6.721h-7.336Z" transform="translate(-130.028 480.867)" fill="#3f4954"/>
        </g>
      </g>
      <g id="Сгруппировать_6948" data-name="Сгруппировать 6948" transform="translate(197.689 -385.362)" clip-path="url(#clip-path-1972)">
        <g id="Сгруппировать_6947" data-name="Сгруппировать 6947" transform="translate(-245.872 -257.603)" clip-path="url(#clip-path-18)">
          <path id="Контур_7753" data-name="Контур 7753" d="M368.87-225.9h9.965v8.231H368.87Z" transform="translate(-125.637 480.867)" fill="#3f4954"/>
        </g>
      </g>
      <g id="Сгруппировать_6950" data-name="Сгруппировать 6950" transform="translate(197.643 -382.656)" clip-path="url(#clip-path-1974)">
        <g id="Сгруппировать_6949" data-name="Сгруппировать 6949" transform="translate(-245.826 -260.309)" clip-path="url(#clip-path-18)">
          <path id="Контур_7756" data-name="Контур 7756" d="M368.783-220.775h5.324v5.588h-5.324Z" transform="translate(-125.596 478.445)" fill="#3f4954"/>
        </g>
      </g>
      <g id="Сгруппировать_6952" data-name="Сгруппировать 6952" transform="translate(197.643 -382.592)" clip-path="url(#clip-path-1976)">
        <g id="Сгруппировать_6951" data-name="Сгруппировать 6951" transform="translate(-245.826 -260.373)" clip-path="url(#clip-path-18)">
          <path id="Контур_7759" data-name="Контур 7759" d="M368.783-220.654h5.324v5.588h-5.324Z" transform="translate(-125.596 478.388)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6954" data-name="Сгруппировать 6954" transform="translate(199.966 -384.039)" clip-path="url(#clip-path-1978)">
        <g id="Сгруппировать_6953" data-name="Сгруппировать 6953" transform="translate(-248.149 -258.926)" clip-path="url(#clip-path-18)">
          <path id="Контур_7762" data-name="Контур 7762" d="M373.185-223.4h9.965v8.231h-9.965Z" transform="translate(-127.675 479.683)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6956" data-name="Сгруппировать 6956" transform="translate(199.747 -381.333)" clip-path="url(#clip-path-1980)">
        <g id="Сгруппировать_6955" data-name="Сгруппировать 6955" transform="translate(-247.93 -261.632)" clip-path="url(#clip-path-18)">
          <path id="Контур_7765" data-name="Контур 7765" d="M372.769-218.268h5.5v5.551h-5.5Z" transform="translate(-127.478 477.261)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6958" data-name="Сгруппировать 6958" transform="translate(186.778 -391.685)" clip-path="url(#clip-path-2094)">
        <g id="Сгруппировать_6957" data-name="Сгруппировать 6957" transform="translate(-234.961 -251.281)" clip-path="url(#clip-path-18)">
          <path id="Контур_7768" data-name="Контур 7768" d="M348.2-237.882h28.411v19.239H348.2Z" transform="translate(-115.874 486.524)" fill="#f4eeef"/>
        </g>
      </g>
      <g id="Сгруппировать_6960" data-name="Сгруппировать 6960" transform="translate(197.643 -385.389)" clip-path="url(#clip-path-1982)">
        <g id="Сгруппировать_6959" data-name="Сгруппировать 6959" transform="translate(-245.826 -257.576)" clip-path="url(#clip-path-18)">
          <path id="Контур_7771" data-name="Контур 7771" d="M368.784-225.953h12.333v9.36H368.784Z" transform="translate(-125.596 480.891)" fill="#c2bdbf"/>
        </g>
      </g>
      <g id="Сгруппировать_6962" data-name="Сгруппировать 6962" transform="translate(186.778 -383.251)" clip-path="url(#clip-path-2098)">
        <g id="Сгруппировать_6961" data-name="Сгруппировать 6961" transform="translate(-234.961 -259.714)" clip-path="url(#clip-path-18)">
          <path id="Контур_7774" data-name="Контур 7774" d="M348.2-221.9h13.489V-211.1H348.2Z" transform="translate(-115.874 478.977)" fill="#d3cdcc"/>
        </g>
      </g>
      <g id="Сгруппировать_6964" data-name="Сгруппировать 6964" transform="translate(194.988 -386.73)" clip-path="url(#clip-path-2100)">
        <g id="Сгруппировать_6963" data-name="Сгруппировать 6963" transform="translate(-243.171 -256.235)" clip-path="url(#clip-path-18)">
          <path id="Контур_7777" data-name="Контур 7777" d="M363.753-228.494h20.2v14.361h-20.2Z" transform="translate(-123.22 482.09)" fill="#c2bdbf"/>
        </g>
      </g>
      <g id="Сгруппировать_6966" data-name="Сгруппировать 6966" transform="translate(200.039 -390.942)" clip-path="url(#clip-path-1986)">
        <g id="Сгруппировать_6965" data-name="Сгруппировать 6965" transform="translate(-248.222 -252.023)" clip-path="url(#clip-path-18)">
          <path id="Контур_7780" data-name="Контур 7780" d="M373.324-236.475h6.8v6.157h-6.8Z" transform="translate(-127.74 485.859)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6968" data-name="Сгруппировать 6968" transform="translate(197.881 -389.71)" clip-path="url(#clip-path-1988)">
        <g id="Сгруппировать_6967" data-name="Сгруппировать 6967" transform="translate(-246.064 -253.255)" clip-path="url(#clip-path-18)">
          <path id="Контур_7783" data-name="Контур 7783" d="M369.234-234.14h6.8v6.158h-6.8Z" transform="translate(-125.809 484.757)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6970" data-name="Сгруппировать 6970" transform="translate(199.02 -390.372)" clip-path="url(#clip-path-1990)">
        <g id="Сгруппировать_6969" data-name="Сгруппировать 6969" transform="translate(-247.203 -252.594)" clip-path="url(#clip-path-18)">
          <path id="Контур_7786" data-name="Контур 7786" d="M371.392-235.394h6.8v6.157h-6.8Z" transform="translate(-126.828 485.349)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6972" data-name="Сгруппировать 6972" transform="translate(196.803 -389.083)" clip-path="url(#clip-path-1992)">
        <g id="Сгруппировать_6971" data-name="Сгруппировать 6971" transform="translate(-244.987 -253.882)" clip-path="url(#clip-path-18)">
          <path id="Контур_7789" data-name="Контур 7789" d="M367.192-232.953h6.8v6.157h-6.8Z" transform="translate(-124.845 484.196)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6974" data-name="Сгруппировать 6974" transform="translate(195.741 -388.472)" clip-path="url(#clip-path-1994)">
        <g id="Сгруппировать_6973" data-name="Сгруппировать 6973" transform="translate(-243.924 -254.493)" clip-path="url(#clip-path-18)">
          <path id="Контур_7792" data-name="Контур 7792" d="M365.179-231.795h6.8v6.158h-6.8Z" transform="translate(-123.894 483.649)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6976" data-name="Сгруппировать 6976" transform="translate(194.677 -387.859)" clip-path="url(#clip-path-1996)">
        <g id="Сгруппировать_6975" data-name="Сгруппировать 6975" transform="translate(-242.86 -255.106)" clip-path="url(#clip-path-18)">
          <path id="Контур_7795" data-name="Контур 7795" d="M363.163-230.634h6.8v6.158h-6.8Z" transform="translate(-122.942 483.101)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6978" data-name="Сгруппировать 6978" transform="translate(199.991 -390.266)" clip-path="url(#clip-path-1998)">
        <g id="Сгруппировать_6977" data-name="Сгруппировать 6977" transform="translate(-248.175 -252.699)" clip-path="url(#clip-path-18)">
          <path id="Контур_7798" data-name="Контур 7798" d="M373.233-235.194H381.1v6.774h-7.865Z" transform="translate(-127.697 485.254)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6980" data-name="Сгруппировать 6980" transform="translate(198.975 -389.084)" clip-path="url(#clip-path-2000)">
        <g id="Сгруппировать_6979" data-name="Сгруппировать 6979" transform="translate(-247.158 -253.881)" clip-path="url(#clip-path-18)">
          <path id="Контур_7801" data-name="Контур 7801" d="M371.307-232.954h6.8v6.158h-6.8Z" transform="translate(-126.788 484.197)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6982" data-name="Сгруппировать 6982" transform="translate(197.827 -388.424)" clip-path="url(#clip-path-2002)">
        <g id="Сгруппировать_6981" data-name="Сгруппировать 6981" transform="translate(-246.01 -254.541)" clip-path="url(#clip-path-18)">
          <path id="Контур_7804" data-name="Контур 7804" d="M369.132-231.7h6.8v6.157h-6.8Z" transform="translate(-125.761 483.606)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6984" data-name="Сгруппировать 6984" transform="translate(196.759 -387.807)" clip-path="url(#clip-path-2004)">
        <g id="Сгруппировать_6983" data-name="Сгруппировать 6983" transform="translate(-244.942 -255.158)" clip-path="url(#clip-path-18)">
          <path id="Контур_7807" data-name="Контур 7807" d="M367.109-230.535h6.8v6.157h-6.8Z" transform="translate(-124.805 483.054)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6986" data-name="Сгруппировать 6986" transform="translate(195.691 -387.19)" clip-path="url(#clip-path-2006)">
        <g id="Сгруппировать_6985" data-name="Сгруппировать 6985" transform="translate(-243.874 -255.775)" clip-path="url(#clip-path-18)">
          <path id="Контур_7810" data-name="Контур 7810" d="M365.085-229.366h6.8v6.158h-6.8Z" transform="translate(-123.849 482.502)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6988" data-name="Сгруппировать 6988" transform="translate(201.022 -389.61)" clip-path="url(#clip-path-2008)">
        <g id="Сгруппировать_6987" data-name="Сгруппировать 6987" transform="translate(-249.205 -253.355)" clip-path="url(#clip-path-18)">
          <path id="Контур_7813" data-name="Контур 7813" d="M375.185-233.952h7.865v6.774h-7.865Z" transform="translate(-128.619 484.668)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6990" data-name="Сгруппировать 6990" transform="translate(199.954 -388.434)" clip-path="url(#clip-path-2010)">
        <g id="Сгруппировать_6989" data-name="Сгруппировать 6989" transform="translate(-248.137 -254.532)" clip-path="url(#clip-path-18)">
          <path id="Контур_7816" data-name="Контур 7816" d="M373.162-231.722h6.8v6.158h-6.8Z" transform="translate(-127.664 483.615)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6992" data-name="Сгруппировать 6992" transform="translate(198.852 -387.782)" clip-path="url(#clip-path-2012)">
        <g id="Сгруппировать_6991" data-name="Сгруппировать 6991" transform="translate(-247.035 -255.183)" clip-path="url(#clip-path-18)">
          <path id="Контур_7819" data-name="Контур 7819" d="M371.073-230.487h6.8v6.157h-6.8Z" transform="translate(-126.677 483.032)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6994" data-name="Сгруппировать 6994" transform="translate(197.783 -387.165)" clip-path="url(#clip-path-2014)">
        <g id="Сгруппировать_6993" data-name="Сгруппировать 6993" transform="translate(-245.967 -255.8)" clip-path="url(#clip-path-18)">
          <path id="Контур_7822" data-name="Контур 7822" d="M369.049-229.318h6.8v6.158h-6.8Z" transform="translate(-125.722 482.48)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6996" data-name="Сгруппировать 6996" transform="translate(193.609 -387.243)" clip-path="url(#clip-path-2016)">
        <g id="Сгруппировать_6995" data-name="Сгруппировать 6995" transform="translate(-241.793 -255.723)" clip-path="url(#clip-path-18)">
          <path id="Контур_7825" data-name="Контур 7825" d="M361.14-229.465h6.8v6.158h-6.8Z" transform="translate(-121.987 482.549)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_6998" data-name="Сгруппировать 6998" transform="translate(194.623 -386.573)" clip-path="url(#clip-path-2018)">
        <g id="Сгруппировать_6997" data-name="Сгруппировать 6997" transform="translate(-242.807 -256.392)" clip-path="url(#clip-path-18)">
          <path id="Контур_7828" data-name="Контур 7828" d="M363.062-228.2h6.8v6.157h-6.8Z" transform="translate(-122.894 481.95)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_7000" data-name="Сгруппировать 7000" transform="translate(196.716 -386.548)" clip-path="url(#clip-path-2020)">
        <g id="Сгруппировать_6999" data-name="Сгруппировать 6999" transform="translate(-244.899 -256.417)" clip-path="url(#clip-path-18)">
          <path id="Контур_7831" data-name="Контур 7831" d="M367.026-228.15h6.8v6.158h-6.8Z" transform="translate(-124.766 481.928)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_7002" data-name="Сгруппировать 7002" transform="translate(195.648 -385.931)" clip-path="url(#clip-path-2022)">
        <g id="Сгруппировать_7001" data-name="Сгруппировать 7001" transform="translate(-243.831 -257.034)" clip-path="url(#clip-path-18)">
          <path id="Контур_7834" data-name="Контур 7834" d="M365-226.981h6.8v6.158H365Z" transform="translate(-123.811 481.376)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_7004" data-name="Сгруппировать 7004" transform="translate(200.923 -389.005)" clip-path="url(#clip-path-2024)">
        <g id="Сгруппировать_7003" data-name="Сгруппировать 7003" transform="translate(-249.106 -253.96)" clip-path="url(#clip-path-18)">
          <path id="Контур_7837" data-name="Контур 7837" d="M375-232.8h8.932v7.391H375Z" transform="translate(-128.531 484.126)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_7006" data-name="Сгруппировать 7006" transform="translate(199.877 -387.153)" clip-path="url(#clip-path-2026)">
        <g id="Сгруппировать_7005" data-name="Сгруппировать 7005" transform="translate(-248.06 -255.813)" clip-path="url(#clip-path-18)">
          <path id="Контур_7840" data-name="Контур 7840" d="M373.015-229.295h6.8v6.158h-6.8Z" transform="translate(-127.594 482.469)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_7008" data-name="Сгруппировать 7008" transform="translate(198.809 -386.536)" clip-path="url(#clip-path-2028)">
        <g id="Сгруппировать_7007" data-name="Сгруппировать 7007" transform="translate(-246.992 -256.43)" clip-path="url(#clip-path-18)">
          <path id="Контур_7843" data-name="Контур 7843" d="M370.992-228.126h6.8v6.158h-6.8Z" transform="translate(-126.639 481.916)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_7010" data-name="Сгруппировать 7010" transform="translate(197.74 -385.919)" clip-path="url(#clip-path-2030)">
        <g id="Сгруппировать_7009" data-name="Сгруппировать 7009" transform="translate(-245.924 -257.046)" clip-path="url(#clip-path-18)">
          <path id="Контур_7846" data-name="Контур 7846" d="M368.968-226.957h6.8v6.158h-6.8Z" transform="translate(-125.683 481.365)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_7012" data-name="Сгруппировать 7012" transform="translate(196.673 -385.302)" clip-path="url(#clip-path-2032)">
        <g id="Сгруппировать_7011" data-name="Сгруппировать 7011" transform="translate(-244.856 -257.663)" clip-path="url(#clip-path-18)">
          <path id="Контур_7849" data-name="Контур 7849" d="M366.945-225.788h6.8v6.157h-6.8Z" transform="translate(-124.728 480.813)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_7014" data-name="Сгруппировать 7014" transform="translate(192.541 -386.625)" clip-path="url(#clip-path-2034)">
        <g id="Сгруппировать_7013" data-name="Сгруппировать 7013" transform="translate(-240.724 -256.34)" clip-path="url(#clip-path-18)">
          <path id="Контур_7852" data-name="Контур 7852" d="M359.116-228.3h6.8v6.157h-6.8Z" transform="translate(-121.031 481.997)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_7016" data-name="Сгруппировать 7016" transform="translate(193.556 -385.957)" clip-path="url(#clip-path-2036)">
        <g id="Сгруппировать_7015" data-name="Сгруппировать 7015" transform="translate(-241.739 -257.009)" clip-path="url(#clip-path-18)">
          <path id="Контур_7855" data-name="Контур 7855" d="M361.039-227.029h6.8v6.157h-6.8Z" transform="translate(-121.939 481.398)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_7018" data-name="Сгруппировать 7018" transform="translate(194.58 -385.315)" clip-path="url(#clip-path-2038)">
        <g id="Сгруппировать_7017" data-name="Сгруппировать 7017" transform="translate(-242.763 -257.651)" clip-path="url(#clip-path-18)">
          <path id="Контур_7858" data-name="Контур 7858" d="M362.98-225.812h6.8v6.158h-6.8Z" transform="translate(-122.855 480.824)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_7020" data-name="Сгруппировать 7020" transform="translate(195.605 -384.685)" clip-path="url(#clip-path-2040)">
        <g id="Сгруппировать_7019" data-name="Сгруппировать 7019" transform="translate(-243.788 -258.28)" clip-path="url(#clip-path-18)">
          <path id="Контур_7861" data-name="Контур 7861" d="M364.922-224.619h6.8v6.157h-6.8Z" transform="translate(-123.773 480.26)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_7022" data-name="Сгруппировать 7022" transform="translate(203.018 -387.802)" clip-path="url(#clip-path-2042)">
        <g id="Сгруппировать_7021" data-name="Сгруппировать 7021" transform="translate(-251.202 -255.163)" clip-path="url(#clip-path-18)">
          <path id="Контур_7864" data-name="Контур 7864" d="M378.968-230.525h6.8v6.158h-6.8Z" transform="translate(-130.406 483.05)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_7024" data-name="Сгруппировать 7024" transform="translate(201.95 -387.185)" clip-path="url(#clip-path-2044)">
        <g id="Сгруппировать_7023" data-name="Сгруппировать 7023" transform="translate(-250.133 -255.781)" clip-path="url(#clip-path-18)">
          <path id="Контур_7867" data-name="Контур 7867" d="M376.944-229.356h6.8v6.157h-6.8Z" transform="translate(-129.45 482.497)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_7026" data-name="Сгруппировать 7026" transform="translate(196.611 -386.568)" clip-path="url(#clip-path-2046)">
        <g id="Сгруппировать_7025" data-name="Сгруппировать 7025" transform="translate(-244.794 -256.397)" clip-path="url(#clip-path-18)">
          <path id="Контур_7870" data-name="Контур 7870" d="M366.828-228.187H377.9v8.625H366.828Z" transform="translate(-124.673 481.945)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_7028" data-name="Сгруппировать 7028" transform="translate(191.473 -386.009)" clip-path="url(#clip-path-2048)">
        <g id="Сгруппировать_7027" data-name="Сгруппировать 7027" transform="translate(-239.656 -256.956)" clip-path="url(#clip-path-18)">
          <path id="Контур_7873" data-name="Контур 7873" d="M357.092-227.128h6.8v6.158h-6.8Z" transform="translate(-120.075 481.445)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_7030" data-name="Сгруппировать 7030" transform="translate(190.405 -385.392)" clip-path="url(#clip-path-2050)">
        <g id="Сгруппировать_7029" data-name="Сгруппировать 7029" transform="translate(-238.588 -257.573)" clip-path="url(#clip-path-18)">
          <path id="Контур_7876" data-name="Контур 7876" d="M355.069-225.959h6.8v6.158h-6.8Z" transform="translate(-119.12 480.893)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_7032" data-name="Сгруппировать 7032" transform="translate(188.27 -384.775)" clip-path="url(#clip-path-2052)">
        <g id="Сгруппировать_7031" data-name="Сгруппировать 7031" transform="translate(-236.453 -258.19)" clip-path="url(#clip-path-18)">
          <path id="Контур_7879" data-name="Контур 7879" d="M351.023-224.79h7.864v6.774h-7.864Z" transform="translate(-117.209 480.341)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_7034" data-name="Сгруппировать 7034" transform="translate(192.487 -385.34)" clip-path="url(#clip-path-2054)">
        <g id="Сгруппировать_7033" data-name="Сгруппировать 7033" transform="translate(-240.67 -257.625)" clip-path="url(#clip-path-18)">
          <path id="Контур_7882" data-name="Контур 7882" d="M359.014-225.86h6.8v6.158h-6.8Z" transform="translate(-120.983 480.847)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_7036" data-name="Сгруппировать 7036" transform="translate(191.42 -384.723)" clip-path="url(#clip-path-2056)">
        <g id="Сгруппировать_7035" data-name="Сгруппировать 7035" transform="translate(-239.603 -258.242)" clip-path="url(#clip-path-18)">
          <path id="Контур_7885" data-name="Контур 7885" d="M356.991-224.691h6.8v6.157h-6.8Z" transform="translate(-120.027 480.295)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_7038" data-name="Сгруппировать 7038" transform="translate(190.352 -384.106)" clip-path="url(#clip-path-2058)">
        <g id="Сгруппировать_7037" data-name="Сгруппировать 7037" transform="translate(-238.535 -258.859)" clip-path="url(#clip-path-18)">
          <path id="Контур_7888" data-name="Контур 7888" d="M354.968-223.523h6.8v6.158h-6.8Z" transform="translate(-119.072 479.743)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_7040" data-name="Сгруппировать 7040" transform="translate(189.284 -383.489)" clip-path="url(#clip-path-2060)">
        <g id="Сгруппировать_7039" data-name="Сгруппировать 7039" transform="translate(-237.467 -259.476)" clip-path="url(#clip-path-18)">
          <path id="Контур_7891" data-name="Контур 7891" d="M352.944-222.353h6.8v6.157h-6.8Z" transform="translate(-118.116 479.191)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_7042" data-name="Сгруппировать 7042" transform="translate(193.512 -384.698)" clip-path="url(#clip-path-2062)">
        <g id="Сгруппировать_7041" data-name="Сгруппировать 7041" transform="translate(-241.695 -258.268)" clip-path="url(#clip-path-18)">
          <path id="Контур_7894" data-name="Контур 7894" d="M360.956-224.643h6.8v6.158h-6.8Z" transform="translate(-121.9 480.272)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_7044" data-name="Сгруппировать 7044" transform="translate(192.444 -384.081)" clip-path="url(#clip-path-2064)">
        <g id="Сгруппировать_7043" data-name="Сгруппировать 7043" transform="translate(-240.627 -258.885)" clip-path="url(#clip-path-18)">
          <path id="Контур_7897" data-name="Контур 7897" d="M358.933-223.474h6.8v6.158h-6.8Z" transform="translate(-120.944 479.72)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_7046" data-name="Сгруппировать 7046" transform="translate(190.308 -383.406)" clip-path="url(#clip-path-2066)">
        <g id="Сгруппировать_7045" data-name="Сгруппировать 7045" transform="translate(-238.491 -259.559)" clip-path="url(#clip-path-18)">
          <path id="Контур_7900" data-name="Контур 7900" d="M354.886-222.2h7.864v6.774h-7.864Z" transform="translate(-119.033 479.117)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_7048" data-name="Сгруппировать 7048" transform="translate(194.537 -384.068)" clip-path="url(#clip-path-2068)">
        <g id="Сгруппировать_7047" data-name="Сгруппировать 7047" transform="translate(-242.72 -258.897)" clip-path="url(#clip-path-18)">
          <path id="Контур_7903" data-name="Контур 7903" d="M362.9-223.451h6.8v6.158h-6.8Z" transform="translate(-122.817 479.709)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_7050" data-name="Сгруппировать 7050" transform="translate(193.469 -383.451)" clip-path="url(#clip-path-2070)">
        <g id="Сгруппировать_7049" data-name="Сгруппировать 7049" transform="translate(-241.652 -259.514)" clip-path="url(#clip-path-18)">
          <path id="Контур_7906" data-name="Контур 7906" d="M360.875-222.282h6.8v6.158h-6.8Z" transform="translate(-121.861 479.157)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_7052" data-name="Сгруппировать 7052" transform="translate(191.334 -382.834)" clip-path="url(#clip-path-2072)">
        <g id="Сгруппировать_7051" data-name="Сгруппировать 7051" transform="translate(-239.517 -260.131)" clip-path="url(#clip-path-18)">
          <path id="Контур_7909" data-name="Контур 7909" d="M356.828-221.113h7.864v6.79h-7.864Z" transform="translate(-119.951 478.605)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_7054" data-name="Сгруппировать 7054" transform="translate(195.543 -383.484)" clip-path="url(#clip-path-2074)">
        <g id="Сгруппировать_7053" data-name="Сгруппировать 7053" transform="translate(-243.726 -259.481)" clip-path="url(#clip-path-18)">
          <path id="Контур_7912" data-name="Контур 7912" d="M364.8-222.343h6.8v6.158h-6.8Z" transform="translate(-123.717 479.186)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_7056" data-name="Сгруппировать 7056" transform="translate(192.295 -382.226)" clip-path="url(#clip-path-2076)">
        <g id="Сгруппировать_7055" data-name="Сгруппировать 7055" transform="translate(-240.478 -260.739)" clip-path="url(#clip-path-18)">
          <path id="Контур_7915" data-name="Контур 7915" d="M358.649-219.96h7.865v6.775h-7.865Z" transform="translate(-120.81 478.061)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_7058" data-name="Сгруппировать 7058" transform="translate(194.475 -382.867)" clip-path="url(#clip-path-2078)">
        <g id="Сгруппировать_7057" data-name="Сгруппировать 7057" transform="translate(-242.658 -260.098)" clip-path="url(#clip-path-18)">
          <path id="Контур_7918" data-name="Контур 7918" d="M362.78-221.174h6.8v6.158h-6.8Z" transform="translate(-122.761 478.634)" fill="#999095"/>
        </g>
      </g>
      <g id="Сгруппировать_7060" data-name="Сгруппировать 7060" transform="translate(186.903 -401.719)" clip-path="url(#clip-path-2196)">
        <g id="Сгруппировать_7059" data-name="Сгруппировать 7059" transform="translate(-235.086 -241.246)" clip-path="url(#clip-path-18)">
          <path id="Контур_7921" data-name="Контур 7921" d="M348.434-256.895h19v14.157h-19Z" transform="translate(-115.986 495.502)" fill="#353b3c"/>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "MyOfficeImage"
}
</script>

<style scoped>

</style>
