<template>
  <div class="dropdown" v-if="options">
    <!-- Dropdown Input -->
    <div class="input-icon">
      <b-form-input
          class="dropdown-input"
          :name="name"
          @focus="showOptions()"
          @blur="exit()"
          @keyup="keyMonitor"
          v-model="searchFilter"
          :disabled="disabled"
          :placeholder="placeholder">
      </b-form-input>
      <span><i class="fas fa-search icon-custom"></i></span>
    </div>
    <!-- Dropdown Menu -->
    <div class="dropdown-content"
         v-show="optionsShown">
      <div v-if="name === 'myOfficeDropdownSearch'">
        <template v-if="filteredDeskOptions.length === 0 && filteredMeetingRoomOptions.length === 0 && filteredEmployeeOptions.length === 0">
          <p class="font-weight-bolder text-left text-muted mx-4 my-4">{{ $t("TABLE.EMPTY_TITLE") }}</p>
        </template>
        <p v-if="filteredDeskOptions.length > 0" class="font-weight-bolder text-left text-muted mx-4 my-4">{{ $t("MY_OFFICE.DESKS_TITLE") }}</p>
        <div class="dropdown-item py-4 px-5"
          @mousedown="selectOption(option)"
          v-for="option in filteredDeskOptions"
          :key="option.id">
          <div :class="setPointClass(option)" class="demo float-left mr-4"></div>
          <h6 class="font-weight-bolder text-dark pt-0">{{ option.name }}</h6>
        </div>
        <p v-if="filteredMeetingRoomOptions.length > 0" class="font-weight-bolder text-left text-muted mx-4 my-4">{{ $t("MY_OFFICE.MEETING_ROOMS_TITLE") }}</p>
        <div class="dropdown-item py-4 px-5"
             @mousedown="selectOption(option)"
             v-for="option in filteredMeetingRoomOptions"
             :key="option.id">
          <div :class="setPointClass(option)" class="demo float-left mr-4"></div>
          <h6 class="font-weight-bolder text-dark m-0 pt-0">{{ option.name }}</h6>
          <p v-if="option.amount_of_seats"
             class="text-left text-light text-muted m-0 ml-10">{{ $t("MY_OFFICE.AMOUNT_OF_SEATS_TO") }} {{ option.amount_of_seats }} {{ $t("MY_OFFICE.AMOUNT_OF_SEATS_PEOPLE") }}</p>
          <p v-if="!option.amount_of_seats"
             class="text-left text-light text-muted m-0 ml-10">{{ $t("MY_OFFICE.AMOUNT_OF_SEATS_NOT_FOUND") }}</p>
        </div>
        <p v-if="filteredEmployeeOptions.length > 0" class="font-weight-bolder text-left text-muted mx-4 my-4">{{ $t("MY_OFFICE.COLLEAGUES_TITLE") }}</p>
        <div class="dropdown-item py-4 px-5"
             @mousedown="selectOption(option)"
             v-for="option in filteredEmployeeOptions"
             :key="option.id">
          <div class="demo float-left mr-4">
            <User/>
          </div>
          <h6 class="font-weight-bolder text-dark m-0 pt-0">{{ option.employee_name }}</h6>
          <p v-if="option.name"
             class="text-left text-light text-muted m-0 ml-10">{{ option.name }}</p>
        </div>
      </div>
      <!--  MEMBERS SEARCH  -->
      <div v-if="name === 'myOfficeDropdownEmployeesSearch'"
          class="dropdown-item py-4 px-5"
          v-for="(option, index) in filteredOptions"
          :key="index">
        <p @mousedown="selectOption(option)">
          <span class="h6 d-block text-left font-weight-bolder text-dark pt-0">{{ option.name.display }}</span>
          <span class="d-block text-left font-weight-light text-muted">{{ option.email }}</span>
        </p>
      </div>
      <!--  GUEST ADD  -->
      <span v-if="isGuest && name === 'myOfficeDropdownEmployeesSearch'" class="dropdown-item py-4 px-5"
           @mousedown="selectOption(searchFilter)">
        <span class="font-size-h6 d-block text-left font-weight-light">
          {{ $t("MY_OFFICE.MEMBERS_BLOCK_ADD") }} <span class="font-weight-bolder text-dark">{{ searchFilter }}</span> {{ $t("MY_OFFICE.MEMBERS_BLOCK_AS_GUEST") }}
        </span>
      </span>
      <!--  GROUPS SEARCH  -->
      <span v-if="name === 'dropdownGroupSearch'"
            class="dropdown-item py-4 px-5"
            @mousedown="selectOption(option)"
            v-for="(option, index) in filteredOptions"
            :key="index">
        <p class="text-left font-weight-bolder text-dark m-0 p-0">{{ option.name }}</p>
      </span>
    </div>
  </div>
</template>

<script>
import User from "@/view/layout/svg/User";
import i18n from "../../../core/plugins/vue-i18n";

export default {
  name: "DropdownSearch",
  components: {
    User
  },
  props: {
    name: {
      type: String,
      required: false,
      default: 'dropdown',
      note: 'Input name'
    },
    options: {
      type: Array,
      required: true,
      default: function () {
        return []
      },
      note: 'Options of dropdown. An array of options with id and name',
    },
    resource: {
      type: Object,
      required: false,
      default: null
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Please select an option',
      note: 'Placeholder of dropdown'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Disable the dropdown'
    },
    maxItem: {
      type: Number,
      required: false,
      default: 10,
      note: 'Max items showing'
    }
  },
  data() {
    return {
      selected: {},
      optionsShown: false,
      searchFilter: '',
      isGuest: false
    }
  },
  created() {
    this.$emit('selected', this.selected);
  },
  computed: {
    filteredOptions() {
      const filtered = [];
      const regOption = new RegExp(this.searchFilter, 'ig');
      for (const option of this.options) {
        if (this.name === 'myOfficeDropdownSearch' || this.name === 'dropdownGroupSearch') {
          if (this.searchFilter.length < 1 || option.name.match(regOption)){
            if (filtered.length < this.maxItem) filtered.push(option);
          }
        } else {
          if (this.searchFilter.length < 1 || option.name.display.match(regOption) || option.email.match(regOption)){
            if (filtered.length < this.maxItem) filtered.push(option);
          }
        }
      }
      // Добавить гостя
      this.isGuest = this.options.length === 0 && this.validateEmail(this.searchFilter);

      return filtered;
    },
    filteredDeskOptions() {
      return this.options.filter(option => option.type === 'desk');
    },
    filteredMeetingRoomOptions() {
      return this.options.filter(option => option.type === 'meeting_room');
    },
    filteredEmployeeOptions() {
      return this.options.filter(option => option.title === 'Коллеги');
    }
  },
  methods: {
    validateEmail(email) {
      let re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    setPointClass(item) {
      if (item.type === 'desk') {
        if (item.point_status === 'free') return 'free-workplace-point';
        else if (item.point_status === 'booked') return 'reserved-workplace-point';
        else if (item.point_status === 'unavailable') return 'unavailable-workplace-point';
        else return 'busy-workplace-point';
      } else {
        if (item.point_status === 'free') return 'free-meeting-point';
        else if (item.point_status === 'booked') return 'reserved-meeting-point';
        else if (item.point_status === 'unavailable') return 'unavailable-meeting-point';
        else return 'busy-meeting-point';
      }
    },
    selectOption(option) {
      if (this.isGuest) {
        option = {
          email: option,
          name: {
            display: i18n.t("MY_OFFICE.MEMBERS_BLOCK_GUEST_DESC")
          }
        }
        this.selected = option;
      } else {
        this.selected = option;
      }
      this.optionsShown = false;
      if (this.name === 'myOfficeDropdownSearch') {
        this.searchFilter = this.selected.name
        this.$emit('selected', this.selected);
      } else if (this.name === 'myOfficeDropdownEmployeesSearch') {
        this.searchFilter = this.isGuest ? this.selected : this.selected.name.display;
        this.$emit('selectedEmployee', { selected: this.selected, properties: this.resource.properties.amountOfSeats });
        this.isGuest = false;
      } else if (this.name === 'dropdownGroupSearch') {
        this.searchFilter = this.selected.name
        this.$emit('selectedGroup', this.selected);
      }
    },
    showOptions(){
      if (!this.disabled) {
        this.searchFilter = '';
        this.optionsShown = true;
      }
    },
    exit() {
      // if (!this.selected.id) {
      //   this.selected = {};
      //   this.searchFilter = '';
      // } else {
      //   this.searchFilter = this.name === 'myOfficeDropdownSearch' ? this.selected.name : this.selected.name.display;
      // }
      this.selected = {};
      this.searchFilter = '';
      this.$emit('selected', this.selected);
      this.optionsShown = false;
    },
    // Selecting when pressing Enter
    keyMonitor: function(event) {
      if (event.key === "Enter" && this.filteredOptions[0])
        this.selectOption(this.filteredOptions[0]);
    }
  },
  watch: {
    searchFilter() {
      if (this.filteredOptions.length === 0) {
        this.selected = {};
      } else {
        this.selected = this.filteredOptions[0];
      }
      this.$emit('filter', this.searchFilter);
    }
  }
};
</script>


<style lang="scss" scoped>
.dropdown {
  position: relative;
  display: block;
  margin: auto;
  width: 100%;
  .dropdown-input {
    border: none;
    background: #F2F6F9;
    cursor: pointer;
    //border: 1px solid #e7ecf5;
    //border-radius: 3px;
    //color: #333;
    //display: block;
    //font-size: .8em;
    //padding: 6px;
    //min-width: 250px;
    //max-width: 250px;
    &:hover {
      background: #f8f8fa;
    }
  }
  .dropdown-content {
    position: absolute;
    background-color: #fff;
    min-width: 100%;
    max-width: 100%;
    max-height: 248px;
    border: 1px solid #e7ecf5;
    box-shadow: 0px -8px 34px 0px rgba(0,0,0,0.05);
    overflow: auto;
    z-index: 1;
    .dropdown-item {
      //color: black;
      //font-size: 1em;
      //line-height: 1em;
      //padding: 8px;
      //text-decoration: none;
      //display: block;
      cursor: pointer;
      border-bottom: 1px solid #E4E5EF;
      &:hover {
        background-color: #e7ecf5;
      }
    }
  }
  .dropdown:hover .dropdowncontent {
    display: block;
  }
}
</style>
